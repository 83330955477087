import { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@mui/material';
import { Comment } from '../../../screens/comment/Comment';
import { Card, CardBody, CardHeader, CardHeaderTitle, CardHeaderToolbar } from '../../../partials/controls/Card';
import { RefuseMessageComponent, ResetMessageComponent } from '../../message';
import { initConfirmation, ConfirmationHeaderTitle } from '../common';
import { ConfirmationStackableActionsSection } from '../workflow'
import { ConfirmationToolbarItems, EditConfirmationStepper, ConfirmationContactToolbar } from './utils';
import {
    ConfirmationDocumentsContainer,
    ConfirmationInfoComponent,
    ConfirmationThirdPartySection,
    ConfirmationAlertsSection
} from './sections';
import { ConfirmationContainer } from './ConfirmationContainer';
import { ContactContainer } from '../../contact';
import { useDeleteConfirmation } from '../../../hooks/useEntityActionPopup';
import { hasThirdParty, contactRoleValidator, getGroupedStackableActions } from '../../../helpers';
import { CONTACT_TYPE, STEP_ACTION_TYPE } from '../../../types/types';
import { UIDialog } from '../../common/UIDialog';
import { ItemContext } from '../../../context';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    margin: {
        margin: theme.spacing(0.5)
    },
    textField: {
        width: '100%',
        height: '5%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    },
    inline: {
        display: 'inline'
    },
    actionsContainer: {
        marginTop: theme.spacing(2)
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    },
    backButton: {
        margin: theme.spacing(3)
    }
}));

const clientContactsLabels = {
    cardTitle: null,
    createButton: 'CONTACT_SECTION.BUTTON.NEW.LABEL',
    createContactPopupTitle: 'DIALOG.CONTACT.CREATE.TITLE',
    editContactPopupTitle: 'DIALOG.CONTACT.EDIT.TITLE',
    contactsSelect: 'CREATE_CONFIRMATION.CLIENT.CONTACT.LABEL',
    headerTitle: 'CONTACT_SECTION.SUBSECTION.CONTACTS.CLIENT'
};

export function ConfirmationComponent(props) {
    const {
        intl,
        user,
        confirmation,
        handleFetchMenuConfirmations,
        paginatedConfirmationItems,
        languages,
        documentTypes,
        itemErrors,
        updateConfirmation,
        processStep,
        selectStackableStep,
        loading,
        deleteConfirmation,
        engagement,
        downloadConfirmationFiles,
        navigationProps
    } = props;

    const history = useHistory();
    const classes = useStyles();
    const btnConfRef = useRef();
    const btnRefuseRef = useRef();
    const btnResetRef = useRef();

    const { updateItem } = useContext(ItemContext);
    const [confirmationSelected, setConfirmation] = useState(initConfirmation);
    const [documentsAvailable, setDocumentAvailable] = useState([]);
    const [invalidContacts, setInvalidContacts] = useState([]);
    const [invalidPostalAddresses, setInvalidPostalAddresses] = useState([]);
    const [sortedSteps, setSortedSteps] = useState([]);
    const [openRefuse, setOpenRefuse] = useState(false);
    const [openReset, setOpenReset] = useState(false);
    const [validationError, setValidationError] = useState('');

    const [lastTriggeredStepAction, setLastTriggeredStepAction] = useState(null);

    const { steps: stackableSteps, isStackable } = getGroupedStackableActions(sortedSteps);

    const getInvalidContacts = confirmation => [
        ...confirmation?.clientContacts?.filter(x => x?.isInvalidAddress),
        ...confirmation?.thirdPartyContacts?.filter(x => x?.isInvalidAddress)
    ];

    const getInvalidPostalAddresses = confirmation => confirmation?.items?.filter(x => x?.isInvalidAddress);

    useEffect(() => {
        confirmation && prepareSteps(confirmation.steps);
        setConfirmation({
            ...confirmation,
            optionalFields: confirmation?.optionalFields?.filter(field => field.isInput)
        });
  
        confirmation && setInvalidContacts(getInvalidContacts(confirmation));
        confirmation && setInvalidPostalAddresses(getInvalidPostalAddresses(confirmation));
    }, [confirmation]);

    useEffect(() => {
        setDocumentAvailable(paginatedConfirmationItems?.items);
    }, [paginatedConfirmationItems]);

    const { delete: DeleteConfirmationComponent, handleDeleteClick } = useDeleteConfirmation({
        user: user,
        deleteAPI: deleteConfirmation,
        warningMessageId: 'MESSAGE.TRASH.CONFIRMATION.CONTENT',
        deleteConfirmationCallback: isSuccess => isSuccess && backToPreviousPage()
    });

    const handleRefusePopUpOpening = () => setOpenRefuse(!openRefuse);
    const handleResetPopUpOpening = () => setOpenReset(!openReset);

    const prepareSteps = steps => {
        const updatedSteps = steps.sort((a, b) =>
            a?.position !== b?.position ? (a?.position < b?.position ? -1 : 1) : 0
        );

        setSortedSteps(updatedSteps);
    };

    const isValidConfirmation = currentConfirmation => {
        const { errorMessage: clientErrors, hasValidContacts: hasValidClientContacts } = contactRoleValidator({
            contacts: currentConfirmation.clientContacts,
            intl: intl
        });

        const {
            errorMessage: thirdPartyErrors,
            hasValidSignatoryContacts: hasValidThirdPartyContacts
        } = contactRoleValidator({
            contacts: currentConfirmation.thirdPartyContacts,
            intl: intl,
            signatoryMessageId: 'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_MAIN'
        });

        let error = clientErrors
            ? `${intl.formatMessage({ id: 'CONTACT_SECTION.SUBSECTION.CONTACTS.CLIENT' })}: ${clientErrors}\n`
            : '';

        error = thirdPartyErrors
            ? `${error}${intl.formatMessage({
                  id: 'CONTACT_SECTION.SUBSECTION.CONTACTS.THIRDPARTY'
              })}: ${thirdPartyErrors}`
            : error;

        return {
            isValid: hasValidClientContacts && hasValidThirdPartyContacts,
            errorMessage: error
        };
    };

    const handleSubmit = async values => {
        const { isValid, errorMessage } = isValidConfirmation(values);
        isValid ? await updateConfirmation({ ...confirmationSelected, ...values }) : setValidationError(errorMessage);
    };

    const handleConfirmationUpdate = values => {
        setConfirmation({ ...confirmationSelected, ...values });
    };

    const handleSaveClick = () => {
        if (btnConfRef && btnConfRef.current) {
            btnConfRef.current.saveForm();
        }
    };

    const backToPreviousPage = () => {
        history.goBack();
    };

    const handleRefuseClick = () => {
        if (btnRefuseRef && btnRefuseRef.current) {
            btnRefuseRef.current.saveForm();
        }
    };

    const handleRefuse = values => {
        if (values?.id) updateItem(values);
        processStep(lastTriggeredStepAction?.confirmationStepId, {
            isActive: lastTriggeredStepAction.status,
            status: lastTriggeredStepAction?.status
        });
        setOpenRefuse(!openRefuse);
    };

    const handleResetClick = () => {
        if (btnResetRef && btnResetRef.current) {
            btnResetRef.current.saveForm();
        }
    };

    const handleReset = values => {
        if (values?.id) updateItem(values);
        processStep(lastTriggeredStepAction?.confirmationStepId, {
            isActive: lastTriggeredStepAction.status,
            status: lastTriggeredStepAction?.status
        });
        setOpenReset(!openReset);
    };

    const getDocumentToReset = () =>
        documentsAvailable?.length > 0
            ? documentsAvailable.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime())[0]
            : {};

    const handleStepAction = action => {
        setLastTriggeredStepAction(action);

        switch (action.type) {
            case STEP_ACTION_TYPE.Refuse:
                setOpenRefuse(!openRefuse);
                break;

            case STEP_ACTION_TYPE.Reset:
                setOpenReset(!openReset);
                break;

            default:
                processStep(action.confirmationStepId, {
                    isActive: true,
                    status: action.status
                });
                break;
        }
    };

    const handleContactsUpdate = updatedContacts => {
        handleConfirmationUpdate({
            clientContacts: updatedContacts
        });
    };

    const handlePreviousConfirmation = () => {
        const {
            filters: { pageIndex },
            handleFilterChange
        } = navigationProps;
        handleFilterChange({
            pageIndex: pageIndex > 0 ? pageIndex - 1 : pageIndex
        });
    };

    const handleNextConfirmation = () => {
        const {
            filters: { pageIndex },
            handleFilterChange
        } = navigationProps;

        handleFilterChange({
            pageIndex: pageIndex + 1
        });
    };

    return (
        <Card>
            <CardHeader
                title={
                    <ConfirmationHeaderTitle
                        isConfirmationReady={!!confirmationSelected?.id}
                        isDefaultTitle={!hasThirdParty(confirmationSelected)}
                        subtitle={confirmationSelected?.thirdPartyCompany?.name}
                        engagementName={engagement?.name}
                    />
                }>
                <CardHeaderToolbar>
                    <ConfirmationToolbarItems
                        loading={loading}
                        handleBack={backToPreviousPage}
                        handleDelete={handleDeleteClick}
                        handleSave={handleSaveClick}
                        currentMenuActions={confirmationSelected?.currentStep?.stepActions}
                        handleStepAction={handleStepAction}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ConfirmationAlertsSection
                    intl={intl}
                    confirmation={confirmationSelected}
                    invalidContacts={invalidContacts}
                    invalidPostalAddresses={invalidPostalAddresses}
                    validationError={validationError}
                    onValidationErrorClose={() => setValidationError('')}
                />
            </CardBody>

            <CardBody className="text-center">
                {!confirmation ? (
                    <CircularProgress />
                ) : (
                    <EditConfirmationStepper
                        steps={sortedSteps}
                        currentStepPosition={confirmation?.currentStep?.position}
                        handleStepAction={handleStepAction}
                    />
                )}
            </CardBody>

            <CardBody>
                <ConfirmationContainer
                    handlePrevious={handlePreviousConfirmation}
                    handleNext={handleNextConfirmation}
                    isNextDisabled={!navigationProps.hasNextPage}
                    isPreviousDisabled={!navigationProps.hasPreviousPage}>
                    <Grid container sx={styles.container} spacing={2}>
                        <Grid item md={7}>
                            {/*Documents section*/}
                            <ConfirmationDocumentsContainer
                                user={user}
                                paginatedItems={paginatedConfirmationItems}
                                documentTypes={documentTypes}
                                itemErrors={itemErrors}
                                confirmation={confirmationSelected}
                                downloadConfirmationFiles={downloadConfirmationFiles}
                                handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                            />

                            {/*Global info section*/}
                            <ConfirmationInfoComponent
                                {...props}
                                btnConfRef={btnConfRef}
                                classes={classes}
                                variant="outlined"
                                confirmation={confirmationSelected}
                                languages={languages}
                                handleSubmit={handleSubmit}
                            />

                            {/*Client contacts section*/}
                            {!confirmationSelected || !confirmationSelected.clientCompany ? (
                                <div className="text-center">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <>
                                    <ContactContainer
                                        contactType={CONTACT_TYPE.CLIENT}
                                        disabled={!confirmationSelected?.isEditable}
                                        intl={intl}
                                        selectedContacts={confirmationSelected?.clientContacts}
                                        allContacts={engagement?.clientContacts}
                                        companies={engagement?.clientCompanies}
                                        handleContactsUpdate={handleContactsUpdate}
                                        strings={clientContactsLabels}
                                        view={null}
                                        toolbarView={ConfirmationContactToolbar}
                                        style={classes}
                                    />
                                </>
                            )}

                            {/*Third party section*/}
                            <ConfirmationThirdPartySection
                                confirmationEngagementId={confirmation?.engagementId}
                                isThirdPartyLoaded={hasThirdParty(confirmationSelected)}
                                isConfirmationLoaded={!!confirmation?.id}
                                thirdPartyCompany={confirmationSelected?.thirdPartyCompany}
                                thirdPartyContacts={confirmationSelected?.thirdPartyContacts}
                                disabled={!confirmationSelected?.isEditable}
                                classes={classes}
                                intl={intl}
                                handleConfirmationUpdate={handleConfirmationUpdate}
                            />
                        </Grid>

                        <Grid item md={5}>
                            {/*Stackable action section*/}
                            {isStackable && (
                                <ConfirmationStackableActionsSection
                                    workflowCurrentPosition={confirmationSelected?.currentStep?.position}
                                    stackableSteps={stackableSteps}
                                    handleSelection={selectStackableStep}
                                />
                            )}

                            {/*Comments section*/}
                            <Card>
                                <CardBody>
                                    <CardHeaderTitle>
                                        <FormattedMessage id="CONFIRMATION.SUBSECTION.COMMENTS" />
                                    </CardHeaderTitle>

                                    <div className="text-center">
                                        <Comment {...props} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Grid>
                    </Grid>

                    {/*Dialogs*/}
                    {openRefuse && (
                        <UIDialog
                            {...props}
                            open={openRefuse}
                            titleId="MESSAGE.REFUSE.WORKFLOW.STEP.TITLE"
                            onSave={handleRefuseClick}
                            onClose={handleRefusePopUpOpening}
                            children={
                                // TODO: review the method to select the document to refuse
                                <RefuseMessageComponent
                                    {...props}
                                    user={user}
                                    document={getDocumentToReset}
                                    messageTitle="MESSAGE.REFUSE.WORKFLOW.STEP.CONTENT"
                                    messageComment="MESSAGE.REFUSE.WORKFLOW.STEP.COMMENT"
                                    handleSubmit={handleRefuse}
                                    btnRefuseRef={btnRefuseRef}
                                />
                            }
                        />
                    )}
                    {openReset && (
                        <UIDialog
                            {...props}
                            open={openReset}
                            titleId="MESSAGE.RESET.WORKFLOW.STEP.TITLE"
                            onSave={() => handleResetClick()}
                            onClose={handleResetPopUpOpening}
                            children={
                                <ResetMessageComponent
                                    {...props}
                                    user={user}
                                    itemErrors={itemErrors}
                                    document={getDocumentToReset}
                                    handleSubmit={handleReset}
                                    btnResetRef={btnResetRef}
                                />
                            }
                        />
                    )}
                    <DeleteConfirmationComponent confirmationId={confirmationSelected?.id} />
                </ConfirmationContainer>
            </CardBody>
        </Card>
    );
}

ConfirmationComponent.propTypes = {
    confirmation: PropTypes.object,
    paginatedConfirmationItems: PropTypes.object,
    documentTypes: PropTypes.object,
    languages: PropTypes.array,
    updateConfirmation: PropTypes.func.isRequired,
    processStep: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

const styles = {
    container: {
        width: '100%',
        justifyContent: 'space-evenly',
        display: 'flex',
        alignItems: 'stretch',
        alignContent: 'stretch'
    }
};
