import { FileExtension } from '../../kit/utils/constants';
import { UITooltip } from '../common/UITooltip';
import { useIntl } from 'react-intl';
import { FolderOpenRounded, MailRounded, HourglassFullRounded, MarkunreadMailboxRounded } from '@mui/icons-material';
import { Palette } from '../../styles/colors';

export const DocumentTableStatusIcon = ({ item }) => {
    const intl = useIntl();

    return (
        <div style={{ textAlign: 'center' }}>
            {item.fileExtension.toLowerCase() === FileExtension.DIR ? (
                <UITooltip
                    title={intl.formatMessage({
                        id: 'DOCUMENT.TABLE.PROCESSING.TOOLTIP.FOLDER'
                    })}
                    children={<FolderOpenRounded color="primary" />}
                />
            ) : item.fileExtension.toLowerCase() === FileExtension.EML ||
              item.fileExtension.toLowerCase() === FileExtension.MSG ? (
                <MailRounded color="primary" />
            ) : !item.processedByRobot ? (
                <UITooltip
                    title={intl.formatMessage({
                        id: 'DOCUMENT.TABLE.PROCESSING.TOOLTIP.PENDING'
                    })}
                    children={<HourglassFullRounded color="primary" />}
                />
            ) : (
                <UITooltip
                    title={intl.formatMessage({
                        id: 'DOCUMENT.TABLE.PROCESSING.TOOLTIP.CHECKED'
                    })}
                    children={<MarkunreadMailboxRounded sx={{ color: Palette.red }} />}
                />
            )}
        </div>
    );
};
