import React, { useLayoutEffect } from 'react';
import { KTUtil } from '../assets/js/components/util';
import KTLayoutHeader from '../assets/js/layout/base/header';
import KTLayoutHeaderMenu from '../assets/js/layout/base/header-menu';
import KTLayoutHeaderTopbar from '../assets/js/layout/base/header-topbar';
import KTLayoutContent from '../assets/js/layout/base/content';
import KTLayoutFooter from '../assets/js/layout/base/footer';
import KTLayoutSubheader from '../assets/js/layout/base/subheader';
import KTLayoutScrolltop from '../assets/js/layout/extended/scrolltop';
import KTLayoutStickyCard from '../assets/js/layout/base/sticky-card';
import KTLayoutStretchedCard from '../assets/js/layout/base/stretched-card';

export function LayoutInit() {
    useLayoutEffect(() => {
        // Initialization
        KTUtil.ready(function () {
            ////////////////////////////////////////////////////
            // Layout Base Partials(mandatory for core layout)//
            ////////////////////////////////////////////////////
            // Init Desktop & Mobile Headers
            KTLayoutHeader.init('mzr_header', 'mzr_header_mobile');

            // Init Header Menu
            KTLayoutHeaderMenu.init('mzr_header_menu', 'mzr_header_menu_wrapper');
            // Init Header Topbar For Mobile Mode
            KTLayoutHeaderTopbar.init('mzr_header_mobile_topbar_toggle');

            // Init subheader
            KTLayoutSubheader.init('mzr_subheader');

            // Init Content
            KTLayoutContent.init('mzr_content');

            // Init Footer
            KTLayoutFooter.init('mzr_footer');

            //////////////////////////////////////////////
            // Layout Extended Partials(optional to use)//
            //////////////////////////////////////////////

            // Init Scrolltop
            KTLayoutScrolltop.init('mzr_scrolltop');

            // Init Sticky Card
            KTLayoutStickyCard.init('mzr_page_sticky_card');

            // Init Stretched Card
            KTLayoutStretchedCard.init('mzr_page_stretched_card');
        });
    }, []);
    return <></>;
}
