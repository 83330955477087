import { parseAppUser } from '../user/userParsers';

export const parseItem = json => {
    return json
        ? {
              id: json.id,
              name: json.name,
              description: json.description,
              fileExtension: json.fileExtension,
              languageId: json.languageId,
              clusterId: json.clusterId,
              type: json.type,
              errorType: json.errorType ?? 0,
              comment: json.comment ?? '',
              documentType: json.documentType,
              emitter: json.emitter,
              source: json.source,
              blocked: json.blocked,
              processedByRobot: json.processedByRobot,
              processedBySupport: json.processedBySupport,
              numberOfGetFile: json.numberOfGetFile,
              paperSendingStatus: json.paperSendingStatus,
              workflowTrack: json.workflowTrack,
              created: json.created,
              updated: json.updated,
              creatorId: json.creatorId,
              creator: json.creator ? parseAppUser(json.creator) : {},
              updatorId: json.updatorId,
              updator: json.updator ? parseAppUser(json.updator) : {},
              engagementId: json.engagementId ?? null,
              confirmationId: json.confirmationId ?? null,
              parentItemId: json.parentItemId,
              childItems: json.childItems ? parseItems(json.childItems) : [],
              isChildItem: json.isChildItem,
              isLost: json.isLost,
              isDeletable: json.isDeletable,
              isTrashed: json.isTrashed,
              isInvalidAddress: json.isInvalidAddress,
              isInvalidAddressDate: json.isInvalidAddressDate,
          }
        : {};
};

export const parseItems = json => {
    return json ? Object.values(json).map(x => parseItem(x)) : [];
};
