import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { readableDate } from '../kit/utils/DateUtil';

export const TagDataType = {
    STRING: 'string',
    DATETIME: 'datetime',
    EMAIL: 'email',
    BOOLEAN: 'boolean',
    DOUBLE: 'double'
};

const TagFormField = {
    [TagDataType.STRING]: 'TextFieldItem',
    [TagDataType.DATETIME]: 'DateFieldItem',
    [TagDataType.EMAIL]: 'TextFieldItem',
    [TagDataType.BOOLEAN]: 'SwitchFieldItem',
    [TagDataType.DOUBLE]: 'TextFieldItem'
};

const TagYUPValidationType = {
    [TagDataType.STRING]: 'string',
    [TagDataType.DATETIME]: 'date',
    [TagDataType.EMAIL]: 'string',
    [TagDataType.BOOLEAN]: 'bool',
    [TagDataType.DOUBLE]: 'number'
};

// TODO: Require a code review, there is an issue with intl. The value tag.id is not a translation key
export const mapTagsToFields = (tags, options = {}) =>
    tags?.map(tag => {
        const formField = TagFormField[tag.dataType?.toLocaleLowerCase()] ?? TagFormField[TagDataType.STRING];
        return {
            itemKey: tag.id,
            labelItem: { id: tag.name, isRequired: true }, // all extra fields are required
            [formField]: { id: tag.id, ...options }
        };
    });

export const handleSubmitWithTags = (values, optionalFields, submit) => {
    let updatedValues = { ...values };
    // first empty the optionalFields in order not to have duplicates (case of confirmation edition)
    updatedValues.optionalFields = [];
    for (const tag of optionalFields) {
        let value = updatedValues[tag.id];
        value = value && tag.dataType.toLocaleLowerCase() === TagDataType.DATETIME ? readableDate(value) : value;

        updatedValues = {
            ...updatedValues,
            optionalFields: [
                ...updatedValues.optionalFields,
                {
                    ...tag,
                    value: value
                }
            ]
        };
    }

    submit(updatedValues);
};

export const tagsArrayToObject = tags => {
    const initialValue = {};
    return tags?.reduce((previous, currentField) => {
        return {
            ...previous,
            [currentField.id]: currentField.value
        };
    }, initialValue);
};

export const getYupValidators = extraFields => {
    const initialValue = {};
    const extraFieldsValidator = extraFields?.reduce((previous, currentField) => {
        const yupType = TagYUPValidationType[currentField.dataType.toLocaleLowerCase()];
        return {
            ...previous,
            [currentField.id]: yupType
                ? Yup[yupType]().required(
                      <FormattedMessage
                          id="SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.EXTRA_FIELDS_ITEM"
                          values={{ fieldName: currentField.name }}
                      />
                  )
                : null
        };
    }, initialValue);

    return extraFieldsValidator;
};

export const sort = tags => {
    const pattern = (tag1, tag2) => (tag1?.eTag < tag2?.eTag ? -1 : 1);
    return tags?.sort((tag1, tag2) => (tag1?.eTag !== tag2?.eTag ? pattern(tag1, tag2) : 0));
};
