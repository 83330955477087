import React from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Comments } from './Comment';
import { UIActionButton } from '../../../../common/UIActionButton';
import { getLocalizedString } from '../../../../../i18n/i18Helper';

/**
 * Comment section component with an area to write comments and a reading area with the list of comments.
 * @param {*} props
 */
export function CommentWrapper(props) {
    const { intl, confirmationId, postComments } = props;

    const initComment = {
        confirmationId: confirmationId,
        text: '',
        isPrivate: true
    };

    // Validation schema
    const CommentEditSchema = Yup.object().shape({
        text: Yup.string()
            .min(2, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 2 }))
            .required(getLocalizedString(intl, 'COMMENT.POST.VALIDATION.REQUIRED_FIELD.TEXT')),
        isPrivate: Yup.boolean()
    });

    const handleSubmit = (values, { resetForm }) => {
        postComments(values);
        resetForm({});
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initComment}
                validationSchema={CommentEditSchema}
                onSubmit={handleSubmit}>
                <Form className="form form-label-right">
                    <div className="form-group">
                        <Field
                            key="text"
                            name="text"
                            placeholder={intl.formatMessage({ id: 'COMMENT.PLACEHOLDER.COMMENT' })}
                            as="textarea"
                            className="form-control"
                        />
                        <ErrorMessage name="text" />
                    </div>

                    <UIActionButton
                        type="submit"
                        fullWidth
                        title={getLocalizedString(intl, 'COMMENT.BUTTON.ADD')} />
                </Form>
            </Formik>

            <Comments {...props} />
        </>
    );
}
