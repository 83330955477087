import React, { Component } from 'react';
import {
    createEngagement,
    deleteEngagement,
    downloadFiles,
    toggleEngagementArchiveStatus,
    updateEngagement
} from '../services';
import { ENGAGEMENT_DOWNLOAD_REPORT } from '../components/engagement/InitEngagement';
import { saveDownloadedFile } from '../helpers/ItemHelper';

export const withEngagement = WrappedComponent => {
    return class extends Component {
        getDownloadEngagementOptions = (engagement, handleDownloadEngagement) => {
            const { intl } = this.props;

            return [
                {
                    id: 'clientRequests',
                    title: intl.formatMessage({ id: 'ENGAGEMENT.DOWNLOAD.OPTION.CLIENT_REQUEST_LETTERS' }),
                    handler: () =>
                        handleDownloadEngagement(engagement, ENGAGEMENT_DOWNLOAD_REPORT.CLIENT_REQUESTS_LETTERS)
                },
                {
                    id: 'files',
                    title: intl.formatMessage({ id: 'ENGAGEMENT.DOWNLOAD.OPTION.FILES' }),
                    handler: () => handleDownloadEngagement(engagement, ENGAGEMENT_DOWNLOAD_REPORT.FILES)
                },
                {
                    id: 'reporting',
                    title: intl.formatMessage({ id: 'ENGAGEMENT.DOWNLOAD.OPTION.REPORTING' }),
                    handler: () => handleDownloadEngagement(engagement, ENGAGEMENT_DOWNLOAD_REPORT.REPORTING)
                }
            ];
        };

        performCreateEngagement = async ({ engagement, setLoading, successCallback }) => {
            const { intl, checkSession, addToastMessage } = this.props;

            let result;
            await createEngagement({
                setLoading: setLoading,
                engagement: engagement,
                checkSession: checkSession,
                addToastMessage: addToastMessage,
                successMessage: intl.formatMessage({
                    id: 'CREATE_ENGAGEMENT.NEW_ENGAGEMENT.SUCCESS'
                }),
                errorMessage: intl.formatMessage({
                    id: 'CREATE_ENGAGEMENT.NEW_ENGAGEMENT.ERROR'
                }),
                callback: async (isSuccess, data) => {
                    result = data;
                    successCallback && isSuccess && (await successCallback(data));
                }
            });

            return result;
        };

        performDeleteEngagement = async ({ engagementInfo, setLoading, successCallback }) => {
            const { intl, checkSession, addToastMessage } = this.props;

            let result;
            await deleteEngagement({
                setLoading: setLoading,
                engagementInfo: engagementInfo,
                checkSession: checkSession,
                addToastMessage: addToastMessage,
                successMessage: intl.formatMessage({
                    id: 'FETCH.ENGAGEMENT.DELETE.SUCCESS'
                }),
                errorMessage: intl.formatMessage({
                    id: 'FETCH.ENGAGEMENT.DELETE.ERROR'
                }),
                callback: async (isSuccess, data) => {
                    result = data;
                    successCallback && isSuccess && (await successCallback(data));
                }
            });

            return result;
        };

        performUpdateEngagement = async ({ engagement, setLoading, successCallback, strings }) => {
            const { intl, checkSession, addToastMessage } = this.props;
            let result;
            await updateEngagement({
                engagement: engagement,
                checkSession: checkSession,
                addToastMessage: addToastMessage,
                successMessage: strings.successMessageId ? intl.formatMessage({ id: strings.successMessageId }) : null,
                errorMessage: intl.formatMessage({ id: strings.errorMessageId }),
                setLoading: setLoading,
                callback: async (isSuccess, data) => {
                    result = data;
                    isSuccess && successCallback && successCallback(data);
                }
            });

            return result;
        };

        performToggleArchiveEngagement = async ({ engagementId, setLoading, successCallback, strings }) => {
            const { intl, checkSession, addToastMessage } = this.props;
            let result;
            await toggleEngagementArchiveStatus({
                engagementId: engagementId,
                checkSession: checkSession,
                addToastMessage: addToastMessage,
                successMessage: strings.successMessageId ? intl.formatMessage({ id: strings.successMessageId }) : null,
                errorMessage: intl.formatMessage({ id: strings.errorMessageId }),
                setLoading: setLoading,
                callback: async (isSuccess, data) => {
                    result = data;
                    isSuccess && successCallback && successCallback(data);
                }
            });

            return result;
        };

        performDownloadEngagement = async ({
            engagementId,
            engagementName,
            downloadType,
            setLoading,
            successCallback
        }) => {
            const { intl, checkSession, addToastMessage } = this.props;
            let result;
            await downloadFiles({
                downloadType: downloadType,
                engagementId: engagementId,
                checkSession: checkSession,
                addToastMessage: addToastMessage,
                errorMessage: intl.formatMessage({
                    id: 'FETCH.ENGAGEMENT.DOWNLOAD.ERROR'
                }),
                setLoading: setLoading,
                callback: async (isSuccess, data) => {
                    result = data;
                    isSuccess &&
                        saveDownloadedFile(
                            data,
                            intl.formatMessage(
                                {
                                    id:
                                        downloadType === ENGAGEMENT_DOWNLOAD_REPORT.FILES
                                            ? 'FETCH.ENGAGEMENT.ALL.DOCUMENTS.FILE.NAME'
                                            : downloadType === ENGAGEMENT_DOWNLOAD_REPORT.REPORTING
                                            ? 'FETCH.ENGAGEMENT.REPORTING.FILE.NAME'
                                            : 'FETCH.ENGAGEMENT.DOWNLOAD.CLIENT.LETTERS.NAME'
                                },
                                { engagement: engagementName }
                            )
                        );

                    isSuccess && successCallback && successCallback(data);
                }
            });

            return result;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    performToggleArchiveEngagement={this.performToggleArchiveEngagement}
                    getDownloadEngagementOptions={this.getDownloadEngagementOptions}
                    performCreateEngagement={this.performCreateEngagement}
                    performDeleteEngagement={this.performDeleteEngagement}
                    performUpdateEngagement={this.performUpdateEngagement}
                    performDownloadEngagement={this.performDownloadEngagement}
                />
            );
        }
    };
};
