import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Grid, Avatar } from '@mui/material';
import { UITooltip } from '../../common/UITooltip';
import { CompanyDetails } from '../../company/CompanyDetails';
import { SearchCompanyWrapper } from '../../company/search/SearchCompanyWrapper';
import { MZRChip } from '../../common/MZRChip';
import { EditCompany, initCompany } from '../../company';
import { COMPANY_TYPE } from '../../../kit/utils/constants';
import { ClientCompanyContext } from '../../../context';
import { UIDialog } from '../../common/UIDialog';

/**
 * search and display client companies.
 *
 * @param {*} props
 * @returns
 */
export function EngagementClientComponent(props) {
    const { loading, classes, engagement, handleAddClient, handleUpdateClient, handleDeleteClient, disabled } = props;
    const { searchCompany } = useContext(ClientCompanyContext);
    const sbmtCompanyRef = useRef();

    const [clients, setClients] = useState(engagement?.clientCompanies);
    const [flagEditionCompany, setFlagEditionCompany] = useState(false);
    const [editCompany, setEditCompany] = useState({ ...initCompany, isClient: true });
    const [openCreateCompany, setOpenCreateCompany] = useState(false);

    useEffect(() => {
        setClients(engagement?.clientCompanies);
    }, [engagement]);

    /** Create a new client. */
    const createClientClick = () => {
        setEditCompany({ ...initCompany, isClient: true });
        setFlagEditionCompany(false);
        setOpenCreateCompany(!openCreateCompany);
    };

    const handleEditCompany = company => {
        setEditCompany(company);
        setFlagEditionCompany(true);
        setOpenCreateCompany(!openCreateCompany);
    };

    const handleCompanyPopUpOpening = () => setOpenCreateCompany(!openCreateCompany);

    const handleClose = () => {
        if (sbmtCompanyRef && sbmtCompanyRef.current) {
            sbmtCompanyRef.current.saveForm();
        }
    };

    const handleCompanySubmit = values => {
        flagEditionCompany ? handleUpdateClient(values) : handleAddClient(values);
        setOpenCreateCompany(!openCreateCompany);
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={6}>
                <Grid container justifyContent="center" alignItems="center">
                    {React.Children.toArray(
                        clients?.map(client => {
                            return (
                                <div className="form-group">
                                    <UITooltip describeChild title={<CompanyDetails company={client} />}>
                                        <Box sx={classes.margin}>
                                            <MZRChip
                                                disabled={disabled}
                                                editable={true}
                                                onEdit={() => handleEditCompany(client)}
                                                onDelete={() => handleDeleteClient(client)}
                                                key={`key_chip_company_client_${client.id}`}
                                                variant="outlined"
                                                color="primary"
                                                label={client.name}
                                                avatar={
                                                    <Avatar>
                                                        {String(client.name)
                                                            .split(' ')
                                                            .map(x => x.substring(0, 1))
                                                            .slice(0, 2)}
                                                    </Avatar>
                                                }
                                            />
                                        </Box>
                                    </UITooltip>
                                </div>
                            );
                        })
                    )}
                </Grid>
                {!disabled && (
                    <Grid item>
                        <SearchCompanyWrapper
                            loading={loading}
                            handleSearchCompany={searchCompany}
                            handleSelectCompany={handleAddClient}
                            createCompanyClick={createClientClick}
                        />
                    </Grid>
                )}
            </Grid>

            {openCreateCompany && (
                <UIDialog
                    open={openCreateCompany}
                    titleId={
                        editCompany?.id
                            ? 'CREATE_CLIENT.SECTION.CONTACT.BUTTON.EDIT_COMPANY'
                            : 'CREATE_CLIENT.SECTION.CONTACT.BUTTON.NEW_COMPANY'
                    }
                    onSave={handleClose}
                    onClose={handleCompanyPopUpOpening}
                    saveTitleId="DIALOG.BUTTON.VALIDATE"
                    children={
                        <EditCompany
                            companyType={COMPANY_TYPE.CLIENT}
                            variant="outlined"
                            company={editCompany}
                            handleSubmit={handleCompanySubmit}
                            sbmtCompanyRef={sbmtCompanyRef}
                        />
                    }
                />
            )}
        </>
    );
}
