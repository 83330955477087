import React, { useRef, useState } from 'react';
import { DeleteMessageComponent } from '../components/message';
import { usePrevious } from './usePrevious';
import { ArchiveMessageComponent } from '../components/engagement';
import { ClientRefuseRequestComponent } from '../components/clientRequest';
import { UIDialog } from '../components/common/UIDialog';

function useEntityActionPopup({ user, actionAPI, callback }) {
    const [isOpen, setIsOpen] = useState(false);
    const prevIsOpen = usePrevious(isOpen);
    const btnRef = useRef();
    const closePopup = close => setIsOpen(!close);
    const handleActionClick = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.saveForm();
        }
    };

    const handleSubmit = values => {
        (async () => {
            const result = await actionAPI(values);
            closePopup(result?.isSuccess);
            callback && callback(result?.isSuccess);
        })();
    };

    return {
        handleValidateClick: () => {
            setIsOpen(!isOpen);
        },
        entityActionDialog: ({ identifier, actionContentComponent: ActionComponent, strings, data }) => {
            const shouldRerenderDelete = isOpen && prevIsOpen !== isOpen;
            return shouldRerenderDelete ? (
                <UIDialog
                    open={isOpen}
                    titleId={strings.labelId}
                    onSave={handleActionClick}
                    onClose={() => closePopup(true)}
                    children={
                        <ActionComponent
                            strings={strings}
                            identifier={identifier}
                            data={data}
                            user={user}
                            handleSubmit={handleSubmit}
                            btnActionRef={btnRef}
                        />
                    }
                />
            ) : null;
        }
    };
}

export function useDeleteEngagement({ user, deleteAPI, deleteEngagementCallback }) {
    const { handleValidateClick, entityActionDialog } = useEntityActionPopup({
        user: user,
        callback: deleteEngagementCallback,
        actionAPI: deleteAPI
    });

    return {
        handleDeleteClick: handleValidateClick,
        delete: ({ engagementId }) => {
            return entityActionDialog({
                identifier: { key: 'id', value: engagementId },
                actionContentComponent: DeleteMessageComponent,
                strings: {
                    labelId: 'MESSAGE.DELETE.ENGAGEMENT.TITLE',
                    contentId: 'MESSAGE.DELETE.ENGAGEMENT.CONTENT',
                    commentId: 'MESSAGE.DELETE.ENGAGEMENT.COMMENT'
                }
            });
        }
    };
}

export function useDeleteConfirmation({ user, deleteAPI, deleteConfirmationCallback, warningMessageId }) {
    const { handleValidateClick, entityActionDialog } = useEntityActionPopup({
        user: user,
        callback: deleteConfirmationCallback,
        actionAPI: deleteAPI
    });

    return {
        handleDeleteClick: handleValidateClick,
        delete: ({ confirmationId, confirmation = {} }) => {
            return entityActionDialog({
                identifier: { key: 'id', value: confirmationId },
                data: confirmation,
                actionContentComponent: DeleteMessageComponent,
                strings: {
                    labelId: 'MESSAGE.DELETE.CONFIRMATION.TITLE',
                    contentId: warningMessageId,
                    commentId: 'MESSAGE.DELETE.CONFIRMATION.COMMENT'
                }
            });
        }
    };
}

export function useDeleteItem({ user, deleteAPI, deleteItemCallback }) {
    const { handleValidateClick, entityActionDialog } = useEntityActionPopup({
        user: user,
        callback: deleteItemCallback,
        actionAPI: deleteAPI
    });

    return {
        handleDeleteClick: handleValidateClick,
        delete: ({ itemId, item = {} }) => {
            return entityActionDialog({
                identifier: { key: 'id', value: itemId },
                data: item,
                actionContentComponent: DeleteMessageComponent,
                strings: {
                    labelId: 'MESSAGE.DELETE.ITEM.TITLE',
                    contentId: 'MESSAGE.DELETE.ITEM.CONTENT',
                    commentId: 'MESSAGE.DELETE.ITEM.COMMENT'
                }
            });
        }
    };
}

export function useArchiveEngagement({ user, archiveAPI, archiveEngagementCallback }) {
    const { handleValidateClick, entityActionDialog } = useEntityActionPopup({
        user: user,
        callback: archiveEngagementCallback,
        actionAPI: archiveAPI
    });

    return {
        handleArchiveClick: handleValidateClick,
        archive: ({ engagement }) => {
            return entityActionDialog({
                data: { ...engagement },
                strings: {
                    labelId: engagement?.archived
                        ? 'MESSAGE.UNARCHIVE.ENGAGEMENT.TITLE'
                        : 'MESSAGE.ARCHIVE.ENGAGEMENT.TITLE',
                    contentId: engagement?.archived
                        ? 'MESSAGE.UNARCHIVE.ENGAGEMENT.CONTENT'
                        : 'MESSAGE.ARCHIVE.ENGAGEMENT.CONTENT'
                },
                actionContentComponent: engagement ? ArchiveMessageComponent : null
            });
        }
    };
}

export function useRefuseConfirmation({ user, refuseCallback, refuseAPI }) {
    const { handleValidateClick, entityActionDialog } = useEntityActionPopup({
        user: user,
        callback: refuseCallback,
        actionAPI: refuseAPI
    });

    return {
        handleRefuseClick: handleValidateClick,
        refuse: ({ request }) => {
            return entityActionDialog({
                data: request,
                strings: {
                    labelId: 'CLIENT.REFUSE.REQUEST.POPUP.TITLE'
                },
                actionContentComponent: ClientRefuseRequestComponent
            });
        }
    };
}
