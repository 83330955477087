export const documents = {
    'DOCUMENTS.LOST.DOCUMENTS.SUBSECTION.TITLE': '{numberDocuments} document(s) perdus',
    'DOCUMENTS.LOST.DOCUMENTS.SUBSECTION.TOOLTIP': 
        'Vous trouverez ici la liste des documents qui n\'ont pas été affectés à une mission/circularisation en raison de code manquant, erroné ou mal lu.',
    'DOCUMENTS.PENDING.SUBSECTION.TITLE': '{numberDocuments} Document(s) en attentes',
    'DOCUMENTS.PENDING.SUBSECTION.TOOLTIP':
        'Vous trouverez ici la liste des documents que vous devez affecter à une circularisation et/ou sélectionner le type de document.',
    'DOCUMENT.TABLE.TITLE.ROBOT': 'Status',
    'DOCUMENT.TABLE.PROCESSING.TOOLTIP.FOLDER': 'Dossier',
    'DOCUMENT.TABLE.PROCESSING.TOOLTIP.PENDING': 'En attente de traitement du robot',
    'DOCUMENT.TABLE.PROCESSING.TOOLTIP.CHECKED': 'Vérifié par robot',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.REMOVE': 'Retirer le document',
    'DOCUMENT.TABLE.TITLE.TYPE': 'Type de document',
    'DOCUMENT.TABLE.TITLE.DOCUMENT_TYPE': 'Type',
    'DOCUMENT.TABLE.TITLE.INVALID.ADDRESS': 'Adresse invalide (NPAI)',
    'DOCUMENT.TABLE.TITLE.CREATED': 'Créé',
    'DOCUMENT.TABLE.TITLE.UPDATED': 'Dernière mise à jour',
    'DOCUMENT.TABLE.TITLE.NAME': 'Nom',
    'DOCUMENT.TABLE.TITLE.EXTENSION': 'Extension',
    'DOCUMENT.TABLE.TITLE.COMPANY': 'Entreprise',
    'DOCUMENT.TABLE.TITLE.STATUS': 'Status',
    'DOCUMENT.TABLE.TITLE.ENGAGEMENT': 'Mission',
    'DOCUMENT.TABLE.TITLE.CONFIRMATION': 'Circularisation',
    'DOCUMENT.TABLE.TITLE.DOWNLOADED': 'Fichier téléchargé',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.OPEN': 'Visualiser',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.VALIDATE': 'Valider',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.LOST':
        'Envoyer le document dans les documents perdus s\'il ne peut être affecté à une circularisation.',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.REFUSE':
        'Le document ne concerne pas ma mission. Renvoyer au support.',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.DOWNLOAD': 'Télécharger',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.SIGN': 'Signer',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.PRINT': 'Imprimer',
    'DOCUMENT.TABLE.STATUS.TOOLTIP.PENDING': 'En attente - Document en attente d\'impression et d\'envoi par le support.',
    'DOCUMENT.TABLE.STATUS.TOOLTIP.INPROGRESS':
        'En cours - Le document est imprimé et l\'envoi est en cours de traitement par le service d\'assistance.',
    'DOCUMENT.TABLE.STATUS.TOOLTIP.DONE': 'Done - Document inprimé et posté par le support',
    'DOCUMENT.TABLE.STATUS.TOOLTIP.NONE': 'Aucun',
    'DOCUMENT.INFORMATION.LABEL.NAME': 'Nom',
    'DOCUMENT.INFORMATION.VALIDATION.REQUIRED_FIELD.NAME': 'Le nom du document est requis',
    'DOCUMENT.INFORMATION.LABEL.DESCRIPTION': 'Description',
    'DOCUMENT.INFORMATION.LABEL.DOCUMENT_TYPE': 'type de document',
    'DOCUMENT.INFORMATION.LABEL.ENGAGEMENT': 'Mission',
    'DOCUMENT.INFORMATION.LABEL.CONFIRMATION': 'Circularisation',
    'DOCUMENT.VIEWER.CARDHEADER.FILE.NAME.TITLE': 'Document {documentName}',
    'DOCUMENT.VIEWER.TITLE': 'Détails du document',
    'DOCUMENT.VIEWER.BUTTON.BACK.LABEL': 'Retour',
    'DOCUMENT.VIEWER.BUTTON.DOWNLOAD.LABEL': 'Télécharger',
    'DOCUMENT.SELECT.CONFIRMATION.SEARCH_BAR.PLACEHOLDER': 'Recherche par code',
    'DOCUMENT.SELECT.ENGAGEMENT.SEARCH_BAR.PLACEHOLDER': 'Recherche par Nom, Code, Entreprise',
    'FETCH.DOCUMENT.UPDATE.SUCCESS': 'Document mis à jour',
    'FETCH.DOCUMENT.UPDATE.ERROR': 'Échec lors de l\'update du document',
    'ITEM.UPLOAD.DIRECTORY.NAME': 'Dossier',
    'ITEM.UPLOAD.DIRECTORY.AUDITOR.DESCRIPTION': 'Dossier uploadé par auditor',
    'ITEM.UPLOAD.DIRECTORY.SUPPORT.DESCRIPTION': 'Dossier uploadé par le support',
    'ITEM.UPLOAD.DOCUMENT.AUDITOR.DESCRIPTION': 'Document uploadé par auditor',
    'ITEM.UPLOAD.DOCUMENT.SUPPORT.DESCRIPTION': 'Document uploadé par le support',
    'ITEM.UPLOAD.TYPE.ERROR.MESSAGE': 'Le type de fichier n\'est pas valide',
    'ITEM.POPUP.INFORMATION.DATE.CREATED': 'Créé:',
    'ITEM.POPUP.INFORMATION.DATE.UPDATED': 'Mise à jour:',
    'ITEM.POPUP.INFORMATION.AUTHOR.BY': 'par {author}',
    'ITEM.POPUP.INFORMATION.INVALID.ADDRESS': 'NPAI: N\'habite pas à l\'adresse indiquée, reçu le {date}',
    'DOCUMENT.INFORMATION.INVALID.ADDRESS': 'Lettre {name} revenue avec la mention NPAI (N\'habite pas à l\'adresse indiquée), le {date}',
    'ITEM.FOLDER.NUMBER.OF.FILE.LABEL': '{filesNumber} fichier(s)',
    'ITEM.TRASH.INFO': 'Mettre le document à la corbeille',
    'ITEM.DOWNLOAD.INFO': 'Télécharger le document',
    'ITEM.DELETE.INFO': 'Supprimer le document',
    'ITEM.RESTORE.INFO': 'Restaurer le document',
    'DOCUMENT.ITEM.UPLOAD.SUCCESS': 'Document uploadé',
    'DOCUMENT.ITEM.UPLOAD.ERROR': 'Échec lors de l\'upload du document',
    'DOCUMENT.ITEM.UPDATE.SUCCESS': 'Document affecté',
    'DOCUMENT.ITEM.UPDATE.ERROR': 'Échec lors de l\'update du document',
    'DOCUMENT.ITEM.DELETE.SUCCESS': 'Document supprimé',
    'DOCUMENT.ITEM.DELETE.ERROR': 'Échec lors de la suppression du document',
    'DOCUMENT.FILE.ACCESS.ERROR': 'Échec lors de l\'accès au document',
    'DOCUMENT.INFO.ACCESS.ERROR': 'Échec lors de l\'accès aux informations du document',
    'DOCUMENT.ACCESS.DOCUMENTS.ERROR': 'Échec lors de l\'accès du document',
    'DOCUMENT.STATUSES.ACCESS.ERROR': 'Échec lors de l\'accès aux status des documents',
    'DOCUMENT.TYPES.ACCESS.ERROR': 'Échec lors de l\'accès aux types de documents',
    'DOCUMENT.ERRORS.ACCESS.ERROR': 'Échec lors de l\'accès à la liste des documents',
    'DOCUMENT.ERRORS.FILE_SIZE_EXCEEDED':
        'Le fichier {fileName} a été rejeté. Le fichier est trop volumineux. La limite de taille est {maxFileSize} MB.'
};
