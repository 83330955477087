import { CLIENT_REQUEST_STATUS } from '../../../types/types';
import { Close, HourglassEmpty, Minimize } from '@mui/icons-material';
import React from 'react';
import { SignatureIcon } from '../../../helpers';
import { Palette } from '../../../styles/colors';

export const ClientRequestStatusIcon = ({ status }: { status: CLIENT_REQUEST_STATUS }) => {
    switch (status) {
        case CLIENT_REQUEST_STATUS.NOT_STARTED:
            return <Minimize color="disabled" />;

        case CLIENT_REQUEST_STATUS.REFUSED:
            return <Close sx={{ color: Palette.red }} />;

        case CLIENT_REQUEST_STATUS.WAITING_AUDITOR_VALIDATION:
            return <HourglassEmpty color="primary" />;

        case CLIENT_REQUEST_STATUS.WAITING_SIGNATURE:
            return <img src={SignatureIcon} alt="sign" style={{ height: '20px', width: '20px' }} />;
        default:
            return null;
    }
};
