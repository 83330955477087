import { createContext } from 'react';

interface ItemContextSpec {
    fetchItems: (filters: Record<string, any>) => Promise<any>;
    fetchItemFile: (itemId: string, filters: Record<string, any>) => Promise<any>;
    fetchItemInfo: (itemId: string) => Promise<any>;
    uploadItems: (files: any[], isGrouped: boolean, initItem: any) => Promise<any>;
    updateItem: (item: any) => Promise<any>;
    deleteItem: (item: any) => Promise<any>;
    downloadItem: (item: any, fileName: string) => Promise<any>;
    printItem: (item: any) => Promise<any>;
    filterItems: (filters: Record<string, any>) => void;
}

export const ItemContext = createContext<ItemContextSpec>(undefined!);
