import React from 'react';
import { Grid, Select } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import { makeSelectOptions } from '../../helpers/FormHelper';
import { COLUMN_UNDEFINED_INDEX } from '../confirmation/import/InitImportedConfirmations';

const UploadPreviewTableData = ({ data, tableHeaderRowClass, columns }) => {
    return (
        <>
            {data.map((row, index) => (
                <tr key={index}>
                    <td key={index} className={tableHeaderRowClass} />

                    {columns.map(column => (
                        <td key={column.id}>{row[column.id - 1]}</td>
                    ))}
                </tr>
            ))}
        </>
    );
};

const UploadPreviewTable = ({
    style: { tableClassName, tableHeaderRowClass },
    groupedColumns,
    data,
    columnsHeaders,
    handleColumnChange
}) => {
    return (
        <Grid container item spacing={4}>
            {Object.keys(groupedColumns).map((sectionName, index) => {
                const columns = groupedColumns[sectionName].columns;

                // const subsections = groupedColumns[sectionName].subsections;
                const sectionColumnsNames = columnsHeaders[index];

                return (
                    <React.Fragment key={`key_display_column_section_${sectionName}`}>
                        <Grid item>
                            <label>{sectionName}</label>

                            <table className={tableClassName}>
                                <tbody>
                                    {sectionColumnsNames && (
                                        <tr>
                                            <th key={0} className={tableHeaderRowClass}>
                                                {''}
                                            </th>

                                            {Object.keys(sectionColumnsNames)?.map((column, index) => (
                                                <th
                                                    key={sectionColumnsNames[column].id}
                                                    className={
                                                        sectionColumnsNames[column].id === 0 ? tableHeaderRowClass : ''
                                                    }
                                                    style={{
                                                        textAlign: 'center',
                                                        color: 'blue',
                                                        fontWeight: '#3f51b5'
                                                    }}>
                                                    <>
                                                        {/*<div>{subsections[index]}</div>*/}

                                                        <div>
                                                            <FormattedMessage id={sectionColumnsNames[column].id} />
                                                        </div>

                                                        <Field
                                                            className=".tr"
                                                            style={{ width: '100%' }}
                                                            as={Select}
                                                            key={column}
                                                            id={column}
                                                            name={column}
                                                            onChange={handleColumnChange}>
                                                            {makeSelectOptions(columns)}
                                                        </Field>
                                                    </>
                                                </th>
                                            ))}
                                        </tr>
                                    )}

                                    {data?.length > 0 && (
                                        <UploadPreviewTableData
                                            data={data}
                                            columns={columns}
                                            tableHeaderRowClass={tableHeaderRowClass}
                                        />
                                    )}
                                </tbody>
                            </table>
                        </Grid>
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};

export const UploadFilePreview = ({ allColumns, columnsHeaders, groupedColumns, setFieldValue, uploadedRows = [] }) => {
    const handleColumnChange = event => {
        const { name, value: selectedColumn } = event.target;
        // Remove previous affectation of the column
        const isAlreadyAffected = Object.values(allColumns).find(column => column === selectedColumn);

        if (isAlreadyAffected) {
            const previousAffectation = Object.keys(allColumns).find(key => allColumns[key] === selectedColumn);
            setFieldValue(previousAffectation, COLUMN_UNDEFINED_INDEX);
        }

        setFieldValue(name, selectedColumn);
    };

    return (
        <Grid container direction="row">
            {UploadPreviewTable({
                data: uploadedRows,
                groupedColumns: groupedColumns,
                columnsHeaders: columnsHeaders,
                handleColumnChange: handleColumnChange,
                style: {
                    tableClassName: 'ExcelTable2007',
                    tableHeaderRowClass: 'heading'
                }
            })}
        </Grid>
    );
};
