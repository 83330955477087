import React, { useMemo } from 'react';
import List from '@mui/material/List';
import { CompanyLetterHeadListItem } from './CompanyLetterHeadListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { UIText } from '../common/UIText';
import { Palette } from '../../styles/colors';
import { getLocalizedString } from '../../i18n/i18Helper';
import { useIntl } from 'react-intl';

export interface LetterHeadProps {
    disabled: boolean;
    toggleActiveLetterHead: (letterhead: any) => Promise<void>;
    deleteLetterHead: (letterhead: any) => Promise<void>;
    createLetterHead: (letterhead: any) => void;
    displayDetails: (letterhead: any) => void;
}

export const CompaniesLetterHeaderContainer = ({
    companies,
    disabled,
    deleteLetterHead,
    createLetterHead,
    displayDetails,
    toggleActiveLetterHead
}: { companies: any } & LetterHeadProps) => {
    const intl = useIntl();
    const sortedCompanies = useMemo(
        () => (companies?.length > 0 ? companies.sort((a, b) => a.name.localeCompare(b.name)) : []),
        [companies]
    );

    return (
        <List>
            <>
                <ListItem
                    secondaryAction={
                        <UIText
                            sx={styles.header}
                            text={getLocalizedString(intl, 'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.ACTIONS')}
                        />
                    }>
                    <ListItemText
                        sx={{ width: '45%' }}
                        disableTypography
                        primary={
                            <UIText
                                sx={styles.header}
                                text={getLocalizedString(
                                    intl,
                                    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.ENTITIES'
                                )}
                            />
                        }
                    />
                    <ListItemText
                        disableTypography
                        primary={
                            <UIText
                                sx={styles.header}
                                text={getLocalizedString(intl, 'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.STATE')}
                            />
                        }
                    />
                </ListItem>

                <Divider sx={{ height: 1, backgroundColor: Palette.primaryTextColor }} />

                {sortedCompanies.map(company => (
                    <CompanyLetterHeadListItem
                        key={company.id}
                        company={company}
                        disabled={disabled}
                        deleteLetterHead={deleteLetterHead}
                        createLetterHead={createLetterHead}
                        displayDetails={displayDetails}
                        toggleActiveLetterHead={toggleActiveLetterHead}
                    />
                ))}
            </>
        </List>
    );
};

const styles = {
    header: {
        fontSize: 14,
        fontWeight: 600
    }
} as const;
