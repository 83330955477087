export const engagement = {
    'ENGAGEMENT.TITLE': 'Mission',
    'ENGAGEMENT.ERROR': 'Échec de chargement de la mission',
    'ENGAGEMENT.DOWNLOAD.OPTION.CLIENT_REQUEST_LETTERS': 'Lettres clients',
    'ENGAGEMENT.DOWNLOAD.OPTION.FILES': 'Tous les documents',
    'ENGAGEMENT.DOWNLOAD.OPTION.REPORTING': 'Rapport',
    'ENGAGEMENT.CARDHEADER.TITLE': 'Mission {name}',
    'ENGAGEMENT.CARDHEADER.RECYCLE.BIN.TITLE': 'Corbeille à missions {engagementName}',
    'ENGAGEMENT.BUTTON.BACK.LABEL': 'Retour',
    'ENGAGEMENT.BUTTON.RECYCLE.BIN.LABEL': 'Corbeille',
    'ENGAGEMENT.BUTTON.DELETE.LABEL': 'Supprimer',
    'ENGAGEMENT.BUTTON.ARCHIVE.LABEL': 'Archiver',
    'ENGAGEMENT.BUTTON.DOWNLOAD.LABEL': 'Télécharger',
    'ENGAGEMENT.BUTTON.SETTINGS.LABEL': 'Configurations',
    'ENGAGEMENT.BUTTON.NEW.LABEL': 'Nouvelle circularisation',
    'ENGAGEMENT.BUTTON.SIGN.LABEL': 'Signer',
    'ENGAGEMENT.CONFIRMATION.LOADING.ERROR': 'Error de chargement de la circularisation',
    'ENGAGEMENT.TABLE.ACTION.TOOLTIP.NEW_CONFIRMATION': 'Nouvelle circularisation',
    'ENGAGEMENT.TABLE.ACTION.TOOLTIP.SETTINGS': 'Configurations',
    'ENGAGEMENT.TABLE.FILTER.CLIENT.PLACEHOLDER': 'Entités',
    'ENGAGEMENT.TABLE.FILTER.THIRD_PARTY.PLACEHOLDER': 'Tiers',
    'ENGAGEMENT.TABLE.FILTER.CATEGORY.PLACEHOLDER': 'Catégorie',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.PLACEHOLDER': 'étape du workflow',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.PLACEHOLDER': 'Status',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.NOT_STARTED': 'Non commencé',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.IN_PROGRESS': 'En cours',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.VALIDATED': 'Validé',
    'ENGAGEMENT.TABLE.FILTER.CLEAR': 'Retirer les filtres',
    'ENGAGEMENT.TABLE.FILTER': 'Filter',
    'ENGAGEMENT.TABLE.TITLE.CONFIRMATION': 'Circularisation',
    'ENGAGEMENT.TABLE.TITLE.CODE': 'Code',
    'ENGAGEMENT.TABLE.TITLE.THIRDPARTY': 'Tiers',
    'ENGAGEMENT.TABLE.TITLE.CATEGORY': 'Categorie',
    'ENGAGEMENT.TABLE.TITLE.LETTERHEAD': 'Papier à en-tête',
    'ENGAGEMENT.TABLE.TITLE.UNKNOWN_CATEGORY': 'Inconnu',
    'ENGAGEMENT.TABLE.TITLE.CLIENT': 'Entité',
    'ENGAGEMENT.TABLE.PLACEHOLDER.THIRD_PARTY': 'Tiers non fourni',
    'ENGAGEMENT.TABLE.PLACEHOLDER.CLIENT': 'Entité non fournie',
    'ENGAGEMENT.TABLE.TITLE.LAST_UPDATE': 'Dernière mise à jour',
    'ENGAGEMENT.TABLE.TITLE.PROGRESS': 'Progression',
    'ENGAGEMENT.ADD.CLIENT.ERROR.MESSAGE': 'Entité déjà présente dans la mission',
    'ENGAGEMENT.ADD.CLIENT.CONTACT.ERROR.MESSAGE': 'Contact déjà existant pour l\'entreprise',
    'CREATE_ENGAGEMENT.TITLE': 'Créer une mission',
    'CREATE_ENGAGEMENT.STEP.INFORMATION.LABEL': 'Informations de la mission',
    'CREATE_ENGAGEMENT.STEP.CLIENTS.LABEL': "Sélectionner les entités de la mission",
    'CREATE_ENGAGEMENT.STEP.CONTACTS.LABEL': "Sélectionner les contacts des entités",
    'CREATE_ENGAGEMENT.STEP.AUDITORS.LABEL': "Ajout de l'équipe d'audit",
    'CREATE_ENGAGEMENT.STEP.OPTIONAL.INFO.LABEL': 'Options',
    'CREATE_ENGAGEMENT.BUTTON.BACK.LABEL': 'Retour',
    'CREATE_ENGAGEMENT.BUTTON.PREVIOUS.LABEL': 'Précédent',
    'CREATE_ENGAGEMENT.BUTTON.RESET.LABEL': 'Réinitialiser',
    'CREATE_ENGAGEMENT.BUTTON.SAVE.LABEL': 'Sauvegarder',
    'CREATE_ENGAGEMENT.BUTTON.NEXT.LABEL': 'Suivant',
    'CREATE_ENGAGEMENT.NEW_ENGAGEMENT.ERROR': 'Échec de création de la mission',
    'CREATE_ENGAGEMENT.NEW_ENGAGEMENT.SUCCESS': 'Mission crée',
    'EDIT.ENGAGEMENT.FIELD.NAME.LABEL': 'Nom de la mission',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.NAME': 'Le nom de la mission est requis',
    'EDIT.ENGAGEMENT.FIELD.ENGAGEMENT_CODE.LABEL': 'Code mission Forvis Mazars (engagement)',
    'EDIT.ENGAGEMENT.FIELD.ENGAGEMENT_CODE.TOOLTIP': 'Code de mission ou projet Forvis Mazars utilisé par le planning (Retain)',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE': 'Le code de la mission est requis',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE.FORMAT': 'Le format du code fourni n\'est pas valide',
    'EDIT.ENGAGEMENT.FIELD.ENGAGEMENT_CODE.DESCRIPTION.LABEL': 'Code mission: {customMessage}',
    'EDIT.ENGAGEMENT.FIELD.OPENING_DATE.LABEL': 'Date d\'ouverture',
    'EDIT.ENGAGEMENT.FIELD.OPENING_DATE.DESCRIPTION.LABEL':
        '<!> La modification de la date d\'ouverture entraîne automatiquement la mise à jour de la date de clôture en ajoutant 1 an.',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.OPENING_DATE': 'La date d\'ouverture est requise',
    'EDIT.ENGAGEMENT.FIELD.CLOSING_DATE.LABEL': 'Date de clôture',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.CLOSING_DATE': 'La date de clôture est requise',
    'EDIT.ENGAGEMENT.VALIDATION.CLOSING.DATE.AFTER.OPENING.DATE': 'La date de clôture doit être après la date d\'ouverture',
    'EDIT.ENGAGEMENT.FIELD.DESCRIPTION.LABEL': 'Description',
    'CONFIRMATION.ACCESS.COMMENTS.ERROR': 'Échec d\'accès aux commentaires de la demande circularisation',
    'CONFIRMATION.MOVE.SUCCESS': 'Circularisation déplacé à l\'épate suivante',
    'CONFIRMATION.MOVE.ERROR': 'Échec lors du passage de la circularisation à l\'étape suivante',
    'CONFIRMATION.STACKED_STEP.SUCCESS': 'Option sélectioné',
    'CONFIRMATION.STACKED_STEP.ERROR': 'Échec lors de la sélection de l\'option',
    'FETCH.ENGAGEMENT.USER.ACCESS.ERROR': 'Échec lors de l\'accès à la mission',
    'FETCH.ENGAGEMENT.LIST.ACCESS.ERROR': 'Échec lors de l\'accès à la liste des missions',
    'FETCH.ENGAGEMENT.MENU.ACCESS.ERROR': 'Échec lors de l\'accès à la liste simplifiée de missions',
    'FETCH.ENGAGEMENT.ITEMS.ACCESS.ERROR': 'Échec lors de l\'accès aux fichiers de la mission',
    'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR': 'Échec lors de l\'accès aux circularisations de la mission',
    'FETCH.ENGAGEMENT.ARCHIVE.SUCCESS': 'Mission archivée',
    'FETCH.ENGAGEMENT.UNARCHIVE.SUCCESS': 'Mission désarchivée',
    'FETCH.ENGAGEMENT.ARCHIVE.ERROR': 'Échec lors l\'archivage de la mission',
    'FETCH.ENGAGEMENT.DELETE.SUCCESS': 'Mission supprimée',
    'FETCH.ENGAGEMENT.DOWNLOAD.ERROR': 'Échec lors du téléchargement de la mission',
    'FETCH.ENGAGEMENT.DELETE.ERROR': 'Échec lors de la suppression de la mission',
    'FETCH.ENGAGEMENT.ALL.DOCUMENTS.FILE.NAME': 'engagement_{engagement}_documents.zip',
    'FETCH.ENGAGEMENT.REPORTING.FILE.NAME': 'engagement_{engagement}_reporting.xlsx',
    'FETCH.ENGAGEMENT.DOWNLOAD.CLIENT.LETTERS.NAME': 'engagement_{engagement}_client_letters.zip',
    'FETCH.CONFIRMATION.TYPE.ACCESS.ERROR': 'Échec lors de l\'accès aux catégories de circularisation',
    'SEARCH.TABLE.TITLE.NAME': 'Nom',
    'SEARCH.TABLE.TITLE.CODE': 'Code mission',
    'SEARCH.TABLE.DIALOG.TITLE': 'Recherche de mission',
    'CREATE.ENGAGEMENT.COPY.EXISTING': 'Copier une mission existante',
    'CREATE.ENGAGEMENT.CHECKBOX.COPY.LETTERHEADS.LABEL': 'Copier les papier à en-tête des entités',
    'CREATE.ENGAGEMENT.CHECKBOX.COPY.LETTERHEADS.TOOLTIP': 'Copier les papiers à en-tête des entités de la mission sélectionnée',
    'CREATE.ENGAGEMENT.CHECKBOX.COPY.THIRD_PARTY.LABEL': 'Copier les entreprises et contacts tiers',
    'CREATE.ENGAGEMENT.CHECKBOX.COPY.THIRD_PARTY.TOOLTIP':
        'Copier les entreprises et les contacts tiers de la mission sélectionnée',
    'CREATE.ENGAGEMENT.PERIOD.TOO.LARGE':
        "Les missions ne dépassent généralement pas {mois} mois. Veuillez vérifier les dates pour vous assurer qu'elles sont correctes.",
    'ENGAGEMENT.TABLE.PENDING.DOCUMENTS.INDICATOR.TOOLTIP':
        'Vous avez des documents en attente dans cette circularisation, veuillez les consulter ...',
    'ENGAGEMENT.TABLE.TITLE.PENDING.DOCUMENTS': 'Documents en attentes',
    'SETTINGS.ARCHIVE.ENGAGEMENT.ERROR': 'Échec lors de l\'archivage de la mission',
    'SETTINGS.UNARCHIVE.ENGAGEMENT.ERROR': 'Échec lors du désarchivage de la mission',
};
