import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { MZRSearchBar } from '../../common/MZRSearchBar';
import { MZRSelectConfirmationOption, MZRValueSelectedContainer } from '../../../helpers';
import { useIntl } from 'react-intl';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { Box } from '@mui/material';
import { getLocalizedString } from '../../../i18n/i18Helper';

const getOptionValue = (option: any) => {
    return `${option.name ?? ''} ${option.confirmationCode ?? ''}`.toLocaleLowerCase();
};

export const ConfirmationSelectField = ({
    onInputChange,
    options,
    name,
    onChange,
    hidden = false,
    disabled = false,
    ...props
}: any) => {
    const intl = useIntl();

    const {
        values: { engagementId, menuConfirmations },
        setFieldValue
    } = useFormikContext<{ confirmationId: string; engagementId: string; menuConfirmations: any[] }>();

    const handleDelete = () => setFieldValue(name, null);

    const handleChange = (selectedValue: any) => {
        setFieldValue(name, selectedValue.id);

        if (selectedValue.engagementId !== engagementId) {
            setFieldValue('engagementId', selectedValue.engagementId);
        }

        onChange && onChange(selectedValue);
    };

    // engagement is set in the engagement dropdown and we have its menuConfirmations:
    // don't trigger a remote search of confirmations, the user is probably searching locally in the options of the confirmations dropdown
    const handleInputChange = engagementId && menuConfirmations?.length > 0 ? null : onInputChange;

    return (
        <Box sx={{ ...(props?.sx || {}) }}>
            {!hidden && (
                <Field
                    as={MZRSearchBar}
                    fullWidth
                    id={props?.id ?? name}
                    name={name}
                    isAreaFocusVisible={false}
                    components={{
                        Option: MZRSelectConfirmationOption,
                        ValueContainer: MZRValueSelectedContainer
                    }}
                    disabled={disabled}
                    options={options}
                    onInputChange={handleInputChange}
                    onSelectionChange={handleChange}
                    isClearable={true}
                    onClear={handleDelete}
                    getOptionValue={getOptionValue}
                    placeholder={getLocalizedString(intl, 'DOCUMENT.SELECT.CONFIRMATION.SEARCH_BAR.PLACEHOLDER')}
                />
            )}
            <ErrorMessage name={name}>
                {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
            </ErrorMessage>
        </Box>
    );
};
