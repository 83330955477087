import { Component } from 'react';
import '../style.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import {
    fetchData,
    fetchCountryClients,
    updateGlobalClientCompany,
    downloadClientCompanyTemplateFile,
    uploadClientCompanyTemplateFile
} from '../../services';
import { ClientConfig } from '../../components/setup';
import { saveDownloadedFile } from '../../helpers/ItemHelper';

/**
 * Client setup screen.
 */
class GlobalClientSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: [],
            companiesFilters: {
                userprofile: this.props.user.profil,
                clusterId: this.props.currentClusterId,
                pageSize: 5,
                pageIndex: 0
            }
        };
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    updateCompany = async company => {
        const { intl, checkSession, addToastMessage } = this.props;
        let result;
        await updateGlobalClientCompany({
            company: company,
            checkSession: checkSession,
            addToastMessage: addToastMessage,
            successMessage: intl.formatMessage({ id: 'COMPANY.UPDATE.CLIENT.SUCCESS' }),
            errorMessage: intl.formatMessage({ id: 'COMPANY.UPDATE.CLIENT.ERROR' }),
            setLoading: this.setLoading,
            callback: async (isSuccess, data) => {
                isSuccess && (await fetchCountryClients(this, this.state.companiesFilters));
                result = data;
            }
        });

        this.setState({ loading: false });
        return result;
    };

    downloadFile = async templateParams => {
        const { intl, checkSession, addToastMessage } = this.props;
        let result;
        await downloadClientCompanyTemplateFile({
            templateParams: templateParams,
            checkSession: checkSession,
            addToastMessage: addToastMessage,
            errorMessage: intl.formatMessage({ id: 'COMPANY.DOWNLOAD.CLIENT.ERROR' }),
            setLoading: this.setLoading,
            callback: async (isSuccess, data) => {
                if (isSuccess) {
                    saveDownloadedFile(data, templateParams?.fileName);
                }

                result = data;
            }
        });

        this.setState({ loading: false });
        return result;
    };

    uploadFile = async companies => {
        const { intl, checkSession, addToastMessage } = this.props;
        let result;

        // TODO: param clusterId seems not working in API
        await uploadClientCompanyTemplateFile({
            companiesInfo: { ...companies, clusterId: this.props.currentClusterId },
            checkSession: checkSession,
            addToastMessage: addToastMessage,
            successMessage: intl.formatMessage({ id: 'COMPANY.UPLOAD.CLIENT.SUCCESS' }),
            errorMessage: intl.formatMessage({ id: 'COMPANY.UPLOAD.CLIENT.ERROR' }),
            setLoading: this.setLoading,
            callback: async (isSuccess, data) => {
                isSuccess && (await fetchCountryClients(this, this.state.companiesFilters));
                result = data;
            }
        });

        this.setState({ loading: false });
        return result;
    };

    async componentDidMount() {
        await fetchData(this, [() => fetchCountryClients(this, this.state.companiesFilters)]);
    }

    handleCompaniesFilterChange = values => {
        this.setState({ companiesFilters: { ...this.state.companiesFilters, ...values } });
    };

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.state.companiesFilters !== prevState.companiesFilters) {
            if (!this.state.loading)
                await fetchData(this, [() => fetchCountryClients(this, this.state.companiesFilters)]);
        }

        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            this.handleCompaniesFilterChange({ clusterId: this.props.currentClusterId });
        }
    }

    render() {
        return (
            <>
                <ClientConfig
                    clientCompaniesPaginated={this.state.clientCompaniesPaginated}
                    loading={this.state.loading}
                    updateCompany={this.updateCompany}
                    downloadFile={this.downloadFile}
                    uploadFile={this.uploadFile}
                    handleFilterChange={this.handleCompaniesFilterChange}
                />
            </>
        );
    }
}

GlobalClientSetupComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array,
    languages: PropTypes.array.isRequired
};

GlobalClientSetupComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        languages: state.cluster.languages,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const GlobalClientSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(GlobalClientSetupComponent));
