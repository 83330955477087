import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Formik } from 'formik';

import { TITLE_LIST } from '..';
import { EditClientContact } from './EditClientContact';
import { EditThirdPartyContact } from './EditThirdPartyContact';
import { CONTACT_TYPE } from '../../../types/types';

export const makeContactTitles = intl =>
    TITLE_LIST.map(title => ({ id: title.id, name: intl.formatMessage({ id: title.id }) }));

export function EditContactComponent(props) {
    const {
        companies,
        companyDisabled,
        contact,
        handleSubmit,
        intl,
        contactType = CONTACT_TYPE.CLIENT,
        restrictedEdition
    } = props;

    const contactForms = {
        [CONTACT_TYPE.CLIENT]: EditClientContact({ intl, companyDisabled, companies, style, restrictedEdition }),
        [CONTACT_TYPE.THIRD_PARTY]: EditThirdPartyContact({ intl, companyDisabled, companies, style })
    };

    const contactForm = contactForms[contactType];

    const buttonFormContactRef = useRef(null);
    const triggerButtonClick = () => buttonFormContactRef?.current?.click();
    props.sbmtContactRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    return (
        <>
            <div className="form form-label-right">
                <Formik
                    enableReinitialize={true}
                    initialValues={contact}
                    validationSchema={contactForm.yup}
                    onSubmit={values => {
                        handleSubmit({
                            ...values,
                            title: values.title ? intl.formatMessage({ id: values.title }) : ''
                        });
                    }}>
                    {({ errors, touched }) => (
                        <>
                            <Form>
                                {React.Children.toArray(contactForm.fields.map(row => row.render()))}

                                <button
                                    type="submit"
                                    style={{ display: 'none' }}
                                    onClick={triggerButtonClick}
                                    ref={buttonFormContactRef}
                                />
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
}

const style = {
    height: '100%'
};

export const EditContact = injectIntl(EditContactComponent);
