import React from 'react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { EditEntityRow } from './EditEntityRow';

export const EditClientCompany = ({ intl }) => {
    return {
        title: 'Client',
        yup: Yup.object().shape({
            internalId: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .nullable(),
            name: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.NAME" />),
            address: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.ADDRESS" />),

            zipCode: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.ZIPCODE" />),
            city: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.CITY" />),
            countryText: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.COUNTRY" />),
            companyId: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .nullable(),
            companyId2: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .nullable(),
            vatId: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .nullable()
        }),
        fields: [
            {
                render: () =>
                    EditEntityRow({
                        columns: [
                            { id: 'name', label: 'NAME', isRequired: true },
                            {
                                id: 'internalId',
                                label: 'INTERNALID'
                            }
                        ],
                        intl: intl
                    })
            },
            {
                render: () =>
                    EditEntityRow({
                        columns: [
                            { id: 'address', label: 'ADDRESS', isRequired: true },
                            {
                                id: 'zipCode',
                                label: 'ZIPCODE',
                                isRequired: true
                            }
                        ],
                        intl: intl
                    })
            },
            {
                render: () =>
                    EditEntityRow({
                        columns: [
                            { id: 'city', label: 'CITY', isRequired: true },
                            {
                                id: 'countryText',
                                label: 'COUNTRY',
                                isRequired: true
                            }
                        ],
                        intl: intl
                    })
            },
            {
                render: () =>
                    EditEntityRow({
                        columns: [
                            {
                                id: 'companyId',
                                label: 'COMPANY_ID_NUMBER',
                                className: 'col-lg-4'
                            },
                            {
                                id: 'companyId2',
                                label: 'COMPANY_ID_NUMBER2',
                                className: 'col-lg-4'
                            },
                            { id: 'vatId', label: 'VAT_ID_NUMBER', className: 'col-lg-4' }
                        ],
                        intl: intl
                    })
            }
        ]
    };
};
