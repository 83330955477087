import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { RobotConfigComponent, COMPONENT_NAME } from '../../components/setup';
import { RobotConfigurationService } from '../../services';
import { CircularProgress } from '@mui/material';

class RobotSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            robotConfig: {},
            checkSessionResults: []
        };
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    robotConfigManager = RobotConfigurationService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading,
        callback: async (isSuccess, data, _) => isSuccess && this.setState({ robotConfig: data?.parsedValue })
    });

    handleRobotConfig = async configuration => {
        const { hasValidConfig, setIsRobotExpanded } = this.props;
        const robotConfiguration = { ...configuration, clusterId: this.props.currentClusterId };

        const result = !!configuration?.id
            ? await this.robotConfigManager.update(robotConfiguration)
            : await this.robotConfigManager.create(robotConfiguration);

        if (result.isSuccess) {
            hasValidConfig(COMPONENT_NAME.ROBOT_SETUP, true);
            setIsRobotExpanded(false);
        } else {
            hasValidConfig(COMPONENT_NAME.ROBOT_SETUP, false);
        }
    }

    async componentDidMount() {
        await this.robotConfigManager.fetchCluster(this.props.currentClusterId);
    };

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            if (!this.state.loading)
                await this.robotConfigManager.fetchCluster(this.props.currentClusterId);
        }
    };

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div className="fullCenter">
                        <CircularProgress />
                    </div>
                ) : (
                    <RobotConfigComponent
                        robotRef={this.props.robotRef}
                        robotConfig={this.state.robotConfig}
                        handleRobotConfig={this.handleRobotConfig}
                        hasValidConfig={this.props.hasValidConfig}
                    />
                )}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const RobotSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(RobotSetupComponent));
