import { performAPIAction } from './BaseService';
import { IntlShape } from 'react-intl';
import { ServiceResult } from '../kit/network/ServiceResult';
import { mailingAuthProviderRepository } from '../kit/repository';

interface ServiceProps {
    entityType: any;
    intl: IntlShape;
    setLoading: (loading: boolean) => void;
    checkSession: (result: ServiceResult) => void;
    addToastMessage: (toast: any) => void;
    callback: (isSuccess: boolean, data: ServiceResult, entityType: any) => void;
}

export const MailingAuthProviderService = (props: ServiceProps) => {
    return {
        fetchCluster: clusterId =>
            performAPIAction({
                ...props,
                apiCall: mailingAuthProviderRepository.fetchByClusterId(clusterId),
                errorMessageId: 'MAILING.AUTH.PROVIDERS.ACCESS.ERROR',
            }),
        create: provider =>
            performAPIAction({
                ...props,
                apiCall: mailingAuthProviderRepository.create(provider),
                successMessageId: 'MAILING.AUTH.PROVIDERS.CREATE.SUCCESS',
                errorMessageId: 'MAILING.AUTH.PROVIDERS.CREATE.ERROR'
            }),
        update: provider =>
            performAPIAction({
                ...props,
                apiCall: mailingAuthProviderRepository.update(provider.id, provider),
                successMessageId: 'MAILING.AUTH.PROVIDERS.UPDATE.SUCCESS',
                errorMessageId: 'MAILING.AUTH.PROVIDERS.UPDATE.ERROR'
            }),

        delete: mailingAuthProviderId =>
            performAPIAction({
                ...props,
                apiCall: mailingAuthProviderRepository.delete(mailingAuthProviderId),
                successMessageId: 'MAILING.AUTH.PROVIDERS.DELETE.SUCCESS',
                errorMessageId: 'MAILING.AUTH.PROVIDERS.DELETE.ERROR'
            })
    };
};
