import { ContactAccess } from '../../types/types';
import { Typography } from '@mui/material';
import React from 'react';

// When using TypeScript 4.x and above
import type {} from '@mui/x-data-grid/themeAugmentation';
import { ContactAccessRightsMatrix } from './ContactAccessRightsMatrix';
import { useIntl } from 'react-intl';
import { NewlineText } from '../common/NewlineText';
import { getLocalizedString } from '../../i18n/i18Helper';

interface InviteContactProps {
    contact: any;
    handleConfirmInvitation: (accessRights: ContactAccess[]) => void;
}

export const InviteContact = ({ contact }: InviteContactProps) => {
    const intl = useIntl();

    return (
        <>
            <Typography>
                <NewlineText
                    text={getLocalizedString(intl, 'SETTINGS.SECTION.CONTACT.INVITE.MESSAGE', {
                        contact: `${contact.firstName ?? ''} ${contact.lastName ?? ''}`
                    })}
                />
            </Typography>
            <ContactAccessRightsMatrix accessRights={contact.accessRights} />
        </>
    );
};
