import React, { useState } from 'react';
import {
    EngagementInformationComponent,
    EngagementMemberComponent,
    EngagementClientComponent,
    EngagementContactComponent,
    EngagementThirdPartyComponent
} from '../components/engagement';
import { hasEditionRights } from '../services';
import { ENGAGEMENT_MEMBER_ROLE, ENGAGEMENT_STEPS, ENGAGEMENT_ACTIONS } from '../components/engagement/InitEngagement';
import { uuidv4 } from '../helpers/BaseHelper';
import { Alert } from '@mui/material';
import { FormattedErrorMessage } from '../components/errorMessage/ErrorMessage';
import { NewlineText } from '../components/common/NewlineText';
import { getExistingContactWithinCompany } from '../helpers/ContactHelper';
import { getExistingCompanyWithinEngagement } from '../helpers/CompanyHelper';
import { EngagementCompanyLetterHeads } from '../components/engagement/sections/EngagementCompanyLetterHeads';
import { EngagementSelectOptions } from '../components/engagement/sections/EngagementSelectOptions';
import { dateWithOffset } from '../kit/utils/DateUtil';

export function useEngagement({ intl, user }) {
    const [currentUser] = useState(user);
    const [errorMessage, setErrorMessage] = useState('');

    const getContactCompany = (engagement, contact) => {
        return engagement.clientCompanies?.filter(x => x.id === contact?.engagementCompanyId);
    };

    const updateEngagementCompany = (engagement, updatedCompany) => {
        const clientCompaniesUpdated = engagement.clientCompanies?.filter(
            currClient => currClient.id !== updatedCompany?.id
        );

        clientCompaniesUpdated.push(updatedCompany);
        return { ...engagement, clientCompanies: clientCompaniesUpdated };
    };

    /** Add member to the engagement */
    const addMember = (engagement, member) => {
        const foundMember = Object.values(engagement?.appUserEngagements).find(x => x.email === member.email);
        if (!foundMember) {
            const appUserUpdated = [...engagement?.appUserEngagements, member];
            return { ...engagement, appUserEngagements: appUserUpdated };
        }
        return engagement;
    };

    /** Change engagement's member's role  */
    const changeMemberRole = (engagement, memberEmail, isRoleManager, hasEditionRights) => {
        let updatedEngagement = { ...engagement };
        if (hasEditionRights) {
            const updatedMembers = Object.values(engagement?.appUserEngagements).map(member =>
                member.email === memberEmail
                    ? {
                          ...member,
                          engagementRole: isRoleManager ? ENGAGEMENT_MEMBER_ROLE.ADMIN : ENGAGEMENT_MEMBER_ROLE.STANDARD
                      }
                    : member
            );

            updatedEngagement = { ...updatedEngagement, appUserEngagements: updatedMembers };
        }

        return updatedEngagement;
    };

    /** Remove a member from engagement depending of user's rights */
    const removeMember = (engagement, member) => {
        const isAuthorizedToDeleteAdmin = hasEditionRights(engagement, currentUser);

        if (isAuthorizedToDeleteAdmin || member.engagementRole !== ENGAGEMENT_MEMBER_ROLE.ADMIN) {
            const updatedMembers = Object.values(engagement?.appUserEngagements).filter(
                currMember => currMember?.email !== member?.email
            );

            return { ...engagement, appUserEngagements: updatedMembers };
        } else {
            return { ...engagement };
        }
    };

    const addClient = (engagement, company) => {
        const foundCompany = getExistingCompanyWithinEngagement(engagement, company);
        if (!foundCompany) {
            // trick: use local temporary uuid to be able to select a company in contact dialog (need an id in the company select)
            return {
                ...engagement,
                clientCompanies: [...engagement.clientCompanies, { ...company, id: uuidv4(), isTemporaryId: true }]
            };
        } else {
            setErrorMessage('ENGAGEMENT.ADD.CLIENT.ERROR.MESSAGE');
            return engagement;
        }
    };

    const updateClient = (engagement, company) => {
        const clientCompaniesUpdated = engagement.clientCompanies?.filter(x => x?.id !== company?.id);
        return { ...engagement, clientCompanies: [...clientCompaniesUpdated, company] };
    };

    const deleteClient = (engagement, client) => {
        const updatedClients = engagement.clientCompanies?.filter(currClient => currClient?.id !== client.id);
        return { ...engagement, clientCompanies: updatedClients };
    };

    const addClientContact = (engagement, contact) => {
        const [contactCompany] = getContactCompany(engagement, contact);
        const [foundContact] = getExistingContactWithinCompany(contactCompany, contact);

        if (!foundContact) {
            const updatedContacts = contactCompany?.contacts.filter(currContact => currContact.email !== contact.email);
            updatedContacts.push(contact);

            const updatedCompany = { ...contactCompany, contacts: updatedContacts };
            return updateEngagementCompany(engagement, updatedCompany);
        } else {
            setErrorMessage('ENGAGEMENT.ADD.CLIENT.CONTACT.ERROR.MESSAGE');
            return engagement;
        }
    };

    const updateClientContact = (engagement, contact) => {
        const [contactCompany] = getContactCompany(engagement, contact);
        const updatedContacts = contactCompany?.contacts.filter(
            currContact => currContact.email !== contact.email && currContact?.id !== contact?.id
        );
        updatedContacts.push(contact);

        const updatedCompany = { ...contactCompany, contacts: updatedContacts };
        return updateEngagementCompany(engagement, updatedCompany);
    };

    /** Delete a client contact from the engagement */
    const deleteClientContact = (engagement, contact) => {
        const [updatedCompany] = engagement?.clientCompanies.filter(x => x.id === contact?.engagementCompanyId);

        const updatedContacts = updatedCompany?.contacts.filter(currContact => currContact.email !== contact.email);

        const clientCompaniesUpdated = engagement?.clientCompanies.filter(
            currClient => currClient.id !== updatedCompany?.id
        );

        clientCompaniesUpdated.push({ ...updatedCompany, contacts: updatedContacts });

        return { ...engagement, clientCompanies: clientCompaniesUpdated };
    };

    const copyEngagement = (engagement, oldEngagement) => {
        return {
            ...engagement,
            parentId: oldEngagement.id,
            name: oldEngagement.name,
            description: oldEngagement.description,
            clientCompanies: oldEngagement.clientCompanies,
            clientContacts: oldEngagement.clientContacts,
            appUserEngagements: oldEngagement.appUserEngagements,
            openingDate: dateWithOffset(oldEngagement.openingDate, 1),
            closingDate: dateWithOffset(oldEngagement.closingDate, 1)
        };
    };

    const engagementReducer = (state, action) => {
        switch (action.type) {
            case ENGAGEMENT_ACTIONS.ADD_MEMBER:
                return addMember(state, action?.values);

            case ENGAGEMENT_ACTIONS.REMOVE_MEMBER:
                return removeMember(state, action?.values);

            case ENGAGEMENT_ACTIONS.CHANGE_MEMBER_ROLE:
                return changeMemberRole(
                    state,
                    action.values.email,
                    action.values.isRoleManager,
                    action.values.hasEditionRights
                );

            case ENGAGEMENT_ACTIONS.ADD_CLIENT:
                return addClient(state, action?.values);

            case ENGAGEMENT_ACTIONS.UPDATE_CLIENT:
                return updateClient(state, action?.values);

            case ENGAGEMENT_ACTIONS.DELETE_CLIENT:
                return deleteClient(state, action?.values);

            case ENGAGEMENT_ACTIONS.ADD_CLIENT_CONTACT:
                return addClientContact(state, action?.values);

            case ENGAGEMENT_ACTIONS.UPDATE_CLIENT_CONTACT:
                return updateClientContact(state, action?.values);

            case ENGAGEMENT_ACTIONS.DELETE_CLIENT_CONTACT:
                return deleteClientContact(state, action?.values);

            case ENGAGEMENT_ACTIONS.DUPLICATE_ENGAGEMENT:
                return copyEngagement(state, action?.values);

            default:
                return state; //TODO INVITE_CLIENT_CONTACT, MANAGE_CLIENT_CONTACT_ACCESS_RIGHTS,  RESET_PASSWORD_CONTACT, REVOKE_CONTACT_ACCESS
        }
    };

    const renderCurrentStep = ({
        currentStep,
        intl,
        loading,
        classes,
        disabled,
        limitedAccess = false,
        restrictedEdition,
        engagement,
        propsInfo,
        propsClient,
        propsContact,
        propsMember,
        propsOptionalInfo
    }) => {
        switch (currentStep) {
            case ENGAGEMENT_STEPS.INFORMATION:
                return (
                    <EngagementInformationComponent
                        handleSelectEngagement={propsInfo.handleSelectEngagement}
                        handleFetchEngagements={propsInfo.handleFetchEngagements}
                        disabled={disabled || limitedAccess}
                        restrictedEdition={restrictedEdition}
                        engagement={engagement}
                        engagementCodeRegEx={propsInfo?.engagementCodeRegEx}
                        engagementCodeMessage={propsInfo?.engagementCodeMessage}
                        handleSubmit={propsInfo?.handleInfoSubmit}
                        sbmtEngagementRef={propsInfo?.sbmtEngagementRef}
                    />
                );

            case ENGAGEMENT_STEPS.CLIENTS:
                return (
                    <EngagementClientComponent
                        key={`key_select_client_component_${currentStep}`}
                        loading={loading}
                        classes={classes}
                        disabled={disabled || limitedAccess}
                        engagement={engagement}
                        searchClientCompanies={propsClient?.searchClientCompanies}
                        handleAddClient={propsClient?.handleClientAdd}
                        handleUpdateClient={propsClient?.handleClientUpdate}
                        handleDeleteClient={propsClient?.handleClientDelete}
                    />
                );

            case ENGAGEMENT_STEPS.CONTACTS:
                return (
                    <EngagementContactComponent
                        intl={intl}
                        restrictedEdition={restrictedEdition}
                        limitedAccess={limitedAccess}
                        loading={loading}
                        classes={classes}
                        disabled={disabled}
                        contactType={propsContact?.contactType}
                        companies={engagement?.clientCompanies}
                        handleAddContact={propsContact?.handleContactAdd}
                        handleUpdateContact={propsContact?.handleContactUpdate}
                        handleDeleteContact={propsContact?.handleContactDelete}
                        handleInviteContact={propsContact?.handleContactInvite}
                        handleRevokeAccess={propsContact?.handleContactRevokeAccess}
                        handleDeleteEngagementAccess={propsContact?.handleContactDeleteAccess}
                        handleResetPassword={propsContact?.handleResetPassword}
                    />
                );

            case ENGAGEMENT_STEPS.LETTERHEADS:
                return (
                    <EngagementCompanyLetterHeads
                        intl={intl}
                        loading={loading}
                        classes={classes}
                        disabled={disabled}
                        limitedAccess={limitedAccess}
                        companies={engagement?.clientCompanies}
                        updateCompany={propsClient?.handleClientUpdate}
                    />
                );

            case ENGAGEMENT_STEPS.MEMBERS:
                return (
                    <EngagementMemberComponent
                        intl={intl}
                        user={user}
                        disabled={disabled || limitedAccess}
                        restrictedEdition={restrictedEdition}
                        engagement={engagement}
                        handleAddMember={propsMember?.handleAddMember}
                        handleDeleteMember={propsMember?.handleDeleteMember}
                        handleChangeRole={propsMember?.handleChangeRole}
                    />
                );

            case ENGAGEMENT_STEPS.THIRD_PARTIES:
                return (
                    <EngagementThirdPartyComponent
                        loading={loading}
                        classes={classes}
                        disabled={disabled}
                        limitedAccess={limitedAccess}
                        engagement={engagement}
                        DownloadEnabled
                    />
                );

            case ENGAGEMENT_STEPS.OPTIONAL_INFO_SELECTION:
                return (
                    <EngagementSelectOptions
                        loading={loading}
                        engagement={engagement}
                        handleSubmit={propsOptionalInfo.handleSubmit}
                        sbmtEngagementRef={propsOptionalInfo?.sbmtEngagementRef}
                    />
                );

            default:
                return null;
        }
    };

    const renderErrorMessage = () =>
        errorMessage && (
            <Alert severity="error" onClose={() => setErrorMessage('')}>
                {FormattedErrorMessage(<NewlineText text={intl.formatMessage({ id: errorMessage })}></NewlineText>)}
            </Alert>
        );

    return [engagementReducer, renderCurrentStep, renderErrorMessage];
}
