import React from 'react';
import { UIActionButton } from '../../common/UIActionButton';
import { toolbarStyle } from '../../../styles/toolbar/ToolbarStyle';
import { Palette } from '../../../styles/colors';

export const ConfirmationStepActions = ({ actions, handleStepAction }) => {
    return (
        <>
            {actions?.length > 0 &&
                React.Children.toArray(
                    actions.map(
                        action =>
                            !action.isMenu && (
                                <UIActionButton
                                    title={action.text}
                                    sx={{
                                        fontSize: 10,
                                        ...toolbarStyle.button,
                                        marginTop: 2,
                                        backgroundColor: action.isCritical ? Palette.red : Palette.defaultPrimaryColor
                                    }}
                                    onClick={() => handleStepAction(action)}
                                />
                            )
                    )
                )}
        </>
    );
};
