import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../partials/controls/Card';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Button, Box } from '@material-ui/core';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { initStorageSharePointOnline } from './InitStorageSharePointOnline';
import { StorageSharePointOnlineComponent } from './StorageSharePointOnline';
import { StorageBaseConfigComponent } from '../../../setup';
import { COMPONENT_NAME } from '../../constants';
import { UIDialog } from '../../../common/UIDialog';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    }
}));

/**
 * Storage SharePoint Online wrapper contain creation/edition/activate/priority of use.
 *
 * @param {*} props
 * @returns
 */
export function StorageSharePointOnlineWrapper(props) {
    const { storageConfiguration, handleStorageConfig, hasValidConfig } = props;

    const classes = useStyles();
    const sbmtConfRef = useRef();

    const [storageSharePointOnline, setStorageConfig] = useState(initStorageSharePointOnline);
    const [openConfig, setOpenConfig] = useState(false);

    useEffect(() => {
        setStorageConfig(storageConfiguration);
    }, [storageConfiguration]);

    const handlePopUpConfigOpening = () => setOpenConfig(!openConfig);

    const handleClickSave = () => {
        if (sbmtConfRef && sbmtConfRef.current) {
            sbmtConfRef.current.saveForm();
        }
    };

    const handleSubmit = values => {
        setStorageConfig({ ...storageSharePointOnline, ...values });
        handleStorageConfig({ ...storageSharePointOnline, ...values });
        setOpenConfig(!openConfig);
    };

    const handleResetConfig = () => {
        setStorageConfig(initStorageSharePointOnline);
    };

    const handleSaveConfig = () => {
        handleStorageConfig(storageSharePointOnline);
    };

    const handleUpdateConfig = values => {
        hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, false);
        setStorageConfig({ ...storageSharePointOnline, ...values });
    };

    return (
        <>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid container alignItems="stretch" justifyContent="center">
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader
                                title={
                                    <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.CREATE.TITLE" />
                                }>
                                <CardHeaderToolbar>
                                    {storageSharePointOnline?.targetPath !== '' ? (
                                        <Box component="span" m={1} className={clsx(classes.box, classes.boxButton)}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                className={classes.button}
                                                onClick={handleResetConfig}>
                                                <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.RESET.LABEL" />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                className={classes.button}
                                                onClick={handleSaveConfig}>
                                                <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.SAVE.LABEL" />
                                            </Button>
                                        </Box>
                                    ) : null}
                                </CardHeaderToolbar>
                            </CardHeader>
                            <CardBody>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={6}>
                                    <Grid item xs={12}>
                                        <Button
                                            startIcon={<SettingsIcon />}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handlePopUpConfigOpening}>
                                            {storageSharePointOnline?.id ? (
                                                <FormattedMessage
                                                    id={
                                                        'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.EDIT.LABEL'
                                                    }
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id={
                                                        'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.CREATE.LABEL'
                                                    }
                                                />
                                            )}
                                        </Button>

                                        {openConfig && (
                                            <UIDialog
                                                {...props}
                                                classes={classes}
                                                open={openConfig}
                                                labelId="STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.TITLE.CREATE.LABEL"
                                                handleClose={handleClickSave}
                                                handleReturn={() => setOpenConfig(!openConfig)}
                                                children={
                                                    <StorageSharePointOnlineComponent
                                                        {...props}
                                                        storageDefault={storageSharePointOnline}
                                                        disabled={false}
                                                        handleSubmit={handleSubmit}
                                                        hasValidConfig={hasValidConfig}
                                                        sbmtConfRef={sbmtConfRef}
                                                    />
                                                }
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <StorageBaseConfigComponent
                                            storageConfiguration={storageSharePointOnline}
                                            handleUpdateConfig={handleUpdateConfig}
                                        />
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default injectIntl(StorageSharePointOnlineWrapper);
