import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { EditEntityRow } from '../../company/edit/EditEntityRow';
import { FormLabel, getFieldElement, LabeledTextFieldItem } from '../../../helpers/FormHelper';
import React from 'react';
import { makeContactTitles } from './EditContact';

export const EditThirdPartyContact = ({ intl, companies, companyDisabled, style }) => {
    return {
        title: 'thirdParty',
        yup: Yup.object().shape({
            engagementCompanyId: Yup.string()
                .required(<FormattedMessage id="EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.COMPANY" />)
                .nullable(),
            title: Yup.string(),
            jobTitle: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .max(50, <FormattedMessage id="INVALID.FIELD.MAX.SYMBOLS" values={{ max: 50 }} />),
            firstName: Yup.string().min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />),
            lastName: Yup.string().min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />),
            email: Yup.string()
                .trim()
                .email(intl.formatMessage({ id: 'INVALID.FIELD.EMAIL.FORMAT' }))
                .required(<FormattedMessage id="EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.EMAIL" />),
            description: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .max(500, <FormattedMessage id="INVALID.FIELD.MAX.SYMBOLS" values={{ max: 500 }} />),
            dialingCode: Yup.string(),
            phone: Yup.string().min(10, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 10 }} />)
        }),
        fields: [
            {
                render: () => (
                    <div style={style} className="form form-label-right row display-flex">
                        <div className="col-lg-6">
                            <FormLabel id="EDIT_CONTACT.LABEL.COMPANY" isRequired={true} />
                            <>
                                {getFieldElement({
                                    SelectFieldItem: {
                                        disabled: companyDisabled,
                                        id: 'engagementCompanyId',
                                        options: companies ? companies : [{ id: 0, name: '' }],
                                        placeholder: intl.formatMessage({ id: 'EDIT_CONTACT.PLACEHOLDER.COMPANY' })
                                    }
                                })}
                            </>
                        </div>
                        <LabeledTextFieldItem
                            id={'jobTitle'}
                            title={'EDIT_CONTACT.LABEL.JOBTITLE'}
                            placeholder={intl.formatMessage({ id: 'EDIT_CONTACT.PLACEHOLDER.JOBTITLE' })}
                            className="col-lg-6"
                        />
                    </div>
                )
            },
            {
                render: () => (
                    <div className="row mt-5">
                        <div style={style} className="col-lg-6">
                            <div className="row">
                                <div style={style} className="col-lg-3">
                                    <FormLabel id="EDIT_CONTACT.LABEL.TITLE" />

                                    {getFieldElement({
                                        SelectFieldItem: {
                                            id: 'title',
                                            options: makeContactTitles(intl),
                                            placeholder: intl.formatMessage({ id: 'EDIT_CONTACT.PLACEHOLDER.TITLE' })
                                        }
                                    })}
                                </div>

                                <LabeledTextFieldItem
                                    id={'firstName'}
                                    title={'EDIT_CONTACT.LABEL.FIRSTNAME'}
                                    placeholder={intl.formatMessage({ id: 'EDIT_CONTACT.PLACEHOLDER.FIRSTNAME' })}
                                    className="col-lg-9"
                                />
                            </div>
                        </div>

                        <LabeledTextFieldItem
                            id={'lastName'}
                            title={'EDIT_CONTACT.LABEL.LASTNAME'}
                            placeholder={intl.formatMessage({ id: 'EDIT_CONTACT.PLACEHOLDER.LASTNAME' })}
                            className="col-lg-6"
                        />
                    </div>
                )
            },
            {
                render: () =>
                    EditEntityRow({
                        labelPrefix: 'EDIT_CONTACT.LABEL',
                        placeholderPrefix: 'EDIT_CONTACT.PLACEHOLDER',
                        columns: [
                            {
                                id: 'email',
                                label: 'EMAIL',
                                className: 'col-lg-6',
                                isRequired: true
                            },
                            {
                                id: 'dialingCode',
                                label: 'DIALINGCODE',
                                className: 'col-lg-2'
                            },
                            { id: 'phone', label: 'PHONE', className: 'col-lg-4' }
                        ],
                        intl: intl
                    })
            },
            {
                render: () =>
                    EditEntityRow({
                        labelPrefix: 'EDIT_CONTACT.LABEL',
                        placeholderPrefix: 'EDIT_CONTACT.PLACEHOLDER',
                        columns: [
                            {
                                id: 'description',
                                label: 'DESCRIPTION',
                                className: 'col-lg-12'
                            }
                        ],
                        intl: intl
                    })
            }
        ]
    };
};
