import React, { useState, useEffect } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { UserAvatarComponent } from '../../user/UserAvatar';
import { ROUTES } from '../../../navigation/Routes';
import { initPaginatedObject } from '../../common/InitPaginatedObject';
import { MZRTableActionIcon } from '../../common/MZRTableActionIcon';
import { useArchiveEngagement, useDeleteEngagement } from '../../../hooks/useEntityActionPopup';
import { MZRPaginatedTable } from '../../common/MZRPaginatedTable';
import { FilterRowContainer } from '../../common/filtering/FilterRowContainer';
import { HOME_COLUMNS_FILTERS } from '../../home/Constants';
import { FILTER_COMPONENT } from '../../../types/types';
import { UIDropDownMenu } from '../../common/UIDropDownMenu';
import { SettingsRounded, DeleteRounded, GetAppRounded, NoteAddRounded, ArchiveRounded, UnarchiveRounded } from '@mui/icons-material';
import { Palette } from '../../../styles/colors';
import { isArchiveToggleAuthorized } from '../../../helpers/EngagementHelper';

export function EngagementTableComponent(props) {
    const {
        user,
        intl,
        engagementsPaginated,
        handleFilterChange,
        resetEngagement,
        archiveEngagement,
        deleteEngagement,
        handleDownloadEngagement,
        getDownloadEngagementOptions,
        isFilteringMode,
        handleFilterEngagementsChange,
        columnsFiltersValues
    } = props;

    const history = useHistory();
    const [selectEngagement, setSelectedEngagement] = useState(null);
    const [engagementsLoaded, setEngagementLoaded] = useState(initPaginatedObject);

    useEffect(() => {
        setEngagementLoaded(engagementsPaginated);
    }, [engagementsPaginated]);

    const { delete: DeleteEngagementComponent, handleDeleteClick } = useDeleteEngagement({
        user: user,
        deleteAPI: deleteEngagement
    });

    const { archive: ArchiveEngagementComponent, handleArchiveClick } = useArchiveEngagement({
        user: user,
        archiveAPI: archiveEngagement
    });

    const handleRowClick = (_, rowData) => {
        if (rowData !== null) {
            history.push(ROUTES.ENGAGEMENT.replace(':engagementId', rowData?.id));
        }
    };

    const onDeleteEngagementClick = engagement => {
        setSelectedEngagement(engagement);
        handleDeleteClick();
    };

    const onToggleEngagementClick = engagement => {
        setSelectedEngagement(engagement);
        handleArchiveClick();
    };

    return (
        <div className="engagement-table">
            <MZRPaginatedTable
                isFiltering={isFilteringMode}
                handleFilterChange={handleFilterChange}
                data={engagementsLoaded}
                loading={props.loading}
                onRowClick={handleRowClick}
                columns={[
                    {
                        filtering: isFilteringMode,
                        filteringConfig: [
                            {
                                component: FILTER_COMPONENT.INPUT,
                                placeholder: intl.formatMessage({
                                    id: 'HOME.TABLE.FILTER.ENGAGEMENT.NAME.CODE.PLACEHOLDER'
                                }),
                                filterName: HOME_COLUMNS_FILTERS.ENGAGEMENT_NAME_OR_CODE
                            }
                        ],
                        title: intl.formatMessage({ id: 'HOME.TABLE.TITLE.ENGAGEMENT' }),
                        field: 'name',
                        width: 250
                    },
                    {
                        title: intl.formatMessage({ id: 'HOME.TABLE.TITLE.CLOSING_DATE' }),
                        field: 'closingDate',
                        type: 'date',
                        width: 100,
                        cellStyle: { textAlign: 'center' },
                        render: rowData => (
                            <FormattedDate value={rowData.closingDate} year="numeric" month="short" day="numeric" />
                        )
                    },
                    {
                        title: <FormattedMessage id="HOME.TABLE.TITLE.NB_COMPANIES" />,
                        field: 'numberOfCompanies',
                        width: 100,
                        cellStyle: { textAlign: 'center' }
                    },
                    {
                        title: <FormattedMessage id="HOME.TABLE.TITLE.NB_CONFIRMATIONS" />,
                        field: 'numberOfConfirmations',
                        width: 100,
                        cellStyle: { textAlign: 'center' }
                    },
                    {
                        title: <FormattedMessage id="HOME.TABLE.TITLE.USERS" />,
                        field: 'numberOfAppUsers',
                        width: 220,
                        render: rowData => <UserAvatarComponent data={rowData.appUserEngagements} max={4} />
                    }
                ]}
                actions={[
                    {
                        id: 'settings',
                        icon: 'settings',
                        onClick: (_, rowData) => {
                            resetEngagement();
                            history.push(ROUTES.ENGAGEMENT_SETTINGS.replace(':engagementId', rowData.id));
                        },
                        tooltip: intl.formatMessage({
                            id: 'HOME.TABLE.ACTION.TOOLTIP.SETTINGS'
                        })
                    },
                    rowData => ({
                        id: 'new',
                        icon: 'new',
                        onClick: (_, rowData) =>
                            history.push({
                                pathname: ROUTES.ENGAGEMENT_CONFIRMATION_CREATE.replace(':engagementId', rowData.id),
                                state: { engagement: rowData }
                            }),
                        tooltip: intl.formatMessage({ id: 'HOME.TABLE.ACTION.TOOLTIP.NEW' }),
                        hidden: rowData.archived || !rowData.isEditable
                    }),
                    rowData => ({
                        id: 'archive',
                        icon: 'archive',
                        onClick: (_, rowData) => onToggleEngagementClick(rowData),
                        tooltip: intl.formatMessage({ id: 'HOME.TABLE.ACTION.TOOLTIP.ARCHIVE' }),
                        hidden: !isArchiveToggleAuthorized(rowData, props.user, 'Archive')
                    }),
                    rowData => ({
                        id: 'unarchive',
                        icon: 'unarchive',
                        onClick: (_, rowData) => onToggleEngagementClick(rowData),
                        tooltip: intl.formatMessage({ id: 'HOME.TABLE.ACTION.TOOLTIP.UNARCHIVE' }),
                        hidden: !isArchiveToggleAuthorized(rowData, props.user, 'Unarchive')
                    }),
                    {
                        id: 'download',
                        icon: 'download',
                        onClick: event => event.preventDefault()
                    },
                    rowData => ({
                        id: 'delete',
                        icon: 'delete',
                        onClick: (_, rowData) => onDeleteEngagementClick(rowData),
                        tooltip: intl.formatMessage({ id: 'HOME.TABLE.ACTION.TOOLTIP.DELETE' }),
                        hidden: rowData.archived || !rowData.isDeletable
                    })
                ]}
                components={{
                    FilterRow: rowProps => (
                        <FilterRowContainer
                            rowProps={rowProps}
                            handleFilterChange={handleFilterEngagementsChange}
                            selectedFilters={columnsFiltersValues}
                        />
                    ),
                    Action: props => {
                        const { action, data } = props;
                        let actionInfo = action;
                        if (typeof action.action === 'function') {
                            actionInfo = action.action(data);
                        }

                        switch (actionInfo.icon) {
                            case 'settings':
                                return (
                                    <MZRTableActionIcon
                                        data={data}
                                        {...actionInfo}
                                        icon={<SettingsRounded color="primary" />}
                                    />
                                );

                            case 'new':
                                return (
                                    <MZRTableActionIcon
                                        data={data}
                                        {...actionInfo}
                                        icon={<NoteAddRounded color="primary" />}
                                    />
                                );

                            case 'archive':
                                return (
                                    <MZRTableActionIcon
                                        data={data}
                                        {...actionInfo}
                                        icon={<ArchiveRounded color="primary" />}
                                    />
                                );

                            case 'unarchive':
                                return (
                                    <MZRTableActionIcon
                                        data={data}
                                        {...actionInfo}
                                        icon={<UnarchiveRounded color="primary" />}
                                    />
                                );

                            case 'download':
                                return (
                                    <MZRTableActionIcon
                                        data={data}
                                        {...actionInfo}
                                        icon={
                                            <UIDropDownMenu
                                                entries={
                                                    getDownloadEngagementOptions
                                                        ? getDownloadEngagementOptions(data, handleDownloadEngagement)
                                                        : []
                                                }
                                                titleComponent={() => <GetAppRounded color="primary" />}
                                            />
                                        }
                                    />
                                );

                            case 'delete':
                                return (
                                    <MZRTableActionIcon
                                        data={data}
                                        {...actionInfo}
                                        className=""
                                        icon={<DeleteRounded sx={{ color: Palette.red }} />}
                                    />
                                );
                            default:
                                return null;
                        }
                    }
                }}
            />

            <DeleteEngagementComponent engagementId={selectEngagement?.id} />
            <ArchiveEngagementComponent engagement={selectEngagement} />
        </div>
    );
}
