import { accountReducer, appReducer, clusterReducer, engagementReducer } from './reducers';
import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = key => ({
    key: key,
    storage
});

const rootReducer = combineReducers({
    account: persistReducer(persistConfig('root'), accountReducer),
    app: persistReducer(persistConfig('app'), appReducer),
    cluster: persistReducer(persistConfig('cluster'), clusterReducer),
    engagement: persistReducer(persistConfig('engagement'), engagementReducer)
});

export const store = createStore(rootReducer);
export const persistor = persistStore(store);
