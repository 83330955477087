import { appApiUrl } from '../../config/appConfiguration';
import { parseResponse, parseResponseShort } from '../parsers';
import { parseMailingConfiguration, parseMailingConfigurations } from './setupParsers';
import { Repository } from '../Repository';

class MailingConfigRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`mailingconfiguration`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseMailingConfigurations);
        return result;
    };

    fetch = async (configurationId) => {
        let result = await this.network.get(`mailingconfiguration/${configurationId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseMailingConfiguration);
        return result;
    };

    fetchCluster = async (clusterId) => {
        let result = await this.network.get(`mailingconfiguration/cluster/${clusterId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseMailingConfiguration);
        return result;
    };
    
    create = async (configuration) => {
        let result = await this.network.post(`mailingconfiguration`, configuration, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    update = async (configurationId, configuration) => {
        let result = await this.network.put(`mailingconfiguration/${configurationId}`, configuration, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseMailingConfiguration);
        return result;
    };

    delete = async (configurationId) => {
        let result = await this.network.delete(`mailingconfiguration/${configurationId}`, null, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };
}

export const mailingConfigRepository = new MailingConfigRepository();
