export const externalClient = {
    'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.DUE_DATE': 'Date d\'échéance',
    'CLIENT.ENGAGEMENT.TABLE.ACTION.TOOLTIP.REFUSE': 'Refuser la demande de circularisation',
    'CLIENT.ENGAGEMENT.TABLE.ACTION.TOOLTIP.COMPLETE.TASKS': 'Compléter les tâches de la circularisation',
    'CLIENT.ENGAGEMENT.TABLE.ACTION.OPTION.DOWNLOAD': 'Télécharger la lettre',
    'CLIENT.ENGAGEMENT.TABLE.ACTION.OPTION.UPLOAD': 'Uploader la lettre signée',

    'CLIENT.HOME.TABLE.TITLE.ENGAGEMENT': 'MISSION',
    'CLIENT.HOME.TABLE.TITLE.CLOSING_DATE': 'DATE DE CLÔTURE',
    'CLIENT.HOME.TABLE.TITLE.NB_PENDING_REQUESTS': 'DEMANDES EN COURS',
    'CLIENT.HOME.TABLE.TITLE.NB_COMPLETED_REQUESTS': 'DEMANDES COMPLÉTÉES',
    'CLIENT.HOME.TABLE.TITLE.AUDITORS': 'AUDITEURS',
    'CLIENT.HOME.TABLE.TITLE.CONTACTS': 'CONTACTES',
    'CLIENT.ENGAGEMENT.PENDING.REQUESTS.TOOLTIP': 'Demandes en attentes',
    'CLIENT.ENGAGEMENT.ALL.REQUESTS.TOOLTIP': 'Toutes les demandes',
    'CLIENT.ENGAGEMENT.REQUESTS.LABEL': 'DEMANDES',
    'CLIENT.ENGAGEMENT.ALL.REQUESTS.LABEL': 'TOUTES LES CIRCULARISATIONS',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.CONFIRMATION.PLACEHOLDER': 'Compte/Montant/Commentaire',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.PLACEHOLDER': 'Papier à en-tête',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.ON': 'Déjà uploadé',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.OFF': 'Non uploadé',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.THIRD_PARTY': 'Entreprise tierce',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.THIRD_PARTY.CONTACTS': 'Contact tiers',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.CONTACTS': 'Contacts',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.SIGNATORY': 'Signataire',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.DUPLICATE': 'Cette circularisation a déjà été demandée',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.INACCURATE':
        'Les informations de cette demande de confirmation ne sont pas exactes (justifier dans la section commentaire)',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.REFUSE': 'Je ne souhaite pas que cette demande soit faite (justifier dans la section commentaire)',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.REASON': 'Motif',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.COMMENT': 'Commentaire',
    'CLIENT.ENGAGEMENT.REQUESTS.REFUSE.SUCCESS': 'La demande a été refusée avec succès',
    'CLIENT.ENGAGEMENT.REQUESTS.REFUSE.ERROR': 'Échec lors du refus de la demande',
    'CLIENT.ENGAGEMENT.REQUESTS.UPLOAD.SUCCESS': 'Circularisation uploadée',
    'CLIENT.ENGAGEMENT.REQUESTS.UPLOAD.ERROR': 'Échec lors de l\'upload de la circularisation',
    'CLIENT.REFUSE.REQUEST.REASON.REQUIRED': 'La motif du refus est requis',
    'CLIENT.REFUSE.REQUEST.COMMENT.REQUIRED': 'Le commentaire est requis',
    'CLIENT.REFUSE.REQUEST.POPUP.TITLE': 'Sélectionnez le motif de votre refus',
    'CLIENT.ENGAGEMENT.UPLOAD.DIALOG.TITLE': 'Uploader la lettre de circularisation signée',
    'CLIENT.ENGAGEMENT.COMPLETE.DIALOG.TITLE': 'Compléter les étapes de la demande',
    'CLIENT.ENGAGEMENT.COMPLETE.THIRD_PARTY.SECTION': 'Tiers',
    'CLIENT.ENGAGEMENT.COMPLETE.LETTERHEAD.SECTION': 'Papier à en-tête',
    'CLIENT.ENGAGEMENT.COMPLETE.COMPLEMENTARY_INFO.SECTION': 'Informations complémentaires',
    'CLIENT.ENGAGEMENT.COMPLETE.VALIDATE.SECTION': 'Validation',
    'CLIENT.CONFIRMATION.STEPS.NEXT.LABEL': 'Sauvegarder et Suivant',
    'CLIENT.CONFIRMATION.STEPS.CONFIRM.LABEL': 'Confirmer',
    'CLIENT.REQUEST.LETTERHEAD.REQUIRED': 'Le papier à en-tête est requis',
    'CLIENT.REQUEST.THIRD_PARTY.REQUIRED': 'L\'entreprise tierce et le contact sont requis',
    'CLIENT.REQUEST.VALIDATION.STEP.CLIENT.COMPANY': 'Informations de l\'entreprise cliente',
    'CLIENT.REQUEST.VALIDATION.STEP.CLIENT.CONTACTS': 'Contacts de l\'entreprise cliente',
    'CLIENT.REQUEST.VALIDATION.STEP.THIRD_PARTY.COMPANY': 'Informations de l\'entreprise tierce',
    'CLIENT.REQUEST.VALIDATION.STEP.THIRD_PARTY.CONTACTS': 'Contacts de l\'entreprise tierce',
    'CLIENT.REQUEST.VALIDATION.STEP.LETTERHEAD': 'Papier à en-tête',
    'CLIENT.ENGAGEMENT.REQUESTS.VALIDATE.SUCCESS': 'Demande validée',
    'CLIENT.ENGAGEMENT.REQUESTS.VALIDATE.ERROR': 'Échec lors de la validation de la demande',
    'CLIENT.REQUEST.VALIDATION.STEP.ALL.STEPS.REQUIRED': 'Veuillez compléter toutes les étapes pour valider votre demande',
    'CLIENT.ENGAGEMENT.SIGN.DIALOG.TITLE': 'Signature',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.STATUS': 'Status',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.NOT_STARTED': 'Pas commencé',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.REFUSED': 'Refusé par l\'équipe d\'audit',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.WAITING_AUDITOR_VALIDATION': 'En attente de validation',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.WAITING_SIGNATURE': 'En attente de signature',
    'CLIENT.REQUEST.ACTION.SIGNATURE.E_SIGNATURE.OPTION': 'Signature électronique',
    'CLIENT.REQUEST.ACTION.SIGNATURE.DRAW.OPTION': 'Signature à la main',
    'CLIENT.REQUEST.ACTION.SIGNATURE.UPLOAD.OPTION': 'Upload de la signature',
    'CLIENT.REQUEST.ACTION.SIGNATURE': 'SIGNATURE',
    'CLIENT.REQUEST.ACTION.REFUSE': 'REFUS',
    'CLIENT.REQUEST.ACTION.DOWNLOAD': 'TELECHARGEMENT',
    'CLIENT.REQUEST.ACTION.SIGN_LATER': 'SIGNER PLUS TARD',
    'CLIENT.CONTACT.INVITE': 'Inviter à rejoindre l\'application',
    'CLIENT.CONTACT.MANAGE.ACCESS.RIGHTS': 'Gérer les accès et les droits',
    'CLIENT.CONTACT.EDIT': 'Modifier les info'
};
