import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../navigation/Routes';
import { Palette } from '../../styles/colors';
import { useIntl } from 'react-intl';
import { UIText } from '../common/UIText';
import { getLocalizedString } from '../../i18n/i18Helper';

export const AuthFooter = () => {
    const intl = useIntl();

    return (
        <Link to={ROUTES.AUTH} className="mt-10 text-center" style={{ color: Palette.primaryTextColor }}>
            <UIText
                variant="h6"
                sx={{ color: Palette.sonicSilver, fontSize: 14 }}
                text={getLocalizedString(intl, 'AUTH.CHANGE.LOGIN.METHOD')}
            />
        </Link>
    );
};
