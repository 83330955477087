const defaultHeaders = {
    'Content-Type': 'application/json',
    accept: '*/*'
};

const formattedHeaders = (headers, body) =>
    body instanceof FormData ? headers : headers ? { ...defaultHeaders, ...headers } : defaultHeaders;

const formattedBody = body => {
    return !body || body instanceof FormData ? body : JSON.stringify(body);
};

const buildURL = (baseURL, path) => {
    return baseURL ? `${baseURL}/${path}` : path;
};

/**
 * Component to perform network requests to API.
 *
 * @param {string} baseURL
 * @param {string} path
 * @param {string} method
 * @param {object} body
 * @param {object} options
 */
export const mzrRequest = async (baseURL, path, method, body, options) => {
    const performFetch = async (url, fetchOptions) => {
        return await fetch(url, fetchOptions);
    };

    const { headers } = options;
    return performFetch(buildURL(baseURL, path), {
        headers: formattedHeaders(headers, body),
        body: formattedBody(body),
        method: method
    });
};

export const buildFilters = params => {
    let filters = '';
    const lim = params ? Object.keys(params).length : -1;
    if (lim > 0) {
        filters = filters.concat('/?');
        for (const filter of Object.keys(params)) {
            const i = Object.keys(params).indexOf(filter);
            filters = filters.concat(`${filter}=${params[filter]}`);
            filters = i < lim - 1 ? filters.concat(`&`) : filters;
        }
    }

    return filters;
};
