import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    ExpandMore as ExpandMoreIcon,
    EditRounded as EditRoundedIcon,
    DeleteForeverRounded as DeleteForeverRoundedIcon
} from '@mui/icons-material';
import { UserResourceConfig } from './UserResourceConfig';
import { Box, Button } from '@material-ui/core';
import { initUserResourceConfig } from './InitUserResourceConfig';
import { UIDialog } from '../../common/UIDialog';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(1)
    }
}));

const styles = {
    AccordionHeaderStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    }
};

export function UserResourceConfigWrapper(props) {
    const { userResources, loading, languages, handleSubmit, hasValidConfig, handleDelete } = props;
    const classes = useStyles();
    const userResrceConfigRef = useRef();

    const [openCreateUserResource, setOpenCreateUserResource] = useState(false);
    const [currentUserResource, setCurrentUserResource] = useState(initUserResourceConfig);
    const [currentUserResources, setCurrentUserResources] = useState([]);

    useEffect(() => {
        userResources && setCurrentUserResources(userResources);
    }, [userResources]);

    const hanleClickSaveResource = () => {
        if (userResrceConfigRef && userResrceConfigRef.current) {
            userResrceConfigRef.current.saveForm();
        }
    };

    const handlePopUpUserResourceOpening = () => {
        setOpenCreateUserResource(true);
    };
    props.userResrceCreateRef.current = {
        openForm() {
            handlePopUpUserResourceOpening();
        }
    };

    const handleUserResourceUpdate = resource => {
        setCurrentUserResource(resource);
        setOpenCreateUserResource(!openCreateUserResource);
    };

    const handleUserResourceDelete = resource => handleDelete(resource.id);

    return (
        <>
            <div className={classes.root}>
                {React.Children.toArray(
                    currentUserResources.map(resource => (
                        <Accordion TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls={`user-resource-action-${resource.id}-content`}>
                                <div style={styles.AccordionHeaderStyle}>
                                    <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={<Checkbox checked={true} color="primary" />}
                                        label={resource.name}
                                    />
                                    <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={
                                            <Box
                                                component="span"
                                                m={1}
                                                className={clsx(classes.box, classes.boxButton)}>
                                                <Button
                                                    startIcon={<EditRoundedIcon />}
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={() => handleUserResourceUpdate(resource)}>
                                                    <FormattedMessage
                                                        id={'USER.RESOURCE.CONFIGURATION.INFO.BUTTON.EDIT.LABEL'}
                                                    />
                                                </Button>
                                                <Button
                                                    startIcon={<DeleteForeverRoundedIcon />}
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={() => handleUserResourceDelete(resource)}>
                                                    <FormattedMessage
                                                        id={'USER.RESOURCE.CONFIGURATION.INFO.BUTTON.DELETE.LABEL'}
                                                    />
                                                </Button>
                                            </Box>
                                        }
                                    />
                                </div>
                            </AccordionSummary>

                            <AccordionDetails
                                children={
                                    <UserResourceConfig
                                        userResourceConfig={resource}
                                        languages={languages}
                                        loading={loading}
                                        isPreview={true}
                                        handleSubmit={handleSubmit}
                                        hasValidConfig={hasValidConfig}
                                        userResrceConfigRef={userResrceConfigRef}
                                    />
                                }
                            />
                        </Accordion>
                    ))
                )}
            </div>

            {openCreateUserResource && (
                <UIDialog
                    open={openCreateUserResource}
                    loading={loading}
                    titleId={
                        currentUserResource.id
                            ? 'USER.RESOURCE.CONFIGURATION.DIALOG.EDIT.TITLE'
                            : 'USER.RESOURCE.CONFIGURATION.DIALOG.CREATE.TITLE'
                    }
                    onSave={hanleClickSaveResource}
                    onClose={() => setOpenCreateUserResource(!openCreateUserResource)}>
                    <UserResourceConfig
                        userResourceConfig={currentUserResource}
                        languages={languages}
                        loading={loading}
                        isPreview={false}
                        handleSubmit={handleSubmit}
                        hasValidConfig={hasValidConfig}
                        userResrceConfigRef={userResrceConfigRef}
                    />
                </UIDialog>
            )}
        </>
    );
}
