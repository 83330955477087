import { MZRSearchBar } from '../MZRSearchBar';
import React, { useState } from 'react';
import { Clear } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { FILTER_COMPONENT } from '../../../types/types';

const getOptionLabel = row => `${row.value ?? ''}`;
const getOptionValue = option => option.id;

export const FilterComponent = ({ component, filtering, ...props }) => {
    if (!filtering) return null;

    return component === FILTER_COMPONENT.SELECT ? (
        <SelectFilterComponent {...props} />
    ) : (
        <InputFilterComponent value={props.selectedFilter?.id} {...props} />
    );
};

const SelectFilterComponent = ({
    filters,
    filterName,
    selectedFilter,
    handleFilterChange,
    disabled = false,
    placeholder
}) => {
    const options = filters ?? [];

    const onSelectionChange = value => {
        handleFilterChange(filterName, value);
    };

    const handleClear = () => {
        handleFilterChange(filterName, null);
    };

    return (
        <MZRSearchBar
            isAreaFocusVisible={false}
            value={selectedFilter}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
            options={options}
            onSelectionChange={onSelectionChange}
            isClearable={true}
            onClear={handleClear}
            placeholder={placeholder}
        />
    );
};

export const InputFilterComponent = ({
    filterName,
    value = null,
    handleFilterChange,
    disabled = false,
    placeholder
}) => {
    const [inputValue, setInputValue] = useState(value);
    const classes = useStyles();

    const onTextInputChange = e => {
        setInputValue(e.target.value ?? null);
    };

    const onKeyPressed = e => {
        if (e.key === 'Enter') {
            // build the filter to harmonise with the case of dropdown
            handleFilterChange(filterName, { id: inputValue, name: inputValue });
        }
    };

    const handleClear = () => {
        setInputValue('');
        handleFilterChange(filterName, null);
    };

    return (
        <TextField
            onKeyPress={onKeyPressed}
            value={inputValue}
            InputProps={{
                classes: { input: classes.input, adornedEnd: classes.adornedEnd },
                endAdornment: inputValue && (
                    <InputAdornment position="start">
                        <Clear onClick={handleClear} color="disabled" fontSize="small" />
                    </InputAdornment>
                )
            }}
            variant="standard"
            onChange={onTextInputChange}
            placeholder={placeholder}
            disabled={disabled}
        />
    );
};

const useStyles = makeStyles(_ => ({
    input: {
        height: '0px !important',
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
        paddingTop: '19px !important',
        paddingBottom: '19px !important'
    },
    adornedEnd: {
        padding: '0px'
    }
}));
