import React, { useEffect, useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FORM_FIELD } from '../../helpers';
import { useForm } from '../../hooks/useForm';
import { getLocalizedString } from '../../i18n/i18Helper';
import * as Yup from 'yup';
import { LetterHeadSpec } from '../../types/types';
import { Help } from '@mui/icons-material';
import { Alert, AlertTitle, Grid, IconButton, Box } from '@mui/material';
import { toAbsoluteUrl } from '../../helpers/AssetsHelpers';
import { UIDialog } from '../common/UIDialog';
import { UPLOAD_TYPES } from '../../helpers/ItemHelper';

interface CreateLetterHeadProps {
    letterHead: any;
    createLetterHead: (values: LetterHeadSpec) => void;
    companyDisabled: boolean;
    companies: object[];
    triggerButtonRef?: any;
    variant?: any;
}

interface CreateLetterHeadUserHelperProps {
    openPreview: () => void;
}

const CreateLetterHeadUserHelper = ({
    openPreview
} : CreateLetterHeadUserHelperProps) => {
    return (
        <Alert severity="info">
            <AlertTitle>
                <FormattedMessage id={'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.MESSAGE'} />
                <IconButton onClick={openPreview} children={<Help color="primary" />} />
            </AlertTitle>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'pre-line' }}>
                <FormattedMessage id={'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.MESSAGE.DETAIL'} />
            </Box>
        </Alert>
    )
}

export const CreateLetterHeadComponent = ({
    letterHead,
    companies,
    createLetterHead,
    companyDisabled,
    triggerButtonRef,
    variant = 'outlined'
}: CreateLetterHeadProps) => {
    const currentBtnRef = useRef();
    const intl = useIntl();
    const [openSamplePreview, setOpenSamplePreview] = useState(false);

    useEffect(() => {
        if (triggerButtonRef)
            triggerButtonRef.current = {
                saveForm() {
                    triggerButtonClick();
                }
            };
    }, []);

    const triggerButtonClick = () => {
        // @ts-ignore
        currentBtnRef?.current?.click();
    };

    const handleOpenSamplePreview = () => {
        setOpenSamplePreview(!openSamplePreview);
    };

    const validationSchema = Yup.object().shape({
        engagementClientCompanyId: Yup.string()
            .required(getLocalizedString(intl, 'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.COMPANY'))
            .nullable(),
        file: Yup.mixed()
            .required(getLocalizedString(intl, 'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.FILE'))
            .test('fileFormat', 'Unsupported Format', value => value && !UPLOAD_TYPES.LETTERHEAD.includes(value.type))
    });

    const UPLOAD_FIELDS = [
        {
            id: 'engagementClientCompanyId',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'SETTINGS.LETTERHEAD.SECTION.COMPANY', isRequired: true },
            options: companies ? companies : [{ id: 0, name: '' }],
            variant: variant,
            disabled: companyDisabled,
            placeholder: getLocalizedString(intl, 'SETTINGS.LETTERHEAD.SECTION.COMPANY_PLACEHOLDER')
        },
        {
            id: 'file',
            fieldType: FORM_FIELD.INPUT_FIELD,
            labelItem: { id: 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.LABEL', isRequired: true },
            acceptedFiles: UPLOAD_TYPES.LETTERHEAD,
            showPreviews: true,
            dropzoneText: getLocalizedString(intl, 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.MSG'),
            previewText: getLocalizedString(intl, 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.UPLOADED_FILES.LABEL'),
            hidden: false
        }
    ];

    const handleSubmit = async values => {
        if(values.file.length > 0) await createLetterHead({ ...values, file: values.file[0]}); 
    }

    const { RenderForm: UploadForm } = useForm({
        enableReinitialize: true,
        initialValues: letterHead,
        handleSubmit: handleSubmit,
        validationSchema: validationSchema
    });

    return (
        <>
            <CreateLetterHeadUserHelper openPreview={handleOpenSamplePreview} />
            
            <Grid>
                <UploadForm
                    formFields={UPLOAD_FIELDS}
                    conditionalFormKey={undefined}
                    conditionalFields={undefined}
                    submitRef={currentBtnRef}
                    submitTrigger={triggerButtonClick}
                />
            </Grid>

            {openSamplePreview && (
                <UIDialog
                    open={openSamplePreview}
                    titleId="SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.SAMPLE.TITLE"
                    onClose={handleOpenSamplePreview}>
                    <img
                        src={toAbsoluteUrl('/media/sample/mazars_letterhead.jpg')}
                        style={{
                            maxWidth: '100%',
                            height: '800px'
                        }}
                        alt="letterhead"
                    />
                </UIDialog>
            )}
        </>
    );
};
