import { Component } from 'react';
import './home.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers';
import { PROFILE } from '../../types/types';
import { DocumentPendingComponent as LostDocumentComponent } from '../../components/document/DocumentPending';
import { ConfirmationService, ItemService } from '../../services';
import { fetchData } from '../../services/FetchHelper';
import * as engagementService from '../../services/EngagementService';
import { AuditorHomeContent } from '../../components/home/AuditorHomeContent';
import { withEngagement } from '../../enhancers/withEngagement';
import { saveDownloadedFile } from '../../helpers/ItemHelper';
import { INIT_ITEMS_FILTER, SORT_CHOICES } from '../../kit/utils/constants';
import { HOME_COLUMNS_FILTERS } from '../../components/home/Constants';
import { ScreenSelector } from './ScreenSelector';
import {
    CONFIRMATION_COLUMNS_FILTERS,
    INIT_MENU_CONFIRMATION_FILTERS
} from '../../components/confirmation/common';
import { UILoader } from '../../components/common/UILoader';

/**
 * Home page component for user with auditor role.
 *
 * This home page give users an overview on their confirmations processes.
 * - Access to auditor's list of engagements
 * - Access to list of documents pending
 *
 * User actions
 * - Open engagement page
 * - Archive engagement
 * - Settings of the engagement
 * - Create a confirmation for the engagement
 *
 * @visibleName Home
 */

class HomeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: [],
            menuEngagements: null, // list of engagements displayed in the engagement dropdown of pending documents
            menuConfirmationsFilters: {
                // for pending documents accordion
                ...INIT_MENU_CONFIRMATION_FILTERS,
                [CONFIRMATION_COLUMNS_FILTERS.CLUSTER_ID]: props.currentClusterId
            },
            engagementsFilters: {
                [HOME_COLUMNS_FILTERS.ENGAGEMENT_NAME_OR_CODE]: '',
                pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
                pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
                isArchived: false,
                sort: SORT_CHOICES.CREATED_DESC,
                clusterId: props.currentClusterId
            },
            itemsFilters: {
                userprofile: PROFILE.AUDITOR,
                pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
                pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
                sort: SORT_CHOICES.UPDATED_DESC,
                displayMode: INIT_ITEMS_FILTER.DISPLAY_MODE_ROOT_ITEM_ONLY,
                engagementId: INIT_ITEMS_FILTER.ENGAGEMENT_ID,
                confirmationId: INIT_ITEMS_FILTER.CONFIRMATION_ID,
                isLost: true,
                clusterId: props.currentClusterId
            },
            docTypesFilters: {
                userprofile: PROFILE.AUDITOR
            },
            errorMessage: ''
        };
    }

    itemManager = ItemService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        callback: async (isSuccess, _data, _) => isSuccess && (await this.fetchItems(this.state.itemsFilters)),
        setLoading: this.setLoading
    });

    confirmationService = ConfirmationService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading
    });

    async componentDidMount() {
        const { itemsFilters, docTypesFilters } = this.state;
        await fetchData(this, [
            () => this.fetchAllEngagements(),
            () => this.fetchItems(itemsFilters),
            () => this.fetchDocumentTypes(docTypesFilters)
        ]);
    }

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.state.loading) return;

        if (this.state.engagementsFilters !== prevState.engagementsFilters) {
            await this.fetchAllEngagements();
        }

        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            this.handleEngagementsFilterChange({ clusterId: this.props.currentClusterId });
            this.handleItemsFilterChange({ clusterId: this.props.currentClusterId });
        }

        if (this.state.itemsFilters !== prevState.itemsFilters) {
            await this.fetchItems(this.state.itemsFilters);
        }
    }

    setLoading = isLoading => {
        this.setState({ loading: isLoading });
    };

    fetchAllEngagements = async () => {
        const { checkSession, addToastMessage, intl } = this.props;
        const { engagementsFilters } = this.state;

        await engagementService.fetchAll({
            intl,
            setLoading: this.setLoading,
            checkSession,
            addToastMessage,
            filters: engagementsFilters,
            callback: (isSuccess, data) => {
                isSuccess && this.setState({ engagementsPaginated: data.parsedValue });
            }
        });
    };

    handleDeleteEngagement = async engagementInfo => {
        const { performDeleteEngagement } = this.props;
        return performDeleteEngagement({
            engagementInfo: engagementInfo,
            setLoading: this.setLoading,
            successCallback: async () => {
                await this.fetchItems(this.state.itemsFilters);
                await this.fetchAllEngagements();
            }
        });
    };

    handleArchiveEngagement = async ({ engagement: { id, archived } }) => {
        const { performToggleArchiveEngagement } = this.props;
        const result = await performToggleArchiveEngagement({
            engagementId: id,
            setLoading: this.setLoading,
            strings: {
                successMessageId: `FETCH.ENGAGEMENT.${!archived ? 'ARCHIVE' : 'UNARCHIVE'}.SUCCESS`,
                errorMessageId: `FETCH.ENGAGEMENT.${!archived ? 'ARCHIVE' : 'UNARCHIVE'}.ERROR`
            },
            successCallback: async () => {
                await this.fetchItems(this.state.itemsFilters);
                await this.fetchAllEngagements();
            }
        });

        return result;
    };

    handleDownloadEngagement = async (engagement, downloadType) => {
        const { performDownloadEngagement } = this.props;
        return performDownloadEngagement({
            downloadType: downloadType,
            engagementId: engagement.id,
            setLoading: this.setLoading,
            engagementName: engagement.name
        });
    };

    fetchItems = async filters => {
        const result = await this.itemManager.fetch(filters);
        if (result?.isSuccess) {
            this.setState({ paginatedItems: result.parsedValue });
        }
    };

    downloadItem = async item => {
        await this.itemManager.download(item, (isSuccess, data, _) => isSuccess && saveDownloadedFile(data, item.name));
    };

    updateItem = async item => {
        await this.itemManager.update({ ...item, userProfile: PROFILE.AUDITOR });
    };

    fetchDocumentTypes = async filters => {
        const result = await this.itemManager.getDocumentTypes(filters);
        if (result?.isSuccess) {
            this.setState({ documentTypes: result?.body });
        }
    };

    handleEngagementsFilterChange = values => {
        this.setState({
            engagementsFilters: { ...this.state.engagementsFilters, ...values }
        });
    };

    handleItemsFilterChange = values => {
        this.setState({ itemsFilters: { ...this.state.itemsFilters, ...values } });
    };

    handleFetchMenuEngagements = async () => {
        await engagementService.fetchMenuEngagements(this);
    };

    fetchMenuConfirmations = async values => {
        const { currentClusterId } = this.props;
        const filters = { ...this.state.menuConfirmationsFilters, clusterId: currentClusterId, ...values };

        let menuConfirmations = [];
        await this.confirmationService.fetchMenu(filters, (isSuccess, result) => {
            if (isSuccess) {
                menuConfirmations = result.parsedValue;
            }
        });

        this.setState({ menuConfirmationsFilters: filters });
        return menuConfirmations;
    };

    render() {
        return this.state.isLoading ? (
            <UILoader />
        ) : (
            <ScreenSelector authorizedProfile={PROFILE.AUDITOR}>
                <LostDocumentComponent
                    setLoading={this.setLoading}
                    shouldPopulateMenuConfirmations={false}
                    isSearchEnabled
                    isRefuseEnabled={false}
                    handleFetchMenuConfirmations={this.fetchMenuConfirmations}
                    fetchMenuEngagement={this.handleFetchMenuEngagements}
                    titleId='DOCUMENTS.LOST.DOCUMENTS.SUBSECTION.TITLE'
                    user={this.props.user}
                    actionProfile={PROFILE.AUDITOR}
                    loading={this.state.loading}
                    paginatedItems={this.state.paginatedItems}
                    menuEngagements={this.state.menuEngagements}
                    documentTypes={this.state.documentTypes}
                    fetchItemFile={this.itemManager.fetchFile}
                    fetchItemInfo={this.itemManager.fetchInfo}
                    updateItem={this.updateItem}
                    downloadItem={this.downloadItem}
                    handleFilterItems={this.handleItemsFilterChange}
                    isDocumentPendingDisplay={false}
                />

                <AuditorHomeContent
                    user={this.props.user}
                    archiveEngagement={this.handleArchiveEngagement}
                    getDownloadEngagementOptions={this.props.getDownloadEngagementOptions}
                    resetEngagement={this.props.resetEngagement}
                    intl={this.props.intl}
                    loading={this.state.loading}
                    engagementsPaginated={this.state.engagementsPaginated}
                    filters={this.state.engagementsFilters}
                    deleteEngagement={this.handleDeleteEngagement}
                    handleDownloadEngagement={this.handleDownloadEngagement}
                    handleFilterChange={this.handleEngagementsFilterChange}
                />
            </ScreenSelector>
        );
    }
}

HomeComponent.propTypes = {
    user: PropTypes.object.isRequired,
    defaultPath: PropTypes.string.isRequired,
    toastMessageList: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    selectedRow: PropTypes.object,
    checkSessionResults: PropTypes.array,
    documentsLoaded: PropTypes.array,
    menuEngagements: PropTypes.array,
    engagements: PropTypes.array
};

HomeComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    },
    resetEngagement: () => {
        dispatch(actions.resetEngagement());
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        defaultPath: state.app.defaultPath,
        toastMessageList: state.app.toastMessageList,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const AuditorHome = compose(
    injectIntl,
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(withEngagement(HomeComponent));
