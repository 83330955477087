import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseArray } from '../parsers';
import { parseContact, parseContacts } from './contactParsers';
import { Repository } from '../Repository';

class EngagementClientContactRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetch = async (contactId) => {
        let result = await this.network.get(`EngagementClientContact/${contactId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseContact);
        return result;
    };

    fetchByCompany = async (engagementCompanyId) => {
        const result = await this.network.get(`EngagementClientContact/company/${engagementCompanyId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseContacts);
        return result;
    };

    deleteCompany = async (engagementClientCompanyId) => {
        return await this.network.delete(`EngagementClientContact/company/${engagementClientCompanyId}`, null, {
            headers: this.buildHeaders()
        });
    };

    create = async (contacts) => {
        let result = await this.network.post('EngagementClientContact', contacts, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseArray);

        return result;
    };

    update = async (contacts) => {
        return await this.network.put('EngagementClientContact', contacts, {
            headers: this.buildHeaders()
        });
    };

    delete = async (contacts) => {
        return await this.network.delete('EngagementClientContact', contacts, {
            headers: this.buildHeaders()
        });
    };

    /// Remove access of the contact from the given engagement
    deleteAccess = async (email, inviter, engagementId) => {
        const body = { email, inviter, engagementId };
        return await this.network.delete('EngagementClientContact/access/engagement/delete', body, {
            headers: this.buildHeaders()
        });
    };
}

export const engagementClientContactRepository = new EngagementClientContactRepository();
