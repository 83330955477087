import React, { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { initItem, ITEM_DOCUMENT_TYPE } from './InitItem';
import TextInput from '../textField/TextInput';
import { EditDocumentSection } from './EditDocumentSection';

export function DocumentInfoComponent(props) {
    const {
        intl,
        document,
        handleSubmit,
        classes,
        renderSelectDocumentType,
        renderSelectEngagement,
        renderSelectConfirmation
    } = props;

    const [documentInformation, setDocumentInformation] = useState(initItem);

    useEffect(() => {
        setDocumentInformation(document);
    }, [document]);

    const documentSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 2 }))
            .required(intl.formatMessage({ id: 'DOCUMENT.INFORMATION.VALIDATION.REQUIRED_FIELD.NAME' })),
        description: Yup.string().min(2, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 2 })),
        documentType: Yup.number(),
        engagementId: Yup.string().nullable(),
        confirmationId: Yup.string().nullable()
    });

    const uiOptions = {
        variant: 'standard',
        className: clsx(classes.margin, classes.textField),
        disabled: documentInformation?.documentType === ITEM_DOCUMENT_TYPE.LETTER_TEMPLATE
    };

    const DOCUMENT_FIELDS = [
        {
            labelItem: { id: 'DOCUMENT.INFORMATION.LABEL.NAME', isRequired: true },
            TextFieldItem: { id: 'name', component: TextInput, ...uiOptions }
        },
        {
            labelItem: { id: 'DOCUMENT.INFORMATION.LABEL.DESCRIPTION' },
            TextFieldItem: { id: 'description', component: TextInput, ...uiOptions }
        },
        {
            labelItem: {
                id: 'DOCUMENT.INFORMATION.LABEL.DOCUMENT_TYPE',
                isHidden: renderSelectDocumentType.props?.hidden
            },
            CustomFieldItem: { render: renderSelectDocumentType, customProps: { id: 'documentType', ...uiOptions } }
        },
        {
            labelItem: {
                id: 'DOCUMENT.INFORMATION.LABEL.ENGAGEMENT',
                isHidden: renderSelectEngagement.props?.hidden
            },
            CustomFieldItem: {
                render: renderSelectEngagement,
                customProps: { id: 'engagementId', ...uiOptions }
            }
        },
        {
            labelItem: {
                id: 'DOCUMENT.INFORMATION.LABEL.CONFIRMATION',
                isHidden: renderSelectConfirmation.props?.hidden
            },
            CustomFieldItem: {
                render: renderSelectConfirmation,
                customProps: { id: 'confirmationId', ...uiOptions }
            }
        }
    ];

    const buttonFormRef = useRef(null);
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    props.sbmtDocRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={documentInformation}
            validationSchema={documentSchema}
            onSubmit={handleSubmit}>
            <Form>
                <EditDocumentSection fields={DOCUMENT_FIELDS} />

                <button type="submit" style={{ display: 'none' }} onClick={triggerButtonClick} ref={buttonFormRef} />
            </Form>
        </Formik>
    );
}
