import * as actions from '../actions';
import { initEngagement } from '../../components/engagement/InitEngagement';
import { INIT_ENGAGEMENT_FILTER } from '../../kit/utils/constants';

const initialState = {
    newEngagement: {
        ...initEngagement
    },
    activeStepPosition: 0,
    confirmationsFilters: {
        ...INIT_ENGAGEMENT_FILTER,
        isTrashed: false
    },
    selectedConfirmationIndex: null
};

export const engagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.EngagementTypes.SET_ENGAGEMENT:
            return {
                ...state,
                newEngagement: { ...state.newEngagement, ...action.engagement }
            };

        case actions.EngagementTypes.SET_ACTIVE_STEP_POSITION: {
            return { ...state, activeStepPosition: action.activeStepPosition };
        }

        case actions.EngagementTypes.RESET_ENGAGEMENT:
            return { ...state, ...initialState };

        case actions.EngagementTypes.RESET_ACTIVE_STEP_POSITION:
            return { ...state, activeStepPosition: 0 };

        case actions.EngagementTypes.SET_CONFIRMATIONS_FILTERS:
            return { ...state, confirmationsFilters: { ...action.filters } };

        case actions.EngagementTypes.RESET_CONFIRMATIONS_FILTERS:
            return {
                ...state,
                confirmationsFilters: { ...initialState.confirmationsFilters },
                selectedConfirmationIndex: null
            };

        case actions.EngagementTypes.SET_SELECTED_CONFIRMATION_INDEX:
            return { ...state, selectedConfirmationIndex: action.index };

        default:
            return state;
    }
};
