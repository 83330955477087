import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';
import TextInput from '../../textField/TextInput';
import { Card } from '../../../partials/controls/Card';
import { FORM_FIELD } from '../../../helpers';
import { useForm } from '../../../hooks/useForm';

/**
 * Component to create an email template.
 * @param {*} props
 * @returns
 */
function EmailTemplateFormComponent(props) {
    const { intl, emailTemplate, confirmationCategories, languages, handleSubmit, disabled } = props;
    const variant = disabled ? 'standard' : 'outlined';

    const buttonTemplFormRef = useRef(null);
    const triggerButtonClick = () => buttonTemplFormRef?.current?.click();
    props.sbmtTemplRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const EmailTemplateSchema = Yup.object().shape({
        emailTypeId: Yup.string()
            .nullable(true)
            .required(intl.formatMessage({ id: 'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.TYPE' })),
        name: Yup.string()
            .nullable(true)
            .min(5, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 5 }))
            .required(intl.formatMessage({ id: 'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.NAME' })),
        description: Yup.string()
            .nullable(true)
            .min(5, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 5 })),
        confirmationCategoryId: Yup.string()
            .nullable(true)
            .required(
                intl.formatMessage({ id: 'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.CONFIRMATION.CATEGORY' })
            ),
        languageId: Yup.string()
            .nullable(true)
            .required(intl.formatMessage({ id: 'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.LANGUAGE' })),
        subject: Yup.string()
            .nullable(true)
            .min(5, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 5 }))
            .required(intl.formatMessage({ id: 'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.SUBJECT' })),
        body: Yup.string()
            .nullable(true)
            .min(5, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 5 }))
            .required(intl.formatMessage({ id: 'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.BODY' }))
    });

    const TEMPLATE_FIELDS = [
        {
            id: 'name',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'EMAIL_TEMPLATE.INFO.FIELD.NAME.LABEL', isRequired: true },
            component: disabled && TextInput,
            variant: variant,
            disabled: disabled ?? false
        },
        {
            id: 'description',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'EMAIL_TEMPLATE.INFO.FIELD.DESCRIPTION.LABEL' },
            component: disabled && TextInput,
            variant: variant,
            disabled: disabled ?? false
        },
        {
            id: 'confirmationCategoryId',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'EMAIL_TEMPLATE.INFO.FIELD.CONFIRMATION.CATEGORY.LABEL', isRequired: true },
            options: confirmationCategories ? confirmationCategories : [{ id: 0, name: 'None' }],
            variant: variant,
            disabled: disabled ?? false
        },
        {
            id: 'languageId',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'EMAIL_TEMPLATE.INFO.FIELD.LANGUAGE.LABEL', isRequired: true },
            options: languages ? languages : [{ id: 0, name: 'None' }],
            variant: variant,
            disabled: disabled ?? false
        },
        {
            id: 'subject',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'EMAIL_TEMPLATE.INFO.FIELD.SUBJECT.LABEL', isRequired: true },
            component: disabled && TextInput,
            variant: variant,
            disabled: disabled ?? false
        },
        {
            id: 'body',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'EMAIL_TEMPLATE.INFO.FIELD.BODY.LABEL', isRequired: true },
            component: 'textarea',
            rows: 10,
            variant: 'outlined',
            disabled: disabled ?? false
        }
    ];

    const { RenderForm } = useForm({
        enableReinitialize: true,
        initialValues: emailTemplate,
        validationSchema: EmailTemplateSchema,
        handleSubmit: handleSubmit
    });

    return (
        <>
            <Card>
                <RenderForm
                    formFields={TEMPLATE_FIELDS}
                    submitRef={buttonTemplFormRef}
                    submitTrigger={triggerButtonClick}
                />
            </Card>
        </>
    );
}

export const EmailTemplateForm = injectIntl(EmailTemplateFormComponent);
