import { AttachFileOutlined, EmailOutlined, LinkOutlined, VideocamOutlined } from '@mui/icons-material';
import * as React from 'react';
import { E_USER_RESOURCE_TYPES } from '../setup/generalConfig/InitUserResourceConfig';
import { Grid } from '@mui/material';
import { UIDropDownMenuEntryProps } from '../common/UIDropDownMenu';
import { UIText } from '../common/UIText';
import { useState } from 'react';
import { UIDialog } from '../common/UIDialog';

export const UserResourceTitleComponent = ({ icon: ResourceIcon, title }: { icon?: JSX.Element; title: string }) => {
    return (
        <Grid container justifyContent="flex-start" spacing={1} sx={{ flexWrap: 'nowrap' }}>
            <Grid item>{ResourceIcon}</Grid>
            <Grid item>
                <label>{title}</label>
            </Grid>
        </Grid>
    );
};

export function UserResourceDialog({
    title,
    content,
    icon,
    dialogTitle = 'USER.RESOURCE.MENU.CONTACT.US'
}: {
    icon?: JSX.Element;
    title: string;
    content: string;
    dialogTitle?: string;
}) {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    return (
        <>
            <div onClick={handleShow}>
                <UserResourceTitleComponent title={title} icon={icon} />
            </div>

            <UIDialog classes={{ maxWidth: 'xs' }} titleId={dialogTitle} open={show} onClose={handleClose}>
                <UIText text={content} />
            </UIDialog>
        </>
    );
}

export interface UserResourceReducerProps {
    resource: any;
    handleDownloadFile: (resource: any) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<any>;
}

export const userResourceReducer = ({
    resource,
    handleDownloadFile
}: UserResourceReducerProps): UIDropDownMenuEntryProps => {
    let initialState: UIDropDownMenuEntryProps = {
        id: resource?.id,
        title: resource?.name,
        handler: undefined,
        titleStyle: null,
        titleComponent: null
    };

    if (!resource?.id) return { ...initialState };

    switch (resource.eUserResourceType) {
        case E_USER_RESOURCE_TYPES.FILE:
            return {
                ...initialState,
                titleComponent: () => (
                    <div onClick={handleDownloadFile(resource)}>
                        <UserResourceTitleComponent title={resource.name} icon={<AttachFileOutlined />} />
                    </div>
                )
            };

        case E_USER_RESOURCE_TYPES.LINK:
            return {
                ...initialState,
                titleComponent: () => <UserResourceTitleComponent title={resource.name} icon={<LinkOutlined />} />,
                handler: () => {
                    window.open(resource.url);
                }
            };

        case E_USER_RESOURCE_TYPES.TEXT:
            return {
                ...initialState,
                titleComponent: () => (
                    <UserResourceDialog title={resource.name} icon={<EmailOutlined />} content={resource.description} />
                )
            };

        case E_USER_RESOURCE_TYPES.VIDEO_LINK:
            return {
                ...initialState,
                titleComponent: () => <UserResourceTitleComponent title={resource.name} icon={<VideocamOutlined />} />,
                handler: () => {
                    window.open(resource.url);
                }
            };

        default:
            return { ...initialState, title: resource?.name };
    }
};
