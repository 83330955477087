import { FormControl, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export function StackableSteps({ stackableSteps, handleSelectStackableStep, isEditable = true }) {
    return (
        <>
            {stackableSteps?.length > 1 && (
                <FormControl component="fieldset">
                    {React.Children.toArray(
                        stackableSteps.map(step => (
                            <RadioGroup
                                aria-label="steps"
                                value={step.isActive}
                                onChange={_ => handleSelectStackableStep(step, stackableSteps)}>
                                <FormControlLabel
                                    checked={step.isActive}
                                    disabled={!isEditable}
                                    control={<Radio />}
                                    label={step.name}
                                />
                            </RadioGroup>
                        ))
                    )}
                </FormControl>
            )}
        </>
    );
}
