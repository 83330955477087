// import { FORM_FIELD } from "../../../../helpers";

export const AUTOMATION_FIELDS = [
    {
        itemKey: '0',
        labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.CLIENT.AUTOMATIC.SENDING.LABEL' },
        SwitchFieldItem: {
            id: 'automaticClientSending',
            // method: () => handleConfirmationUpdate({ 'automaticClientSending': !confirmation.automaticClientSending }),
            // value: confirmation.automaticClientSending,
            color: 'primary'
        }
    },
    {
        itemKey: '1',
        labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.AUTOMATIC.SENDING.LABEL' },
        SwitchFieldItem: {
            id: 'automaticThirdPartySending',
            // method: () => handleConfirmationUpdate({ 'automaticThirdPartySending': !confirmation.automaticThirdPartySending }),
            // value: confirmation.automaticThirdPartySending,
            color: 'primary'
        }
    }
];

// to use with useForm Hook
// export const AUTOMATION_FIELDS = [
//     {
//         id: 'automaticClientSending',
//         fieldType: FORM_FIELD.SWITCH_FIELD,
//         labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.CLIENT.AUTOMATIC.SENDING.LABEL' },
//         color: 'primary'
//     },
//     {
//         id: 'automaticThirdPartySending',
//         fieldType: FORM_FIELD.SWITCH_FIELD,
//         labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.AUTOMATIC.SENDING.LABEL' },
//         color: 'primary'
//     },
// ];