import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import React, { useState } from 'react';
import './mzrlogin.scss';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { getLocalizedString } from '../../i18n/i18Helper';
import { useForm } from '../../hooks/useForm';
import { FORM_FIELD } from '../../helpers';
import { initExternLoginInfo, ExternAuthInfoSpec } from './initLoginAuth';
import { AuthForm } from './AuthForm';
import { AuthFooter } from './AuthFooter';
import { UIPasswordInput } from '../common/UIPasswordInput';

interface ClientAuthContentProps {
    handleSignIn: (info: ExternAuthInfoSpec) => Promise<any>;
}

export const ClientAuthContent = ({ handleSignIn }: UIScaffoldChildContext & ClientAuthContentProps) => {
    const intl = useIntl();

    const [loginInfo, setLoginInfo] = useState<ExternAuthInfoSpec>(initExternLoginInfo);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(getLocalizedString(intl, 'INVALID.FIELD.EMAIL.FORMAT'))
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'AUTH.VALIDATION.REQUIRED_FIELD')),
        password: Yup.string()
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'AUTH.VALIDATION.REQUIRED_FIELD'))
    });

    const fields = [
        {
            id: 'email',
            name: 'email',
            type: 'email',
            fieldType: FORM_FIELD.TEXT_FIELD,
            placeholder: getLocalizedString(intl, 'AUTH.INPUT.EMAIL'),
            component: 'input',
            style: { backgroundPosition: 'right 1em center' },
            className: 'form-control form-control-solid h-auto py-5 px-6'
        },
        {
            id: 'password',
            name: 'password',
            type: 'password',
            fieldType: FORM_FIELD.TEXT_FIELD,
            placeholder: getLocalizedString(intl, 'AUTH.INPUT.PASSWORD'),
            component: UIPasswordInput,
            className: 'form-control form-control-solid h-auto py-5 px-6',
            style: { backgroundPosition: 'right 1em center' }
        }
    ];

    const handleSubmit = async (info: ExternAuthInfoSpec) => {
        setLoginInfo(info);
        await handleSignIn(info);
    };

    const { RenderForm: LoginForm } = useForm({
        initialValues: loginInfo,
        handleSubmit: handleSubmit,
        enableReinitialize: true,
        validationSchema: validationSchema
    });

    return (
        <Box className="flex-row-fluid d-flex flex-column position-relative p-12 pl-16 overflow-hidden mt-30 mt-lg-0">
            <AuthForm fields={fields} form={LoginForm} title="AUTH.LOGIN.AS.EXTERNAL.TITLE" footer={<AuthFooter />} />
        </Box>
    );
};
