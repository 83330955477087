import React, { useEffect, useState, useContext } from 'react';
import { initItem } from '../../../../document/InitItem';
import { initPaginatedObject } from '../../../../common/InitPaginatedObject';
import { ConfirmationDocumentsRecycleBin } from './ConfirmationDocumentsRecycleBin';
import { ConfirmationDocumentsSection } from './ConfirmationDocumentsSection';
import { ItemContext } from '../../../../../context';

interface ConfirmationDocumentsContainerProps {
    user: any;
    paginatedItems: Record<string, any>;
    documentTypes: any[];
    itemErrors: any;
    confirmation: any;
    downloadConfirmationFiles: (confirmationId) => Promise<any>;
    handleFetchMenuConfirmations: (filters: Record<string, any>) => Promise<any>;
}

export function ConfirmationDocumentsContainer({
    user,
    paginatedItems,
    documentTypes,
    itemErrors,
    confirmation,
    downloadConfirmationFiles,
    handleFetchMenuConfirmations
}: ConfirmationDocumentsContainerProps) {
    const { uploadItems, filterItems } = useContext(ItemContext);

    const [isRecycleBinDisplay, setIsRecycleBinDisplay] = useState(false);
    const [currentPaginatedDocuments, setCurrentPaginatedDocuments] = useState(initPaginatedObject);

    const [selectedItem, setItem] = useState({
        ...initItem,
        ...{ engagementId: confirmation?.engagementId, confirmationId: confirmation?.id }
    });

    useEffect(() => {
        setCurrentPaginatedDocuments(initPaginatedObject);
        filterItems({ isTrashed: isRecycleBinDisplay });
    }, [isRecycleBinDisplay]);

    useEffect(() => {
        setCurrentPaginatedDocuments(paginatedItems);
    }, [paginatedItems]);

    useEffect(() => {
        setItem({
            ...initItem,
            ...{ engagementId: confirmation?.engagementId, confirmationId: confirmation?.id }
        });
    }, [confirmation, paginatedItems]);

    const handleUpload = async values => {
        await uploadItems(values.files, values.isGrouped, selectedItem);
    };

    const handleDownloadFiles = async () => {
        await downloadConfirmationFiles(confirmation?.id);
    };

    const handleOpenRecycleBin = () => {
        setIsRecycleBinDisplay(!isRecycleBinDisplay);
    };

    return (
        <>
            {isRecycleBinDisplay ? (
                <ConfirmationDocumentsRecycleBin
                    handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                    confirmation={confirmation}
                    handleBack={handleOpenRecycleBin}
                    paginatedDocuments={currentPaginatedDocuments}
                    documentTypes={documentTypes}
                    itemErrors={itemErrors}
                    user={user}
                />
            ) : (
                <ConfirmationDocumentsSection
                    handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                    handleOpenRecycleBin={handleOpenRecycleBin}
                    handleUpload={handleUpload}
                    handleDownloadAll={handleDownloadFiles}
                    confirmation={confirmation}
                    paginatedDocuments={currentPaginatedDocuments}
                    documentTypes={documentTypes}
                    itemErrors={itemErrors}
                    user={user}
                />
            )}
        </>
    );
}
