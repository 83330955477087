import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';
import { initLostItemConfig } from './InitLostItemConfig';
import { FORM_FIELD } from '../../../helpers';
import { useForm } from '../../../hooks/useForm';
import { getLocalizedString } from '../../../i18n/i18Helper';

const LostItemConfigComponent = props => {
    const { intl, lostItemConfig, handleSubmit } = props;
    const buttonLostItemFormRef = useRef(null);
    const [currentLostItemConfig, setCurrentLostItemConfig] = useState(initLostItemConfig);

    useEffect(() => {
        lostItemConfig && setCurrentLostItemConfig(lostItemConfig);
    }, [lostItemConfig]);

    const triggerButtonClick = () => buttonLostItemFormRef?.current?.click();
    props.lostItemConfigRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const LostItemConfigSchema = Yup.object().shape({
        clusterId: Yup.string().nullable(true),
        isSupportMandatoryToAssignAsLostItem: Yup.bool()
            .required(getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_MANDATORY_SUPPORT_TO_ASSIGN')),
        isClaimMandatory: Yup.bool()
            .required(getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_CLAIM_MANDATORY')),
        isReadAllowed: Yup.bool()
            .required(getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_READ_ALLOWED'))
    });

    const LOST_ITEM_FIELDS = [
        {
            id: 'isSupportMandatoryToAssignAsLostItem',
            fieldType: FORM_FIELD.SWITCH_FIELD,
            labelItem: { id: 'LOST.ITEM.CONFIGURATION.IS_MANDATORY_SUPPORT_TO_ASSIGN.LABEL' },
            notCheckedLabelId: 'LOST.ITEM.CONFIGURATION.SWITCH.OFF.LABEL',
            notCheckedTooltipLabel: getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.SWITCH.OFF.LABEL'),
            checkedLabelId: 'LOST.ITEM.CONFIGURATION.SWITCH.ACTIVE.LABEL',
            checkedTooltipLabel: getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.SWITCH.ACTIVE.LABEL'),
            color: 'primary'
        },
        {
            id: 'isClaimMandatory',
            fieldType: FORM_FIELD.SWITCH_FIELD,
            labelItem: { id: 'LOST.ITEM.CONFIGURATION.IS_CLAIM_MANDATORY.LABEL' },
            notCheckedLabelId: 'LOST.ITEM.CONFIGURATION.SWITCH.OFF.LABEL',
            notCheckedTooltipLabel: getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.SWITCH.OFF.LABEL'),
            checkedLabelId: 'LOST.ITEM.CONFIGURATION.SWITCH.ACTIVE.LABEL',
            checkedTooltipLabel: getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.SWITCH.ACTIVE.LABEL'),
            color: 'primary'
        },
        {
            id: 'isReadAllowed',
            fieldType: FORM_FIELD.SWITCH_FIELD,
            labelItem: { id: 'LOST.ITEM.CONFIGURATION.IS_READ_ALLOWED.LABEL' },
            notCheckedLabelId: 'LOST.ITEM.CONFIGURATION.SWITCH.OFF.LABEL',
            notCheckedTooltipLabel: getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.SWITCH.OFF.LABEL'),
            checkedLabelId: 'LOST.ITEM.CONFIGURATION.SWITCH.ACTIVE.LABEL',
            checkedTooltipLabel: getLocalizedString(intl, 'LOST.ITEM.CONFIGURATION.SWITCH.ACTIVE.LABEL'),
            color: 'primary'
        }
    ];

    // TODO: review trigger mechanism to display save button on touched (RobotConfig)
    const { RenderForm } = useForm({
        enableReinitialize: true,
        initialValues: currentLostItemConfig,
        validationSchema: LostItemConfigSchema,
        handleSubmit: handleSubmit
    });

    return (
        <>
            <RenderForm
                formFields={LOST_ITEM_FIELDS}
                submitRef={buttonLostItemFormRef}
                submitTrigger={triggerButtonClick}
            />
        </>
    );
};

export const LostItemConfig = injectIntl(LostItemConfigComponent);
