export const mailing = {
    // General
    'MAILING.CONFIG.TITLE': 'Mailing Setup',
    'MAILING.CONFIG.CARDHEADER.MAILING.TITLE': 'Mailing configuration',
    'MAILING.CONFIG.SECTION.SMTP.TITLE': 'SMTP',
    'MAILING.CONFIG.SECTION.IMAP.TITLE': 'IMAP',
    'MAILING.CONFIG.SECTION.MAILBOX.FOLDERS.TITLE': 'MAILBOX FOLDERS',

    'MAILING.CONFIGURATION.BASICAUTH.TYPE.LABEL': 'BasicAuth',
    'MAILING.CONFIGURATION.MODERNAUTH.TYPE.LABEL': 'ModernAuth',

    'MAILING.CONFIG.BASIC.AUTH.PROVIDER.NAME.LABEL': 'Mailbox provider',
    'MAILING.CONFIG.BASIC.AUTH.PROVIDER.NAME.PLACEHOLDER': 'Microsoft 365',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROVIDER.NAME': 'Mailbox provider name is required',

    'MAILING.CONFIG.BASIC.AUTH.DISPLAY.NAME.LABEL': 'Name of the sender that will be displayed',
    'MAILING.CONFIG.BASIC.AUTH.DISPLAY.NAME.PLACEHOLDER': 'Forvis Mazars Confirmations',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCOUNT.LABEL': 'SMTP - Account',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCOUNT.PLACEHOLDER': 'confirmations@mazars.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.ACCOUNT': 'SMTP account is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.PASSWORD.LABEL': 'SMTP - Account password',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.PASSWORD.PLACEHOLDER': 'azerty1234',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.PASSWORD': 'SMTP account password is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.HOST.LABEL': 'SMTP - Host',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.HOST.PLACEHOLDER': 'smtp.office365.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.HOST': 'SMTP host is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.PORT.LABEL': 'SMTP - Port',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.PORT.PLACEHOLDER': '587',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.PORT': 'SMTP port is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.SECURE_SOCKET_OPTIONS.LABEL': 'SMTP - Secure socket option',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.SECURE_SOCKET_OPTIONS.PLACEHOLDER': 'None',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.SECURE_SOCKET_OPTIONS': 'SMTP secure socket option is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.LABEL': 'SMTP - Accept all SSL certificates',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.PLACEHOLDER': 'False',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.ACCEPT_ALL_SSL_CERTIFICATES': 'SMTP accept all SSL certificates is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.LABEL': 'No',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.TOOLTIP': 'Unactive accept all SSL certificate',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.YES.LABEL': 'Yes',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.YES.TOOLTIP': 'Active accept all SSL certificate',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCOUNT.LABEL': 'IMAP - Account',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCOUNT.PLACEHOLDER': 'confirmations@mazars.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.ACCOUNT': 'IMAP account is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.PASSWORD.LABEL': 'IMAP - Account password',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.PASSWORD.PLACEHOLDER': 'azerty1234',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.PASSWORD': 'IMAP account password is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.HOST.LABEL': 'IMAP - Host',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.HOST.PLACEHOLDER': 'outlook.office365.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.HOST': 'IMAP host is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.PORT.LABEL': 'IMAP - Port',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.PORT.PLACEHOLDER': '993',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.PORT': 'IMAP port is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.SECURE_SOCKET_OPTIONS.LABEL': 'IMAP - Secure socket option',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.SECURE_SOCKET_OPTIONS.PLACEHOLDER': 'None',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.SECURE_SOCKET_OPTIONS': 'IMAP secure socket option is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.LABEL': 'IMAP - Accept all SSL certificates',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.PLACEHOLDER': 'False',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.ACCEPT_ALL_SSL_CERTIFICATES': 'IMAP accept all SSL certificates is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.NO.LABEL': 'Non',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.NO.TOOLTIP': 'Unactive accept all SSL certificate',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.YES.LABEL': 'Oui',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.YES.TOOLTIP': 'Active accept all SSL certificate',

    'MAILING.CONFIG.ADMINISTRATORS.LABEL': 'Adresses électroniques des administrateurs - utiliser la virgule (,) comme séparateur sans espace.',
    'MAILING.CONFIG.ADMINISTRATORS.PLACEHOLDER': 'administrator1@mazars.com, administrator2@mazars.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.ADMINISTRATOR': 'Au moins une adresse électronique d\'un administrateur est requise',

    'MAILING.CONFIG.INBOX.FOLDER.LABEL': 'Dossier Inbox',
    'MAILING.CONFIG.INBOX.FOLDER.PLACEHOLDER': 'Boîte de réception',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.INBOX.FOLDER': 'Le chemin d\'accès au dossier Boîte de réception de la boîte aux lettres est requis',

    'MAILING.CONFIG.SENT_ITEMS.FOLDER.LABEL': 'Dossier Éléments envoyés',
    'MAILING.CONFIG.SENT_ITEMS.FOLDER.PLACEHOLDER': 'Éléments envoyés',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SENT_ITEMS.FOLDER': 'Le chemin d\'accès au dossier Éléments envoyés de la boîte aux lettres est requis',

    'MAILING.CONFIG.WIP.FOLDER.LABEL': 'Dossier Traitements en cours',
    'MAILING.CONFIG.WIP.FOLDER.PLACEHOLDER': 'WIP',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.WIP.FOLDER': 'Le chemin d\'accès au dossier Traitements en cours de la boîte aux lettres est requis',

    'MAILING.CONFIG.PROCESSED.FOLDER.LABEL': 'Dossier Traités',
    'MAILING.CONFIG.PROCESSED.FOLDER.PLACEHOLDER': 'PROCESSED',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROCESSED.FOLDER': 'Le chemin d\'accès au dossier Traités de la boîte aux lettres est requis',

    'MAILING.CONFIG.REQUESTS.FOLDER.LABEL': 'Dossier Demandes',
    'MAILING.CONFIG.REQUESTS.FOLDER.PLACEHOLDER': 'REQUESTS',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.REQUESTS.FOLDER': 'Le chemin d\'accès au dossier Demandes de la boîte aux lettres est requis',

    'MAILING.CONFIG.ISSUES.FOLDER.LABEL': 'Dossier Problèmes',
    'MAILING.CONFIG.ISSUES.FOLDER.PLACEHOLDER': 'ISSUES',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.ISSUES.FOLDER': 'Le chemin d\'accès au dossier Problèmes de la boîte aux lettres est requis',

    'MAILING.CONFIG.OTHERS.FOLDER.LABEL': 'Dossier Autres',
    'MAILING.CONFIG.OTHERS.FOLDER.PLACEHOLDER': 'OTHERS',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.OTHERS.FOLDER': 'Le chemin d\'accès au dossier Autres de la boîte aux lettres est requis',

    'MAILING.CONFIG.FOLLOWUP.NB.DAYS.LABEL': 'Nombre de jours de restriction pour le prochain suivi',
    'MAILING.CONFIG.FOLLOWUP.NB.DAYS.PLACEHOLDER': '7',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.FOLLOWUP.NB.DAYS': 'Nombre de jours pendant lesquels la restriction est requise',

    'MAILING.CONFIG.TYPE.AUTH.LABEL': 'Type d\'authentification',
    'MAILING.CONFIG.TYPE.AUTH.PLACEHOLDER': 'Authentification de basique (login/mot de passe)',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.TYPE.AUTH': 'Type d\'authentification requis pour l\'accès à la boîte aux lettres',

    'MAILING.CONFIG.MODERNAUTH.PROVIDER.LABEL': 'Fournisseur d\'authentification de la boîte aux lettres',
    'MAILING.CONFIG.MODERNAUTH.PROVIDER.PLACEHOLDER': 'Microsoft Online',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROVIDER': 'Le fournisseur d\'authentification de la boîte aux lettres est requis',

    'MAILING.CONFIG.MODERNAUTH.USERNAME.LABEL': 'Adresse électronique du compte',
    'MAILING.CONFIG.MODERNAUTH.USERNAME.PLACEHOLDER': 'confirmations@mazars.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.USERNAME': 'Un compte de messagerie est nécessaire',

    'MAILING.CONFIG.MODERNAUTH.USER_ID.LABEL': 'Identifiant unique du compte du fournisseur d\'authentification',
    'MAILING.CONFIG.MODERNAUTH.USER_ID.PLACEHOLDER': 'e.g. 25a6ad59-cb79-4e72-abed-9c034b080a08',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.USER_ID': 'L\'identifiant unique du compte du fournisseur d\'authentification est requis',

    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.NONE.LABEL': 'Aucune',
    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.SSL_ON_CONNECT.LABEL': 'SSL à la connexion',
    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.START_TLS.LABEL': 'Démarrer TLS',
    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.START_TLS_WHEN_AVAILABLE.LABEL': 'Démarrer TLS si disponible',
    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.AUTO.LABEL': 'Auto',

    'MAILING.CONFIGURATION.ACCEPT_ALL_SSL_CERTIFICATES.NO.LABEL': 'Non',
    'MAILING.CONFIGURATION.ACCEPT_ALL_SSL_CERTIFICATES.YES.LABEL': 'Oui',

    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.LABEL': 'Suppression automatique des emails',
    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.TOOLTIP': 'L\'option de suppression automatique des emails permet de supprimer chaque email de la boîte aux lettres après la fin du traitement automatique.',
    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.DEACTIVATED.LABEL': 'Désactivé',
    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.ACTIVATED.LABEL': 'Activé',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.AUTOMATIC_DELETE_EMAILS': 'La configuration de la suppression automatique des emails est requise',

    // Requests messages
    'MAILING.CONFIG.ACCESS.ERROR': 'Échec de l\'accès à la configuration de la boîte aux lettres',
    'MAILING.CONFIG.USER.PROFILE.ERROR': 'Échec de l\'accès au profile utilisateur',
    'MAILING.CONFIG.CREATE.ERROR': 'Échec de la création de la configuration de la boîte aux lettres',
    'MAILING.CONFIG.CREATE.SUCCESS': 'Configuration de la boîte aux lettres créée',
    'MAILING.CONFIG.UPDATE.ERROR': 'Échec de la modification de la configuration de la boîte aux lettres',
    'MAILING.CONFIG.UPDATE.SUCCESS': 'Configuration de la boîte aux lettres modifiée',
    'MAILING.AUTH.PROVIDERS.ACCESS.ERROR': 'L\'accès aux fournisseurs d\'authentification a échoué',
    'MAILING.AUTH.PROVIDERS.CREATE.SUCCESS': 'Fournisseurs d\'authentification créé',
    'MAILING.AUTH.PROVIDERS.CREATE.ERROR': 'Échec de la création du fournisseurs d\'authentification',
    'MAILING.AUTH.PROVIDERS.UPDATE.SUCCESS': 'Fournisseurs d\'authentification modifié',
    'MAILING.AUTH.PROVIDERS.UPDATE.ERROR': 'Échec de la modification du fournisseurs d\'authentification',
    'MAILING.AUTH.PROVIDERS.DELETE.SUCCESS':  'Fournisseurs d\'authentification supprimé',
    'MAILING.AUTH.PROVIDERS.DELETE.ERROR': 'Échec de la suppression du fournisseurs d\'authentification',
};
