import { createContext } from 'react';

interface LetterHeadContextSpec {
    createLetterHead?: (contact: any) => void;
    deleteLetterHead?: (contact: any) => void;
    fetchLetterHeadFile?: (contact: any) => void;
}

const letterheadContextDefaultValue: LetterHeadContextSpec = {
    createLetterHead: undefined,
    deleteLetterHead: undefined,
    fetchLetterHeadFile: undefined
};

export const LetterHeadContext = createContext(letterheadContextDefaultValue);
