import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../createMethod.css';
import { Card, CardBody, CardHeader, CardFooter } from '../../../../partials/controls/Card';
import { ListItemText, Divider, Typography, Grid } from '@mui/material';
import { LetterTemplateFilters } from './LetterTemplateFilters';
import { VisibilityOutlined, CheckCircleOutlineOutlined } from '@mui/icons-material';
import { getMainWorkflowActions } from '../../../../helpers/WorkflowHelper';
import { CreateConfirmationStepper } from '../CreateConfirmationStepper';
import { LetterTemplateContext } from '../../../setup';
import { Palette } from '../../../../styles/colors';
import { UIActionButton } from '../../../common/UIActionButton';
import { getLocalizedString } from '../../../../i18n/i18Helper';
import { DocumentPreview } from '../../../document/DocumentPreview';

const styles = {
    carousel: {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 3,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
        }
    }
};

const nameWrapper = (list, key, value) => {
    return list ? (
        list.filter(x => x[key] === value)[0]?.name
    ) : (
        <FormattedMessage id="CREATE_CONFIRMATION.SELECT_TEMPLATE.LOADING.LABEL" />
    );
};

const LetterTemplateEntry = ({ titleId, name }) => {
    return (
        <Typography component="span" variant="body2" color={Palette.sonicSilver} display="block">
            <strong>
                <FormattedMessage id={titleId} />
            </strong>
            {name}
        </Typography>
    );
};

const LetterTemplateDetails = ({ template, confirmationCategories, languages }) => {
    return (
        <>
            <LetterTemplateEntry
                titleId={'CREATE_CONFIRMATION.SELECT_TEMPLATE.TYPE.LABEL'}
                name={nameWrapper(confirmationCategories, 'id', template.confirmationCategoryId)}
            />

            <LetterTemplateEntry
                titleId={'CREATE_CONFIRMATION.SELECT_TEMPLATE.LANGUAGE.LABEL'}
                name={nameWrapper(languages, 'id', template.languageId)}
            />

            <LetterTemplateEntry titleId={'CREATE_CONFIRMATION.SELECT_TEMPLATE.UPDATE.LABEL'} name={template.updated} />
        </>
    );
};

const LetterTemplateCarouselItem = ({
    template,
    handleSelectTemplate,
    confirmationCategories,
    languages,
    handleSeeMore
}) => {
    const intl = useIntl();

    const handleSelect = event => {
        event.stopPropagation();
        handleSelectTemplate(template);
    };

    const handleViewDetails = async event => {
        event.stopPropagation();
        await handleSeeMore(template);
    };

    return (
        <Card
            className="pl-10 pr-10"
            style={{ height: '100%', margin: 5 }}
            id={template.id}
            key={`key_card_confirmation_template_${template.id}`}
            onClick={event => {
                handleSelectTemplate(template);
                event.stopPropagation();
            }}>
            <CardHeader title={template.name} key={`key_cardheader_confirmation_template_${template.id}`} />

            <CardBody key={`key_cardbody_confirmation_template_${template.id}`}>
                <ListItemText
                    primary={
                        <Typography component="span" variant="subtitle1" align="justify">
                            {template.description}
                            <Divider />
                        </Typography>
                    }
                    secondary={
                        <LetterTemplateDetails
                            template={template}
                            confirmationCategories={confirmationCategories}
                            languages={languages}
                        />
                    }
                />
            </CardBody>

            <CardFooter>
                <Grid
                    sx={{
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        display: 'flex',
                        gap: 1
                    }}>
                    <Grid item xs={5}>
                        <UIActionButton
                            uppercase={false}
                            startIcon={<CheckCircleOutlineOutlined />}
                            onClick={handleSelect}
                            title={getLocalizedString(
                                intl,
                                'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.TEMPLATE.BUTTON.LABEL'
                            )}
                        />
                    </Grid>
                    <Grid>
                        <Grid item xs={5}>
                            <UIActionButton
                                uppercase={false}
                                startIcon={<VisibilityOutlined color="inherit" size="small" />}
                                onClick={handleViewDetails}
                                title={getLocalizedString(
                                    intl,
                                    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SEE_MORE.BUTTON.LABEL'
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardFooter>
        </Card>
    );
};

export function SelectLetterTemplate(props) {
    const { templates, languages, confirmationCategories, handleSelect, handleConfirmationUpdate = () => {} } = props;

    const intl = useIntl();
    const [selectionTemplates, setTemplates] = useState(templates);
    const [currentTemplate, setCurrentTemplate] = useState({});
    const { downloadTemplate } = useContext(LetterTemplateContext);
    const [triggerPreviewDialog, setTriggerPreviewDialog] = useState(0);

    useEffect(() => {
        setTemplates(templates);
    }, [templates]);

    const handleSelectTemplate = template => {
        const values = {
            letterTemplate: template,
            letterTemplateId: template.id,
            confirmationCategoryId: template.confirmationCategoryId,
            languageId: template.languageId
        };

        handleSelect(values);
    };

    const handleChange = (values, selectedTemplates) => {
        handleConfirmationUpdate(values);
        setTemplates(selectedTemplates);
    };

    const handleResetFilters = () => {
        setTemplates(templates);
        const values = {
            confirmationCategoryId: null,
            languageId: null
        };

        handleConfirmationUpdate(values);
    };

    const handleSeeMore = item => {
        setCurrentTemplate(item);
        setTriggerPreviewDialog(trigger => trigger + 1);
    };

    const updateOpenPreviewDocument = value => {
        setTriggerPreviewDialog(value);
        value === 0 && setCurrentTemplate(null);
    };

    return (
        <>
            <LetterTemplateFilters
                intl={intl}
                handleResetFilters={handleResetFilters}
                handleChange={handleChange}
                confirmationCategories={confirmationCategories}
                languages={languages}
                templates={templates}
            />
            <Carousel
                id="carousel_control_select_template"
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={10000}
                autoPlay={false}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={true}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={styles.carousel}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable>
                {selectionTemplates?.length > 0 &&
                    selectionTemplates.map(template => (
                        <LetterTemplateCarouselItem
                            key={template.id}
                            template={template}
                            confirmationCategories={confirmationCategories}
                            languages={languages}
                            handleSelectTemplate={handleSelectTemplate}
                            handleSeeMore={handleSeeMore}
                        />
                    ))}
            </Carousel>
            {currentTemplate && triggerPreviewDialog && (
                <DocumentPreview
                    isDownloadable={false}
                    isEditable={false}
                    fetchDocumentFileAPI={downloadTemplate}
                    item={currentTemplate}
                    updateOpenPreview={updateOpenPreviewDocument}
                    shouldOpenPreview={triggerPreviewDialog}
                    children={
                        <Card>
                            <CardHeader
                                title={
                                    <FormattedMessage id="CREATE_CONFIRMATION.SELECT_TEMPLATE.MORE_INFO_STACKED_STEPS" />
                                }
                            />
                            <CardBody>
                                <CreateConfirmationStepper
                                    isEditable={false}
                                    mainWorkflowActions={getMainWorkflowActions(
                                        currentTemplate?.workflow?.workflowActions
                                    )}
                                    workflowActions={currentTemplate?.workflow?.workflowActions}
                                />
                            </CardBody>
                        </Card>
                    }
                />
            )}
        </>
    );
}
