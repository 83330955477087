import { appApiUrl } from '../../config/appConfiguration';
import { parseResponse, parseResponseShort } from '../parsers';
import { parseComments } from './confirmationParsers';
import { Repository } from '../Repository';

/**
 * Class to communicate with API to access to confirmations' comments resources.
 */
class ConfirmationCommentRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    /**
     * Get all auditor's comments for a confirmation selected.
     * Param: confirmation ID
     */
    fetch = async (confirmationId) => {
        let result = await this.network.get(`confirmationcomment/confirmation/${confirmationId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseComments);
        return result;
    };

    /**
     * Create a comment.
     * Param: comment Object (detail)
     */
    create = async (comment) => {
        let result = await this.network.post(`confirmationcomment`, comment, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    /**
     * Update a comment selected.
     * Param: comment ID, comment Object (detail)
     */
    update = async (commentId, comment) => {
        let result = await this.network.put(`confirmationcomment/${commentId}`, comment, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    /**
     * Delete a comment selected.
     * Param: comment ID
     */
    delete = async (commentId) => {
        return await this.network.delete(`confirmationcomment/${commentId}`, null, {
            headers: this.buildHeaders()
        });
    };
}

export const confirmationCommentRepository = new ConfirmationCommentRepository();
