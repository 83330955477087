import { serializeLetterHeadInfo, letterHeadRepository } from '../kit/repository';
import { performAPIAction } from './BaseService';

export const LetterHeadService = ({ ...rest }) => {
    return {
        createLetterHead: async letterHeadInfo =>
            await performAPIAction({
                ...rest,
                apiCall: letterHeadRepository.create({
                    file: letterHeadInfo.file,
                    info: serializeLetterHeadInfo(letterHeadInfo)
                }),
                successMessageId: 'SETTINGS.LETTERHEAD.SECTION.CREATE.SUCCESS',
                errorMessageId: 'SETTINGS.LETTERHEAD.SECTION.CREATE.ERROR'
            }),
        deleteLetterHead: letterHead =>
            performAPIAction({
                ...rest,
                apiCall: letterHeadRepository.delete(letterHead.id),
                successMessageId: 'SETTINGS.LETTERHEAD.SECTION.DELETE.SUCCESS',
                errorMessageId: 'SETTINGS.LETTERHEAD.SECTION.DELETE.ERROR'
            }),
        fetchLetterHeadFile: letterHead =>
            performAPIAction({
                ...rest,
                apiCall: letterHeadRepository.fetchFile(letterHead.id),
                successMessageId: null,
                errorMessageId: 'SETTINGS.LETTERHEAD.SECTION.FETCH.ERROR',
                callback: null
            })
    };
};
