import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardBody } from '../../partials/controls/Card';
import { Typography } from '@mui/material';
import { ROUTES } from '../../navigation/Routes';
import { TuneOutlined as TuneOutlinedIcon, AssessmentOutlined as AssessmentOutlinedIcon } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
        justifyContent: 'center'
    },
    menu: {
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15
            },
            '& $imageMarked': {
                opacity: 0
            }
        }
    },
    focusVisible: {},
    menuButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white
    },
    menuSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%'
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity')
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`
    },
    imageMarked: {
        height: 3,
        width: 68,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 34px)',
        transition: theme.transitions.create('opacity')
    }
}));

export function CountryAdminMenuComponent(props) {
    const history = useHistory();
    const classes = useStyles();

    const openAdminSettings = () => {
        history.push(ROUTES.COUNTRY_ADMIN_SETTINGS);
    };

    const openAdminMetrics = () => {
        history.push(ROUTES.COUNTRY_ADMIN_METRICS);
    };

    const ADMIN_MENU = [
        {
            title: <FormattedMessage id="COUNTRY_ADMIN.MENU.SETTINGS.TITLE" />,
            width: '30%',
            handleClick: openAdminSettings,
            icon: <TuneOutlinedIcon fontSize="large" />
        },
        {
            title: <FormattedMessage id="COUNTRY_ADMIN.MENU.METRICS.TITLE" />,
            width: '30%',
            handleClick: openAdminMetrics,
            icon: <AssessmentOutlinedIcon fontSize="large" />
        }
    ];

    return (
        <Card>
            <CardBody>
                <div className={classes.root}>
                    {React.Children.toArray(
                        ADMIN_MENU.map(menu => (
                            <ButtonBase
                                focusRipple
                                className={classes.menu}
                                focusVisibleClassName={classes.focusVisible}
                                onClick={menu.handleClick}
                                style={{
                                    width: menu.width
                                }}>
                                <span
                                    className={classes.menuSrc}
                                    style={{
                                        backgroundColor: '#007bff'
                                    }}
                                />
                                <span className={classes.imageBackdrop} />
                                <span className={classes.menuButton}>
                                    {menu.icon}
                                    <Typography
                                        component="span"
                                        variant="h4"
                                        color="inherit"
                                        className={classes.imageTitle}>
                                        {menu.title}
                                        <span className={classes.imageMarked} />
                                    </Typography>
                                </span>
                            </ButtonBase>
                        ))
                    )}
                </div>
            </CardBody>
        </Card>
    );
}
