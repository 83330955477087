import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../../../i18n/i18Helper';
import { Box } from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UploadDocumentsComponent } from '../../../../common/UploadDocumentsComponent';
import React from 'react';
import { UPLOAD_TYPES } from '../../../../../helpers';
import { ConfirmationsDocumentsTree } from './ConfirmationsDocumentsTree';
import { ConfirmationDocumentsToolbar } from './ConfirmationDocumentsToolbar';
import { UISectionHeader } from '../../../../common/UISectionHeader';

interface ConfirmationDocumentsProps {
    handleDownloadAll: () => void;
    handleUpload: (values: Record<string, any>) => void;
    handleOpenRecycleBin: () => void;
    confirmation: any;
    paginatedDocuments: Record<string, any>;
    documentTypes: any[];
    itemErrors: any;
    user: any;
    handleFetchMenuConfirmations: (filters: Record<string, any>) => Promise<any>;
}

export const ConfirmationDocumentsSection = ({
    handleDownloadAll,
    handleUpload,
    handleOpenRecycleBin,
    confirmation,
    paginatedDocuments,
    documentTypes,
    itemErrors,
    user,
    handleFetchMenuConfirmations
}: ConfirmationDocumentsProps) => {
    const intl = useIntl();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <UISectionHeader
                title={getLocalizedString(intl, 'CONFIRMATION.SUBSECTION.DOCUMENTS')}
                toolbar={
                    <ConfirmationDocumentsToolbar
                        handleDownloadAll={handleDownloadAll}
                        handleOpenRecycleBin={handleOpenRecycleBin}
                    />
                }
            />

            {!documentTypes ? (
                <CircularProgress />
            ) : (
                <ConfirmationsDocumentsTree
                    handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                    user={user}
                    paginatedDocuments={paginatedDocuments}
                    documentTypes={documentTypes}
                    itemErrors={itemErrors}
                    confirmation={confirmation}
                />
            )}

            <UploadDocumentsComponent
                isWrappedInCard={false}
                filesInputProps={{
                    acceptedFiles: UPLOAD_TYPES.AUDITOR,
                    showPreviews: false,
                    filesLimit: 5
                }}
                handleUpload={handleUpload}
                isSubmitOnChange
            />
        </Box>
    );
};
