import React from 'react';
import { LabeledTextFieldItem } from '../../../helpers/FormHelper';

export const EditEntityRow = ({
    columns,
    intl,
    labelPrefix = 'EDIT_CLIENT.LABEL',
    placeholderPrefix = 'EDIT_CLIENT.PLACEHOLDER'
}) => {
    return (
        <div className="row mt-5" style={{ height: '100%' }}>
            {columns &&
                columns.map(({ id, label, isRequired = false, ...rest }, key) => (
                    <LabeledTextFieldItem
                        key={key}
                        id={id}
                        title={`${labelPrefix}.${label}`}
                        placeholder={intl.formatMessage({ id: `${placeholderPrefix}.${label}` })}
                        isRequired={isRequired}
                        {...rest}
                    />
                ))}
        </div>
    );
};
