import { buildFilters } from '../../network/mzrRequest';
import { appApiUrl } from '../../config/appConfiguration';
import { parseResponse } from '../parsers';
import { parseEmailTemplate, parseEmailTemplates } from './setupParsers';
import { Repository } from '../Repository';

class EmailTemplateRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async (filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`emailtemplate${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseEmailTemplates);
        return result;
    };

    create = async (template) => {
        let result = await this.network.post(`emailtemplate`, template, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    update = async (templateId, template) => {
        let result = await this.network.put(`emailtemplate/${templateId}`, template, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    fetch = async (templateId) => {
        let result = await this.network.get(`emailtemplate/${templateId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseEmailTemplate);
        return result;
    };

    delete = async (templateId) => {
        let result = await this.network.delete(`emailtemplate/${templateId}`, null, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };
}

export const emailTemplateRepository = new EmailTemplateRepository();
