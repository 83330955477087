import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { DescriptionOutlined as DescriptionOutlinedIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { UITooltip } from '../../common/UITooltip';
import { localizedReadableFullDate } from '../../../kit/utils/DateUtil';
import { Palette } from '../../../styles/colors';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    fonts: {
        fontWeight: 'bold'
    },
    inline: {
        display: 'inline'
    }
}));

/**
 * Component to display list of confirmation templates existing.
 *
 * @param {*} props
 * @returns
 */
function TemplateListComponent(props) {
    const { intl, templates, handleSelectTemplate, handleDeleteTemplate, confirmationCategories } = props;
    const classes = useStyles();

    const confirmationCategoryNameWrapper = (confirmationCategoryId, confirmationCategories) => {
        const [category] = confirmationCategories.filter(x => x.id === confirmationCategoryId);
        return category?.name;
    };

    return (
        <>
            <List dense={true}>
                {Object.values(templates)
                    .sort((a, b) => (a?.created < b?.created ? 1 : -1))
                    .map(template => {
                        return (
                            <ListItem
                                key={`confirmation-template-item-${template?.id}`}
                                button
                                onClick={() => handleSelectTemplate(template)}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <DescriptionOutlinedIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography className={classes.fonts}>{template?.name}</Typography>}
                                    secondary={
                                        <>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                display="block"
                                                color="textPrimary">
                                                <FormattedMessage id="TEMPLATE.TEXT.LANGUAGE" />
                                                <strong>{template?.language?.name}</strong>
                                            </Typography>
                                            {confirmationCategories && (
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    display="block"
                                                    color="textPrimary">
                                                    <FormattedMessage id="TEMPLATE.TEXT.CONFIRMATION.CATEGORY" />
                                                    <strong>
                                                        {confirmationCategoryNameWrapper(
                                                            template?.confirmationCategoryId,
                                                            confirmationCategories
                                                        )}
                                                    </strong>
                                                </Typography>
                                            )}
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                display="block"
                                                color="textPrimary">
                                                <FormattedMessage id="TEMPLATE.TEXT.CREATED" />
                                                <strong>{localizedReadableFullDate(intl, template?.created)}</strong>
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                display="block"
                                                color="textPrimary">
                                                <FormattedMessage id="TEMPLATE.TEXT.UPDATED" />
                                                <strong>{localizedReadableFullDate(intl, template?.updated)}</strong>
                                            </Typography>
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete">
                                        <UITooltip
                                            title={<FormattedMessage id="TEMPLATE.ACTION.TOOLTIP.DELETE" />}
                                            children={
                                                <DeleteIcon
                                                    sx={{ color: Palette.red }}
                                                    onClick={() => handleDeleteTemplate(template)}
                                                />
                                            }
                                        />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
            </List>
        </>
    );
}

export const TemplateList = injectIntl(TemplateListComponent);
