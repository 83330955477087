import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ExpandMore as ExpandMoreIcon, EditRounded as EditRoundedIcon } from '@mui/icons-material';

import { initWorkflow, InfoWorkflowComponent } from '.';
import { Box, Button } from '@material-ui/core';
import { UIDialog } from '../../common/UIDialog';

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
});

export function WorkflowConfigComponent(props) {
    const { workflows, workflowActions, confirmationCategories, configurationsChecks, loading, updateWorkflow } = props;
    const classes = useStyles();

    const [isConfigValid, setIsConfigValid] = useState(false);

    useEffect(() => {
        setIsConfigValid(configurationsChecks?.isExistingWorkflow ?? false);
    }, [configurationsChecks]);

    const sbmtWorkflowRef = useRef();

    const handleClickSave = () => {
        if (sbmtWorkflowRef && sbmtWorkflowRef.current) {
            sbmtWorkflowRef.current.saveForm();
        }
    };

    const [newWorkflow, setNewWorkflow] = useState(initWorkflow);
    const [openWorkflow, setOpenWorkflow] = useState(false);

    const handleWorkflowUpdate = workflow => {
        setNewWorkflow(workflow);
        setOpenWorkflow(!openWorkflow);
    };

    const handleSubmit = async values => {
        const { isSuccess } = await updateWorkflow(values);
        if (isSuccess) setOpenWorkflow(!openWorkflow);
    };

    return (
        <>
            <div className={classes.root}>
                {workflows ? (
                    Object.values(workflows).map(workflow => {
                        return (
                            <Accordion
                                key={`workflow_additional-actions${workflow.id}-section`}
                                TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls={`workflow_additional-actions${workflow.id}-content`}
                                    id={`workflow_additional-actions${workflow.id}-header`}
                                    key={`workflow_additional-actions${workflow.id}-header`}>
                                    <div style={styles.AccordionHeaderStyle}>
                                        <FormControlLabel
                                            id={`workflow-form-control-${workflow?.id}-label`}
                                            key={`workflow-form-control-${workflow?.id}-label`}
                                            aria-label={`workflow-form-control-${workflow?.id}-label`}
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={<Checkbox checked={isConfigValid ?? false} color="primary" />}
                                            label={workflow.name.toUpperCase()}
                                        />
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={
                                                <Box
                                                    component="span"
                                                    m={1}
                                                    className={clsx(classes.box, classes.boxButton)}>
                                                    <Button
                                                        startIcon={<EditRoundedIcon />}
                                                        variant="outlined"
                                                        size="small"
                                                        color="primary"
                                                        className={classes.button}
                                                        onClick={() => handleWorkflowUpdate(workflow)}>
                                                        <FormattedMessage id={'WORKFLOW.INFO.BUTTON.EDIT.LABEL'} />
                                                    </Button>
                                                </Box>
                                            }
                                        />
                                    </div>
                                </AccordionSummary>

                                <AccordionDetails
                                    key={`workflow_additional-actions${workflow.id}-detail`}
                                    children={
                                        <InfoWorkflowComponent
                                            workflow={workflow}
                                            workflows={workflows}
                                            workflowActions={workflowActions}
                                            categories={confirmationCategories}
                                            edition={false}
                                            handleSubmit={handleSubmit}
                                            sbmtWorkflowRef={sbmtWorkflowRef}
                                        />
                                    }
                                />
                            </Accordion>
                        );
                    })
                ) : (
                    <CircularProgress />
                )}
            </div>

            {openWorkflow && (
                <UIDialog
                    {...props}
                    open={openWorkflow}
                    titleId="WORKFLOW.DIALOG.TITLE.EDIT.LABEL"
                    onSave={handleClickSave}
                    onClose={() => setOpenWorkflow(!openWorkflow)}>
                    <InfoWorkflowComponent
                        workflow={newWorkflow}
                        workflows={workflows}
                        workflowActions={workflowActions}
                        categories={confirmationCategories}
                        loading={loading}
                        edition={true}
                        handleSubmit={handleSubmit}
                        sbmtWorkflowRef={sbmtWorkflowRef}
                    />
                </UIDialog>
            )}
        </>
    );
}

WorkflowConfigComponent.propTypes = {
    workflows: PropTypes.array,
    workflowActions: PropTypes.array,
    confirmationCategories: PropTypes.array,
    loading: PropTypes.bool,
    updateWorkflow: PropTypes.func
};

const styles = {
    AccordionHeaderStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    }
};

export default injectIntl(WorkflowConfigComponent);
