import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, Box } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { withContact } from '../../../enhancers/withContact';
import { initContact, EditContact } from '../../contact';
import { CONTACT_TYPE } from '../../../types/types';
import { UIDialog } from '../../common/UIDialog';
import { InviteContact } from '../../contact/InviteContact';
import { ManageContactAccessRights } from '../../contact/ManageContactAccessRights';
import { UIActionButton } from '../../common/UIActionButton';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { toolbarStyle } from '../../../styles/toolbar/ToolbarStyle';
import { isExternalAccessEnabled } from '../../../kit/config/appConfiguration';

/**
 * search and display engagement's contacts.
 *
 * @param {*} props
 * @returns
 */
function EngagementContact(props) {
    const {
        classes,
        companies,
        disabled,
        handleAddContact,
        handleUpdateContact,
        handleDeleteContact,
        handleInviteContact,
        handleResetPassword,
        handleRevokeAccess,
        handleDeleteEngagementAccess,
        contactType,
        limitedAccess,
        restrictedEdition
    } = props;

    const intl = useIntl();

    const [openDialogContact, setOpenDialogContact] = useState(false);
    const [isInvitationMode, setIsInvitationMode] = useState(false);
    const [openDialogManageContact, setOpenDialogManageContact] = useState(false);
    const [flagEditionContact, setFlagEditionContact] = useState(false);
    const [dialogTitleLabel, setDialogTitleLabel] = useState('DIALOG.CONTACT.CREATE.TITLE');
    const [newContact, setNewContact] = useState(initContact);
    const [contacts, setContacts] = useState([]);
    const hasContacts = contacts?.length > 0;
    const sbmtContactRef = useRef();

    const isCreateDisabled = disabled || companies?.length === 0 || limitedAccess;

    useEffect(() => {
        const clientContacts = companies
            ? companies.reduce((acc, company) => [...acc, ...(company.contacts || [])], [])
            : [];
        setContacts(clientContacts);
    }, [companies]);

    const handleCreateContact = () => {
        setNewContact(initContact);
        setFlagEditionContact(false);
        setDialogTitleLabel('DIALOG.CONTACT.CREATE.TITLE');
        setOpenDialogContact(!openDialogContact);
    };

    const handleContactSave = values => {
        if (flagEditionContact) {
            handleUpdateContact(values);
        } else {
            const [engagementCompany] = companies.filter(company => company.id === values.engagementCompanyId);
            handleAddContact({
                ...values,
                engagementCompany: engagementCompany
            });
        }
        setNewContact(initContact);
        setFlagEditionContact(false);
        setOpenDialogContact(!openDialogContact);
    };

    const handleEditContact = contact => {
        setNewContact(contact);
        setFlagEditionContact(true);
        setDialogTitleLabel('DIALOG.CONTACT.EDIT.TITLE');
        setOpenDialogContact(!openDialogContact);
    };

    const handleContactPopUpOpening = () => setOpenDialogContact(!openDialogContact);

    const handleClose = () => {
        if (sbmtContactRef && sbmtContactRef.current) {
            sbmtContactRef.current.saveForm();
        }
    };

    const onInviteContact = contact => {
        setNewContact(contact);
        setDialogTitleLabel({
            id: 'DIALOG.CONTACT.INVITE.TITLE',
            values: { contact: `${contact.firstName ?? ''} ${contact.lastName ?? ''}`, email: contact.email }
        });

        setIsInvitationMode(true);
        setOpenDialogManageContact(!openDialogManageContact);
    };

    const onManageAccess = contact => {
        setNewContact(contact);
        setIsInvitationMode(false);
        setDialogTitleLabel({
            id: 'DIALOG.CONTACT.MANAGE.ACCESS.TITLE',
            values: { contact: `${contact.firstName ?? ''} ${contact.lastName ?? ''}`, email: contact.email }
        });
        setOpenDialogManageContact(!openDialogManageContact);
    };

    const handleConfirm = () => {
        setOpenDialogManageContact(!openDialogManageContact);
        isInvitationMode ? handleInviteContact(newContact) : handleUpdateContact(newContact);
    };

    return (
        <>
            {!hasContacts ? (
                <Box sx={{ ...toolbarStyle.container, justifyContent: 'center' }}>
                    <UIActionButton
                        sx={toolbarStyle.button}
                        title={getLocalizedString(intl, 'CONTACT.SECTION.BUTTON.CREATE')}
                        disabled={isCreateDisabled}
                        startIcon={<AddCircleOutline />}
                        onClick={handleCreateContact}
                    />
                </Box>
            ) : (
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item container justifyContent="flex-end">
                        <UIActionButton
                            sx={toolbarStyle.button}
                            disabled={isCreateDisabled}
                            title={getLocalizedString(intl, 'CONTACT_SECTION.BUTTON.NEW.LABEL')}
                            onClick={handleCreateContact}
                        />
                    </Grid>

                    {contacts?.length > 0 && (
                        <Grid item>
                            {props.renderContacts({
                                showInvitationStatus:
                                    isExternalAccessEnabled &&
                                    !limitedAccess &&
                                    !restrictedEdition &&
                                    !!handleInviteContact,
                                handleInvite:
                                    !isExternalAccessEnabled ||
                                    limitedAccess ||
                                    restrictedEdition ||
                                    !handleInviteContact
                                        ? null
                                        : onInviteContact,
                                handleManageAccessRights:
                                    !isExternalAccessEnabled || limitedAccess || restrictedEdition
                                        ? null
                                        : onManageAccess,
                                restrictedEdition: limitedAccess,
                                contactType: CONTACT_TYPE.CLIENT,
                                disabled: disabled,
                                isRoleEditable: false,
                                contacts: contacts,
                                handleEditContact: handleEditContact,
                                handleDeleteContact: limitedAccess ? null : handleDeleteContact
                            })}
                        </Grid>
                    )}
                </Grid>
            )}

            {openDialogContact && (
                <UIDialog
                    {...props}
                    open={openDialogContact}
                    titleId={dialogTitleLabel}
                    onSave={handleClose}
                    onClose={handleContactPopUpOpening}
                    saveTitleId="DIALOG.BUTTON.VALIDATE">
                    <EditContact
                        restrictedEdition={limitedAccess}
                        contactType={contactType}
                        {...props}
                        classes={classes}
                        variant="outlined"
                        companyDisabled={flagEditionContact}
                        companies={companies}
                        contact={newContact}
                        handleSubmit={handleContactSave}
                        sbmtContactRef={sbmtContactRef}
                    />
                </UIDialog>
            )}

            {openDialogManageContact && (
                <UIDialog
                    classes={classes}
                    open={openDialogManageContact}
                    titleId={dialogTitleLabel}
                    actions={
                        <UIActionButton
                            style={{ width: '120px' }}
                            title={getLocalizedString(
                                intl,
                                isInvitationMode ? 'SETTINGS.SECTION.CONTACT.ACTION.INVITE' : 'DIALOG.BUTTON.VALIDATE'
                            )}
                            onClick={handleConfirm}
                        />
                    }
                    onClose={() => setOpenDialogManageContact(!openDialogManageContact)}>
                    {isInvitationMode ? (
                        <InviteContact contact={newContact} />
                    ) : (
                        <ManageContactAccessRights
                            contact={newContact}
                            handleRevokeAccess={handleRevokeAccess}
                            handleDeleteEngagementAccess={handleDeleteEngagementAccess}
                            handleResetPassword={handleResetPassword}
                        />
                    )}
                </UIDialog>
            )}
        </>
    );
}

EngagementContact.propTypes = {
    /**
     * List of companies.
     */
    companies: PropTypes.array,
    /**
     * Function to update company's contact list.
     */
    handleUpdateContact: PropTypes.func.isRequired,
    /**
     * Function to delete a contact from company's contact list.
     */
    handleDeleteContact: PropTypes.func.isRequired,

    /**
     * Function to invite a client contact
     */
    handleInviteContact: PropTypes.func.isRequired,

    /**
     * Flag that indicates whether there is a limited access to some section of the contact component
     */
    limitedAccess: PropTypes.bool,

    /**
     * Flag that indicates whether the current user has restricted edition rights
     */
    restrictedEdition: PropTypes.bool
};

EngagementContact.defaultProps = {
    limitedAccess: false,
    restrictedEdition: false
};

export const EngagementContactComponent = withContact(EngagementContact);
