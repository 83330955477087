import { appApiUrl } from '../../config/appConfiguration';
import { parseResponse, parseResponseShort } from '../parsers';
import { parseAuthProvider, parseAuthProviders } from './setupParsers';
import { Repository } from '../Repository';

class MailingAuthProviderRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`MailingAuthProvider`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseAuthProviders);
        return result;
    };

    fetch = async (mailingAuthProviderId) => {
        let result = await this.network.get(`MailingAuthProvider/${mailingAuthProviderId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseAuthProvider);
        return result;
    };

    fetchByClusterId = async (clusterId) => {
        let result = await this.network.get(`MailingAuthProvider/cluster/${clusterId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseAuthProviders);
        return result;
    };

    create = async (provider) => {
        let result = await this.network.post(`MailingAuthProvider`, provider, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseAuthProvider);
        return result;
    };

    update = async (mailingAuthProviderId, provider) => {
        let result = await this.network.put(`MailingAuthProvider/${mailingAuthProviderId}`, provider, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    delete = async (mailingAuthProviderId) => {
        let result = await this.network.delete(`MailingAuthProvider/${mailingAuthProviderId}`, null, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };
}

export const mailingAuthProviderRepository = new MailingAuthProviderRepository();
