import { useEffect, useState } from 'react';

/*Exemple of step:
{
            key: CreationProcessStep.TEMPLATE,
            title: <FormattedMessage id="CREATE_CONFIRMATION.STEP_TEMPLATE.LABEL" />,
            isActive: true
 }
 */

export function useStepper({ initialSteps, initialPosition, changeStepCallback }) {
    const [steps, setSteps] = useState(initialSteps);
    const [firstStep, setFirstStep] = useState(0);
    const [lastStep, setLastStep] = useState(0);
    const [totalSteps, setTotalSteps] = useState(0);
    const [activeSteps, setActiveSteps] = useState(steps.filter(step => step.isActive));
    const [activeStepPosition, setActiveStepPosition] = useState(initialPosition);
    // helping variable especially when moving to target position is deferred (ex wait submit in information tab in engagement creation)
    const [targetStepPosition, setTargetStepPosition] = useState(null);
    const [completed, setCompleted] = useState({});
    const [activeStep, setActiveStep] = useState(
        activeSteps.length > initialPosition && initialPosition >= 0 ? activeSteps[initialPosition]?.key : 0
    );

    useEffect(() => {
        setCompleted({});
    }, []);

    useEffect(() => {
        setActiveStep(activeSteps[activeStepPosition]?.key);
        changeStepCallback && changeStepCallback(activeStepPosition);
    }, [activeStepPosition, activeSteps, changeStepCallback]);

    useEffect(() => {
        setFirstStep(activeSteps[0]?.key);
        setLastStep(activeSteps[activeSteps.length - 1]?.key);
        setTotalSteps(activeSteps.length);
    }, [activeSteps]);

    const doCompleteStep = (step, isCompleted) => {
        const newCompleted = completed;
        if (isCompleted) {
            newCompleted[step] = true;
        } else {
            delete newCompleted[step];
        }

        setCompleted(newCompleted);
    };

    const getStep = position => activeSteps[position]?.key;

    return {
        steps,
        activeSteps,
        activeStep,
        activeStepPosition,
        totalSteps,
        lastStep,
        firstStep,
        update: (newSteps, newActiveStep) => {
            setSteps(newSteps);
            const newActiveSteps = newSteps.filter(step => step.isActive);
            setActiveSteps(newActiveSteps);
            setActiveStepPosition(newActiveSteps.findIndex(s => s.key === newActiveStep));
        },
        nextStep: () => setActiveStepPosition(activeStepPosition + 1),
        previousStep: () => setActiveStepPosition(activeStepPosition - 1),
        resetStep: () => {
            setCompleted({});
            setActiveStepPosition(0);
        },
        getStep: getStep,
        moveToTargetPosition: (position = targetStepPosition) => setActiveStepPosition(position),
        setTargetStepPosition: setTargetStepPosition,
        isStepCompleted: stepPosition => getStep(stepPosition) in completed,
        completeStep: doCompleteStep
    };
}
