export const parseResponseShort = json => {
    return {
        id: json.id
    };
};

export const parseResponseArray = jsonArray => {
    return {
        ids: jsonArray ? [...jsonArray] : []
    };
};

export const parseExtensions = json => {
    return {
        additionalProp1: json.additionalProp1,
        additionalProp2: json.additionalProp2,
        additionalProp3: json.additionalProp3
    };
};

export const parseResponse = json => {
    return {
        type: json.type,
        title: json.title,
        status: json.status,
        detail: json.detail,
        instance: json.instance,
        extensions: json.extensions ? parseExtensions(json.extensions) : {}
    };
};

export const parsePaginationResponse = (parserFunc, json) => {
    return {
        totalItems: json.totalItems,
        pageSize: json.pageSize,
        totalPages: json.totalPages,
        pageIndex: json.pageIndex,
        itemType: json.itemType,
        filters: json.filters ? json.filters : {},
        hasPreviousPage: json.hasPreviousPage,
        hasNextPage: json.hasNextPage,
        items: json.items ? parserFunc(json.items) : []
    };
};

export const parseTimeZones = json => {
    return json ? Object.values(json).map(x => parseTimeZone(x)) : [];
};

export const parseTimeZone = timezone => {
    return {
        id: timezone.id,
        displayName: timezone.displayName,
        baseUtcOffset: timezone.baseUtcOffset ? parseBaseUtcOffset(timezone.baseUtcOffset) : {}
    };
};

export const parseBaseUtcOffset = baseUtcOffset => {
    return {
        ticks: baseUtcOffset.ticks,
        days: baseUtcOffset.days,
        hours: baseUtcOffset.hours,
        milliseconds: baseUtcOffset.milliseconds,
        minutes: baseUtcOffset.minutes,
        seconds: baseUtcOffset.seconds,
        totalDays: baseUtcOffset.totalDays,
        totalHours: baseUtcOffset.totalHours,
        totalMilliseconds: baseUtcOffset.totalMilliseconds,
        totalMinutes: baseUtcOffset.totalMinutes,
        totalSeconds: baseUtcOffset.totalSeconds
    };
};

export const parseWithNULL = entry => {
    return entry?.toUpperCase() === 'NULL' ? null : entry;
};

export const parseResponseTypes = json => {
    return {
        name: json.name,
        setpIds: json.stepIds ? Object.values(json.stepIds).map(x => x) : []
    };
};
