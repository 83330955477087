import { parseAppUser } from '../user/userParsers';
import { parseTags } from '../confirmation/confirmationParsers';

export const parseMailingConfigurations = json => {
    return json ? Object.values(json).map(x => parseMailingConfiguration(x)) : [];
};

export const parseMailingConfiguration = json => {
    return {
        id: json.id,
        clusterId: json.clusterId,
        authType: json.authType,
        administrators: json.administrators,
        inboxFolder: json.inboxFolder,
        sentItemsFolder: json.sentItemsFolder,
        processingInProgressFolder: json.processingInProgressFolder,
        processedFolder: json.processedFolder,
        requestsFolder: json.requestsFolder,
        issuesFolder: json.issuesFolder,
        othersFolder: json.othersFolder,
        numberOfDaysBeforeFollowUp: json.numberOfDaysBeforeFollowUp,
        automaticDeleteEmails: json.automaticDeleteEmails,
        basicAuthConfiguration: json.basicAuthConfiguration ? parseBasicAuthConfiguration(json.basicAuthConfiguration) : {},
        modernAuthConfiguration: json.modernAuthConfiguration ? parseModernAuthConfiguration(json.modernAuthConfiguration) : {}
    };
};

export const parseBasicAuthConfiguration = json => {
    return {
        id: json.id,
        providerName: json.providerName,
        smtpDisplayName: json.smtpDisplayName,
        smtpUsername: json.smtpUsername,
        smtpPassword: json.smtpPassword,
        smtpHost: json.smtpHost,
        smtpPort: json.smtpPort,
        smtpSecureSocketOptions: json.smtpSecureSocketOptions,
        smtpAcceptAllSslCertificates: json.smtpAcceptAllSslCertificates,
        imapUsername: json.imapUsername,
        imapPassword: json.imapPassword,
        imapHost: json.imapHost,
        imapPort: json.imapPort,
        imapSecureSocketOptions: json.imapSecureSocketOptions,
        imapAcceptAllSslCertificates: json.imapAcceptAllSslCertificates,
        isActive: json.isActive,
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updatorId: json.updatorId,
        updator: json.updator ? parseAppUser(json.updator) : {}
    };
};

export const parseModernAuthConfiguration = json => {
    return {
        id: json.id,
        mailingAuthProviderId: json.mailingAuthProviderId,
        userName: json.userName,
        userObjectId: json.userObjectId,
        isActive: json.isActive,
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updatorId: json.updatorId,
        updator: json.updator ? parseAppUser(json.updator) : {}
    };
};

export const parseRobotConfigurations = json => {
    return json ? Object.values(json).map(x => parseRobotConfiguration(x)) : [];
};

export const parseRobotConfiguration = json => {
    return {
        id: json?.id,
        clusterId: json?.clusterId,
        administrators: json?.administrators,
        mailingConfiguration: json?.mailingConfiguration ? parseMailingConfiguration(json?.mailingConfiguration) : {},
        engagementCodePattern: json?.engagementCodePattern,
        description: json?.description
    };
};

export const parseStorageConfiguration = json => {
    return {
        default: json.default ? parseStorageConfigurationDefault(json.default) : {},
        sharePointOnline: json.sharePointOnline ? parseStorageConfigurationSharePointOnline(json.sharePointOnline) : {}
    };
};

export const parseStorageConfigurationSharePointOnline = json => {
    return {
        id: json.id,
        clusterId: json.clusterId,
        isValid: json.isValid,
        priorityLevel: json.priorityLevel,
        token: json.token,
        tokenExpiration: json.tokenExpiration,
        tenantName: json.tenantName,
        tenantId: json.tenantId,
        siteName: json.siteName,
        siteUrl: json.siteUrl,
        documentLibrary: json.documentLibrary,
        targetPath: json.targetPath,
        isActive: json.isActive,
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updatorId: json.updatorId,
        updator: json.updator ? parseAppUser(json.updator) : {}
    };
};

export const parseStorageConfigurationDefault = json => {
    return {
        id: json.id,
        clusterId: json.clusterId,
        isActive: json.isActive,
        priorityLevel: json.priorityLevel,
        targetPath: json.targetPath
    };
};

export const parseConfiguration = json => ({
    isValidStorageConfiguration: json?.isValidStorageConfiguration ?? false,
    isValidRobotConfiguration: json?.isValidRobotConfiguration ?? false,
    isValidMailingConfiguration: json?.isValidMailingConfiguration ?? false,
    isExistingWorkflow: json?.isExistingWorkflow ?? false,
    isExistingLetterTemplate: json?.isExistingLetterTemplate ?? false,
    // TODO: email check not available
    // isExistingEmailTemplate: json?.isExistingEmailTemplate ?? false,
    isExistingEmailTemplate: true
});

export const parseLostItemConfiguration = json => {
    return {
        id: json.id,
        clusterId: json.clusterId,
        isSupportMandatoryToAssignAsLostItem: json.isSupportMandatoryToAssignAsLostItem,
        isClaimMandatory: json.isClaimMandatory,
        isReadAllowed: json.isReadAllowed
    };
};

export const parseEmailTemplate = json => {
    return {
        id: json.id,
        name: json.name,
        confirmationCategoryId: json.confirmationCategoryId,
        confirmationCategory: json.confirmationCategory,
        languageId: json.languageId,
        language: parseLanguage(json.language),
        emailTypeId: json.emailTypeId,
        description: json.description,
        subject: json.subject,
        body: json.body,
        clusterId: json.clusterId,
        archived: json.archived,
        emailType: json.emailType ? parseEmailType(json.emailType) : {},
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updatorId: json.updatorId,
        updator: json.updator ? parseAppUser(json.updator) : {}
    };
};

export const parseEmailTemplates = json => {
    return json ? Object.values(json).map(x => parseEmailTemplate(x)) : [];
};

export const parseEmailType = json => {
    return {
        id: json.id,
        name: json.name,
        description: json.description,
        mandatoryTags: parseTags(json.mandatoryTags),
        optionalTags: parseTags(json.optionalTags)
    };
};

export const parseEmailTypes = json => {
    return json ? Object.values(json).map(x => parseEmailType(x)) : [];
};

export const parseLanguage = json => {
    return {
        id: json.id,
        tag: json.tag,
        name: json.name,
        displayName: json.displayName,
        dateFormat: json.dateFormat,
        timeFormat: json.timeFormat
    };
};

export const parseLanguages = json => {
    return json ? Object.values(json).map(x => parseLanguage(x)) : [];
};

export const parseAuthProvider = json => {
    return {
        id: json.id,
        clusterId: json.clusterId,
        providerName: json.providerName,
        authority: json.authority,
        tenantId: json.tenantId,
        clientId: json.clientId,
        clientSecret: json.clientSecret,
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updatorId: json.updatorId,
        updator: json.updator ? parseAppUser(json.updator) : {}
    };
};

export const parseAuthProviders = json => {
    return json ? Object.values(json).map(x => parseAuthProvider(x)) : [];
};
