import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import { ProcessConnector, ProcessStepIcon } from '../../../helpers/StepperHelper';
import Avatar from '@material-ui/core/Avatar';
import { Grid } from '@mui/material';
import { UITooltip } from '../../common/UITooltip';
import { getMainWorkflowActions } from '../../../helpers/WorkflowHelper';
import { MZRChip } from '../../common/MZRChip';

/**
 * Workflow component used with Formik
 */
export function WorkflowWrapper(props) {
    const { options, disabled, isCategoryDeletable } = props;
    const [activeStep, setActiveStep] = useState(null);
    const [workflow, setWorkflow] = useState({});

    useEffect(() => {
        setActiveStep(workflow?.workflowActions?.length);
    }, [workflow]);

    // TODO: Maybe remove the 'options' selection
    useEffect(() => {
        if (props?.form?.values?.workflow) {
            setWorkflow(props?.form?.values?.workflow);
        } else if (options && props?.form?.values?.workflowId) {
            const [_workflow] = Object.values(options)?.filter(x => x?.id === props?.form?.values?.workflowId);
            setWorkflow(_workflow);
        } else {
            setWorkflow(props?.form?.values);
        }
    }, [props?.form?.values]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = values => {
        if (!disabled) {
            const valueUpdated = [];
            if (props.field.value.includes(values)) {
                props.field.value.map(x => {
                    if (x !== values) valueUpdated.push(x);
                    return null;
                });
            }

            props.form.setFieldValue(props.field.name, valueUpdated);
        }
    };

    const handleStepActivate = step => {
        if (!disabled) {
            const _workflowActionsUpdated = [];
            Object.values(workflow?.workflowActions).map(x => {
                if (x?.id === step?.id) {
                    _workflowActionsUpdated.push({ ...step, isSelected: !step?.isSelected });
                } else {
                    _workflowActionsUpdated.push(x);
                }
                return null;
            });
            props.form.setFieldValue('workflowActions', _workflowActionsUpdated);
        }
    };

    /** Render workflow with stacked actions (or not) */
    const renderStackedWorkflow = actions => {
        const mainActions = getMainWorkflowActions(actions);
        return React.Children.toArray(
            mainActions?.map(action => (
                <UITooltip
                    title={action.description}
                    key={`styled-tooltip-action-${action?.id}`}
                    children={
                        <Step key={action.id} completed={action?.isSelected}>
                            <StepButton onClick={() => handleStepActivate(action)}>
                                <StepLabel StepIconComponent={ProcessStepIcon}>{action.name}</StepLabel>
                            </StepButton>
                            {Object.values(workflow?.workflowActions)
                                .filter(x => x?.position === action?.position && x?.id !== action?.id)
                                ?.map(option => (
                                    <UITooltip
                                        title={option.description}
                                        key={`styled-tooltip-action-${option?.id}`}
                                        children={
                                            <Step key={option.id} completed={option?.isSelected}>
                                                <StepButton onClick={() => handleStepActivate(option)}>
                                                    <StepLabel StepIconComponent={ProcessStepIcon}>
                                                        {option.name}
                                                    </StepLabel>
                                                </StepButton>
                                            </Step>
                                        }
                                    />
                                ))}
                        </Step>
                    }
                />
            ))
        );
    };

    /** Display list of categories selected (allowed) for the workflow */
    const renderWorkflowCategories = categories => {
        return React.Children.toArray(
            Object.values(categories).map(category => (
                <Grid item>
                    <UITooltip
                        title={category?.description}
                        key={`styled-tooltip-action.${category?.id}`}
                        children={
                            <MZRChip
                                id={`confirmation_category_allowed_${category?.id}`}
                                key={`confirmation_category_allowed_${category?.id}`}
                                variant={
                                    props?.form?.values?.confirmationCategoryId === category?.id
                                        ? 'default'
                                        : 'outlined'
                                }
                                color="primary"
                                size="small"
                                disabled={!isCategoryDeletable}
                                onDelete={() => handleClick(category)}
                                label={category?.name}
                                avatar={
                                    <Avatar>
                                        {String(category?.name)
                                            .split(' ')
                                            .map(x => x.substring(0, 1))}
                                    </Avatar>
                                }
                            />
                        }
                    />
                </Grid>
            ))
        );
    };

    return (
        <React.Fragment key={`workflow_${props?.field?.id}`}>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<ProcessConnector />}>
                        {workflow?.workflowActions ? renderStackedWorkflow(workflow?.workflowActions) : null}
                    </Stepper>
                </Grid>

                <Grid container item direction="row" alignItems="center" spacing={2}>
                    {workflow?.confirmationCategories
                        ? renderWorkflowCategories(workflow?.confirmationCategories)
                        : null}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

WorkflowWrapper.propTypes = {
    // /**
    //  * Options available.
    //  */
    options: PropTypes.array,
    // /**
    //  * Disabled the modifications.
    //  */
    disabled: PropTypes.bool.isRequired,
    /**
     * Allows the user to select the categories
     */
    isCategoryDeletable: PropTypes.bool.isRequired
};

WorkflowWrapper.defaultProps = {
    isCategoryDeletable: false
};

export default injectIntl(WorkflowWrapper);
