import React from 'react';
import { Box } from '@mui/material';
import { HighlightOffRounded, FilterList, BorderColorTwoTone, Archive, OpenInNewRounded } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { UIActionButton } from '../common/UIActionButton';
import { getLocalizedString } from '../../i18n/i18Helper';
import { toolbarStyle } from '../../styles/toolbar/ToolbarStyle';

interface HomeToolbarProps {
    isFilteringMode: boolean;
    handleGlobalFiltersChange: (filters: Record<string, any>) => void;
    handleArchiveViewChange: () => void;
    isArchiveMode: boolean;
    handleCreateEngagement?: () => void;
    filterableColumns: Record<string, string>;
    onFilterClicked: () => void;
}

const archiveButtonReducer = (isArchived: boolean) => {
    return {
        startIcon: !isArchived ? <Archive /> : <OpenInNewRounded />,
        labelId: !isArchived ? 'HOME.ENGAGEMENT.FILTER.ARCHIVED' : 'HOME.ENGAGEMENT.FILTER.OPEN'
    };
};

export const HomeToolbar = ({
    isArchiveMode,
    handleArchiveViewChange,
    isFilteringMode,
    onFilterClicked,
    handleCreateEngagement
}: HomeToolbarProps) => {
    const { startIcon, labelId } = archiveButtonReducer(isArchiveMode);
    const intl = useIntl();

    return (
        <>
            <Box sx={toolbarStyle.container}>
                <UIActionButton
                    sx={toolbarStyle.button}
                    display="secondary"
                    startIcon={isFilteringMode ? <HighlightOffRounded /> : <FilterList />}
                    onClick={onFilterClicked}
                    title={getLocalizedString(
                        intl,
                        isFilteringMode ? 'ENGAGEMENT.TABLE.FILTER.CLEAR' : 'ENGAGEMENT.TABLE.FILTER'
                    )}
                />

                <UIActionButton
                    sx={toolbarStyle.button}
                    startIcon={startIcon}
                    onClick={handleArchiveViewChange}
                    title={getLocalizedString(intl, labelId)}
                />

                {handleCreateEngagement && (
                    <UIActionButton
                        sx={toolbarStyle.button}
                        startIcon={<BorderColorTwoTone color="inherit" />}
                        onClick={handleCreateEngagement}
                        title={getLocalizedString(intl, 'HOME.ENGAGEMENT.BUTTON.CREATE')}
                    />
                )}
            </Box>
        </>
    );
};
