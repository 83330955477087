import { appApiUrl } from '../../config/appConfiguration';
import { parseTimeZones } from '../parsers';
import { Repository } from '../Repository';

class UtilitiesRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchTimeZones = async () => {
        let result = await this.network.get(`utilities/timezones`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseTimeZones);
        return result;
    };
}

export const utilitiesRepository = new UtilitiesRepository();
