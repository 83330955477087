import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl, useIntl } from 'react-intl';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../partials/controls/Card';
import { ImportConfirmationsFormComponent } from './ImportConfirmationsForm';
import { Box, Grid, Grow } from '@mui/material';
import { CreateImportTemplate } from '..';
import { ROUTES } from '../../../navigation/Routes';
import { ConfirmationHeaderTitle } from '../common';
import { CloudUpload, GetAppRounded } from '@mui/icons-material';
import { UIActionButton } from '../../common/UIActionButton';
import { UIBackButton } from '../../common/UIBackButton';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { toolbarStyle } from '../../../styles/toolbar/ToolbarStyle';

const CreateConfirmationImportActions = ({
    handleGenerateImportModel,
    handleUploadConfig,
    loading,
    displayOnlyActions,
    isDownloadActive,
    isUploadActive
}) => {
    const intl = useIntl();

    const renderActionsButtons = ({ sx }) => {
        return (
            <>
                <UIActionButton
                    sx={{ ...sx }}
                    display={isDownloadActive ? 'primary' : 'secondary'}
                    startIcon={<GetAppRounded />}
                    title={getLocalizedString(intl, 'IMPORT.CONFIRMATION.BUTTON.DOWNLOAD.XLS_TEMPLATE.LABEL')}
                    onClick={handleGenerateImportModel}
                />
                <UIActionButton
                    sx={{ ...sx }}
                    display={isUploadActive ? 'primary' : 'secondary'}
                    startIcon={loading ? <span className="mr-6 spinner spinner-white" /> : <CloudUpload />}
                    disabled={loading}
                    title={getLocalizedString(intl, 'IMPORT.CONFIRMATION.BUTTON.IMPORT.LABEL')}
                    onClick={handleUploadConfig}
                />
            </>
        );
    };

    return (
        <div className="mt-5">
            <Grow
                in={!displayOnlyActions}
                style={{ transformOrigin: '0 0 0' }}
                {...(!displayOnlyActions ? { timeout: 500 } : {})}>
                <Box component="span" m={1} sx={toolbarStyle.container}>
                    {renderActionsButtons({ sx: sx.smallButton })}
                </Box>
            </Grow>

            <Grow
                in={displayOnlyActions}
                style={{ transformOrigin: '0 0 0' }}
                {...(displayOnlyActions ? { timeout: 500 } : {})}>
                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    {displayOnlyActions && renderActionsButtons({ sx: sx.bigButton })}
                </Grid>
            </Grow>
        </div>
    );
};

const CreateConfirmationImport = props => {
    const {
        loading,
        templates,
        languages,
        intl,
        confirmationCategories,
        fetchImportExcelTemplate,
        engagement,
        uploadConfirmations
    } = props;

    const history = useHistory();

    const [displayOnlyActions, setDisplayOnlyActions] = React.useState(false);

    const sbmtChckRef = useRef();

    const [displayGenerateConfig, setDisplayGenerateConfig] = useState(false);
    const [displayUploadConfig, setDisplayUploadConfig] = useState(false);

    useEffect(() => {
        setDisplayOnlyActions(!displayGenerateConfig && !displayUploadConfig);
    }, [displayGenerateConfig, displayUploadConfig]);

    const handleGenerateImportModel = () => {
        setDisplayGenerateConfig(!displayGenerateConfig);
        setDisplayUploadConfig(false);
    };

    const handleUploadConfig = () => {
        setDisplayUploadConfig(!displayUploadConfig);
        setDisplayGenerateConfig(false);
    };

    const generateExcelFile = async configurationInfo => {
        const { isSuccess } = await fetchImportExcelTemplate(configurationInfo);
        setDisplayGenerateConfig(!isSuccess);
    };

    const uploadConfirmationFile = async newConfirmations => {
        const { isSuccess } = await uploadConfirmations(newConfirmations);
        isSuccess && history.replace(ROUTES.ENGAGEMENT.replace(':engagementId', engagement.id));
    };

    return (
        <Card>
            <CardHeader
                title={
                    <ConfirmationHeaderTitle
                        isConfirmationReady={!!engagement?.id}
                        isDefaultTitle={false}
                        engagementName={engagement?.name}
                    />
                }>
                <CardHeaderToolbar>
                    <Box component="span" m={1} sx={toolbarStyle.container}>
                        <UIBackButton onClick={history.goBack} />
                    </Box>
                </CardHeaderToolbar>
            </CardHeader>

            <CardBody>
                <div className="mt-5">
                    <CreateConfirmationImportActions
                        isDownloadActive={displayGenerateConfig}
                        isUploadActive={displayUploadConfig}
                        loading={loading}
                        displayOnlyActions={displayOnlyActions}
                        handleGenerateImportModel={handleGenerateImportModel}
                        handleUploadConfig={handleUploadConfig}
                    />
                    <div>
                        {displayGenerateConfig && (
                            <CreateImportTemplate
                                loading={loading}
                                languages={languages}
                                templates={templates}
                                confirmationCategories={confirmationCategories}
                                generateExcelFile={generateExcelFile}
                                engagement={engagement}
                            />
                        )}

                        {displayUploadConfig && (
                            <ImportConfirmationsFormComponent
                                loading={loading}
                                intl={intl}
                                uploadConfirmationFile={uploadConfirmationFile}
                                sbmtChckRef={sbmtChckRef}
                            />
                        )}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export const CreateConfirmationImportComponent = injectIntl(CreateConfirmationImport);

const sx = {
    smallButton: {
        margin: 0.5,
        minHeight: 'auto',
        minWidth: 'auto',
        fontSize: 10
    },

    bigButton: {
        height: '50px',
        minWidth: '280px',
        margin: 2
    }
};
