import { useIntl } from 'react-intl';
import { Divider, Grid } from '@mui/material';
import { Card, CardBody, CardHeader } from '../../../partials/controls/Card';
import { StackableSteps } from './StackableSteps';
import React from 'react';

export const ConfirmationStackableActionsSection = ({
    stackableSteps,
    handleSelection,
    workflowCurrentPosition
}) => {
    const intl = useIntl();
    
    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: 'CONFIRMATION.STACKABLE.ACTIONS.CHOICE' })}></CardHeader>
            <CardBody>
                <Grid item container direction="row" display="flex" justifyContent="space-between" alignItems="center">
                    {React.Children.toArray(
                        Object.keys(stackableSteps).map((position, index, keys) => {
                            const currentStackableSteps = stackableSteps[position];
                            
                            return (
                                currentStackableSteps && (
                                    <>
                                        <Grid item>
                                            <StackableSteps
                                                key={stackableSteps[position].id}
                                                stackableSteps={currentStackableSteps}
                                                handleSelectStackableStep={handleSelection}
                                                isEditable={workflowCurrentPosition <= position}
                                            />
                                        </Grid>
                                        {index < keys.length - 1 && <Divider style={{ width: '100%' }} />}
                                    </>
                                )
                            );
                        })
                    )}
                </Grid>
            </CardBody>
        </Card>
    );
};
