import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { MZRPaginatedTable } from '../common/MZRPaginatedTable';
import { getLocalizedString } from '../../i18n/i18Helper';

const uiOptions = {
    width: 250,
    cellStyle: { textAlign: 'center' }
};

function CompanyTableComponent(props) {
    const {
        intl,
        loading,
        isCompanyDeletable,
        isCompanyEditable,
        companiesPaginated,
        handleCompanyClick,
        handleFilterChange,
        handleCompanyDelete,
        handleCompanyEdit,
        shouldDisplayAdminColumns
    } = props;

    const [companies, setCompanies] = useState({});

    useEffect(() => {
        setCompanies(companiesPaginated);
    }, [companiesPaginated]);

    const handleRowClick = (_, rowData) => {
        handleCompanyClick(rowData);
    };

    const getColumns = (commonColumns, adminColumns, displayAdminColumns) => {
        return [...commonColumns, ...(displayAdminColumns ? adminColumns : [])];
    };

    const BASE_COLUMNS = [
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.NAME' }),
            field: 'name',
            ...uiOptions
        },
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.ADDRESS' }),
            field: 'address',
            ...uiOptions
        },
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.ZIPCODE' }),
            field: 'zipCode',
            ...uiOptions
        },
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.CITY' }),
            field: 'city',
            ...uiOptions
        },
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.COUNTRY' }),
            field: 'countryText',
            ...uiOptions
        }
    ];

    const ADMIN_COLUMNS = [
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.INTERNAL_ID' }),
            field: 'internalId',
            ...uiOptions
        },
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.COMPANY_ID_NUMBER' }),
            field: 'companyId',
            ...uiOptions
        },
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.COMPANY_ID_NUMBER2' }),
            field: 'companyId2',
            ...uiOptions
        },
        {
            title: intl.formatMessage({ id: 'COMPANY.TABLE.TITLE.VAT_NUMBER' }),
            field: 'vatId',
            ...uiOptions
        }
    ];

    const getActions = () => {
        let actions = [];

        if (isCompanyEditable) {
            actions.push({
                id: 'edit',
                iconProps: { fontSize: 'small', color: 'primary' },
                icon: 'edit',
                tooltip: getLocalizedString(intl, 'COMPANY.TABLE.EDIT.COMPANY'),
                onClick: (_, rowData) => handleCompanyEdit(rowData)
            });
        }

        if (isCompanyDeletable) {
            actions.push({
                id: 'delete',
                iconProps: { fontSize: 'small', color: 'secondary' },
                icon: 'delete',
                tooltip: getLocalizedString(intl, 'COMPANY.TABLE.DELETE.COMPANY'),
                onClick: (_, rowData) => handleCompanyDelete(rowData)
            });
        }

        return actions;
    };

    return (
        <>
            <MZRPaginatedTable
                actions={getActions()}
                columns={getColumns(BASE_COLUMNS, ADMIN_COLUMNS, shouldDisplayAdminColumns)}
                onRowClick={handleRowClick}
                loading={loading}
                data={companies}
                handleFilterChange={handleFilterChange}
            />
        </>
    );
}

CompanyTableComponent.defaultProps = {
    loading: false
};

CompanyTableComponent.propTypes = {
    /**
     * List of companies within a paginated object.
     */
    companiesPaginated: PropTypes.object,
    /**
     * Display admin columns.
     */
    displayAdminColumns: PropTypes.bool,
    /**
     * Indicator of loading during API requests.
     */
    loading: PropTypes.bool,
    /**
     * Function applied on row click.
     */
    handleSelectCompany: PropTypes.func,
    /**
     * Function to change API request filters (page, items per page,...)
     */
    handleFilterChange: PropTypes.func
};

export const CompanyTable = injectIntl(CompanyTableComponent);
