import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { SessionStatus } from '../../kit/utils/constants';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { storageConfigurationRepository, serializeStorageConfigurationDefault } from '../../kit/repository';
import { fetchData, fetchStorageDefault, fetchStorage } from '../../services';
import { createToast, getToastType } from '../../components/toast/Toast';
import { COMPONENT_NAME } from '../../components/setup/constants';
import { StorageDefaultWrapper } from '../../components/setup';

class StorageDefaultSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };
    }

    createStorage = async configuration => {
        const {
            intl,
            checkSession,
            addToastMessage,
            hasValidConfig,
            setIsStorageConfigExpanded
        } = this.props;
        this.setState({ loading: true });

        const configurationSerialized = serializeStorageConfigurationDefault({
            ...configuration,
            clusterId: this.props.currentClusterId
        });

        const result = await storageConfigurationRepository.createDefault(configurationSerialized);
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid && result.isSuccess) {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({
                        id: 'FETCH.STORAGE.DEFAULT.CONFIG.CREATE.SUCCESS'
                    })
                )
            );
            hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, true);
            setIsStorageConfigExpanded(false);
            await fetchStorage(this);
        } else {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({
                        id: 'FETCH.STORAGE.DEFAULT.CONFIG.CREATE.ERROR'
                    }),
                    result.error
                )
            );
            hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, false);
        }
        this.setState({ loading: false });
    };

    updateStorage = async configuration => {
        const {
            intl,
            checkSession,
            user,
            addToastMessage,
            hasValidConfig,
            setIsStorageConfigExpanded
        } = this.props;
        this.setState({ loading: true });

        const configurationSerialized = serializeStorageConfigurationDefault({
            ...configuration,
            clusterId: configuration?.clusterId ?? user?.clusterId
        });

        const result = await storageConfigurationRepository.updateDefault(
            configuration?.id,
            configurationSerialized
        );
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid && result.isSuccess) {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({
                        id: 'FETCH.STORAGE.DEFAULT.CONFIG.UPDATE.SUCCESS'
                    })
                )
            );
            hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, true);
            setIsStorageConfigExpanded(false);
            await fetchStorage(this);
        } else {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({
                        id: 'FETCH.STORAGE.DEFAULT.CONFIG.UPDATE.ERROR'
                    }),
                    result.error
                )
            );
            hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, false);
        }
        this.setState({ loading: false });
    };

    handleStorageConfig = async configuration =>
        !!configuration?.id ? this.updateStorage(configuration) : this.createStorage(configuration);

    async componentDidMount() {
        await fetchData(this, [() => fetchStorageDefault(this, false)]);
    }

    render() {
        return (
            <>
                <StorageDefaultWrapper
                    storageConfiguration={this.state.storageDefaultConfiguration}
                    handleStorageConfig={this.handleStorageConfig}
                    hasValidConfig={this.props.hasValidConfig}
                />
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const StorageDefaultSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(StorageDefaultSetupComponent));
