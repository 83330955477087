import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { Card, CardBody, CardHeader } from '../../partials/controls/Card';
import { Grid } from '@mui/material';
import { initItem } from '../document/InitItem';
import { swap } from '../../helpers/BaseHelper';
import { useForm } from '../../hooks/useForm';
import { FORM_FIELD } from '../../helpers';
import { getLocalizedString } from '../../i18n/i18Helper';

export function ValidationMessageComponent(props) {
    const { intl, user, document, documents, documentTypes, handleSubmit } = props;

    const [documentsLoaded, setDocumentLoaded] = useState([]);
    const [currentItem, setCurrentItem] = useState({
        ...initItem,
        isModificationAppliedToFolder: false,
        isItemRemovedFromFolder: false
    });

    useEffect(() => {
        setDocumentLoaded(documents);
    }, [documents]);

    useEffect(() => {
        setCurrentItem({ ...document, isModificationAppliedToFolder: false, isItemRemovedFromFolder: false });
    }, [document]);

    const ItemSchema = Yup.object().shape({
        documentType: Yup.number(),
        comment: Yup.string()
            .nullable(true)
            .min(20, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 20 }))
            .max(500, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 500 })),
        isChildItem: Yup.boolean(),
        isItemRemovedFromFolder: Yup.boolean(),
        isModificationAppliedToFolder: Yup.boolean().when('isItemRemovedFromFolder', {
            is: isItemRemovedFromFolder => isItemRemovedFromFolder,
            then: Yup.boolean().oneOf(
                [false],
                getLocalizedString(intl, 'MESSAGE.VALIDATION.DISPATCH.VALIDATION.REQUIRED_FIELD.APPLIED.TO.FOLDER'),
            )
        })
    });

    const btnValidationMsgRef = useRef(null);
    const triggerButtonClick = () => btnValidationMsgRef?.current?.click();
    props.btnValidationRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const handleItemSubmit = values => {
        let updatedCurrentDocument = values;

        if (values?.isItemRemovedFromFolder && !values?.isModificationAppliedToFolder) {
            updatedCurrentDocument = { ...updatedCurrentDocument, isChildItem: false, parentItemId: null };
        } else if (values?.isModificationAppliedToFolder && !values?.isItemRemovedFromFolder) {
            const [folder] = Object.values(documentsLoaded)?.filter(x => x?.id === currentItem?.parentItemId);

            // Apply modifications to all children items
            const childItems = folder?.childItems
                ?.filter(x => x?.id !== currentItem?.id)
                .reduce(
                    (parentAcc, item) => [
                        ...parentAcc,
                        {
                            ...item,
                            ...{
                                engagementId: values?.engagementId,
                                confirmationId: values?.confirmationId
                            }
                        }
                    ],
                    []
                );

            updatedCurrentDocument = {
                ...folder,
                ...{
                    documentType: values?.documentType,
                    comment: values?.comment,
                    engagementId: values?.engagementId,
                    confirmationId: values?.confirmationId,
                    childItems: [...childItems, updatedCurrentDocument]
                }
            };
        }

        handleSubmit(updatedCurrentDocument);
    };

    const MSG_FIELDS = [
        {
            id: 'isModificationAppliedToFolder',
            fieldType: FORM_FIELD.SWITCH_FIELD,
            labelItem: { id: 'MESSAGE.VALIDATION.DISPATCH.APPLIED.TO.FOLDER.LABEL' },
            notCheckedLabelId: 'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.ITEM.LABEL',
            notCheckedTooltipLabel: getLocalizedString(intl, 'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.ITEM.TOOLTIP'),
            checkedLabelId: 'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.FOLDER.LABEL',
            checkedTooltipLabel: getLocalizedString(intl, 'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.FOLDER.TOOLTIP'),
            color: 'primary'
        },
        {
            id: 'isItemRemovedFromFolder',
            fieldType: FORM_FIELD.SWITCH_FIELD,
            labelItem: { id: 'MESSAGE.VALIDATION.DISPATCH.REMOVE.FROM.FOLDER.LABEL' },
            notCheckedLabelId: 'MESSAGE.VALIDATION.DISPATCH.BUTTON.KEEP.IN.FOLDER.LABEL',
            notCheckedTooltipLabel: getLocalizedString(intl, 'MESSAGE.VALIDATION.DISPATCH.BUTTON.KEEP.IN.FOLDER.TOOLTIP'),
            checkedLabelId: 'MESSAGE.VALIDATION.DISPATCH.BUTTON.REMOVE.FROM.FOLDER.LABEL',
            checkedTooltipLabel: getLocalizedString(intl, 'MESSAGE.VALIDATION.DISPATCH.BUTTON.REMOVE.FROM.FOLDER.TOOLTIP'),
            color: 'primary'
        },
        {
            id: 'documentType',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'MESSAGE.VALIDATION.DISPATCH.ITEM.TYPE' },
            options: documentTypes ? swap(documentTypes) : [{ id: 0, name: 'None' }],
            variant: 'outlined'
        },
        {
            id: 'comment',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MESSAGE.VALIDATION.DISPATCH.ITEM.COMMENT' },
            component: 'textarea',
            rows: 4,
            variant: 'outlined'
        }
    ];

    const { RenderForm } = useForm({
        enableReinitialize: true,
        initialValues: currentItem,
        validationSchema: ItemSchema,
        handleSubmit: handleItemSubmit
    });

    return (
        <>
            <Grid container direction="column" justifyContent="space-around" alignItems="center" spacing={1}>
                <Card>
                    <CardHeader
                        title={
                            <FormattedMessage
                                id="MESSAGE.VALIDATION.DISPATCH.ITEM.CONTENT"
                                values={{ userName: user?.fullname, documentName: currentItem?.name }}
                            />
                        }></CardHeader>
                    <CardBody>
                        <RenderForm
                            formFields={MSG_FIELDS}
                            submitRef={btnValidationMsgRef}
                            submitTrigger={triggerButtonClick}
                        />
                    </CardBody>
                </Card>
            </Grid>
        </>
    );
}
