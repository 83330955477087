import React, { useMemo } from 'react';
import { PAPER_PROCESS_STATUS, PaperDocumentStatusIcon } from '../../../../document';
import { FileExtension } from '../../../../../kit/utils/constants';
import { CloudDownloadOutlined, Restore, DeleteForever, DeleteOutlineOutlined } from '@mui/icons-material';
import { UITooltip } from '../../../../common/UITooltip';
import { Box, IconButton } from '@mui/material';
import { Palette } from '../../../../../styles/colors';
import { DOCUMENT_ITEM_TYPE, ItemNode } from '../../../../../types/types';
import { useIntl } from 'react-intl';
import { MZRSearchBar } from '../../../../common/MZRSearchBar';
import { getLocalizedString } from '../../../../../i18n/i18Helper';

const paperProcessStatusDisplayed = [PAPER_PROCESS_STATUS.PENDING, PAPER_PROCESS_STATUS.IN_PROGRESS];
const selectDocumentTypeEnabled = [
    FileExtension.PDF,
    FileExtension.P7M,
    FileExtension.JPEG,
    FileExtension.JPG,
    FileExtension.PNG,
    FileExtension.XLS,
    FileExtension.XLSX
];

const getDocumentTypeSelectOptions = (documentTypes: Record<string, number>) => {
    return Object.keys(documentTypes).map(key => ({ id: documentTypes[key], name: key }));
};

const UNKNOWN_DOCUMENT_TYPE = 0;

const ConfirmationDocumentTypeSelect = ({ intl, node, documentTypes, handleChange }) => {
    const selectionDisabled = !selectDocumentTypeEnabled.includes(node?.fileExtension.toLowerCase());
    const documentTypesOptions = useMemo(() => getDocumentTypeSelectOptions(documentTypes), [documentTypes]);

    const handleClear = () => {
        handleChange({
            id: UNKNOWN_DOCUMENT_TYPE,
            name: getLocalizedString(intl, 'CONFIRMATION.DOCUMENT.TYPE.' + UNKNOWN_DOCUMENT_TYPE)
        });
    };

    return (
        <Box sx={{ width: '16em' }}>
            <MZRSearchBar
                customStyle={{ width: '100%' }}
                name="documentType"
                isAreaFocusVisible={false}
                disabled={selectionDisabled}
                value={{
                    id: node.documentType,
                    name: documentTypes[node.documentType]
                }}
                getOptionValue={option => option.id}
                getOptionLabel={option => getLocalizedString(intl, 'CONFIRMATION.DOCUMENT.TYPE.' + option.id)}
                options={documentTypesOptions}
                onSelectionChange={handleChange}
                placeholder={getLocalizedString(intl, 'CONFIRMATION.DOCUMENT.TYPE.PLACEHOLDER')}
                isClearable
                // @ts-ignore
                onClear={handleClear}
                onInputChange={undefined}
            />
        </Box>
    );
};

interface ConfirmationDocumentTreeItemActionsProps {
    node: ItemNode;
    documentTypes: any[];
    handleChange: (node: any) => void;
    authorizedDownload: string[];
    handleDownload: (event: any, node: any) => void;
    handleTrash: (event: any, node: any) => void;
    handleRestore: (event: any, node: any) => void;
    handleDelete: (event: any, node: any) => void;
}

export const ConfirmationDocumentTreeItemActions = ({
    node,
    documentTypes,
    handleChange,
    authorizedDownload,
    handleDownload,
    handleTrash,
    handleRestore,
    handleDelete
}: ConfirmationDocumentTreeItemActionsProps) => {
    const intl = useIntl();

    const isSetDocumentTypeAuthorized =
        node.documentType !== DOCUMENT_ITEM_TYPE.FOLDER && node?.fileExtension.toLowerCase() !== FileExtension.EML;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                m: 1
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexGrow: 1,
                    justifyContent: 'stretch',
                    paddingTop: 1,
                    marginRight: 5
                }}>
                {/* Paper process status */}
                {paperProcessStatusDisplayed.includes(node.paperSendingStatus) && (
                    <div>
                        <PaperDocumentStatusIcon intl={intl} item={node} fontSize="small" />
                    </div>
                )}

                {!node.isTrashed &&
                    isSetDocumentTypeAuthorized &&
                    ConfirmationDocumentTypeSelect({
                        intl,
                        node,
                        documentTypes,
                        handleChange
                    })}
            </Box>

            <Box sx={styles.container}>
                {!node.isTrashed && authorizedDownload.includes(node?.fileExtension.toLowerCase()) && (
                    <UITooltip
                        title={getLocalizedString(intl, 'ITEM.DOWNLOAD.INFO')}
                        children={
                            <IconButton onClick={event => handleDownload(event, node)} sx={styles.iconButton}>
                                <CloudDownloadOutlined sx={styles.icon} />
                            </IconButton>
                        }
                    />
                )}

                {node.isDeletable && !node.isTrashed && (
                    <UITooltip
                        title={getLocalizedString(intl, 'ITEM.TRASH.INFO')}
                        children={
                            <IconButton onClick={event => handleTrash(event, node)} sx={styles.iconButton}>
                                <DeleteOutlineOutlined sx={{ color: Palette.red }} />
                            </IconButton>
                        }
                    />
                )}

                {node.isTrashed && (
                    <>
                        <UITooltip
                            title={getLocalizedString(intl, 'ITEM.RESTORE.INFO')}
                            children={
                                <IconButton onClick={event => handleRestore(event, node)} sx={styles.iconButton}>
                                    <Restore sx={styles.icon} />
                                </IconButton>
                            }
                        />

                        <UITooltip
                            title={getLocalizedString(intl, 'ITEM.DELETE.INFO')}
                            children={
                                <IconButton onClick={event => handleDelete(event, node)} sx={styles.iconButton}>
                                    <DeleteForever sx={{ color: Palette.red }} />
                                </IconButton>
                            }
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};

const styles = {
    container: {
        flexDirection: 'row',
        flex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    iconButton: {
        height: 34,
        width: 34,
        '& svg': {
            fontSize: 24
        }
    },
    icon: {
        color: Palette.defaultPrimaryColor
    }
};
