import { buildFilters } from '../../network/mzrRequest';
import { appApiUrl } from '../../config/appConfiguration';
import { parseConfiguration } from './setupParsers';
import { Repository } from '../Repository';

/**
 * Class to communicate with API to access to configuration resources.
 */
class ConfigurationRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    /**
     * Get all configurations status.
     */
    getAll = async (filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`configuration${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseConfiguration);
        return result;
    };
}

export const configurationRepository = new ConfigurationRepository();
