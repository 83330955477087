import { useRef, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import {
    mapTagsToFields,
    handleSubmitWithTags,
    tagsArrayToObject,
    getGroupedStackableActions,
    getYupValidators,
    sort
} from '../../../../helpers';
import { formatAutoName, sortSteps, ConfirmationSection } from '../../common';
import { ConfirmationStackableActionsSection } from '../../workflow'
import { GENERAL_FIELDS, DATES_FIELDS, AUTOMATION_FIELDS } from './fields';

const classes = {
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    }
};

/**
 * Select parameters component for create confirmation.
 */
export function SelectParametersComponent(props) {
    const { confirmation, engagement, handleConfirmationUpdate, handleStepOptionSelect } = props;
    const [sortedSteps, setSortedSteps] = useState([]);
    const [extraFields] = useState(
        sort(
            confirmation?.optionalFields?.length > 0
                ? confirmation?.optionalFields
                : confirmation?.letterTemplate.tags?.filter(field => !field.isMandatory && field.isInput)
        )
    );
    const { steps: stackableSteps, isStackable } = getGroupedStackableActions(sortedSteps);
    
    const btnFormRef = useRef(null);
    const triggerButtonClick = () => btnFormRef?.current?.click();
    props.sbmtParamRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    useEffect(() => {
        confirmation && setSortedSteps(sortSteps(confirmation?.workflowActions));
        handleConfirmationUpdate({
            name: formatAutoName(engagement, confirmation)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const confirmationSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
            .max(255, <FormattedMessage id="INVALID.FIELD.MAX.SYMBOLS" values={{ max: 255 }} />)
            .required(<FormattedMessage id="SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.NAME" />),
        description: Yup.string()
            .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
            .max(500, <FormattedMessage id="INVALID.FIELD.MAX.SYMBOLS" values={{ max: 500 }} />),
        clientDueDate: Yup.date().required(
            <FormattedMessage id="SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.CLIENT.DUEDATE" />
        ),
        automaticClientSending: Yup.bool(),
        thirdPartyDueDate: Yup.date().required(
            <FormattedMessage id="SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.THIRDPARTY.DUEDATE" />
        ),
        automaticThirdPartySending: Yup.bool(),
        ...getYupValidators(extraFields)
    });

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...confirmation, ...tagsArrayToObject(extraFields) }}
            validationSchema={confirmationSchema}
            onSubmit={values => handleSubmitWithTags(values, extraFields, handleConfirmationUpdate)}>
            {_ => {
                return (
                    <Form className="form form-label-right">
                        <Grid
                            container
                            direction="column"
                            sx={classes.root}
                            spacing={2}
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={6}>
                                    <ConfirmationSection
                                        title={'SELECT.PARAMETERS.CONFIRMATION.SECTION.INFORMATION'}
                                        fields={GENERAL_FIELDS}
                                        sectionKey="GENERAL"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    {isStackable && (
                                        <ConfirmationStackableActionsSection
                                            workflowCurrentPosition={0}
                                            stackableSteps={stackableSteps}
                                            handleSelection={handleStepOptionSelect}
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={6} spacing={2}>
                                    <ConfirmationSection
                                        title={'SELECT.PARAMETERS.CONFIRMATION.SECTION.DATES'}
                                        fields={DATES_FIELDS}
                                        sectionKey="DATES"
                                    />
                                </Grid>

                                <Grid item xs={6} spacing={2}>
                                    {extraFields?.length > 0 && (
                                        <ConfirmationSection
                                            title={'SELECT.PARAMETERS.CONFIRMATION.SECTION.OTHERS'}
                                            fields={mapTagsToFields(extraFields)}
                                            sectionKey="EXTRA_TAGS"
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            {props.automation && (
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={6}>
                                        <ConfirmationSection
                                            title={'SELECT.PARAMETERS.CONFIRMATION.SECTION.AUTO'}
                                            fields={AUTOMATION_FIELDS}
                                            sectionKey="AUTO"
                                        />
                                    </Grid>
                                </Grid>
                            )}

                        </Grid>

                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            onClick={triggerButtonClick}
                            ref={btnFormRef}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}
