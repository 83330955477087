import React, { useEffect, useRef, useState, useMemo, MutableRefObject } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../../i18n/i18Helper';
import { useForm } from '../../../../hooks/useForm';
import { E_AUTH_TYPE, initMailingConfiguration, MailingConfigurationSpec } from '../../../../types/configTypes';
import { FORM_FIELD } from '../../../../helpers';
import TextInput from '../../../textField/TextInput';
import { SECURE_SOCKET_OPTIONS } from '../../../../types/types';

interface MailingConfigComponentProps {
    authProviders: any;
    mailingConfiguration: any;
    mailingRef: MutableRefObject<any>;
    handleSave: (values: any) => void;
    isPreview: boolean;
}

export function MailingConfigComponent({
    authProviders,
    mailingConfiguration,
    mailingRef,
    handleSave,
    isPreview = false,
}: MailingConfigComponentProps) {
    const intl = useIntl();
    const buttonFormRef = useRef(null);
    const variant = isPreview ? 'standard' : 'outlined';

    const [currentConfiguration, setCurrentConfig] = useState<MailingConfigurationSpec | null>(initMailingConfiguration);

    const authProviderOptions = useMemo(() => {
        return authProviders?.map(provider => ({ id: provider.id, name: provider.providerName }));
    }, [authProviders]);

    useEffect(() => {
        mailingConfiguration && setCurrentConfig(mailingConfiguration);
    }, [mailingConfiguration]);

    // @ts-ignore
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    mailingRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const BasicAuthSchema = Yup.object().shape({       
        providerName: Yup.string()
            .min(5, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 5 }))
            .max(80, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 80 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROVIDER.NAME')),
        smtpDisplayName: Yup.string()
            .max(80, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 80 }))
            .nullable(true),
        smtpUsername: Yup.string()
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.ACCOUNT')),
        smtpPassword: Yup.string()
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.PASSWORD')),
        smtpHost: Yup.string()
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.HOST')),
        smtpPort: Yup.number()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 0 }))
            .max(49151, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 49151 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.PORT')),
        smtpSecureSocketOptions: Yup.number()
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.SECURE_SOCKET_OPTIONS')),
        smtpAcceptAllSslCertificates: Yup.bool()
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.ACCEPT_ALL_SSL_CERTIFICATES')),
        imapUsername: Yup.string()
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.ACCOUNT')),
        imapPassword: Yup.string()
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.PASSWORD')),
        imapHost: Yup.string()
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.HOST')),
        imapPort: Yup.number()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 0 }))
            .max(49151, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 49151 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.PORT')),
        imapSecureSocketOptions: Yup.number()
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.SECURE_SOCKET_OPTIONS')),
        imapAcceptAllSslCertificates: Yup.bool()
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.ACCEPT_ALL_SSL_CERTIFICATES')),
    });

    const ModernAuthSchema = Yup.object().shape({
        mailingAuthProviderId: Yup.string()
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROVIDER')),
        userName: Yup.string()
            .min(5, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 5 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.USERNAME')),
        userObjectId: Yup.string()
            .min(36, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 36 }))
            .max(36, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 36 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.USER_ID')),
      });

    const ConfigurationSchema = Yup.object().shape({
        clusterId: Yup.string().nullable(true),
        administrators: Yup.string()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.ADMINISTRATOR')),
        inboxFolder: Yup.string()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.INBOX.FOLDER')),
        sentItemsFolder: Yup.string()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SENT_ITEMS.FOLDER')),
        processingInProgressFolder: Yup.string()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.WIP.FOLDER')),
        processedFolder: Yup.string()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROCESSED.FOLDER')),
        requestsFolder: Yup.string()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.REQUESTS.FOLDER')),
        issuesFolder: Yup.string()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.ISSUES.FOLDER')),
        othersFolder: Yup.string()
            .min(0, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(255, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 255 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.OTHERS.FOLDER')),
        numberOfDaysBeforeFollowUp: Yup.number()
            .min(1, getLocalizedString(intl, 'INVALID.FIELD.MIN.NUMBER', { min: 1 }))
            .max(30, getLocalizedString(intl, 'INVALID.FIELD.MAX.NUMBER', { max: 30 }))
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.FOLLOWUP.NB.DAYS')),
        automaticDeleteEmails: Yup.boolean()
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.AUTOMATIC_DELETE_EMAILS')),
        authType: Yup.string()
            .required(getLocalizedString(intl, 'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.TYPE.AUTH')),
        basicAuthConfiguration: Yup.object()
            .when('authType', { is: E_AUTH_TYPE.BASIC_AUTH, then: BasicAuthSchema })
            .nullable(true),
        modernAuthConfiguration: Yup.object()
            .when('authType', { is: E_AUTH_TYPE.MODERN_AUTH, then: ModernAuthSchema })
            .nullable(true)
    });

    const SELECT_AUTH_TYPES = [
        {
            id: E_AUTH_TYPE.BASIC_AUTH,
            name: getLocalizedString(intl, 'MAILING.CONFIGURATION.BASICAUTH.TYPE.LABEL')
        },
        {
            id: E_AUTH_TYPE.MODERN_AUTH,
            name: getLocalizedString(intl, 'MAILING.CONFIGURATION.MODERNAUTH.TYPE.LABEL')
        }
    ];

    const SELECT_SECURE_SOCKET_OPTIONS = [
        {
            id: SECURE_SOCKET_OPTIONS.NONE,
            name: getLocalizedString(intl, 'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.NONE.LABEL')
        },
        {
            id: SECURE_SOCKET_OPTIONS.SSL_ON_CONNECT,
            name: getLocalizedString(intl, 'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.SSL_ON_CONNECT.LABEL')
        },
        {
            id: SECURE_SOCKET_OPTIONS.START_TLS,
            name: getLocalizedString(intl, 'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.START_TLS.LABEL')
        },
        {
            id: SECURE_SOCKET_OPTIONS.START_TLS_WHEN_AVAILABLE,
            name: getLocalizedString(intl, 'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.START_TLS_WHEN_AVAILABLE.LABEL')
        },
        {
            id: SECURE_SOCKET_OPTIONS.AUTO,
            name: getLocalizedString(intl, 'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.AUTO.LABEL')
        },
    ];

    const MAILING_CONFIG = [
        {
            id: 'inboxFolder',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.INBOX.FOLDER.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.INBOX.FOLDER.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'sentItemsFolder',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.SENT_ITEMS.FOLDER.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.SENT_ITEMS.FOLDER.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'processingInProgressFolder',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.WIP.FOLDER.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.WIP.FOLDER.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'processedFolder',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.PROCESSED.FOLDER.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.PROCESSED.FOLDER.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'requestsFolder',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.REQUESTS.FOLDER.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.REQUESTS.FOLDER.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'issuesFolder',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.ISSUES.FOLDER.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.ISSUES.FOLDER.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'othersFolder',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.OTHERS.FOLDER.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.OTHERS.FOLDER.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            // placeholder
        },
        {
            id: 'authType',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.TYPE.AUTH.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.TYPE.AUTH.PLACEHOLDER'),
            options: SELECT_AUTH_TYPES,
            variant: variant,
            disabled: isPreview
        },
        {           
            id: 'administrators',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.ADMINISTRATORS.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.ADMINISTRATORS.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'numberOfDaysBeforeFollowUp',
            fieldType: FORM_FIELD.TEXT_FIELD,
            type: 'number',
            labelItem: { id: 'MAILING.CONFIG.FOLLOWUP.NB.DAYS.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.FOLLOWUP.NB.DAYS.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'automaticDeleteEmails',
            fieldType: FORM_FIELD.SWITCH_FIELD,
            labelItem: { id: 'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.LABEL', isRequired: true },
            notCheckedLabelId: 'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.DEACTIVATED.LABEL',
            notCheckedTooltipLabel: getLocalizedString(intl, 'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.DEACTIVATED.TOOLTIP'),
            checkedLabelId: 'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.ACTIVATED.LABEL',
            checkedTooltipLabel: getLocalizedString(intl, 'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.ACTIVATED.TOOLTIP'),
            color: 'primary',
            variant: variant,
            disabled: isPreview
        }
    ];

    const BASIC_AUTH_CONFIG_SMTP = [
        {
            id: 'basicAuthConfiguration.smtpHost',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.SMTP.HOST.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.HOST.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.smtpPort',
            fieldType: FORM_FIELD.TEXT_FIELD,
            type: 'number',
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.SMTP.PORT.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.PORT.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.smtpUsername',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCOUNT.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCOUNT.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.smtpPassword',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.SMTP.PASSWORD.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.PASSWORD.PLACEHOLDER'),
            component: TextInput,
            type: 'password',
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.smtpSecureSocketOptions',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.SMTP.SECURE_SOCKET_OPTIONS.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.SECURE_SOCKET_OPTIONS.PLACEHOLDER'),
            options: SELECT_SECURE_SOCKET_OPTIONS,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.smtpAcceptAllSslCertificates',
            fieldType: FORM_FIELD.SWITCH_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.LABEL', isRequired: true },
            notCheckedLabelId: 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.LABEL',
            notCheckedTooltipLabel: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.TOOLTIP'),
            checkedLabelId: 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.YES.LABEL',
            checkedTooltipLabel: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.YES.TOOLTIP'),
            color: 'primary',
            variant: variant,
            disabled: isPreview
        },
    ];

    const BASIC_AUTH_CONFIG_IMAP = [
        {
            id: 'basicAuthConfiguration.imapHost',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.IMAP.HOST.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.IMAP.HOST.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.imapPort',
            fieldType: FORM_FIELD.TEXT_FIELD,
            type: 'number',
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.IMAP.PORT.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.IMAP.PORT.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.imapUsername',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCOUNT.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCOUNT.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.imapPassword',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.IMAP.PASSWORD.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.IMAP.PASSWORD.PLACEHOLDER'),
            component: TextInput,
            type: 'password',
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.imapSecureSocketOptions',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.IMAP.SECURE_SOCKET_OPTIONS.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.IMAP.SECURE_SOCKET_OPTIONS.PLACEHOLDER'),
            options: SELECT_SECURE_SOCKET_OPTIONS,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.imapAcceptAllSslCertificates',
            fieldType: FORM_FIELD.SWITCH_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.LABEL', isRequired: true },
            notCheckedLabelId: 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.LABEL',
            notCheckedTooltipLabel: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.TOOLTIP'),
            checkedLabelId: 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.YES.LABEL',
            checkedTooltipLabel: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.TOOLTIP'),
            color: 'primary',
            variant: variant,
            disabled: isPreview
        },
    ];

    const BASIC_AUTH_CONFIG_ADMIN = [
        {
            id: 'basicAuthConfiguration.providerName',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.PROVIDER.NAME.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.PROVIDER.NAME.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'basicAuthConfiguration.smtpDisplayName',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.BASIC.AUTH.DISPLAY.NAME.LABEL' },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.BASIC.AUTH.DISPLAY.NAME.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {}, // add blank space
        ...BASIC_AUTH_CONFIG_SMTP,
        ...BASIC_AUTH_CONFIG_IMAP
    ];

    const MODERN_AUTH_CONFIG = [
        {
            id: 'modernAuthConfiguration.mailingAuthProviderId',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.MODERNAUTH.PROVIDER.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.MODERNAUTH.PROVIDER.PLACEHOLDER'),
            options: authProviderOptions ? authProviderOptions : [{ id: 0, name: 'None' }],
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'modernAuthConfiguration.userName',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.MODERNAUTH.USERNAME.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.MODERNAUTH.USERNAME.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'modernAuthConfiguration.userObjectId',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'MAILING.CONFIG.MODERNAUTH.USER_ID.LABEL', isRequired: true },
            placeholder: getLocalizedString(intl, 'MAILING.CONFIG.MODERNAUTH.USER_ID.PLACEHOLDER'),
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
    ];

    const SUB_FORMS = [
        { id: E_AUTH_TYPE.BASIC_AUTH, form: BASIC_AUTH_CONFIG_ADMIN, numberOfcolumns: 3 },
        { id: E_AUTH_TYPE.MODERN_AUTH, form: MODERN_AUTH_CONFIG, numberOfcolumns: 3 }
    ];

    const { RenderForm: MailingConfigForm } = useForm({
        enableReinitialize: true,
        initialValues: currentConfiguration,
        validationSchema: ConfigurationSchema,
        handleSubmit: handleSave
    });

    return (
        <>
            <MailingConfigForm
                formFields={MAILING_CONFIG}
                formNumberOfColumns={4}
                conditionalFormKey="authType"
                conditionalFields={SUB_FORMS}
                submitRef={buttonFormRef}
                submitTrigger={triggerButtonClick}
            />
        </>
    );
}
