import { ServiceResult } from '../../network/ServiceResult';
import { ClientRequest, RefuseConfirmationRequestSpec, UploadDocumentSpec } from '../../../types/types';
import { appApiUrl } from '../../config/appConfiguration';
import { parsePaginationResponse } from '../parsers';
import { buildFilters } from '../../network/mzrRequest';
import { parseConfirmations } from '../confirmation/confirmationParsers';
import { Repository } from '../Repository';

export interface ClientEngagementRequestRepositorySpec {
    fetchAll(engagementId: string, filters: Record<string, any>): Promise<ServiceResult>;

    refuse(requests: RefuseConfirmationRequestSpec[]): Promise<ServiceResult>;

    upload(letterInfo: any): Promise<ServiceResult>;

    confirm(request: ClientRequest): Promise<ServiceResult>;
}

export class ClientEngagementRequestRepository extends Repository implements ClientEngagementRequestRepositorySpec {
    constructor() {
        super(appApiUrl);
    }

    async fetchAll(engagementId: string, filters: Record<string, any>): Promise<ServiceResult> {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`clientRequests/${engagementId}${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseConfirmations));
        return result;
    }

    async refuse(requests: RefuseConfirmationRequestSpec[]): Promise<ServiceResult> {
        return await this.network.put('clientRequests/refuse', requests, {
            headers: this.buildHeaders()
        });
    }

    upload(letterInfo: UploadDocumentSpec): Promise<ServiceResult> {
        const result = new ServiceResult({ body: {}, status: 200, error: '', headers: this.buildHeaders(true) });

        return Promise.resolve(result);
    }

    confirm(request: ClientRequest): Promise<ServiceResult> {
        const result = new ServiceResult({ body: {}, status: 200, error: '', headers: this.buildHeaders(true) });

        return Promise.resolve(result);
    }
}

export const clientEngagementRequestRepository = new ClientEngagementRequestRepository();
