import { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextInput from '../../../../textField/TextInput';
import * as Yup from 'yup';
import { initConfirmation } from '../../../common';
import '../../../common/confirmationInfo.css';
import { EditConfirmationSection } from '../EditConfirmationSection';
import {
    getYupValidators,
    handleSubmitWithTags,
    mapTagsToFields,
    tagsArrayToObject
} from '../../../../../helpers/TagsHelper';
import clsx from 'clsx';
import { Card, CardBody, CardHeaderTitle } from '../../../../../partials/controls/Card';

export function ConfirmationInfoComponent(props) {
    const { confirmation, languages, handleSubmit, classes } = props;

    const [confirmationInformation, setInformation] = useState(initConfirmation);
    const [optionalFields, setOptionalFields] = useState([]);
    const confirmationSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
            .required(<FormattedMessage id="CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.CONFIRMATION_NAME" />),
        description: Yup.string().min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />),
        clientSendDate: Yup.date(),
        clientDueDate: Yup.date().required(
            <FormattedMessage id="CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.CLIENT_DUEDATE" />
        ),
        automaticClientSending: Yup.bool().required(
            <FormattedMessage id="CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.AUTOMATIC_CLIENT_SENDDATE" />
        ),
        thirdPartyDueDate: Yup.date().required(
            <FormattedMessage id="CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.THIRDPARTY_DUEDATE" />
        ),
        automaticThirdPartySending: Yup.bool().required(
            <FormattedMessage id="CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.AUTOMATIC_THIRDPARTY_SENDDATE" />
        ),
        languageId: Yup.string(),
        ...getYupValidators(optionalFields)
    });

    const uiOptions = {
        component: TextInput,
        variant: 'standard',
        className: clsx(classes.margin, classes.textField),
        disabled: !confirmationInformation.isEditable
    };

    const INFORMATION_FIELDS = [
        {
            labelItem: { id: 'CONFIRMATION.LABEL.CONFIRMATION_CLIENT', isRequired: true },
            TextFieldItem: { id: 'clientCompany.name', ...uiOptions, disabled: true }
        },
        {
            labelItem: { id: 'CONFIRMATION.LABEL.CONFIRMATION_NAME', isRequired: true },
            TextFieldItem: { id: 'name', ...uiOptions }
        },
        {
            labelItem: { id: 'CONFIRMATION.LABEL.CONFIRMATION_DESCRIPTION' },
            TextFieldItem: { id: 'description', ...uiOptions }
        },
        {
            labelItem: { id: 'CONFIRMATION.LABEL.CONFIRMATION_CODE', isRequired: true },
            TextFieldItem: { id: 'confirmationCode', ...uiOptions, disabled: true }
        },
        {
            labelItem: { id: 'CONFIRMATION.LABEL.CLIENT_DUEDATE', isRequired: true },
            DateFieldItem: {
                id: 'clientDueDate',
                ...uiOptions
            }
        },
        {
            labelItem: { id: 'CONFIRMATION.LABEL.THIRDPARTY_DUEDATE', isRequired: true },
            DateFieldItem: {
                id: 'thirdPartyDueDate',
                ...uiOptions
            }
        },
        {
            labelItem: { id: 'CONFIRMATION.LABEL.LANGUAGE' },
            SelectFieldItem: {
                id: 'languageId',
                options: languages,
                value: confirmation?.languageId,
                ...uiOptions
            }
        }
    ];

    const buttonFormRef = useRef(null);
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    props.btnConfRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    useEffect(() => {
        const fields = confirmation?.optionalFields?.filter(field => field.isInput);
        setOptionalFields(fields);
        // the extra fields (tags) should be spread in confirmation level to be able to show and control them (for formik)
        const simplifiedFields = tagsArrayToObject(fields);
        setInformation({ ...confirmation, ...simplifiedFields });
    }, [confirmation]);

    return (
        <Card>
            <CardBody>
                <CardHeaderTitle>
                    <FormattedMessage id="CONFIRMATION.SUBSECTION.INFORMATION" />
                </CardHeaderTitle>
                <Formik
                    enableReinitialize={true}
                    initialValues={confirmationInformation}
                    validationSchema={confirmationSchema}
                    onSubmit={values => handleSubmitWithTags(values, optionalFields, handleSubmit)}>
                    {_ => (
                        <Form>
                            <EditConfirmationSection fields={INFORMATION_FIELDS} />

                            {optionalFields?.length > 0 && (
                                <EditConfirmationSection fields={mapTagsToFields(optionalFields, uiOptions)} />
                            )}
                            <button
                                type="submit"
                                style={{ display: 'none' }}
                                onClick={triggerButtonClick}
                                ref={buttonFormRef}
                            />
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
}

injectIntl(ConfirmationInfoComponent);
