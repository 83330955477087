import { appApiUrl } from '../../config/appConfiguration';
import { parseLanguage, parseLanguages } from './setupParsers';
import { Repository } from '../Repository';

class LanguageRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`language`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseLanguages);
        return result;
    };

    fetch = async (languageId) => {
        let result = await this.network.get(`language/${languageId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseLanguage);
        return result;
    };

    fetchName = async (languageName) => {
        let result = await this.network.get(`language/name/${languageName}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseLanguage);
        return result;
    };

    update = async (languageId) => {
        return await this.network.put(`language/default/${languageId}`, {
            headers: this.buildHeaders()
        });
    };
}

export const languageRepository = new LanguageRepository();
