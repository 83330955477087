import * as Yup from 'yup';
import { FORM_FIELD } from '../../../helpers';
import { useIntl } from 'react-intl';
import React, { useRef } from 'react';
import { useForm } from '../../../hooks/useForm';
import { Box } from '@mui/material';

interface EngagementSelectOptionsProps {
    engagement: any;
    handleSubmit: (values: any) => void;
    loading: boolean;
    sbmtEngagementRef: any;
}

const checkboxStyle = { margin: '0 1em' };

export const EngagementSelectOptions = ({
    engagement,
    handleSubmit,
    sbmtEngagementRef
}: EngagementSelectOptionsProps) => {
    const intl = useIntl();
    const validationSchema = Yup.object().shape({
        copyLetterheads: Yup.bool(),
        copyThirdParties: Yup.bool()
    });

    const buttonFormRef = useRef(null);
    // @ts-ignore
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    sbmtEngagementRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const fields = [
        {
            fieldType: FORM_FIELD.CHECKBOX_FIELD,
            id: 'copyLetterheads',
            customStyle: checkboxStyle,
            labelId: intl.formatMessage({ id: 'CREATE.ENGAGEMENT.CHECKBOX.COPY.LETTERHEADS.LABEL' }),
            toolTipLabelId: intl.formatMessage({ id: 'CREATE.ENGAGEMENT.CHECKBOX.COPY.LETTERHEADS.TOOLTIP' }),
            color: 'primary',
            isSubmitOnChange: true
        },
        {
            fieldType: FORM_FIELD.CHECKBOX_FIELD,
            id: 'copyThirdParties',
            customStyle: checkboxStyle,
            labelId: intl.formatMessage({ id: 'CREATE.ENGAGEMENT.CHECKBOX.COPY.THIRD_PARTY.LABEL' }),
            toolTipLabelId: intl.formatMessage({ id: 'CREATE.ENGAGEMENT.CHECKBOX.COPY.THIRD_PARTY.TOOLTIP' }),
            color: 'primary',
            isSubmitOnChange: true
        }
    ];

    const { RenderForm: SelectOptionsForm } = useForm({
        initialValues: engagement,
        handleSubmit: handleSubmit,
        enableReinitialize: true,
        validationSchema: validationSchema
    });

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SelectOptionsForm
                key="optional_fields"
                formFields={fields}
                conditionalFields={undefined}
                conditionalFormKey={undefined}
                submitTrigger={triggerButtonClick}
                submitRef={buttonFormRef}
            />
        </Box>
    );
};
