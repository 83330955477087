import { UISectionTitle } from './UISectionTitle';
import { Box } from '@mui/material';
import React from 'react';
import { UIText } from './UIText';

export interface UISectionHeaderProps {
    title: string;
    subtitle?: string;
    titleProps?: any;
    toolbar?: React.ReactNode;
}

export const UISectionHeader = ({ toolbar: Toolbar, title, subtitle, titleProps = {} }: UISectionHeaderProps) => {
    return (
        <>
            <Box sx={{ direction: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <UISectionTitle title={title} {...titleProps} />
                {Toolbar ? Toolbar : null}
            </Box>
            {subtitle && <UIText text={subtitle} />}
        </>
    );
};
