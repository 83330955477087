import { buildFilters } from '../../network/mzrRequest';
import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseShort } from '../parsers';
import { parseConfirmationCategories, parseConfirmationCategory } from './confirmationParsers';
import { Repository } from '../Repository';

class ConfirmationCategoryRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`confirmationCategory`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseConfirmationCategories);
        return result;
    };

    create = async (type) => {
        let result = await this.network.post(`confirmationCategory`, type, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    fetch = async (categoryId) => {
        let result = await this.network.get(`confirmationCategory/${categoryId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseConfirmationCategory);
        return result;
    };

    update = async (categoryId, category) => {
        return await this.network.put(`confirmationCategory/${categoryId}`, category, {
            headers: this.buildHeaders()
        });
    };

    delete = async (categoryId) => {
        return await this.network.put(`confirmationCategory/${categoryId}`, null, {
            headers: this.buildHeaders()
        });
    };

    fetchByOrganizationCluster = async (clusterId) => {
        let filters = buildFilters({ clusterId: clusterId });
        let result = await this.network.get(`confirmationCategory${filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseConfirmationCategories);
        return result;
    };
}

export const confirmationCategoryRepository = new ConfirmationCategoryRepository();
