import { parseConfirmations } from '../confirmation/confirmationParsers';
import { parseAppUsers } from '../user/userParsers';
import { parseWorkflowAction } from '../workflow/workflowParsers';
import { parseItems } from '../item/itemParsers';
import { parseCompanies } from '../company/companyParsers';
import { parseContacts } from '../contact/contactParsers';

export const parseEngagementsMenu = json => {
    return json ? Object.values(json).map(x => parseEngagementMenuItem(x)) : [];
};

export const parseEngagementMenuItem = json => {
    return {
        id: json.id,
        name: json.name,
        engagementCode: json.engagementCode,
        clientCompanies: json.clientCompanies ? parseCompanies(json.clientCompanies) : []
    };
};

export const parseEngagementRole = json => {
    return {
        id: json.id,
        appUserId: json.appUserId,
        engagementId: json.engagementId,
        engagementRole: json.engagementRole
    };
};

export const parseEngagements = json => {
    return json ? Object.values(json).map(x => parseEngagement(x)) : [];
};

export const parseEngagement = json => {
    return {
        id: json.id,
        name: json.name,
        description: json.description,
        engagementCode: json.engagementCode,
        openingDate: json.openingDate,
        closingDate: json.closingDate,
        archived: json.archived,
        clusterId: json.clusterId,
        creatorId: json.creatorId,
        clientCompanies: json.clientCompanies ? parseCompanies(json.clientCompanies) : [],
        clientContacts: json.clientContacts ? parseContacts(json.clientContacts) : [],
        numberOfCompanies: json.numberOfCompanies,
        appUserEngagements: json.appUserEngagements ? parseAppUsers(json.appUserEngagements) : [],
        numberOfAppUsers: json.numberOfAppUsers,
        confirmations: json.confirmations ? parseConfirmations(json.confirmations) : [],
        numberOfConfirmations: json.numberOfConfirmations,
        stepsProgress: json.stepsProgress ? json.stepsProgress.map(x => parseStepsProgress(x)) : [],
        isEditable: true, // json.isEditable, uncomment when it's ready
        isDeletable: json.isDeletable,
        documents: json.documents ? parseItems(json.documents) : [],
        numberOfPendingRequests: json.numberOfPendingRequests,
        numberOfRequests: json.numberOfRequests,

        parentId: json.parentId,
        mandateId: json.mandateId,
        copyLetterheads: json.copyLetterheads,
        copyThirdParties: json.copyThirdParties
    };
};

export const parseStepsProgress = json => {
    return {
        step: json.workflowAction ? parseWorkflowAction(json.workflowAction) : {},
        numberOfRequired: json.numberOfRequired,
        numberOfNotStarted: json.numberOfNotStarted,
        numberOfInProgress: json.numberOfInProgress,
        numberOfDone: json.numberOfDone,
        progress: json.progress
    };
};
