import { Box, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { UIActionButton } from '../common/UIActionButton';
import { getLocalizedString } from '../../i18n/i18Helper';
import React, { useRef } from 'react';
import { Palette } from '../../styles/colors';

interface AuthFormProps {
    fields: any;
    form: any;
    title: any;
    subtitle?: any;
    loading?: boolean;
    footer: JSX.Element;
}

const FormattedTitle = ({ title }: { title: string }) => {
    return (
        <FormattedMessage
            id={title}
            values={{
                b: (chunks: any) => (
                    <Typography
                        variant="h5"
                        style={{
                            fontWeight: 'bold',
                            color: Palette.defaultPrimaryColor
                        }}>
                        {chunks}
                    </Typography>
                )
            }}>
            {chunks => (
                <Typography variant="h5" style={{ color: Palette.primaryTextColor, textAlign: 'center' }}>
                    {chunks}
                </Typography>
            )}
        </FormattedMessage>
    );
};

export const AuthForm = ({ fields, form: Form, title, subtitle, loading, footer }: AuthFormProps) => {
    const intl = useIntl();
    const btnLoginRef = useRef(null);
    // @ts-ignore
    const triggerButtonClick = () => btnLoginRef?.current?.click();

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
                <FormattedTitle title={title} />
            </Box>

            {subtitle}

            <Form
                formFields={fields}
                key="auth-client-key"
                conditionalFields={undefined}
                conditionalFormKey={undefined}
                submitRef={btnLoginRef}
                submitTrigger={undefined}
            />

            <Box sx={{ display: 'flex', mt: 5, mr: '22px' }}>
                <UIActionButton
                    disabled={loading}
                    style={{ width: '100%' }}
                    onClick={triggerButtonClick}
                    title={getLocalizedString(intl, 'AUTH.LOGIN.BUTTON')}
                    type="submit">
                    {loading && <span className="ml-3 spinner spinner-white" />}
                </UIActionButton>
            </Box>

            {footer}
        </>
    );
};
