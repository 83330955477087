import { useRef, useState } from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { FormLabel } from '../../../helpers/FormHelper';
import { Alert } from '@mui/material';
import { dateWithOffset } from '../../../kit/utils/DateUtil';
import { toolbarStyle } from '../../../styles/toolbar/ToolbarStyle';
import { UIActionButton } from '../../common/UIActionButton';
import { SearchEngagementWrapper } from '../search/SearchEngagementWrapper';
import { UIDialog } from '../../common/UIDialog';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { isValidGapEngagementDates } from '../../../helpers/EngagementHelper';
import { ENGAGEMENT_PERIOD_DEFAULT_MONTHS } from '../InitEngagement';
import { UIDatePickerField } from '../../common/UIDatePickerField';
import { OutlinedInput } from '@mui/material';

/**
 * Edit engagement component to display fields with engagement's information.
 */
function EngagementInformation(props) {
    const {
        intl,
        engagement,
        handleSubmit,
        disabled,
        restrictedEdition,
        engagementCodeRegEx,
        engagementCodeMessage,
        handleFetchEngagements,
        handleSelectEngagement
    } = props;

    const [openSearch, setOpenSearch] = useState(false);
    const [isValidEngagementPeriod, setValidEngagementPeriod] = useState(true);
    const isCopyEngagementVisible = handleSelectEngagement && handleFetchEngagements;

    const buttonFormRef = useRef(null);
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    props.sbmtEngagementRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const EngagementSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 3 }))
            .required(intl.formatMessage({ id: 'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.NAME' })),
        engagementCode: Yup.string()
            .min(2, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 2 }))
            .matches(
                `^${engagementCodeRegEx}$`,
                intl.formatMessage({ id: 'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE.FORMAT' })
            )
            .required(intl.formatMessage({ id: 'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE' })),
        openingDate: Yup.date().required(
            intl.formatMessage({ id: 'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.OPENING_DATE' })
        ),
        closingDate: Yup.date()
            .required(intl.formatMessage({ id: 'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.CLOSING_DATE' }))
            .when(
                'openingDate',
                (openingDate, closingDate) =>
                    openingDate &&
                    closingDate.min(
                        openingDate,
                        intl.formatMessage({ id: 'EDIT.ENGAGEMENT.VALIDATION.CLOSING.DATE.AFTER.OPENING.DATE' })
                    )
            ),
        description: Yup.string()
    });

    const openingDateChangeCallback = (value, setFieldValue) => {
        setFieldValue('closingDate', dateWithOffset(value, 1, 0, -1));
    };

    const closingDateChangeCallback = (value, setFieldValue) => {
        setFieldValue('closingDate', value);
        setValidEngagementPeriod(isValidGapEngagementDates(engagement.openingDate, value));
    };

    const onCopyEngagementAsked = () => {
        setOpenSearch(true);
    };

    const cancelSearch = () => {
        setOpenSearch(false);
    };
    const onSelectEngagement = selectedEngagement => {
        handleSelectEngagement(selectedEngagement);
        cancelSearch();
    };

    return (
        <>
            {isCopyEngagementVisible && (
                <UIActionButton
                    sx={{ ...toolbarStyle.button, mb: 2 }}
                    title={getLocalizedString(intl, 'CREATE.ENGAGEMENT.COPY.EXISTING')}
                    onClick={onCopyEngagementAsked}
                />
            )}

            {!isValidEngagementPeriod && (
                <Alert severity="error">
                    {FormattedErrorMessage(
                        getLocalizedString(intl, 'CREATE.ENGAGEMENT.PERIOD.TOO.LARGE', {
                            months: ENGAGEMENT_PERIOD_DEFAULT_MONTHS
                        })
                    )}
                </Alert>
            )}

            <Formik
                enableReinitialize={true}
                initialValues={engagement}
                validationSchema={EngagementSchema}
                onSubmit={values => handleSubmit(values)}>
                {_ => (
                    <Form>
                        <Grid container direction='column' justifyContent='space-between' alignItems="stretch">
                            <Grid container direction='row' alignItems='center' spacing={1}>
                                <Grid item xs={3}>
                                    <label>
                                        <FormLabel id="EDIT.ENGAGEMENT.FIELD.NAME.LABEL" isRequired />
                                    </label>
                                    <Grid container>
                                        <Field
                                            as={OutlinedInput}
                                            disabled={restrictedEdition || disabled}
                                            name="name"
                                            style={{ padding: 0, width: '100%' }}
                                        />
                                        <ErrorMessage name="name">
                                            {errorMessage => FormattedErrorMessage(errorMessage)}
                                        </ErrorMessage>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <label>
                                        <FormLabel
                                            id="EDIT.ENGAGEMENT.FIELD.ENGAGEMENT_CODE.LABEL"
                                            isRequired
                                            helperTitleId="EDIT.ENGAGEMENT.FIELD.ENGAGEMENT_CODE.DESCRIPTION.LABEL"
                                            helperCustomMessage={engagementCodeMessage}
                                        />
                                    </label>
                                    <Grid container>
                                        <Field
                                            as={OutlinedInput}
                                            disabled={restrictedEdition || disabled}
                                            name="engagementCode"
                                            style={{ padding: 0, width: '100%' }}
                                        />
                                        <ErrorMessage name="engagementCode">
                                            {errorMessage => FormattedErrorMessage(errorMessage)}
                                        </ErrorMessage>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <label>
                                        <FormLabel
                                            id="EDIT.ENGAGEMENT.FIELD.OPENING_DATE.LABEL"
                                            isRequired
                                            helperTitleId="EDIT.ENGAGEMENT.FIELD.OPENING_DATE.DESCRIPTION.LABEL"
                                            helperCustomMessage=""
                                        />
                                    </label>
                                    <Grid container>
                                        <Field
                                            as={UIDatePickerField}
                                            name='openingDate'
                                            locale='fr-fr'
                                            variant='outlined'
                                            disabled={restrictedEdition || disabled}
                                            disablePast={false}
                                            callback={openingDateChangeCallback}
                                        />
                                        <ErrorMessage name="openingDate">
                                            {errorMessage => FormattedErrorMessage(errorMessage)}
                                        </ErrorMessage>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <label>
                                        <FormLabel id="EDIT.ENGAGEMENT.FIELD.CLOSING_DATE.LABEL" isRequired />
                                    </label>
                                    <Grid container>
                                        <Field
                                            as={UIDatePickerField}
                                            name='closingDate'
                                            locale='fr-fr'
                                            variant='outlined'
                                            disabled={restrictedEdition || disabled}
                                            disablePast={false}
                                            callback={closingDateChangeCallback}
                                        />
                                        <ErrorMessage name="closingDate">
                                            {errorMessage => FormattedErrorMessage(errorMessage)}
                                        </ErrorMessage>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container direction='column' alignItems='stretch'>
                                <label>
                                    <FormattedMessage id="EDIT.ENGAGEMENT.FIELD.DESCRIPTION.LABEL" />
                                </label>
                                <Field
                                    as="textarea"
                                    name="description"
                                    disabled={restrictedEdition || disabled}
                                />
                                <ErrorMessage name="description">
                                    {errorMessage => FormattedErrorMessage(errorMessage)}
                                </ErrorMessage>
                            </Grid>
                        </Grid>

                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            onClick={triggerButtonClick}
                            ref={buttonFormRef}
                        />
                    </Form>
                )}
            </Formik>

            {openSearch && isCopyEngagementVisible && (
                <UIDialog {...props} open={openSearch} titleId="SEARCH.TABLE.DIALOG.TITLE" onClose={cancelSearch}>
                    <SearchEngagementWrapper
                        doInitialSearch
                        loading={false}
                        handleSelectEngagement={onSelectEngagement}
                        handleSearchEngagement={handleFetchEngagements}
                    />
                </UIDialog>
            )}
        </>
    );
}

export const EngagementInformationComponent = injectIntl(EngagementInformation);
