import { HighlightedProcessStepIcon, ProcessConnector } from '../../../helpers/StepperHelper';
import { Stepper, Step, StepLabel } from '@mui/material';

export const CreateConfirmationProgress = ({ activeStepPosition, activeSteps }) => {
    return (
        <Stepper activeStep={activeStepPosition} alternativeLabel connector={<ProcessConnector />}>
            {activeSteps.map(
                (stage, index) =>
                    stage.isActive && (
                        <Step key={index}>
                            <StepLabel StepIconComponent={HighlightedProcessStepIcon}>{stage.title}</StepLabel>
                        </Step>
                    )
            )}
        </Stepper>
    );
};
