import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import './mzrlogin.scss';
import { FormattedErrorMessage } from '../errorMessage/ErrorMessage';
import { getLocalizedString } from '../../i18n/i18Helper';
import { FORM_FIELD } from '../../helpers';
import { useForm } from '../../hooks/useForm';
import { AuthForm } from './AuthForm';
import { initInternLoginInfo } from './initLoginAuth';
import { AuthFooter } from './AuthFooter';
import { Box } from '@mui/material';
import { UIPasswordInput } from '../common/UIPasswordInput';

function MZRLoginComponent(props) {
    const { intl, clusters, loading } = props;

    const countryCodes = useMemo(() => {
        return clusters?.map(cluster => ({ id: cluster.authenticationCode, name: cluster.name }));
    }, [clusters]);

    const [firstCountry] = countryCodes;

    const [loginInfo, setLoginInfo] = useState({ ...initInternLoginInfo, countryCode: firstCountry?.id });
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(getLocalizedString(intl, 'INVALID.FIELD.EMAIL.FORMAT'))
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'AUTH.VALIDATION.REQUIRED_FIELD')),
        password: Yup.string()
            .min(3, getLocalizedString(intl, 'INVALID.FIELD.MIN.SYMBOLS', { min: 3 }))
            .max(50, getLocalizedString(intl, 'INVALID.FIELD.MAX.SYMBOLS', { max: 50 }))
            .required(getLocalizedString(intl, 'AUTH.VALIDATION.REQUIRED_FIELD')),
        countryCode: Yup.string()
            .oneOf(
                countryCodes.map(x => x.id),
                intl.formatMessage({ id: 'AUTH.INVALID.FIELD.COUNTRY' })
            )
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                })
            )
    });

    const fields = [
        {
            id: 'email',
            name: 'email',
            type: 'email',
            fieldType: FORM_FIELD.TEXT_FIELD,
            placeholder: getLocalizedString(intl, 'AUTH.INPUT.EMAIL'),
            component: 'input',
            className: `form-control form-control-solid h-auto py-5 px-6`
        },
        {
            id: 'password',
            name: 'password',
            type: 'password',
            fieldType: FORM_FIELD.TEXT_FIELD,
            placeholder: getLocalizedString(intl, 'AUTH.INPUT.PASSWORD'),
            component: UIPasswordInput,
            className: `form-control form-control-solid h-auto py-5 px-6`
        },
        {
            id: 'countryCode',
            name: 'countryCode',
            fieldType: FORM_FIELD.SELECT_FIELD,
            placeholder: getLocalizedString(intl, 'AUTH.INPUT.PASSWORD'),
            className: `form-control form-control-solid h-auto px-6}`,
            options: countryCodes,
            variant: 'standard',
            disableUnderline: true
        }
    ];

    const handleSubmit = async values => {
        setLoginInfo(values);
        const { handleSignIn } = props;
        const { isSuccess } = await handleSignIn(values.email, values.password, values.countryCode);

        if (!isSuccess) {
            setLoginErrorMessage(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.INVALID_LOGIN'
                })
            );
        }
    };

    const { RenderForm: LoginForm } = useForm({
        initialValues: loginInfo,
        handleSubmit: handleSubmit,
        enableReinitialize: true,
        validationSchema: validationSchema
    });

    return (
        <Box className="flex-row-fluid d-flex flex-column position-relative p-12 pl-16 overflow-hidden mt-30 mt-lg-0">
            <AuthForm
                loading={loading}
                form={LoginForm}
                fields={fields}
                title="AUTH.LOGIN.AS.MAZARS.TITLE"
                subtitle={
                    <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                        <div className="alert-text ">
                            <FormattedMessage id="AUTH.LOGIN.WARNING.MESSAGE" />
                        </div>
                    </div>
                }
                footer={<AuthFooter />}
            />

            {loginErrorMessage && <div className="my-10 text-center">{FormattedErrorMessage(loginErrorMessage)}</div>}
        </Box>
    );
}

export const AuthContent = injectIntl(MZRLoginComponent);
