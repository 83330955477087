import React, { useEffect, useState } from 'react';
import { UserAvatarComponent } from '../../user/UserAvatar';
import { initPaginatedObject } from '../../common/InitPaginatedObject';
import { MZRPaginatedTable } from '../../common/MZRPaginatedTable';
import { FormattedMessage, useIntl } from 'react-intl';

export const SearchEngagementTable = ({
    loading,
    handleSelectEngagement,
    handleFilterChange,
    engagementsPaginated
}: any) => {
    const intl = useIntl();
    const [engagementsLoaded, setEngagementLoaded] = useState(initPaginatedObject);

    useEffect(() => {
        setEngagementLoaded(engagementsPaginated);
    }, [engagementsPaginated]);

    const handleRowClick = (_: any, rowData: any) => {
        handleSelectEngagement(rowData);
    };

    return (
        <div className="engagement-table">
            <MZRPaginatedTable
                isFiltering={false}
                handleFilterChange={handleFilterChange}
                data={engagementsLoaded}
                loading={loading}
                onRowClick={handleRowClick}
                columns={[
                    {
                        title: intl.formatMessage({ id: 'SEARCH.TABLE.TITLE.NAME' }),
                        field: 'name',
                        cellStyle: { textAlign: 'center' }
                    },
                    {
                        title: intl.formatMessage({ id: 'SEARCH.TABLE.TITLE.CODE' }),
                        field: 'engagementCode',
                        cellStyle: { textAlign: 'center' }
                    },
                    {
                        title: <FormattedMessage id="HOME.TABLE.TITLE.NB_COMPANIES" />,
                        field: 'numberOfCompanies',

                        cellStyle: { textAlign: 'center' }
                    },

                    {
                        title: <FormattedMessage id="HOME.TABLE.TITLE.USERS" />,
                        field: 'appUserEngagements',
                        cellStyle: { textAlign: 'center' },
                        render: rowData => <UserAvatarComponent data={rowData.appUserEngagements} max={4} />
                    }
                ]}
            />
        </div>
    );
};
