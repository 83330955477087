import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import { EditContact, initContact } from '.';
import { withContact } from '../../enhancers/withContact';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../partials/controls/Card';
import { ContactContext } from '../../context';
import { UIDialog } from '../common/UIDialog';
import { UIActionButton } from '../common/UIActionButton';
import { getLocalizedString } from '../../i18n/i18Helper';
import { toolbarStyle } from '../../styles/toolbar/ToolbarStyle';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    createButton: {
        marginLeft: theme.spacing(2)
    }
}));

const DefaultContactView = ({
    renderContactsSelect,
    allContacts,
    selectedContacts,
    handleContactsUpdate,
    classes,
    isCreateDisabled,
    disabled,
    handleContactClickCreate,
    strings
}) => {
    const intl = useIntl();

    return (
        <Grid item container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={8}>
                {renderContactsSelect({
                    disabled: disabled,
                    allContacts: allContacts,
                    selectedContacts: selectedContacts,
                    handleContactsUpdate: handleContactsUpdate
                })}
            </Grid>

            <Grid item xs={3} className={classes.createButton}>
                <UIActionButton
                    sx={toolbarStyle.button}
                    title={getLocalizedString(intl, strings.createButton)}
                    disabled={isCreateDisabled || disabled}
                    startIcon={<AddCircleOutline />}
                    display="secondary"
                    onClick={handleContactClickCreate}></UIActionButton>
            </Grid>
        </Grid>
    );
};

function ContactContainerComponent({
    intl,
    companies,
    allContacts,
    selectedContacts,
    handleContactsUpdate,
    contactType,
    strings,
    renderContactsSelect, // see withContact enhancer
    handleContactSubmit,
    renderContacts,
    isCreateDisabled = false,
    hasContactRoles = true, // manage isSignatory, isRecipient roles visibility,
    initialContact = initContact,
    disabled = false,
    isSignatoryOptionDisabled = false,
    view: ContactView = DefaultContactView,
    toolbarView: ToolbarView = null,
    style = makeStyles(_ => ({})),
    ...props
}) {
    const [newContact, setNewContact] = useState(initialContact);
    const [openCreate, setOpenCreate] = useState(false);
    const [dialogTitleLabel, setDialogTitleLabel] = useState(strings.createContactPopupTitle);

    const sbmtContactRef = useRef();
    const classes = { ...useStyles(), ...style };
    const contactContext = useContext(ContactContext);

    const handleContactSubmitCallback = ({ selectedContacts, isSuccess }) => {
        isSuccess && handleContactsUpdate(selectedContacts, true);
        setOpenCreate(!isSuccess);
    };

    const handleContactClickCreate = () => {
        setNewContact(initialContact);
        setDialogTitleLabel(strings.createContactPopupTitle);
        handleContactPopUpOpening();
    };

    const handleEditContact = contact => {
        setNewContact(contact);
        setDialogTitleLabel(strings.editContactPopupTitle);
        handleContactPopUpOpening();
    };

    const handleContactPopUpOpening = () => {
        setOpenCreate(!openCreate);
    };

    const handleContactClickSave = () => {
        if (sbmtContactRef && sbmtContactRef.current) {
            sbmtContactRef.current.saveForm();
        }
    };

    const handleContactDelete = contact => {
        handleContactsUpdate(selectedContacts.filter(x => x.email !== contact.email));
    };

    return (
        <>
            <Card>
                {ToolbarView && (
                    <CardHeader title={<FormattedMessage id={strings.headerTitle} />}>
                        <CardHeaderToolbar>
                            <ToolbarView
                                disabled={disabled}
                                classes={classes}
                                selectedContacts={selectedContacts}
                                allContacts={allContacts}
                                handleContactClickCreate={handleContactClickCreate}
                                isCreateDisabled={isCreateDisabled}
                                handleContactsUpdate={handleContactsUpdate}
                                renderContactsSelect={renderContactsSelect}
                                strings={strings}
                            />
                        </CardHeaderToolbar>
                    </CardHeader>
                )}
                <CardBody>
                    <Grid container className={classes.root} justifyContent="space-between" alignItems="stretch">
                        <>
                            {ContactView && (
                                <ContactView
                                    disabled={disabled}
                                    classes={classes}
                                    selectedContacts={selectedContacts}
                                    allContacts={allContacts}
                                    handleContactClickCreate={handleContactClickCreate}
                                    isCreateDisabled={isCreateDisabled}
                                    handleContactsUpdate={handleContactsUpdate}
                                    renderContactsSelect={renderContactsSelect}
                                    strings={strings}
                                />
                            )}

                            {openCreate && (
                                <UIDialog
                                    {...props}
                                    open={openCreate}
                                    titleId={dialogTitleLabel}
                                    onSave={handleContactClickSave}
                                    onClose={handleContactPopUpOpening}
                                    children={
                                        <EditContact
                                            contactType={contactType}
                                            classes={classes}
                                            variant="outlined"
                                            companyDisabled={!!newContact.id}
                                            companies={companies}
                                            contact={newContact}
                                            handleSubmit={values =>
                                                handleContactSubmit({
                                                    ...contactContext[contactType],
                                                    contact: values,
                                                    contacts: selectedContacts,
                                                    callback: handleContactSubmitCallback
                                                })
                                            }
                                            sbmtContactRef={sbmtContactRef}
                                        />
                                    }
                                />
                            )}
                        </>

                        <Grid item xs={12}>
                            {renderContacts({
                                isSignatoryOptionDisabled: isSignatoryOptionDisabled,
                                contactType: contactType,
                                disabled: disabled,
                                contacts: selectedContacts,
                                isRoleEditable: hasContactRoles,
                                handleEditContact: handleEditContact,
                                handleDeleteContact: handleContactDelete,
                                roleChangeCallback: handleContactsUpdate
                            })}
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>
        </>
    );
}

export const ContactContainer = withContact(ContactContainerComponent);
