import { deepFreeze } from '../../helpers/FreezeHelper';
import { LetterHeadSpec } from '../../types/types';

const InitLetterHead: LetterHeadSpec = {
    id: undefined,
    engagementClientCompanyId: undefined,
    file: undefined,
    isLetterHeadActivated: true
};

export const initLetterHead = deepFreeze(InitLetterHead);
