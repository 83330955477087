/**
 * ServiceResult component to manage response of network request to API.
 *
 * Check body, headers, status and errors of responses.
 */
export class ServiceResult {
    constructor({ body, headers, status, error }) {
        this.body = body;
        this.headers = headers;
        this.status = status;
        this.error = error;
        this.parsedValue = {};

        this.checkErrorsInRequestBody();
    }

    checkErrorsInRequestBody() {
        if (!this.error && this.body && (this.body.error || this.body.errors)) {
            this.error = { message: this.body.error || this.body.errors };
        }
    }

    /**
     * parse the body with the given parser in case of success
     * @param parser: the parsing function
     * @see ../repository/parsers
     */
    tryParsingValue = parser => {
        this.parsedValue = this.isSuccess ? parser(this.value) : {};
    };

    get value() {
        return this.body;
    }

    get isSuccess() {
        return !this.error && this.status >= 200 && this.status < 300;
    }

    get failure() {
        return !this.isSuccess
            ? {
                  error: this.error,
                  message: this.errorMessage,
                  code: this.status
              }
            : {};
    }

    get isFile() {
        return this.body instanceof Blob;
    }

    get isForbiddenAccessError() {
        return this.status === 403;
    }

    get isBadRequestError() {
        return this.status === 400;
    }

    get isUnauthorizedUserError() {
        return this.status === 401;
    }

    get isServerError() {
        return this.status >= 500;
    }

    get isNotFoundError() {
        return this.status === 404;
    }

    get isNetworkError() {
        return this.error && !this.error.isNetworkAvailable;
    }

    get errorMessage() {
        if (this.isUnauthorizedUserError) {
            return 'INVALID.SESSION.UNAUTHORISED.USER.MESSAGE';
        } else if (this.isServerError) {
            return 'UNKNOWN.ERROR.MESSAGE';
        } else if (this.isForbiddenAccessError) {
            return 'INVALID.SESSION.FORBIDDEN.ACCESS.MESSAGE';
        } else if (this.isNotFoundError) {
            return 'INVALID.SESSION.NOT.FOUND.MESSAGE';
        } else {
            return 'UNKNOWN.ERROR.MESSAGE';
        }
    }
}
