export const ConfirmationTypes = {
    ADD_CONFIRMATION: 'addConfirmation',
    SET_CONFIRMATION: 'setConfirmation',
    RESET_CONFIRMATION: 'resetConfirmation'
};

export const addConfirmation = confirmation => {
    return {
        type: ConfirmationTypes.ADD_CONFIRMATION,
        confirmation
    };
};

export const setConfirmation = confirmations => {
    return {
        type: ConfirmationTypes.SET_CONFIRMATION,
        confirmations
    };
};

export const resetConfirmation = () => {
    return {
        type: ConfirmationTypes.RESET_CONFIRMATION
    };
};
