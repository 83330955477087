import { appApiUrl } from '../../config/appConfiguration';
import { Repository } from '../Repository';

/**
 * Class to communicate with API to access to confirmation Step resources.
 */
class ConfirmationStepRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    update = async (stepId, status) => {
        let result = await this.network.put(`confirmationStep/${stepId}`, status, {
            headers: this.buildHeaders()
        });

        return result;
    };
}

export const confirmationStepRepository = new ConfirmationStepRepository();
