import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import { FORM_FIELD } from '../../../helpers';
import { useForm } from '../../../hooks/useForm';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { CLIENT_REFUSE_REQUEST_REASON, RefuseConfirmationRequestSpec } from '../../../types/types';

interface ClientRefuseRequestProps {
    handleSubmit: (request: RefuseConfirmationRequestSpec) => Promise<any>;
    data: any;
    btnActionRef: MutableRefObject<any>;
}

export const ClientRefuseRequestComponent = ({
    handleSubmit,
    data: requestData,
    btnActionRef
}: ClientRefuseRequestProps) => {
    const intl = useIntl();
    const initialValues = { comment: '', reason: undefined, id: requestData?.id };
    const [refuseValues, setRefuseValues] = useState<RefuseConfirmationRequestSpec>(initialValues);
    const btnRef = useRef();

    const refuseReasonOptions: { id: CLIENT_REFUSE_REQUEST_REASON; name: string }[] = useMemo(
        () => [
            {
                id: CLIENT_REFUSE_REQUEST_REASON.DUPLICATE,
                name: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.REFUSE.REQUEST.DUPLICATE')
            },
            {
                id: CLIENT_REFUSE_REQUEST_REASON.INACCURATE,
                name: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.REFUSE.REQUEST.INACCURATE')
            },
            {
                id: CLIENT_REFUSE_REQUEST_REASON.REFUSE,
                name: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.REFUSE.REQUEST.REFUSE')
            }
        ],
        []
    );

    // @ts-ignore
    const triggerButtonClick = () => btnRef?.current?.click();
    btnActionRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const validationSchema = Yup.object().shape({
        reason: Yup.string().required(getLocalizedString(intl, 'CLIENT.REFUSE.REQUEST.REASON.REQUIRED')),
        comment: Yup.string()
            .when('reason', {
                is: reason =>
                    [CLIENT_REFUSE_REQUEST_REASON.REFUSE, CLIENT_REFUSE_REQUEST_REASON.INACCURATE].includes(reason),
                then: Yup.string().required(getLocalizedString(intl, 'CLIENT.REFUSE.REQUEST.COMMENT.REQUIRED'))
            })
            .nullable(true)
            .min(20, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 20 }))
            .max(500, intl.formatMessage({ id: 'INVALID.FIELD.MAX.SYMBOLS' }, { max: 500 }))
    });

    const fields = [
        {
            id: 'reason',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'CLIENT.ENGAGEMENT.REFUSE.REQUEST.REASON' },
            options: refuseReasonOptions,
            variant: 'outlined'
        },
        {
            id: 'comment',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: {
                id: 'CLIENT.ENGAGEMENT.REFUSE.REQUEST.COMMENT',
                isRequired: refuseValues.reason === CLIENT_REFUSE_REQUEST_REASON.REFUSE
            },
            component: 'textarea',
            rows: 6,
            variant: 'outlined'
        }
    ];

    const onSubmit = async (values: RefuseConfirmationRequestSpec) => {
        setRefuseValues(values);
        await handleSubmit(values);
    };

    const { RenderForm: RefuseComponent } = useForm({
        enableReinitialize: true,
        initialValues: refuseValues,
        validationSchema: validationSchema,
        handleSubmit: onSubmit
    });

    return (
        <>
            <RefuseComponent
                formFields={fields}
                conditionalFormKey={undefined}
                conditionalFields={undefined}
                submitRef={btnRef}
                submitTrigger={undefined}
            />
        </>
    );
};
