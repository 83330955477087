import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ClientRequestSignatureCarouselItem } from './ClientRequestSignatureCarouselItem';
import { ClientRequest } from '../../../../types/types';
import { ServiceResult } from '../../../../kit/network/ServiceResult';
import React, { useRef } from 'react';

interface ClientRequestsSignatureCarouselProps {
    requests: ClientRequest[];
    handleRefuse: (request: ClientRequest) => void;
    handleDownload: (request: ClientRequest) => void;
    fetchDocumentFileAPI: (id: string, filters: Record<string, any>) => Promise<ServiceResult>;
}

export const ClientRequestSignatureCarousel = ({
    requests,
    handleRefuse,
    handleDownload,
    fetchDocumentFileAPI
}: ClientRequestsSignatureCarouselProps) => {
    const buttonFormRef = useRef(null);

    //TODO what to do for the last request
    // @ts-ignore
    const handleSignLater = () => buttonFormRef?.current?.click(); //TODO: sign later only trigger next request ?

    function CustomRightArrow({ onClick }: any) {
        function handleClick() {
            onClick();
        }

        return (
            <button
                ref={buttonFormRef}
                onClick={handleClick}
                className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
            />
        );
    }

    return (
        <Carousel
            showDots
            customRightArrow={<CustomRightArrow />}
            keyBoardControl
            autoPlaySpeed={100000}
            autoPlay={false}
            responsive={styles.carousel}>
            {requests.map((request: any, index) => (
                <ClientRequestSignatureCarouselItem
                    id={'pdfViewer' + index}
                    handleSignLater={handleSignLater}
                    fetchDocumentFileAPI={fetchDocumentFileAPI}
                    request={request}
                    key={request.confirmationLetterItemId}
                    handleRefuse={handleRefuse}
                    handleDownload={handleDownload}
                />
            ))}
        </Carousel>
    );
};

const styles = {
    carousel: {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 1,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 1,
            partialVisibilityGutter: 30
        }
    }
};
