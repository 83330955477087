import { Palette } from '../../styles/colors';
import { IconButton } from '@mui/material';
import React from 'react';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { MoreVert } from '@mui/icons-material';

export const UIExpandableIconButton = ({ children, style, ...props }: IconButtonProps) => {
    return (
        <IconButton
            {...props}
            style={{
                ...styles.iconButton,
                ...style
            }}>
            <>
                <MoreVert />
                {children}
            </>
        </IconButton>
    );
};

const styles = {
    iconButton: {
        color: Palette.white,
        backgroundColor: Palette.defaultPrimaryColor,
        width: 38,
        height: 38,
        borderRadius: 0,
        border: '1px solid',
        borderColor: Palette.defaultPrimaryColor
    }
};
