import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PROFILE } from '../../types/types';
import { AuditorEngagement } from './AuditorEngagement';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import React, { useEffect, useState } from 'react';
import { ClientEngagement } from './ClientEngagement';
import { connect } from 'react-redux';
import { confirmationCategoryRepository, engagementRepository, workflowActionRepository } from '../../kit/repository';
import { compose } from 'redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { UIScaffold, UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { getLocalizedString } from '../../i18n/i18Helper';
import { handleApiFailure } from '../../helpers';
import { ServiceResult } from '../../kit/network/ServiceResult';

const engagementReducer = (sessionProfile: number, userProfile: number) => {
    if (isAuthorisedProfile(PROFILE.AUDITOR, userProfile) && sessionProfile === PROFILE.AUDITOR) return AuditorEngagement;
    else if (isAuthorisedProfile(PROFILE.CLIENT, userProfile) && sessionProfile === PROFILE.CLIENT) return ClientEngagement;
    else return null;
};

const EngagementComponent = (props: any) => {
    const [engagement, setEngagement] = useState<any>(undefined);
    const [confirmationCategories, setConfirmationCategories] = useState<any>([]);
    const [workflowActions, setWorkflowActions] = useState<any>([]);
    const intl = useIntl();

    const { user, sessionProfile } = props;
    const routeParams: { engagementId: string } = useParams();
    let scaffold: UIScaffoldChildContext;

    useEffect(() => {
        (!engagement || !confirmationCategories || !workflowActions) && fetchInitialData();
    }, [engagement, confirmationCategories, workflowActions]);

    const onFetchDataFinished = (result: ServiceResult, setter: React.Dispatch<any>, errorTitle: string) => {
        if (result.isSuccess) {
            setter(result.parsedValue);
        } else {
            handleApiFailure(result, getLocalizedString(intl, errorTitle), scaffold);
        }
    };
    const fetchInitialData = async () => {
        scaffold?.setLoading(true);
        const [engagementResult, categoriesResult, workflowActionsResult] = await Promise.all([
            fetchEngagement(),
            await confirmationCategoryRepository.fetchAll(),
            await workflowActionRepository.fetchAll()
        ]);

        scaffold?.setLoading(false);
        onFetchDataFinished(engagementResult, setEngagement, 'FETCH.ENGAGEMENT.USER.ACCESS.ERROR');
        onFetchDataFinished(categoriesResult, setConfirmationCategories, 'FETCH.CONFIRMATION.TYPE.ACCESS.ERROR');
        onFetchDataFinished(
            workflowActionsResult,
            setWorkflowActions,
            'FETCH.CONFIRMATION.WORKFLOW.ACTIONS.ACCESS.ERROR'
        );
    };

    const fetchEngagement = async () => {
        return await engagementRepository.fetch(routeParams.engagementId);
    };
    const Component: any | null = engagementReducer(sessionProfile, user.profil);

    return (
        <UIScaffold>
            {props => {
                scaffold = props;
                return Component ? (
                    <Component
                        {...props}
                        fetchEngagement={fetchEngagement}
                        scaffold={scaffold}
                        engagement={engagement}
                        engagementId={routeParams.engagementId}
                        workflowActions={workflowActions}
                        confirmationCategories={confirmationCategories}
                    />
                ) : (
                    <PageNotFound {...props} />
                );
            }}
        </UIScaffold>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.account.user,
        sessionProfile: state.app.sessionProfile
    };
};

export const Engagement = compose(connect(mapStateToProps, null))(EngagementComponent);
