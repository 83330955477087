import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../../../i18n/i18Helper';
import { Box } from '@mui/material';
import { toolbarStyle } from '../../../../../styles/toolbar/ToolbarStyle';
import { UIActionButton } from '../../../../common/UIActionButton';
import React from 'react';
import { ConfirmationsDocumentsTree } from './ConfirmationsDocumentsTree';
import { UISectionHeader } from '../../../../common/UISectionHeader';

interface ConfirmationDocumentsRecycleBinProps {
    confirmation: any;
    handleBack: () => void;
    paginatedDocuments: Record<string, any>;
    documentTypes: any[];
    itemErrors: string | null;
    user: any;
    handleFetchMenuConfirmations: (filters: Record<string, any>) => Promise<any>;
}

export const ConfirmationDocumentsRecycleBin = ({
    confirmation,
    handleBack,
    paginatedDocuments,
    documentTypes,
    itemErrors,
    user,
    handleFetchMenuConfirmations
}: ConfirmationDocumentsRecycleBinProps) => {
    const intl = useIntl();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <UISectionHeader
                title={getLocalizedString(intl, 'CONFIRMATION.SUBSECTION.RECYCLE_BIN')}
                toolbar={
                    <Box component="span" sx={toolbarStyle.container}>
                        <UIActionButton
                            display="secondary"
                            sx={toolbarStyle.button}
                            title={getLocalizedString(intl, 'CONFIRMATION.DOCUMENTS.RECYCLE_BIN.RETURN')}
                            onClick={handleBack}
                        />
                    </Box>
                }
            />

            <ConfirmationsDocumentsTree
                handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                user={user}
                paginatedDocuments={paginatedDocuments}
                documentTypes={documentTypes}
                itemErrors={itemErrors}
                confirmation={confirmation}
            />
        </Box>
    );
};
