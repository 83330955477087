export { configurationRepository } from './ConfigurationRepository';
export { emailTemplateRepository } from './EmailTemplateRepository';
export { emailTypeRepository } from './EmailTypeRepository';
export { languageRepository } from './LanguageRepository';
export { lostItemConfigurationRepository } from './LostItemConfigurationRepository';
export { mailingAuthProviderRepository } from './MailingAuthProviderRepository'
export { mailingConfigRepository } from './MailingConfigRepository';
export { metricsRepository } from './MetricsRepository';
export { oAuth2Repository } from './OAuth2Repository';
export { robotConfigRepository } from './RobotRepository';
export { storageConfigurationRepository } from './StorageConfigurationRepository';
export { utilitiesRepository } from './UtilitiesRepository';