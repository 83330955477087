import { CreationProcessStep } from '../components/confirmation';

export const withCreateConfirmation = WrappedComponent =>
    function withConfirmationContainer(props) {
        const onLetterTemplateChanged = (values, steps, callback) => {
            // Trick: to clone the workflowActions (immutability), otherwise confirmation.letterTemplate is updated, spread works only on one level
            const workflowActions = values.letterTemplate?.workflow.workflowActions.map(action => {
                if (action.isStackable) action.isActive = false;
                return action;
            });

            const hasStackableWorkflowActions = values.letterTemplate?.workflow.isStacked;

            const newSteps = steps.map(step => {
                if (step.key === CreationProcessStep.PARAMETERS && !step.isActive) {
                    step.isActive = hasStackableWorkflowActions;
                }

                return step;
            });

            callback(newSteps, hasStackableWorkflowActions, {
                ...values,
                workflowActions: workflowActions,
                activeStackedWorkflowActions: []
            });
        };

        const onStackableStepChanged = (selectedStackedAction, stackedSteps, workflowActions, callback) => {
            const stackedStepsIDs = stackedSteps.map(s => s.id);
            const updatedActions = workflowActions.map(action => {
                if (stackedStepsIDs.includes(action.id)) {
                    action.isActive = action.id === selectedStackedAction.id;
                }

                return action;
            });

            callback([...updatedActions]);
        };

        return (
            <WrappedComponent
                {...props}
                onLetterTemplateChanged={onLetterTemplateChanged}
                onStackableStepChanged={onStackableStepChanged}
            />
        );
    };
