import { Box, Button, ButtonProps } from '@mui/material';
import { Palette } from '../../styles/colors';
import { UIText } from './UIText';

export type UIButtonDisplay = 'primary' | 'secondary' | 'tertiary';

export interface UIActionButtonProps {
    display?: UIButtonDisplay;
    loading?: boolean;
    rounded?: boolean;
    title?: string | JSX.Element;
    uppercase?: boolean;
    sx?: any;
}

export const UIActionButton = ({
    title,
    children,
    style,
    display = 'primary',
    loading = false,
    rounded = true,
    uppercase = true,
    disabled = false,
    sx = {},
    ...rest
}: UIActionButtonProps & ButtonProps) => {
    return (
        <>
            <Button
                {...rest}
                disabled={disabled}
                variant={display === 'tertiary' ? 'contained' : 'outlined'}
                sx={styles.button(display, rounded, sx, style)}
                startIcon={
                    loading ? (
                        <span className="mr-6 spinner spinner-white" />
                    ) : (
                        rest.startIcon && <Box sx={{ pl: 2 }}> {rest.startIcon}</Box>
                    )
                }
                endIcon={rest.endIcon && <Box sx={{ pr: 2 }}> {rest.endIcon}</Box>}>
                <UIText
                    text={uppercase ? title?.toLocaleUpperCase() : title}
                    sx={{ ...styles.text, fontSize: style?.fontSize ?? sx?.fontSize ?? 14 }}
                />
                {children}
            </Button>
        </>
    );
};

const styles = {
    text: {
        color: 'inherit',
        fontSize: 14,
        p: 1
    },

    button: (display: UIButtonDisplay, rounded: boolean, sx: any, style: any) => {
        const styleByDisplay = buttonStyleReducer(display);
        return {
            '&.MuiButton-root': {
                minWidth: '24%',
                minHeight: '3em',
                borderBottomLeftRadius: rounded ? 20 : 0,
                textTransform: 'none' as any,
                ...styleByDisplay,
                ...style,
                ...sx
            },
            '&.MuiButton-contained': {
                textDecoration: 'underline!important'
            },
            '&.MuiButton-root:hover': {
                backgroundColor: Palette.darkPrimaryColor,
                color: 'white',
                borderColor: Palette.darkPrimaryColor
            },
            '&.MuiButton-root:disabled': {
                backgroundColor: Palette.whiteSmoke,
                color: Palette.sonicSilver,
                borderColor: Palette.whiteSmoke
            }
        };
    }
};

const buttonStyleReducer = (
    display: UIButtonDisplay
): {
    backgroundColor: string;
    color: string;
    borderColor?: string;
    border?: number;
    borderWidth?: number;
    boxShadow?: number;
} => {
    switch (display) {
        case 'primary':
            return {
                border: 1,
                borderWidth: 1,
                backgroundColor: Palette.defaultPrimaryColor,
                color: 'white',
                borderColor: Palette.defaultPrimaryColor
            };
        case 'secondary':
            return {
                backgroundColor: 'transparent',
                color: Palette.primaryTextColor,
                border: 1,
                borderWidth: 1,
                borderColor: Palette.primaryTextColor
            };
        case 'tertiary':
            return {
                boxShadow: 0,
                backgroundColor: 'transparent',
                color: Palette.defaultPrimaryColor
            };
        default:
            return {
                border: 1,
                borderWidth: 1,
                backgroundColor: Palette.defaultPrimaryColor,
                color: 'white',
                borderColor: Palette.defaultPrimaryColor
            };
    }
};
