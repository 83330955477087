import { useContext, useEffect, useState } from 'react';
import { TablePagination } from '@mui/material';
import { Folder, FolderOpen } from '@mui/icons-material';
import { FileExtension, INIT_ITEMS_FILTER } from '../../../../../kit/utils/constants';
import { initItem } from '../../../../document/InitItem';
import { handleRowsPerPage, ROWS_PER_PAGE_OPTIONS } from '../../../../../helpers';
import { initPaginatedObject } from '../../../../common/InitPaginatedObject';
import { useDeleteItem } from '../../../../../hooks/useEntityActionPopup';
import { ItemContext } from '../../../../../context';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { ConfirmationDocumentTreeItem } from './ConfirmationDocumentTreeItem';
import { DocumentPreview } from '../../../../document/DocumentPreview';

const authorizedVisualisation = [FileExtension.PDF, FileExtension.DOCX];
const authorizedDownload = [
    FileExtension.PDF,
    FileExtension.EML,
    FileExtension.P7M,
    FileExtension.JPEG,
    FileExtension.JPG,
    FileExtension.PNG,
    FileExtension.XLS,
    FileExtension.XLSX
];

interface ConfirmationsDocumentsTreeProps {
    user: any;
    paginatedDocuments: Record<string, any>;
    documentTypes: any[];
    itemErrors: any | null;
    confirmation: any;
    handleFetchMenuConfirmations: (filters: Record<string, any>) => Promise<any>;
}

export function ConfirmationsDocumentsTree({
    user,
    paginatedDocuments,
    documentTypes,
    itemErrors,
    confirmation,
    handleFetchMenuConfirmations
}: ConfirmationsDocumentsTreeProps) {
    const [currentPaginatedDocuments, setCurrentPaginatedDocuments] = useState(initPaginatedObject);
    const [selectedDocument, setSelectedDocument] = useState(initItem);
    const [currentDocument, setCurrentDocument] = useState(null);

    const { fetchItemFile, updateItem, deleteItem, downloadItem, filterItems } = useContext(ItemContext);
    const [triggerPreviewDialog, setTriggerPreviewDialog] = useState<number>(0);

    const handleUpdateDocument = values => {
        return updateItem({
            ...values,
            confirmationId: confirmation.id,
            engagementId: confirmation.engagementId
        });
    };

    const { delete: DeleteDocumentComponent, handleDeleteClick } = useDeleteItem({
        user: user,
        deleteAPI: deleteItem,
        deleteItemCallback: () => {}
    });

    useEffect(() => {
        setCurrentPaginatedDocuments(paginatedDocuments);
    }, [paginatedDocuments]);

    const handleChangePage = pageIndex => {
        filterItems({ pageIndex: pageIndex });
    };

    const handleChangeRowPerPage = event => {
        filterItems({ pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX, pageSize: event.target.value });
    };

    const onClickDeleteDocument = document => {
        setSelectedDocument(document);
        handleDeleteClick();
    };

    const handlePreview = async document => {
        if (authorizedVisualisation.includes(document?.fileExtension.toLowerCase())) {
            documentPreviewClick(document);
        } else if (authorizedDownload.includes(document?.fileExtension.toLowerCase())) {
            await downloadItem(document, `${confirmation.name} - ${document?.name}`);
        } else {
            //do nothing
        }
    };

    const handleDownload = async (event, file) => {
        event.preventDefault();
        event.stopPropagation();
        await downloadItem(file, `${confirmation.name} - ${file?.name}`);
    };

    const handleTrash = async (event, nodes) => {
        event.preventDefault();
        event.stopPropagation();
        if (nodes?.isTrashed) {
            onClickDeleteDocument(nodes);
        } else {
            await updateItem({ ...nodes, isTrashed: true });
        }
    };

    const handleRestore = async (event, nodes) => {
        event.preventDefault();
        event.stopPropagation();
        await updateItem({ ...nodes, isTrashed: false });
    };

    const handleDelete = (event, nodes) => {
        event.preventDefault();
        event.stopPropagation();
        if (nodes?.isTrashed) {
            onClickDeleteDocument(nodes);
        }
    };

    const handleChange = async updatedNode => {
        await updateItem({
            ...updatedNode,
            confirmationId: confirmation.id,
            engagementId: confirmation.engagementId
        });
    };

    const documentPreviewClick = (item: any) => {
        setCurrentDocument({ ...item, fileName: `${confirmation.name} - ${item?.name}` });
        setTriggerPreviewDialog(trigger => trigger + 1);
    };

    const updateOpenPreviewDocument = value => {
        setTriggerPreviewDialog(value);
        value === 0 && setCurrentDocument(null);
    };

    return (
        <>
            <TreeView
                aria-label="confirmations-documents-section"
                defaultCollapseIcon={<FolderOpen />}
                defaultExpandIcon={<Folder />}
                sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {currentPaginatedDocuments?.items
                    ? Object.values(currentPaginatedDocuments?.items).map(documentNode => {
                          return (
                              <ConfirmationDocumentTreeItem
                                  handlePreview={handlePreview}
                                  itemErrors={itemErrors}
                                  documentTypes={documentTypes}
                                  node={documentNode as any}
                                  authorizedDownload={authorizedDownload}
                                  handleDownload={handleDownload}
                                  handleDelete={handleDelete}
                                  handleChange={handleChange}
                                  handleRestore={handleRestore}
                                  handleTrash={handleTrash}
                              />
                          );
                      })
                    : null}
            </TreeView>
            <TablePagination
                component="div"
                labelRowsPerPage=""
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                rowsPerPage={handleRowsPerPage(currentPaginatedDocuments?.pageSize)}
                count={currentPaginatedDocuments?.totalItems ?? 0}
                page={currentPaginatedDocuments?.pageIndex ?? INIT_ITEMS_FILTER.PAGE_INDEX}
                onPageChange={(_, page) => handleChangePage(page)}
                onRowsPerPageChange={event => handleChangeRowPerPage(event)}
            />
            {currentDocument && triggerPreviewDialog && (
                <DocumentPreview
                    fetchDocumentFileAPI={fetchItemFile}
                    handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                    item={currentDocument}
                    documentTypes={documentTypes}
                    updateDocumentAPI={handleUpdateDocument}
                    updateOpenPreview={updateOpenPreviewDocument}
                    shouldOpenPreview={triggerPreviewDialog}
                />
            )}
            <DeleteDocumentComponent itemId={selectedDocument?.id} />
        </>
    );
}
