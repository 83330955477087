import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { NewlineText } from './NewlineText';
import { AddCircleOutline } from '@mui/icons-material';
import { UITooltip } from '../common/UITooltip';
import { Palette } from '../../styles/colors';

const style = {
    blockquote: {
        fontStyle: 'italic',
        fontSize: '.75rem',
        margin: '1rem 0'
    },
    control: styles => ({
        ...styles,
        fontSize: '.85rem',
        width: '100%',
        borderColor: Palette.dividerColor,
        backgroundColor: 'transparent',
        borderBottomLeftRadius: 20,
        color: Palette.primaryTextColor,
        '&:hover': {
            borderColor: Palette.sonicSilver
        },
        '&:focus-within': {
            borderColor: Palette.sonicSilver
        }
    }),
    option: styles => ({ ...styles, fontSize: '.85rem' }),
    menu: styles => ({
        ...styles,
        outerHeight: '100%',
        width: 'max-content',
        minWidth: '100%'
    }),
    menuPortal: styles => ({ ...styles, zIndex: 3000 })
};

const Control = ({ children, ...props }) => {
    const { onCreate, isCreateVisible, createLabel } = props.selectProps;
    const style = { cursor: 'pointer', marginRight: '.75em' };
    return (
        <components.Control {...props}>
            {children}
            {isCreateVisible && (
                <UITooltip title={createLabel}>
                    <AddCircleOutline onMouseDown={onCreate} color={'inherit'} style={style} />
                </UITooltip>
            )}
        </components.Control>
    );
};

export function MZRSearchBar({
    id = 'select',
    name = 'select',
    value,
    options,
    onSelectionChange,
    onInputChange,
    onClear,
    getOptionLabel,
    onCustomFocus = undefined,
    getOptionValue,
    placeholder,
    createLabel = '',
    handleCreate = null,
    isAreaFocusVisible = true,
    disabled = false,
    isClearable,
    components = {},
    customStyle = {}
}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);
    const [ariaFocusMessage, setAriaFocusMessage] = useState('');

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const onFocus = focused => {
        let message;
        if (isAreaFocusVisible) {
            message = onCustomFocus(focused);
            setAriaFocusMessage(message);
        }

        return message;
    };

    const onChange = (selectedOption, triggeredAction) => {
        switch (triggeredAction.action) {
            case 'select-option':
                selectedOption && onSelectionChange(selectedOption);
                break;

            case 'clear':
                onClear && onClear();

                break;
            default:
                break;
        }
    };

    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    const onCreate = e => {
        handleCreate();
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <>
            {!!isAreaFocusVisible && !!ariaFocusMessage && !!isMenuOpen && (
                <blockquote style={style.blockquote}>
                    <NewlineText text={ariaFocusMessage} />
                </blockquote>
            )}

            <Select
                onInputChange={onInputChange}
                isDisabled={disabled}
                createLabel={createLabel}
                isCreateVisible={isMenuOpen && handleCreate}
                onCreate={onCreate}
                components={{ Control, ...components }}
                noOptionsMessage={() => {
                    setAriaFocusMessage('');
                }}
                placeholder={placeholder}
                value={selectedValue}
                inputId="select-input"
                id={id}
                name={name}
                options={options}
                isClearable={isClearable}
                isSearchable
                onChange={onChange}
                aria-labelledby="aria-label"
                ariaLiveMessages={{ onFocus }}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                styles={{ ...style, ...(customStyle || {}) }}
                menuPlacement="auto"
                menuPortalTarget={document.body}
            />
        </>
    );
}
