import { deepFreeze } from '../../helpers/FreezeHelper';
import { EMPTY_GUID } from '../../kit/utils/constants';

export const UNDEFINED_ITEM_ID = EMPTY_GUID;

export const ITEM_DOCUMENT_TYPE = {
    UNKNOWN: 0,
    LETTER_TEMPLATE: 1,
    CLIENT_REQUEST: 2,
    CLIENT_ANSWER: 3,
    THIRD_PARTY_REQUEST: 4,
    THIRD_PARTY_ANSWER: 5,
    EMAIL: 6,
    OTHER: 7
};

export const ITEM_SOURCE = {
    AUDITOR: 0,
    ROBOT: 1,
    SUPPORT: 2
};

export const ITEM_ERROR_TYPE = {
    NONE: 0,
    FORGED_DOCUMENT: 1,
    BAD_QUALITY: 2,
    CORRUPTED_FILE: 3,
    REFUSED_BY_CLIENT: 4,
    REFUSED_BY_THIRDPARTY: 5,
    REFUSED_BY_AUDITOR: 6,
    CANCELED: 7,
    OTHER: 8
};

export const PAPER_PROCESS_STATUS = {
    NONE: 0,
    PENDING: 1,
    IN_PROGRESS: 2,
    DONE: 3
};

const InitItem = {
    id: null,
    parentItemId: null,
    name: '',
    description: '',
    clusterId: null,
    languageId: '80496848-425d-48f8-a53a-d7399415cacd', // TODO: Review languageId - to delete
    type: 0,
    documentType: 0,
    fileHash: null,
    errorType: 0,
    comment: '',
    emitter: 0,
    source: 0,
    processedByRobot: false,
    processedBySupport: false,
    workflowTrack: 0,
    engagementId: null,
    confirmationId: null,
    childItems: [],
    file: '',
    fileExtension: '',
    isChildItem: false
};

export const initItem = deepFreeze(InitItem);

export const ITEM_FILTERS = {
    SUPPORT_FILTER: 'globalFilter'
};
