import { deepFreeze } from '../../../helpers/FreezeHelper';

const InitConfirmation = {
    name: '',
    description: '',
    periodStartDate: new Date(),
    periodEndDate: new Date(),
    languageId: '',
    clientSendDate: new Date(),
    clientDueDate: new Date(),
    automaticClientSending: false,
    thirdPartySendDate: new Date(),
    thirdPartyDueDate: new Date(),
    automaticThirdPartySending: false,
    confirmationCategoryId: '',
    letterTemplateId: '',
    letterTemplate: {},
    thirdPartyCompanyId: null,
    thirdPartyCompany: null,
    engagementId: null,
    clientCompanyId: null,
    clientCompany: {},
    clusterId: null,
    currentMovementId: '',
    nextStep: {},
    optionalFields: [],
    activeStackedWorkflowActions: {}, // ex {1: "XXXXXX"} 1 stand for position 1 in the workflow
    workflowActions: [],
    clientContacts: [],
    thirdPartyContacts: []
};

export const initConfirmation = deepFreeze(InitConfirmation);

export const CreationProcessStep = {
    TEMPLATE: 'template',
    CLIENT: 'client',
    THIRDPARTY: 'thirdParty',
    PARAMETERS: 'parameters',
};

export const CONFIRMATION_COLUMNS_FILTERS = {
    CONFIRMATION_CODE: 'confirmationCode',
    CONFIRMATION_NAME_OR_CODE: 'confirmationNameOrCode',
    ENGAGEMENT_ID: 'engagementId',
    CLUSTER_ID: 'clusterId',
    USER_PROFILE: 'userProfile'
};

export const INIT_MENU_CONFIRMATION_FILTERS = {
    [CONFIRMATION_COLUMNS_FILTERS.CONFIRMATION_NAME_OR_CODE]: null,
    [CONFIRMATION_COLUMNS_FILTERS.ENGAGEMENT_ID]: null
};
