import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    ExpandMore as ExpandMoreIcon,
    AddCircleOutline,
    EditRounded as EditRoundedIcon,
    DeleteForeverRounded as DeleteForeverRoundedIcon
} from '@mui/icons-material';
import { Box, Button } from '@material-ui/core';
import { initLetterTemplate, LetterTemplateForm } from '.';
import { UIDialog } from '../../common/UIDialog';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(1)
    }
}));

export function LetterTemplateConfigComponent(props) {
    const {
        letterTemplates,
        letterTemplateTags,
        confirmationCategories,
        workflows,
        languages,
        loading,
        configurationsChecks,
        letterTemplateSubmit,
        deleteLetterTemplate
    } = props;
    const classes = useStyles();

    const [isConfigValid, setIsConfigValid] = useState(false);

    useEffect(() => {
        setIsConfigValid(configurationsChecks?.isExistingLetterTemplate ?? false);
    }, [configurationsChecks]);

    const [templates, setLetterTemplates] = useState([]);

    useEffect(() => {
        setLetterTemplates(letterTemplates);
    }, [letterTemplates]);

    const sbmtLetterTemplRef = useRef();

    const [newLetterTemplate, setNewTemplate] = useState(initLetterTemplate);
    const [openTemplate, setOpenTemplate] = useState(false);

    const handleTemplateCreate = () => {
        setNewTemplate(initLetterTemplate);
        setOpenTemplate(!openTemplate);
    };

    const handleTemplateUpdate = template => {
        setNewTemplate(template);
        setOpenTemplate(!openTemplate);
    };

    const handleTemplateDelete = template => {
        deleteLetterTemplate(template?.id);
    };

    const handleClickSave = () => {
        if (sbmtLetterTemplRef && sbmtLetterTemplRef.current) {
            sbmtLetterTemplRef.current.saveForm();
        }
    };

    const handleSubmit = async values => {
        const { isSuccess } = await letterTemplateSubmit(values);
        if (isSuccess) {
            setOpenTemplate(!openTemplate);
        }
    };

    return (
        <>
            <Box component="span" m={1} className={clsx(classes.box, classes.boxButton)}>
                <Button
                    startIcon={<AddCircleOutline />}
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={handleTemplateCreate}>
                    <FormattedMessage id={'LETTER_TEMPLATE.INFO.BUTTON.CREATE.LABEL'} />
                </Button>
            </Box>

            <div id={`confirmation-template-config-panel`} className={classes?.root}>
                {templates
                    ? React.Children.toArray(
                          templates.map(template => {
                              return (
                                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                                      <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-label="Expand"
                                          aria-controls={`confirmation-template-${template?.id}-summary`}>
                                          <div style={styles.AccordionHeaderStyle}>
                                              <FormControlLabel
                                                  aria-label={`confirmation-template-form-control-${template?.id}-label`}
                                                  onClick={event => event.stopPropagation()}
                                                  onFocus={event => event.stopPropagation()}
                                                  control={
                                                      <Checkbox checked={isConfigValid ?? false} color="primary" />
                                                  }
                                                  label={template.name.toUpperCase()}
                                              />
                                              <FormControlLabel
                                                  aria-label="Acknowledge"
                                                  onClick={event => event.stopPropagation()}
                                                  onFocus={event => event.stopPropagation()}
                                                  control={
                                                      <Box
                                                          component="span"
                                                          m={1}
                                                          className={clsx(classes.box, classes.boxButton)}>
                                                          <Button
                                                              startIcon={<EditRoundedIcon />}
                                                              variant="outlined"
                                                              size="small"
                                                              color="primary"
                                                              className={classes.button}
                                                              onClick={() => handleTemplateUpdate(template)}>
                                                              <FormattedMessage
                                                                  id={'LETTER_TEMPLATE.INFO.BUTTON.EDIT.LABEL'}
                                                              />
                                                          </Button>
                                                          <Button
                                                              startIcon={<DeleteForeverRoundedIcon />}
                                                              variant="outlined"
                                                              size="small"
                                                              color="primary"
                                                              className={classes.button}
                                                              onClick={() => handleTemplateDelete(template)}>
                                                              <FormattedMessage
                                                                  id={'LETTER_TEMPLATE.INFO.BUTTON.DELETE.LABEL'}
                                                              />
                                                          </Button>
                                                      </Box>
                                                  }
                                              />
                                          </div>
                                      </AccordionSummary>

                                      <AccordionDetails
                                          aria-label={`confirmation-template-details-${template?.id}-content`}
                                          children={
                                              <>
                                                  <LetterTemplateForm
                                                      letterTemplate={template}
                                                      letterTemplateTags={letterTemplateTags}
                                                      confirmationCategories={confirmationCategories}
                                                      workflows={workflows}
                                                      languages={languages}
                                                      isPreview={true}
                                                      isEdition={!!template?.id}
                                                      handleSubmit={handleSubmit}
                                                      sbmtTemplRef={sbmtLetterTemplRef}
                                                  />
                                              </>
                                          }
                                      />
                                  </Accordion>
                              );
                          })
                      )
                    : null}
            </div>

            {openTemplate && (
                <UIDialog
                    {...props}
                    open={openTemplate}
                    loading={loading}
                    titleId="LETTER_TEMPLATE.INFO.BUTTON.CREATE.LABEL"
                    onSave={handleClickSave}
                    onClose={() => setOpenTemplate(!openTemplate)}>
                    <LetterTemplateForm
                        {...props}
                        letterTemplate={newLetterTemplate}
                        letterTemplateTags={letterTemplateTags}
                        confirmationCategories={confirmationCategories}
                        workflows={workflows}
                        languages={languages}
                        isPreview={false}
                        isEdition={!!newLetterTemplate?.id}
                        handleSubmit={handleSubmit}
                        sbmtTemplRef={sbmtLetterTemplRef}
                    />
                </UIDialog>
            )}
        </>
    );
}

const styles = {
    AccordionHeaderStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    }
};

export default injectIntl(LetterTemplateConfigComponent);
