import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Alert } from '@mui/material';
import { useXLSXFile } from '../../../hooks/useXLSXFile';
import {
    FILE_DISPLAY_ROWS_NUMBER,
    FILE_HEADER_INDEX,
    InitUploadConfirmations,
    LETTER_TEMPLATE_ID_CELL
} from './InitImportedConfirmations';
import { FORM_FIELD } from '../../../helpers';
import { UPLOAD_TYPES } from '../../../helpers/ItemHelper';
import { uncapitalize } from '../../../helpers/BaseHelper';
import { sort } from '../../../helpers/TagsHelper';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { LetterTemplateContext } from '../../setup';
import { useForm } from '../../../hooks/useForm';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { UIActionButton } from '../../common/UIActionButton';
import { ImportConfirmationsPreview } from './ImportConfirmationsPreview';

export function ImportConfirmationsFormComponent(props) {
    const { loading, uploadConfirmationFile, intl } = props;
    const [confirmationsInfo, setConfirmationsInfo] = useState(InitUploadConfirmations);
    const [optionalTagsNames, setOptionalTagsNames] = useState([]);
    const [shouldShowPreview, setShouldShowPreview] = useState(false);
    const [isValidFileInfo, setIsValidFileInfo] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { fetchTemplate: fetchLetterTemplate } = useContext(LetterTemplateContext);

    const {
        sheetNamesList,
        columnsNames,
        onFileUploaded,
        onSheetSelected,
        uploadedRows,
        headers,
        groupColumnsBySection,
        retrieveCellContent,
        configurationSheet
    } = useXLSXFile();

    useEffect(() => {
        const getOptionalTagsIndexes = () => {
            let confirmationIndexes = { ...confirmationsInfo };

            Object.keys(optionalTagsNames)?.forEach(key => {
                const tagValue = optionalTagsNames[key];
                const [tagColumn] = columnsNames.filter(column => column.name === tagValue.id);

                if (tagColumn) {
                    confirmationIndexes = { ...confirmationIndexes, [key]: tagColumn.id };
                }
            });

            setConfirmationsInfo(confirmationIndexes);
        };

        getOptionalTagsIndexes();
    }, [optionalTagsNames, columnsNames]);

    useEffect(() => {
        setShouldShowPreview(isValidFileInfo && columnsNames?.length > 0);
    }, [isValidFileInfo, columnsNames]);

    const importBtnFormRef = useRef(null);
    const triggerButtonClick = () => importBtnFormRef?.current?.click();
    props.sbmtChckRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const getOptionalTagsNames = async () => {
        let letterTemplateOptionalTags = [];
        const format = tags =>
            sort(tags)?.reduce(
                (acc, tag) => ({ ...acc, [uncapitalize(tag.text) + 'ColumnIndex']: { id: tag.name } }),
                {}
            );

        const letterTemplateId = retrieveCellContent(
            configurationSheet,
            LETTER_TEMPLATE_ID_CELL.LINE_INDEX,
            LETTER_TEMPLATE_ID_CELL.COLUMN_INDEX
        );

        if (letterTemplateId) {
            const { isSuccess, parsedValue: letterTemplate } = await fetchLetterTemplate(letterTemplateId);
            setIsValidFileInfo(isSuccess);

            if (isSuccess) {
                letterTemplateOptionalTags = letterTemplate?.tags
                    ? letterTemplate.tags.filter(tag => tag.isInput && !tag.isMandatory)
                    : [];
            } else {
                setErrorMessage(intl.formatMessage({ id: 'IMPORT.CONFIRMATION.LETTER_TEMPLATE.NOT.EXISTS' }));
            }
        }

        setOptionalTagsNames(format(letterTemplateOptionalTags));
    };

    const handleSheetSelect = async event => {
        await getOptionalTagsNames();
        onSheetSelected(event.target.value.name, FILE_HEADER_INDEX, FILE_DISPLAY_ROWS_NUMBER);
    };

    const handleSave = () => {
        if (props.sbmtChckRef && props.sbmtChckRef.current) {
            props.sbmtChckRef.current.saveForm();
        }
    };

    const handleSubmit = async values => {
        await uploadConfirmationFile({
            ...values,
            file: values.file[0],
            worksheetName: values.worksheetName?.name
        });
    };

    const fields = [
        {
            id: 'file',
            name: 'file',
            fieldType: FORM_FIELD.INPUT_FIELD,
            acceptedFiles: UPLOAD_TYPES.EXCEL,
            showPreviews: true,
            filesLimit: 1,
            callback: onFileUploaded,
            dropzoneText: getLocalizedString(intl, 'IMPORT.CONFIRMATION.EXCEL.FILE.LABEL')
        },
        {
            labelItem: { id: 'CLIENT.CONFIG.IMPORT.FORM.WORKSHEETNAME.LABEL' },
            id: 'worksheetName',
            name: 'worksheetName',
            optionsObjectChoices: true,
            fieldType: FORM_FIELD.SELECT_FIELD,
            callback: handleSheetSelect,
            options: sheetNamesList.length > 0 ? sheetNamesList : [{ id: 0, name: 'None' }]
        },
        {
            id: 'preview',
            name: 'preview',
            fieldType: FORM_FIELD.COMPONENT_FIELD,
            component: () =>
                shouldShowPreview
                    ? ImportConfirmationsPreview(
                          groupColumnsBySection,
                          columnsNames,
                          headers,
                          optionalTagsNames,
                          uploadedRows
                      )
                    : null
        }
    ];

    const form = useForm({
        initialValues: confirmationsInfo,
        handleSubmit: handleSubmit,
        enableReinitialize: true
    });

    // need to use memo here otherwise, the formik values will be reinit after executing handleSheetSelect
    const { RenderForm: ImportForm } = useMemo(() => form, []);

    return (
        <>
            <div className="add-confirmation mt-5">
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    style={{ marginTop: '20px', paddingLeft: '100px', paddingRight: '100px' }}>
                    <Grid item>
                        {errorMessage && (
                            <Grid container item xs={12} direction="column">
                                {
                                    <Alert severity="error" onClose={() => setErrorMessage('')}>
                                        {FormattedErrorMessage(errorMessage)}
                                    </Alert>
                                }
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <ImportForm
                            key="confirmations_import_fields"
                            formFields={fields}
                            conditionalFields={undefined}
                            conditionalFormKey={undefined}
                            submitRef={importBtnFormRef}
                            submitTrigger={triggerButtonClick}
                        />
                    </Grid>

                    <Grid item container justifyContent="flex-end">
                        <UIActionButton
                            startIcon={loading && <span className="mr-6 spinner spinner-white" />}
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            disabled={!shouldShowPreview || loading || uploadedRows?.length === 0}>
                            <FormattedMessage id="IMPORT.CONFIRMATION.UPLOAD.SUBMIT.LABEL" />
                        </UIActionButton>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

injectIntl(ImportConfirmationsFormComponent);
