export const auth = {
    'AUTH.WELCOME.LEGAL': '2024 Forvis Mazars',
    'AUTH.WELCOME.MESSAGE': "Bienvenue sur la platforme de gestion des circularisatons d'audit de Forvis Mazars",
    'AUTH.WELCOME.MESSAGE.TEXT':
        'Présents dans plus de 100 pays et territoires, nous travaillons ensemble comme une seule équipe au-delà des frontières et des fonctions pour fournir des services exceptionnels et sur mesure. Nous combinons nos compétences et notre expertise avec une perspective mondiale et des connaissances locales pour fournir à nos clients un large éventail de services professionnels en matière d\'audit et de comptabilité, ainsi que des services fiscaux, de conseil financier, de consultation et juridiques*\n\n\n\n*lorsque cela est autorisé par les lois du pays concerné',
    'AUTH.INVALID.FIELD.COUNTRY': 'pays invalide',
    'AUTH.OPTIONS.CONTINUE.AS.MAZARS.BUTTON': 'Continuer en tant que employé Mazars',
    'AUTH.OPTIONS.CONTINUE.AS.EXTERNAL.BUTTON': 'Continuer en tant que utilisateur externe',
    'AUTH.LOGIN.AS.MAZARS.TITLE': 'Se connecter en tant que <b>Employé Mazars</b>',
    'AUTH.LOGIN.AS.EXTERNAL.TITLE': 'Se connecter en tant que <b>Utilisateur externe </b>',
    'AUTH.CHANGE.LOGIN.METHOD': '- Changer de méthode de connexion  -',

    'AUTH.LOGIN.TITLE': 'Connexion Forvis Mazars Circularisations',
    'AUTH.LOGIN.MESSAGE': 'Entrer votre email et mot de passe',
    'AUTH.LOGIN.WARNING.MESSAGE': 'Utilisez votre compte Forvis Mazars et mot de passe pour continuer.',
    'AUTH.LOGIN.BUTTON': 'Connexion',
    'AUTH.INPUT.EMAIL': 'E-mail',

    'AUTH.INPUT.PASSWORD': 'Mot de passe',
    'AUTH.CREDENTIALS.ERROR': 'Nom d\'utilisateur/mot de passe/pays non valide, veuillez réessayer.',
    'AUTH.VALIDATION.INVALID_LOGIN': 'Login Invalide',
    'AUTH.VALIDATION.REQUIRED_FIELD': 'Information manquante'
};
