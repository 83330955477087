import { deepFreeze } from '../../../../helpers/FreezeHelper';

const InitStorageSharePointOnline = {
    clusterId: null,
    isActive: false,
    priorityLevel: 5,
    token: '',
    tokenExpiration: '',
    tenantName: '',
    tenantId: '',
    siteName: '',
    siteUrl: '',
    clientId: '',
    clientSecret: '',
    targetPath: ''
};

export const initStorageSharePointOnline = deepFreeze(InitStorageSharePointOnline);
