import React from 'react';
import { injectIntl } from 'react-intl';
import { MZRSearchBar } from '../common/MZRSearchBar';

const getOptionLabel = contact => `${contact.firstName ?? ''} ${contact.lastName ?? ''} ${contact.email}`;

const getOptionValue = option => option.id;

function ContactSearchBarComponent(props) {
    const { options, onSelectionChange, intl, selectedContact, disabled, optionLabelDisplay = getOptionLabel } = props;
    return (
        <MZRSearchBar
            getOptionLabel={optionLabelDisplay}
            disabled={disabled}
            isAreaFocusVisible={false}
            value={selectedContact}
            options={options}
            onSelectionChange={onSelectionChange}
            getOptionValue={getOptionValue}
            placeholder={intl.formatMessage({ id: 'MANUAL.CREATE.CONFIRMATION.COMPANY.CONTACTS' })}
        />
    );
}

export const ContactSearchBar = injectIntl(ContactSearchBarComponent);
