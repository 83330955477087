import { buildFilters } from '../../network/mzrRequest';
import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseShort } from '../parsers';
import { parseWorkflow, parseWorkflows } from './workflowParsers';
import { Repository } from '../Repository';

class WorkflowRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async (filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`workflow${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseWorkflows);
        return result;
    };

    create = async (workflow) => {
        let result = await this.network.post(`workflow`, workflow, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    fetch = async (workflowId) => {
        let result = await this.network.get(`workflow/${workflowId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseWorkflow);
        return result;
    };

    update = async (workflowId, workflow) => {
        let result = await this.network.put(`workflow/${workflowId}`, workflow, {
            headers: this.buildHeaders()
        });

        // result.tryParsingValue(parseResponseShort);
        return result;
    };

    delete = async (workflowId) => {
        let result = await this.network.delete(`workflow/${workflowId}`, null, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };
}

export const workflowRepository = new WorkflowRepository();
