import { deepFreeze } from '../../helpers/FreezeHelper';
import { dateWithOffset } from '../../kit/utils/DateUtil';

export const ENGAGEMENT_PERIOD_DEFAULT_MONTHS = 12;

export const ENGAGEMENT_COLUMNS_FILTERS = {
    CLIENT: 'engagementClientCompanyId',
    THIRD_PARTY: 'engagementThirdPartyCompany',
    CATEGORY: 'confirmationCategoryId',
    WORKFLOW_ACTION: 'workflowActionId',
    WORKFLOW_ACTION_STATUS: 'eStepStatus',
    CONFIRMATION_INFO: 'confirmationInfo',
    LETTERHEAD: 'hasLetterhead',
    CLIENT_REQUEST_STATUS: 'clientRequestStatus'
};

export const WORKFLOW_ACTION_STATUS = {
    NOT_STARTED: '0',
    IN_PROGRESS: '1',
    VALIDATED: '2'
};

export const ENGAGEMENT_DOWNLOAD_REPORT = {
    ALL: 0,
    FILES: 1,
    REPORTING: 2,
    CLIENT_REQUESTS_LETTERS: 3
};

export const ENGAGEMENT_ACTIONS = {
    ADD_MEMBER: 'addMember',
    REMOVE_MEMBER: 'removeMember',
    CHANGE_MEMBER_ROLE: 'changeMemberRole',
    ADD_CLIENT: 'addClient',
    UPDATE_CLIENT: 'updateClient',
    DELETE_CLIENT: 'deleteClient',
    ADD_CLIENT_CONTACT: 'addClientContact',
    UPDATE_CLIENT_CONTACT: 'updateClientContact',
    DELETE_CLIENT_CONTACT: 'deleteClientContact',
    INVITE_CLIENT_CONTACT: 'inviteClientContact',
    RESET_PASSWORD_CONTACT: 'resetPasswordContact',
    REVOKE_CONTACT_ACCESS: 'revokeContactAccess',
    DUPLICATE_ENGAGEMENT: 'duplicateEngagement'
};

export const ENGAGEMENT_STEPS = {
    INFORMATION: 0,
    CLIENTS: 1,
    CONTACTS: 2,
    LETTERHEADS: 3,
    MEMBERS: 4,
    THIRD_PARTIES: 5,
    OPTIONAL_INFO_SELECTION: 6
};

export const ENGAGEMENT_MEMBER_ROLE = {
    STANDARD: 1,
    ADMIN: 2
};

const InitEngagement = {
    name: '',
    description: '',
    engagementCode: '',
    openingDate: new Date(),
    closingDate: dateWithOffset(new Date(), 1, 0, -1),
    archived: false,
    clientCompanies: [],
    clientContacts: [],
    appUserEngagements: [],
    parentId: null,
    copyLetterheads: false,
    copyThirdParties: false,
    clusterId: null
};

export const initEngagement = deepFreeze(InitEngagement);
