import React, { useState, useEffect, useContext } from 'react';
import { LetterHeadContext } from '../../context';
import { UPLOAD_TYPES } from '../../helpers/ItemHelper';
import { DocumentViewerContent } from '../document/DocumentViewerContent';
import { UIText } from '../common/UIText';
import { UILoader } from '../common/UILoader';
import { Palette } from '../../styles/colors';

interface LetterHeadPreviewProps {
    letterHead: any;
    loading: boolean;
}

const DocumentPreview = ({ letterHead, pictureURL, documentBlob } : { letterHead: any, pictureURL: string | null, documentBlob: Blob | null }) => {
    return !pictureURL && !documentBlob ? null : pictureURL ? 
        <>
            <UIText
                variant="h6"
                sx={{ color: Palette.sonicSilver, fontSize: 14 }}
                text={letterHead?.engagementClientCompany?.name}
            />
            <img style={{ width: '100%' }} src={pictureURL} alt="letterhead" />
        </> :
        <DocumentViewerContent
            id="pdfViewer"
            enableDownload={false}
            fileName={letterHead?.engagementClientCompany?.name}
            document={documentBlob}
        />
}

export const LetterHeadPreview = ({ letterHead, loading } : LetterHeadPreviewProps) => {
    const { fetchLetterHeadFile } = useContext(LetterHeadContext);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [documentBlob, setDocumentBlob] = useState<Blob | null>(null);

    useEffect(() => {
        (async () => {
            await fetchPreview(letterHead);
        })();
    }, [letterHead]);

    const fetchPreview = async (letterHead : any) => {
        // @ts-ignore
        const { isSuccess, body } = await fetchLetterHeadFile(letterHead);
        if(isSuccess && UPLOAD_TYPES.PICTURES.includes(body.type)) setImagePreview(URL.createObjectURL(body));
        if(isSuccess && UPLOAD_TYPES.PDF.includes(body.type)) setDocumentBlob(body);
    };

    return loading ? <UILoader /> : <DocumentPreview letterHead={letterHead} pictureURL={imagePreview} documentBlob={documentBlob} />
};