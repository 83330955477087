import { deepFreeze } from '../../../helpers/FreezeHelper';

const InitWorkflow = {
    id: null,
    name: '',
    description: '',
    clusterId: null,
    actions: [],
    confirmationCategories: []
};

export const initWorkflow = deepFreeze(InitWorkflow);
