import { Component } from 'react';
import '../style.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { fetchData, UserResourceService } from '../../services';
import { COMPONENT_NAME } from '../../components/setup';
import { UserResourceConfigWrapper } from '../../components/setup/generalConfig/UserResourceConfigWrapper';
import { CircularProgress } from '@material-ui/core';

/**
 * User resource setup screen.
 */
class UserResourceSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    userResourceManager = UserResourceService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        callback: async (isSuccess, _data, _) => isSuccess && (await this.fetchUserResources(this)),
        setLoading: this.setLoading
    });

    fetchUserResources = async () => {
        const result = await this.userResourceManager.fetch();
        if (result?.isSuccess) {
            this.setState({ userResources: result.parsedValue });
        }
    };

    userResourceSubmit = async values => {
        if (values?.id && values?.id !== null) {
            const { isSuccess } = await this.userResourceManager.update(values);

            if (isSuccess) {
                this.props.hasValidConfig(COMPONENT_NAME.USER_RESOURCE_SETUP, true);
                // this.props.setIsLostItemConfigExpanded(false);
            }
        } else {
            const { isSuccess } = await this.userResourceManager.create({
                ...values,
                clusterId: this.props.currentClusterId
            });
            if (isSuccess) {
                this.props.hasValidConfig(COMPONENT_NAME.USER_RESOURCE_SETUP, true);
                // this.props.setIsLostItemConfigExpanded(false);
            }
        }
    };

    async componentDidMount() {
        await fetchData(this, [() => this.fetchUserResources(this)]);
    }

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            if (!this.state.loading)
                await fetchData(this, [() => this.fetchUserResources(this)]);
        }
    };

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div className="fullCenter">
                        <CircularProgress />
                    </div>
                ) : (
                    <UserResourceConfigWrapper
                        loading={this.state.loading}
                        userResources={this.state.userResources}
                        languages={this.props.languages}
                        userResrceCreateRef={this.props.userResrceCreateRef}
                        handleSubmit={this.userResourceSubmit}
                        handleDelete={this.userResourceManager.delete}
                        hasValidConfig={this.props.hasValidConfig}
                    />
                )}
            </>
        );
    }
}

UserResourceSetupComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array,
    languages: PropTypes.array.isRequired
};

UserResourceSetupComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        languages: state.cluster.languages,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const UserResourceSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(UserResourceSetupComponent));
