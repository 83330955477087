// @ts-ignore

import { createTheme } from '@mui/material';
import { Palette } from './colors';
export const MZRTheme = createTheme({
    palette: {
        secondary: {
            main: Palette.red
        },
        primary: {
            main: Palette.defaultPrimaryColor
        }
    }
});
