import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '@mui/material';
import { UILoader } from '../components/common/UILoader';
import { useDispatch } from 'react-redux';
import * as actions from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { getLocalizedString } from '../i18n/i18Helper';
import { createToast } from '../components/toast/Toast';
import { UIDialog } from '../components/common/UIDialog';
import { UIText } from '../components/common/UIText';

export interface UIScaffoldLoadingAndSession {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setSessionExpired: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UIScaffoldChildContext extends UIScaffoldLoadingAndSession {
    setToast: (title: string, description: string, severity: string) => void;
}

interface UIScaffoldProps {
    loadingText?: string;
    children: (context: UIScaffoldChildContext) => React.ReactNode;
}

export const UIScaffold = ({ children, loadingText }: UIScaffoldProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const [isSessionExpired, setSessionExpired] = useState(false);

    const classes = useStyles();
    const isLoaderVisible = isLoading;

    const setToast = (title: string, description: string, severity: string) => {
        dispatch(actions.addToastMessage(createToast(severity, title, description)));
    };

    const onDismissLoader = (_: any, reason: string) => {
        if (reason && reason === 'backdropClick') return;

        setLoading(false);
    };

    const onDismissExpiredSession = () => {
        dispatch(actions.resetUser());
        dispatch(actions.flushCurrentCluster());
        setSessionExpired(false);
    };

    return (
        <>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: LOADER_BG_COLOR,
                        boxShadow: 'none'
                    }
                }}
                disableEscapeKeyDown
                fullWidth
                classes={{ paper: classes.dialogPaper }}
                maxWidth={'md'}
                open={isLoaderVisible}
                onClose={onDismissLoader}
                aria-labelledby="indicator">
                <UILoader text={loadingText} />
            </Dialog>

            <UIDialog open={isSessionExpired} titleId="INVALID.SESSION.POPUP.TITLE" onClose={onDismissExpiredSession}>
                <UIText text={getLocalizedString(intl, 'INVALID.SESSION.UNAUTHORISED.USER.MESSAGE')} />
            </UIDialog>

            {children({ setLoading, setSessionExpired, setToast })}
        </>
    );
};

const LOADER_BG_COLOR = '#FFFFFF30';
const useStyles = makeStyles({
    dialogPaper: {
        minHeight: '50vh',
        maxHeight: '50vh'
    },
    container: {
        flex: '1',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center'
    }
});
