import React from 'react';
import { EditCompany } from '../../../company';
import { SearchCompanyContainer } from '../../../company/search/SearchCompanyContainer';
import { SearchCompanyWrapper } from '../../../company/search/SearchCompanyWrapper';
import { COMPANY_TYPE } from '../../../../kit/utils/constants';

export function SearchThirdParty({
    loading,
    createCompanyAPI,
    createCompanyCallback,
    selectCompanyCallback,
    searchCompany,
    initialCompany
}) {
    return (
        <SearchCompanyContainer
            doInitialSearch={true}
            loading={loading}
            searchCompany={searchCompany}
            companyType={COMPANY_TYPE.THIRDPARTY}
            createCompanyAPI={createCompanyAPI}
            createCompanyCallback={createCompanyCallback}
            createCompanyComponent={EditCompany}
            selectCompanyCallback={selectCompanyCallback}
            companySearchModule={SearchCompanyWrapper}
            initialCompany={initialCompany}
            createPopupTitleId={'CREATE_CONFIRMATION.THIRDPARTY.CREATE.COMPANY.LABEL'}
        />
    );
}
