import { buildFilters } from '../../network/mzrRequest';
import { appApiUrl } from '../../config/appConfiguration';
import { parseResponse } from '../parsers';
import { serializeLetterTemplate } from '../index';
import { parseLetterTemplate, parseLetterTemplates } from './confirmationParsers';
import { Repository } from '../Repository';

class LetterTemplateRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async (filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`letterTemplate${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseLetterTemplates);
        return result;
    };

    fetch = async (templateId) => {
        let result = await this.network.get(`letterTemplate/${templateId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseLetterTemplate);
        return result;
    };

    create = async (template) => {
        const templateForm = new FormData();
        templateForm.append('File', template.file[0]);
        templateForm.append('Json', JSON.stringify(serializeLetterTemplate(template)));

        let result = await this.network.post('letterTemplate', templateForm, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    update = async (templateId, template) => {
        const templateForm = new FormData();
        templateForm.append('Json', JSON.stringify(serializeLetterTemplate(template)));

        let result = await this.network.put(`letterTemplate/${templateId}`, templateForm, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    delete = async (templateId) => {
        let result = await this.network.delete(`letterTemplate/${templateId}`, null, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    download = async (templateId, filters = {}) => {
        const _filters = buildFilters(filters);
        return await this.network.get(`letterTemplate/${templateId}/file${_filters}`, {
            headers: this.buildHeaders()
        });
    };
}

export const letterTemplateRepository = new LetterTemplateRepository();
