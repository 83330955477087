import { Grid } from '@mui/material';
import React from 'react';
import { FormLabel, getFieldElement } from '../../../../helpers/FormHelper';

export const EditConfirmationSection = ({ fields }) => {
    return (
        <Grid item style={{ display: 'flex', flexDirection: 'column' }} xs={12}>
            {React.Children.toArray(
                fields?.map(field => (
                    <Grid container direction="row" alignItems="center">
                        <Grid container item direction="column" xs={5}>
                            <FormLabel id={field?.labelItem?.id} isRequired={field?.labelItem?.isRequired} />
                        </Grid>

                        <Grid className="edit-confirmation" container item direction="column" xs={6}>
                            {getFieldElement(field)}
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>
                ))
            )}
        </Grid>
    );
};
