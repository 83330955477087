import { appApiUrl } from '../../config/appConfiguration';
import { serializeLostItemConfiguration } from '../serializers';
import { parseLostItemConfiguration } from './setupParsers';
import { Repository } from '../Repository';

class LostItemConfigurationRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetch = async () => {
        let result = await this.network.get(`lostitemconfiguration`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseLostItemConfiguration);
        return result;
    };

    create = async (configuration) => {
        const serializedConfiguration = serializeLostItemConfiguration(configuration);
        return await this.network.post(`lostitemconfiguration`, serializedConfiguration, {
            headers: { 
                ...this.buildHeaders(true),
                mode: 'no-cors'
            }
        });
    };

    update = async (configurationId, configuration) => {
        const serializedConfiguration = serializeLostItemConfiguration(configuration);
        return await this.network.put(`lostitemconfiguration/${configurationId}`, serializedConfiguration, {
            headers: { 
                ...this.buildHeaders(true),
                mode: 'no-cors'
            }
        });
    };

    delete = async (configurationId) => {
        return await this.network.delete(`lostitemconfiguration/${configurationId}`, null, {
            headers: this.buildHeaders()
        });
    };
}

export const lostItemConfigurationRepository = new LostItemConfigurationRepository();
