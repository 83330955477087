export const documents = {
    'DOCUMENTS.LOST.DOCUMENTS.SUBSECTION.TITLE': '{numberDocuments} Lost document(s)',
    'DOCUMENTS.LOST.DOCUMENTS.SUBSECTION.TOOLTIP':
        'You will find here the list of documents not dispatched to engagement/confirmation due to missing, wrong or missread code',
    'DOCUMENTS.PENDING.SUBSECTION.TITLE': '{numberDocuments} Document(s) pending',
    'DOCUMENTS.PENDING.SUBSECTION.TOOLTIP':
        'You will find here the list of documents you have to dispatch to a confirmation and/or select the document type',
    'DOCUMENT.TABLE.TITLE.ROBOT': 'Processing',
    'DOCUMENT.TABLE.PROCESSING.TOOLTIP.FOLDER': 'Folder',
    'DOCUMENT.TABLE.PROCESSING.TOOLTIP.PENDING': 'Pending robot processing',
    'DOCUMENT.TABLE.PROCESSING.TOOLTIP.CHECKED': 'Checked by robot',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.REMOVE': 'Remove item',
    'DOCUMENT.TABLE.TITLE.TYPE': 'Type of item',
    'DOCUMENT.TABLE.TITLE.DOCUMENT_TYPE': 'Type',
    'DOCUMENT.TABLE.TITLE.INVALID.ADDRESS': 'Invalid address',
    'DOCUMENT.TABLE.TITLE.CREATED': 'Creation date',
    'DOCUMENT.TABLE.TITLE.UPDATED': 'Last update',
    'DOCUMENT.TABLE.TITLE.NAME': 'Name',
    'DOCUMENT.TABLE.TITLE.EXTENSION': 'Extension',
    'DOCUMENT.TABLE.TITLE.COMPANY': 'Company',
    'DOCUMENT.TABLE.TITLE.STATUS': 'Status',
    'DOCUMENT.TABLE.TITLE.ENGAGEMENT': 'Engagement',
    'DOCUMENT.TABLE.TITLE.CONFIRMATION': 'Confirmation',
    'DOCUMENT.TABLE.TITLE.DOWNLOADED': 'File downloaded',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.OPEN': 'Visualise',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.VALIDATE': 'Validate',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.LOST':
        'Send the document to the lost basket if it cannot be assigned to a confirmation',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.REFUSE':
        'The document does not concern my engagement. Send it back to the Support Team.',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.DOWNLOAD': 'Download',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.SIGN': 'Sign',
    'DOCUMENT.TABLE.ACTION.TOOLTIP.PRINT': 'Print',
    'DOCUMENT.TABLE.STATUS.TOOLTIP.PENDING': 'Pending - Document waiting to be print and send by support',
    'DOCUMENT.TABLE.STATUS.TOOLTIP.INPROGRESS':
        'In progress - Document printed and shipment currently being processed by support',
    'DOCUMENT.TABLE.STATUS.TOOLTIP.DONE': 'Done - Document printed and sent by support',
    'DOCUMENT.TABLE.STATUS.TOOLTIP.NONE': 'None',
    'DOCUMENT.INFORMATION.LABEL.NAME': 'Name',
    'DOCUMENT.INFORMATION.VALIDATION.REQUIRED_FIELD.NAME': 'Document name is required',
    'DOCUMENT.INFORMATION.LABEL.DESCRIPTION': 'Description',
    'DOCUMENT.INFORMATION.LABEL.DOCUMENT_TYPE': 'Document type',
    'DOCUMENT.INFORMATION.LABEL.ENGAGEMENT': 'Engagement',
    'DOCUMENT.INFORMATION.LABEL.CONFIRMATION': 'Confirmation',
    'DOCUMENT.VIEWER.CARDHEADER.FILE.NAME.TITLE': 'Document {documentName}',
    'DOCUMENT.VIEWER.TITLE': 'Document details',
    'DOCUMENT.VIEWER.BUTTON.BACK.LABEL': 'Back',
    'DOCUMENT.VIEWER.BUTTON.DOWNLOAD.LABEL': 'Download',
    'DOCUMENT.SELECT.CONFIRMATION.SEARCH_BAR.PLACEHOLDER': 'Search by Code',
    'DOCUMENT.SELECT.ENGAGEMENT.SEARCH_BAR.PLACEHOLDER': 'Search by Name, Code, Companies',
    'FETCH.DOCUMENT.UPDATE.SUCCESS': 'Document/Item updated',
    'FETCH.DOCUMENT.UPDATE.ERROR': 'Document/Item update failed',
    'ITEM.UPLOAD.DIRECTORY.NAME': 'Directory',
    'ITEM.UPLOAD.DIRECTORY.AUDITOR.DESCRIPTION': 'Folder uploaded by auditor',
    'ITEM.UPLOAD.DIRECTORY.SUPPORT.DESCRIPTION': 'Folder uploaded by support',
    'ITEM.UPLOAD.DOCUMENT.AUDITOR.DESCRIPTION': 'Document uploaded by auditor',
    'ITEM.UPLOAD.DOCUMENT.SUPPORT.DESCRIPTION': 'Document uploaded by support',
    'ITEM.UPLOAD.TYPE.ERROR.MESSAGE': 'File type is invalid',
    'ITEM.POPUP.INFORMATION.DATE.CREATED': 'Created:',
    'ITEM.POPUP.INFORMATION.DATE.UPDATED': 'Last update:',
    'ITEM.POPUP.INFORMATION.AUTHOR.BY': 'by {author}',
    'ITEM.POPUP.INFORMATION.INVALID.ADDRESS': 'Undeliverable mail: letter returned due to invalid postal address on the {date}',
    'DOCUMENT.INFORMATION.INVALID.ADDRESS': 'Letter {name} returned as Undelivered mail, due to invalid postal address on the {date}',
    'ITEM.FOLDER.NUMBER.OF.FILE.LABEL': '{filesNumber} file(s)',
    'ITEM.TRASH.INFO': 'Put the document in the Recycle Bin',
    'ITEM.DOWNLOAD.INFO': 'Download the document',
    'ITEM.DELETE.INFO': 'Delete the document',
    'ITEM.RESTORE.INFO': 'Restore the document',
    'DOCUMENT.ITEM.UPLOAD.SUCCESS': 'Document uploaded',
    'DOCUMENT.ITEM.UPLOAD.ERROR': 'Document upload error',
    'DOCUMENT.ITEM.UPDATE.SUCCESS': 'Document dispached',
    'DOCUMENT.ITEM.UPDATE.ERROR': 'Document update error',
    'DOCUMENT.ITEM.DELETE.SUCCESS': 'Document deleted',
    'DOCUMENT.ITEM.DELETE.ERROR': 'Document delete error',
    'DOCUMENT.FILE.ACCESS.ERROR': 'Document loading error',
    'DOCUMENT.INFO.ACCESS.ERROR': 'Document information loading error',
    'DOCUMENT.ACCESS.DOCUMENTS.ERROR': 'Document access error',
    'DOCUMENT.STATUSES.ACCESS.ERROR': 'Acces list of documents statuses failed',
    'DOCUMENT.TYPES.ACCESS.ERROR': 'Acces list of documents types failed',
    'DOCUMENT.ERRORS.ACCESS.ERROR': 'Acces list of documents errors failed',
    'DOCUMENT.ERRORS.FILE_SIZE_EXCEEDED':
        'File {fileName} was rejected. File is too big. Size limit is {maxFileSize} MB.'
};
