import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { UIDialog } from '../../common/UIDialog';

export function SearchCompanyContainer({
    createCompanyComponent: CreateCompanyComponent,
    companySearchModule: CompanySearchModule,
    createCompanyAPI,
    createCompanyCallback,
    deleteCompanyAPI,
    selectCompanyCallback = undefined,
    handleEditCompany,
    loading,
    initialCompany,
    searchCompany,
    companyType,
    createPopupTitleId,
    doInitialSearch,
    ...props
}) {
    const [openCreate, setOpenCreate] = useState(false);
    const sbmtCompanyRef = useRef();
    const classes = useStyles();
    const [newCompany, setNewCompany] = useState(initialCompany);

    const handleCompanyNotExists = () => {
        setNewCompany(initialCompany);
        setOpenCreate(!openCreate);
    };

    const handleCompanyClickSave = () => {
        if (sbmtCompanyRef && sbmtCompanyRef.current) {
            sbmtCompanyRef.current.saveForm();
        }
    };

    const handleCompanyPopUpCancel = () => {
        setOpenCreate(!openCreate);
        setNewCompany(initialCompany); //TODO should be done in SearchThirdParty
    };

    const handleCreateCompany = async company => {
        let newCompanyUpdate = { ...newCompany, ...company };
        setNewCompany(newCompanyUpdate);
        const result = await createCompanyAPI(newCompanyUpdate);
        if (result?.parsedValue?.ids?.length > 0) {
            newCompanyUpdate.id = result.parsedValue.ids[0];
            createCompanyCallback(result?.isSuccess, newCompanyUpdate);
        } else {
            createCompanyCallback(result?.isSuccess);
        }
        result?.isSuccess && setOpenCreate(false);
    };

    const handleSelect = company => {
        selectCompanyCallback && selectCompanyCallback(true, company);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <CompanySearchModule
                    doInitialSearch={doInitialSearch}
                    loading={loading}
                    createCompanyClick={handleCompanyNotExists}
                    handleSelectCompany={handleSelect}
                    handleEditCompany={handleEditCompany}
                    handleSearchCompany={searchCompany}
                    deleteCompany={deleteCompanyAPI}
                />
            </Grid>

            <Grid item container alignItems={'flex-end'} xs={12}>
                {openCreate && (
                    <UIDialog
                        {...props}
                        open={openCreate}
                        titleId={createPopupTitleId}
                        onSave={handleCompanyClickSave}
                        onClose={handleCompanyPopUpCancel}>
                        <CreateCompanyComponent
                            {...props}
                            classes={classes}
                            variant="outlined"
                            company={newCompany}
                            companyType={companyType}
                            handleSubmit={handleCreateCompany}
                            sbmtCompanyRef={sbmtCompanyRef}
                        />
                    </UIDialog>
                )}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    margin: {
        margin: theme.spacing(1)
    },
    textField: {
        width: '98%'
    },
    selectField: {
        width: '75%'
    },
    inputField: {
        width: '80%',
        paddingLeft: 25,
        paddingRight: 25
    },
    formControl: {
        width: '100%',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
}));
