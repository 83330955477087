import React from 'react';
import './login.scss';

/**
 * Layout specific for the login page.
 *
 * @param {Node} param0
 */
const LoginLayout = ({ children }) => <>{children}</>;

export default LoginLayout;
