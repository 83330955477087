import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SearchCompanyTab, SEARCH_FILTERS } from './SearchCompanyTab';
import { CompanyTable } from '../CompanyTable';
import { Button, Box } from '@mui/material';
import { useSearch } from '../../../hooks/useSearch';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export const SearchCompanyWrapper = props => {
    const {
        intl,
        loading,
        handleSearchCompany,
        handleSelectCompany,
        handleEditCompany,
        createCompanyClick,
        deleteCompany,
        doInitialSearch = false
    } = props;
    const classes = useStyles();

    const { onSearch, filteredPaginatedItems, globalFilters, handleClear, handleFilterChange } = useSearch({
        filtersList: SEARCH_FILTERS,
        handleSearch: handleSearchCompany
    });

    const handleCompanyDelete = async values => {
        const { isSuccess } = await deleteCompany(values);
        if (isSuccess) await onSearch(globalFilters);
    };

    return (
        <>
            <SearchCompanyTab
                handleSearch={onSearch}
                handleClear={handleClear}
                globalFilters={globalFilters}
                doInitialSearch={doInitialSearch}
            />

            <CompanyTable
                intl={intl}
                loading={loading}
                shouldDisplayAdminColumns={false}
                companiesPaginated={filteredPaginatedItems}
                handleCompanyClick={handleSelectCompany}
                isCompanyDeletable={!!deleteCompany} // Display delete option in the table
                handleCompanyDelete={handleCompanyDelete}
                isCompanyEditable={!!handleEditCompany} // Display edit option in the table
                handleCompanyEdit={handleEditCompany}
                handleFilterChange={handleFilterChange}
            />

            <Box component="span" m={1} className={clsx(classes.box, classes.boxButton)}>
                <Button size="small" className={classes.button} onClick={createCompanyClick}>
                    <FormattedMessage id="SEARCH.COMPANY.BUTTON.CREATE.COMPANY.LABEL" />
                </Button>
            </Box>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    }
}));
