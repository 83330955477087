import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { MoreHorizTwoTone as MoreHorizTwoToneIcon } from '@mui/icons-material';
import { MZRChip } from '../../common/MZRChip';

export function TemplateTagsWrapper(props) {
    const { options } = props;

    return (
        <>
            <Grid container direction="column" alignItems="center" spacing={4}>
                <Grid item>
                    <label>
                        <strong>
                            <FormattedMessage id={'TEMPLATE_TAGS.SUBSECTION.TAGS.MANDATORY'} />
                        </strong>
                    </label>
                </Grid>
                <Grid item container spacing={2}>
                    <Box key="mandatory_box_key" width={1}>
                        <Grid container item alignItems="center" spacing={1}>
                            {options?.mandatoryTags ? (
                                Object.values(options?.mandatoryTags)?.map(tag => (
                                    <Grid item key={`grid_mandatory_tag_${tag?.id}`}>
                                        <MZRChip
                                            id={`mandatory_${tag?.id}`}
                                            key={`mandatory_${tag?.id}`}
                                            size="small"
                                            color="secondary"
                                            label={tag?.text ?? tag?.tag?.text}
                                            avatar={
                                                <Avatar>
                                                    {String(tag?.text ?? tag?.tag?.text)
                                                        .split(' ')
                                                        .map(x => x.substring(0, 1))}
                                                </Avatar>
                                            }
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <div className="text-center">
                                    <MoreHorizTwoToneIcon />
                                </div>
                            )}
                        </Grid>
                    </Box>
                </Grid>

                {options?.optionalTags?.length > 0 && (
                    <>
                        <Grid item>
                            <label>
                                <strong>
                                    <FormattedMessage id={'TEMPLATE_TAGS.SUBSECTION.TAGS.OPTIONAL'} />
                                </strong>
                            </label>
                        </Grid>

                        <Grid item container spacing={2}>
                            <Box key="optional_box_key" width={1}>
                                <Grid container item justifyContent="space-evenly" alignItems="center" spacing={1}>
                                    {options?.optionalTags ? (
                                        Object.values(options?.optionalTags)?.map(tag => (
                                            <Grid item key={`grid_optional_tag_${tag?.id}`}>
                                                <MZRChip
                                                    id={`optional_${tag?.id}`}
                                                    key={`optional_${tag?.id}`}
                                                    size="small"
                                                    color="primary"
                                                    label={tag?.text ?? tag?.tag?.text}
                                                    avatar={
                                                        <Avatar>
                                                            {String(tag?.text ?? tag?.tag?.text)
                                                                .split(' ')
                                                                .map(x => x.substring(0, 1))}
                                                        </Avatar>
                                                    }
                                                />
                                            </Grid>
                                        ))
                                    ) : (
                                        <div className="text-center">
                                            <MoreHorizTwoToneIcon />
                                        </div>
                                    )}
                                </Grid>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}

TemplateTagsWrapper.propTypes = {
    // /**
    //  *  Options mandatory and optional tags available.
    //  */
    options: PropTypes.shape({
        /**
         * Mandatory tags required to create a template.
         */
        mandatoryTags: PropTypes.array,
        /**
         * Optional tags that could be use in a template.
         */
        optionalTags: PropTypes.array
    })
};
