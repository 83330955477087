import React from 'react';
import TextField from '@material-ui/core/TextField';

const TextInput = ({
    id,
    key,
    name,
    label,
    field,
    margin,
    variant,
    fullWidth,
    form: { dirty, touched, errors },
    className,
    disabled,
    ...other
}) => {
    const hasError = dirty && touched[field.name] && errors[field.name] !== undefined;

    return (
        <TextField
            id={id}
            key={key}
            name={name}
            className={className}
            label={label}
            variant={variant}
            error={hasError}
            fullWidth={fullWidth}
            margin={margin}
            disabled={disabled}
            {...field}
            {...other}
        />
    );
};

export default TextInput;
