import { buildFilters } from '../../network/mzrRequest';
import { appApiUrl } from '../../config/appConfiguration';
import { parsePaginationResponse } from '../parsers';
import { serializeItem } from '../serializers';
import { parseItem, parseItems } from './itemParsers';
import { Repository } from '../Repository';

class ItemRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async (filters = {}) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`item${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseItems));
        return result;
    };

    fetch = async (itemId) => {
        let result = await this.network.get(`item/${itemId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseItem);
        return result;
    };

    update = async (itemId, item) => {
        const serializedItem = serializeItem(item);
        let result = await this.network.put(`item/${itemId}`, serializedItem, {
            headers: this.buildHeaders(true)
        });

        return result;
    };

    delete = async (itemId) => {
        return await this.network.delete(`item/${itemId}`, null, {
            headers: this.buildHeaders()
        });
    };

    fetchFile = async (itemId, filters = {}) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`item/${itemId}/file${_filters}`, {
            headers: this.buildHeaders()
        });

        return result;
    };

    download = async (itemId) => {
        let result = await this.network.get(`item/${itemId}/file`, {
            headers: this.buildHeaders(true)
        });

        return result;
    };

    fetchExcelTemplate = async (clusterId) => {
        let result = await this.network.get(`item/${clusterId}/confirmationexcelbulktemplate`, {
            headers: this.buildHeaders(true)
        });

        return result;
    };

    upload = async (items) => {
        let formData = new FormData();
        const documentsSerialized = items.reduce((acc, item) => [...acc, serializeItem(item)], []);
        items.forEach(item => {
            formData.append('File', item.file);
            item.childItems && item.childItems.forEach(childItem => formData.append('File', childItem.file));
        });
        formData.append('Json', JSON.stringify(documentsSerialized));

        let result = await this.network.post(`item`, formData, {
            headers: this.buildHeaders(true)
        });

        return result;
    };

    fetchDocumentTypes = async (filters = {}) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`item/documentTypes${_filters}`, {
            headers: this.buildHeaders()
        });

        return result;
    };

    fetchItemTypes = async () => {
        let result = await this.network.get(`item/types`, {
            headers: this.buildHeaders()
        });

        return result;
    };

    fetchErrors = async () => {
        let result = await this.network.get(`item/errors`, {
            headers: this.buildHeaders()
        });

        return result;
    };
}

export const itemRepository = new ItemRepository();
