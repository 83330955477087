import { appApiUrl } from '../../config/appConfiguration';
import { parseResponse, parseResponseShort, parsePaginationResponse } from '../parsers';
import { buildFilters } from '../../network/mzrRequest';
import { serializeCompaniesTemplateRequest, serializeCompaniesUpload } from '../serializers';
import { parseCompanies, parseCompany } from './companyParsers';
import { Repository } from '../Repository';

/**
 * Class to communicate with API to access to company resources.
 */
class GlobalClientCompanyRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    /**
     * Get details of a given global client company.
     * Param: company ID
     */
    fetch = async (companyId) => {
        let result = await this.network.get(`GlobalClientCompany/${companyId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseCompany);
        return result;
    };

    /**
     * Create a company.
     * Param: company details
     */
    create = async (company) => {
        let result = await this.network.post('GlobalClientCompany', company, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    /**
     * Update details for the given list of companies.
     * Param: list of companies to delete
     */
    update = async (companies) => {
        let result = await this.network.put('GlobalClientCompany', companies, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    /**
     * Delete list of companies.
     * Param: companies: list of the companies Ids to delete
     */
    delete = async (companies) => {
        return await this.network.delete('company', companies, {
            headers: this.buildHeaders()
        });
    };

    /**
     * Search global client companies according to the given filters
     * @param filters: list of filters (name, country, address, zipCode, city)
     */
    fetchAll = async (filters) => {
        const formattedFilters = buildFilters(filters);
        let result = await this.network.get(`GlobalClientCompany${formattedFilters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseCompanies));
        return result;
    };

    /**
     * Fetches the excel template to use in bulk upload of global client companies
     * @param configurationInfo: the info of the excel template
     */
    fetchExcelTemplate = async (configurationInfo) => {
        let result = await this.network.post(
            'GlobalClientCompany/excel/file',
            serializeCompaniesTemplateRequest(configurationInfo), {
                headers: this.buildHeaders(true)
            }
        );

        return result;
    };

    upload = async ({ clusterId, file, info, resetDatabase }) => {
        const formData = new FormData();

        formData.append('ClusterId', clusterId);
        formData.append('File', file);
        formData.append('Info', JSON.stringify(serializeCompaniesUpload(info)));
        formData.append('ResetDatabase', resetDatabase);

        let result = await this.network.post('GlobalClientCompany/excel', formData, {
            headers: this.buildHeaders(true)
        });

        return result;
    };
}

export const globalClientCompanyRepository = new GlobalClientCompanyRepository();
