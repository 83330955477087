import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SessionStatus } from '../kit/utils/constants';
import * as actions from '../store/actions';
import { FormattedMessage } from 'react-intl';
import { UIDialog } from '../components/common/UIDialog';

export const getSessionStatus = apiResult => {
    let sessionStatus = SessionStatus.unknown;

    if (apiResult?.isSuccess) {
        sessionStatus = SessionStatus.valid;
    } else if (!!apiResult?.isForbiddenAccessError) {
        sessionStatus = SessionStatus.forbidden;
    } else if (!!apiResult?.isUnauthorizedUserError) {
        sessionStatus = SessionStatus.invalid;
    }

    return sessionStatus;
};

const CheckSessionWrapper = WrappedComponent => {
    return class extends Component {
        state = {
            openErrorPup: false,
            message: '',
            validateCallback: null
        };

        handleConfirm = validateCallback => () => {
            this.setState({ openErrorPopup: false });
            validateCallback && validateCallback();
        };

        showErrorPopup = (message, validateCallback) => {
            this.setState({ openErrorPopup: true, message: message, validateCallback: validateCallback });
        };

        checkSession = async (apiResult, showErrorMessage = true) => {
            const sessionStatus = getSessionStatus(apiResult);

            switch (sessionStatus) {
                case SessionStatus.invalid:
                    if (showErrorMessage) {
                        this.showErrorPopup(apiResult?.errorMessage, () => {
                            // remove user from redux
                            this.props.logoutUser();
                        });
                    }

                    break;

                default:
                    break;
            }

            return sessionStatus;
        };

        render() {
            const { openErrorPopup, message, validateCallback } = this.state;

            return (
                <>
                    <WrappedComponent {...this.props} checkSession={this.checkSession} />
                    {openErrorPopup && (
                        <UIDialog
                            classes={{ maxWidth: 'xs' }}
                            open={openErrorPopup}
                            titleId="INVALID.SESSION.POPUP.TITLE"
                            onClose={this.handleConfirm(validateCallback)}
                            saveTitleId="DIALOG.BUTTON.OK">
                            <span>{<FormattedMessage id={message} />}</span>
                        </UIDialog>
                    )}
                </>
            );
        }
    };
};

const mapDispatchToProps = dispatch => ({
    logoutUser: () => {
        dispatch(actions.resetUser());
        dispatch(actions.flushCurrentCluster());
    }
});

export const withCheckSession = compose(connect(null, mapDispatchToProps), CheckSessionWrapper);
