import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/plugins/keenthemes-icons/font/ki.css';
import './assets/plugins/flaticon/flaticon.css';
import './assets/plugins/flaticon2/flaticon.css';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { MzrI18nProvider } from './i18n';

ReactDOM.render(
    <MzrI18nProvider>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </MzrI18nProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
