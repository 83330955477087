import { useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../helpers/TableHelper';
import { ServiceResult } from '../kit/network/ServiceResult';

export const useSearch = ({
    filtersList,
    handleSearch
}: {
    filtersList: Record<string, { id: string; fields: string[] }>;
    handleSearch: (filters: Record<any, any>) => Promise<ServiceResult>;
}) => {
    const [filteredPaginatedItems, setFilteredPaginatedItems] = useState<any>({});

    const [globalFilters, setGlobalFilters] = useState({
        pageSize: DEFAULT_PAGE_SIZE,
        pageIndex: 0
    });

    const prepareFilters = filters => {
        const initialValue = {};
        return Object.keys(filters)?.reduce((previous, key) => {
            const filterValue = filters[key];

            let formattedFilters = {
                ...previous,
                ...{ [key]: filterValue } // ex: pageSize=5
            };

            if (filtersList[key]) {
                // need to map in case of having multiple fields, api should have them separately
                const fieldsFilters = filtersList[key]?.fields?.reduce(
                    (previousFilter, fieldName) => ({
                        ...previousFilter,
                        [fieldName]: filterValue
                    }),
                    {}
                );

                // it's one of the defined fields filters, replace the current formatted filter
                formattedFilters = {
                    ...previous,
                    ...fieldsFilters // ex name=lcl
                };
            }

            return formattedFilters;
        }, initialValue);
    };

    const onSearch = async filters => {
        const result: ServiceResult = await handleSearch(prepareFilters(filters));
        result?.isSuccess && setFilteredPaginatedItems(result?.parsedValue ?? {});
    };

    const handleClear = () => {
        setFilteredPaginatedItems({});
    };

    const handleFilterChange = async values => {
        setGlobalFilters({ ...globalFilters, ...values });
    };

    return {
        handleClear,
        handleFilterChange,
        onSearch,
        filteredPaginatedItems,
        globalFilters
    };
};
