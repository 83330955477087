import { useIntl } from 'react-intl';
import { Box, Grid } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { UIDropDownMenu } from '../common/UIDropDownMenu';
import { MZRSearchField } from '../common/search/MZRSearchField';
import { ITEM_FILTERS } from './index';
import { UIExpandableIconButton } from '../common/UIExpandableIconButton';

const SEARCH_FIELD = { id: 'FILTER_1', name: ITEM_FILTERS.SUPPORT_FILTER };

export const DocumentsToolbar = ({ handleOpenRecycleBin, handleSearch, style = {} }) => {
    const intl = useIntl();
    const [filters, setFilters] = useState({});

    const handleChangeFilter = event => {
        setFilters({ ...filters, [event.target.name]: event.target.value });
    };

    const onSearch = () => {
        handleSearch(SEARCH_FIELD.name, filters[SEARCH_FIELD.id]);
    };

    const handleClearSearch = _ => {
        handleSearch(SEARCH_FIELD.name, '');
    };

    return (
        <Grid
            item
            style={{
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'row',
                ...style
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <MZRSearchField
                    searchIcon={<SearchOutlined />}
                    titleId="SUPPORT.SEARCH.PLACEHOLDER"
                    filter={{
                        id: SEARCH_FIELD.id,
                        value: filters[SEARCH_FIELD.id] ?? ''
                    }}
                    filterKey={SEARCH_FIELD.id}
                    handleChange={handleChangeFilter}
                    handleSearch={onSearch}
                    onClear={handleClearSearch}
                />
            </Box>

            {handleOpenRecycleBin && (
                <UIDropDownMenu
                    id="BIN"
                    entries={[
                        {
                            id: 'recycle_bin',
                            divider: true,
                            title: intl.formatMessage({
                                id: 'ENGAGEMENT.BUTTON.RECYCLE.BIN.LABEL'
                            }),
                            handler: handleOpenRecycleBin
                        }
                    ]}
                    titleComponent={() => (
                        <UIExpandableIconButton aria-label="recycle-bin" style={{ margin: '16px' }} />
                    )}
                />
            )}
        </Grid>
    );
};
