export const popup = {
    'MESSAGE.REFUSE.WORKFLOW.STEP.TITLE': 'Veuillez confirmer votre choix!',
    'MESSAGE.REFUSE.WORKFLOW.STEP.CONTENT':
        'Hello {userName}, Etes-vous sûr de vouloir refuser et réinitialiser la confirmation ? Cette opération est définitive.',
    'MESSAGE.REFUSE.WORKFLOW.STEP.COMMENT': 'Veuillez expliquer votre refus',
    'MESSAGE.REFUSE.LOST.DOCUMENT.CONTENT':
        'Hello {userName}, Etes-vous sûr de vouloir envoyer ce document à la section des documents perdus ? Cette opération est définitive.',
    'MESSAGE.REFUSE.LOST.DOCUMENT.COMMENT':
        'Veuillez fournir des informations sur le document afin qu\'il puisse être identifié par les auditeurs.',
    'MESSAGE.RESET.WORKFLOW.STEP.TITLE': 'Veuillez confirmer votre choix!',
    'MESSAGE.RESET.WORKFLOW.STEP.CONTENT':
        'Bonjour {userName}, sachez que l\'annulation d\'une confirmation entraîne sa réinitialisation et la perte de toutes les données. Cette opération est définitive.',
    'MESSAGE.RESET.WORKFLOW.STEP.ERROR': 'Sélectionner le type d\'erreur',
    'MESSAGE.RESET.FIELD.VALIDATION.REQUIRED_FIELD.ERROR_TYPE': 'Le type d\'erreur pour le document est requis',
    'MESSAGE.RESET.WORKFLOW.STEP.COMMENT': 'Veuillez expliquer',
    'MESSAGE.DELETE.CONFIRMATION.TITLE': 'Veuillez confirmer votre choix!',
    'MESSAGE.ERASE.CONFIRMATION.CONTENT':
        'Bonjour {nomutilisateur}, sachez que la suppression d\'une confirmation entraîne la suppression de toutes ses données. Cette opération est définitive.',
    'MESSAGE.TRASH.CONFIRMATION.CONTENT':
        "Bonjour {userName}, êtes-vous sûr de vouloir mettre cette confirmation dans la corbeille de l'mission ?",
    'MESSAGE.DELETE.CONFIRMATION.COMMENT': 'Veuillez indiquer la raison de la suppression',
    'MESSAGE.DELETE.ITEM.TITLE': 'Veuillez confirmer votre choix!',
    'MESSAGE.DELETE.ITEM.CONTENT':
        'Bonjour {userName}, sachez que la suppression d\'un document l\'efface définitivement et ne peut être récupérée. Cette opération est définitive.',
    'MESSAGE.DELETE.ITEM.COMMENT': 'Veuillez confirmer la suppression.',
    'MESSAGE.ARCHIVE.ENGAGEMENT.TITLE': 'ÊTES-VOUS SÛR DE VOULOIR ARCHIVER CETTE MISSION ?',
    'MESSAGE.UNARCHIVE.ENGAGEMENT.TITLE': 'ÊTES-VOUS SÛR DE VOULOIR DESARCHIVER CETTE MISSION ?',
    'MESSAGE.ARCHIVE.ENGAGEMENT.CONTENT':
        'Bonjour {userName}, sachez que l\'archivage d\'une mission n\'est pas réversible et désactive son édition.{br}{br}Il sera impossible de créer une autre mission avec le code "{engagementCode}" par la suite.{br}{br}Cette opération est définitive. Veuillez confirmer votre choix ?',
    'MESSAGE.UNARCHIVE.ENGAGEMENT.CONTENT':
        'Bonjour {userName}, sachez que cette action va desarchivager la mission "{engagementCode}". Veuillez confirmer votre choix ?',
    'MESSAGE.DELETE.ENGAGEMENT.TITLE': 'Veuillez confirmer votre choix!',
    'MESSAGE.DELETE.ENGAGEMENT.CONTENT':
        'Bonjour {userName}, sachez que la suppression d\'une mission entraîne la suppression de toutes ses données. Cette opération est définitive.',
    'MESSAGE.DELETE.ENGAGEMENT.COMMENT': 'Veuillez indiquer la raison de la suppression',
    'MESSAGE.VALIDATION.DISPATCH.ITEM.TYPE': 'Type de document',
    'MESSAGE.VALIDATION.DISPATCH.ITEM.COMMENT': 'Commentaire',
    'MESSAGE.VALIDATION.DISPATCH.ITEM.CONTENT':
        'Bonjour {userName}, veuillez confirmer vos modifications pour le document : {documentName}.',
    'MESSAGE.VALIDATION.DISPATCH.APPLIED.TO.FOLDER.LABEL':
        "Voulez-vous appliquer ces modifications au document ou au dossier du document ?",
    'MESSAGE.VALIDATION.DISPATCH.VALIDATION.REQUIRED_FIELD.APPLIED.TO.FOLDER':
        'Les modifications ne peuvent pas être appliquées au dossier si le document est supprimé du dossier.',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.ITEM.LABEL': 'Appliquer au Document',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.ITEM.TOOLTIP':
        'Appliquer les modifications (par exemple, le code mission, le code de confirmation et/ou le type de document) au document.',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.FOLDER.LABEL': 'Appliquer au dossier',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.FOLDER.TOOLTIP':
    'Appliquer les modifications (par exemple, le code mission, le code de confirmation et/ou le type de document) au dossier.',
    'MESSAGE.VALIDATION.DISPATCH.REMOVE.FROM.FOLDER.LABEL':
        'Voulez-vous SUPPRIMER le document de son dossier ou le laisser dans son dossier ?',
    'MESSAGE.VALIDATION.DISPATCH.VALIDATION.REQUIRED_FIELD.REMOVE.FROM.FOLDER':
        'Le document ne peut pas être retiré du dossier',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.KEEP.IN.FOLDER.LABEL': 'Laisser dans le dossier',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.KEEP.IN.FOLDER.TOOLTIP': 'Laisser le document actuel dans son dossier',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.REMOVE.FROM.FOLDER.LABEL': 'Retirer du dossier',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.REMOVE.FROM.FOLDER.TOOLTIP': 'Retirer le document actuel de son dossier',
    'INVALID.SESSION.POPUP.TITLE': 'Erreur',
    'INVALID.SESSION.FORBIDDEN.ACCESS.MESSAGE': 'Vous n\'êtes pas autorisé à accéder à ce contenu',
    'INVALID.SESSION.NOT.FOUND.MESSAGE': 'La ressource à laquelle vous essayez d\'accéder est introuvable.',
    'INVALID.SESSION.UNAUTHORISED.USER.MESSAGE':
        'Votre session a expiré, veuillez rafraîchir votre page et vous connecter à nouveau pour avoir accès à la plateforme.',
    'UNKNOWN.ERROR.MESSAGE': 'Une erreur inconnue s\'est produite, veuillez réessayer plus tard',

    'DIALOG.SECTION.TITLE': 'Information du contact',
    'DIALOG.BUTTON.CANCEL': 'Annuler',
    'DIALOG.BUTTON.SAVE': 'Sauvegarder',
    'DIALOG.BUTTON.VALIDATE': 'Valider',
    'DIALOG.BUTTON.OK': 'OK',
    'DIALOG.BUTTON.DELETE': 'Supprimer',
    'DIALOG.COMPANY.CREATE.TITLE': 'Créer une entreprise',
    'DIALOG.COMPANY.EDIT.TITLE': 'Modifier une entreprise',
    'DIALOG.CONTACT.CREATE.TITLE': 'Créer un contact',
    'DIALOG.CONTACT.EDIT.TITLE': 'Modifier un contact',
    'DIALOG.CONTACT.INVITE.TITLE': 'nviter et gérer les droits d\'accès pour {contact} {email}',
    'DIALOG.CONTACT.MANAGE.ACCESS.TITLE': 'Gérer les droits d\'accès pour {contact} {email}'
};
