import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { LostItemSetup, UserResourceSetup } from '../../../screens/setup';
import { COMPONENT_NAME } from '..';
import { configurationRepository } from '../../../kit/repository';
import { AccordionSections } from '../../../helpers/AccordionHelper';
import { getLocalizedString } from '../../../i18n/i18Helper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}));

const GeneralConfigComponent = props => {
    const classes = useStyles();
    const intl = useIntl();
    const lostItemConfigRef = useRef();
    const userResrceCreateRef = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const [isLostItemConfigValid, setIsLostItemConfigValid] = useState(false);
    const [isLostItemConfigExpanded, setIsLostItemConfigExpanded] = useState(false);

    const [isUserResourceConfigValid, setIsUserResourceConfigValid] = useState(false);
    const [isUserResourceConfigExpanded, setIsUserResourceConfigExpanded] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            // TODO: lostIemConfig and userResourceConfig are not in the WS response (no check performed by API)
            const { parsedValue } = await configurationRepository.getAll();

            if (parsedValue) {
                setIsLostItemConfigValid(parsedValue?.isLostItemConfigValid ?? false);
                setIsUserResourceConfigValid(parsedValue?.isUserResourceConfigValid ?? false);
            }
            setIsLoading(false);
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const isAllSectionsValid = isLostItemConfigValid && isUserResourceConfigValid;
        props.toggleCanHandleNext(isAllSectionsValid);
    }, [isLostItemConfigValid]); // eslint-disable-line react-hooks/exhaustive-deps

    const hasValidConfig = (componentName, value) => {
        switch (componentName) {
            case COMPONENT_NAME.LOST_ITEM_SETUP:
                setIsLostItemConfigValid(value);
                break;

            case COMPONENT_NAME.USER_RESOURCE_SETUP:
                setIsUserResourceConfigValid(value);
                break;

            default:
                break;
        }
    };

    const handleSaveLostItem = () => {
        if (lostItemConfigRef && lostItemConfigRef.current) {
            lostItemConfigRef.current.saveForm();
        }
    };

    const handlePopUpOpening = () => {
        if (userResrceCreateRef && userResrceCreateRef.current) {
            userResrceCreateRef.current.openForm();
        }
    };

    const GENERAL_CONFIG = [
        {
            isExpanded: isLostItemConfigExpanded,
            // TODO: isConfigValid is disabled to keep save button displayed, trigger mechanism required in LostItemConfig component
            isConfigValid: false, // isLostItemConfigValid,
            setExpanded: setIsLostItemConfigExpanded,
            checkboxLabel: getLocalizedString(intl, 'GENERAL.CONFIGURATION.LOST.ITEM.SUBSECTION.TITLE'),
            isSaveEnabled: true,
            saveLabel: getLocalizedString(intl, 'GENERAL.CONFIGURATION.LOST.ITEM.BUTTON.SAVE.LABEL'),
            handleSave: handleSaveLostItem,
            isCreateEnabled: false,
            component: (
                <LostItemSetup
                    hasValidConfig={hasValidConfig}
                    setIsLostItemConfigExpanded={setIsLostItemConfigExpanded}
                    lostItemConfigRef={lostItemConfigRef}
                />
            )
        },
        {
            isExpanded: isUserResourceConfigExpanded,
            isConfigValid: isUserResourceConfigValid,
            setExpanded: setIsUserResourceConfigExpanded,
            checkboxLabel: getLocalizedString(intl, 'GENERAL.CONFIGURATION.USER.RESOURCE.SUBSECTION.TITLE'),
            isSaveEnabled: false,
            isCreateEnabled: true,
            createLabel: getLocalizedString(intl, 'GENERAL.CONFIGURATION.USER.RESOURCE.BUTTON.CREATE.LABEL'),
            handleCreate: handlePopUpOpening,
            component: (
                <UserResourceSetup
                    hasValidConfig={hasValidConfig}
                    setIsUserResourceConfigExpanded={setIsUserResourceConfigExpanded}
                    userResrceCreateRef={userResrceCreateRef}
                />
            )
        }
    ];

    return (
        <>
            <div className={classes.root}>
                <AccordionSections sections={GENERAL_CONFIG} isLoading={isLoading} />
            </div>
        </>
    );
};

export const GeneralConfig = injectIntl(GeneralConfigComponent);
