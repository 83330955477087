import React from 'react';
import { SearchEngagementTab, SEARCH_FILTERS } from './SearchEngagementBar';
import { SearchEngagementTable } from './SearchEngagementTable';
import { useSearch } from '../../../hooks/useSearch';

export const SearchEngagementWrapper = props => {
    const { loading, handleSearchEngagement, handleSelectEngagement, doInitialSearch = false } = props;
    const { onSearch, filteredPaginatedItems, globalFilters, handleClear, handleFilterChange } = useSearch({
        filtersList: SEARCH_FILTERS,
        handleSearch: handleSearchEngagement
    });

    return (
        <>
            <SearchEngagementTab
                handleSearch={onSearch}
                handleClear={handleClear}
                globalFilters={globalFilters}
                doInitialSearch={doInitialSearch}
            />

            <SearchEngagementTable
                loading={loading}
                handleSelectEngagement={handleSelectEngagement}
                handleFilterChange={handleFilterChange}
                engagementsPaginated={filteredPaginatedItems}
            />
        </>
    );
};
