import { parseWithNULL } from '../parsers';
import { parseContacts } from '../contact/contactParsers';

const parseLetterHead = json => {
    return {
        engagementClientCompany: json.engagementClientCompany ? parseCompany(json.engagementClientCompany) : {},
        engagementClientCompanyId: json.engagementClientCompanyId,
        fileExtension: json.fileExtension,
        id: json.id
    };
};

export const parseCompanies = json => {
    return json ? Object.values(json).map(x => parseCompany(x)) : [];
};

export const parseCompany = json => {
    return {
        id: json.id,
        engagementId: json.engagementId,
        internalId: json.internalId,
        name: json.name,
        address: json.address,
        city: json.city,
        zipCode: json.zipCode,
        companyId: parseWithNULL(json.companyId),
        companyId2: parseWithNULL(json.companyId2),
        vatId: parseWithNULL(json.vatId),
        clusterId: json.clusterId,
        countryText: json.countryText,
        contacts: json.contacts ? parseContacts(json.contacts) : [],
        letterHead: json.letterHead ? parseLetterHead(json.letterHead) : null,
        isLetterHeadActivated: json.isLetterHeadActivated,
        isBlankLetterHead: json.isLetterHeadActivated ? !json.isLetterHeadActivated : false
    };
};
