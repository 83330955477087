import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import { Card, CardBody } from '../../partials/controls/Card';
import { FormattedMessage } from 'react-intl';

export function ArchiveMessageComponent(props) {
    const {
        user,
        handleSubmit,
        data: engagement,
        strings: { contentId }
    } = props;

    const initialValues = {
        engagement: { ...engagement }
    };

    const btnRef = useRef();
    const triggerButtonClick = () => btnRef?.current?.click();
    props.btnActionRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    return (
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={values => handleSubmit(values)}>
            {() => (
                <Form>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center" spacing={1}>
                        <Card>
                            <CardBody>
                                <FormattedMessage
                                    id={contentId}
                                    values={{
                                        userName: user?.fullname,
                                        engagementCode: engagement.engagementCode,
                                        br: <br />
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Grid>
                    <button type="submit" style={{ display: 'none' }} onClick={triggerButtonClick} ref={btnRef} />
                </Form>
            )}
        </Formik>
    );
}
