import * as React from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { UIDropDownMenu, UIDropDownMenuEntryProps } from '../../common/UIDropDownMenu';
import { setCurrentClusterId } from '../../../store/actions/cluster';
import { UIActionButton } from '../../common/UIActionButton';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { useIntl } from 'react-intl';
import { EMPTY_GUID } from '../../../kit/utils/constants';

const TitleComponent = ({ clusterId, clusters }) => {
    const intl = useIntl();
    let displayedCluster: string;
    if (!clusterId || clusterId === EMPTY_GUID) {
        displayedCluster = getLocalizedString(intl, 'TOP_BAR.CLUSTER.NAME.ALL');
    } else {
        const cluster = clusters.find(c => c.id === clusterId);
        displayedCluster = cluster?.name || clusterId;
    }

    return (
        <UIActionButton
            display="secondary"
            title={getLocalizedString(intl, 'TOP_BAR.CLUSTER.SELECTED_CLUSTER', { name: displayedCluster })}
            uppercase={false}
        />
    );
};

export const ClusterTopBarItem = () => {
    const intl = useIntl();
    const clusters = useSelector((state: any) => state.cluster.clusters);
    const dispatch = useDispatch();
    const [menuEntries, setMenuEntries] = useState<UIDropDownMenuEntryProps[]>([]);

    const currentClusterId: any = useSelector((state: any) => state.cluster.currentClusterId);

    useEffect(() => {
        const entries = clusters.reduce(
            (acc: UIDropDownMenuEntryProps[], cluster: any) => {
                return [
                    ...acc,
                    {
                        title: cluster.name,
                        id: cluster.id,
                        handler: () => handleChangeCluster(cluster)
                    }
                ];
            },
            [
                {
                    title: getLocalizedString(intl, 'TOP_BAR.CLUSTER.NAME.ALL'),
                    id: 'empty_cluster_id',
                    handler: () => handleChangeCluster(null)
                }
            ]
        );

        setMenuEntries(entries);
    }, []);

    const handleChangeCluster = (cluster: any) => {
        dispatch(setCurrentClusterId(cluster?.id || EMPTY_GUID));
    };

    return (
        <Box sx={{ marginTop: 1.5 }}>
            <UIDropDownMenu
                id="topBarOrganization"
                entries={menuEntries}
                titleComponent={() => <TitleComponent clusterId={currentClusterId} clusters={clusters} />}
            />
        </Box>
    );
};
