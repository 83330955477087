/**
 * Verify if the user has a specific profile (a user can have several profiles).
 * Function to check Bitwise flags.
 * @param {*} authorisedProfile
 * @param {*} userProfile
 */
export function isAuthorisedProfile(
    authorisedProfile: any,
    userProfile: number,
) : boolean {
    return (authorisedProfile & userProfile) === authorisedProfile;
}

/**
 * Verify if the user has a only the specified profile
 * @param authorisedProfile
 * @param userProfile 
 * @returns 
 */
export function hasOnlyProfile(
    authorisedProfile: any,
    userProfile: number,
) : boolean {
    return userProfile === authorisedProfile;
}
