import React from 'react';
import { EditCompany, initCompany } from '../../../company';
import { SearchCompanyContainer } from '../../../company/search/SearchCompanyContainer';
import { SearchCompanyWrapper } from '../../../company/search/SearchCompanyWrapper';
import { COMPANY_TYPE } from '../../../../kit/utils/constants';

export function SearchClient({
    loading,
    createCompanyAPI,
    createCompanyCallback,
    searchCompany,
    selectCompanyCallback
}) {
    return (
        <SearchCompanyContainer
            doInitialSearch={false}
            loading={loading}
            searchCompany={searchCompany}
            companyType={COMPANY_TYPE.CLIENT}
            createCompanyAPI={createCompanyAPI}
            createCompanyCallback={createCompanyCallback}
            createCompanyComponent={EditCompany}
            selectCompanyCallback={selectCompanyCallback}
            companySearchModule={SearchCompanyWrapper}
            initialCompany={{ ...initCompany, isClient: true }} //TODO remove isClient ?
            createPopupTitleId={'CREATE_CONFIRMATION.CLIENT.CREATE.COMPANY.LABEL'}
        />
    );
}
