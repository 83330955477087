export const contact = {
    'CREATE_CLIENT.SECTION.CONTACT.SUBSECTION.FORM.TITLE': 'Créer un contact',
    'CREATE_CLIENT.SECTION.CONTACT.BUTTON.NEW_CONTACT': 'Créer contact',
    'CREATE_CLIENT.SECTION.CONTACT.BUTTON.NEW_COMPANY': 'Créer entreprise',
    'CREATE_CLIENT.SECTION.CONTACT.BUTTON.EDIT_COMPANY': 'Modifier entreprise',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.INTERNALID': 'Le code client Forvis Mazars est requis',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.NAME': 'Le nom de l\'entreprise est requis',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.ADDRESS': 'L\'adresse est requise',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.CITY': 'La ville est requise',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.ZIPCODE': 'Le code postal est requis',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.COUNTRY': 'Le pays est requis',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER': 'Le code SIREN est requis',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER2': 'Le code SIRET est requis',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.VAT_ID_NUMBER': 'Le numéro de TVA intracommunautaire est requis',
    'EDIT_CLIENT.LABEL.INTERNALID': 'Code client Forvis Mazars',
    'EDIT_CLIENT.PLACEHOLDER.INTERNALID': 'code client Forvis Mazars',
    'EDIT_CLIENT.LABEL.NAME': 'Nom',
    'EDIT_CLIENT.PLACEHOLDER.NAME': 'nom de l\'entreprise',
    'EDIT_CLIENT.LABEL.ADDRESS': 'Adresse',
    'EDIT_CLIENT.PLACEHOLDER.ADDRESS': 'adresse',
    'EDIT_CLIENT.LABEL.CITY': 'Ville',
    'EDIT_CLIENT.PLACEHOLDER.CITY': 'ville',
    'EDIT_CLIENT.LABEL.ZIPCODE': 'Code Postal',
    'EDIT_CLIENT.PLACEHOLDER.ZIPCODE': 'code postal',
    'EDIT_CLIENT.LABEL.COUNTRY': 'Pays',
    'EDIT_CLIENT.PLACEHOLDER.COUNTRY': 'pays',
    'EDIT_CLIENT.LABEL.COMPANY_ID_NUMBER': 'Code SIREN',
    'EDIT_CLIENT.PLACEHOLDER.COMPANY_ID_NUMBER': 'code SIREN',
    'EDIT_CLIENT.LABEL.COMPANY_ID_NUMBER2': 'Code SIRET',
    'EDIT_CLIENT.PLACEHOLDER.COMPANY_ID_NUMBER2': 'code SIRET',
    'EDIT_CLIENT.LABEL.COMPANY_ID_NUMBER3': 'Numéro d\'identifiant d\'entreprise national 3',
    'EDIT_CLIENT.PLACEHOLDER.COMPANY_ID_NUMBER3': 'numéro d\'identifiant d\'entreprise national 3',
    'EDIT_CLIENT.LABEL.VAT_ID_NUMBER': 'Numéro de TVA intracommunautaire',
    'EDIT_CLIENT.PLACEHOLDER.VAT_ID_NUMBER': 'numéro de TVA intracommunautaire',
    'EDIT_CLIENT.LABEL.CONTACTS': 'Contacts',
    'CREATE_CONTACT.CONTACT.CREATE.SUCCESS': 'Contact créé',
    'CREATE_CONTACT.CONTACT.CREATE.ERROR': 'Échec lors de la création du contact',
    'CREATE_CONTACT.CONTACT.VALIDATION.REQUIRED_FIELD.CLIENT': 'Merci de renseigner un client',
    'CREATE_CONTACT.CONTACT.VALIDATION.REQUIRED_FIELD.THIRDPARTY': 'Merci de renseigner un tiers',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.COMPANY': 'L\'entreprise est requise',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.TITLE': 'Le titre es requis',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.JOBTITLE': 'Le nom du poste est requis',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.FIRSTNAME': 'Le prénom est requis',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.LASTNAME': 'Le nom de famille est requis',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.EMAIL': 'L\'adresse électronique est requise',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.DESCRIPTION': 'La description est requise',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.COUNTRY': 'Le pays est requis',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.CITY': 'La ville est requise',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'La langue est requise',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.DIALING_CODE': 'L\'indicatif est requis',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.PHONE': 'Le numéro de téléphone est requis',
    'EDIT_CONTACT.LABEL.COMPANY': 'Entreprise',
    'EDIT_CONTACT.PLACEHOLDER.COMPANY': 'Nom de l\'entreprise',
    'EDIT_CONTACT.LABEL.TITLE': 'Titre',
    'EDIT_CONTACT.PLACEHOLDER.TITLE': 'Mr/Mme',
    'EDIT_CONTACT.TITLE.MR': 'Mr',
    'EDIT_CONTACT.TITLE.MRS': 'Mme',
    'EDIT_CONTACT.TITLE.MISS': 'Mlle',
    'EDIT_CONTACT.TITLE.MS': 'Ms',
    'EDIT_CONTACT.TITLE.LAYER': 'Me',
    'EDIT_CONTACT.TITLE.DOCTOR': 'Dr',
    'EDIT_CONTACT.LABEL.JOBTITLE': 'Position',
    'EDIT_CONTACT.PLACEHOLDER.JOBTITLE': 'Position',
    'EDIT_CONTACT.LABEL.FULLNAME': 'Nom',
    'EDIT_CONTACT.LABEL.FIRSTNAME': 'Prénom',
    'EDIT_CONTACT.PLACEHOLDER.FIRSTNAME': 'prénom',
    'EDIT_CONTACT.LABEL.LASTNAME': 'Nom de famille',
    'EDIT_CONTACT.PLACEHOLDER.LASTNAME': 'nom de famille',
    'EDIT_CONTACT.LABEL.CITY': 'Ville',
    'EDIT_CONTACT.PLACEHOLDER.CITY': 'ville',
    'EDIT_CONTACT.LABEL.COUNTRY': 'Pays',
    'EDIT_CONTACT.PLACEHOLDER.COUNTRY': 'pays',
    'EDIT_CONTACT.LABEL.LANGUAGE': 'Langue',
    'EDIT_CONTACT.PLACEHOLDER.LANGUAGE': 'langue',
    'EDIT_CONTACT.LABEL.EMAIL': 'E-mail',
    'EDIT_CONTACT.PLACEHOLDER.EMAIL': 'contact@company.com',
    'EDIT_CONTACT.LABEL.DESCRIPTION': 'Description',
    'EDIT_CONTACT.PLACEHOLDER.DESCRIPTION': 'description',
    'EDIT_CONTACT.LABEL.DIALINGCODE': 'Indicatif',
    'EDIT_CONTACT.PLACEHOLDER.DIALINGCODE': '+33',
    'EDIT_CONTACT.LABEL.PHONE': 'Téléphone',
    'EDIT_CONTACT.PLACEHOLDER.PHONE': '0 00 00 00 00',
    'EDIT_CONTACT.LABEL.DIALINGCODE2': 'Indicatif 2',
    'EDIT_CONTACT.PLACEHOLDER.DIALINGCODE2': '+33',
    'EDIT_CONTACT.LABEL.PHONE2': 'Téléphone 2',
    'EDIT_CONTACT.PLACEHOLDER.PHONE2': '0 00 00 00 00',
    'CONTACT_SECTION.SUBSECTION.CONTACTS.CLIENT': 'Contacts de l\'entité',
    'CONTACT_SECTION.SUBSECTION.CONTACTS.THIRDPARTY': 'Contacts tiers',
    'CONTACT_SECTION.SELECT.CONTACT_COMPANY.LABEL': 'Sélectioner les contacts',
    'CONTACT_SECTION.SELECT.CONTACT.LABEL': 'Sélectioner les contacts',
    'CONTACT_SECTION.BUTTON.NEW.LABEL': 'Créer',
    'CONTACT_SECTION.BUTTON.SAVE.LABEL': 'Sauvegarder',

    'CONTACT.ACCESS.CONTACT.ERROR': 'Échec lors de l\'accès au contact',
    'CONTACT.CREATE.SUCCESS': 'Contact créé',
    'CONTACT.CREATE.ERROR': 'Échec lors de la création du contact',
    'CONTACT.UPDATE.SUCCESS': 'Contact updaté',
    'CONTACT.UPDATE.ERROR': 'Échec lors de l\'update du contact',
    'CONTACT.SECTION.BUTTON.CREATE': 'Créer contact',

    'CONTACT.INVITATION.NOTINVITED': 'Non invité',
    'CONTACT.INVITATION.NOTCONNECTED': 'Invité - jamais connecté',
    'CONTACT.INVITATION.CONNECTED': 'Invité - dernier accès {date}'
};
