export const settings = {
    'SETTINGS.TITLE': 'Settings',
    'SETTINGS.CARD.TITLE': 'Engagement {engagementName} - Settings',
    'SETTINGS.BUTTON.LABEL.BACK': 'Back',
    'SETTINGS.BUTTON.LABEL.RESET': 'Reset',
    'SETTINGS.BUTTON.LABEL.SAVE': 'Save',
    'SETTINGS.BUTTON.LABEL.NEW': 'NEW COMPANY',
    'SETTINGS.BUTTON.LABEL.ADD': 'ADD MEMBER',
    'SETTINGS.SECTION.LABEL.INFORMATION': 'INFORMATION',
    'SETTINGS.SECTION.LABEL.CLIENTS': 'ENTITIES',
    'SETTINGS.SECTION.LABEL.CONTACTS': 'CONTACTS',
    'SETTINGS.SECTION.LABEL.LETTERHEADS': 'LETTERHEADS',
    'SETTINGS.SECTION.LABEL.AUDITORS': 'AUDITORS',
    'SETTINGS.SECTION.LABEL.THIRD_PARTIES': 'THIRD-PARTIES',
    'SETTINGS.ENGAGEMENT.EDIT.VALIDATION.REQUIRED_FIELD': 'Missing information',
    'SETTINGS.ENGAGEMENT.EDIT.VALIDATION.REQUIRED_FIELD.EMAIL': 'An email is required',
    'SETTINGS.MEMBER.RIGHT.LABEL': 'Admin',
    'SETTINGS.MEMBER.RIGHT.HINT':
        'Admin member can delete other members of his team (standard or admin) and should validate client request before sending',
    'SETTINGS.MEMBER.EMAIL.PLACEHOLDER': "Add member's email",
    'SETTINGS.ACTION.ARCHIVE': 'Archive',
    'SETTINGS.ACTION.UNARCHIVE': 'Unarchive',
    'SETTINGS.SECTION.CONTACT.ACTION.INVITE': 'Save and Invite',
    'SETTINGS.SECTION.CONTACT.RIGHTS.SELECT.ALL': 'Select all',
    'SETTINGS.SECTION.CONTACT.MANAGE.ACCESS.MESSAGE':
        '{contact} has been invited to Forvis Mazars Confirmations. Use the below section to either manage its access to this engagement or revoke access to the platform',
    'SETTINGS.SECTION.CONTACT.INVITE.MESSAGE':
        "{contact} will be invited to Forvis Mazars Confirmations. Use the below section to manage their access rights to this engagement. \n Invited client will have to set up a password following the security required before access to Forvis Mazars Confirmations.'",
    'SETTINGS.SECTION.CONTACT.RESET.PASSWORD': 'Reset password',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS': 'Revoke Access',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.CURRENT.ENGAGEMENT': 'Revoke from from current engagement',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.ALL.ENGAGEMENTS': 'Revoke from all engagements',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.DIALOG.TITLE': 'Manage contact access',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.DIALOG.MESSAGE':
        'The current contact has already been invited to the platform, would you like to revoke his access from current engagement or all the other engagements where he is affected ',

    'SETTINGS.LETTERHEAD.ALERT.MISSING.MESSAGE': 'One of the entities has no letterhead, please upload one. ',
    'SETTINGS.LETTERHEAD.SECTION.CREATE.DIALOG.TITLE': 'Upload new letterhead',
    'SETTINGS.LETTERHEAD.SECTION.ALERT.SAVE.REQUIRED.MESSAGE':
        'Please save your engagement before upload new letterhead',
    'SETTINGS.LETTERHEAD.SECTION.BUTTON.NEW.LABEL': 'Create',
    'SETTINGS.LETTERHEAD.SECTION.BUTTON.CREATE': 'Upload letterhead',
    'SETTINGS.LETTERHEAD.SECTION.CREATE.SUCCESS': 'Letterhead created',
    'SETTINGS.LETTERHEAD.SECTION.CREATE.ERROR': 'Create letterhead error',
    'SETTINGS.LETTERHEAD.SECTION.DELETE.SUCCESS': 'Letterhead deleted',
    'SETTINGS.LETTERHEAD.SECTION.DELETE.ERROR': 'Delete letterhead error',
    'SETTINGS.LETTERHEAD.SECTION.CREATE.TITLE': 'Create letterhead',
    'SETTINGS.LETTERHEAD.SECTION.DETAILS.TITLE': 'Letterhead details',
    'SETTINGS.LETTERHEAD.SECTION.FETCH.ERROR': 'Letterhead file fetch error',
    'SETTINGS.LETTERHEAD.SECTION.COMPANY': 'Company',
    'SETTINGS.LETTERHEAD.SECTION.COMPANY_PLACEHOLDER': 'Company name',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.MESSAGE':
        "Please upload the entity's letterhead by following the steps below:",
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.MESSAGE.DETAIL':
        "1- Obtain a blank document (A4 format) from your client containing his company's letterhead \n2- If it is a Word document, the document should be converted into a .jpg, .jpeg, .png or .pdf format (whilst keeping an A4 size) \n3- Once the new format generated, upload the resulted document by clicking on the upload button \n4- Only documents with .jpg, .jpeg, .png or .pdf extension are accepted \n\nIf your client doesn't wish to communicate his entity's letterhead, please uncheck the checkbox `Use letterhead` \n\nFor further details, refer to the training content.",
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.SAMPLE.TITLE': 'Sample Forvis Mazars letterhead',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.ACTION.LABEL': 'Do not use letterhead',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.ENTITIES': 'Entities',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.ACTIONS': 'Actions',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.STATE': 'State',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.SUBTITLE':
        'Please upload client letterhead or check the "do not use letterhead" option for each entity',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.TITLE': 'Letterhead',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.ACTIVATE.ACTION.TOOLTIP':
        'Activate or deactivate letterhead for the generation of the confirmation letter for the entity',
    'SETTINGS.UPDATE.ENGAGEMENT.ERROR': 'Update engagement error',
    'SETTINGS.UPDATE.ENGAGEMENT.SUCCESS': 'Engagement settings updated',
    'SETTINGS.ACCESS.ENGAGEMENT.ERROR': 'Access engagement',
    'SETTINGS.ACCESS.COMPANIES.ERROR': 'Access companies error',
    'SETTINGS.CLIENT.CONTACT.INVITE.SUCCESS': 'Invitation sent with success',
    'SETTINGS.CLIENT.CONTACT.INVITE.ERROR': 'Send invitation error',
    'SETTINGS.CLIENT.CONTACT.REVOKE.ACCESS.SUCCESS': 'Access revoked with success',
    'SETTINGS.CLIENT.CONTACT.REVOKE.ACCESS.ERROR': 'Revoke access error',
    'SETTINGS.CLIENT.CONTACT.RESET.PASSWORD.SUCCESS': 'reset password with success',
    'SETTINGS.CLIENT.CONTACT.RESET.PASSWORD.ERROR': 'Reset password error',
    'SETTINGS.CLIENT.CONTACT.INVITE.LAST.CONNECTION': 'Last connection {date}'
};
