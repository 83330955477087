import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { SessionStatus } from '../../kit/utils/constants';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { confirmationCommentRepository, serializeComment } from '../../kit/repository';
import { CommentWrapper } from '../../components/confirmation/edit/sections';
import { CircularProgress } from '@mui/material';
import { fetchData } from '../../services';
import { createToast, getToastType } from '../../components/toast/Toast';

/**
 * Comment page component for user with auditor role.
 * This comment component is a subsection of the confirmation page.
 * Allow user to post and read other auditors' comments.
 */
class CommentsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            checkSessionResults: [],
            loading: false
        };
    }

    fetchComments = async () => {
        const {
            intl,
            checkSession,
            match: { params },
            addToastMessage
        } = this.props;

        const result = await confirmationCommentRepository.fetch(params.confirmationId);
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid) {
            if (result.isSuccess) {
                this.setState({
                    comments: Object.values(result.parsedValue)
                });
            } else {
                addToastMessage(
                    createToast(
                        getToastType(sessionStatus),
                        intl.formatMessage({ id: 'CONFIRMATION.ACCESS.COMMENTS.ERROR' }),
                        result.error
                    )
                );
            }
        }
    };

    postComments = async newComment => {
        const { intl, checkSession, addToastMessage } = this.props;

        this.setState({ loading: true });
        const commentSerialized = serializeComment(newComment);
        const result = await confirmationCommentRepository.create(commentSerialized);
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid) {
            if (result.isSuccess) {
                await this.fetchComments();
            } else {
                addToastMessage(
                    createToast(
                        getToastType(sessionStatus),
                        intl.formatMessage({ id: 'CONFIRMATION.ACCESS.COMMENTS.ERROR' }),
                        result.error
                    )
                );
            }
            this.setState({ loading: false });
        }
    };

    deleteComment = async commentId => {
        const { intl, checkSession, addToastMessage } = this.props;

        this.setState({ loading: true });
        const result = await confirmationCommentRepository.delete(commentId);
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid) {
            if (result.isSuccess) {
                await this.fetchComments();
            } else {
                addToastMessage(
                    createToast(
                        getToastType(sessionStatus),
                        intl.formatMessage({ id: 'CONFIRMATION.ACCESS.COMMENTS.ERROR' }),
                        result.error
                    )
                );
            }
            this.setState({ loading: false });
        }
    };

    async componentDidMount() {
        await fetchData(this, [this.fetchComments]);
    }

    render() {
        const {
            intl,
            match: { params }
        } = this.props;
        const { loading } = this.state;
        return loading ? (
            <CircularProgress />
        ) : (
            <CommentWrapper
                intl={intl}
                loading={this.state.loading}
                confirmationId={params.confirmationId}
                comments={this.state.comments}
                postComments={this.postComments}
                deleteComment={this.deleteComment}
            />
        );
    }
}

CommentsComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    comments: PropTypes.array,
    checkSessionResults: PropTypes.array
};

CommentsComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
    };
};

export const Comment = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(CommentsComponent));
