import React from 'react';
import { useHistory } from 'react-router-dom';
import { EngagementTableComponent } from '../engagement';

import { ROUTES } from '../../navigation/Routes';
import { HOME_COLUMNS_FILTERS } from './Constants';
import { HomeToolbar } from './HomeToolbar';
import { useFilterTable } from '../../hooks/useFilterTable';
import { useIntl } from 'react-intl';

export function AuditorHomeContent(props) {
    const {
        getDownloadEngagementOptions,
        archiveEngagement,
        resetEngagement,
        filters: { isArchived },
        loading,
        engagementsPaginated,
        filters,
        deleteEngagement,
        handleDownloadEngagement,
        handleFilterChange,
        user
    } = props;

    const intl = useIntl();
    const history = useHistory();

    const { isFilteringMode, filterableColumnsValues, onFilterTableChanged, onFilterClicked } = useFilterTable(
        HOME_COLUMNS_FILTERS,
        handleFilterChange
    );

    const handleArchiveViewChange = () => {
        handleFilterChange({ isArchived: !isArchived });
    };

    const handleCreateEngagement = () => {
        // first reset the redux newEngagement
        resetEngagement();
        history.push(ROUTES.ENGAGEMENT_CREATE);
    };

    return (
        <>
            <HomeToolbar
                handleArchiveViewChange={handleArchiveViewChange}
                isArchiveMode={isArchived}
                isFilteringMode={isFilteringMode}
                onFilterClicked={onFilterClicked}
                handleCreateEngagement={handleCreateEngagement}
            />

            <EngagementTableComponent
                user={user}
                archiveEngagement={archiveEngagement}
                getDownloadEngagementOptions={getDownloadEngagementOptions}
                resetEngagement={resetEngagement}
                intl={intl}
                loading={loading}
                engagementsPaginated={engagementsPaginated}
                filters={filters}
                deleteEngagement={deleteEngagement}
                handleDownloadEngagement={handleDownloadEngagement}
                handleFilterChange={handleFilterChange}
                isFilteringMode={isFilteringMode}
                columnsFiltersValues={filterableColumnsValues}
                handleFilterEngagementsChange={onFilterTableChanged}
            />
        </>
    );
}
