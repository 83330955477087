import React, { useEffect, useRef, useState } from 'react';
import { FilterTableSpec } from '../../../hooks/useFilterTable';
import { ENGAGEMENT_COLUMNS_FILTERS } from '../../engagement/InitEngagement';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { FilterRowContainer } from '../../common/filtering/FilterRowContainer';
import { Check as CheckIcon, CloseTwoTone, CloudOffOutlined, CompareArrows, PlayArrow } from '@mui/icons-material';
import { UserAvatarComponent } from '../../user/UserAvatar';
import { MZRTableActionIcon } from '../../common/MZRTableActionIcon';
import {
    CLIENT_CONFIRMATION_STEP_TYPE,
    ClientRequest,
    FILTER_COMPONENT,
    UploadDocumentSpec
} from '../../../types/types';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { useRefuseConfirmation } from '../../../hooks/useEntityActionPopup';
import { UploadDocumentsComponent } from '../../common/UploadDocumentsComponent';
// import { useDocument } from '../../../hooks/useDocument';
import { MZRPaginatedTable } from '../../common/MZRPaginatedTable';
import { ClientRequestSignatureCarousel, ClientRequestSteps } from '..';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { UIDialog } from '../../common/UIDialog';
import { ServiceResult } from '../../../kit/network/ServiceResult';
import {
    buildFilters,
    FORM_FIELD,
    getVisibilityIcon,
    SignatureIcon,
    UPLOAD_TYPES,
    hasLetterhead,
    hasThirdParty,
    hasThirdPartyContacts,
    ClientRequestStatusOptions,
    getDownloadOptions,
    isSignableRequest,
    LetterheadOptions,
    requestActionsReducer
} from '../../../helpers';
import { ClientRequestStatusIcon } from './ClienRequestStatusIcon';
import './ClientRequests.css';
import { UIDropDownMenu } from '../../common/UIDropDownMenu';
import { UIText } from '../../common/UIText';
import { UITooltip } from '../../common/UITooltip';
import { Palette } from '../../../styles/colors';

interface ClientRequestsContentProps {
    user: any;
    paginatedRequests: any;
    engagement: any;
    handleFilterChange: (values: Record<string, any>) => void;
    confirmationCategories: any;
    filterManager: FilterTableSpec;
    refuseRequest: (request: any) => void;
    fetchConfirmationDocument: (id: string, filters: Record<string, any>) => Promise<any>;
    fetchConfirmationDocumentInfo: (id: string) => Promise<any>;
    uploadLetter: (values: UploadDocumentSpec) => Promise<boolean>;
    setAdditionalActions: React.Dispatch<boolean>;
    isSignatureAsked: boolean;
    fetchRequestDetails: (requestId: string) => Promise<any | undefined>;
    fetchRequests: () => Promise<any>;
    saveRequestChanges: (request: ClientRequest) => Promise<ServiceResult>;
    confirmRequest: (request: ClientRequest) => Promise<ServiceResult>;
}

export const ClientRequestsContent = ({
    user,
    paginatedRequests,
    engagement,
    handleFilterChange,
    confirmationCategories,
    filterManager,
    refuseRequest,
    fetchConfirmationDocument,
    fetchConfirmationDocumentInfo,
    uploadLetter,
    setAdditionalActions,
    setLoading,
    setSessionExpired,
    setToast,
    saveRequestChanges,
    confirmRequest,
    fetchRequests,
    isSignatureAsked
}: ClientRequestsContentProps & UIScaffoldChildContext) => {
    const intl = useIntl();
    const uploadBtnRef = useRef();
    // the request that is selected by an action of a row
    const [currentRequest, setCurrentRequest] = useState<ClientRequest | null>(null);
    // the requests that are selected by the checkboxes of the table
    const [selectedRequests, setSelectedRequests] = useState<ClientRequest[]>([]);
    const [isCompleteRequestOpen, setIsCompleteRequestOpen] = useState<boolean>(false);
    const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
    const [isSignOpen, setIsSignOpen] = useState<boolean>(isSignatureAsked);

    useEffect(() => {
        setCurrentRequest(paginatedRequests?.items?.find(req => req.id === currentRequest?.id));
    }, [paginatedRequests]);

    const { refuse: RefuseRequestComponent, handleRefuseClick } = useRefuseConfirmation({
        user: user,
        refuseAPI: refuseRequest,
        refuseCallback: (isSuccess: boolean) => {
            // trigger refresh page after refuse ({} doesn't reinitialize filters @see the implementation of handleFilterChange)
            isSuccess && handleFilterChange({});
        }
    });

    //TODO replace with DocumentPreview once we develop client access screens
    /*
    const { documentPreviewClick, DocumentPreviewRender, downloadDocument } = useDocument({
        intl: intl,
        fetchDocumentFileAPI: fetchConfirmationDocument,
        fetchDocumentInfoAPI: fetchConfirmationDocumentInfo
    });*/

    useEffect(() => {
        if (!isSignOpen && isSignatureAsked) setIsSignOpen(isSignatureAsked);
    }, [isSignatureAsked]);

    const columns = [
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.SELECT,
                    placeholder: intl.formatMessage({
                        id: 'CLIENT.ENGAGEMENT.TABLE.TITLE.STATUS'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.CLIENT_REQUEST_STATUS,
                    filters: buildFilters(ClientRequestStatusOptions, (option: any) =>
                        getLocalizedString(intl, option.label)
                    )
                }
            ],
            title: intl.formatMessage({ id: 'CLIENT.ENGAGEMENT.TABLE.TITLE.STATUS' }),
            field: 'status',
            render: (rowData: ClientRequest) => (
                <UITooltip
                    title={rowData.clientRequestStatus}
                    children={<ClientRequestStatusIcon status={rowData.clientRequestStatus} />}
                />
            ),
            cellStyle: { textAlign: 'center' }
        },

        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.SELECT,
                    placeholder: intl.formatMessage({
                        id: 'ENGAGEMENT.TABLE.FILTER.CLIENT.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.CLIENT,
                    filters: buildFilters(
                        engagement?.clientCompanies,
                        (company: any) => `${company.name} | ${company.zipCode} ${company.city}`
                    )
                }
            ],
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.CLIENT' }),
            field: 'clientCompany',
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) =>
                rowData?.clientCompany?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.PLACEHOLDER.CLIENT" />
        },
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.INPUT,
                    placeholder: intl.formatMessage({
                        id: 'CLIENT.ENGAGEMENT.TABLE.FILTER.CONFIRMATION.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.CONFIRMATION_INFO
                }
            ],
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.CONFIRMATION' }),
            field: 'confirmationInfo',
            cellStyle: { textAlign: 'center' }
        },
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.SELECT,
                    placeholder: intl.formatMessage({
                        id: 'ENGAGEMENT.TABLE.FILTER.CATEGORY.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.CATEGORY,
                    filters: buildFilters(confirmationCategories, (category: any) => `${category.name} `)
                }
            ],
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.CATEGORY' }),
            field: 'confirmationCategory',
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) =>
                rowData?.confirmationCategory?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.TITLE.UNKNOWN_CATEGORY" />
        },
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.SELECT,
                    placeholder: intl.formatMessage({
                        id: 'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.LETTERHEAD,
                    filters: buildFilters(LetterheadOptions, (option: any) => getLocalizedString(intl, option.label))
                }
            ],
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.LETTERHEAD' }),
            field: 'letterHead',
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) =>
                rowData.clientCompany?.letterHead ? (
                    <CheckIcon color="primary" fontSize="medium" />
                ) : (
                    <CloudOffOutlined color="primary" fontSize="medium" />
                )
        },
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.INPUT,
                    placeholder: intl.formatMessage({
                        id: 'ENGAGEMENT.TABLE.FILTER.THIRD_PARTY.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY
                }
            ],
            title: intl.formatMessage({ id: 'CLIENT.ENGAGEMENT.TABLE.TITLE.THIRD_PARTY' }),
            field: 'thirdPartyCompany',
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) =>
                rowData?.thirdPartyCompany?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.PLACEHOLDER.THIRD_PARTY" />
        },

        {
            title: intl.formatMessage({ id: 'CLIENT.ENGAGEMENT.TABLE.TITLE.THIRD_PARTY.CONTACTS' }),
            field: 'thirdPartyContacts',
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) => <UserAvatarComponent data={rowData?.thirdPartyContacts ?? []} max={4} />
        },
        {
            title: intl.formatMessage({ id: 'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.CONTACTS' }),
            field: 'clientContacts',
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) => <UserAvatarComponent data={rowData?.clientContacts ?? []} max={4} />
        },
        {
            title: intl.formatMessage({ id: 'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.SIGNATORY' }),
            field: 'signatory',
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) =>
                rowData?.clientContacts
                    .filter((contact: any) => contact.isSignatory)
                    .map((contact: any) => <UIText text={`${contact.fullname} ${contact.jobTitle ?? ''}`} />)
        },
        {
            title: intl.formatMessage({ id: 'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.DUE_DATE' }),
            field: 'clientDueDate',
            type: 'date',
            grouping: false,
            render: (rowData: any) => (
                <FormattedTime value={rowData.clientDueDate} year="numeric" month="short" day="numeric" />
            ),

            cellStyle: { textAlign: 'center' }
        }
    ];

    const actions = [
        {
            position: 'row',
            icon: 'sign',
            onClick: (_: any, rowData: any) => handleSign(rowData),
            tooltip: intl.formatMessage({ id: 'DOCUMENT.TABLE.ACTION.TOOLTIP.SIGN' })
        },
        {
            position: 'row',
            icon: 'open',
            onClick: (_: any, rowData: any) => handlePreview(rowData),
            tooltip: intl.formatMessage({ id: 'DOCUMENT.TABLE.ACTION.TOOLTIP.OPEN' })
        },
        {
            position: 'row',
            icon: 'complete',
            onClick: (_: any, rowData: any) => completeAsked(rowData),
            tooltip: intl.formatMessage({ id: 'CLIENT.ENGAGEMENT.TABLE.ACTION.TOOLTIP.COMPLETE.TASKS' })
        },
        {
            position: 'row',
            icon: 'refuse',
            onClick: (_: any, rowData: any) => refuseAsked(rowData),
            tooltip: intl.formatMessage({ id: 'CLIENT.ENGAGEMENT.TABLE.ACTION.TOOLTIP.REFUSE' })
        },
        {
            position: 'row',
            icon: 'download'
        }
    ];

    const components = {
        FilterRow: (rowProps: any) => (
            <FilterRowContainer
                rowProps={rowProps}
                handleFilterChange={filterManager.onFilterTableChanged}
                selectedFilters={filterManager.filterableColumnsValues}
            />
        ),
        Action: (props: any) => {
            const { action, data } = props;
            let actionInfo = action;
            if (typeof action.action === 'function') {
                actionInfo = action.action(data);
            }

            const availableActions = requestActionsReducer(data as ClientRequest, user);
            const isHidden = !availableActions.includes(actionInfo.icon);

            switch (actionInfo.icon) {
                case 'sign':
                    return (
                        <MZRTableActionIcon
                            data={data}
                            {...actionInfo}
                            icon={<img src={SignatureIcon} alt="signature" style={{ height: '20px', width: '20px' }} />}
                            hidden={isHidden}
                        />
                    );

                case 'open':
                    return (
                        <MZRTableActionIcon
                            data={data}
                            {...actionInfo}
                            icon={getVisibilityIcon(data)}
                            hidden={isHidden}
                        />
                    );

                case 'complete':
                    return (
                        <MZRTableActionIcon
                            data={data}
                            {...actionInfo}
                            icon={<PlayArrow color="primary" />}
                            hidden={isHidden}
                        />
                    );

                case 'refuse':
                    return (
                        <MZRTableActionIcon
                            data={data}
                            {...actionInfo}
                            icon={<CloseTwoTone sx={{ color: Palette.red }} />}
                            hidden={isHidden}
                        />
                    );

                case 'download':
                    return (
                        <MZRTableActionIcon
                            data={data}
                            {...actionInfo}
                            icon={
                                <UIDropDownMenu
                                    id="Download"
                                    entries={getDownloadOptions(intl, data, handleDownload, uploadAsked)}
                                    titleComponent={() => <CompareArrows color="primary" />}
                                />
                            }
                            hidden={isHidden}
                        />
                    );

                default:
                    return null;
            }
        }
    };

    const uploadComplementaryForm = {
        fields: [
            {
                id: 'comment',
                fieldType: FORM_FIELD.TEXT_FIELD,
                labelItem: {
                    id: 'CLIENT.ENGAGEMENT.REFUSE.REQUEST.COMMENT'
                },
                component: 'textarea',
                rows: 6,
                variant: 'outlined'
            }
        ],
        validationSchema: {}
    };

    /// Preview request document
    const handlePreview = async (data: ClientRequest) => {
        // data.letterItemId && (await documentPreviewClick(data.letterItemId));
    };

    ///  Upload document request
    const uploadAsked = (request: any) => {
        setCurrentRequest(request);
        setIsUploadOpen(true);
    };

    const handleUpload = async (values: UploadDocumentSpec) => {
        const isSuccess = await uploadLetter({ ...values, id: currentRequest?.id });
        setIsUploadOpen(!isSuccess);
    };

    const handleTriggerUpload = () => {
        // @ts-ignore
        uploadBtnRef?.current?.saveForm();
    };

    /// Download request document
    const handleDownload = async (data: ClientRequest) => {
        //data.letterItemId && (await downloadDocument(data.letterItemId));
    };

    /// Complete the steps of the request
    const completeAsked = (data: ClientRequest) => {
        setCurrentRequest(data);
        setIsCompleteRequestOpen(true);
    };

    const handleSaveRequest = async (request: ClientRequest) => {
        const { isSuccess } = await saveRequestChanges({ ...request, clientDueDate: request.thirdPartyDueDate }); //TODO hard coded, because clientDueDate in not in the initial model
        return isSuccess;
    };

    const handleConfirmRequest = async (request: ClientRequest) => {
        const { isSuccess } = await confirmRequest(request);
        setIsCompleteRequestOpen(!isSuccess);
    };

    /// Refuse a request
    const refuseAsked = (data: ClientRequest) => {
        setCurrentRequest(data);
        handleRefuseClick();
    };

    const handleRequestUpdate = (values: any) => {
        setCurrentRequest({ ...currentRequest, ...values });
    };

    /// Sign the requests documents
    const handleSign = (data: ClientRequest) => {
        setSelectedRequests([data]);
        setIsSignOpen(true);
    };

    const onSelectionChange = (rows: (ClientRequest & { tableData: { checked: boolean } })[]) => {
        // trick to fix the bug of select all selects checkbox that selects all the rows even the disabled ones
        //filter manually the requests we can sign and the user checked
        const selectableRows: ClientRequest[] = rows.filter(
            row => isSignableRequest(row as ClientRequest, user) && row.tableData.checked
        );

        setSelectedRequests(selectableRows);
        setAdditionalActions(selectableRows.length > 0);
    };

    const handleSaveItem = () => {
        alert('save item changes like signature');
    };

    return (
        <div className="ClientRequests">
            <MZRPaginatedTable
                selectionOptions={{
                    showSelectAllCheckbox: true,
                    selection: true,
                    selectionProps: (rowData: any) => ({
                        disabled: !isSignableRequest(rowData, user)
                    }),
                    headerSelectionProps: { disabled: false }
                }}
                onSelectionChange={onSelectionChange}
                actions={actions}
                isFiltering={filterManager.isFilteringMode}
                columns={columns}
                onRowClick={undefined}
                loading={false}
                data={paginatedRequests}
                handleFilterChange={handleFilterChange}
                components={components}
            />

            <RefuseRequestComponent request={currentRequest} />

            {/*<DocumentPreviewRender isDownloadable isEditable={false} children={<></>} />*/}

            {isCompleteRequestOpen && currentRequest && (
                <UIDialog
                    open={isCompleteRequestOpen}
                    onClose={() => setIsCompleteRequestOpen(!isCompleteRequestOpen)}
                    titleId="CLIENT.ENGAGEMENT.COMPLETE.DIALOG.TITLE">
                    <ClientRequestSteps
                        fetchRequests={fetchRequests}
                        handleRequestUpdate={handleRequestUpdate}
                        request={currentRequest}
                        steps={[
                            {
                                type: CLIENT_CONFIRMATION_STEP_TYPE.THIRD_PARTY,
                                isActive: true,
                                isCompleted: hasThirdParty(currentRequest) && hasThirdPartyContacts(currentRequest),
                                title: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.COMPLETE.THIRD_PARTY.SECTION')
                            },
                            {
                                type: CLIENT_CONFIRMATION_STEP_TYPE.COMPLEMENTARY_INFORMATION,
                                isActive: false, // is active when there is complementary info to add
                                isCompleted: true,
                                title: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.COMPLETE.COMPLEMENTARY_INFO.SECTION')
                            },
                            {
                                type: CLIENT_CONFIRMATION_STEP_TYPE.LETTERHEAD,
                                isActive: true,
                                isCompleted: hasLetterhead(currentRequest),
                                title: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.COMPLETE.LETTERHEAD.SECTION')
                            },
                            {
                                type: CLIENT_CONFIRMATION_STEP_TYPE.CONFIRM,
                                isCompleted: true,
                                isActive: true,
                                title: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.COMPLETE.VALIDATE.SECTION')
                            }
                        ]}
                        setLoading={setLoading}
                        setToast={setToast}
                        setSessionExpired={setSessionExpired}
                        handleSaveChanges={handleSaveRequest}
                        handleConfirmRequest={handleConfirmRequest}
                    />
                </UIDialog>
            )}

            {isUploadOpen && (
                <UIDialog
                    open={isUploadOpen}
                    onClose={() => setIsUploadOpen(!isUploadOpen)}
                    onSave={handleTriggerUpload}
                    titleId="CLIENT.ENGAGEMENT.UPLOAD.DIALOG.TITLE">
                    <UploadDocumentsComponent
                        isWrappedInCard={false}
                        filesInputProps={{
                            acceptedFiles: UPLOAD_TYPES.EXTERNAL_CLIENT,
                            showPreviews: true,
                            filesLimit: 1
                        }}
                        triggerButtonRef={uploadBtnRef}
                        handleUpload={handleUpload}
                        isSubmitOnChange={false}
                        complementaryForm={uploadComplementaryForm}
                    />
                </UIDialog>
            )}

            {isSignOpen && (
                <UIDialog
                    titleId="CLIENT.ENGAGEMENT.SIGN.DIALOG.TITLE"
                    open={isSignOpen}
                    onSave={handleSaveItem}
                    onClose={() => setIsSignOpen(!isSignOpen)}>
                    <ClientRequestSignatureCarousel
                        fetchDocumentFileAPI={fetchConfirmationDocument}
                        requests={selectedRequests}
                        handleDownload={handleDownload}
                        handleRefuse={refuseAsked}
                    />
                </UIDialog>
            )}
        </div>
    );
};
