import { appApiUrl } from '../../config/appConfiguration';
import { serializeUserResource } from '../serializers';
import { parseUserResources } from './userParsers';
import { Repository } from '../Repository';

class UserResourceRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetch = async () => {
        let result = await this.network.get(`userResource`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseUserResources);
        return result;
    };

    create = async (resource) => {
        let formData = new FormData();
        const serializedResource = serializeUserResource(resource);
        formData.append('Json', JSON.stringify(serializedResource));
        formData.append('File', resource.file[0]);

        return await this.network.post(`userResource`, formData, {
            headers: { 
                ...this.buildHeaders(true),
                mode: 'no-cors'
            }
        });
    };

    fetchFile = async (resourceId) => {
        return await this.network.get(`userResource/${resourceId}/file`, {
            headers: {
                ...this.buildHeaders(),
                Accept: 'application/pdf'
            }
        });
    };

    update = async (resourceId, resource) => {
        let formData = new FormData();
        const serializedRessource = serializeUserResource(resource);
        formData.append('Json', JSON.stringify(serializedRessource));
        formData.append('File', resource.file[0]);

        return await this.network.put(`userResource/${resourceId}`, formData, {
            headers: { 
                ...this.buildHeaders(true),
                mode: 'no-cors'
            }
        });
    };

    delete = async (resourceId) => {
        return await this.network.delete(`userResource/${resourceId}`, null, {
            headers: this.buildHeaders()
        });
    };
}

export const userResourceRepository = new UserResourceRepository();
