import { readableDate } from '../utils/DateUtil';

export const serializeComment = comment => {
    return {
        ConfirmationId: comment.confirmationId,
        ParentCommentId: comment.parentCommentId,
        Text: comment.text,
        IsPrivate: comment.isPrivate
    };
};

export const serializeEngagementCompany = company => {
    return {
        EngagementId: company.engagementId,
        Id: company.id,
        InternalId: company.internalId,
        Name: company.name,
        Address: company.address,
        City: company.city,
        ZipCode: company.zipCode,
        CompanyId: company.companyId,
        CompanyId2: company.companyId2,
        vatId: company.vatId,
        CountryText: company.countryText,
        ClusterId: company.clusterId,
        Contacts: company.contacts ? company.contacts.map(x => serializeContact(x)) : [],
        IsLetterHeadActivated: company.isLetterHeadActivated
    };
};

export const serializeEngagementCompaniesToDelete = companies => {
    return companies ? companies.map(x => x.id) : [];
};

export const serializeCompany = company => {
    return {
        InternalId: company.internalId,
        Name: company.name,
        Address: company.address,
        City: company.city,
        ZipCode: company.zipCode,
        CompanyId: company.companyId,
        CompanyId2: company.companyId2,
        VatId: company.vatId,
        CountryText: company.countryText,
        ClusterId: company.clusterId,
        Contacts: company.contacts ? company.contacts.map(x => serializeContact(x)) : []
    };
};

export const serializeCompaniesTemplateRequest = params => {
    return {
        FileName: params.fileName,
        WorksheetName: params.worksheetName,
        InternalId: params.internalIdColumnIndex,
        InternalIdComment: params.internalIdColumnComment,
        Name: params.nameColumnIndex,
        NameComment: params.nameColumnComment,
        Address: params.addressColumnIndex,
        AddressComment: params.addressColumnComment,
        ZipCode: params.zipCodeColumnIndex,
        ZipCodeComment: params.zipCodeColumnComment,
        City: params.cityColumnIndex,
        CityComment: params.cityColumnComment,
        CountryText: params.countryTextColumnIndex,
        CountryTextComment: params.countryTextColumnComment,
        CompanyId: params.companyIdColumnIndex,
        CompanyIdComment: params.companyIdColumnComment,
        CompanyId2: params.companyId2ColumnIndex,
        CompanyId2Comment: params.companyId2ColumnComment,
        VatId: params.vatIdColumnIndex,
        VatIdComment: params.vatIdColumnComment
    };
};

export const serializeCompaniesUpload = file => {
    return {
        WorksheetName: file.worksheetName,
        InternalIdColumnIndex: file.internalIdColumnIndex,
        NameColumnIndex: file.nameColumnIndex,
        AddressColumnIndex: file.addressColumnIndex,
        ZipCodeColumnIndex: file.zipCodeColumnIndex,
        CityColumnIndex: file.cityColumnIndex,
        CountryTextColumnIndex: file.countryTextColumnIndex,
        CompanyIdColumnIndex: file.companyIdColumnIndex,
        CompanyId2ColumnIndex: file.CompanyId2ColumnIndex,
        VatIdColumnIndex: file.vatIdColumnIndex
    };
};

export const serializeConfirmation = confirmation => {
    return {
        Id: confirmation.id,
        Name: confirmation.name,
        Description: confirmation.description,
        ClusterId: confirmation.clusterId,
        LanguageId: confirmation.languageId,
        EngagementId: confirmation.engagementId,

        PeriodStartDate: readableDate(confirmation.periodStartDate),
        PeriodEndDate: confirmation.periodEndDate,
        ClientDueDate: readableDate(confirmation.clientDueDate),
        AutomaticClientSending: confirmation.automaticClientSending,
        ThirdPartyDueDate: readableDate(confirmation.thirdPartyDueDate),
        AutomaticThirdPartySending: confirmation.automaticThirdPartySending,
        LetterTemplateId: confirmation.letterTemplateId,

        EngagementThirdPartyCompany: confirmation?.thirdPartyCompany
            ? serializeEngagementCompany(confirmation.thirdPartyCompany)
            : null,
        EngagementThirdPartyCompanyId: confirmation?.thirdPartyCompany?.id ?? confirmation?.thirdPartyCompanyId,
        ThirdPartyContacts: confirmation?.thirdPartyContacts
            ? confirmation.thirdPartyContacts.map(contact => serializeConfirmationContact(contact))
            : [],
        EngagementClientCompanyId: confirmation?.clientCompany?.id ?? confirmation?.clientCompanyId,
        ClientContacts: confirmation?.clientContacts
            ? confirmation.clientContacts.map(contact => serializeConfirmationContact(contact))
            : [],
        Tags: confirmation.optionalFields
            ? confirmation.optionalFields.map(x => serializeConfirmationOptionalField(x))
            : [],
        activeStackedWorkflowActions: confirmation.activeStackedWorkflowActions
            ? Object.values(confirmation.activeStackedWorkflowActions).map(actionId => ({ WorkflowActionId: actionId }))
            : []
    };
};

export const serializeConfirmationContact = contact => {
    return {
        ContactId: contact.id,
        ConfirmationId: contact.confirmationId,
        IsSignatory: contact.isSignatory,
        IsRecipient: contact.isRecipient
    };
};

export const serializeConfirmationOptionalField = tag => {
    return {
        TagId: tag.id,
        Value: tag.value
    };
};

export const serializeConfirmations = confirmations => {
    return confirmations ? Object.values(confirmations).map(x => serializeConfirmation(x)) : [];
};

export const serializeImportedConfirmation = confirmation => {
    return {
        Name: confirmation?.name?.value,
        Description: confirmation?.description?.value,
        ClientSendDate: readableDate(confirmation?.clientSendDate?.value),
        ClientDueDate: readableDate(confirmation?.clientDueDate?.value),
        AutomaticClientSending: confirmation?.automaticClientSending?.value,
        ThirdPartyDueDate: readableDate(confirmation?.thirdPartyDueDate?.value),
        AutomaticThirdPartySending: confirmation?.automaticThirdPartySending?.value,
        ConfirmationTypeId: confirmation?.confirmationTypeId ? confirmation?.confirmationTypeId : 0,
        ConfirmationTemplateId: confirmation?.confirmationTemplateId ? confirmation?.confirmationTemplateId : 0,
        ThirdPartyCompanyId: confirmation?.thirdPartyCompanyId ? confirmation?.thirdPartyCompanyId : 0,
        EngagementId: confirmation?.engagementId ? confirmation?.engagementId : 0,
        ClientCompanyId: confirmation?.clientCompanyId ? confirmation?.clientCompanyId : null,
        ClusterId: confirmation?.clusterId ? confirmation?.clusterId : null,
        LanguageId: confirmation?.languageId ? confirmation?.languageId : 0,
        ClientContactIds: [],
        ThirdPartyContactIds: []
    };
};

export const serializeImportedConfirmations = confirmations => {
    return confirmations ? Object.values(confirmations).map(x => serializeImportedConfirmation(x)) : [];
};

export const serializeUploadConfirmation = confirmation => {
    return {
        ConfirmationTypeId: confirmation.confirmationTypeId,
        ConfirmationTemplateId: confirmation.confirmationTemplateId,
        ClientCompanyId: confirmation.clientCompanyId,
        EngagementId: confirmation.engagementId,
        EngagementClientId: confirmation.engagementClientId,
        SheetName: confirmation.sheetName,
        Name: confirmation.name,
        Description: confirmation.description,
        Accounts: confirmation.accounts,
        ClientSendDate: readableDate(confirmation.clientSendDate),
        ClientDueDate: readableDate(confirmation.clientDueDate),
        AutomaticClientSending: confirmation.automaticClientSending,
        ThirdPartyDueDate: readableDate(confirmation.thirdPartyDueDate),
        AutomaticThirdPartySending: confirmation.automaticThirdPartySending,
        ClientContactsEmails: confirmation.clientContactsEmails,
        ThirdPartyContactsEmails: confirmation.thirdPartyContactsEmails
    };
};

export const serializeConfirmationTemplate = template => {
    return {
        Name: template.name,
        Description: template.description,
        ConfirmationTypeId: template.confirmationTypeId,
        ClusterId: template.clusterId,
        Archived: template.archived,
        LanguageId: template.languageId
    };
};

export const serializeConfirmationType = type => {
    return {
        Name: type.name,
        StepIds: type.StepIds ? type.StepIds : []
    };
};

//TODO clean up
export const serializeContact = contact => {
    return {
        Id: contact?.id,
        EngagementCompanyId: contact.engagementCompanyId,
        ClusterId: contact.clusterId,
        Title: contact.title,
        JobTitle: contact.jobTitle,
        FirstName: contact.firstName,
        LastName: contact.lastName,
        Email: contact.email,
        LanguageId: contact.languageId,
        DialingCode: contact.dialingCode,
        Phone: contact.phone,
        Description: contact.description,
        IsSignatory: contact.isSignatory,
        IsRecipient: contact.isRecipient,
        InvitationStatus: contact.invitationStatus,
        AccessRights: contact.accessRights
    };
};

export const serializeItem = item => {
    return {
        ParentItemId: item.parentItemId,
        Name: item.name,
        Description: item.description,
        EngagementId: item.engagementId,
        ConfirmationId: item.confirmationId,
        LanguageId: item.languageId,
        ClusterId: item.clusterId,
        Type: item.type,
        DocumentType: item.documentType,
        FileHash: item.fileHash,
        Source: item.source,
        PaperSendingStatus: item.paperSendingStatus,
        Emitter: item.emitter,
        WorkflowTrack: item.workflowTrack,
        ErrorType: item.errorType,
        Comment: item.comment,
        EmailFrom: item.emailFrom,
        EmailTo: item.emailTo,
        EmailCc: item.emailCc,
        EmailBcc: item.emailBcc,
        ChildItems: serializeItems(item.childItems),
        IsChildItem: item.isChildItem,
        UserProfile: item.userProfile,
        IsLost: item.isLost,
        IsTrashed: item.isTrashed,
        IsInvalidAddress: item.isInvalidAddress,
    };
};

export const serializeItems = items => {
    return items ? items.map(x => serializeItem(x)) : [];
};

export const serializeEmailTemplate = template => {
    return {
        Name: template.name,
        EmailTypeId: template.emailTypeId,
        ConfirmationCategoryId: template.confirmationCategoryId,
        Description: template.description,
        Subject: template.subject,
        Body: template.body,
        ClusterId: template.clusterId,
        LanguageId: template.languageId,
        Archived: template.archived
    };
};

export const serializeEmailTemplates = templates => {
    return templates ? Object.values(templates).map(x => serializeEmailTemplate(x)) : [];
};

export const serializeEngagement = engagement => {
    return {
        Name: engagement.name,
        Description: engagement.description,
        engagementCode: engagement.engagementCode,
        OpeningDate: readableDate(engagement.openingDate),
        ClosingDate: readableDate(engagement.closingDate),
        Archived: engagement.archived,
        EngagementClientCompanies: engagement.clientCompanies
            ? engagement.clientCompanies.map(company =>
                  serializeEngagementCompany({
                      ...company,
                      engagementId: engagement.id,
                      id: company.isTemporaryId ? null : company.id // case of uuid generated locally to manage contacts companies, see useEngagement
                  })
              )
            : [],
        AppUserEngagementRequests: engagement.appUserEngagements
            ? engagement.appUserEngagements.map(x => serializeEngagementMember(x))
            : [],

        parentId: engagement.parentId,
        mandateId: engagement.mandateId,
        copyLetterheads: engagement.copyLetterheads,
        copyThirdParties: engagement.copyThirdParties,
        clusterId: engagement.clusterId
    };
};

export const serializeEngagementMember = member => {
    return {
        Email: member.email,
        EngagementRole: member.engagementRole
    };
};

export const serializeLetterTemplate = template => {
    return {
        Id: template.id,
        Name: template.name,
        Description: template.description,
        ConfirmationCategoryId: template.confirmationCategoryId,
        WorkflowId: template.workflowId,
        ClusterId: template.clusterId,
        LanguageId: template.languageId,
        IsActive: true,
        Tags: template?.tags ? Object.values(template?.tags).map(x => serializeLetterTemplateTag(x)) : []
    };
};

export const serializeLetterTemplateTag = tag => {
    return {
        TagId: tag.id,
        IsMandatory: tag.isMandatory
    };
};

export const serializeMailingConfiguration = config => {
    return {
        clusterId: config.clusterId,
        administrators: config.administrators,
        inboxFolder: config.inboxFolder,
        sentItemsFolder: config.sentItemsFolder,
        processingInProgressFolder: config.processingInProgressFolder,
        processedFolder: config.processedFolder,
        requestsFolder: config.requestsFolder,
        issuesFolder: config.issuesFolder,
        othersFolder: config.othersFolder,
        numberOfDaysBeforeFollowUp: config.numberOfDaysBeforeFollowUp,
        automaticDeleteEmails: config.automaticDeleteEmails,
        authType: config.authType,
        basicAuthConfiguration: config.basicAuthConfiguration ? serializeBasicAuthConfiguration(config.basicAuthConfiguration) : null,
        modernAuthConfiguration: config.modernAuthConfiguration ? serializeModernAuthConfiguration(config.modernAuthConfiguration) : null
    };
};

export const serializeBasicAuthConfiguration = config => {
    return {
        providerName: config.providerName,
        SmtpDisplayName: config.smtpDisplayName,
        SmtpUsername: config.smtpUsername,
        SmtpPassword: config.smtpPassword,
        SmtpHost: config.smtpHost,
        SmtpPort: config.smtpPort,
        smtpSecureSocketOptions: config.smtpSecureSocketOptions,
        smtpAcceptAllSslCertificates: config.smtpAcceptAllSslCertificates,
        ImapUsername: config.imapUsername,
        ImapPassword: config.imapPassword,
        ImapHost: config.imapHost,
        ImapPort: config.imapPort,
        imapSecureSocketOptions: config.imapSecureSocketOptions,
        imapAcceptAllSslCertificates: config.imapAcceptAllSslCertificates,
    };
};

export const serializeModernAuthConfiguration = config => {
    return {
        mailingAuthProviderId: config.mailingAuthProviderId,
        userName: config.userName,
        userObjectId: config.userObjectId,
    };
};

export const serializeRobotConfiguration = config => {
    return {
        ClusterId: config.clusterId,
        Administrators: config.administrators,
        EngagementCodePattern: config.engagementCodePattern,
        Description: config.description
    };
};

export const serializeStorageConfigurationSharePointOnline = config => {
    return {
        ClusterId: config.clusterId,
        IsActive: config.isActive,
        PriorityLevel: config.priorityLevel,
        Token: config.token,
        TokenExpiration: config.tokenExpiration,
        TenantName: config.tenantName,
        TenantId: config.tenantId,
        SiteName: config.siteName,
        SiteUrl: config.siteUrl,
        DocumentLibrary: config.documentLibrary,
        TargetPath: config.targetPath
    };
};

export const serializeStorageConfigurationDefault = config => {
    return {
        ClusterId: config.clusterId,
        IsActive: config.isActive,
        PriorityLevel: config.priorityLevel,
        TargetPath: config.targetPath
    };
};

export const serializeTag = tag => {
    return {
        Id: tag.id,
        Name: tag.name,
        Description: tag.description,
        Text: tag.text,
        DataType: tag.dataType,
        ETag: tag.eTag
    };
};

export const serializeWorkflow = workflow => {
    return {
        Id: workflow.id,
        Name: workflow.name,
        Description: workflow.description,
        ClusterId: workflow.clusterId,
        WorkflowActions: workflow?.workflowActions
            ? workflow?.workflowActions.filter(a => a?.isSelected).map(x => serializeWorkflowAction(x))
            : [],
        ConfirmationCategories: workflow?.confirmationCategories
            ? workflow?.confirmationCategories.map(x => serializeConfirmationCategory(x))
            : []
    };
};

export const serializeWorkflowAction = action => {
    return {
        WorkflowId: action?.workflowId,
        Name: action?.name,
        Description: action?.description,
        WorkflowActionId: action?.workflowActionId ?? action?.id,
        Position: action?.position
    };
};

export const serializeConfirmationCategory = category => {
    return {
        Id: category.id,
        Name: category.name,
        Description: category.description,
        EConfirmationCategory: category.eConfirmationCategory,
        ConfirmationCategoryId: category.confirmationCategoryId ?? category.id,
        WorkflowId: category.workflowId
    };
};

export const serializeConfirmationsTemplateRequest = params => {
    return {
        ...params.labels,
        EngagementId: params.engagementId,
        LetterTemplateId: params.letterTemplateId,
        ActiveStackedWorkflowActions: params.activeStackedWorkflowActions
            ? Object.values(params.activeStackedWorkflowActions).map(actionId => ({ WorkflowActionId: actionId }))
            : []
    };
};

export const serializeConfirmationsUpload = configuration => {
    return {
        WorksheetName: configuration.worksheetName,

        // client part
        ClientCompanyNameColumnIndex: configuration.clientCompanyNameColumnIndex,
        ClientCompanyContact1EmailColumnIndex: configuration.clientContact1EmailColumnIndex,
        ClientCompanyContact1IsRecipientColumnIndex: configuration.clientContact1IsRecipientColumnIndex,
        ClientCompanyContact2EmailColumnIndex: configuration.clientContact2EmailColumnIndex,

        // third-party part
        ThirdPartyCompanyNameColumnIndex: configuration.thirdPartyCompanyNameColumnIndex,
        ThirdPartyCompanyAddressColumnIndex: configuration.thirdPartyCompanyAddressColumnIndex,
        ThirdPartyCompanyZipCodeColumnIndex: configuration.thirdPartyCompanyZipCodeColumnIndex,
        ThirdPartyCompanyCityColumnIndex: configuration.thirdPartyCompanyCityColumnIndex,
        ThirdPartyCompanyCountryColumnIndex: configuration.thirdPartyCompanyCountryColumnIndex,
        ThirdPartyContact1JobTitleColumnIndex: configuration.thirdPartyContact1JobTitleColumnIndex,
        ThirdPartyContact1EmailColumnIndex: configuration.thirdPartyContact1EmailColumnIndex,
        ThirdPartyContact1TitleColumnIndex: configuration.thirdPartyContact1TitleColumnIndex,
        ThirdPartyContact1FirstNameColumnIndex: configuration.thirdPartyContact1FirstNameColumnIndex,
        ThirdPartyContact1LastNameColumnIndex: configuration.thirdPartyContact1LastNameColumnIndex,
        ThirdPartyContact1DialingCodeColumnIndex: configuration.thirdPartyContact1DialingCodeColumnIndex,
        ThirdPartyContact1PhoneColumnIndex: configuration.thirdPartyContact1PhoneColumnIndex,

        // confirmation main part
        ConfirmationClientDueDateColumnIndex: configuration.confirmationClientDueDateColumnIndex,
        ConfirmationThirdPartyDueDateColumnIndex: configuration.confirmationThirdPartyDueDateColumnIndex,

        // confirmation optional part
        AccountExtractStartDateColumnIndex: configuration.accountExtractStartDateColumnIndex,
        AccountExtractEndDateColumnIndex: configuration.accountExtractEndDateColumnIndex,
        ConfirmationAccountColumnIndex: configuration.accountColumnIndex,
        ConfirmationAmountColumnIndex: configuration.amountColumnIndex,
        ConfirmationCurrencyColumnIndex: configuration.currencyColumnIndex
    };
};

export const serializeLetterHeadInfo = info => {
    return { EngagementClientCompanyId: info.engagementClientCompanyId };
};

export const serializeEngagementReporting = downloadType => {
    return { documentsType: downloadType };
};

export const serializeLostItemConfiguration = configuration => {
    return {
        ClusterId: configuration.clusterId,
        IsSupportMandatoryToAssignAsLostItem: configuration.isSupportMandatoryToAssignAsLostItem,
        IsClaimMandatory: configuration.isClaimMandatory,
        IsReadAllowed: configuration.isReadAllowed
    };
};

export const serializeUserResource = resource => {
    return {
        Name: resource.name,
        Description: resource.description,
        EUserResourceType: resource.eUserResourceType,
        ClusterId: resource.clusterId,
        LanguageId: resource.languageId,
        Url: resource.url
    };
};

export const serializeConfirmationRequest = request => {
    return {
        EngagementID: request.engagementID,
        ConfirmationId: request.confirmationId,
        Status: request.status,
        RefusalReason: request.refusalReason,
        Comments: request.comments ? request.comments.map(x => serializeComment(x)) : []
    };
};
