import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { toolbarStyle } from '../../../../../styles/toolbar/ToolbarStyle';
import { UIActionButton } from '../../../../common/UIActionButton';
import { getLocalizedString } from '../../../../../i18n/i18Helper';
import { UIDropDownMenu } from '../../../../common/UIDropDownMenu';
import { UIExpandableIconButton } from '../../../../common/UIExpandableIconButton';
import React from 'react';
import { getMoreSettings } from '../../../../../helpers/EngagementHelper';

interface ConfirmationDocumentsToolbarProps {
    handleDownloadAll?: () => void;
    handleOpenRecycleBin: () => void;
    isDownloadable?: boolean;
}

export const ConfirmationDocumentsToolbar = ({
    isDownloadable = true,
    handleDownloadAll,
    handleOpenRecycleBin
}: ConfirmationDocumentsToolbarProps) => {
    const intl = useIntl();

    return (
        <Box component="span" sx={toolbarStyle.container}>
            {isDownloadable && handleDownloadAll && (
                <UIActionButton
                    uppercase={false}
                    display="tertiary"
                    sx={toolbarStyle.button}
                    title={getLocalizedString(intl, 'CONFIRMATION.ALL.DOCUMENTS.DOWNLOAD.ALL.FILES')}
                    onClick={handleDownloadAll}
                />
            )}

            <UIDropDownMenu
                id="document-settings"
                // @ts-ignore
                entries={getMoreSettings({
                    intl: intl,
                    handleOpenRecycleBin: handleOpenRecycleBin
                })}
                titleComponent={() => <UIExpandableIconButton />}
            />
        </Box>
    );
};
