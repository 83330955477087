import React, { useState, useEffect } from 'react';
import './Toast.css';
import { injectIntl } from 'react-intl';
import checkIcon from '../../assets/toastIcons/check.svg';
import errorIcon from '../../assets/toastIcons/error.svg';
import infoIcon from '../../assets/toastIcons/info.svg';
import warningIcon from '../../assets/toastIcons/warning.svg';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { SessionStatus } from '../../kit/utils/constants';

export const TOAST_TYPE = {
    SUCCESS: 'success',
    DANGER: 'danger',
    INFO: 'info',
    WARNING: 'warning'
};

export const TOAST = {
    DISMISS_TIME: 10000,
    SHOULD_AUTO_DELETE: true
};

const ToastComponent = props => {
    const { toastList, position, autoDelete, dismissTime, setToastMessage } = props;
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList([...toastList]);

        // eslint-disable-next-line
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, dismissTime);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [toastList, autoDelete, dismissTime, list]);

    const deleteToast = id => {
        const listItemIndex = list.findIndex(message => message.id === id);
        const toastListItem = toastList.findIndex(message => message.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setToastMessage(list);
        setList([...list]);
    };

    return (
        <>
            <div className={`notification-container ${position}`}>
                {list.map((toast, key) => (
                    <div
                        key={key}
                        className={`notification toast ${position}`}
                        style={{ backgroundColor: toast?.backgroundColor ?? 'lightcoral' }}>
                        <button onClick={() => deleteToast(toast.id)}>X</button>
                        <div className="notification-image">
                            <img src={toast?.icon} alt="" />
                        </div>
                        <div style={{ margin: '0 2em 2em 2em', height: '100%' }}>
                            <div
                                className="notification-title"
                                style={{ margin: '0.2em 0 1.2em 1.2em', height: '50%' }}>
                                {String(toast?.title)}
                            </div>
                            <div className="notification-message" style={{ margin: '0 2em', height: '50%' }}>
                                {String(toast?.description)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export const getToastType = sessionStatus => {
    switch (sessionStatus) {
        case SessionStatus.valid:
            return TOAST_TYPE.SUCCESS;

        case SessionStatus.invalid:
            return TOAST_TYPE.DANGER;

        case SessionStatus.unknown:
            return TOAST_TYPE.DANGER;

        case SessionStatus.forbidden:
            return TOAST_TYPE.DANGER;

        default:
            return TOAST_TYPE.DANGER;
    }
};

export const createToast = (type, title, description) => {
    let toastMessage;
    const id = Math.floor(Math.random() * 100 + 1);
    switch (type) {
        case TOAST_TYPE.SUCCESS:
            toastMessage = {
                id,
                title: title,
                description: description ?? '',
                backgroundColor: '#4caf50',
                icon: checkIcon,
                type: type
            };
            break;
        case TOAST_TYPE.DANGER:
            toastMessage = {
                id,
                title: title,
                description: description ?? '',
                backgroundColor: '#f44336',
                icon: errorIcon,
                type: type
            };
            break;
        case TOAST_TYPE.INFO:
            toastMessage = {
                id,
                title: title,
                description: description ?? '',
                backgroundColor: '#03a9f4',
                icon: infoIcon,
                type: type
            };
            break;
        case TOAST_TYPE.WARNING:
            toastMessage = {
                id,
                title: title,
                description: description ?? '',
                backgroundColor: '#ffeb3b',
                icon: warningIcon,
                type: type
            };
            break;
        default:
            toastMessage = {
                id,
                title: title,
                description: description ?? '',
                backgroundColor: '#f44336',
                icon: errorIcon,
                type: type
            };
            break;
    }
    return toastMessage;
};

const mapDispatchToProps = dispatch => ({
    setToastMessage: messageId => {
        dispatch(actions.setToastMessage(messageId));
    }
});

export const Toast = connect(null, mapDispatchToProps)(injectIntl(ToastComponent));
