export const administration = {
    'EMAIL_TEMPLATE.SUBSECTION.CREATE.TITLE': 'Nouveau modèle',
    'EMAIL_TEMPLATE.SUBSECTION.TEMPLATES.TITLE': 'Modèles',
    'EMAIL_TEMPLATE.INFO.BUTTON.CREATE.LABEL': 'Créer modèle',
    'EMAIL_TEMPLATE.INFO.BUTTON.EDIT.LABEL': 'Modifier modèle',
    'EMAIL_TEMPLATE.INFO.BUTTON.CLEAR.LABEL': 'Effacer',
    'EMAIL_TEMPLATE.INFO.BUTTON.SAVE.LABEL': 'Sauvegarder',
    'EMAIL_TEMPLATE.INFO.TITLE.CREATE.LABEL': 'Nouveau modèle d\'e-mail',
    'EMAIL_TEMPLATE.INFO.FIELD.TYPE.LABEL': 'Type',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.TYPE': 'Le type d\'e-mail est requis',
    'EMAIL_TEMPLATE.INFO.FIELD.NAME.LABEL': 'Nom',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.NAME': 'Le nom est requis',
    'EMAIL_TEMPLATE.INFO.FIELD.DESCRIPTION.LABEL': 'Description',
    'EMAIL_TEMPLATE.INFO.FIELD.CONFIRMATION.CATEGORY.LABEL': 'Catégorie de circularisation',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.CONFIRMATION.CATEGORY': 'La catégorie de circularisation est requis',
    'EMAIL_TEMPLATE.INFO.FIELD.LANGUAGE.LABEL': 'Langue',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'La langue du modèle d\'e-mail est requis',
    'EMAIL_TEMPLATE.INFO.FIELD.ARCHIVED.LABEL': 'Archivé',
    'EMAIL_TEMPLATE.INFO.FIELD.SUBJECT.LABEL': 'Objet',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.SUBJECT': 'L\'objet est requis',
    'EMAIL_TEMPLATE.INFO.FIELD.BODY.LABEL': 'Corps de l\'e-mail',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.BODY': 'Le corps du modèle d\'e-mail est requis',
    'EMAIL_TEMPLATE.ACTION.TOOLTIP.DELETE': 'Supprimer modèle',
    'EMAIL_TEMPLATE.ALERT.TYPE.MISSING.LABEL': 'Le(s) type(s) de modèle(s) suivant(s) sont manquants: {missingEmailTemplates}',
    'EMAIL_TEMPLATE.ALERT.DEFAULT.LANGUAGE.MISSING.LABEL':
        'Le modèle d\'e-mail par default d type {emailTypeName} est manquant dans le(s) langue(s) suivante(s): {missingEmailLanguages}',
    'TEMPLATE_TAGS.SUBSECTION.TAGS.MANDATORY': 'Balises facultatives',
    'TEMPLATE_TAGS.SUBSECTION.TAGS.OPTIONAL': 'Balises facultatives',
    'COUNTRY_ADMIN.TITLE': 'Administration',
    'COUNTRY_ADMIN_SETTINGS.TITLE': 'Paramètres',
    'COUNTRY_ADMIN_METRICS.TITLE': 'Métriques',
    'COUNTRY_ADMIN.MENU.SETTINGS.TITLE': 'Paramètres',
    'COUNTRY_ADMIN.MENU.METRICS.TITLE': 'Métriques',
    'SETUP.SUBSECTION.TECHNICAL.CONFIGURATION.TITLE': 'Configurations techniques',
    'SETUP.SUBSECTION.GENERAL.CONFIGURATION.TITLE': 'Configurations générales',
    'SETUP.SUBSECTION.WORKFLOW.TITLE': 'Configuration des workflows',
    'SETUP.SUBSECTION.LETTER.TEMPLATE.TITLE': 'Configuration des modèles de lettres',
    'SETUP.SUBSECTION.EMAIL.TEMPLATE.TITLE': 'Configuration des modèles de courriers électroniques',
    'SETUP.SUBSECTION.CLIENT.TITLE': 'Chargement des clients',
    'SETUP.BUTTON.BACK.LABEL': 'Retour',
    'SETUP.BUTTON.PREVIOUS.LABEL': 'Précédent',
    'SETUP.BUTTON.NEXT.LABEL': 'Suivant',
    'SETUP.BUTTON.RESET.LABEL': 'Réinitialiser',
    'SETUP.BUTTON.FINISH.LABEL': 'Terminer',
    'SETUP.BUTTON.CREATE.LABEL': 'Créer',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.TITLE': 'Configuration du stockage',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.DEFAULT.LABEL': 'Stockage par défaut',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.SHAREPOINT_ONLINE.LABEL': 'SharePoint Online',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.NEW.LABEL': 'Demander un nouveau connecteur',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.NEW.MESSAGE': 'Contacter les administrateurs de l\'applications.',
    'TECHNICAL.CONFIGURATION.MAILING.SUBSECTION.TITLE': 'Configuration de la boîte aux lettres',
    'TECHNICAL.CONFIGURATION.ROBOT.SUBSECTION.TITLE': 'Configuration du robot',
    'TECHNICAL.CONFIGURATION.BUTTON.SAVE.LABEL': 'Sauvegarder',
    'TECHNICAL.CONFIGURATION.ALERT.ERROR.STORAGE.MESSAGE':
        'La configuration du stockage n\'est pas disponible ou a été modifiées. Veuillez sauvegarder vos modifications',
    'TECHNICAL.CONFIGURATION.ALERT.ERROR.MAILING.MESSAGE':
        'La configuration de la boîte aux lettres n\'est pas disponible ou a été modifiées. Veuillez sauvegarder vos modifications',
    'TECHNICAL.CONFIGURATION.ALERT.ERROR.ROBOT.MESSAGE':
        'La configuration du robot n\'est pas disponible ou a été modifiées. Veuillez sauvegarder vos modifications',
    'TECHNICAL.CONFIGURATION.BUTTON.TOKEN.LABEL': 'Connecter',
    'STORAGE.BASE.CONFIG.ISACTIVE.LABEL': 'Activer cette configuration de stockage ?',
    'STORAGE.BASE.CONFIG.ISACTIVE.SUBLABEL': 'Activer',
    'STORAGE.BASE.CONFIG.PRIORITY_LEVEL.LABEL': 'Niveau de priorité',
    'STORAGE.BASE.CONFIG.PRIORITY_LEVEL.DESCRIPTION': 'Niveau de priorité',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.CREATE.TITLE': 'Configuration de stockage par défaut',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.RESET.LABEL': 'Réinitialiser',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.SAVE.LABEL': 'Sauvegarder',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.CREATE.LABEL': 'Créer',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.EDIT.LABEL': 'Modifier',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.TITLE.CREATE.LABEL': 'Creer la configuration d\'un stockage par defaut',
    'STORAGE.DEFAULT.CONFIG.FIELD.TARGET_PATH.LABEL': 'Racine du chemin d\'accès (root)',
    'STORAGE.DEFAULT.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TARGET_PATH': 'Le chemin d\'accès à la racine est requis',
    'STORAGE.DEFAULT.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.ISACTIVE': 'Un choix est requis',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.CREATE.TITLE': 'Configuration du stockage SharePoint Online',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.RESET.LABEL': 'Réinitialiser',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.SAVE.LABEL': 'Sauvegarder',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.CREATE.LABEL': 'Créer',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.EDIT.LABEL': 'Modifier',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.TITLE.CREATE.LABEL':
        'Creer la configuration d\'un stockage SharePoint Online',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TENANT_NAME.LABEL': 'Nom du tenant',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TENANT_NAME': 'Le nom du tenant est requis',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TENANT_ID.LABEL': 'Tenant ID',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TENANT_ID': 'Tenant ID est requis',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TARGET_PATH.LABEL': 'Root path',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TARGET_PATH': 'Chemin Root est requis',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.SITE_NAME.LABEL': 'Site name',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.SITE_NAME': 'Site name est requis',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.SITE_URL.LABEL': 'Site url',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.SITE_URL': 'Site url est requis',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.DOCUMENT_LIBRARY.LABEL': 'Document library',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.DOCUMENT_LIBRARY': 'Document library est requis',
    'ROBOT.CONFIG.CARDHEADER.MAILING.TITLE': 'Configuration du robot',
    'ROBOT.CONFIG.LABEL.ADMINISTRATORS': 'Adresses électroniques des administrateurs - utiliser la virgule (,) comme séparateur sans espace',
    'ROBOT.CONFIG.VALIDATION.REQUIRED_FIELD.ADMINISTRATORS': 'Un administrateur est requis',
    'ROBOT.CONFIG.LABEL.ENGAGEMENT_CODE_PATTERN': 'Format du code de mission',
    'ROBOT.CONFIG.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE_PATTERN': 'Le format du code de mission est requis',
    'ROBOT.CONFIG.LABEL.DESCRIPTION': 'Description (exemple de code de mission pour l\'auditeur)',
    'GENERAL.CONFIGURATION.LOST.ITEM.SUBSECTION.TITLE': 'Configuration des objets perdus',
    'GENERAL.CONFIGURATION.USER.RESOURCE.SUBSECTION.TITLE': 'Configuration des ressources de l\'utilisateur',
    'GENERAL.CONFIGURATION.LOST.ITEM.BUTTON.SAVE.LABEL': 'Sauvegarder',
    'GENERAL.CONFIGURATION.USER.RESOURCE.BUTTON.CREATE.LABEL': 'Créer une ressource utilisateur',
    'LOST.ITEM.CONFIGURATION.IS_MANDATORY_SUPPORT_TO_ASSIGN.LABEL':
        'L\'action du support est requis pour déclarer un document comme perdu',
    'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_MANDATORY_SUPPORT_TO_ASSIGN':
        'Support user action is mandatory to declare a document as lost',
    'LOST.ITEM.CONFIGURATION.IS_CLAIM_MANDATORY.LABEL':
        'Activer le mécanisme de réclamation pour que l\'auditeur puisse demander l\'accès à un document',
    'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_CLAIM_MANDATORY':
        'La configuration du méchanisme de réclamation est requise',
    'LOST.ITEM.CONFIGURATION.IS_READ_ALLOWED.LABEL':
        'Autoriser l\'auditeur à lire un document perdu, sinon il n\'aura accès qu\'au commentaire du support',
    'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_READ_ALLOWED':
        'La configuration de l\'autorisation de lecture des documents perdus par les auditeurs est requise',
    'LOST.ITEM.CONFIGURATION.SWITCH.ACTIVE.LABEL': 'Activer',
    'LOST.ITEM.CONFIGURATION.SWITCH.OFF.LABEL': 'Désactiver',
    'USER.RESOURCE.CONFIGURATION.DIALOG.CREATE.TITLE': 'Créer une resource utilisateur',
    'USER.RESOURCE.CONFIGURATION.DIALOG.EDIT.TITLE': 'Modifier une resource utilisateur',
    'USER.RESOURCE.CONFIGURATION.INFO.BUTTON.EDIT.LABEL': 'Modifier',
    'USER.RESOURCE.CONFIGURATION.INFO.BUTTON.DELETE.LABEL': 'Supprimer',
    'USER.RESOURCE.CONFIGURATION.FIELD.TYPE.LABEL': 'Type',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.TYPE': 'Le type est requis',
    'USER.RESOURCE.CONFIGURATION.FIELD.NAME.LABEL': 'Nom',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.NAME': 'Le nom est requis',
    'USER.RESOURCE.CONFIGURATION.FIELD.DESCRIPTION.LABEL': 'Description',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.DESCRIPTION': 'La description est requise',
    'USER.RESOURCE.CONFIGURATION.FIELD.LANGUAGE.LABEL': 'Langue',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'La langue est requise',
    'USER.RESOURCE.CONFIGURATION.FIELD.FILE.LABEL': 'Télécharger un fichier',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.FILE': 'un fichier est requis',
    'USER.RESOURCE.CONFIGURATION.FIELD.URL.LABEL': 'URL',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.URL': 'L\'URL est requise',
    'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.MSG': 'Glisser & déplacer un fichier ici ou cliquer',
    'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.UPLOADED_FILES.LABEL': 'Fichiers sélectionnés',
    'USER.RESOURCE.CONFIGURATION.TYPE.FILE.NAME': 'Fichier',
    'USER.RESOURCE.CONFIGURATION.TYPE.LINK.NAME': 'Lien',
    'USER.RESOURCE.CONFIGURATION.TYPE.VIDEO_LINK.NAME': 'Lien de la vidéo',
    'USER.RESOURCE.CONFIGURATION.TYPE.TEXT.NAME': 'Text',
    'USER.RESOURCE.MENU.CONTACT.US': 'Nous contacter',
    'CLIENT.CONFIG.EXCEL.TEMPLATE.BUTTON.DOWNLOAD.LABEL':'Modèle Excel',
    'CLIENT.CONFIG.EXCEL.TEMPLATE.BUTTON.IMPORT.LABEL': 'Import',
    'CLIENT.CONFIG.IMPORT.FORM.FILE.LABEL': 'Télécharger fichier',
    'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.FILE': 'Fichier requis',
    'CLIENT.CONFIG.IMPORT.FORM.WORKSHEETNAME.LABEL': 'Sélectionner la feuille de calcul à importer',
    'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.WORKSHEETNAME': 'Le nom de l\'onglet est requis',
    'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.LABEL':
        'Voulez-vous réinitialiser la base de données et supprimer toutes les entreprises précédentes ?',
    'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.RESETDATABASE': 'La réponse est requise',
    'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.YES.LABEL': 'Supprimer toutes les entreprises de la base de données',
    'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.YES.WARNING.MESSAGE':
        '<Attention!> Cette action supprime toutes les entreprises clientes de la base de données et les remplaces par celles du fichier',
    'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.NO.LABEL': 'Ne pas réinitialiser la base de données',
    'CLIENT.CONFIG.IMPORT.FORM.SECTION.COLUMNS.LABEL': 'Vérifier la correspondance des colonnes',
    'CLIENT.CONFIG.IMPORT.EXCEL.FILE.NAME.LABEL': 'mazars_confirmations_upload_global_client.xlsx',
    'CLIENT.CONFIG.IMPORT.EXCEL.VALIDATION.REQUIRED_FIELD.FILE': 'Le fichier Excel du modèle est requis',
    'CLIENT.CONFIG.IMPORT.EXCEL.FILE.WORKSHEETNAME.LABEL': 'Entreprises',
    'CLIENT.CONFIG.IMPORT.EXCEL.VALIDATION.REQUIRED_FIELD.WORKSHEETNAME': 'Nom de la feuille Excel est requis',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.INTERNAL_ID.LABEL': 'Code client Mazars (interne)',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.INTERNAL_ID': 'La colonne code client Mazars est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.INTERNAL_ID.COMMENT':
        'Le code client Mazars est disponible dans AGIL ou RETAIN. Veuillez le remplir pour pouvoir vous interfacer avec ATLAS ou SIGNAL à l\'avenir.',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER.LABEL': 'Code SIREN',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER.COMMENT': 'Le code SIREN de l\'entreprise peut être trouvé sur le site web SOCIETE.COM.',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER': 'La colonne code SIREN est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER2.LABEL': 'Code SIRET',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER2.COMMENT': 'Le code SIRET de l\'entreprise peut être trouvé sur le site web SOCIETE.COM.',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER2': 'La colonne code SIRET est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER3.LABEL': 'Numéro d\'identification de l\'entreprise (facultatif)',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER3.COMMENT': 'Numéro d\'identification de l\'entreprise (facultatif)',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.VAT_NUMBER.LABEL': 'Numéro de TVA intracommunautaire',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.VAT_NUMBER.COMMENT': 'Numéro de TVA intracommunautaire',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.VAT_NUMBER': 'La colonne numéro de TVA intracommunautaire est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.NAME.LABEL': 'Nom',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.NAME': 'La colonne nom est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.NAME.COMMENT': 'Nom de l\'entité ou de l\'entreprise cliente',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.DESCRIPTION.LABEL': 'Description',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.DESCRIPTION': 'La colonne description est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.DESCRIPTION.COMMENT': 'La description est facultative',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ADDRESS.LABEL': 'Adresse',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.ADDRESS': 'La colonne adresse est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ADDRESS.COMMENT': 'Commentaire sur l\'adresse',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ZIPCODE.LABEL': 'Code postal',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.ZIPCODE': 'La colonne code postal est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ZIPCODE.COMMENT': 'Code postal de l\'entreprise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.CITY.LABEL': 'Ville',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.CITY': 'La colonne ville est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.CITY.COMMENT': 'Commentaire sur la ville',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COUNTRY.LABEL': 'Pays',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.COUNTRY': 'La colonne pays est requise',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COUNTRY.COMMENT': 'Pays et/ou État',
    'WORKFLOW.DIALOG.TITLE.EDIT.LABEL': 'Modifier workflow',
    'WORKFLOW.INFO.BUTTON.EDIT.LABEL': 'Modifier workflow',
    'WORKFLOW.INFO.FIELD.NAME.LABEL': 'Nom du workflow',
    'WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.NAME': 'Le nom du workflow est requis',
    'WORKFLOW.INFO.FIELD.DESCRIPTION.LABEL': 'Description du workflow',
    'WORKFLOW.INFO.FIELD.CATEGORY.LABEL': 'Catégorie de workflow',
    'WORKFLOW.ACTIVATE.WORKFLOW.STEP.LABEL': 'Cliquez sur une étape pour l\'activer ou la désactiver',
    'WORKFLOW.INFO.FIELD.WORKFLOW_ACTIONS.LABEL': 'Ajouter des étapes au workflow',
    'WORKFLOW.INFO.FIELD.ACTIONS.LABEL': 'ETAPES DU WORKFLOW',
    'WORKFLOW.INFO.FIELD.ACTIONS.POSITION.LABEL': 'Position',
    'WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.POSITION': 'La position de l\'étape est requise',
    'WORKFLOW.INFO.VALIDATION.POSITIVE.ACTION.POSITION': 'Le chiffre de la position doit être un entier positif',
    'WORKFLOW.INFO.FIELD.ACTIONS.NAME.LABEL': 'Nom de l\'étape',
    'WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.NAME': 'Le nom de l\'étape est requis',
    'WORKFLOW.INFO.FIELD.ACTIONS.DESCRIPTION.LABEL': 'Description de l\'étape',
    'WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.DESCRIPTION': 'La description de l\'étape est requise',
    'WORKFLOW.SETUP.SUBSECTION.BANK.TITLE': 'Banque',
    'WORKFLOW.SETUP.SUBSECTION.DEBTOR.TITLE': 'Débiteur',
    'WORKFLOW.SETUP.SUBSECTION.OTHER.TITLE': 'Autre',
    'WORKFLOW.SETUP.SUBSECTION.LAWYER.TITLE': 'Avocat',
    'TEMPLATE.ACTION.TOOLTIP.DELETE': 'Supprimer',
    'TEMPLATE.TEXT.LANGUAGE': 'Langue: ',
    'TEMPLATE.TEXT.CONFIRMATION.CATEGORY': 'Catégorie de la circularisation: ',
    'TEMPLATE.TEXT.CREATED': 'Créée: ',
    'TEMPLATE.TEXT.UPDATED': 'Mise à jour: ',
    'LETTER_TEMPLATE.INFO.BUTTON.CREATE.LABEL': 'Créer modèle',
    'LETTER_TEMPLATE.INFO.BUTTON.EDIT.LABEL': 'Modifier modèle',
    'LETTER_TEMPLATE.INFO.BUTTON.DELETE.LABEL': 'Supprimer modèle',
    'LETTER_TEMPLATE.INFO.FIELD.CONFIRMATION.CATEGORY.LABEL': 'Catégorie de la circularisation',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.CONFIRMATION.CATEGORY': 'La catégorie de la circularisation est requise',
    'LETTER_TEMPLATE.INFO.FIELD.WORKFLOW.LABEL': 'Workflow',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.WORKFLOW': 'Le workflow est requis',
    'LETTER_TEMPLATE.INFO.FIELD.NAME.LABEL': 'Nom du modèle',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.NAME': 'Le nom du modèle est requis',
    'LETTER_TEMPLATE.INFO.FIELD.DESCRIPTION.LABEL': 'Description du modèle',
    'LETTER_TEMPLATE.INFO.FIELD.LANGUAGE.LABEL': 'Langue',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'La langue est requise',
    'LETTER_TEMPLATE.INFO.FIELD.UPLOAD.LABEL': 'Télécharger le document Word',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.UPLOAD': 'Le fichier Word est requis',
    'LETTER_TEMPLATE.TAG.SELECTOR.EDIT.LABEL': 'Sélectionnez les balises (tags) incluses dans le modèle de lettre (Word)',
    'LETTER_TEMPLATE.TAG.SELECTOR.READ.LABEL': 'Balises inclues dans le modèle de lettre',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.TAGS': 'Les balises (tags) incluses dans le modèle de lettre sont requises',
    'FETCH.CONFIG.CHECKS.ERROR': 'Erreur d\'accès aux contrôles de configuration',
    'FETCH.STORAGE.CONFIGURATION.ACCESS.ERROR': 'Erreur d\'accès à la configuration du stockage',
    'FETCH.STORAGE.DEFAULT.CONFIG.CREATE.SUCCESS': 'Configuration de stockage par défaut créée',
    'FETCH.STORAGE.DEFAULT.CONFIG.CREATE.ERROR': 'Échec de la création de la configuration de stockage par défaut',
    'FETCH.STORAGE.DEFAULT.CONFIG.UPDATE.SUCCESS': 'Configuration de stockage par défaut mise à jour',
    'FETCH.STORAGE.DEFAULT.CONFIG.UPDATE.ERROR': 'Échec de la mise à jour de la configuration de stockage par défaut',
    'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.CREATE.SUCCESS': 'Configuration de stockage SharePoint Online créée',
    'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.CREATE.ERROR': 'Échec de la création de la configuration de stockage de SharePoint Online',
    'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.UPDATE.SUCCESS': 'La configuration de stockage de SharePoint Online a été mise à jour',
    'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.UPDATE.ERROR': 'Échec de la mise à jour de la configuration de stockage de SharePoint Online',
    'FETCH.WORKFLOW.UPDATE.ERROR': 'La mise à jour du workflow a échoué',
    'FETCH.WORKFLOW.UPDATE.SUCCESS': 'Le workflow a été mis à jour',
    'LETTER.TEMPLATE.CREATE.SUCCESS': 'Modèle de lettre créé',
    'LETTER.TEMPLATE.CREATE.ERROR': 'La création du modèle de lettre a échoué',
    'LETTER.TEMPLATE.UPDATE.SUCCESS': 'Modèle de lettre mis à jour',
    'LETTER.TEMPLATE.UPDATE.ERROR': 'Échec de la mise à jour du modèle de lettre',
    'LETTER.TEMPLATE.DELETE.SUCCESS': 'Modèle de lettre supprimé',
    'LETTER.TEMPLATE.DELETE.ERROR': 'Échec de la suppression du modèle de lettre',
    'LETTER.TEMPLATE.FILE.ACCESS.ERROR': 'Échec de l\'accès au modèle de lettre',
    'ROBOT.CONFIG.ACCESS.ERROR': 'Échec de l\'accès à la configuration du robot',
    'ROBOT.CONFIG.USER.PROFILE.ERROR': 'Échec de l\'accès au profil utilisateur',
    'ROBOT.CONFIG.CREATE.ERROR': 'Échec de création de la configuration du robot',
    'ROBOT.CONFIG.CREATE.SUCCESS': 'Configuration du robot créée',
    'ROBOT.CONFIG.UPDATE.ERROR': 'Échec de la modification de la configuration du robot',
    'ROBOT.CONFIG.UPDATE.SUCCESS': 'Configuration du robot modifiée',
    'ROBOT.CONFIG.DELETE.ERROR': 'Delete robot configuration failed',
    'ROBOT.CONFIG.DELETE.SUCCESS': 'Robot configuration deleted',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.CREATE.SUCCESS': 'Modèle de circularisation créé',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.CREATE.ERROR': 'Échec de la création du modèle de circularisation',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.DELETE.SUCCESS': 'Modèle de circularisation supprimé',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.DELETE.ERROR': 'Échec de la suppression du modèle de circularisation',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.UPDATE.ERROR': 'Erreur de mise à jour du modèle de circularisation',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.UPDATE.SUCCESS': 'Modèle de circularisation mis à jour',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.CREATE.SUCCESS': 'Modèle d\'email créé',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.CREATE.ERROR': 'Échec de la création d\'un modèle d\'e-mail',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.UPDATE.SUCCESS': 'Modèle d\'e-mail mis à jour',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.UPDATE.ERROR': 'Échec de la mise à jour du modèle d\'e-mail',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.DELETE.SUCCESS': 'Modèle d\'email supprimé',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.DELETE.ERROR': 'Échec de la suppression du modèle d\'email',
    'EMAIL.TEMPLATES.SETUP.USER.PROFILE.ERROR': 'Configuration du modèle d\'e-mail - erreur de profil utilisateur',
    'EMAIL.TEMPLATES.SETUP.TYPES.ERROR': 'Erreur d\'accès aux types de modèles de courrier électronique',
    'LOST_ITEM_CONFIG.INFO.ACCESS.ERROR': 'Erreur de chargement de la configuration des documents perdus',
    'LOST_ITEM_CONFIG.CREATE.SUCCESS': 'Configuration de l\'article perdue créée',
    'LOST_ITEM_CONFIG.CREATE.ERROR': 'Échec de la création de la configuration des documents perdus',
    'LOST_ITEM_CONFIG.UPDATE.SUCCESS': 'Mise à jour de la configuration des documents perdus',
    'LOST_ITEM_CONFIG.UPDATE.ERROR': 'Échec de la mise à jour de la configuration des documents perdus',
    'LOST_ITEM_CONFIG.DELETE.SUCCESS': 'Configuration des documents perdus supprimés',
    'LOST_ITEM_CONFIG.DELETE.ERROR': 'Échec lors de la suppression de la configuration des documents perdus',
    'METRICS.CARD.TITLE.LABEL': 'Mesures d\'utilisation de l\'application',
    'METRICS.APPUSERS.LABEL': 'Nombre d\'utilisateurs',
    'METRICS.CONFIRMATIONS.LABEL': 'Nombre de circularisations',
    'METRICS.CLIENT.COMPANIES.LABEL': 'Nombre d\'entreprises clientes',
    'METRICS.THIRDPARTY.COMPANIES.LABEL': 'Nombre d\'entreprise tierces',
    'METRICS.ENGAGEMENTS.LABEL': 'Nombre de missions (engagements)',
    'METRICS.ITEMS.LABEL': 'Nombre de documents'
};
