import { ServiceResult } from '../../network/ServiceResult';
import { externalAuthApiBaseURL, externalAuthClientId } from '../../config/appConfiguration';
import * as ClientParsers from './clientParsers';
import { Repository } from '../Repository';

interface ExternalAuthRepositorySpec {
    login(email: string, password: string, rememberMe: boolean): Promise<ServiceResult>;

    resetPassword(email: string, inviter: string): Promise<ServiceResult>;
}

export class ExternalAuthRepository extends Repository implements ExternalAuthRepositorySpec {
    constructor() {
        super(externalAuthApiBaseURL);
    }

    async login(email: string, password: string, rememberMe: boolean): Promise<ServiceResult> {
        const body = { email, password, rememberMe: rememberMe };
        const result = await this.network.post('auth/login', body, {
            headers: {
                ...this.buildHeaders(),
                clientId: externalAuthClientId
            }
        });

        result.tryParsingValue(ClientParsers.parseAuthResponse);

        return result;
    }

    async resetPassword(email: string, inviter: string): Promise<ServiceResult> {
        const body = { email, inviter };

        return this.network.post('Guest/request/resetPassword', body, {
            headers: {
                clientId: externalAuthClientId,
                ...this.buildHeaders()
            }
        });
    }
}

export const externalAuthRepository: ExternalAuthRepositorySpec = new ExternalAuthRepository();
