import React from 'react';
import { injectIntl } from 'react-intl';
import { MZRSearchTab } from '../../common/MZRSearchTab';
import { HOME_COLUMNS_FILTERS } from '../../home/Constants';

export const SEARCH_FILTERS = {
    FILTER_1: { id: 'FILTER_1', fields: [HOME_COLUMNS_FILTERS.ENGAGEMENT_NAME_OR_CODE] }
};

const SearchTabComponent = props => {
    return <MZRSearchTab {...props} filtersList={SEARCH_FILTERS} />;
};

export const SearchEngagementTab = injectIntl(SearchTabComponent);
