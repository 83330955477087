import { ServiceResult } from '../../network/ServiceResult';
import * as ClientParsers from './clientParsers';
import { appApiUrl } from '../../config/appConfiguration';
import { Repository } from '../Repository';

interface ClientAccountRepositorySpec {
    checkLogin(accessToken: string, profile: number, provider: number): Promise<ServiceResult>;

    invite(
        email: string,
        inviter: string,
        redirectUrl: string,
        engagementRights: any
    ): Promise<ServiceResult>;
}

export class ClientAccountRepository extends Repository implements ClientAccountRepositorySpec {
    constructor() {
        super(appApiUrl);
    }

    async checkLogin(accessToken: string, profile: number, provider: number): Promise<ServiceResult> {
        const body = { accessToken, profile, provider };
        const result = await this.network.post('auth/login', body, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(ClientParsers.parseAuthResponse);

        return result;
    }

    //TODO define type for rights
    async invite(
        email: string,
        inviter: string,
        redirectUrl: string,
        engagementRights: any
    ): Promise<ServiceResult> {
        const body = { email, inviter, redirectUrl, engagementRights };

        return await this.network.post('auth/invite', body, {
            headers: this.buildHeaders()
        });
    }
}

export const clientAccountRepository: ClientAccountRepositorySpec = new ClientAccountRepository();
