import { useIntl } from 'react-intl';
import { Box, IconButton } from '@mui/material';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { Palette } from '../../../styles/colors';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { StyledTooltip } from '../../../helpers';

export const ConfirmationContainer = ({ children, handleNext, handlePrevious, isNextDisabled, isPreviousDisabled }) => {
    return (
        <Box sx={sx.container}>
            <NavigationButton
                icon={ArrowBackIosRounded}
                style={{ left: '20px' }}
                disabled={isPreviousDisabled}
                handleNavigate={handlePrevious}
                tooltipId="CONFIRMATION.NAVIGATION.PREVIOUS.TOOLTIP"
            />

            <Box sx={{ marginX: NAVIGATION_PANEL_SIZE, width: '100%' }}>{children}</Box>

            <NavigationButton
                icon={ArrowForwardIosRounded}
                style={{ right: 0 }}
                disabled={isNextDisabled}
                handleNavigate={handleNext}
                tooltipId="CONFIRMATION.NAVIGATION.NEXT.TOOLTIP"
            />
        </Box>
    );
};

const NavigationButton = ({
    tooltipId,
    disabled,
    handleNavigate,
    style,
    icon: Icon
}: {
    tooltipId: string;
    disabled: boolean;
    handleNavigate: () => {};
    style: Record<string, any>;
    icon: any;
}) => {
    const intl = useIntl();

    return (
        <Box sx={sx.buttonContainer(style)}>
            <StyledTooltip
                title={getLocalizedString(intl, tooltipId)}
                children={
                    <IconButton onClick={handleNavigate} disabled={disabled} style={{ ...sx.button(disabled) }}>
                        <Icon fontSize="large" sx={sx.icon} />
                    </IconButton>
                }
            />
        </Box>
    );
};

const NAVIGATION_PANEL_SIZE = '80px';

const sx = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch'
    },
    button: (disabled: boolean) => ({
        backgroundColor: disabled ? Palette.whiteSmoke : Palette.defaultPrimaryColor,
        marginX: 2
    }),
    icon: {
        color: 'white'
    },
    buttonContainer: (style: Record<string, any>) => ({
        ...style,
        backgroundColor: 'transparent',
        position: 'fixed',
        bottom: '0',
        width: NAVIGATION_PANEL_SIZE,
        height: '40%'
    })
} as const;
