import { ClientHomeContent } from '../../components/home/ClientHomeContent';
import { useEffect, useState } from 'react';
import { HOME_COLUMNS_FILTERS } from '../../components/home/Constants';
import { INIT_ITEMS_FILTER, SORT_CHOICES } from '../../kit/utils/constants';
import { PROFILE } from '../../types/types';
import { engagementRepository } from '../../kit/repository';
import { UIScaffold, UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { getLocalizedString } from '../../i18n/i18Helper';
import { useIntl } from 'react-intl';
import './home.scss';
import { handleApiFailure } from '../../helpers';
import { ScreenSelector } from './ScreenSelector';

const clientEngagementFilters = {
    [HOME_COLUMNS_FILTERS.ENGAGEMENT_NAME_OR_CODE]: '',
    pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
    pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
    isArchived: false,
    sort: SORT_CHOICES.CREATED_DESC,
    userProfile: PROFILE.CLIENT
};

export const ClientHome = () => {
    const intl = useIntl();
    const [filters, setFilters] = useState<any>(clientEngagementFilters);
    const [engagements, setEngagements] = useState({});
    let scaffold: UIScaffoldChildContext;

    const handleFilterChange = (newFilters: Record<string, any>) => {
        setFilters(newFilters);
    };

    useEffect(() => {
        (async () => {
            await fetchEngagements();
        })();
    }, [filters]);

    const fetchEngagements = async () => {
        scaffold?.setLoading(true);
        const result = await engagementRepository.clientFetchAll(filters);

        scaffold?.setLoading(false);
        if (result.isSuccess) {
            setEngagements(result.parsedValue);
        } else {
            handleApiFailure(result, getLocalizedString(intl, 'FETCH.ENGAGEMENT.LIST.ACCESS.ERROR'), scaffold);
        }
    };

    return (
        <ScreenSelector authorizedProfile={PROFILE.CLIENT}>
            <UIScaffold>
                {props => {
                    scaffold = props;
                    return (
                        <ClientHomeContent
                            handleFilterChange={handleFilterChange}
                            filters={filters}
                            engagements={engagements}
                        />
                    );
                }}
            </UIScaffold>
        </ScreenSelector>
    );
};
