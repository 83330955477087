import React, { useState, useRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Box } from '@mui/material';
import { EditCompany, initCompany } from '../../company';
import { COMPANY_TYPE } from '../../../kit/utils/constants';

import { SearchCompanyContainer } from '../../company/search/SearchCompanyContainer';
import { SearchCompanyWrapper } from '../../company/search/SearchCompanyWrapper';
import { ThirdPartyCompanyContext } from '../../../context';
import { UIActionButton } from '../../common/UIActionButton';
import { UIDialog } from '../../common/UIDialog';
import { UITooltip } from '../../common/UITooltip';
import { toolbarStyle } from '../../../styles/toolbar/ToolbarStyle';
import { getLocalizedString } from '../../../i18n/i18Helper';

/**
 * search and display client companies.
 *
 * @param {*} props
 * @returns
 */
export function EngagementThirdPartyComponent(props) {
    const { engagement, disabled, limitedAccess, DownloadEnabled = false } = props;
    const intl = useIntl();

    const { createCompany, searchCompany, updateCompany, deleteCompany, downloadAll } = useContext(
        ThirdPartyCompanyContext
    );
    const sbmtCompanyRef = useRef();

    const [editCompany, setEditCompany] = useState(initCompany);
    const [openDialogCompany, setOpenDialogCompany] = useState(false);

    const handleEditCompany = selectedCompany => {
        setEditCompany(selectedCompany);
        setOpenDialogCompany(!openDialogCompany);
    };

    const handleCompanyPopUpOpening = () => setOpenDialogCompany(!openDialogCompany);

    const handleClose = () => {
        if (sbmtCompanyRef && sbmtCompanyRef.current) {
            sbmtCompanyRef.current.saveForm();
        }
    };

    const handleCompanySubmit = async values => {
        const { isSuccess } = await updateCompany(values);
        if (isSuccess) setOpenDialogCompany(!openDialogCompany);
    };

    return (
        <>
            {DownloadEnabled && (
                <Box component="span" sx={toolbarStyle.container}>
                    <UITooltip
                        title={getLocalizedString(intl, 'COMPANY.BUTTON.DOWNLOAD.TOOLTIP')}
                        children={
                            <UIActionButton
                                sx={toolbarStyle.button}
                                title={getLocalizedString(intl, 'COMPANY.BUTTON.DOWNLOAD.LABEL')}
                                disabled={limitedAccess}
                                onClick={downloadAll}
                            />
                        }
                    />
                </Box>
            )}

            {!disabled && engagement && (
                <Grid item>
                    <SearchCompanyContainer
                        doInitialSearch={true}
                        companyType={COMPANY_TYPE.THIRDPARTY}
                        searchCompany={searchCompany}
                        companySearchModule={SearchCompanyWrapper}
                        createPopupTitleId={'CREATE_CONFIRMATION.THIRDPARTY.CREATE.COMPANY.LABEL'}
                        initialCompany={{ ...initCompany, engagementId: engagement?.id }}
                        handleEditCompany={handleEditCompany}
                        createCompanyAPI={createCompany}
                        createCompanyCallback={() => {}}
                        createCompanyComponent={EditCompany}
                        deleteCompanyAPI={limitedAccess ? null : deleteCompany}
                    />
                </Grid>
            )}

            {openDialogCompany && (
                <UIDialog
                    open={openDialogCompany}
                    titleId={
                        editCompany?.id
                            ? 'CREATE_CLIENT.SECTION.CONTACT.BUTTON.EDIT_COMPANY'
                            : 'CREATE_CLIENT.SECTION.CONTACT.BUTTON.NEW_COMPANY'
                    }
                    onSave={handleClose}
                    onClose={handleCompanyPopUpOpening}
                    saveTitleId="DIALOG.BUTTON.VALIDATE">
                    <EditCompany
                        companyType={COMPANY_TYPE.THIRDPARTY}
                        variant="outlined"
                        company={editCompany}
                        handleSubmit={handleCompanySubmit}
                        sbmtCompanyRef={sbmtCompanyRef}
                    />
                </UIDialog>
            )}
        </>
    );
}
