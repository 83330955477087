import { buildFilters } from '../../network/mzrRequest';
import { appApiUrl } from '../../config/appConfiguration';
import { parsePaginationResponse, parseResponse, parseResponseShort } from '../parsers';
import { serializeConfirmationsTemplateRequest, serializeConfirmationsUpload } from '../serializers';
import { parseConfirmation, parseConfirmationsMenu } from './confirmationParsers';
import { parseItems } from '../item/itemParsers';
import { Repository } from '../Repository';

/**
 * Class to communicate with API to access to confirmation resources.
 */
class ConfirmationRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    /**
     * Get detail of a confirmation selected.
     * @param: confirmation ID.
     */
    fetch = async (confirmationId) => {
        let result = await this.network.get(`confirmation/${confirmationId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseConfirmation);
        return result;
    };

    /**
     * Create a confirmation.
     * @param: confirmation object.
     */
    create = async (confirmations) => {
        let result = await this.network.post('confirmation', confirmations, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    /**
     * Update list of confirmations.
     * @param: confirmation array.
     */
    update = async (confirmations) => {
        let result = await this.network.put('confirmation', confirmations, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    /**
     * Update list of confirmations.
     * @param: confirmation array.
     */
    update = async (confirmations) => {
        let result = await this.network.put('confirmation', confirmations, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    /**
     * Put the selected confirmation in trash or recover it.
     * @param: confirmation ID.
     */
    trash = async (confirmationId, comment, isTrashed) => {
        return await this.network.put(
            `confirmation/${confirmationId}/trash/${isTrashed}`,
            { comment: comment },
            {
                headers: this.buildHeaders()
            }
        );
    };

    /**
     * Delete confirmation selected permanently
     * @param: confirmation ID.
     */
    deletePermanently = async (confirmationId, comment) => {
        let result = await this.network.delete(
            `confirmation/${confirmationId}`,
            { comment: comment },
            {
                headers: this.buildHeaders()
            }
        );

        result.tryParsingValue(parseResponse);
        return result;
    };

    /**
     * Get list of documents for the confirmation selected.
     * @param: confirmation ID.
     */
    fetchDocuments = async (confirmationId, filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`confirmation/${confirmationId}/items${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseItems));
        return result;
    };

    fetchExcelTemplate = async (configurationInfo) => {
        return await this.network.post(
            'confirmation/excel/file',
            serializeConfirmationsTemplateRequest(configurationInfo),
            { headers: this.buildHeaders(true) }
        );
    };

    /**
     * Upload Excel file to create multiple confirmations.
     */
    upload = async ({ file, configurationInfo }) => {
        const formData = new FormData();
        formData.append('File', file);
        formData.append('Info', JSON.stringify(serializeConfirmationsUpload(configurationInfo)));

        return await this.network.post('confirmation/excel', formData, { headers: this.buildHeaders(true) });
    };

    /***
     * Download the documents/items of the the given confirmation
     */
    downloadFiles = async (confirmationId) => {
        return await this.network.get(`confirmation/${confirmationId}/files`, {
            headers: {
                mode: 'no-cors',
                'Access-Control-Allow-Origin': '*',
                ...this.buildHeaders(true)
            }
        });
    };

    fetchMenu = async (filters) => {
        const _filters = buildFilters(filters);
        const result = await this.network.get(`confirmation/menu${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseConfirmationsMenu);
        return result;
    };
}

export const confirmationsRepository = new ConfirmationRepository();
