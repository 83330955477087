import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AddCircleOutline, ExpandMore, Save, Link } from '@mui/icons-material';
import { toolbarStyle } from '../styles/toolbar/ToolbarStyle';
import { UIActionButton } from '../components/common/UIActionButton';
import { Box } from '@mui/material';

const AccordionSection = ({
    isExpanded,
    setExpanded,
    isLoading,
    hidden,
    isConfigValid,
    checkboxLabel,
    component,
    isSaveEnabled = false,
    saveLabel,
    handleSave,
    isCreateEnabled = false,
    createLabel,
    handleCreate,
    isCustomActionEnabled = false,
    customActionLabel,
    handleCustomAction
}) =>   <Accordion
            expanded={isExpanded}
            onChange={() => setExpanded(!isExpanded)}
            hidden={hidden}
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="accordion-actions-content"
                id="accordion-actions-header">
                <div style={styles.AccordionHeaderStyle}>
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={event => event.stopPropagation()}
                        onFocus={event => event.stopPropagation()}
                        control={<Checkbox checked={isConfigValid ?? false} color="primary" />}
                        label={checkboxLabel}
                    />

                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={event => event.stopPropagation()}
                        onFocus={event => event.stopPropagation()}
                        control={
                            <Box sx={toolbarStyle.container}>
                                {isSaveEnabled && !(isConfigValid || isLoading) && (
                                    <UIActionButton
                                        sx={toolbarStyle.button}
                                        startIcon={<Save color="inherit" fontSize="small" />}
                                        title={saveLabel}
                                        onClick={() => handleSave()}
                                    />
                                )}

                                {isCreateEnabled && isExpanded && (
                                    <UIActionButton
                                        sx={toolbarStyle.button}
                                        startIcon={<AddCircleOutline color="inherit" fontSize="small" />}
                                        title={createLabel}
                                        onClick={() => handleCreate()}
                                    />
                                )}
                                
                                {isCustomActionEnabled && isExpanded && (
                                    <UIActionButton
                                        sx={toolbarStyle.button}
                                        startIcon={<Link color="inherit" fontSize="small" />}
                                        title={customActionLabel}
                                        onClick={() => handleCustomAction()}
                                    />
                                )}
                            </Box>
                        }
                    />
                </div>
            </AccordionSummary>
            <AccordionDetails children={component} />
        </Accordion>;

export const AccordionSections = ({ sections, isLoading }) => {
    return React.Children.toArray(
        sections.map(section => <AccordionSection isLoading={isLoading} {...section} />)
    );
};

const styles = {
    AccordionHeaderStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    }
};
