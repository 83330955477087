import { CLIENT_CONTACT_RIGHT_TYPE, ContactAccess, ContactRight } from '../../types/types';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { transposeMatrix } from '../../helpers';
import { IntlShape, useIntl } from 'react-intl';
import { getLocalizedString } from '../../i18n/i18Helper';

/* const cols = [
      { field: 'entity1Id', headerName: 'entity1', width: 150 },
      { field: 'entity2Id', headerName: 'entity2', width: 150 }
  ];

  const rows: GridRowsProp = [
      { id: 1, entity1Id: true, entity2Id: true },
      { id: 2, entity1Id: true, entity2Id: false }
  ];*/

const COLUMN_RIGHT_NAME = {
    field: 'right',
    headerName: '',
    width: 150
};

const distributeRightOnCompanies = (rightValues: ContactRight[], companies: any[]): GridRowsProp => {
    return {
        ...companies.reduce(
            (acc, company, index) => ({
                ...acc,
                ...{
                    id: rightValues[index]?.type,
                    [company.id]: rightValues[index]?.active
                }
            }),
            { id: 0, [COLUMN_RIGHT_NAME.field]: rightValues[0].name } // first column shows the name of the right
        )
    };
};

const buildColumns = (data: ContactAccess[], handleRightChanged: (selected: any) => (event: any) => void) => {
    const columns: GridColDef[] = [
        COLUMN_RIGHT_NAME,
        ...data.map(access => ({
            field: access.company.id,
            headerName: access.company.name,
            width: 150,
            renderCell: (cell: GridRenderCellParams) => (
                <Checkbox color="primary" checked={cell.value} onClick={handleRightChanged(cell)} />
            )
        }))
    ];

    return columns;
};

const buildRows = (data: ContactAccess[], intl: IntlShape) => {
    const companies = data.map(access => access.company);
    const rights: ContactRight[][] = data.map(access => access.rights); //[[right1ForCompany1, right2ForCompany1][right1ForCompany2, right2ForCompany2]]
    const transposedRights = transposeMatrix(rights); // [[right1ForCompany1, right1ForCompany2], [right2ForCompany1, right2ForCompany2]]

    const firstRow = companies.reduce(
        (acc, company, index) => ({
            ...acc,
            ...{
                id: CLIENT_CONTACT_RIGHT_TYPE.ALL,
                [company.id]:
                    rights[index]?.filter(right => right.active)?.length === rights[index]?.length ? true : false
            }
        }),
        { id: 0, [COLUMN_RIGHT_NAME.field]: getLocalizedString(intl, 'SETTINGS.SECTION.CONTACT.RIGHTS.SELECT.ALL') } // first row is for selecting all rights for a company
    );

    const rows: GridRowsProp = [
        firstRow,
        ...transposedRights.reduce((acc, current) => [...acc, distributeRightOnCompanies(current, companies)], [])
    ]; //=>   [{ id: 1, entity1Id: right1ForCompany1, entity2Id: right1ForCompany2 },{ id: 1, entity1Id: right2ForCompany1, entity2Id: right2ForCompany2 }]

    return rows;
};

interface ContactAccessRightsMatrixProps {
    accessRights: ContactAccess[];
}

export const ContactAccessRightsMatrix = ({ accessRights }: ContactAccessRightsMatrixProps) => {
    const [data, setData] = useState<ContactAccess[]>(accessRights);
    const intl = useIntl();

    const handleRightChanged = (selected: any) => (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const { field: companyId, id: rightType, value: active } = selected;
        const companyAccess = data.find(item => item.company.id === companyId);
        if (companyAccess) {
            const rights = companyAccess?.rights.map(right => ({
                ...right,
                active: rightType === CLIENT_CONTACT_RIGHT_TYPE.ALL || right.type === rightType ? !active : right.active
            }));

            companyAccess.rights = rights;
            const updatedDate: ContactAccess[] = data.map(c => (c.company.id !== companyId ? c : companyAccess));

            setData(updatedDate);
        }
    };

    const columns = buildColumns(data, handleRightChanged);
    const rows = buildRows(data, intl);

    return (
        <div style={{ height: '300px', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Pagination: () => null
                }}
            />
        </div>
    );
};
