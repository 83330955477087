import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './warningBanner.scss';
import { Link } from '@mui/material';

const DevelopmentWarningBannerComponent = ({ intl }) => {
    return (
        <>
            <div className="mzr-dev-banner">
                <label>
                    <FormattedMessage
                        id="DEVELOPMENT.WARNING.BANNER.MESSAGE"
                        values={{
                            a: chunks => (
                                <Link target="_blank" href={chunks}>
                                    {chunks}
                                </Link>
                            )
                        }}
                    />
                </label>
            </div>
        </>
    );
};

export const DevelopmentWarningBanner = injectIntl(DevelopmentWarningBannerComponent);
