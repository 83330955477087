import React, { Fragment } from 'react';
import {
    List,
    ListItem,
    Divider,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    IconButton,
    ListItemSecondaryAction
} from '@mui/material';

import { Delete } from '@mui/icons-material';
import { Palette } from '../../../../../styles/colors';

const getSortedComments = comments => {
    return Object.values(comments).sort((a, b) => (a.created < b.created ? 1 : -1));
};

/**
 * Component to display the list of comments loaded for the curent confirmation.
 * @param {*} props
 */
export function Comments(props) {
    const { comments, deleteComment } = props;

    const handleDelete = comment => () => {
        deleteComment(comment.id);
    };

    return (
        <List sx={styles.root}>
            {comments.length > 0 && React.Children.toArray(getSortedComments(comments).map(comment => {
                return (
                    <Fragment key={comment.id}>
                        <ListItem key={comment.id} alignItems="flex-start">
                            <ListItemAvatar>
                                {comment.authorEngagementRole === 2 ? (
                                    <Avatar className="avatar bg-success" alt={comment.author.fullname}>
                                        {String(comment.author.fullname)
                                            .split(' ')
                                            .map(x => x.substring(0, 1))}
                                    </Avatar>
                                ) : (
                                    <Avatar className="avatar bg-primary" alt={comment.author.fullname}>
                                        {String(comment.author.fullname)
                                            .split(' ')
                                            .map(x => x.substring(0, 1))}
                                    </Avatar>
                                )}
                            </ListItemAvatar>

                            <ListItemText
                                primary={<Typography className={styles.fonts}>{comment.text}</Typography>}
                                secondary={
                                    <>
                                        <Typography
                                            component="span"
                                            sx={styles.inline}
                                            variant="body2"
                                            color="textPrimary">
                                            {comment.author.email}
                                        </Typography>
                                        {` - ${comment.created}`}
                                    </>
                                }
                            />

                            <ListItemSecondaryAction>
                                <IconButton
                                    key={comment.id}
                                    edge="end"
                                    aria-label="delete"
                                    onClick={handleDelete(comment)}>
                                    <Delete sx={{ color: Palette.red }} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </Fragment>
                );
            }))}
        </List>
    );
}

const styles = {
    root: {
        width: '100%',
        backgroundColor: Palette.white
    },
    fonts: {
        fontWeight: 'bold'
    },
    inline: {
        display: 'inline'
    }
};
