import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Business, VisibilityOutlined, VisibilityOff, CheckOutlined } from '@mui/icons-material';
import { components } from 'react-select';
import { UNDEFINED_ITEM_ID } from '../components/document/InitItem';
import MenuItem from '@material-ui/core/MenuItem';

export const getVisibilityIcon = (item, authorizedVisualisation, authorizedDownload) => {
    const isVisualisationVisible = authorizedVisualisation
        ? authorizedVisualisation.includes(item?.fileExtension.toLowerCase())
        : true;
    const isDownloadVisible = authorizedDownload
        ? authorizedDownload.includes(item?.fileExtension.toLowerCase())
        : true;

    return isVisualisationVisible || isDownloadVisible ? (
        <VisibilityOutlined color="primary" />
    ) : (
        <VisibilityOff color="disabled" />
    );
};

export const getValidationIcon = (item, authorisedStatus) =>
    item.paperSendingStatus === authorisedStatus ? (
        <CheckOutlined color="primary" />
    ) : (
        <CheckOutlined color="disabled" />
    );

export const getSelectDocumentTypeOptions = (documentTypes, intl) => {
    return documentTypes
        ? Object.keys(documentTypes).map(x => (
              <MenuItem key={x} value={documentTypes[x]}>
                  {intl.formatMessage({ id: 'CONFIRMATION.DOCUMENT.TYPE.' + documentTypes[x] })}
              </MenuItem>
          ))
        : [];
};

/**
 * React-select custom component to display the name of a selected value.
 * @param {*} props
 * @returns
 */
export const MZRValueSelectedContainer = ({ children, ...props }) => {
    const { value, options } = props.selectProps;

    if (!options || !value || value === UNDEFINED_ITEM_ID) {
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    } else {
        const [displayedValue] = options && options?.filter(x => x?.id === value);
        return <components.ValueContainer {...props}>{displayedValue?.name}</components.ValueContainer>;
    }
};

/**
 * React-select custom component to display engagement options.
 * @param {*} props
 * @returns
 */
export const MZRSelectEngagementOption = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <MenuItem key={props.data.id} value={props.data} {...props.innerProps}>
                <List dense>
                    <ListItemText
                        dense
                        primaryTypographyProps={{ variant: 'subtitle1', color: 'inherit' }}
                        primary={props.data.name}
                        secondaryTypographyProps={{ variant: 'overline', color: 'primary' }}
                        secondary={props.data.engagementCode}
                    />
                    {Object.values(props.data.clientCompanies).map(company => {
                        return (
                            <ListItem dense key={`key-select-menu-${props.data.id}-${company?.name}`}>
                                <ListItemIcon children={<Business color="disabled" />} />
                                <ListItemText
                                    className={{ width: '100%', padding: '0 0.1em' }}
                                    dense="true"
                                    primary={company?.name}
                                    secondaryTypographyProps={{
                                        variant: 'caption',
                                        color: 'textPrimary'
                                    }}
                                    secondary={company?.address}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </MenuItem>
            {children}
        </components.Option>
    );
};

/**
 * React-select custom component to display confirmation options.
 * @param {*} props
 * @returns
 */
export const MZRSelectConfirmationOption = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <MenuItem key={props.data.id} value={props.data} {...props.innerProps}>
                <ListItemText primary={props.data.name} secondary={props.data.confirmationCode} />
            </MenuItem>
            {children}
        </components.Option>
    );
};
