import React from 'react';
import { UIScaffold, UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { ClientAuthContent } from '../../components/auth/ClientAuthContent';
import { ExternAuthInfoSpec } from '../../components/auth/initLoginAuth';
import { clientAccountRepository, externalAuthRepository } from '../../kit/repository';
import { useDispatch } from 'react-redux';
import { ServiceResult } from '../../kit/network/ServiceResult';
import * as actions from '../../store/actions';
import { Box, Paper } from '@mui/material';
import { AUTH_PROVIDER, SESSION_TYPE, PROFILE } from '../../types/types';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../navigation/Routes';
import { TOAST_TYPE } from '../../components/toast/Toast';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../i18n/i18Helper';
import { toAbsoluteUrl } from '../../helpers';
import { accountRepository } from '../../kit/repository';

export const ClientAuth = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleError = (title: string, result: ServiceResult, setToast: any) => {
        const description = !result?.isUnauthorizedUserError ? result.errorMessage : '';

        setToast(
            getLocalizedString(intl, title),
            `${description ? getLocalizedString(intl, description) : ''} ${result.error ?? ''}`,
            TOAST_TYPE.DANGER
        );
    };
    const handleSignIn = ({ setLoading, setToast, setSessionExpired }: UIScaffoldChildContext) => async (
        loginInfo: ExternAuthInfoSpec
    ) => {
        setLoading(true);
        externalAuthRepository
            .login(loginInfo.email, loginInfo.password, true)
            .then(async (authResult: ServiceResult) => {
                if (authResult?.isSuccess) {
                    const { token: authExtToken } = authResult?.parsedValue as { token: string };

                    clientAccountRepository
                        .checkLogin(authExtToken, PROFILE.CLIENT, AUTH_PROVIDER.MAZARS_EXTERNAL_AUTH)
                        .then(async (registerResult: ServiceResult) => {
                            accountRepository.fetchUserInfo().then((profileResult: ServiceResult) => {
                                setLoading(false);

                                if (profileResult?.isSuccess) {
                                    dispatch(actions.setProfile(profileResult.parsedValue));
                                    dispatch(actions.setDefaultPath(ROUTES.HOME));
                                    dispatch(actions.setConnection(true, SESSION_TYPE.EXTERNAL_CLIENT));
                                    dispatch(actions.setSessionProfile(PROFILE.CLIENT));
                                } else {
                                    if (!!profileResult?.isUnauthorizedUserError) {
                                        setSessionExpired(true);
                                    } else {
                                        handleError('AUTH.CREDENTIALS.ERROR', profileResult, setToast);
                                    }
                                }
                            });
                        });
                } else {
                    handleError('AUTH.CREDENTIALS.ERROR', authResult, setToast);
                    setLoading(false);
                }
            });
    };

    return (
        <UIScaffold>
            {({ setLoading, setSessionExpired, setToast }) => (
                <Box sx={sx.container}>
                    <Link to={ROUTES.AUTH} className="mt-5">
                        <img
                            alt="Logo"
                            className="max-h-150px"
                            src={toAbsoluteUrl('/media/logos/ForvisMazars-Logo-Color-CMYK.png')}
                        />
                    </Link>

                    <Paper elevation={3}>
                        <ClientAuthContent
                            setSessionExpired={setSessionExpired}
                            setLoading={setLoading}
                            setToast={setToast}
                            handleSignIn={handleSignIn({ setLoading, setSessionExpired, setToast })}
                        />
                    </Paper>
                </Box>
            )}
        </UIScaffold>
    );
};

const sx = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gridRowGap: 30,
        marginY: 10
    }
} as const;
