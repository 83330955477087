import { appApiUrl } from '../../config/appConfiguration';
import { Repository } from '../Repository';

class OAuth2Repository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    getAuthorisation = async () => {
        let result = await this.network.get(`oauth2/code-flow`, {
            headers: this.buildHeaders()
        });

        return result;
    };

    getToken = async (payload) => {
        let result = await this.network.get(`oauth2/get-token`, payload, {
            headers: this.buildHeaders()
        });

        return result;
    };
}

export const oAuth2Repository = new OAuth2Repository();
