import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseShort } from '../parsers';
import { parseLetterTemplateTags } from './confirmationParsers';
import { Repository } from '../Repository';

class LetterTemplateTagRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`letterTemplateTag`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseLetterTemplateTags);
        return result;
    };

    create = async (tag) => {
        let result = await this.network.post(`letterTemplateTag`, tag, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    update = async (tagId, tag) => {
        return await this.network.put(`letterTemplateTag/${tagId}`, tag, {
            headers: this.buildHeaders()
        });
    };

    delete = async (tagId) => {
        return await this.network.delete(`letterTemplateTag/${tagId}`, null, {
            headers: this.buildHeaders()
        });
    };
}

export const letterTemplateTagRepository = new LetterTemplateTagRepository();
