import { appApiUrl } from '../../config/appConfiguration';
import { buildFilters } from '../../network/mzrRequest';
import { Repository } from '../Repository';

/**
 * Class to communicate with API's Metrics resources.
 */
class MetricsRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async (filters = {}) => {
        const _filters = buildFilters(filters);
        return await this.network.get(`metrics${_filters}`, {
            headers: this.buildHeaders()
        });
    };
}

export const metricsRepository = new MetricsRepository();
