import React, { Component } from 'react';
import '../style.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { COMPONENT_NAME, LostItemConfig } from '../../components/setup';
import { LostItemConfigService } from '../../services';
import { CircularProgress } from '@material-ui/core';

/**
 * Lost item setup screen.
 */
class LostItemSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    lostItemConfigManager = LostItemConfigService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        callback: async (isSuccess, _data, _) => isSuccess && (await this.fetchLostItemConfig()),
        setLoading: this.setLoading
    });

    fetchLostItemConfig = async () => {
        const result = await this.lostItemConfigManager.fetch();
        if (result?.isSuccess) {
            this.setState({ lostItemConfig: result.parsedValue });
        }
    };

    lostItemConfigSubmit = async values => {
        if (values?.id && values?.id !== null) {
            const { isSuccess } = await this.lostItemConfigManager.update(values);

            if (isSuccess) {
                this.props.hasValidConfig(COMPONENT_NAME.LOST_ITEM_SETUP, true);
                this.props.setIsLostItemConfigExpanded(false);
            }
        } else {
            const { isSuccess } = await this.lostItemConfigManager.create(values);
            if (isSuccess) {
                this.props.hasValidConfig(COMPONENT_NAME.LOST_ITEM_SETUP, true);
                this.props.setIsLostItemConfigExpanded(false);
            }
        }
    };
    
    // TODO: missing clusterId filter in the API endpoint
    async componentDidMount() {
        await this.fetchLostItemConfig(this);
    };

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            if (!this.state.loading)
                await this.fetchLostItemConfig(this);
        }
    };

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div className="fullCenter">
                        <CircularProgress />
                    </div>
                ) : (
                    <LostItemConfig
                        loading={this.state.loading}
                        lostItemConfig={this.state.lostItemConfig}
                        lostItemConfigRef={this.props.lostItemConfigRef}
                        handleSubmit={this.lostItemConfigSubmit}
                        hasValidConfig={this.props.hasValidConfig}
                    />
                )}
            </>
        );
    }
}

LostItemSetupComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array
};

LostItemSetupComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
    };
};

export const LostItemSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(LostItemSetupComponent));
