import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedErrorMessage } from '../components/errorMessage/ErrorMessage';
import { ErrorMessage, Field } from 'formik';
import { Help as HelpIcon } from '@mui/icons-material';
import { UITooltip } from '../components/common/UITooltip';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Grid, Select } from '@mui/material';
import { OutlinedInput } from '@mui/material';
import { UIDatePickerField } from '../components/common/UIDatePickerField';

export const FORM_FIELD = {
    TEXT_FIELD: 'TextFieldItem',
    SELECT_FIELD: 'SelectFieldItem',
    INPUT_FIELD: 'InputFieldItem',
    DATE_FIELD: 'DateFieldItem',
    SWITCH_FIELD: 'SwitchFieldItem',
    CHECKBOX_FIELD: 'BoxFieldItem',
    PROVIDED_FIELD: 'ProvidedFieldItem',
    COMPONENT_FIELD: 'ComponentFieldItem',
    CUSTOM_FIELD: 'CustomFieldItem'
};

/**
 * Helper component to create a custom FormatMessage based on i18n.
 *
 * @param {*} param0
 * @returns
 */
export const FormLabel = ({ id, isRequired, helperTitleId, helperCustomMessage, isHidden = false, style = {} }) =>
    !isHidden ? (
        <label style={style}>
            <FormattedMessage id={id} />
            {isRequired ? <label style={{ color: 'red', marginBottom: 0 }}> *</label> : null}
            {helperTitleId ? (
                <UITooltip
                    title={<FormattedMessage id={helperTitleId} values={{ customMessage: helperCustomMessage }} />}
                    children={<HelpIcon style={{ fontSize: 'medium', marginInline: 5 }} color="primary" />}
                />
            ) : null}
        </label>
    ) : null;

/**
 * Helper component to create a dynamic menu for select component.
 * @param {*} options
 * @returns
 */
export const makeSelectOptions = options =>
    options?.map(
        item =>
            item && (
                <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                </MenuItem>
            )
    );

/**
 * Helper component to create a dynamic menu for select component.
 * @param {*} options
 * @returns
 */
export const makeSelectObjectOptions = options =>
    options?.map(
        item =>
            item && (
                <MenuItem key={item?.id} value={item}>
                    {item?.name}
                </MenuItem>
            )
    );

/**
 * Helper component to create faster modulable form.
 *
 * Text field, select field, date field, input field, switch field, custom field
 *
 * @param {*} field
 * @returns
 */
export const getFieldElement = field => {
    const [elementType] = Object.keys(field)?.filter(key => key !== 'labelItem' && key !== 'itemKey');

    switch (elementType) {
        case 'TextFieldItem':
            return (
                <Grid container>
                    <Field
                        as={OutlinedInput}
                        key={field?.TextFieldItem?.id}
                        id={field?.TextFieldItem?.id}
                        name={field?.TextFieldItem?.id}
                        component={field?.TextFieldItem?.component}
                        rows={field?.TextFieldItem?.rows}
                        placeholder={field?.TextFieldItem?.placeholder}
                        variant={field?.TextFieldItem?.variant ?? 'outlined'}
                        disabled={field?.TextFieldItem?.disabled}
                        type={field?.TextFieldItem?.type ?? ''}
                        autoComplete={field?.TextFieldItem?.type ? 'current-password' : ''}
                        style={{ padding: 0, width: '100%' }}
                        className={field?.TextFieldItem?.className}
                    />
                    <ErrorMessage name={field?.TextFieldItem?.id}>
                        {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
                    </ErrorMessage>
                </Grid>
            );

        case 'SelectFieldItem':
            return (
                <Grid container>
                    <Field
                        as={Select}
                        key={field?.SelectFieldItem?.id}
                        labelId={field?.labelItem?.id}
                        label={field?.labelItem?.id}
                        id={field?.SelectFieldItem?.id}
                        name={field?.SelectFieldItem?.id}
                        placeholder={field?.SelectFieldItem?.placeholder}
                        multiple={field?.SelectFieldItem?.multiple}
                        variant={field?.SelectFieldItem?.variant ?? 'outlined'}
                        disabled={field?.SelectFieldItem?.disabled}
                        className={field?.SelectFieldItem?.className}
                        fullWidth>
                        {field?.SelectFieldItem?.optionsObjectChoices
                            ? makeSelectObjectOptions(field?.SelectFieldItem?.options)
                            : makeSelectOptions(field?.SelectFieldItem?.options)}
                    </Field>
                    {!field?.SelectFieldItem?.noValidation ? (
                        <ErrorMessage name={field?.SelectFieldItem?.id}>
                            {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
                        </ErrorMessage>
                    ) : null}
                </Grid>
            );

        case 'InputFieldItem':
            if (field?.InputFieldItem?.hidden) {
                return;
            }
            return (
                <Grid container item>
                    <input
                        // as={CustomInputComponent}
                        key={field?.InputFieldItem?.id}
                        id={field?.InputFieldItem?.id}
                        type={field?.InputFieldItem?.type}
                        name={field?.InputFieldItem?.id}
                        onChange={field?.InputFieldItem?.method}
                        // onChange={props?.form?.setFieldValue ?? field?.InputFieldItem?.method}
                        hidden={field?.InputFieldItem?.hidden}
                    />
                    <ErrorMessage name={field?.InputFieldItem?.id}>
                        {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
                    </ErrorMessage>
                </Grid>
            );

        case 'DateFieldItem':
            return (
                <Grid container>
                    <Field
                        as={UIDatePickerField}
                        name={field?.DateFieldItem?.id}
                        locale={field?.DateFieldItem?.locale}
                        size='small'
                        variant={field?.DateFieldItem?.variant}
                        className={field?.DateFieldItem?.className}
                        disabled={field?.DateFieldItem?.disabled}
                        disablePast={field?.DateFieldItem?.disablePast}
                    />

                    <Grid item>
                        <ErrorMessage name={field?.DateFieldItem?.id} style={{ margin: 5 }}>
                            {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
                        </ErrorMessage>
                    </Grid>
                </Grid>
            );

        case 'SwitchFieldItem':
            return (
                <Grid container>
                    <FormControlLabel
                        control={
                            <Field
                                component={Switch}
                                key={field?.SwitchFieldItem?.id}
                                id={field?.SwitchFieldItem?.id}
                                name={field?.SwitchFieldItem?.id}
                                checked={field?.SwitchFieldItem?.value}
                                onChange={field?.SwitchFieldItem?.method}
                                color={field?.SwitchFieldItem?.color}
                            />
                        }
                        label={
                            <FormLabel
                                id={field?.SwitchFieldItem?.labelId ?? field?.labelItem?.id}
                                isRequired={field?.labelItem?.isRequired}
                            />
                        }
                    />
                    <ErrorMessage name={field?.SwitchFieldItem?.id}>
                        {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
                    </ErrorMessage>
                </Grid>
            );

        case 'CustomListFieldItem':
            return (
                <Grid container>
                    {Object.values(field?.CustomListFieldItem?.value).map(item => {
                        return (
                            <Grid container>
                                <Field
                                    key={`${field?.CustomListFieldItem?.id}-${item?.id}`}
                                    id={item?.id}
                                    name={item?.id}
                                    value={
                                        item[field?.CustomListFieldItem?.displayKey1] ||
                                        item[field?.CustomListFieldItem?.displayKey2]
                                            ? `${item[field?.CustomListFieldItem?.displayKey1]} ${
                                                  item[field?.CustomListFieldItem?.displayKey2]
                                              }`
                                            : item[field?.CustomListFieldItem?.displayKey3]
                                    }
                                    fullWidth
                                    variant={field?.CustomListFieldItem?.variant}
                                    disabled={field?.CustomListFieldItem?.disabled}
                                    style={{ padding: 0, width: '100%' }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            );

        case 'CustomFieldItem':
            return (
                <Grid item>
                    {React.cloneElement(field.CustomFieldItem?.render, { ...field.CustomFieldItem?.customProps })}
                </Grid>
            );

        default:
            return null;
    }
};

export const SimpleFormItem = ({ title, value }) => {
    return (
        <Grid container direction="row" justifyContent="space-around" alignItems="center" alignContent="center">
            <Grid item xs={5}>
                <FormLabel id={title} />
            </Grid>

            <Grid item xs={7}>
                <label> {value ?? '/'}</label>
            </Grid>
            <Grid item xs={1} />
        </Grid>
    );
};

export const LabeledTextFieldItem = ({
    id,
    title,
    placeholder,
    isRequired = false,
    style,
    className = 'col-lg-6',
    ...textProps
}) => {
    return (
        <div style={style} className={className}>
            <FormLabel id={title} isRequired={isRequired} />

            {getFieldElement({
                TextFieldItem: {
                    id: id,
                    placeholder: placeholder,
                    ...textProps
                }
            })}
        </div>
    );
};
