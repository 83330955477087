import React from 'react';
import {
    ENGAGEMENT_COLUMNS_FILTERS,
    ENGAGEMENT_PERIOD_DEFAULT_MONTHS,
    WORKFLOW_ACTION_STATUS
} from '../components/engagement/InitEngagement';
import GetAppIcon from '@mui/icons-material/GetApp';
import { UIDropDownMenu } from '../components/common/UIDropDownMenu';
import { Palette } from '../styles/colors';
import { UIActionButton } from '../components/common/UIActionButton';
import { getLocalizedString } from '../i18n/i18Helper';
import { datesDiff } from '../kit/utils/DateUtil';
import { hasEditionRights } from '../services';
import { isAuthorisedProfile } from '../components/auth/UserHelper';
import { PROFILE } from '../types/types';

// don't send the filters which are null
export const cleanFilters = filters => {
    const confirmationsFilters = {};

    filters &&
        Object.keys(filters).map(key => (filters[key] !== null ? (confirmationsFilters[key] = filters[key]) : null));
    return confirmationsFilters;
};

export const buildFilters = (data, getOptionLabel) => {
    return data?.reduce((acc, element) => [...acc, { id: element.id, value: getOptionLabel(element) }], []);
};

export const resetColumnsFilters = (filters, defaultValue = null) => {
    const updatedFilters = filters
        ? Object.values(filters).reduce(
              (acc, filterName) => ({
                  ...acc,
                  [filterName]: defaultValue
              }),
              {}
          )
        : {};

    return updatedFilters;
};

export const getWorkflowActionsStatus = intl => [
    {
        id: WORKFLOW_ACTION_STATUS.NOT_STARTED,
        name: intl.formatMessage({
            id: 'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.NOT_STARTED'
        })
    },
    {
        id: WORKFLOW_ACTION_STATUS.IN_PROGRESS,
        name: intl.formatMessage({
            id: 'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.IN_PROGRESS'
        })
    },
    {
        id: WORKFLOW_ACTION_STATUS.VALIDATED,
        name: intl.formatMessage({
            id: 'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.VALIDATED'
        })
    }
];

export const getMoreSettings = ({
    intl,
    archiveProps = {},
    isDownloadHidden = true,
    downloadOptions,
    handleOpenRecycleBin,
    handleDelete,
    isDeleteHidden = true
}) => {
    const {
        hidden: isToggleArchiveHidden = true,
        disabled: isArchiveDisabled = true,
        handleToggleArchive,
        title: archiveTitle
    } = archiveProps;

    const archiveEntry = !isToggleArchiveHidden
        ? {
              id: 'archive',
              divider: false,
              title: getLocalizedString(intl, archiveTitle),
              handler: handleToggleArchive,
              disabled: isArchiveDisabled
          }
        : null;

    const deleteEntry = !isDeleteHidden
        ? {
              id: 'delete',
              divider: false,
              title: intl.formatMessage({ id: 'ENGAGEMENT.BUTTON.DELETE.LABEL' }),
              titleStyle: { style: { color: Palette.red } },
              handler: handleDelete
          }
        : null;

    const downloadEntry = !isDownloadHidden
        ? {
              id: 'download',
              nested: true,
              nestedMenu: props => (
                  <UIDropDownMenu
                      {...props}
                      entries={downloadOptions}
                      titleComponent={() => {
                          return (
                              <UIActionButton
                                  uppercase={false}
                                  sx={{ minHeight: 'auto' }}
                                  title={getLocalizedString(intl, 'ENGAGEMENT.BUTTON.DOWNLOAD.LABEL')}
                                  endIcon={<GetAppIcon color="inherit" fontSize="small" />}></UIActionButton>
                          );
                      }}
                  />
              )
          }
        : null;

    const binEntry = {
        id: 'bin',
        divider: true,
        title: intl.formatMessage({ id: 'ENGAGEMENT.BUTTON.RECYCLE.BIN.LABEL' }),
        handler: handleOpenRecycleBin
    };

    const list = [archiveEntry, deleteEntry, binEntry, downloadEntry].filter(entry => !!entry);

    return list;
};

export const isValidGapEngagementDates = (
    openingDate,
    closingDate,
    monthsNumber = ENGAGEMENT_PERIOD_DEFAULT_MONTHS
) => {
    return datesDiff(openingDate, closingDate) !== -1 && datesDiff(openingDate, closingDate) <= monthsNumber;
};

export const prepareFilters = (
    filters,
    clientCompanies,
    confirmationCategories,
    workflowActions,
    workflowActionsStatus
) => {
    const {
        [ENGAGEMENT_COLUMNS_FILTERS.CLIENT]: clientEntityId,
        [ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY]: thirdParty,
        [ENGAGEMENT_COLUMNS_FILTERS.CATEGORY]: categoryId,
        [ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION]: workflowActionId,
        [ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION_STATUS]: actionStatusId
    } = filters;

    const selectCategory = confirmationCategories.find(c => c.id === categoryId);
    const selectedClient = clientCompanies.find(c => c.id === clientEntityId);
    const selectWorkflowAction = workflowActions.find(c => c.id === workflowActionId);
    const actionStatus = workflowActionsStatus.find(c => c.id === actionStatusId);

    let values = {};

    if (selectedClient) {
        values[ENGAGEMENT_COLUMNS_FILTERS.CLIENT] = {
            id: selectedClient.id,
            value: selectedClient.name
        };
    }

    if (thirdParty) {
        values[ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY] = {
            id: thirdParty,
            value: thirdParty
        };
    }

    if (selectCategory) {
        values[ENGAGEMENT_COLUMNS_FILTERS.CATEGORY] = {
            id: selectCategory?.id,
            value: selectCategory?.name
        };
    }

    if (selectWorkflowAction) {
        values[ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION] = {
            id: selectWorkflowAction?.id,
            value: selectWorkflowAction?.name
        };
    }

    if (actionStatus) {
        values[ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION_STATUS] = {
            id: actionStatus?.id,
            value: actionStatus?.name
        };
    }

    return values;
};

export const isArchiveToggleAuthorized = (engagement, user, requestedArchiveToggleType) => {
    if (!engagement) return false;
    let isAuthorized = false;
    switch (requestedArchiveToggleType) {
        case 'Archive':
            isAuthorized = !engagement.archived && engagement.isEditable && hasEditionRights(engagement, user);
            break;
        case 'Unarchive':
            isAuthorized =
                engagement.archived &&
                engagement.isEditable &&
                (user?.profil === PROFILE.SUPER_ADMIN || isAuthorisedProfile(PROFILE.ADMIN, user?.profil));
            break;
        default:
            break;
    }

    return isAuthorized;
};
