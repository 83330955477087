import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const EmailTemplateAlertSection = ({
    languages,
    isMissingEmailTemplateTypes,
    missingEmailTemplateTypes,
    isMissingEmailTemplateLanguages,
    missingEmailTemplateLanguages
}) => {
    const languageNameWrapper = (languageId, languages) => {
        const [selectedLanguage] = languages.filter(x => x?.id === languageId);
        return selectedLanguage?.name;
    };

    const templateslanguagesToString = templates =>
        templates.map(x => languageNameWrapper(x?.languageId, languages)).join(', ');

    const emailTypesToString = templates => templates.map(x => x.name).join(', ');

    return (
        <>
            {isMissingEmailTemplateTypes && (
                <Alert severity="error">
                    <FormattedMessage
                        id={'EMAIL_TEMPLATE.ALERT.TYPE.MISSING.LABEL'}
                        values={{ missingEmailTemplates: emailTypesToString(missingEmailTemplateTypes) }}
                    />
                </Alert>
            )}

            {React.Children.toArray(
                Object.keys(missingEmailTemplateLanguages).map(emailTypeName => {
                    return (
                        isMissingEmailTemplateLanguages[emailTypeName] && (
                            <Alert severity="error">
                                <FormattedMessage
                                    id={'EMAIL_TEMPLATE.ALERT.DEFAULT.LANGUAGE.MISSING.LABEL'}
                                    values={{
                                        emailTypeName: emailTypeName,
                                        missingEmailLanguages: templateslanguagesToString(
                                            missingEmailTemplateLanguages[emailTypeName]
                                        )
                                    }}
                                />
                            </Alert>
                        )
                    );
                })
            )}
        </>
    );
};

EmailTemplateAlertSection.propTypes = {
    languages: PropTypes.array,
    isMissingEmailTemplateTypes: PropTypes.bool,
    missingEmailTemplateTypes: PropTypes.array,
    isMissingEmailTemplateLanguages: PropTypes.object,
    missingEmailTemplateLanguages: PropTypes.object
};
