import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SessionStatus } from '../../kit/utils/constants';
import * as actions from '../../store/actions';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import './auth.scss';
import { accountRepository } from '../../kit/repository';
import { withCheckSession } from '../../enhancers';
import { compose } from 'redux';
import { fetchClusters, fetchData, fetchLanguages, fetchUserProfile } from '../../services';
import { createToast, getToastType } from '../../components/toast/Toast';
import { AuthContent } from '../../components/auth/AuthContent';
import { getDefaultRouteByProfile } from '../../navigation/RouterHelpers';
import { Box, Paper } from '@mui/material';
import { SESSION_TYPE, PROFILE } from '../../types/types';
import { ROUTES } from '../../navigation/Routes';
import { toAbsoluteUrl } from '../../helpers';
import inMemoryTokenManager from '../../kit/network/inMemoryToken';

/**
 * Authentication page component for all users.
 *
 * This login page require Mazars credentials for the user connection.
 *
 * @visibleName Auth
 */
class AuthComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkSessionResults: [],
            loading: false
        };
    }

    // abortController = new AbortController();

    handleSignIn = async (email, password, countryCode) => {
        const { intl, setUserConnection, addToastMessage, setSessionProfile } = this.props;
        this.setState({ loading: true });

        const result = await accountRepository.login(email, password, countryCode);
        
        if (result.isSuccess) {
            inMemoryTokenManager.setToken(result.parsedValue.token);
            
            await fetchUserProfile(this, false);
            
            setUserConnection(inMemoryTokenManager.isTokenAvailable());
            setSessionProfile();
           
            this.setState({ loading: false });
        } else {
            let description = !result.isUnauthorizedUserError
                ? result?.errorMessage
                    ? intl.formatMessage({ id: result?.errorMessage })
                    : result?.error
                : '';

            addToastMessage(
                createToast(
                    getToastType(SessionStatus.unknown),
                    intl.formatMessage({ id: 'AUTH.CREDENTIALS.ERROR' }),
                    description
                )
            );

            this.setState({ loading: false });
        }

        return result;
    };

    async componentDidMount() {
        await fetchData(this, [() => fetchClusters(this), () => fetchLanguages(this)]);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const { user, setDefaultPath } = this.props;
        if (user !== prevProps.user && user.profil) setDefaultPath(getDefaultRouteByProfile(user.profil));
    }

    // componentWillUnmount() {
    //     this.abortController.abort();
    // }

    render() {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gridRowGap: 30,
                    marginY: 10
                }}>
                <Link to={ROUTES.AUTH} className="mt-5">
                    <img alt="Logo" className="max-h-150px" src={toAbsoluteUrl('/media/logos/ForvisMazars-Logo-Color-CMYK.png')} />
                </Link>
                <Paper elevation={3}>
                    <AuthContent
                        {...this.state}
                        loading={this.state.loading}
                        clusters={this.props.clusters}
                        handleSignIn={this.handleSignIn}
                    />
                </Paper>
            </Box>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserConnection: isConnected => {
            dispatch(actions.setConnection(isConnected, SESSION_TYPE.MAZARS));
        },
        setSessionProfile: () => {
            // TODO: get main profile of the user
            dispatch(actions.setSessionProfile(PROFILE.AUDITOR));
        },
        saveProfile: profile => {
            dispatch(actions.setProfile(profile));
            dispatch(actions.setCurrentClusterId(profile.clusterId));
        },
        logoutUser: () => {
            dispatch(actions.resetUser());
            dispatch(actions.flushCurrentCluster());
        },
        setDefaultPath: defaultPath => {
            dispatch(actions.setDefaultPath(defaultPath));
        },
        addToastMessage: message => {
            dispatch(actions.addToastMessage(message));
        },
        saveClusters: clusters => {
            dispatch(actions.setClusters(clusters));
        },
        saveLanguages: languages => {
            dispatch(actions.setLanguages(languages));
        }
    };
};

const mapStateToProps = state => {
    return {
        user: state.account.user,
        sessionProfile: state.app.sessionProfile,
        defaultPath: state.app.defaultPath,
        clusters: state.cluster.clusters,
        languages: state.cluster.languages
    };
};

export const auth = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(AuthComponent));
