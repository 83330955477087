export const common = {
    'COMMON.HELP': 'Help',
    'COMMON.LOADING.IN.PROGRESS': 'loading ...',
    'APPLICATION.NAME': 'Confirmations',
    'PAGE_NOT_FOUND.TITLE': "We can't let you see this page",
    'PAGE_NOT_FOUND.PARAGRAPH_PART_1': 'To access this page, you may need to ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_2_LINK': 'log in with another account. ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_3': 'You can go back to the ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_4_LINK': 'previous page ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_5': 'or go to the ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_6_LINK': 'home.',
    'DEFAULT_ERROR_MESSAGE.TITLE': 'Error',
    'DEFAULT_ERROR_MESSAGE.MESSAGE': 'Something goes wrong',
    'INVALID.FIELD.MIN.SYMBOLS': 'Minimum {min} symbols',
    'INVALID.FIELD.MAX.SYMBOLS': 'Maximum {max} symbols',
    'INVALID.FIELD.MIN.NUMBER': '{min} is minimum',
    'INVALID.FIELD.MAX.NUMBER': '{max} is maximum',
    'INVALID.FIELD.GUID.REGEX': 'Id format invalid',
    'INVALID.FIELD.EMAIL.FORMAT': 'Invalid email format',
    'PLACEHOLDER.END.OF.FILE': 'Placeholder end of file',
    'COMMON.SEARCH': 'Search',
    'COMMON.BUTTON.BACK.LABEL': 'Back',
    'COMMON.POWERED_BY.LABEL': 'Powered by Global Technology & Innovation'
};
