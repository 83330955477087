import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage,useIntl } from 'react-intl';
import { Alert } from '@mui/material';
import { CreateConfirmationProgress, CreationProcessStep } from '../common';
import { SelectLetterTemplate } from '../manual';
import {
    initConfirmationImport,
    IMPORT_CONFIRMATION_COLUMN_NAMES,
    IMPORT_CONFIRMATION_MESSAGES
} from './InitImportedConfirmations';
import { SelectImportParametersComponent } from './SelectImportParameters';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { useStepper } from '../../../hooks/useStepper';
import { makeStyles } from '@material-ui/core/styles';
import { withCreateConfirmation } from '../../../enhancers/withCreateConfirmation';
import { StepperFooterActions } from '../../stepper/StepperFooterActions';
import {
    formatGenerateFileRequest,
    expectedOptionAnswersNumber,
} from '../../../helpers';

function getCreationSteps() {
    return [
        {
            key: CreationProcessStep.TEMPLATE,
            title: <FormattedMessage id="CREATE_CONFIRMATION.STEP_TEMPLATE.LABEL" />,
            isActive: true
        },
        {
            key: CreationProcessStep.PARAMETERS,
            title: <FormattedMessage id="CREATE_CONFIRMATION.STEP_PARAMETERS.LABEL" />,
            isActive: false
        }
    ];
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    backButton: {
        marginRight: theme.spacing(1)
    }
}));

function CreateImportTemplateComponent(props) {
    const { engagement, templates, languages, confirmationCategories, generateExcelFile, loading } = props;
    
    const intl = useIntl();
    const classes = useStyles();
    const [stepErrorMessage, setStepErrorMessage] = useState('');
    const [config, setConfig] = useState({ ...initConfirmationImport, engagementId: engagement?.id });
    const [isSubmitAuthorised, setIsAuthorisedSubmit] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const {
        steps,
        activeSteps,
        activeStep,
        firstStep,
        lastStep,
        activeStepPosition,
        nextStep,
        previousStep,
        update
    } = useStepper({
        initialSteps: getCreationSteps(),
        initialPosition: 0
    });

    useEffect(() => {
        setConfig((previousConfig) => { return { ...previousConfig, engagementId: engagement?.id } } );
        setIsAuthorisedSubmit(false);
        setIsSelected(false);
    }, [engagement]);

    function handlePrepRequest (values){
        const fileRequest = formatGenerateFileRequest(
            intl.formatMessage({ id: 'IMPORT.CONFIRMATION.EXCEL.FILE.NAME.LABEL' }),
            intl.formatMessage({
                id: 'IMPORT.CONFIRMATION.EXCEL.FILE.WORKSHEETNAME.LABEL'
            }),
            { ...IMPORT_CONFIRMATION_COLUMN_NAMES, ...IMPORT_CONFIRMATION_MESSAGES },
            intl
        );

        return { ...values, labels: { ...fileRequest } };
    };

    async function handleGenerate () {
        const request = handlePrepRequest(config);
        await generateExcelFile(request);
    };

    const handleGenerateCallback = useCallback(handleGenerate, [handleGenerate]);

    useEffect(() => {       
        if (activeStep === lastStep && isSubmitAuthorised && isSelected) {
            handleGenerateCallback();
            setIsAuthorisedSubmit(false);
            setIsSelected(false);
        };
    }, [config, activeStep, lastStep, isSubmitAuthorised, isSelected, handleGenerateCallback]);

    function handleTemplateSelect (values) {
        const { onLetterTemplateChanged } = props;

        const callback = (newSteps, hasStackableWorkflowActions, updatedValues) => {
            if (hasStackableWorkflowActions) 
                update(newSteps, CreationProcessStep.PARAMETERS);
            else
                setIsAuthorisedSubmit(true);

            setConfig((previousConfig) => { return { ...previousConfig, ...updatedValues } } );
            setIsSelected(true);
        };

        onLetterTemplateChanged(values, steps, callback);
    };

    function handleSelectStackableStep (selectedStackedAction, stackedSteps) {
        const { onStackableStepChanged } = props;

        const callback = updatedActions => {
            setConfig({
                ...config,
                workflowActions: updatedActions,
                activeStackedWorkflowActions: {
                    ...config.activeStackedWorkflowActions,
                    ...{ [selectedStackedAction.position]: selectedStackedAction.workflowActionId }
                }
            });
        };

        onStackableStepChanged(selectedStackedAction, stackedSteps, config.workflowActions, callback);
    };

    function validateParametersStep(config, onValidation) {
        const expectedStackedWorkflowActionsNumber = expectedOptionAnswersNumber(config?.workflowActions);

        if (Object.keys(config.activeStackedWorkflowActions).length === expectedStackedWorkflowActionsNumber) {
            setStepErrorMessage('');
            onValidation();
        } else {
            setStepErrorMessage(
                intl.formatMessage({ id: 'CREATE_CONFIRMATION.VALIDATION.REQUIRED_STEPS.STACKED_STEPS' })
            );
        }
    };

    function handleStepAction(activeStep, action) {
        switch (activeStep) {
            case CreationProcessStep.TEMPLATE:
                action();
                break;

            case CreationProcessStep.PARAMETERS:
                validateParametersStep(config, action)
                break;

            default:
                break;
        }
    }

    function handleNext() {
        handleStepAction(activeStep, nextStep);
    }

    function handleSave () {
        handleStepAction(activeStep, handleGenerate);
    }

    function handlePrevious() {
        previousStep();
    }

    return (
        <div className="add-confirmation mt-5">
            <div className={classes.root}>

                {activeSteps.length > 1 && (
                    <CreateConfirmationProgress activeStepPosition={activeStepPosition} activeSteps={activeSteps} />
                )}
                
                {stepErrorMessage && (
                    <Alert severity="error" onClose={() => setStepErrorMessage('')}>
                        {FormattedErrorMessage(stepErrorMessage)}
                    </Alert>
                )}

                <div>
                    <div className="content">
                        <div className="mb-10">

                            {activeStep === CreationProcessStep.TEMPLATE && (
                                <SelectLetterTemplate
                                    templates={templates}
                                    languages={languages}
                                    confirmationCategories={confirmationCategories}
                                    handleSelect={handleTemplateSelect}
                                />
                            )}

                            {activeStep === CreationProcessStep.PARAMETERS && (
                                <SelectImportParametersComponent
                                    parameters={config}
                                    handleSelectStackableStep={handleSelectStackableStep}
                                />
                            )}
                        </div>

                        <StepperFooterActions
                            isNextStepDisabled={activeStep === firstStep}
                            loading={loading}
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            handleSave={handleSave}
                            isFirstStep={activeStep === firstStep}
                            isLastStep={activeStep === lastStep}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export const CreateImportTemplate = withCreateConfirmation(CreateImportTemplateComponent);
