import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { CircularProgress } from '@mui/material';
import { EmailTemplateConfigWrapper, DEFAULT_CONFIRMATION_TEMPLATE_CATEGORY } from '../../components/setup';
import {
    fetchData,
    fetchConfigurationChecks,
    fetchConfirmationCategories,
    EmailTemplateService,
    LetterTemplateService
} from '../../services';

class EmailTemplateSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };

        this.emailTemplateManager = EmailTemplateService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading,
            callback: async (isSuccess, _data, _) => isSuccess && await this.fetchEmailTemplates({clusterId: this.props.currentClusterId})
        });

        this.letterTemplateManager = LetterTemplateService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading,
            callback: async (isSuccess, _data, _) => isSuccess && await this.fetchLetterTemplates({clusterId: this.props.currentClusterId})
        });
    }

    setLoading = isLoading => {
        this.setState({ loading: isLoading });
    };

    fetchEmailTemplates = async filters => {
        const result = await this.emailTemplateManager.fetchAll(filters);
        if (result?.isSuccess) {
            this.setState({ emailTemplates: Object.values(result.parsedValue) });
        }
    };

    fetchEmailTypes = async () => {
        const result = await this.emailTemplateManager.fetchTypes();
        if (result?.isSuccess) {
            this.setState({ emailTypes: Object.values(result.parsedValue) });
        }
    };

    fetchLetterTemplates = async filters => {
        const result = await this.letterTemplateManager.fetchAll(filters);
        if (result?.isSuccess) {
            this.setState({ letterTemplates: Object.values(result.parsedValue) });
        }
    };

    async componentDidMount() {
        await fetchData(this, [
            () => fetchConfirmationCategories(this),
            () => fetchConfigurationChecks(this, {clusterId: this.props.currentClusterId}),
            () => this.fetchEmailTypes(),
            () => this.fetchEmailTemplates({clusterId: this.props.currentClusterId}),
            () => this.fetchLetterTemplates({clusterId: this.props.currentClusterId})
        ]);

        // trick to add a default category (no category selected) to confirmationCategories
        this.state.confirmationCategories &&
            this.state.confirmationCategories.push(DEFAULT_CONFIRMATION_TEMPLATE_CATEGORY);
    };

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            if (!this.state.loading)
                await fetchData(this, [
                    () => fetchConfigurationChecks(this, {clusterId: this.props.currentClusterId}),
                    () => this.fetchEmailTemplates({clusterId: this.props.currentClusterId}),
                    () => this.fetchLetterTemplates({clusterId: this.props.currentClusterId})
                ]);
        }
    };

    render() {
        const { languages } = this.props;
        const {
            configurationsChecks,
            confirmationCategories,
            emailTypes,
            emailTemplates,
            letterTemplates
        } = this.state;

        return (
            <>
                {emailTypes ? (
                    <EmailTemplateConfigWrapper
                        currentClusterId={this.props.currentClusterId}
                        configurationsChecks={configurationsChecks}
                        confirmationCategories={confirmationCategories}
                        emailTypes={emailTypes}
                        emailTemplates={emailTemplates}
                        languages={languages}
                        letterTemplates={letterTemplates}
                        createEmailTemplate={this.emailTemplateManager.create}
                        updateEmailTemplate={this.emailTemplateManager.update}
                        deleteEmailTemplate={this.emailTemplateManager.delete}
                    />
                ) : (
                    <div className="fullCenter">
                        <CircularProgress />
                    </div>
                )}
            </>
        );
    }
}

EmailTemplateSetupComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array,
    languages: PropTypes.array.isRequired
};

EmailTemplateSetupComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        languages: state.cluster.languages,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const EmailTemplateSetup = compose(
    //withMenu, // need to add this enhancer to have the side bar menu
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(EmailTemplateSetupComponent));
