import { CLIENT_CONTACT_INVITATION_STATUS } from '../types/types';

export const isNewContact = (contact, allContacts) => {
    return !allContacts.find(x => x.id === contact.id);
};

export const saveContact = async ({ contact, allContacts, create, update, callback }) => {
    let selectedContacts =
        allContacts.filter(
            currentContact => currentContact?.email !== contact?.email && currentContact?.id !== contact?.id
        ) ?? [];

    let isSuccess;
    if (isNewContact(contact, allContacts)) {
        const result = await create(contact);
        if (result?.parsedValue?.ids?.length > 0) {
            selectedContacts.push({ ...contact, id: result.parsedValue.ids[0] });
        }

        isSuccess = result?.isSuccess;
    } else {
        const result = await update(contact);
        if (result?.isSuccess) {
            selectedContacts.push(contact);
        }

        isSuccess = result?.isSuccess;
    }

    await callback({ isSuccess: isSuccess, selectedContacts: selectedContacts });
};

export const hasUniqueSignatoryContact = contacts => {
    if (contacts?.length > 0) {
        return contacts.filter(contact => contact.isSignatory).length === 1;
    } else {
        return true;
    }
};

export const hasSomeRecipientsContacts = contacts => {
    if (contacts?.length > 0) {
        return contacts.some(contact => contact.isRecipient);
    } else {
        return true;
    }
};

export const contactRoleValidator = ({
    contacts,
    intl,
    message = '',
    signatoryMessageId = 'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_SIGNATORY'
}) => {
    const hasUniqueSignatory = hasUniqueSignatoryContact(contacts);
    const hasSomeRecipients = hasSomeRecipientsContacts(contacts);

    return {
        hasValidRecipientContacts: hasSomeRecipients,
        hasValidSignatoryContacts: hasUniqueSignatory,
        hasValidContacts: hasSomeRecipients && hasUniqueSignatory,
        errorMessage: `${message}${
            !hasUniqueSignatory
                ? intl.formatMessage({
                      id: signatoryMessageId
                  })
                : ''
        }${
            !hasSomeRecipients
                ? intl.formatMessage({
                      id: 'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_RECIPIENT'
                  })
                : ''
        }`
    };
};

export const shortUserLabel = user => {
    const labelArray =
        user.firstName || user.lastName
            ? `${user.firstName} ${user.lastName}`.split(' ')
            : !!user.email
            ? user.email.split('.')
            : '';

    return !labelArray ? '' : labelArray?.map(x => x.substring(0, 1).toUpperCase());
};

export const getExistingContactWithinCompany = (company, contact) => {
    return company?.contacts.filter(currContact => currContact.email === contact.email);
};

/*
        Only super admins or engagement's managers can invite client contact
        Only client contact that are saved and has not been invited yet
 */

export const canInviteContact = contact => {
    return contact.id && contact?.invitationStatus?.status === CLIENT_CONTACT_INVITATION_STATUS.NOT_INVITED;
};

/* 
        Only super admins or engagement's managers can invite client contact
        Only client contact that are saved and has been invited
 */

export const canManageAccessRights = contact => {
    return (
        contact.id &&
        [
            CLIENT_CONTACT_INVITATION_STATUS.INVITED_CONNECTED,
            CLIENT_CONTACT_INVITATION_STATUS.INVITED_NOT_CONNECTED
        ].includes(contact?.invitationStatus?.status)
    );
};

export const colorByInvitationStatus = status => {
    switch (status) {
        case CLIENT_CONTACT_INVITATION_STATUS.NOT_INVITED:
            return 'red';
        case CLIENT_CONTACT_INVITATION_STATUS.INVITED_NOT_CONNECTED:
            return 'orange';
        case CLIENT_CONTACT_INVITATION_STATUS.INVITED_CONNECTED:
            return 'green';

        default:
            return 'blue';
    }
};
