import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { store, persistor } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { I18nProvider } from './i18n';
import { PageNotFound } from './screens/pageNotFound/PageNotFound';
import DefaultLayout from './layout/DefaultLayout';
import { ToastWrapper } from './components/toast/ToastWrapper';
import { AppRoutes, RouteWrapper } from './navigation/RouterHelpers';
import * as SyncfusionService from './services/SyncfusionService';
import { ThemeProvider } from '@emotion/react';
import { MZRTheme } from './styles/MZRTheme';

class AppComponent extends Component {
    componentDidMount() {
        SyncfusionService.register();
    }

    render() {
        return (
            <I18nProvider>
                <Router basename={process.env.REACT_APP_BASENAME || ''}>
                    <ToastWrapper>
                        <Switch>
                            {AppRoutes.map((routeInfo, key) => (
                                <RouteWrapper exact key={key} {...routeInfo} />
                            ))}
                            <RouteWrapper component={PageNotFound} layout={DefaultLayout} />
                        </Switch>
                    </ToastWrapper>
                </Router>
            </I18nProvider>
        );
    }
}

export const App = connect(null, null)(AppComponent);

const AppContainer = () => (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ThemeProvider theme={MZRTheme}>
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

export default AppContainer;
