// import { FORM_FIELD } from "../../../../helpers";

export const DATES_FIELDS = [
    // {
    //     labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.CLIENT.SENDDATE.LABEL' },
    //     DateFieldItem: {
    //         id: 'clientSendDate',
    //         disablePast: true,
    //         disabled: !props.automation
    //     }
    // },
    {
        itemKey: '0',
        labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.CLIENT.DUEDATE.LABEL', isRequired: true },
        DateFieldItem: {
            id: 'clientDueDate',
            disablePast: true,
            locale: 'fr-fr',    // TODO: get format from language
            variant: 'outlined',
        }
    },
    // {
    //     labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.SENDDATE.LABEL' },
    //     DateFieldItem: {
    //         id: 'thirdPartySendDate',
    //         disablePast: true,
    //         disabled: !props.automation
    //     }
    // },
    {
        itemKey: '1',
        labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.DUEDATE.LABEL', isRequired: true },
        DateFieldItem: {
            id: 'thirdPartyDueDate',
            disablePast: true,
            locale: 'fr-fr',    // TODO: get format from language
            variant: 'outlined',
        }
    }
];


// to use with useForm Hook
// export const DATES_FIELDS = [
//     // {
//     //     id: 'clientSendDate',
//     //     fieldType: FORM_FIELD.DATE_FIELD,
//     //     labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.CLIENT.SENDDATE.LABEL' },
//     //     disablePast: true,
//     //     color: 'primary'
//     // },
//     {
//         id: 'clientDueDate',
//         fieldType: FORM_FIELD.DATE_FIELD,
//         labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.CLIENT.DUEDATE.LABEL', isRequired: true },
//         disablePast: true,
//         color: 'primary'
//     },
//     // {
//     //     id: 'thirdPartySendDate',
//     //     fieldType: FORM_FIELD.DATE_FIELD,
//     //     labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.SENDDATE.LABEL' },
//     //     disablePast: true,
//     //     color: 'primary'
//     // },
//     {
//         id: 'thirdPartyDueDate',
//         fieldType: FORM_FIELD.DATE_FIELD,
//         labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.DUEDATE.LABEL', isRequired: true },
//         disablePast: true,
//         color: 'primary'
//     }
// ];
