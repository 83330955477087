import * as actions from '../actions';
import { EMPTY_GUID } from '../../kit/utils/constants';

const initialState = {
    clusters: [],
    currentClusterId: EMPTY_GUID,
    languages: []
};

export const clusterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.ClusterTypes.SET_CLUSTERS:
            return { ...state, clusters: action.clusters };

        case actions.ClusterTypes.SET_CURRENT_CLUSTER_ID:
            return { ...state, currentClusterId: action.clusterId };

        case actions.ClusterTypes.FLUSH_CURRENT_CLUSTER:
            return { ...state, currentClusterId: EMPTY_GUID };

        case actions.ClusterTypes.SET_LANGUAGES:
            return { ...state, languages: action.languages };

        default:
            return state;
    }
};
