import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader } from '../../partials/controls/Card';
import { Grid } from '@mui/material';
import { FormLabel, getFieldElement } from '../../helpers';

export function DeleteMessageComponent(props) {
    const {
        data = {},
        user,
        handleSubmit,
        identifier,
        strings: { contentId, commentId }
    } = props; // ex identifier= {key: id, value: 1} where value is the id of a confirmation or Engagement

    const initialValues = { [identifier.key]: identifier.value, comment: '', ...data };

    const btnRef = useRef();
    const triggerButtonClick = () => btnRef?.current?.click();
    props.btnActionRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const MSG_FIELDS = [
        {
            labelItem: { id: commentId },
            TextFieldItem: {
                id: 'comment',
                component: 'textarea',
                rows: 5,
                variant: 'outlined'
            }
        }
    ];

    return (
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={values => handleSubmit(values)}>
            {() => (
                <Form>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center" spacing={1}>
                        <Card>
                            <CardHeader
                                title={
                                    <FormattedMessage id={contentId} values={{ userName: user?.fullname }} />
                                }></CardHeader>
                            <CardBody>
                                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                    {MSG_FIELDS?.map(field => (
                                        <Grid
                                            container
                                            direction="column"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '0 20%'
                                            }}>
                                            <Grid item>
                                                <label>
                                                    <FormLabel
                                                        id={field?.labelItem?.id}
                                                        isRequired={field?.labelItem?.isRequired}
                                                    />
                                                </label>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: 'stretch',
                                                    flexDirection: 'column',
                                                    width: '22em'
                                                }}
                                                item>
                                                {getFieldElement(field)}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>
                    <button type="submit" style={{ display: 'none' }} onClick={triggerButtonClick} ref={btnRef} />
                </Form>
            )}
        </Formik>
    );
}
