import React from 'react';
import { HOME_COLUMNS_FILTERS } from './Constants';
import { HomeToolbar } from './HomeToolbar';
import { useFilterTable } from '../../hooks/useFilterTable';
import { ClientEngagementTable } from '../engagement';

interface ClientHomeContentProps {
    handleFilterChange: (filters: Record<string, any>) => void;
    filters: Record<string, any>;
    engagements: any;
}

export function ClientHomeContent({
    handleFilterChange,
    filters: { isArchived },
    engagements
}: ClientHomeContentProps) {
    const { isFilteringMode, filterableColumnsValues, onFilterClicked, onFilterTableChanged } = useFilterTable(
        HOME_COLUMNS_FILTERS,
        handleFilterChange
    );

    const handleArchiveViewChange = () => {
        handleFilterChange({ isArchived: !isArchived });
    };

    return (
        <>
            <HomeToolbar
                handleArchiveViewChange={handleArchiveViewChange}
                isArchiveMode={isArchived}
                isFilteringMode={isFilteringMode}
                onFilterClicked={onFilterClicked}
                filterableColumns={HOME_COLUMNS_FILTERS}
                handleGlobalFiltersChange={handleFilterChange}
            />

            <ClientEngagementTable
                paginatedEngagements={engagements}
                handleFilterChange={handleFilterChange}
                isFilteringMode={isFilteringMode}
                columnsFiltersValues={filterableColumnsValues}
                handleFilterEngagementsChange={onFilterTableChanged}
            />
        </>
    );
}
