import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseShort } from '../parsers';
import {
    parseStorageConfiguration,
    parseStorageConfigurationDefault,
    parseStorageConfigurationSharePointOnline
} from './setupParsers';
import { Repository } from '../Repository';

class StorageConfigurationRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchCountry = async (clusterId) => {
        let result = await this.network.get(`storageconfiguration/cluster/${clusterId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseStorageConfiguration);
        return result;
    };

    fetchSharePointOnline = async (configurationId) => {
        let result = await this.network.get(`storageconfiguration/sharepointonline/${configurationId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseStorageConfigurationSharePointOnline);
        return result;
    };

    updateSharePointOnline = async (configurationId, configuration) => {
        let result = await this.network.put(`storageconfiguration/sharepointonline/${configurationId}`, configuration, {
            headers: this.buildHeaders()
        });

        return result;
    };

    deleteSharePointOnline = async (configurationId) => {
        return await this.network.delete(`storageconfiguration/sharepointonline/${configurationId}`, null, {
            headers: this.buildHeaders()
        });
    };

    fetchSharePointOnlineCountry = async (clusterId) => {
        let result = await this.network.get(`storageconfiguration/sharepointonline/cluster/${clusterId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseStorageConfigurationSharePointOnline);
        return result;
    };

    fetchDefault = async (configurationId) => {
        let result = await this.network.get(`storageconfiguration/default/${configurationId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseStorageConfigurationDefault);
        return result;
    };

    updateDefault = async (configurationId, configuration) => {
        let result = await this.network.put(`storageconfiguration/default/${configurationId}`, configuration, {
            headers: this.buildHeaders()
        });

        return result;
    };

    deleteDefault = async (configurationId) => {
        return await this.network.delete(`storageconfiguration/default/${configurationId}`, null, {
            headers: this.buildHeaders()
        });
    };

    fetchDefaultCountry = async (clusterId) => {
        let result = await this.network.get(`storageconfiguration/default/cluster/${clusterId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseStorageConfigurationDefault);
        return result;
    };

    createSharePointOnline = async (configuration) => {
        let result = await this.network.post(`storageconfiguration/sharepointonline`, configuration, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    createDefault = async (configuration) => {
        let result = await this.network.post(`storageconfiguration/default`, configuration, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };
}

export const storageConfigurationRepository = new StorageConfigurationRepository();
