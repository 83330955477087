import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseShort } from '../parsers';
import { parseTags } from './confirmationParsers';
import { Repository } from '../Repository';

class TagRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`tag`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseTags);
        return result;
    };

    create = async (tag) => {
        let result = await this.network.post(`tag`, tag, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    update = async (tagId, tag) => {
        return await this.network.put(`tag/${tagId}`, tag, {
            headers: this.buildHeaders()
        });
    };

    delete = async (tagId) => {
        return await this.network.delete(`tag/${tagId}`, null, {
            headers: this.buildHeaders()
        });
    };
}

export const tagRepository = new TagRepository();
