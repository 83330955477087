import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';
import { initUserResourceConfig, E_USER_RESOURCE_TYPES } from './InitUserResourceConfig';
import TextInput from '../../textField/TextInput';
import { useForm } from '../../../hooks/useForm';
import { FORM_FIELD, UPLOAD_TYPES } from '../../../helpers';

const UserResourceConfigComponent = props => {
    const { intl, userResourceConfig, handleSubmit, languages, isPreview } = props;
    const variant = isPreview ? 'standard' : 'outlined';
    const buttonUserResourceFormRef = useRef(null);
    const [currentUserResourceConfig, setCurrentUserResourceConfig] = useState(initUserResourceConfig);

    useEffect(() => {
        userResourceConfig && setCurrentUserResourceConfig(userResourceConfig);
    }, [userResourceConfig]);

    const triggerButtonClick = () => buttonUserResourceFormRef?.current?.click();
    props.userResrceConfigRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const UserResourceConfigSchema = Yup.object().shape({
        clusterId: Yup.string().nullable(true),
        eUserResourceType: Yup.number().min(
            1,
            intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.TYPE' })
        ),
        name: Yup.string()
            .nullable(true)
            .min(5, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 5 }))
            .max(255, intl.formatMessage({ id: 'INVALID.FIELD.MAX.SYMBOLS' }, { max: 255 }))
            .required(intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.NAME' })),
        description: Yup.string()
            .nullable(true)
            .min(20, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 20 }))
            .max(500, intl.formatMessage({ id: 'INVALID.FIELD.MAX.SYMBOLS' }, { max: 500 }))
            .required(intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.DESCRIPTION' })),
        languageId: Yup.string()
            .nullable(true)
            .required(intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.LANGUAGE' })),
        url: Yup.string()
            .nullable(true)
            .when('eUserResourceType', {
                is: eUserResourceType =>
                    [E_USER_RESOURCE_TYPES.LINK, E_USER_RESOURCE_TYPES.VIDEO_LINK].includes(eUserResourceType),
                then: Yup.string().required(
                    intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.URL' })
                )
            }),
        file: Yup.mixed()
            .nullable(true)
            .when('eUserResourceType', {
                is: E_USER_RESOURCE_TYPES.FILE,
                then: Yup.mixed().required(
                    intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.FILE' })
                )
            })
    });

    const SELECT_USER_RESOURCE_TYPES = [
        { id: 0, name: 'None' },
        {
            id: E_USER_RESOURCE_TYPES.FILE,
            name: intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.TYPE.FILE.NAME' })
        },
        {
            id: E_USER_RESOURCE_TYPES.LINK,
            name: intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.TYPE.LINK.NAME' })
        },
        {
            id: E_USER_RESOURCE_TYPES.VIDEO_LINK,
            name: intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.TYPE.VIDEO_LINK.NAME' })
        },
        {
            id: E_USER_RESOURCE_TYPES.TEXT,
            name: intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.TYPE.TEXT.NAME' })
        }
    ];

    const USER_RESOURCE_FIELDS = [
        {
            id: 'eUserResourceType',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'USER.RESOURCE.CONFIGURATION.FIELD.TYPE.LABEL', isRequired: true },
            options: SELECT_USER_RESOURCE_TYPES,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'name',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'USER.RESOURCE.CONFIGURATION.FIELD.NAME.LABEL', isRequired: true },
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'description',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'USER.RESOURCE.CONFIGURATION.FIELD.DESCRIPTION.LABEL', isRequired: true },
            component: 'textarea',
            rows: 4,
            variant: 'outlined',
            disabled: isPreview
        },
        {
            id: 'languageId',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'USER.RESOURCE.CONFIGURATION.FIELD.LANGUAGE.LABEL', isRequired: true },
            options: languages ? languages : [{ id: 0, name: 'None' }],
            variant: variant,
            disabled: isPreview
        }
    ];

    const USER_RESOURCE_LINK_FIELDS = [
        {
            id: 'url',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'USER.RESOURCE.CONFIGURATION.FIELD.URL.LABEL', isRequired: true },
            component: TextInput,
            variant: variant,
            disabled: isPreview
        }
    ];

    const USER_RESOURCE_FILE_FIELDS = [
        {
            id: 'file',
            fieldType: FORM_FIELD.INPUT_FIELD,
            labelItem: { id: 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.LABEL', isRequired: true },
            acceptedFiles: UPLOAD_TYPES.USER_RESOURCE,
            showPreviews: true,
            dropzoneText: intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.MSG' }),
            previewText: intl.formatMessage({
                id: 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.UPLOADED_FILES.LABEL'
            }),
            hidden: isPreview
        }
    ];

    const SUB_FORMS = [
        { id: E_USER_RESOURCE_TYPES.FILE, form: USER_RESOURCE_FILE_FIELDS },
        { id: E_USER_RESOURCE_TYPES.LINK, form: USER_RESOURCE_LINK_FIELDS },
        { id: E_USER_RESOURCE_TYPES.VIDEO_LINK, form: USER_RESOURCE_LINK_FIELDS }
    ];

    const { RenderForm } = useForm({
        enableReinitialize: true,
        initialValues: currentUserResourceConfig,
        validationSchema: UserResourceConfigSchema,
        handleSubmit: handleSubmit
    });

    return (
        <>
            <RenderForm
                formFields={USER_RESOURCE_FIELDS}
                conditionalFormKey="eUserResourceType"
                conditionalFields={SUB_FORMS}
                submitRef={buttonUserResourceFormRef}
                submitTrigger={triggerButtonClick}
            />
        </>
    );
};

export const UserResourceConfig = injectIntl(UserResourceConfigComponent);
