import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
    Check,
    CloseTwoTone,
    CloudUpload,
    Delete,
    DoDisturb,
    VisibilityOutlined
} from '@mui/icons-material';
import { Palette } from '../../styles/colors';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { UITooltip } from '../common/UITooltip';
import { getLocalizedString } from '../../i18n/i18Helper';
import { UICheckbox } from '../common/UICheckbox';
import IconButton from '@mui/material/IconButton';
import { initLetterHead } from './InitLetterHead';
import { LetterHeadProps } from './CompanyLetterHeadersContainer';
import { useIntl } from 'react-intl';

interface CompanyLetterHeaderActionsProps {
    disabled: boolean;
    deleteLetterHead: (letterhead: any) => Promise<void>;
    createLetterHead: (letterhead: any) => void;
    previewLetterhead: (letterhead: any) => void;
    company: any;
}

const CompanyLetterHeaderActions = ({
    company,
    disabled,
    deleteLetterHead,
    createLetterHead,
    previewLetterhead
}: CompanyLetterHeaderActionsProps) => {
    const onDelete = async () => {
        await deleteLetterHead(company.letterHead);
    };

    const onCreate = () => {
        createLetterHead({
            ...initLetterHead,
            engagementClientCompanyId: company.id
        });
    };

    const onPreview = () => {
        previewLetterhead({
            ...company.letterHead,
            engagementClientCompany: company
        });
    };

    return (
        <>
            {company.letterHead ? (
                <>
                    <IconButton
                        disabled={disabled || company.isBlankLetterHead}
                        color="secondary"
                        edge="end"
                        aria-label="details"
                        onClick={onPreview}>
                        <VisibilityOutlined id="display" color="primary" fontSize="medium" />
                    </IconButton>

                    <IconButton
                        disabled={disabled || company.isBlankLetterHead}
                        color="secondary"
                        edge="end"
                        aria-label="delete"
                        onClick={onDelete}>
                        <Delete fontSize="medium" />
                    </IconButton>
                </>
            ) : (
                <IconButton
                    disabled={disabled || company.isBlankLetterHead || company.isTemporaryId}
                    color="primary"
                    edge="end"
                    aria-label="add"
                    onClick={onCreate}>
                    <CloudUpload fontSize="medium" />
                </IconButton>
            )}
        </>
    );
};

export const CompanyLetterHeadListItem = ({
    company,
    disabled,
    deleteLetterHead,
    createLetterHead,
    displayDetails,
    toggleActiveLetterHead
}: { company: any } & LetterHeadProps) => {
    const intl = useIntl();
    const handleToggle = async () => {
        await toggleActiveLetterHead({
            ...company,
            isLetterHeadActivated: !company.isBlankLetterHead,
            isBlankLetterHead: !company.isBlankLetterHead
        });
    };
    return (
        <>
            <ListItem
                sx={{ height: '60px' }}
                secondaryAction={
                    <CompanyLetterHeaderActions
                        company={company}
                        disabled={disabled}
                        deleteLetterHead={deleteLetterHead}
                        createLetterHead={createLetterHead}
                        previewLetterhead={displayDetails}
                    />
                }>
                <ListItemIcon>
                    {company.isLetterHeadActivated ? (
                        company.letterHead ? (
                            <Check color="primary" fontSize="large" />
                        ) : (
                            <CloseTwoTone sx={{ color: Palette.red }} fontSize="large" />
                        )
                    ) : (
                        <DoDisturb fontSize="large" />
                    )}
                </ListItemIcon>

                <ListItemText sx={{ width: '50%' }} primary={company.name} />

                <ListItemText
                    primary={
                        <UITooltip
                            title={getLocalizedString(
                                intl,
                                'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.ACTIVATE.ACTION.TOOLTIP'
                            )}
                            children={
                                <UICheckbox
                                    name={`${company.id}-isLetterHeadActivated`}
                                    label={getLocalizedString(
                                        intl,
                                        'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.ACTION.LABEL'
                                    )}
                                    checked={company.isBlankLetterHead}
                                    onChange={handleToggle}
                                />
                            }
                        />
                    }
                />
            </ListItem>
        </>
    );
};
