import React from 'react';
import { UITreeItem } from '../../../../common/UITreeItem';
import { UITooltip } from '../../../../common/UITooltip';
import { ITEM_ERROR_TYPE } from '../../../../document/InitItem';
import { UIText } from '../../../../common/UIText';
import { iconForDocument } from '../../../../../kit/utils/resolver';
import { useIntl } from 'react-intl';
import { DOCUMENT_ITEM_TYPE, ItemNode } from '../../../../../types/types';
import { ConfirmationDocumentTreeItemActions } from './ConfirmationDocumentTreeItemActions';
import { Divider, Stack } from '@mui/material';
import { Palette } from '../../../../../styles/colors';
import { getDocumentDate, getDocumentInvalidPostalAddress, getDocumentLabelInfo } from '../../../../../helpers';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const DocumentLabelText = ({ node, itemErrors }: { node: ItemNode; itemErrors: Record<string, any> }) => {
    const intl = useIntl();

    return (
        <UITooltip
            wrap
            title={
                <>
                    <UIText text={`${node.description}` + (node.comment ? ` - ${node.comment}` : '')} />
                    {itemErrors && node.errorType !== ITEM_ERROR_TYPE.NONE ? (
                        <UIText
                            variant="body2"
                            sx={{ color: Palette.red }}
                            text={`${Object.keys(itemErrors).filter(key => itemErrors[key] === node.errorType)[0]}`}
                        />
                    ) : null}

                    {node?.isInvalidAddress ? (
                        <UIText
                            variant="body2"
                            sx={{ color: Palette.red }}
                            text={getDocumentInvalidPostalAddress(node, intl)}
                        />
                    ) : null}

                    <UIText variant="caption" color="inherit" text={getDocumentDate(node, intl)} />
                </>
            }
            children={
                <Stack direction="row">
                    <UIText text={`${node.name}${node.type === DOCUMENT_ITEM_TYPE.FILE ? node.fileExtension : ''}`} />
                    {node?.isInvalidAddress ? <WarningAmberIcon fontSize="small" sx={{ color: Palette.red }} /> : ''}
                </Stack>
            }
        />
    );
};

interface ConfirmationDocumentTreeItemProps {
    node: ItemNode;
    itemErrors: any;
    documentTypes: any[];
    handlePreview: (document: any) => Promise<any>;
    handleChange: (node: ItemNode) => Promise<any>;
    authorizedDownload;
    handleDownload;
    handleTrash;
    handleRestore;
    handleDelete;
}

export function ConfirmationDocumentTreeItem({
    node,
    itemErrors,
    handlePreview,
    handleChange,
    ...rest
}: ConfirmationDocumentTreeItemProps) {
    const intl = useIntl();

    const handleDocumentTypeChange = async (documentType: { id: number; name: string }) => {
        await handleChange({ ...node, documentType: documentType.id });
    };

    const handleOpenDocument = async (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        await handlePreview(node);
    };

    return (
        <>
            <UITreeItem
                key={node.id}
                nodeId={`nodeId-${node.id}`}
                labelText={<DocumentLabelText node={node} itemErrors={itemErrors} />}
                startIcon={iconForDocument(node.fileExtension?.toLowerCase())}
                labelInfo={getDocumentLabelInfo(node, intl)}
                actionButton={
                    node.type === DOCUMENT_ITEM_TYPE.FILE ? (
                        <ConfirmationDocumentTreeItemActions
                            node={node}
                            handleChange={handleDocumentTypeChange}
                            {...rest}
                        />
                    ) : undefined
                }
                onSelectItem={handleOpenDocument}>
                {/*render nested item children if any*/}
                {Array.isArray(node.childItems)
                    ? React.Children.toArray(
                          node.childItems.map(childNode => 
                            <ConfirmationDocumentTreeItem
                                key={childNode.id}
                                node={childNode}
                                handlePreview={handlePreview}
                                handleChange={handleChange}
                                itemErrors={itemErrors}
                                {...rest}
                            />)
                      )
                    : null}
            </UITreeItem>
            <Divider sx={{ width: '100%', color: Palette.dividerColor, height: '1px' }} />
        </>
    );
}
