import { deepFreeze } from '../../../helpers/FreezeHelper';

export const IMPORT_COLUMN_NAME = {
    internalIdColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.INTERNAL_ID.LABEL' },
    nameColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.NAME.LABEL' },
    addressColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ADDRESS.LABEL' },
    zipCodeColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ZIPCODE.LABEL' },
    cityColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.CITY.LABEL' },
    countryTextColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COUNTRY.LABEL' },
    companyIdColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER.LABEL' },
    companyId2ColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER2.LABEL' },
    vatIdColumnIndex: { id: 'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.VAT_NUMBER.LABEL' }
};

export const FILE_HEADER_INDEX = 0;
export const FILE_DISPLAY_ROWS_NUMBER = 5;

export const IMPORT_CLIENT_COLUMN_RESET_DB = {
    NO: 0,
    YES: 1
};
const InitClientImport = {
    file: null,
    worksheetName: null,
    resetDatabase: null,
    internalIdColumnIndex: 1,
    nameColumnIndex: 2,
    addressColumnIndex: 3,
    zipCodeColumnIndex: 4,
    cityColumnIndex: 5,
    countryTextColumnIndex: 6,
    companyIdColumnIndex: 7,
    companyId2ColumnIndex: 8,
    vatIdColumnIndex: 9
};

export const initClientImport = deepFreeze(InitClientImport);
