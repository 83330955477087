import { FormattedMessage } from 'react-intl';
import React from 'react';
import { toAbsoluteUrl } from '../../helpers';
import { Link } from 'react-router-dom';

export const AuthWelcomeMessage = () => {
    return (
        <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10">
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
                <Link to="/" className="flex-column-auto mt-5">
                    <img alt="Logo" className="max-h-150px" src={toAbsoluteUrl('/media/logos/ForvisMazars-Logo-Color-CMYK.png')} />
                </Link>
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                    <h3 className="font-size-h1 mb-5">
                        <FormattedMessage id="AUTH.WELCOME.MESSAGE" />
                    </h3>
                    <p className="font-weight-lighter opacity-80" id="welcome-message-text">
                        <FormattedMessage id="AUTH.WELCOME.MESSAGE.TEXT" />
                    </p>
                </div>

                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                    <div className="opacity-70 font-weight-bold">
                        &copy; <FormattedMessage id="AUTH.WELCOME.LEGAL" />
                    </div>
                </div>
            </div>
        </div>
    );
};
