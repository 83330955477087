import { Card, CardBody, CardHeader } from '../../partials/controls/Card';
import { useIntl } from 'react-intl';
import React, { useEffect, useRef } from 'react';
import { FORM_FIELD } from '../../helpers';
import { useForm } from '../../hooks/useForm';
import { getLocalizedString } from '../../i18n/i18Helper';
import * as Yup from 'yup';
import { UploadDocumentSpec } from '../../types/types';

interface FileDropzoneProps {
    showPreviews: boolean;
    acceptedFiles: string[];
    filesLimit: number;
}

interface UploadDocumentsProps {
    handleUpload: (values: UploadDocumentSpec) => void;
    isWrappedInCard?: boolean;
    title?: string;
    isSubmitOnChange: boolean;
    complementaryForm?: { validationSchema: any; fields: any[] };
    triggerButtonRef?: any;
    filesInputProps: FileDropzoneProps;
    toolbar?: JSX.Element;
}

export const UploadDocumentsComponent = ({
    handleUpload,
    title = 'SUPPORT.BUTTON.LABEL.UPLOAD',
    isSubmitOnChange,
    complementaryForm,
    triggerButtonRef,
    filesInputProps,
    isWrappedInCard = true,
    toolbar
}: UploadDocumentsProps) => {
    const currentBtnRef = useRef();

    const intl = useIntl();
    const checkboxStyle = { margin: '0 1em' };
    const initialValues: UploadDocumentSpec = { files: [], isGrouped: false, comment: undefined, id: undefined };

    useEffect(() => {
        if (triggerButtonRef)
            triggerButtonRef.current = {
                saveForm() {
                    triggerButtonClick();
                }
            };
    }, []);

    const triggerButtonClick = () => {
        // @ts-ignore
        currentBtnRef?.current?.click();
    };

    const UPLOAD_FIELDS = [
        {
            fieldType: FORM_FIELD.CHECKBOX_FIELD,
            id: 'isGrouped',
            customStyle: checkboxStyle,
            labelId: intl.formatMessage({ id: 'SUPPORT.CHECKBOX.UPLOAD.GROUPED.LABEL' }),
            toolTipLabelId: intl.formatMessage({ id: 'SUPPORT.CHECKBOX.UPLOAD.GROUPED.TOOLTIP' }),
            color: 'primary'
        },
        {
            fieldType: FORM_FIELD.INPUT_FIELD,
            id: 'files',
            acceptedFiles: filesInputProps.acceptedFiles,
            filesLimit: filesInputProps.filesLimit,
            dropzoneText: intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.MSG' }),
            showPreviews: filesInputProps.showPreviews,
            isSubmitOnChange: isSubmitOnChange
        },
        ...(complementaryForm?.fields || [])
    ];

    const validationSchema = Yup.object().shape({
        isGrouped: Yup.boolean(),
        files: Yup.mixed(),
        ...(complementaryForm?.validationSchema || {})
    });

    const { RenderForm: UploadForm } = useForm({
        enableReinitialize: true,
        initialValues: initialValues,
        handleSubmit: handleUpload,
        validationSchema: validationSchema
    });

    return (
        <>
            {isWrappedInCard ? (
                // @ts-ignore
                <Card>
                    {
                        // @ts-ignore
                        title && <CardHeader title={getLocalizedString(intl, title)}> {toolbar}</CardHeader>
                    }

                    {
                        // @ts-ignore
                        <CardBody>
                            <UploadForm
                                formFields={UPLOAD_FIELDS}
                                conditionalFormKey={undefined}
                                conditionalFields={undefined}
                                submitRef={currentBtnRef}
                                submitTrigger={triggerButtonClick}
                            />
                        </CardBody>
                    }
                </Card>
            ) : (
                <UploadForm
                    formFields={UPLOAD_FIELDS}
                    conditionalFormKey={undefined}
                    conditionalFields={undefined}
                    submitRef={currentBtnRef}
                    submitTrigger={triggerButtonClick}
                />
            )}
        </>
    );
};
