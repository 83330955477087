import { deepFreeze } from '../../../helpers/FreezeHelper';

const InitLostItemConfig = {
    clusterId: null,
    isSupportMandatoryToAssignAsLostItem: false,
    isClaimMandatory: false,
    isReadAllowed: false
};

export const initLostItemConfig = deepFreeze(InitLostItemConfig);
