import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box } from '@mui/material';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { UIDialog } from '../../common/UIDialog';
import { LetterHeadContext } from '../../../context';
import { getLocalizedString } from '../../../i18n/i18Helper';
import {
    CompaniesLetterHeaderContainer,
    CreateLetterHeadComponent,
    LetterHeadPreview,
    initLetterHead
} from '../../letterHead';
import { UISectionHeader } from '../../common/UISectionHeader';

/**
 * search and display engagement's companies letterheads.
 *
 * @param {*} props
 * @returns
 */
export function EngagementCompanyLetterHeads(props) {
    const { intl, classes, companies, disabled, loading, updateCompany } = props;
    const sbmtLetterHeadRef = useRef();

    const [openDialog, setOpenDialog] = useState(false);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [isSaveRequired, setIsSavedRequired] = useState(false);

    const [letterHead, setLetterHead] = useState(initLetterHead);
    const [companiesMissingLetterHeads, setCompaniesMissingLetterHeads] = useState([]);
    const { createLetterHead, deleteLetterHead } = useContext(LetterHeadContext);

    useEffect(() => {
        setCompaniesMissingLetterHeads(companies?.filter(company => !company.letterHead) ?? []);
        setIsSavedRequired(companies && companies.reduce((acc, company) => acc || company?.isTemporaryId, false));
    }, [companies]);

    const handleClickSave = () => {
        if (sbmtLetterHeadRef && sbmtLetterHeadRef.current) {
            sbmtLetterHeadRef.current.saveForm();
        }
    };

    const handleSubmitLetterHead = async values => {
        const { isSuccess } = await createLetterHead(values);
        setOpenDialog(!isSuccess);
    };

    const handleCreateLetterHead = initialData => {
        setLetterHead(initialData);
        setOpenDialog(!openDialog);
    };

    const handleDeleteLetterHead = async letterHead => {
        await deleteLetterHead(letterHead);
    };

    const handleDisplayDetails = letterHead => {
        setLetterHead(letterHead);
        setOpenDetailsDialog(!openDetailsDialog);
    };

    const handleToggleActiveLetterHead = async company => {
        await updateCompany({ ...company, isLetterHeadActivated: !company.isLetterHeadActivated });
    };

    const handlePopUpOpening = () => setOpenDialog(!openDialog);
    const handleDetailsPopUpOpening = () => setOpenDetailsDialog(!openDetailsDialog);

    return (
        <>
            <Box sx={styles.container}>
                <UISectionHeader
                    title={getLocalizedString(intl, 'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.TITLE')}
                    titleProps={{ sx: styles.title }}
                    subtitle={getLocalizedString(intl, 'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.SUBTITLE')}
                />

                {isSaveRequired && (
                    <Alert severity="error" onClose={() => setIsSavedRequired(false)}>
                        {FormattedErrorMessage(
                            intl.formatMessage({ id: 'SETTINGS.LETTERHEAD.SECTION.ALERT.SAVE.REQUIRED.MESSAGE' })
                        )}
                    </Alert>
                )}

                <Box sx={styles.list}>
                    <CompaniesLetterHeaderContainer
                        disabled={disabled}
                        companies={companies}
                        createLetterHead={handleCreateLetterHead}
                        deleteLetterHead={handleDeleteLetterHead}
                        displayDetails={handleDisplayDetails}
                        toggleActiveLetterHead={handleToggleActiveLetterHead}
                    />
                </Box>
            </Box>
            {openDialog && (
                <UIDialog
                    classes={classes}
                    loading={loading}
                    open={openDialog}
                    titleId="SETTINGS.LETTERHEAD.SECTION.CREATE.TITLE"
                    onSave={handleClickSave}
                    onClose={handlePopUpOpening}>
                    <CreateLetterHeadComponent
                        loading={loading}
                        variant="outlined"
                        companyDisabled={!!letterHead.engagementClientCompanyId}
                        companies={companiesMissingLetterHeads}
                        letterHead={letterHead}
                        createLetterHead={handleSubmitLetterHead}
                        triggerButtonRef={sbmtLetterHeadRef}
                    />
                </UIDialog>
            )}
            {openDetailsDialog && (
                <UIDialog
                    classes={classes}
                    loading={loading}
                    open={openDetailsDialog}
                    titleId="SETTINGS.LETTERHEAD.SECTION.DETAILS.TITLE"
                    onClose={handleDetailsPopUpOpening}>
                    <LetterHeadPreview loading={loading} letterHead={letterHead} />
                </UIDialog>
            )}
        </>
    );
}

EngagementCompanyLetterHeads.propTypes = {
    /**
     * List of companies.
     */
    companies: PropTypes.array,
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired,
    classes: PropTypes.any.isRequired
};

const styles = {
    container: {
        paddingLeft: 10,
        paddingRight: 10
    },
    list: {
        marginTop: 5
    },
    title: {
        fontSize: 38
    }
};
