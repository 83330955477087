import React from 'react';
import {
    Check as CheckIcon,
    HourglassFullRounded as HourglassFullTwoToneIcon,
    MoreHoriz as MoreHorizIcon,
    Print as PrintIcon
} from '@mui/icons-material';
import { UITooltip } from '../common/UITooltip';
import { PAPER_PROCESS_STATUS } from './InitItem';
import { Palette } from '../../styles/colors';

export const PaperDocumentStatusIcon = ({ intl, item, fontSize }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            {item.paperSendingStatus === PAPER_PROCESS_STATUS.PENDING ? (
                <UITooltip
                    title={intl.formatMessage({
                        id: 'DOCUMENT.TABLE.STATUS.TOOLTIP.PENDING'
                    })}
                    children={<HourglassFullTwoToneIcon color="primary" fontSize={fontSize} />}
                />
            ) : item.paperSendingStatus === PAPER_PROCESS_STATUS.IN_PROGRESS ? (
                <UITooltip
                    title={intl.formatMessage({
                        id: 'DOCUMENT.TABLE.STATUS.TOOLTIP.INPROGRESS'
                    })}
                    children={<PrintIcon sx={{ color: Palette.red }} fontSize={fontSize} />}
                />
            ) : item.paperSendingStatus === PAPER_PROCESS_STATUS.DONE ? (
                <UITooltip
                    title={intl.formatMessage({
                        id: 'DOCUMENT.TABLE.STATUS.TOOLTIP.DONE'
                    })}
                    children={<CheckIcon color="primary" fontSize={fontSize} />}
                />
            ) : (
                <UITooltip
                    title={intl.formatMessage({
                        id: 'DOCUMENT.TABLE.STATUS.TOOLTIP.NONE'
                    })}
                    children={<MoreHorizIcon color="primary" fontSize={fontSize} />}
                />
            )}
        </div>
    );
};
