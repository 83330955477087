import { ROUTES } from './Routes';
import { Route } from 'react-router-dom';
import DefaultLayout from '../layout/DefaultLayout';
import LoginLayout from '../layout/LoginLayout';
import { auth } from '../screens/auth/Auth';
import { createEngagementComponent, Engagement } from '../screens/engagement';
import { support } from '../screens/support';
import { countryAdminSettings, countryAdminMenu, countryAdminMetrics } from '../screens/admin';
import {
    confirmation,
    createConfirmationManual,
    createConfirmationImport,
    createMethod
} from '../screens/confirmation';
import { settingsComponent } from '../screens/settings';
import { isAuthorisedProfile } from '../components/auth/UserHelper';
import { PROFILE } from '../types/types';
import { ClientAuth } from '../screens/auth/ClientAuth';
import { ProtectedRoute } from './ProtectedRoute';
import { LoginOptions } from '../screens/auth/LoginOptions';
import { Home } from '../screens/home';
import { isExternalAccessEnabled } from '../kit/config/appConfiguration';

export const getDefaultRouteByProfile = profile => {
    if (!profile) return ROUTES.AUTH;

    // TODO: Does not work with cumulative profiles
    if (profile === PROFILE.AUDITOR || isAuthorisedProfile(PROFILE.AUDITOR, profile)) {
        return ROUTES.HOME;
    }

    if (profile === PROFILE.POSTIE || isAuthorisedProfile(PROFILE.POSTIE, profile)) {
        return ROUTES.COUNTRY_POSTIE;
    }

    if (profile === PROFILE.CLIENT || isAuthorisedProfile(PROFILE.CLIENT, profile)) {
        return ROUTES.HOME;
    }

    if (profile === PROFILE.ADMIN || isAuthorisedProfile(PROFILE.ADMIN, profile)) {
        return ROUTES.COUNTRY_ADMIN;
    }

    // else:
    return ROUTES.HOME;
};

export function getCurrentUrl(location) {
    return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url) {
    const current = getCurrentUrl(location);

    if (!current || !url) {
        return false;
    }

    if (current === url) {
        return true;
    }

    if (current.indexOf(url) > -1) {
        return true;
    }

    return false;
}

const ClientRoutes = [
    {
        path: ROUTES.AUTH_CLIENT,
        titleKey: 'AUTH.CLIENT.LOGIN.TITLE',
        component: isExternalAccessEnabled ? ClientAuth : LoginOptions,
        layout: LoginLayout,
        isProtected: false
    }
];

export const AppRoutes = [
    ...ClientRoutes,
    {
        path: ROUTES.AUTH,
        titleKey: 'AUTH.LOGIN.TITLE',
        component: LoginOptions,
        layout: LoginLayout,
        isProtected: false
    },
    {
        path: ROUTES.AUTH_MAZARS,
        titleKey: 'AUTH.LOGIN.TITLE',
        component: auth,
        layout: LoginLayout,
        isProtected: false
    },
    {
        path: ROUTES.HOME,
        titleKey: 'HOME.TITLE',
        component: Home,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.ENGAGEMENT,
        titleKey: 'ENGAGEMENT.TITLE',
        component: Engagement,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.ENGAGEMENT_CREATE,
        titleKey: 'CREATE_ENGAGEMENT.TITLE',
        component: createEngagementComponent,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.ENGAGEMENT_SETTINGS,
        titleKey: 'SETTINGS.TITLE',
        component: settingsComponent,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.ENGAGEMENT_CONFIRMATION_CREATE,
        titleKey: 'METHOD.CREATE.CONFIRMATION.TITLE',
        component: createMethod,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.CONFIRMATION_CREATE_MANUAL,
        titleKey: 'MANUAL.CREATE.CONFIRMATION.TITLE',
        component: createConfirmationManual,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.CONFIRMATION_CREATE_IMPORT,
        titleKey: 'IMPORT.CREATE.CONFIRMATION.TITLE',
        component: createConfirmationImport,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.CONFIRMATION,
        titleKey: 'CONFIRMATION.TITLE',
        component: confirmation,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.COUNTRY_POSTIE,
        titleKey: 'COUNTRY_SUPPORT.TITLE',
        component: support,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.COUNTRY_ADMIN,
        titleKey: 'COUNTRY_ADMIN.TITLE',
        component: countryAdminMenu,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.COUNTRY_ADMIN_SETTINGS,
        titleKey: 'COUNTRY_ADMIN_SETTINGS.TITLE',
        component: countryAdminSettings,
        layout: DefaultLayout,
        isProtected: true
    },
    {
        path: ROUTES.COUNTRY_ADMIN_METRICS,
        titleKey: 'COUNTRY_ADMIN_METRICS.TITLE',
        component: countryAdminMetrics,
        layout: DefaultLayout,
        isProtected: true
    }
];

export const getCrumbs = props => {
    const { intl } = props;
    const crumbs = AppRoutes
        // Get all routes that contain the current one.
        .filter(
            ({ path }) =>
                props.match.path.includes(path) &&
                path !== ROUTES.AUTH &&
                path !== ROUTES.AUTH_CLIENT &&
                path !== ROUTES.AUTH_MAZARS
        )

        // Swap out any dynamic routes with their param values.
        // E.g. "/engagement/:engagementId" will become "/engagement/1"
        .map(({ path, titleKey, ...rest }) => ({
            path: Object.keys(props.match.params).length
                ? Object.keys(props.match.params).reduce(
                      (currentPath, param) => currentPath.replace(`:${param}`, props.match.params[param]),
                      path
                  )
                : path,
            title: titleKey ? intl.formatMessage({ id: titleKey }) : '',
            ...rest
        }));

    return crumbs;
};

export const RouteWrapper = ({ component: Component, layout: Layout, isProtected = true, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            return (
                <ProtectedRoute isProtected={isProtected}>
                    <Layout {...props}>
                        <Component {...props} />
                    </Layout>
                </ProtectedRoute>
            );
        }}
    />
);
