export const confirmation = {
    'CONFIRMATION.TITLE': 'Confirmation',
    'CONFIRMATION.ACCESS.CONFIRMATION.ERROR': 'Confirmation information access error',
    'CONFIRMATION.ACCESS.DOCUMENTS.ERROR': 'Confirmation documents access error',
    'CONFIRMATION.ACCESS.COMPANY.THIRDPARTY.ERROR': 'Third-party access error',
    'CONFIRMATION.UPDATE.SUCCESS': 'Confirmation updated',
    'CONFIRMATION.UPDATE.ERROR': 'Confirmation update error',
    'CONFIRMATION.ERASE.SUCCESS': 'Confirmation deleted permanently',
    'CONFIRMATION.ERASE.ERROR': 'Delete Confirmation permanently error',
    'CONFIRMATION.DELETE.SUCCESS': 'Confirmation in recycle bin',
    'CONFIRMATION.DELETE.ERROR': 'Confirmation delete error',
    'CONFIRMATION.DELETE.INFO': 'Put the confirmation in the Recycle Bin',
    'CONFIRMATION.RESTORE.INFO': 'Restore the confirmation',
    'CONFIRMATION.ERASE.INFO': 'Delete the confirmation permanently',
    'CONFIRMATION.HEADER.TITLE': 'Confirmation for {title}',
    'CONFIRMATION.BUTTON.BACK.LABEL': 'Back',
    'CONFIRMATION.BUTTON.RESET.LABEL': 'Reset',
    'CONFIRMATION.BUTTON.DELETE.LABEL': 'Delete',
    'CONFIRMATION.BUTTON.SAVE.LABEL': 'Save',
    'CONFIRMATION.BUTTON.EDIT.LABEL': 'Edit',
    'CONFIRMATION.BUTTON.REPLACE.LABEL': 'Replace',
    'CONFIRMATION.BUTTON.CREATE.LABEL': 'Create',
    'CONFIRMATION.BUTTON.NEXTSTEP.LABEL': 'Next step',
    'CONFIRMATION.BUTTON.VALIDATE.LABEL': 'Validate',
    'CONFIRMATION.LABEL.CONFIRMATION_NAME': 'Confirmation',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.CONFIRMATION_NAME': 'Confirmation name is required',
    'CONFIRMATION.LABEL.CONFIRMATION_DESCRIPTION': 'Description',
    'CONFIRMATION.LABEL.PERIODE_STARTDATE': 'Period start date',
    'CONFIRMATION.LABEL.PERIODE_ENDDATE': 'Period end date',
    'CONFIRMATION.LABEL.CONFIRMATION_CLIENT': 'Company',
    'CONFIRMATION.LABEL.CONFIRMATION_CODE': 'Code',
    'CONFIRMATION.LABEL.CLIENT_DUEDATE': 'Sign off due date',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.CLIENT_DUEDATE': 'Sign off due date is required',
    'CONFIRMATION.PLACEHOLDER.CLIENT_DUEDATE': 'Sign off due date',
    'CONFIRMATION.LABEL.THIRDPARTY_DUEDATE': 'Third-party due date',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.THIRDPARTY_DUEDATE': 'Third-party due date is required',
    'CONFIRMATION.LABEL.LANGUAGE': 'Language',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'Language is required',
    'CONFIRMATION.PLACEHOLDER.THIRDPARTY_DUEDATE': 'Third-party due date',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.AUTOMATIC_CLIENT_SENDDATE':
        'Automatic client sending date is required',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.AUTOMATIC_THIRDPARTY_SENDDATE':
        'Automatic third-party sending date is required',
    'CONFIRMATION.CHECKBOX.UPLOAD.GROUPED.LABEL': 'Group multiple files',
    'CONFIRMATION.CHECKBOX.UPLOAD.GROUPED.TOOLTIP': 'Upload files from the same confirmation within a folder',
    'CONFIRMATION.ALL.DOCUMENTS.FILE.NAME': 'Confirmation all documents.zip',
    'CONFIRMATION.ALL.DOCUMENTS.DOWNLOAD.ALL.FILES': 'Download all',

    'IMPORT.CONFIRMATION.EXCEL.FILE.LABEL': 'Choose a file',
    'IMPORT.CONFIRMATION.EXCEL.FILE.NAME.LABEL': 'Confirmations upload.xlsx',
    'IMPORT.CONFIRMATION.EXCEL.FILE.WORKSHEETNAME.LABEL': 'Confirmations',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.HEADER.LABEL': 'Company information',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.COMPANY.HEADER.LABEL': 'Company',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.COMPANY.NAME.LABEL': 'Name',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.COMPANY.NAME.COMMENT':
        'Choose the client company from the drop-down menu. If you need to create a new client company, add-it into your engagement settings on the web application then regenerate this file.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.HEADER.LABEL': 'Main contact',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.EMAIL.LABEL': 'E-mail',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.EMAIL.COMMENT':
        'Choose the client contact from the drop-down menu. If you need to create a new client contact, add-it into your engagement settings on the web application then regenerate this file.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.RECIPIENT.LABEL': 'Is recipient',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.RECIPIENT.COMMENT':
        "'True' if you want the contact to receive the confirmation request. If you choose 'False' the contact will be use to fill in letter template but will not receive e-mails.",
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT2.HEADER.LABEL': '(Optional) secondary contact',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT2.EMAIL.LABEL': 'E-mail',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT2.EMAIL.COMMENT':
        'Choose the client contact from the drop-down menu. If you need to create a new client contact, add-it into your engagement settings on the web application then regenerate this file.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.HEADER.LABEL': 'Third-party information',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.HEADER.LABEL': 'Company',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.NAME.LABEL': 'Name',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.NAME.COMMENT':
        'Name of the third-party company that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ADDRESS.LABEL': 'Address',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ADDRESS.COMMENT':
        'Address of the third-party company that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.CITY.LABEL': 'City',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.CITY.COMMENT':
        'City of the third-party company that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ZIPCODE.LABEL': 'Zip code',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ZIPCODE.COMMENT':
        'Zip code of the third-party company that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.COUNTRY.LABEL': 'Country',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.COUNTRY.COMMENT':
        'Country of the third-party company that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.HEADER.LABEL': 'Contact',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.JOBTITLE.LABEL': 'Job title',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.JOBTITLE.COMMENT':
        'Job title of the third-party contact that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.EMAIL.LABEL': 'E-Mail',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.EMAIL.COMMENT':
        'E-mail address of the third-party contact that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.TITLE.LABEL': 'Title',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.TITLE.COMMENT':
        'Title of the third-party contact that will receive the confirmation request. (e.g. Mr, Ms...).',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.FIRSTNAME.LABEL': 'First name',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.FIRSTNAME.COMMENT':
        'First name of the third-party contact that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.LASTNAME.LABEL': 'Last name',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.LASTNAME.COMMENT':
        'Last name of the third-party contact that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.DIALING_CODE.LABEL': 'Dialing code',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.DIALING_CODE.COMMENT':
        'Dialing code of the third-party contact that will receive the confirmation request. (e.g. +33, +65...).',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.PHONE.LABEL': 'Phone',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.PHONE.COMMENT':
        'Phone number of the third-party contact that will receive the confirmation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.HEADER.LABEL': 'Confirmation information',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.SEND_DATE.LABEL': 'Client send date',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.SEND_DATE.COMMENT':
        'Date when you want to send the confirmation to the client. The date will be displayed on the confirmation letter.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.DUE_DATE.LABEL': 'Sign off due date',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.DUE_DATE.COMMENT':
        'Date when you want the client to answer to your confirmation. The date will be displayed on the confirmation letter.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.SEND_DATE.LABEL': 'Third-party send date',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.SEND_DATE.COMMENT':
        'Date when you want to send the confirmation to the third-party. The date will be displayed on the confirmation letter.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.DUE_DATE.LABEL': 'Third-party due date',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.DUE_DATE.COMMENT':
        'Date when you want the third-party to reply the the confirmation. The date will be displayed on the confirmation letter.',
    'IMPORT.CONFIRMATION.EXCEL.TRUE_VALUE': 'True',
    'IMPORT.CONFIRMATION.EXCEL.FALSE_VALUE': 'False',
    'IMPORT.CONFIRMATION.EXCEL.CLIENT.COMPANY.SELECTION.ERROR.TITLE': 'Invalid client company selected',
    'IMPORT.CONFIRMATION.EXCEL.CLIENT.COMPANY.SELECTION.ERROR.MESSAGE':
        'Please select a client company from the drop-down menu. If you need to add a new client company, add-it into your engagement settings on the web application then regenerate this file.',
    'IMPORT.CONFIRMATION.EXCEL.CLIENT.CONTACT.SELECTION.ERROR.TITLE': 'Invalid client contact e-mail selected',
    'IMPORT.CONFIRMATION.EXCEL.CLIENT.CONTACT.SELECTION.ERROR.MESSAGE':
        'Please select a client contact e-mail from the drop-down menu. If you need to add a new client contact, add-it into your engagement settings on the web application then regenerate this file.',
    'IMPORT.CONFIRMATION.EXCEL.BOOLEAN.SELECTION.ERROR.TITLE': 'Invalid selection',
    'IMPORT.CONFIRMATION.EXCEL.BOOLEAN.SELECTION.ERROR.MESSAGE': "Please either select 'True' or 'False'.",
    'IMPORT.CONFIRMATION.EXCEL.DATE_TIME.ERROR.TITLE': 'Invalid date',
    'IMPORT.CONFIRMATION.EXCEL.DATE_TIME.ERROR.MESSAGE':
        "Please enter a valid date/time between a range of 'today - 15 years' and 'today + 15 years'.",
    'IMPORT.CONFIRMATION.LETTER_TEMPLATE.NOT.EXISTS':
        'The information contained in your file do not exist or have been changed, please regenerate a new excel template',
    'METHOD.CREATE.CONFIRMATION.BUTTON.BACK.LABEL': 'Back',
    'METHOD.CREATE.CONFIRMATION.MANUAL.TITLE': 'Manual',
    'METHOD.CREATE.CONFIRMATION.MANUAL.DESCRIPTION': 'Create a confirmation manually',
    'METHOD.CREATE.CONFIRMATION.MANUAL.CONTENT': 'Follow each steps of the confirmation creation process.',
    'METHOD.CREATE.CONFIRMATION.IMPORT.TITLE': 'Import',
    'METHOD.CREATE.CONFIRMATION.IMPORT.DESCRIPTION': 'Create multiple confirmations',
    'METHOD.CREATE.CONFIRMATION.IMPORT.CONTENT':
        'Download Excel file template to fill. Select the type of confirmations to create. Import Excel file filled with all confirmations to create.',
    'METHOD.CREATE.CONFIRMATION.TITLE': 'New confirmation',
    'MANUAL.CREATE.CONFIRMATION.TITLE': 'Create manually',
    'MANUAL.CREATE.CONFIRMATION.CREATE.ERROR': 'Create confirmation error',
    'MANUAL.CREATE.CONFIRMATION.CREATE.SUCCESS': 'Confirmation created',
    'MANUAL.CREATE.CONFIRMATION.ACCESS.ENGAGEMENT.ERROR': 'Access engagement error',
    'MANUAL.CREATE.CONFIRMATION.TEMPLATES.ACCESS.ERROR': 'Access confirmation templates error',
    'MANUAL.CREATE.CONFIRMATION.ACCESS.COMPANY.CLIENT.ERROR': 'Access engagement companies error',
    'MANUAL.CREATE.CONFIRMATION.COMPANY.SEARCH_BAR.PLACEHOLDER': 'Search name, address, city, zip code, ...',
    'MANUAL.CREATE.CONFIRMATION.COMPANY.NOT.EXISTS': 'My company does not exist',
    'MANUAL.CREATE.CONFIRMATION.COMPANY.NO.CONTACTS': 'No contacts',
    'MANUAL.CREATE.CONFIRMATION.COMPANY.CONTACTS': 'Contacts',
    'CREATE_CONFIRMATION.STEP_TEMPLATE.LABEL': 'Confirmation template',
    'CREATE_CONFIRMATION.STEP_CLIENT.LABEL': 'Entity',
    'CREATE_CONFIRMATION.STEP_THIRD_PARTY.LABEL': 'Third Party',
    'CREATE_CONFIRMATION.STEP_PARAMETERS.LABEL': 'Information',
    'CREATE_CONFIRMATION.STEP_OVERVIEW.LABEL': 'Overview',
    'CREATE_CONFIRMATION.STEP_WORKFLOW.LABEL': 'Workflow',
    'CREATE_CONFIRMATION.BUTTON.LABEL.BACK': 'Back',
    'CREATE_CONFIRMATION.BUTTON.LABEL.RESET': 'Reset',
    'CREATE_CONFIRMATION.BUTTON.LABEL.CREATE': 'Create',
    'CREATE_CONFIRMATION.STEPS.NEXT.LABEL': 'Next',
    'CREATE_CONFIRMATION.STEPS.PREVIOUS.LABEL': 'Previous',
    'CREATE_CONFIRMATION.STEPS.FINISH.LABEL': 'Finish',
    'CREATE_CONFIRMATION.STEPS.FINISH.MSG': 'All steps completed',
    'CREATE_CONFIRMATION.STEPS.RESET.LABEL': 'Reset',
    'CREATE_CONFIRMATION.VALIDATION.REQUIRED_STEPS.CLIENT': ' - A client company is required',
    'CREATE_CONFIRMATION.VALIDATION.REQUIRED_STEPS.STACKED_STEPS': 'One option for each step is required',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SECTION.FILTER.LABEL': 'Filters',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.BUTTON.CLEAR.FILTER.LABEL': 'Clear filters',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.TYPE.LABEL': 'Select type of confirmation',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.TYPE.PLACEHOLDER': 'Confirmation type',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.LANGUAGE.LABEL': 'Select language',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.LANGUAGE.PLACEHOLDER': 'Language',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.TYPE.LABEL': 'Type: ',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.UPDATE.LABEL': 'Last update: ',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.LANGUAGE.LABEL': 'Language: ',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.LOADING.LABEL': 'Loading..',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.TEMPLATE.BUTTON.LABEL': 'Select',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SEE_MORE.BUTTON.LABEL': 'See more',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.STACKED_STEPS':
        'For each optional step in the workflow, please select the one to use.',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.MORE_INFO_STACKED_STEPS': 'Template workflow',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SEE_MORE.TITLE': "Letter template's details",
    'CREATE_CONFIRMATION.SELECT.CLIENT.COMPANY.LABEL': "Select one of your engagement's entity",
    'CREATE_CONFIRMATION.CLIENT.CREATE.COMPANY.LABEL': 'Create client company',
    'CREATE_CONFIRMATION.SELECT.CLIENT.CONTACT_COMPANY.LABEL': "Select one of you engagement's contact",
    'CREATE_CONFIRMATION.CLIENT.CONTACT.LABEL': 'Contacts',
    'CREATE_CONFIRMATION.CLIENT.CREATE.CONTACT.LABEL': 'Create contact',
    'CREATE_CONFIRMATION.CLIENT.CONTACT.SIGNATORY': 'Signatory',
    'CREATE_CONFIRMATION.CLIENT.CONTACT.RECIPIENT': 'Recipient',
    'CREATE_CONFIRMATION.CLIENT.CONTACT.SIGNATORY.INFO':
        'Check this box to designate the person whose name will appear as the signatory of the letter',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.MAIN.INFO': 'Contact to whom the letter will be addressed. Contact name will be used in the generated letter.',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.MAIN': 'Main',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.RECIPIENT.INFO': 'Contact will recive the email confirmation request',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.RECIPIENT': 'Recipient',
    'CREATE_CONFIRMATION.CLIENT.CONTACT.RECIPIENT.INFO':
        'Select this checkbox, to design the person that will receive the confirmation request letter.',
    'CREATE_CONFIRMATION.SELECT.THIRDPARTY.COMPANY.LABEL': 'Select third-party company',
    'CREATE_CONFIRMATION.THIRDPARTY.COMPANY.LABEL': 'Company',
    'CREATE_CONFIRMATION.THIRDPARTY.SEARCH.COMPANY.LABEL': 'Select third-party company',
    'CREATE_CONFIRMATION.THIRDPARTY.CREATE.COMPANY.LABEL': 'Create third-party company',
    'CREATE_CONFIRMATION.THIRDPARTY.EDIT.COMPANY.LABEL': 'Edit third-party company',
    'CREATE_CONFIRMATION.THIRDPARTY.SELECT.COMPANY.LABEL': 'Select third-party company',
    'CREATE_CONFIRMATION.SELECT.THIRDPARTY.CONTACT.LABEL': 'Select third-party contacts',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.LABEL': 'Contacts',
    'CREATE_CONFIRMATION.THIRDPARTY.CREATE.CONTACT.LABEL': 'Create contact',
    'IMPORT.CREATE.CONFIRMATION.TITLE': 'Create confirmations import',
    'IMPORT.CONFIRMATION.CARD.HEADER': 'Import confirmations',
    'IMPORT.CONFIRMATION.BUTTON.BACK.LABEL': 'Back',
    'IMPORT.CONFIRMATION.BUTTON.RESET.LABEL': 'Reset',
    'IMPORT.CONFIRMATION.BUTTON.SAVE.LABEL': 'Save',
    'IMPORT.CONFIRMATION.BUTTON.DOWNLOAD.XLS_TEMPLATE.LABEL': 'Download excel template',
    'IMPORT.CONFIRMATION.BUTTON.IMPORT.LABEL': 'Upload confirmations',
    'IMPORT_DIALOG.SECTION.TITLE': 'Import confirmations file',
    'IMPORT_DIALOG.BUTTON.CANCEL': 'Cancel',
    'IMPORT_DIALOG.BUTTON.VALIDATE': 'Validate',
    'IMPORT.CONFIRMATION.LABEL.CLIENT': 'Company',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.CLIENT': 'Company is required',
    'IMPORT.CONFIRMATION.LABEL.TYPE': 'Confirmations type',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.TYPE': 'Type of confirmation is required',
    'IMPORT.CONFIRMATION.LABEL.TEMPLATE': 'Confirmations template',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.TEMPLATE': 'Template is required',
    'IMPORT.CONFIRMATION.LABEL.LANGUAGE': 'Language',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'Language is required',
    'IMPORT.CONFIRMATION.LABEL.SHEETNAME': 'Sheet name',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.SHEETNAME': 'Sheet name is required',
    'IMPORT.CONFIRMATION.LABEL.UPLOAD': 'Confirmations upload',
    'IMPORT.CONFIRMATION.UPLOAD.ERROR.FORMAT': 'File format error',
    'IMPORT.CONFIRMATION.UPLOAD.SUBMIT.LABEL': 'Submit',
    'IMPORT.CONFIRMATION.UPLOAD.CHECK.MAPPING.MESSAGE': 'Please, check the columns mapping before submit',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.INFORMATION': 'General',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.OTHERS': 'Others',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.PERIOD_DATES': 'Period dates',
    'SELECT.PARAMETERS.CONFIRMATION.NAME.LABEL': 'Name',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.NAME': 'Confirmation name is required',
    'SELECT.PARAMETERS.CONFIRMATION.DESCRIPTION.LABEL': 'Description',
    'SELECT.PARAMETERS.CONFIRMATION.PERIOD.STARTDATE.LABEL': 'Start of period',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.PERIOD.STARTDATE': 'Start of period is required',
    'SELECT.PARAMETERS.CONFIRMATION.PERIOD.ENDDATE.LABEL': 'End of period',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.PERIOD.ENDDATE': 'End of period is required',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.DATES': 'Dates',
    'SELECT.PARAMETERS.CONFIRMATION.CLIENT.DUEDATE.LABEL': 'Sign off due date',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.CLIENT.DUEDATE': 'Sign off due date is required',
    'SELECT.PARAMETERS.CONFIRMATION.CLIENT.SENDDATE.LABEL': 'Entity send date',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.CLIENT.SENDDATE': 'Entity send date is required',
    'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.DUEDATE.LABEL': 'Third-party due date',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.THIRDPARTY.DUEDATE': 'Third-party due date is required',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.AUTO': 'Automation',
    'SELECT.PARAMETERS.CONFIRMATION.CLIENT.AUTOMATIC.SENDING.LABEL': 'Automatic sending to company',
    'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.AUTOMATIC.SENDING.LABEL': 'Automatic sending to third-party',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.EXTRA_FIELDS_ITEM': '{fieldName} is required',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.SUMMARY.LABEL': 'Summary',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CATEGORY.LABEL': 'Category of confirmation',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.LANGUAGE.LABEL': 'Language',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.TEMPLATE.LABEL': 'Template',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.NAME.LABEL': 'Name',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.DESCRIPTION.LABEL': 'Description',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CLIENT.NAME.LABEL': "Engagement's entity",
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CLIENT.CONTACTS.LABEL': "Entity's contacts",
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CLIENT.DUEDATE.LABEL': 'Sign off due date',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CLIENT.SENDDATE.LABEL': 'Entity send date',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.THIRDPARTY.NAME.LABEL': 'Third-party company',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.THIRDPARTY.CONTACTS.LABEL': 'Third-party contacts',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.THIRDPARTY.DUEDATE.LABEL': 'Third-party due date',
    'CREATE_CONFIRMATION.OVERVIEW.ENGAGEMENT.CODE.LABEL': 'Engagement code',
    'CREATE_CONFIRMATION.OVERVIEW.ENGAGEMENT.NAME.LABEL': 'Engagement name',
    'CONFIRMATION.CREATE.ERROR': 'Confirmation creation error',
    'CONFIRMATION.SUBSECTION.CONTACTS.THIRDPARTY': 'Third-Party contacts',
    'CONFIRMATION.SUBSECTION.COMPANY.THIRDPARTY': 'Third-Party company',
    'CONFIRMATION.SUBSECTION.INFORMATION': 'Information',
    'CONFIRMATION.SUBSECTION.DOCUMENTS': 'Documents',
    'CONFIRMATION.SUBSECTION.RECYCLE_BIN': 'Recycle Bin',
    'CONFIRMATION.DOCUMENTS.RECYCLE_BIN': 'Open Recycle Bin',
    'CONFIRMATION.DOCUMENTS.RECYCLE_BIN.RETURN': 'Close Recycle Bin',
    'CONFIRMATION.SUBSECTION.COMMENTS': 'Comments',
    'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_SIGNATORY': ' - A signatory contact is required',
    'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_MAIN': ' - A main contact is required',
    'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_RECIPIENT': ' - At least one recipient contact is required',
    'CONFIRMATION.DOCUMENT.TYPE.0': 'Unknown',
    'CONFIRMATION.DOCUMENT.TYPE.1': 'Confirmation letter template',
    'CONFIRMATION.DOCUMENT.TYPE.2': 'Client request',
    'CONFIRMATION.DOCUMENT.TYPE.3': 'Client answer',
    'CONFIRMATION.DOCUMENT.TYPE.4': 'Third party request',
    'CONFIRMATION.DOCUMENT.TYPE.5': 'Third party answer',
    'CONFIRMATION.DOCUMENT.TYPE.6': 'Email',
    'CONFIRMATION.DOCUMENT.TYPE.7': 'Other',
    'CONFIRMATION.DOCUMENT.TYPE.PLACEHOLDER': 'Select a document type',
    'CONFIRMATION.CONTACT.EMAIL.INVALID.MESSAGE':
        'Email sent to contact {email}, returned as not delivered on the {date}',
    'CONFIRMATION.CLIENT.SENDING.REQUESTS.NUMBER.MESSAGE':
        'Confirmation sent to client {counter} times -  last request: {date}',
    'CONFIRMATION.THIRDPARTY.SENDING.REQUESTS.NUMBER.MESSAGE':
        'Confirmation sent to third-party {counter} times -  last request: {date}',
    'CONFIRMATION.THIRDPARTY.SENDING.REQUESTS.CHECK.LETTER.MESSAGE':
        'Please check the content of your confirmation letter before sending (Signature, quality, number of pages,...)',
    'CONFIRMATION.STACKABLE.ACTIONS.CHOICE': 'Confirmation sending options',
    'COMMENT.PLACEHOLDER.COMMENT': 'Write your comment here..',
    'COMMENT.POST.VALIDATION.REQUIRED_FIELD.TEXT': 'Message is required',
    'COMMENT.BUTTON.ADD': 'Post',
    'COMMENT.CHECKBOX.LABEL': 'Private',
    'COMMENT.CHECKBOX.TOOLTIP': 'Private messages can only be visualised by authorised user',
    'CONFIRMATION.ACCESS.COUNTRIES.ERROR': 'Error loading countries',
    'CONFIRMATION.ACCESS.LANGUAGES.ERROR': 'Error loading languages',
    'IMPORT.CREATE.CONFIRMATION.ACCESS.XLS_TEMPLATE.ACCESS.ERROR': 'Confirmations template xlxs access error',
    'IMPORT.CREATE.CONFIRMATION.ACCESS.CONTACT.ERROR': 'Contacts access error',
    'IMPORT.CREATE.CONFIRMATION.CHECK.SUCCESS': 'Confirmations imported checked',
    'IMPORT.CREATE.CONFIRMATION.CHECK.ERROR': 'Check confirmations imported failed.\nPlease modify the red boxes in the downloaded file',
    'IMPORT.CREATE.CONFIRMATION.CREATE.SUCCESS': 'Confirmations created',
    'IMPORT.CREATE.CONFIRMATION.CREATE.ERROR': 'Create confirmations failed',
    'FETCH.CONFIRMATION.WORKFLOWS.ACCESS.ERROR': 'Workflows access error',
    'FETCH.CONFIRMATION.WORKFLOW.ACTIONS.ACCESS.ERROR': 'Workflow actions access error',
    'LETTER.TEMPLATE.ACCESS.ERROR': 'Letter templates access error',
    'LETTER.TEMPLATE.TAG.ACCESS.ERROR': 'Letter template tags access error',
    'FETCH.EMAIL.TEMPLATES.ACCESS.ERROR': 'Email templates access error',

    'CONFIRMATION.NAVIGATION.PREVIOUS.TOOLTIP': 'Previous confirmation',
    'CONFIRMATION.NAVIGATION.NEXT.TOOLTIP': 'Next confirmation',
    'FETCH.ENGAGEMENT.MENU.ACCESS.ERROR': 'Fetch menu confirmations failed'
};
