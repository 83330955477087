export const support = {
    'SUPPORT.FILTER.PROCESS.PAPER.LABEL': 'Demandes de circularisation',
    'SUPPORT.FILTER.PROCESS.PAPER.TOOLTIP': 'Documents à envoyer',
    'SUPPORT.FILTER.DOCUMENT.RECEIVED.LABEL': 'Documents reçues',
    'SUPPORT.FILTER.DOCUMENT.RECEIVED.TOOLTIP': 'Documents à affecter',
    'SUPPORT.BUTTON.LABEL.UPLOAD': 'Upload de documents scannés',
    'SUPPORT.CHECKBOX.UPLOAD.GROUPED.LABEL': 'Grouper dans un dossier plusieurs fichiers',
    'SUPPORT.CHECKBOX.UPLOAD.GROUPED.TOOLTIP': 'Uploader des documents liés à la même circularisation dans un dossier.',
    'COUNTRY_SUPPORT.TITLE': 'Support du pays',
    'SUPPORT.BUTTON.CLOSE.TRASH.LABEL': 'Fermer la corbeille',
    'SUPPORT.SEARCH.PLACEHOLDER': 'Rechercher par nom ou commentaire'
};
