import { Grid } from '@mui/material';
import { FilterComponent } from './FilterComponent';
import React, { Children, Fragment } from 'react';

export const FilterRowContainer = ({ rowProps, handleFilterChange, selectedFilters }) => {
    const { columns, selection } = rowProps;

    return (
        <>
            <tr>
                {selection && <th />}
                {Children.toArray(
                    columns.map(({ field, filteringConfig, filtering }, index) => (
                        <Fragment key={index}>
                            {field && filteringConfig?.length > 0 ? (
                                <th>
                                    <Grid container direction="row" justifyContent="center" spacing={1}>
                                        {filteringConfig.map(config => (
                                            <Grid item key={config.id} xs={12 / filteringConfig.length - 1}>
                                                <FilterComponent
                                                    filtering={filtering}
                                                    {...config}
                                                    handleFilterChange={handleFilterChange}
                                                    selectedFilter={selectedFilters[config?.filterName]}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </th>
                            ) : (
                                <th />
                            )}
                        </Fragment>
                    ))
                )}
            </tr>
        </>
    );
};
