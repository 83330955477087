import { buildFilters } from '../../network/mzrRequest';
import { appApiUrl } from '../../config/appConfiguration';
import { parsePaginationResponse, parseResponseArray } from '../parsers';
import { parseCompanies, parseCompany } from './companyParsers';
import { parseContacts } from '../contact/contactParsers';
import { Repository } from '../Repository';

/**
 * Class to communicate with API to access to third-party company resources.
 */
class EngagementThirdPartyCompanyRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    /**
     * Create a list of third-party companies.
     * @param {*} companies
     * @returns
     */
    create = async (companies) => {
        let result = await this.network.post('EngagementThirdPartyCompany', companies, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseArray);
        return result;
    };

    /**
     * Update a list of third-party companies.
     * @param {*} companies
     * @returns
     */
    update = async (companies) => {
        let result = await this.network.put('EngagementThirdPartyCompany', companies, {
            headers: this.buildHeaders()
        });

        return result;
    };

    /**
     * Delete a list of third-party companies.
     * @param {*} companies
     * @returns
     */
    delete = async (companies) => {
        return await this.network.delete(`EngagementThirdPartyCompany`, companies, {
            headers: this.buildHeaders()
        });
    };

    /**
     * Get third-party companies for the engagement.
     * @param {*} engagementId
     * @returns
     */

    fetchByEngagement = async (engagementId, filters = {}) => {
        const formattedFilters = buildFilters(filters);
        let result = await this.network.get(
            `EngagementThirdPartyCompany/engagement/${engagementId}${formattedFilters}`,
            {
                headers: this.buildHeaders()
            }
        );

        result.tryParsingValue(parsePaginationResponse.bind(this, parseCompanies));
        return result;
    };

    /**
     * Get third-party companies for the engagement.
     * @param {*} engagementId
     * @returns
     */
    deleteEngagement = async (engagementId) => {
        return await this.network.delete(`EngagementThirdPartyCompany/engagement/${engagementId}`, {
            headers: this.buildHeaders()
        });
    };

    /**
     * Get details for a third-party company selected.
     * @param {*} companyId
     * @returns
     */
    fetch = async (companyId) => {
        let result = await this.network.get(`EngagementThirdPartyCompany/${companyId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseCompany);
        return result;
    };

    /**
     * Get contacts for a company selected.
     * TODO: check return is it a list of contact objects or engagement object
     * @param {*} companyId
     * @returns
     */
    fetchContacts = async (companyId) => {
        let result = await this.network.get(`EngagementThirdPartyCompany/${companyId}/contacts`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseContacts);
        return result;
    };

    /***
     * Download file with engagement's third-party companies & contacts
     * @param engagementId: the id of the engagement
     */
     downloadAll = async (engagementId) => {
        return await this.network.get(`EngagementThirdPartyCompany/engagement/${engagementId}/file`, {
            headers: this.buildHeaders()
        });
    };
}

export const engagementThirdPartyCompanyRepository = new EngagementThirdPartyCompanyRepository();
