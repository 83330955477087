import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../partials/controls/Card';
import { FormattedMessage } from 'react-intl';
import { Grid, CircularProgress } from '@material-ui/core';
import {
    MoreHorizTwoTone as MoreHorizTwoToneIcon,
    AddCircleOutline,
    BorderColorTwoTone as BorderColorTwoToneIcon,
    HighlightOff as HighlightOffIcon
} from '@mui/icons-material';
import { Button, Box } from '@material-ui/core';
import { initEmailTemplate } from './InitEmailTemplate';
import { EmailTemplateForm } from './EmailTemplateForm';
import { TemplateList } from './TemplateList';
import { TemplateTagsWrapper } from './TemplateTagsWrapper';
import { UIDialog } from '../../common/UIDialog';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    boxButton: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    }
}));

export function EmailTemplateConfigComponent(props) {
    const {
        currentClusterId,
        emailTemplates,
        currentEmailType,
        emailTypes,
        languages,
        confirmationCategories,
        createTemplate,
        updateTemplate,
        deleteTemplate
    } = props;

    const classes = useStyles();
    const sbmtTemplRef = useRef();

    const [emailTemplate, setTemplate] = useState(initEmailTemplate);
    const [openTemplate, setOpenTemplate] = useState(false);

    useEffect(() => {
        setTemplate({ ...initEmailTemplate, emailTypeId: currentEmailType?.id, clusterId: currentClusterId });
    }, [currentEmailType, currentClusterId]);
    
    const handlePopUpTemplateOpening = () => setOpenTemplate(!openTemplate);

    const handleClickSave = () => {
        if (sbmtTemplRef && sbmtTemplRef.current) {
            sbmtTemplRef.current.saveForm();
        }
    };

    const handleSubmit = values => {
        setTemplate({ ...emailTemplate, ...values });
        if (emailTemplate?.id) {
            updateTemplate({ ...emailTemplate, ...values });
        } else {
            createTemplate([{ ...emailTemplate, ...values }]);
        }
        setOpenTemplate(!openTemplate);
    };

    const handleResetTemplate = () => {
        setTemplate({ ...initEmailTemplate, emailTypeId: currentEmailType?.id, clusterId: currentClusterId });
    };

    const handleSaveTemplate = () => {
        if (emailTemplate?.id) {
            updateTemplate(emailTemplate);
        } else {
            createTemplate([emailTemplate]);
        }
    };

    const handleSelectTemplate = values => {
        setTemplate({ ...emailTemplate, ...values });
    };

    const handleDeleteTemplate = values => {
        deleteTemplate(values.id);
    };

    return (
        <>
            <Grid container direction="column" alignItems="stretch">
                <Grid container alignItems="stretch">
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader title={<FormattedMessage id="EMAIL_TEMPLATE.SUBSECTION.CREATE.TITLE" />}>
                                <CardHeaderToolbar>
                                    <Box component="span" m={1} className={clsx(classes.box, classes.boxButton)}>
                                        <Button
                                            startIcon={
                                                emailTemplate.id ? (
                                                    <BorderColorTwoToneIcon color="inherit" />
                                                ) : (
                                                    <AddCircleOutline color="inherit" />
                                                )
                                            }
                                            variant="outlined"
                                            color="primary"
                                            onClick={handlePopUpTemplateOpening}>
                                            {emailTemplate.id ? (
                                                <FormattedMessage id={'EMAIL_TEMPLATE.INFO.BUTTON.EDIT.LABEL'} />
                                            ) : (
                                                <FormattedMessage id={'EMAIL_TEMPLATE.INFO.BUTTON.CREATE.LABEL'} />
                                            )}
                                        </Button>
                                        {emailTemplate.name !== '' ? (
                                            <>
                                                <Button
                                                    startIcon={<HighlightOffIcon color="inherit" />}
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={handleResetTemplate}>
                                                    <FormattedMessage id="EMAIL_TEMPLATE.INFO.BUTTON.CLEAR.LABEL" />
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={handleSaveTemplate}>
                                                    <FormattedMessage id="EMAIL_TEMPLATE.INFO.BUTTON.SAVE.LABEL" />
                                                </Button>
                                            </>
                                        ) : null}
                                    </Box>
                                </CardHeaderToolbar>
                            </CardHeader>
                            <CardBody>
                                <Grid container direction="column" alignItems="stretch" spacing={6}>
                                    <Grid item xs={12}>
                                        {openTemplate && (
                                            <UIDialog
                                                open={openTemplate}
                                                titleId="EMAIL_TEMPLATE.INFO.TITLE.CREATE.LABEL"
                                                onSave={handleClickSave}
                                                onClose={() => setOpenTemplate(!openTemplate)}>
                                                <EmailTemplateForm
                                                    emailTemplate={emailTemplate}
                                                    languages={languages}
                                                    confirmationCategories={confirmationCategories}
                                                    disabled={false}
                                                    handleSubmit={handleSubmit}
                                                    sbmtTemplRef={sbmtTemplRef}
                                                />
                                            </UIDialog>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        {emailTemplate.name ? (
                                            <EmailTemplateForm
                                                emailTemplate={emailTemplate}
                                                languages={languages}
                                                confirmationCategories={confirmationCategories}
                                                disabled={true}
                                                handleSubmit={handleSubmit}
                                                sbmtTemplRef={sbmtTemplRef}
                                            />
                                        ) : (
                                            <div className="text-center">
                                                <MoreHorizTwoToneIcon />
                                            </div>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        {emailTypes ? (
                                            <TemplateTagsWrapper
                                                key={`tag-section-component-${emailTemplate?.emailTypeId}`}
                                                options={Object.values(emailTypes).find(
                                                    x => x.id === emailTemplate?.emailTypeId
                                                )}
                                            />
                                        ) : (
                                            <CircularProgress />
                                        )}
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>

                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                title={
                                    <FormattedMessage id="EMAIL_TEMPLATE.SUBSECTION.TEMPLATES.TITLE" />
                                }></CardHeader>
                            <CardBody>
                                {emailTemplates ? (
                                    <TemplateList
                                        templates={Object.values(emailTemplates).filter(
                                            template => template?.emailTypeId === currentEmailType?.id
                                        )}
                                        confirmationCategories={confirmationCategories}
                                        handleSelectTemplate={handleSelectTemplate}
                                        handleDeleteTemplate={handleDeleteTemplate}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <MoreHorizTwoToneIcon />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
