import { DEFAULT_ENGAGEMENT_PAGE_SIZE, DEFAULT_PAGE_SIZE } from '../../helpers/TableHelper';
import { ENGAGEMENT_COLUMNS_FILTERS, WORKFLOW_ACTION_STATUS } from '../../components/engagement/InitEngagement';

export const SessionStatus = {
    valid: 'valid',
    invalid: 'invalid',
    unknown: 'unknown',
    forbidden: 'forbidden'
};

export const FileExtension = {
    DIR: '.dir',
    PDF: '.pdf',
    MSG: '.msg',
    DOCX: '.docx',
    EML: '.eml',
    P7M: '.p7m',
    XLS: '.xls',
    XLSX: '.xlsx',
    JPG: '.jpg',
    JPEG: '.jpeg',
    PNG: '.png',
};

export const CONFIRMATION_STEP_STATUS = {
    CREATED: 0,
    INPROGRESS: 1,
    VALIDATED: 2
};

export const COMPANY_TYPE = {
    CLIENT: 'Client',
    THIRDPARTY: 'ThirdParty'
};

export const SUPPORT_PROCESS = {
    REQUESTS: 0,
    ANSWERS: 1
};

export const INIT_ITEMS_FILTER = {
    PAGE_SIZE: DEFAULT_PAGE_SIZE,
    PAGE_INDEX: 0,
    DISPLAY_MODE_ROOT_ITEM_ONLY: 'rootitemsonly',
    ENGAGEMENT_ID: null,
    CONFIRMATION_ID: null,
    IS_LOST: false,
    IS_TRASHED: false
};

export const SORT_CHOICES = {
    UPDATED_ASC: 'updated',
    UPDATED_DESC: 'updated_desc',
    CREATED_DESC: 'created_desc'
};

export const EXTERNAL_EMAIL_TYPES = ['ThirdPartyConfirmationRequest', 'ClientConfirmationRequest'];

export const E_WORKFLOW_ACTIONS = {
    CONFIRMATION_CREATED: 0,
    CONFIRMATION_CLIENT_REQUEST_GENERATED: 1,
    VALIDATION_FROM_ENGAGEMENT_ADMIN: 2,
    CONFIRMATION_CLIENT_REQUEST_SEND_VIA_EMAIL: 3,
    CONFIRMATION_CLIENT_REQUEST_SEND_VIA_PAPER: 4,
    CONFIRMATION_CLIENT_REQUEST_SEND_VIA_ESIGNATURE: 5,
    CONFIRMATION_CLIENT_ANSWER_RECEIVED: 6,
    CONFIRMATION_THIRDPARTY_REQUEST_SENT_VIA_EMAIL: 7,
    CONFIRMATION_THIRDPARTY_REQUEST_SENT_VIA_PAPER: 8,
    CONFIRMATION_THIRDPARTY_REQUEST_SENT_VIA_ESIGNATURE: 9,
    CONFIRMATION_THIRDPARTY_ANSWER_RECEIVED: 10,
    FINISH: 11
};

export const INIT_ENGAGEMENT_FILTER = {
    pageSize: DEFAULT_ENGAGEMENT_PAGE_SIZE,
    pageIndex: 0,
    sort: SORT_CHOICES.UPDATED_DESC,
    [ENGAGEMENT_COLUMNS_FILTERS.CLIENT]: null,
    [ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY]: null,
    [ENGAGEMENT_COLUMNS_FILTERS.CATEGORY]: null,
    [ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION]: null,
    [ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION_STATUS]: WORKFLOW_ACTION_STATUS.IN_PROGRESS
};

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
