export const appName = process.env.REACT_APP_APP_NAME;
export const mazarsOrganizationId = process.env.REACT_APP_MAZARS_MASTER_ORGANIZATION_ID;

export let appApiUrl = process.env.REACT_APP_API_BASE_URL;

export let authApiBaseURL = process.env.REACT_APP_MAZARS_AUTH_BASE_URL;
export let externalAuthApiBaseURL = process.env.REACT_APP_EXTERNAL_AUTH_BASE_URL;
export let externalAuthClientId = process.env.REACT_APP_EXTERNAL_CLIENT_ID;

export let syncfusionLicenseKey = process.env.REACT_APP_SYNCFUSION_LICENSE_KEY;

export let pdfViewerServiceURL = process.env.REACT_APP_PDFVIEWER_SERVICE_URL;

export let isExternalAccessEnabled = process.env.REACT_APP_EXTERNAL_ACCESS_STATE === 'on'; // possible values on off see .env can override this value in env.dev/prod...
