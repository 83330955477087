import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { initPaginatedObject } from '../common/InitPaginatedObject';
import { ValidationMessageComponent, RefuseMessageComponent } from '../message';
import { FileExtension } from '../../kit/utils/constants';
import { TREE_COLOR } from '../../helpers/TableHelper';
import { UNDEFINED_ITEM_ID } from './InitItem';
import { isAuthorisedProfile } from '../auth/UserHelper';
import { ItemNode, PROFILE } from '../../types/types';
import { UIDialog } from '../common/UIDialog';
import { MZRPaginatedTable } from '../common/MZRPaginatedTable';
import { DocumentTableActions } from './DocumentTableActions';
import { configureDocumentTable } from './ConfigureDocumentTable';
import { CONFIRMATION_COLUMNS_FILTERS } from '../confirmation/common';
import { DocumentPreview } from './DocumentPreview';

const authorizedVisualisation = [FileExtension.PDF, FileExtension.DOCX];
const authorizedDownload = [FileExtension.EML, FileExtension.P7M];

interface DocumentTableComponentProps {
    user: any;
    actionProfile: any;
    loading: boolean;
    paginatedItems: Record<string, any>;
    menuEngagements: any[];
    setDisplayInvalidAddressEnabled?: boolean;
    handleFetchMenuConfirmations: (filters: Record<string, any>) => Promise<any>;
    documentTypes;
    fetchItemFile;
    fetchItemInfo;
    updateItem;
    handleFilterItems;
    downloadItem;
    actions?: any[];
    optionalActionsEnabled?: boolean;
    isRefuseEnabled?: boolean;
    setLoading?: any;
}

export function DocumentTableComponent({
    user,
    actionProfile,
    loading,
    paginatedItems,
    menuEngagements,
    setDisplayInvalidAddressEnabled,
    handleFetchMenuConfirmations,
    documentTypes,
    fetchItemFile,
    handleFilterItems,
    updateItem,
    downloadItem,
    actions = [],
    optionalActionsEnabled = true,
    isRefuseEnabled = true,
    setLoading
}: DocumentTableComponentProps) {
    const intl = useIntl();
    const btnValidationRef = useRef();
    const btnRefuseRef = useRef();
    const [loadedItems, setLoadedItems] = useState(initPaginatedObject);
    const [currentDocument, setCurrentDocument] = useState<any | null>(null);
    const [openValidationDialog, setOpenValidationDialog] = useState(false);
    const [openRefuseDialog, setOpenRefuseDialog] = useState(false);
    const [triggerPreviewDialog, setTriggerPreviewDialog] = useState<number>(0);

    useEffect(() => {
        paginatedItems && setLoadedItems(paginatedItems);
    }, [paginatedItems]);

    const handleItemValidationPopUpOpening = () => setOpenValidationDialog(!openValidationDialog);
    const handleItemRefusePopUpOpening = () => setOpenRefuseDialog(!openRefuseDialog);

    const handleItemOpen = async (item: ItemNode) => {
        if (authorizedVisualisation.includes(item?.fileExtension.toLowerCase())) {
            documentPreviewClick(item);
        } else if (authorizedDownload.includes(item?.fileExtension.toLowerCase())) {
            await downloadItem(item);
        } else {
            console.log('Visualisation not allowed!');
        }
    };

    const handleValidationClick = () => {
        if (btnValidationRef && btnValidationRef.current) {
            // @ts-ignore
            btnValidationRef.current.saveForm();
        }
    };

    const handleItemValidate = item => {
        // Check if item is within a folder (item has a parentItemId or not)
        if (item.parentItemId !== UNDEFINED_ITEM_ID && item.parentItemId !== null) {
            // Option to apply changes to all folder
            setCurrentDocument(item);
            handleItemValidationPopUpOpening();
        } else {
            updateItem(item);
        }
    };

    const handleItemValidationSubmit = item => {
        updateItem(item);
        handleItemValidationPopUpOpening();
    };

    const handleRefuseClick = () => {
        if (btnRefuseRef && btnRefuseRef.current) {
            // @ts-ignore
            btnRefuseRef.current.saveForm();
        }
    };

    const handleItemRefuse = item => {
        const clear = {
            engagementId: null,
            confirmationId: null
        };

        if (isAuthorisedProfile(PROFILE.AUDITOR, user.profil) && isAuthorisedProfile(PROFILE.AUDITOR, actionProfile)) {
            updateItem({ ...item, ...clear });
        } else {
            setCurrentDocument({ ...item, ...clear });
            handleItemRefusePopUpOpening();
        }
    };

    const handleItemRefuseSubmit = item => {
        updateItem(item);
        handleItemRefusePopUpOpening();
    };

    const handleItemLost = item => {
        if (isAuthorisedProfile(PROFILE.POSTIE, user.profil) && isAuthorisedProfile(PROFILE.POSTIE, actionProfile)) {
            setCurrentDocument({ ...item, isLost: true });
            handleItemRefusePopUpOpening();
        }
    };

    const handleLoadedItemsUpdate = item => {
        let documentsUpdated = [...loadedItems?.items];
        const docIndex = item.tableData.id;

        if (docIndex in Object.keys(loadedItems?.items)) {
            documentsUpdated[docIndex] = { ...item };
        } else {
            console.log(`Document index ${docIndex} does not exit`);
        }

        setLoadedItems({ ...loadedItems, items: documentsUpdated });
    };

    const handleEngagementChanged = async rowItem => {
        // the engagement has been changed in the engagement dropdown => fetch its confirmations and populate the confirmations dropdown
        const menuConfirmations = await handleFetchMenuConfirmations({
            [CONFIRMATION_COLUMNS_FILTERS.ENGAGEMENT_ID]: rowItem.engagementId,
            [CONFIRMATION_COLUMNS_FILTERS.CONFIRMATION_NAME_OR_CODE]: null
        });

        handleLoadedItemsUpdate({ ...rowItem, menuConfirmations: menuConfirmations });
    };

    const handleConfirmationInputChange = (input, rowItem) => {
        if (input && input?.length >= 4) {
            handleFetchMenuConfirmations({
                [CONFIRMATION_COLUMNS_FILTERS.ENGAGEMENT_ID]: rowItem.engagementId,
                [CONFIRMATION_COLUMNS_FILTERS.CONFIRMATION_NAME_OR_CODE]: input
            }).then(menuConfirmations => {
                handleLoadedItemsUpdate({ ...rowItem, menuConfirmations: menuConfirmations || [] });
            });
        }
    };

    const getParentChildData = (row: any, rows: any[]) => rows.find(a => a.id === row.parentItemId);

    const { actions: configuredActions, columns: configuredColumns } = configureDocumentTable({
        intl,
        columnsProps: {
            documentTypes,
            handleEngagementChanged,
            menuEngagements,
            setDisplayInvalidAddressEnabled,
            handleLoadedItemsUpdate,
            handleConfirmationInputChange
        },
        actionsProps: {
            actions,
            actionProfile,
            optionalActionsEnabled,
            isRefuseEnabled,
            handleItemRefuse,
            handleItemOpen,
            handleItemLost,
            handleItemValidate
        }
    });

    const documentPreviewClick = item => {
        setCurrentDocument(item);
        setTriggerPreviewDialog(trigger => trigger + 1);
    };

    const updateOpenPreviewDocument = (value: number) => {
        setTriggerPreviewDialog(value);
        value === 0 && setCurrentDocument(null);
    };

    return (
        <>
            <MZRPaginatedTable
                handleFilterChange={handleFilterItems}
                columns={configuredColumns}
                data={loadedItems}
                parentChildData={getParentChildData}
                options={{
                    rowStyle: rowData => {
                        if (!rowData.tableData.isTreeExpanded && rowData.tableData.path.length === 1) {
                            return {};
                        }
                        return { backgroundColor: TREE_COLOR[rowData.tableData.path.length] };
                    },
                    sorting: true
                }}
                actions={configuredActions}
                components={{
                    Action: props => <DocumentTableActions {...props} />
                }}
                loading={loading}
                onRowClick={undefined}
            />
            {/*Dialogs*/}
            {openValidationDialog && (
                <UIDialog
                    open={openValidationDialog}
                    titleId="MESSAGE.RESET.WORKFLOW.STEP.TITLE"
                    onSave={handleValidationClick}
                    onClose={handleItemValidationPopUpOpening}>
                    <ValidationMessageComponent
                        intl={intl}
                        user={user}
                        document={currentDocument}
                        documents={loadedItems?.items}
                        documentTypes={documentTypes}
                        handleSubmit={handleItemValidationSubmit}
                        btnValidationRef={btnValidationRef}
                    />
                </UIDialog>
            )}
            {openRefuseDialog && (
                <UIDialog
                    open={openRefuseDialog}
                    titleId="MESSAGE.REFUSE.WORKFLOW.STEP.TITLE"
                    onSave={handleRefuseClick}
                    onClose={handleItemRefusePopUpOpening}>
                    <RefuseMessageComponent
                        intl={intl}
                        user={user}
                        document={currentDocument}
                        messageTitle="MESSAGE.REFUSE.LOST.DOCUMENT.CONTENT"
                        messageComment="MESSAGE.REFUSE.LOST.DOCUMENT.COMMENT"
                        handleSubmit={handleItemRefuseSubmit}
                        btnRefuseRef={btnRefuseRef}
                    />
                </UIDialog>
            )}
            {currentDocument && triggerPreviewDialog && (
                <DocumentPreview
                    isDownloadable
                    setLoading={setLoading}
                    fetchDocumentFileAPI={fetchItemFile}
                    item={currentDocument}
                    documentTypes={documentTypes}
                    menuEngagements={menuEngagements}
                    handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                    updateDocumentAPI={updateItem}
                    isEditable
                    updateOpenPreview={updateOpenPreviewDocument}
                    shouldOpenPreview={triggerPreviewDialog}
                />
            )}
        </>
    );
}
