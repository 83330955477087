import { parseRole } from "../user/userParsers";

export const parseAuthResponse = (json: any) => ({
    token: json.token
});

export const parseProfile = (json: any) => {
    return {
        id: json.id,
        firstName: json.firstName,
        lastName: json.lastName,
        email: json.email,
        city: json.city,
        address: json.address,
        phoneNumber: json.phoneNumber,
        jobTitle: json.jobTitle,
        profil: json.Profile,
        roles: json.roles ? json.roles.map(x => parseRole(x)) : []
    };
};
