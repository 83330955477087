import React from 'react';
import { CLIENT_CONFIRMATION_STEP_TYPE, ClientRequest, CONTACT_TYPE } from '../../../../types/types';
import { Box, Divider } from '@mui/material';
import { ContactsList } from '../../../contact';
import { Check, Close } from '@mui/icons-material';
import { UITooltip } from '../../../common/UITooltip';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../../i18n/i18Helper';
import { CompanyDetails } from '../../../company/CompanyDetails';
import { UIText } from '../../../common/UIText';

interface ClientRequestValidationStepProps {
    request: ClientRequest;
    stepsStatus: any;
}

interface ClientRequestValidationSectionTitleProps {
    title: string;
    isCompleted: boolean;
}

const ClientRequestValidationSectionTitle = ({ title, isCompleted }: ClientRequestValidationSectionTitleProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <UIText text={title} variant="h6" />

            {isCompleted ? <Check color="primary" fontSize="large" /> : <Close color="error" fontSize="large" />}
        </Box>
    );
};

interface ClientRequestValidationContactSectionProps extends ClientRequestValidationSectionTitleProps {
    contacts: any[];
    contactType: CONTACT_TYPE;
}

const ClientRequestValidationContactSection = ({
    contacts,
    contactType,
    title,
    isCompleted
}: ClientRequestValidationContactSectionProps) => {
    return (
        <>
            <ClientRequestValidationSectionTitle title={title} isCompleted={isCompleted} />

            <ContactsList
                isSignatoryOptionDisabled
                contactType={contactType}
                disabled
                isRoleEditable={false}
                contacts={contacts}
                handleClickOpen={undefined}
                handleRemoveContact={undefined}
                handleRoleChange={undefined}
            />
        </>
    );
};

interface ClientRequestValidationCompanySectionSectionProps extends ClientRequestValidationSectionTitleProps {
    company: any;
}

const ClientRequestValidationCompanySection = ({
    company,
    title,
    isCompleted
}: ClientRequestValidationCompanySectionSectionProps) => {
    return (
        <>
            <ClientRequestValidationSectionTitle title={title} isCompleted={isCompleted} />

            <UITooltip title={<CompanyDetails company={company} />}>
                <Box sx={{ px: 2, py: 2 }}>
                    <UIText text={company.name} />
                    <UIText text={`${company.companyId ?? ''} ${company.companyId2 ?? ''} ${company.vatId ?? ''}`} />
                    <UIText text={`${company.address}, ${company.zipCode} ${company.city}, ${company.countryText}`} />
                </Box>
            </UITooltip>
        </>
    );
};

export const ClientRequestValidationStep = ({ request, stepsStatus }: ClientRequestValidationStepProps) => {
    const intl = useIntl();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <ClientRequestValidationCompanySection
                company={request.clientCompany}
                title={getLocalizedString(intl, 'CLIENT.REQUEST.VALIDATION.STEP.CLIENT.COMPANY')}
                isCompleted
            />

            <Divider sx={sx.divider} />

            <ClientRequestValidationContactSection
                title={getLocalizedString(intl, 'CLIENT.REQUEST.VALIDATION.STEP.CLIENT.CONTACTS')}
                isCompleted
                contacts={request.clientContacts}
                contactType={CONTACT_TYPE.CLIENT}
            />

            <Divider sx={sx.divider} />
            <ClientRequestValidationCompanySection
                company={request.thirdPartyCompany}
                title={getLocalizedString(intl, 'CLIENT.REQUEST.VALIDATION.STEP.THIRD_PARTY.COMPANY')}
                isCompleted={stepsStatus[CLIENT_CONFIRMATION_STEP_TYPE.THIRD_PARTY]}
            />

            <Divider sx={sx.divider} />
            <ClientRequestValidationContactSection
                title={getLocalizedString(intl, 'CLIENT.REQUEST.VALIDATION.STEP.THIRD_PARTY.CONTACTS')}
                isCompleted={stepsStatus[CLIENT_CONFIRMATION_STEP_TYPE.THIRD_PARTY]}
                contactType={CONTACT_TYPE.THIRD_PARTY}
                contacts={request.thirdPartyContacts}
            />

            <Divider sx={sx.divider} />
            <ClientRequestValidationSectionTitle
                title={getLocalizedString(intl, 'CLIENT.REQUEST.VALIDATION.STEP.LETTERHEAD')}
                isCompleted={stepsStatus[CLIENT_CONFIRMATION_STEP_TYPE.LETTERHEAD]}
            />
        </Box>
    );
};

const sx = {
    divider: {
        height: 0.5
    }
} as const;
