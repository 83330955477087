export const EngagementTypes = {
    SET_ENGAGEMENT: 'setEngagement',
    RESET_ENGAGEMENT: 'resetEngagement',
    SET_ACTIVE_STEP_POSITION: 'setActiveStepPosition',
    RESET_ACTIVE_STEP_POSITION: 'resetActiveStepPosition',
    SET_CONFIRMATIONS_FILTERS: 'setConfirmationsFilters',
    RESET_CONFIRMATIONS_FILTERS: 'resetConfirmationsFilters',
    SET_SELECTED_CONFIRMATION_INDEX: 'setSelectedConfirmationIndex'
};

export const setEngagement = engagement => {
    return {
        type: EngagementTypes.SET_ENGAGEMENT,
        engagement
    };
};

export const setActiveStepPosition = activeStepPosition => {
    return {
        type: EngagementTypes.SET_ACTIVE_STEP_POSITION,
        activeStepPosition
    };
};

export const resetEngagement = () => {
    return {
        type: EngagementTypes.RESET_ENGAGEMENT
    };
};

export const resetActiveStepPosition = () => {
    return {
        type: EngagementTypes.RESET_ACTIVE_STEP_POSITION
    };
};

export const setConfirmationsFilters = filters => {
    return {
        type: EngagementTypes.SET_CONFIRMATIONS_FILTERS,
        filters
    };
};

export const resetConfirmationsFilters = () => {
    return {
        type: EngagementTypes.RESET_CONFIRMATIONS_FILTERS
    };
};

export const setSelectedConfirmationIndex = index => {
    return {
        type: EngagementTypes.SET_SELECTED_CONFIRMATION_INDEX,
        index
    };
};
