import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PROFILE } from '../../types/types';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import { Loader } from '../../components/loader/Loader';
import { MetricsService } from '../../services';
import { MetricsComponent } from '../../components/metrics';

/**
 * Country administration page component for user with countryAdmin role.
 *
 * This administration page give users the tools to setup the application for his country's needs.
 *
 * Country configuration for mailing, robot, DMS, workflows, templates, client companies (Please refer to the config section).
 */
class CountryAdminMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    metricsManager = MetricsService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading
    });

    fetchMetrics = async () => {
        const { currentClusterId } = this.props;
        const result = await this.metricsManager.fetch({ clusterId: currentClusterId });
        result?.isSuccess && this.setState({ metrics: result?.body });
    };

    async componentDidMount() {
        await this.fetchMetrics();
    }

    render() {
        const { user } = this.props;

        if (!user.profil) {
            return <Loader />;
        }

        if (isAuthorisedProfile(PROFILE.ADMIN, user.profil)) {
            return <MetricsComponent loading={this.state.loading} metrics={this.state.metrics} />;
        }

        return <PageNotFound {...this.props} />;
    }
}

CountryAdminMetrics.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array
};

CountryAdminMetrics.defaultProps = {
    loading: false
};

const mapStateToProps = state => {
    return {
        user: state.account.user,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const countryAdminMetrics = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps)
)(injectIntl(CountryAdminMetrics));
