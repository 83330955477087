import { FieldProps, useFormikContext } from 'formik';
import { de, enGB, es, fr, it, zhCN } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { TextFieldVariants } from '@mui/material';

interface FormValues {
  name: string;
  value: Date;
}

interface UIDatePickerProps {
  className?: string;
  locale: string;
  variant: TextFieldVariants;
  size: 'small' | 'medium' | undefined;
  disabled: boolean;
  disablePast: boolean;
  callback?: (value: Date | null, setFunc: (name: string, value: Date) => void) => void;
}

const locales = { 'en-us': undefined, 'en-gb': enGB, 'es-mx': es, 'fr-fr': fr, 'it-it': it, 'zh-cn': zhCN, de };

export const UIDatePickerField = ({
  name,
  value,
  className,
  locale = 'fr-fr',
  variant = 'outlined',
  size = 'medium',
  disabled = false,
  disablePast = false,
  callback = undefined,
}: UIDatePickerProps & DatePickerProps<Date> & FieldProps<FormValues>) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (value: Date | null) => {
    name && setFieldValue(name, value);
    callback && callback(value, setFieldValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales[locale.toLowerCase()]}>
      <DatePicker
        name={name}
        value={value}
        className={className}
        disabled={disabled}
        disablePast={disablePast}
        closeOnSelect
        onChange={(value) => handleChange(value)}
        slotProps={{
          textField: { variant: variant, InputProps: { size: size }, sx: { margin: '0 0.6em', width: '100%' }, className: className },
        }}
      />
    </LocalizationProvider>
  );
};