import React, { useEffect, useState } from 'react';

import {
    Annotation,
    BookmarkView,
    FormDesigner,
    FormFields,
    Inject,
    LinkAnnotation,
    Magnification,
    Navigation,
    PdfViewerComponent,
    Print,
    TextSearch,
    TextSelection,
    ThumbnailView,
    Toolbar
} from '@syncfusion/ej2-react-pdfviewer';
import { blobToBase64 } from '../../helpers';
import * as SyncfusionService from '../../services/SyncfusionService';

export const DocumentViewerContent = ({
    children,
    document,
    actions,
    fileName,
    id,
    enableSignature = false,
    enableDownload = true
}: {
    children?: React.ReactNode;
    document: Blob | null;
    id: string;
    fileName: string;
    actions?: React.ReactNode;
    enableSignature?: boolean;
    enableDownload?: boolean;
}) => {
    const [fileData, setFileData] = useState<string>('');
    let pdfViewerRef: any;
    let services: any[] = [
        Toolbar,
        Magnification,
        Navigation,
        Annotation,
        LinkAnnotation,
        BookmarkView,
        ThumbnailView,
        Print,
        TextSelection,
        TextSearch,
        FormFields,
        FormDesigner
    ];

    useEffect(() => {
        (async () => {
            const data: string = !document
                ? ''
                : document instanceof Blob
                ? ((await blobToBase64(document)) as string)
                : document;

            setFileData(data);
        })();
    }, [document]);

    return (
        <>
            {fileData && (
                <PdfViewerComponent
                    toolbarSettings={{
                        toolbarItems: [
                            'PageNavigationTool',
                            'PanTool',
                            'SelectionTool',
                            'SearchOption',
                            'PrintOption',
                            'AnnotationEditTool',
                            'DownloadOption'
                        ],
                        annotationToolbarItems: ['HandWrittenSignatureTool']
                    }}
                    enableHandwrittenSignature={enableSignature}
                    enableDownload={enableDownload}
                    downloadFileName={fileName}
                    ref={scope => {
                        pdfViewerRef = scope;
                    }}
                    id={id}
                    documentPath={fileData}
                    serviceUrl={SyncfusionService.SYNCFUSION_SERVICE_URL}
                    style={{ height: '580px' }}>
                    <Inject services={services} />
                </PdfViewerComponent>
            )}
            {actions}
            {children}
        </>
    );
};
