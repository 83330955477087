import React from 'react';
import { Checkbox, FormControlLabel, CheckboxProps } from '@mui/material';

interface UICheckboxProps {
    label: string;
    disabled?: boolean;
    checked: boolean;
    onChange: () => void;
    color?: any;
}

export const UICheckbox = ({ 
    label, 
    disabled = false, 
    checked, 
    onChange, 
    color = 'primary', 
    ...rest
}: UICheckboxProps & CheckboxProps) => {
    return (
        <FormControlLabel 
            label={label}  
            control={
                <Checkbox
                    {...rest}
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{ color: color }}
                />} />
    );
};
