export const ROUTES = {
    AUTH: '/',
    AUTH_MAZARS: '/auth/mazars',
    AUTH_CLIENT: '/auth/client',
    HOME: '/home',
    ENGAGEMENT: '/engagement/:engagementId',
    ENGAGEMENT_SETTINGS: '/engagement/:engagementId/settings',
    ENGAGEMENT_CREATE: '/home/engagement/create',
    ENGAGEMENT_CONFIRMATION_CREATE: '/engagement/:engagementId/confirmation/create',
    DOCUMENT: '/document',
    DOCUMENT_VIEWER: '/document/:itemId',
    CONFIRMATION: '/engagement/:engagementId/confirmation/:confirmationId',
    CONFIRMATION_DOCUMENT_VIEWER: '/confirmation/:confirmationId/document/:itemId',
    CONFIRMATION_CREATE_MANUAL: '/engagement/:engagementId/confirmation/create/manual',
    CONFIRMATION_CREATE_IMPORT: '/engagement/:engagementId/confirmation/create/import',
    COUNTRY_POSTIE: '/support/country',
    COUNTRY_ADMIN: '/admin/country',
    COUNTRY_ADMIN_SETTINGS: '/admin/country/settings',
    COUNTRY_ADMIN_METRICS: '/admin/country/metrics'
};
