import { Tooltip } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';

/** Change style of tooltip component */
export const StyledTooltip = withStyles({
    tooltip: {
        fontSize: '1em',
        color: '#ffffff',
        backgroundColor: '#787878',
        zIndex: 1
    }
})(Tooltip);
