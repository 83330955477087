import React from 'react';
import { UITooltip } from '../common/UITooltip';

export const MZRTableActionIcon = ({ hidden, tooltip, disabled, icon: Icon, data, onClick }) => {
    return !hidden ? (
        <UITooltip
            title={tooltip || ''}
            children={
                <button
                    disabled={disabled}
                    className={`btn btn-icon btn-light btn-hover-primary btn-sm mr-3 ${disabled ? 'disabled' : ''}`}
                    onClick={event => {
                        onClick && onClick(event, data);
                        event.stopPropagation();
                    }}>
                    <span className="svg-icon svg-icon-md svg-icon-primary">{Icon}</span>
                </button>
            }
        />
    ) : null;
};
