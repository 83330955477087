import React, { Component } from 'react';
import './confirmation.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { COMPANY_TYPE, INIT_ITEMS_FILTER, SessionStatus, SORT_CHOICES } from '../../kit/utils/constants';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { ConfirmationComponent } from '../../components/confirmation/edit/Confirmation';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import {
    fetchConfirmationDocuments,
    fetchData,
    fetchThirdParties,
    fetchEngagement,
    fetchEngagementConfirmations,
    CompanyService,
    ConfirmationService,
    ContactService,
    ItemService
} from '../../services';
import { createToast, getToastType } from '../../components/toast/Toast';
import { DocumentPendingComponent, ITEM_DOCUMENT_TYPE } from '../../components/document';
import { saveDownloadedFile, UPLOAD_TYPES } from '../../helpers/ItemHelper';
import { confirmationStepRepository } from '../../kit/repository';
import { FormattedErrorMessage } from '../../components/errorMessage/ErrorMessage';
import { NewlineText } from '../../components/common/NewlineText';
import { CONTACT_TYPE, PROFILE } from '../../types/types';
import { ThirdPartyCompanyContext, ContactContext } from '../../context';
import { Alert } from '@mui/material';
import { ROUTES } from '../../navigation/Routes';
import * as engagementService from '../../services/EngagementService';
import { ItemContext } from '../../context';
import {
    CONFIRMATION_COLUMNS_FILTERS,
    INIT_MENU_CONFIRMATION_FILTERS
} from '../../components/confirmation/common';
import { UILoader } from '../../components/common/UILoader';

/**
 * Confirmation page component for user with auditor role.
 *
 * This confirmation page give users a detailed view on a specific confirmation.
 *
 * - Progress bar for the confirmation selected.
 * - Detailed information about the confirmation, client, third-party, contacts and list of documents. User can change your client’s contact, change your third-party and third-party’s contacts.
 * - Comment section for communication with other auditors member of the engagement. User can post and read comment within the confirmation.
 * - Confirmation validation and move to the next step.
 */
class Confirmation extends Component {
    constructor(props) {
        super(props);

        const makePageIndex = () => {
            const {
                reduxConfirmationsFilters: { pageIndex, pageSize },
                reduxSelectedConfirmationIndex
            } = this.props;

            return pageIndex * pageSize + reduxSelectedConfirmationIndex;
        };

        // TODO: we should use the current user profile in the filters
        this.state = {
            loading: false,
            checkSessionResults: [],
            hasNextConfirmation: false,
            hasPreviousConfirmation: false,
            menuEngagements: [],
            menuConfirmationsFilters: {
                // for pending documents accordion
                ...INIT_MENU_CONFIRMATION_FILTERS,
                [CONFIRMATION_COLUMNS_FILTERS.CLUSTER_ID]: props.currentClusterId,
                [CONFIRMATION_COLUMNS_FILTERS.ENGAGEMENT_ID]: this.props.match.params.engagementId
            },
            confirmationsFilters: {
                ...props.reduxConfirmationsFilters,
                pageSize: 1,
                pageIndex: makePageIndex()
            },
            itemsFilters: {
                userprofile: PROFILE.AUDITOR,
                pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
                pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
                documenttype: ITEM_DOCUMENT_TYPE.UNKNOWN,
                sort: SORT_CHOICES.UPDATED_DESC,
                confirmationId: this.props?.match?.params?.confirmationId,
                isLost: INIT_ITEMS_FILTER.IS_LOST,
                isTrashed: INIT_ITEMS_FILTER.IS_TRASHED
            },
            confirmationItemsFilters: {
                userprofile: PROFILE.AUDITOR,
                pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
                pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
                sort: SORT_CHOICES.CREATED_DESC,
                displaymode: INIT_ITEMS_FILTER.DISPLAY_MODE_ROOT_ITEM_ONLY,
                confirmationId: this.props?.match?.params?.confirmationId,
                isLost: INIT_ITEMS_FILTER.IS_LOST,
                isTrashed: INIT_ITEMS_FILTER.IS_TRASHED
            },
            docTypesFilters: {
                userprofile: PROFILE.AUDITOR
            },
            errorMessage: ''
        };
    }

    confirmationService = ConfirmationService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading
    });

    /***
     * A third party manager implementing the needed functions (create, update, search) to manage the third Party company
     * */
    thirdPartyCompanyManager = CompanyService({
        engagementId: this.props.match.params.engagementId,
        intl: this.props.intl,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        target: [COMPANY_TYPE.THIRDPARTY],
        callback: async isSuccess => isSuccess && (await fetchThirdParties(this, this.props.match.params.engagementId))
    });

    contactManager = ContactService({
        intl: this.props.intl,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        callback: async (isSuccess, _, contactType) => {
            if (isSuccess) {
                contactType === CONTACT_TYPE.THIRD_PARTY
                    ? await fetchThirdParties(this, this.state.confirmation?.engagementId) // needed to have the updated thirdparty contacts
                    : await fetchEngagement(this); // needed to have the contacts and client companies of the engagement where belongs the confirmation
            }
        }
    });

    itemManager = ItemService({
        intl: this.props.intl,
        user: this.props.user,
        definedUserProfile: PROFILE.AUDITOR,
        authorisedUploadItemType: UPLOAD_TYPES.AUDITOR,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        callback: async (isSuccess, _data, _) =>
            isSuccess &&
            (await fetchData(this, [
                () => this.fetchItems(this.state.itemsFilters),
                () => fetchConfirmationDocuments(this, this.state.confirmationItemsFilters)
            ])),
        setLoading: this.setLoading,
        setErrorMessage: this.setErrorMessage
    });

    setLoading = isLoading => {
        this.setState({ loading: isLoading });
    };

    setErrorMessage = errorMessage => {
        this.setState({ errorMessage: errorMessage });
    };

    fetchItems = async filters => {
        const result = await this.itemManager.fetch(filters);
        if (result?.isSuccess) {
            this.setState({ paginatedItems: result.parsedValue });
        }
    };

    downloadItem = async (item, fileName) => {
        await this.itemManager.download(
            item,
            async (isSuccess, data, _) => isSuccess && saveDownloadedFile(data, fileName ?? item.name)
        );
    };

    uploadItems = async (files, isGrouped, initItem) => {
        return this.itemManager.upload(files, isGrouped, initItem);
    };

    updateItem = async item => {
        return this.itemManager.update(item);
    };

    downloadConfirmationFiles = async confirmationId => {
        const { intl } = this.props;

        await this.confirmationService.downloadFiles(confirmationId, (isSuccess, data) => {
            isSuccess && saveDownloadedFile(data, intl.formatMessage({ id: 'CONFIRMATION.ALL.DOCUMENTS.FILE.NAME' }));
        });
    };

    fetchDocumentTypes = async filters => {
        const result = await this.itemManager.getDocumentTypes(filters);
        if (result?.isSuccess) {
            this.setState({ documentTypes: result?.body });
        }
    };

    fetchItemErrors = async () => {
        const result = await this.itemManager.getItemErrors();
        if (result?.isSuccess) {
            this.setState({ itemErrors: result?.body });
        }
    };

    handleConfirmationItemsFilterChange = values => {
        this.setState({ confirmationItemsFilters: { ...this.state.confirmationItemsFilters, ...values } });
    };

    async componentDidMount() {
        const { docTypesFilters, itemsFilters, confirmationItemsFilters } = this.state;

        await fetchData(this, [
            () => this.performFetchConfirmation(),
            () => fetchEngagement(this),
            () => this.fetchItems(itemsFilters),
            () => fetchConfirmationDocuments(this, confirmationItemsFilters),
            () => this.fetchDocumentTypes(docTypesFilters),
            () => this.fetchItemErrors()
        ]);
    }

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.state.itemsFilters !== prevState.itemsFilters) {
            if (!this.state.loading) await this.fetchItems(this.state.itemsFilters);
        }

        if (this.state.confirmationItemsFilters !== prevState.confirmationItemsFilters) {
            if (!this.state.loading) await fetchConfirmationDocuments(this, this.state.confirmationItemsFilters);
        }

        if (this.state.confirmationsFilters !== prevState.confirmationsFilters && !this.state.loading) {
            await this.performFetchConfirmation();
            await this.fetchItems(this.state.itemsFilters);
            await fetchConfirmationDocuments(this, this.state.confirmationItemsFilters);
        }
    }

    retrieveConfirmationInfo = async confirmationId => {
        await this.confirmationService.fetch(confirmationId, (isSuccess, result) => {
            if (isSuccess) {
                this.setState({
                    confirmation: result.parsedValue,
                    clientContacts: result.parsedValue.clientContacts,
                    thirdPartyContacts: result.parsedValue.thirdPartyContacts
                });
            }
        });
    };

    /**
     * Update a confirmation.
     * @public
     */
    updateConfirmation = async (confirmation, isSuccessMessageDisplayed = true) => {
        let result;

        await this.confirmationService.update(confirmation, isSuccessMessageDisplayed, async (isSuccess, data) => {
            result = data;
            if (isSuccess) {
                await fetchData(this, [
                    () => this.performFetchConfirmation(),
                    () => fetchConfirmationDocuments(this, this.state.confirmationItemsFilters),
                    () => this.fetchItems(this.state.itemsFilters)
                ]);
            }
        });

        return result;
    };

    deleteConfirmation = async confirmationInfo => {
        let result;
        await this.confirmationService.trash(confirmationInfo, true, (_, data) => {
            result = data;
        });

        return result;
    };

    /**
     * Move a confirmation to another step (movement).
     * @param {Id} stepId
     * @param status
     * @public
     */
    processStep = async (stepId, status) => {
        const { intl, checkSession, addToastMessage } = this.props;
        this.setState({ loading: true });

        const result = await confirmationStepRepository.update(stepId, status);
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid && result.isSuccess) {
            await fetchData(this, [
                () => this.performFetchConfirmation(),
                () => fetchConfirmationDocuments(this, this.state.confirmationItemsFilters),
                () => this.fetchItems(this.state.itemsFilters)
            ]);
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({ id: 'CONFIRMATION.MOVE.SUCCESS' }),
                    result.error
                )
            );
        } else {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({ id: 'CONFIRMATION.MOVE.ERROR' }),
                    result.error
                )
            );
        }
        this.setState({ loading: false });
    };

    handleItemsFilterChange = async values => {
        this.setState({ itemsFilters: { ...this.state.itemsFilters, ...values } });
    };

    handleSelectStackableStep = async step => {
        const { intl, checkSession, addToastMessage } = this.props;
        this.setState({ loading: true });

        const result = await confirmationStepRepository.update(step.id, { isActive: true, status: 0 });
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid && result.isSuccess) {
            await fetchData(this, [() => this.performFetchConfirmation()]);
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({ id: 'CONFIRMATION.STACKED_STEP.SUCCESS' }),
                    result.error
                )
            );
        } else {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({ id: 'CONFIRMATION.STACKED_STEP.ERROR' }),
                    result.error
                )
            );
        }
        this.setState({ loading: false });
    };

    performFetchConfirmation = async () => {
        const { intl, checkSession, addToastMessage } = this.props;
        const { confirmationsFilters } = this.state;

        await fetchEngagementConfirmations({
            filters: confirmationsFilters,
            engagementId: this.props.match.params.engagementId,
            checkSession,
            addToastMessage,
            errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR' }),
            setLoading: this.setLoading,
            callback: (isSuccess, data) => {
                if (isSuccess) {
                    const confirmation = data.parsedValue?.items[0];
                    if (confirmation) {
                        this.setState({
                            hasNextConfirmation: data.parsedValue.hasNextPage,
                            hasPreviousConfirmation: data.parsedValue.hasPreviousPage
                        });
                        this.retrieveConfirmationInfo(confirmation.id);
                        if (this.props.match.params.confirmationId !== confirmation.id)
                            this.props.history.replace(
                                ROUTES.CONFIRMATION.replace(
                                    ':engagementId',
                                    this.props.match.params.engagementId
                                ).replace(':confirmationId', confirmation.id)
                            );
                    } else {
                        // TODO: show error
                    }
                } else {
                    const checkSessionResults = [
                        ...this.state.checkSessionResults,
                        {
                            sessionStatus: data.status,
                            technicalMessage: data?.error,
                            errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR' })
                        }
                    ];

                    this.setState({ checkSessionResults: checkSessionResults });
                }
            }
        });
    };

    handleConfirmationFilterChange = values => {
        this.setState({ confirmationsFilters: { ...this.state.confirmationsFilters, ...values } });
    };

    handleFetchMenuEngagements = async () => {
        await engagementService.fetchMenuEngagements(this);
    };

    fetchMenuConfirmations = async values => {
        const { currentClusterId } = this.props;
        const filters = { ...this.state.menuConfirmationsFilters, clusterId: currentClusterId, ...values };

        let menuConfirmations = [];
        await this.confirmationService.fetchMenu(filters, (isSuccess, result) => {
            if (isSuccess) {
                menuConfirmations = result.parsedValue;
            }
        });

        this.setState({ menuConfirmationsFilters: filters });
        return menuConfirmations;
    };

    render() {
        const { intl, user } = this.props;

        if (!user.profil || this.state.loading) {
            return <UILoader />;
        } else {
            if (isAuthorisedProfile(PROFILE.AUDITOR, user.profil)) {
                return (
                    <>
                        <DocumentPendingComponent
                            engagementId={this.props.match.params.engagementId}
                            confirmationId={this.state.confirmation?.id}
                            shouldPopulateMenuConfirmations={!!this.props.match.params.engagementId}
                            fetchMenuEngagement={this.handleFetchMenuEngagements}
                            handleFetchMenuConfirmations={this.fetchMenuConfirmations}
                            user={this.props.user}
                            actionProfile={PROFILE.AUDITOR}
                            loading={this.state.loading}
                            paginatedItems={this.state.paginatedItems}
                            menuEngagements={this.state.menuEngagements}
                            documentTypes={this.state.documentTypes}
                            fetchItemFile={this.itemManager.fetchFile}
                            fetchItemInfo={this.itemManager.fetchInfo}
                            updateItem={this.updateItem}
                            downloadItem={this.downloadItem}
                            handleFilterItems={this.handleItemsFilterChange}
                        />

                        <div className="row">
                            <div className="col-12">
                                {this.state.errorMessage && (
                                    <Alert severity="error" onClose={() => this.setErrorMessage('')}>
                                        {FormattedErrorMessage(
                                            <NewlineText
                                                text={intl.formatMessage({
                                                    id: this.state.errorMessage
                                                })}></NewlineText>
                                        )}
                                    </Alert>
                                )}

                                <ItemContext.Provider
                                    value={{
                                        fetchItemFile: this.itemManager.fetchFile,
                                        fetchItemInfo: this.itemManager.fetchInfo,
                                        uploadItems: this.uploadItems,
                                        updateItem: this.updateItem,
                                        deleteItem: this.itemManager.delete,
                                        downloadItem: this.downloadItem,
                                        filterItems: this.handleConfirmationItemsFilterChange
                                    }}>
                                    <ThirdPartyCompanyContext.Provider
                                        value={{
                                            createCompany: this.thirdPartyCompanyManager.createThirdParty,
                                            updateCompany: this.thirdPartyCompanyManager.updateThirdParty,
                                            searchCompany: this.thirdPartyCompanyManager.searchThirdParties
                                        }}>
                                        <ContactContext.Provider
                                            value={{
                                                [CONTACT_TYPE.CLIENT]: {
                                                    createContact: this.contactManager.createClientContact,
                                                    updateContact: this.contactManager.updateClientContact
                                                },
                                                [CONTACT_TYPE.THIRD_PARTY]: {
                                                    createContact: this.contactManager.createThirdPartyContact,
                                                    updateContact: this.contactManager.updateThirdPartyContact
                                                }
                                            }}>
                                            <ConfirmationComponent
                                                navigationProps={{
                                                    handleFilterChange: this.handleConfirmationFilterChange,
                                                    filters: { ...this.state.confirmationsFilters },
                                                    hasNextPage: this.state.hasNextConfirmation,
                                                    hasPreviousPage: this.state.hasPreviousConfirmation
                                                }}
                                                {...this.props}
                                                intl={this.props.intl}
                                                user={this.props.user}
                                                languages={this.props.languages}
                                                loading={this.state.loading}
                                                confirmation={this.state.confirmation}
                                                engagement={this.state.engagement}
                                                documentTypes={this.state.documentTypes}
                                                itemErrors={this.state.itemErrors}
                                                paginatedConfirmationItems={this.state.paginatedConfirmationItems}
                                                createConfirmation={this.createConfirmation}
                                                deleteConfirmation={this.deleteConfirmation}
                                                updateConfirmation={this.updateConfirmation}
                                                processStep={this.processStep}
                                                selectStackableStep={this.handleSelectStackableStep}
                                                downloadConfirmationFiles={this.downloadConfirmationFiles}
                                                handleFetchMenuConfirmations={this.fetchMenuConfirmations}
                                            />
                                        </ContactContext.Provider>
                                    </ThirdPartyCompanyContext.Provider>
                                </ItemContext.Provider>
                            </div>
                        </div>
                    </>
                );
            } else {
                return <PageNotFound {...this.props} />;
            }
        }
    }
}

Confirmation.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    languages: PropTypes.array.isRequired,
    checkSessionResults: PropTypes.array
};

Confirmation.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        languages: state.cluster.languages,
        currentClusterId: state.cluster.currentClusterId,
        reduxConfirmationsFilters: state.engagement.confirmationsFilters,
        reduxSelectedConfirmationIndex: state.engagement.selectedConfirmationIndex ?? 0
    };
};

export const confirmation = compose(
    injectIntl,
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(Confirmation);
