import * as actions from '../actions';

const initialState = {
    defaultPath: '',
    sessionProfile: '',
    toastMessageList: [],
    selectedProfileRoute: ''
};

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.Types.SET_DEFAULT_PATH:
            return { ...state, defaultPath: action.defaultPath };

        case actions.Types.SET_SELECTED_PROFILE_ROUTE:
            return { ...state, selectedProfileRoute: action.route };

        case actions.Types.SET_SESSION_PROFILE:
            return { ...state, sessionProfile: action.sessionProfile };

        case actions.Types.ADD_TOAST_MESSAGE:
            return { ...state, toastMessageList: [...state.toastMessageList, action.toastMessage] };

        case actions.Types.SET_TOAST_MESSAGE:
            return { ...state, toastMessageList: action.toastList };

        case actions.Types.RESET_APP:
            return { ...state, ...initialState };

        default:
            return state;
    }
};
