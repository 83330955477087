import React from 'react';
import { SvgIcon } from '@material-ui/core';

export function MazarsPdfIcon(props) {
    return (
        <SvgIcon
            {...props}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="48px"
            height="63px"
            viewBox="0 0 48 63"
            enableBackground="new 0 0 48 63">
            <image
                id="image0"
                width="48"
                height="63"
                x="0"
                y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAABHCAMAAAB75USLAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABUFBMVEUAAAA5hcUygMIwfcE0
gMJfptsgcL9fptsqeb0jcrlbo9n////9/v7l7vewzeZUkskrd7vh7PX8/f7c6fS20OhtotFIisX0
+Pvg6/Xm7/fy9/tfmMz6/P3u9Prz9/uryeQwer3A1+tOjsdNjcZXk8nY5vPH2+3K3e5jm81km857
qtXR4vCRuNzB1+t4qNSuy+Xv9Poqdru1z+dJisXj7fZEh8OVu90mdLqvzOXw9vq30eiXvd75+/1s
odCZvt+tyuW91ep4qdSHstnF2u19rNZ/rdaBrtcndbrM3u9vo9GEsNiDr9dxpNKXvN7D2ewueb2j
xOL+/v/x9vulxeIseLzP4PCbv99blssodbuhw+H4+/1mnc40fb41fr82fr8ver0kc7nq8vje6vU7
gcHN3+9roNCIs9l6qtWavt+Tut0pdrv1+PzQ4fCnx+NYlMolc7pusTSuAAAAB3RSTlMAr8C5r+8Q
IjRFsgAAAAFiS0dECx/XxMAAAAAJcEhZcwAAFxEAABcRAcom8z8AAAAHdElNRQflBBgVEwcPFkyL
AAABX0lEQVRIx+3UZ0/CQBjA8YrzeKCUKlIciAMVXOAeVXELLtx77/3933lXaaU1rXeNMTG5/4vL
pckv6T0dgoCrpKhE+BYNq/K4Y8jjjqFSd8zqaJnFUTOzo2cmx8BQmTtW5JjYl2NjhmNkumNlBUfD
TJVzxhlnnHHG2R+xCpyXPp0htry/wwDn84sBhKQg3srVNSEJX66FQrYsrCgRqKsnrKEximVTjIY1
t7S2xaGdsI7ORLILoFtjPb0kW9aHVxFS6XQQ+vF2YBAiQ4QNO59NRGhkFMZimI2TY02AOknYlDOb
xmsG4uQmNTYDMEvY3PzCYmLJli1ns7korBhsFWBNH8n6hi2TVRV8eclgSdjMEbalKP7tHVuW2s3s
7R8ggx3C0TFh+ZPTs/MLp5FoSZ+TDFzC1TXFSIrYzW3oTgG4157bAzWTwzJ+ax6fGBnA88vr2zv6
mbn8AhjZv/gFcWZlH2zjg+z2vILSAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA0LTI0VDIxOjE5
OjA2KzAwOjAwkGIPiAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNC0yNFQyMToxOTowNiswMDow
MOE/tzQAAAAASUVORK5CYII="
            />
        </SvgIcon>
    );
}

export function DocxIcon(props) {
    return (
        <SvgIcon
            {...props}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="48px"
            height="48px"
            viewBox="0 0 48 48"
            enableBackground="new 0 0 48 48">
            <path fill="#2196F3" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z" />
            <path
                fill="#FFF"
                d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
            />
            <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
            <path
                fill="#FFF"
                d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
            />
        </SvgIcon>
    );
}

export function ExcelIcon(props) {
    return (
        <SvgIcon
            {...props}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="48px"
            height="48px"
            viewBox="0 0 48 48"
            enableBackground="new 0 0 48 63">
            <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"/>
            <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"/>
            <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"/>
            <path fill="#17472a" d="M14 24.005H29V33.055H14z"/>
            <g>
                <path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"/>
                <path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"/>
                <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"/>
                <path fill="#129652" d="M29 24.005H44V33.055H29z"/>
            </g>
            <path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"/>
            <path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"/>
        </SvgIcon>
    )
}

export function DeleteIcon(props) {
    return (
        <SvgIcon {...props} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
        </SvgIcon>
    );
}

export const SignatureIcon = require('../assets/signature.png').default;

export function UnknownFileIcon(props) {
    return (
        <SvgIcon
            {...props}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="48px"
            height="48px"
            viewBox="0 0 48 48"
            enableBackground="new 0 0 48 63">
            <imgage 
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVR4nO3Xy0sCQRwH8Pk3KpLAW4fOQacsPBZ1qkt0i45CKEaS9JAeZgsVQUkdxOhSN3scskgq9RSWYVTUYiFRoKFra1C/2I0mEQQX9zU0P/heFgZ+H+c3zIgQLVq0JBUTLYDMmUJqlpzNr5x/qI+QE5DIfMKq2gg5ASz3pTzC5OegOHIDWKURagBYJRFqAVilEGoCWCUQagPYEoSuAUwFoQDTf98Bd5gHZzAHjoMszJy+kwNwhTgwL7FQb7+EGmtMTJ3tAlo8dzAazOob4A7z0DR5jRsvjdERh4njvH4B/ZvPuNnWhXtwhfJiOrxJ/L17/Um/gPbFB9yoMEq/3+fOeDxSzbM3+gWUy3ykAAYMuCUPMLD1gnema+2RLIAlkMa/vtFxpe9DzJTEupsBw/BP88IZGNpJV7wWaQ3wRHhoHEuIzdfaYjC4/SppPdIaYAlk8Nx3epOS1yOtAX0bf3eC8KQgDmDffxMvNiHCOBEHYKoM0hogPNzMyyz0+FLiRUYcoK3oWWHby5AH6PWlxOYbRuIwfsSRB2CiBXAe5mD6RPqfGUYvAKIPMUMBVRbdgSg9A/KOkNJBchcF+OkO0KJFC5Wvb5j+O4RSc2mzAAAAAElFTkSuQmCC"
            />
        </SvgIcon>
    )
}

export function PictureFileIcon(props) {
    return (
        <SvgIcon
            {...props}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="48px"
            height="48px"
            viewBox="0 0 48 48"
            enableBackground="new 0 0 48 63">
            <path d="M 7 2 L 7 48 L 43 48 L 43 15.414063 L 29.410156 2 Z M 9 4 L 28 4 L 28 17 L 41 17 L 41 46 L 9 46 Z M 30 5.390625 L 39.734375 15 L 30 15 Z M 33.5 21.332031 C 32.457031 21.335938 31.609375 22.15625 31.609375 23.167969 C 31.613281 24.179688 32.457031 25 33.5 25 C 34.542969 25 35.386719 24.179688 35.390625 23.167969 C 35.390625 22.15625 34.542969 21.335938 33.5 21.332031 Z M 21.167969 24 C 20.835938 23.988281 20.519531 24.136719 20.324219 24.40625 L 12.863281 34.488281 C 12.640625 34.792969 12.605469 35.195313 12.773438 35.53125 C 12.945313 35.871094 13.289063 36.082031 13.667969 36.082031 L 28.425781 36.082031 C 28.449219 36.082031 28.472656 36.082031 28.496094 36.082031 C 28.5 36.082031 28.5 36.082031 28.503906 36.082031 L 35.015625 36 C 35.398438 35.996094 35.742188 35.769531 35.90625 35.421875 C 36.066406 35.074219 36.015625 34.667969 35.777344 34.371094 L 29.269531 26.386719 C 29.078125 26.148438 28.785156 26.011719 28.480469 26.015625 C 28.164063 26.023438 27.871094 26.175781 27.6875 26.429688 L 25.554688 29.363281 L 21.9375 24.410156 C 21.753906 24.164063 21.472656 24.011719 21.167969 24 Z M 21.125 26.6875 L 24.753906 31.660156 C 24.757813 31.667969 24.761719 31.671875 24.765625 31.675781 L 26.523438 34.082031 L 15.652344 34.082031 Z M 28.539063 28.65625 L 32.917969 34.027344 L 29 34.078125 L 26.796875 31.0625 Z"/>
        </SvgIcon>
    )
}
