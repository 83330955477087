import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Tabs, Tab } from '@mui/material';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../partials/controls/Card';

import './settings.scss';
import { initEngagement, ENGAGEMENT_STEPS, ENGAGEMENT_ACTIONS } from '../InitEngagement';
import { useEngagement } from '../../../hooks/useEngagement';
import { hasEditionRights } from '../../../services';
import { SettingsToolbarItems } from './SettingsToolbarItems';
import { useArchiveEngagement, useDeleteEngagement } from '../../../hooks/useEntityActionPopup';
import { ROUTES } from '../../../navigation/Routes';
import { hasOnlyProfile } from '../../auth/UserHelper';
import { CONTACT_TYPE, PROFILE } from '../../../types/types';
import { ContactContext } from '../../../context';
import { isArchiveToggleAuthorized } from '../../../helpers/EngagementHelper';

const a11yProps = index => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
};

/**
 * Settings component for engagement.
 * Update engagement information, engagement's clients and engagement's auditor memebers
 * @param {*} props
 * @returns
 */
export function Settings(props) {
    const {
        user,
        loading,
        engagement,
        engagementCodeRegEx,
        engagementCodeMessage,
        updateEngagement,
        archiveEngagement,
        deleteEngagement,
        activeStepPosition,
        saveActiveStepPosition,
        inviteContact,
        resetPasswordContact
    } = props;

    const intl = useIntl();
    const history = useHistory();
    const classes = styles;
    const sbmtEngagementRef = useRef();

    const [tab, setTab] = useState(ENGAGEMENT_STEPS.INFORMATION);
    const [engagementSettings, setEngagementSettings] = useState(initEngagement);
    const [engagementManagement, engagementStepRender, renderErrorMessage] = useEngagement({ intl: intl, user: user });
    const [delegateUpdateEngagement, setDelegateUpdateEngagement] = useState(false);
    const disabled = engagementSettings ? !engagementSettings.isEditable || engagementSettings.archived : false;
    const isLimitedAccess = hasOnlyProfile(PROFILE.CLIENT, user.profil);
    const contactContext = useContext(ContactContext)[CONTACT_TYPE.CLIENT];
    const isArchiveToggleEnabled = isArchiveToggleAuthorized(
        engagementSettings,
        user,
        engagementSettings?.archived ? 'Unarchive' : 'Archive'
    );

    const tabs = [
        ENGAGEMENT_STEPS.INFORMATION,
        ENGAGEMENT_STEPS.CLIENTS,
        ENGAGEMENT_STEPS.CONTACTS,
        ENGAGEMENT_STEPS.LETTERHEADS,
        ENGAGEMENT_STEPS.MEMBERS,
        ENGAGEMENT_STEPS.THIRD_PARTIES
    ];
    const { delete: DeleteEngagementComponent, handleDeleteClick: handleDeleteEngagementClick } = useDeleteEngagement({
        user: props.user,
        deleteAPI: deleteEngagement,
        deleteEngagementCallback: isSuccess => {
            if (isSuccess) {
                history.replace(ROUTES.HOME);
            }
        }
    });

    useEffect(() => {
        setEngagementSettings(engagement);
    }, [engagement]);

    useEffect(() => {
        const selectedTab = tabs[activeStepPosition];
        selectedTab !== tab && setTab(selectedTab);
    }, [activeStepPosition]);

    const { archive: ArchiveEngagementComponent, handleArchiveClick } = useArchiveEngagement({
        user: props.user,
        archiveAPI: archiveEngagement,
        archiveEngagementCallback: isSuccess => isSuccess && history.replace(ROUTES.HOME)
    });

    const handleChangeTab = (_, nextTab) => {
        setTab(nextTab);

        const activeStepPosition = tabs.findIndex(currentTab => currentTab === nextTab);
        saveActiveStepPosition(activeStepPosition);

        // everytime we leave information tab, trigger update engagementSetting object with the data from information form
        if (tab === ENGAGEMENT_STEPS.INFORMATION && nextTab !== ENGAGEMENT_STEPS.INFORMATION) {
            triggerInformationUpdate();
        }
    };

    const handleSaveSettings = async () => {
        if (tab !== ENGAGEMENT_STEPS.INFORMATION) {
            /// save button is clicked from other tab than information, call API directly (information data is updated when the tab changes)
            setDelegateUpdateEngagement(false);
            await updateEngagement(engagementSettings);
        } else {
            // save button is clicked and information tab is selected, first update engagementSettings with information data then call API
            setDelegateUpdateEngagement(true);
            triggerInformationUpdate();
        }
    };

    const triggerInformationUpdate = _ => {
        if (sbmtEngagementRef && sbmtEngagementRef.current) {
            sbmtEngagementRef.current.saveForm();
        }
    };

    /** Add information to the current engagement. */
    const handleEngagementInfoSubmit = async values => {
        // trick: need to use intermediate variable because useState is asynchronous
        const engagementToUpdate = { ...engagementSettings, ...values };
        setEngagementSettings(engagementToUpdate);
        delegateUpdateEngagement && (await updateEngagement(engagementToUpdate));
        setDelegateUpdateEngagement(false);
    };

    const resetEngagement = () => {
        setEngagementSettings(engagement);
    };

    const handleClientAdd = values => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.ADD_CLIENT,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleClientUpdate = values => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.UPDATE_CLIENT,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleClientDelete = values => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.DELETE_CLIENT,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleContactAdd = values => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.ADD_CLIENT_CONTACT,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleContactUpdate = values => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.UPDATE_CLIENT_CONTACT,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleContactDelete = values => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.DELETE_CLIENT_CONTACT,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleContactInvite = async values => {
        await inviteContact(values);
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.INVITE_CLIENT_CONTACT,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleContactResetPassword = async values => {
        await resetPasswordContact(values);
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.RESET_PASSWORD_CONTACT,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleContactRevokeAccess = async values => {
        await contactContext.revokeAccess(values.email, user.email);
    };

    const handleContactDeleteEngagementAccess = async values => {
        await contactContext.deleteAccess(values.email, user.email, engagement?.id);
    };

    const handleAddMember = values => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.ADD_MEMBER,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleDeleteMember = values => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.REMOVE_MEMBER,
            values: values
        });
        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const handleChangeRole = event => {
        const updatedEngagement = engagementManagement(engagementSettings, {
            type: ENGAGEMENT_ACTIONS.CHANGE_MEMBER_ROLE,
            values: {
                email: event.target.value,
                isRoleManager: event.target.checked,
                hasEditionRights: hasEditionRights(engagement, user)
            }
        });

        updatedEngagement && setEngagementSettings(updatedEngagement);
    };

    const onArchiveClick = async () => {
        !!engagementSettings && handleArchiveClick();
    };

    const backToPreviousPage = () => history.goBack();

    return (
        <>
            <Card>
                <CardHeader
                    title={
                        <FormattedMessage
                            id="SETTINGS.CARD.TITLE"
                            values={{
                                engagementName:
                                    engagementSettings?.name ?? intl.formatMessage({ id: 'COMMON.LOADING.IN.PROGRESS' })
                            }}
                        />
                    }>
                    <CardHeaderToolbar>
                        <SettingsToolbarItems
                            archiveProps={{
                                title: engagementSettings?.archived
                                    ? 'SETTINGS.ACTION.UNARCHIVE'
                                    : 'SETTINGS.ACTION.ARCHIVE',
                                hidden: isLimitedAccess || !isArchiveToggleEnabled,
                                disabled: !isArchiveToggleEnabled,
                                handleToggleArchive: onArchiveClick
                            }}
                            disabled={disabled}
                            handleBack={backToPreviousPage}
                            handleDelete={handleDeleteEngagementClick}
                            isDeleteHidden={!engagementSettings?.isDeletable || isLimitedAccess}
                            handleReset={resetEngagement}
                            isResetHidden={isLimitedAccess}
                            handleSave={handleSaveSettings}
                            loading={loading}
                        />
                    </CardHeaderToolbar>
                </CardHeader>

                <CardBody>{renderErrorMessage()}</CardBody>

                <CardBody>
                    <Grid container spacing={6}>
                        <Grid container item>
                            <Tabs
                                value={tab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChangeTab}
                                aria-label="disabled tabs example">
                                <Tab
                                    label={<FormattedMessage id="SETTINGS.SECTION.LABEL.INFORMATION" />}
                                    {...a11yProps(ENGAGEMENT_STEPS.INFORMATION)}
                                />
                                <Tab
                                    label={<FormattedMessage id="SETTINGS.SECTION.LABEL.CLIENTS" />}
                                    {...a11yProps(ENGAGEMENT_STEPS.CLIENTS)}
                                />
                                <Tab
                                    label={<FormattedMessage id="SETTINGS.SECTION.LABEL.CONTACTS" />}
                                    {...a11yProps(ENGAGEMENT_STEPS.CONTACTS)}
                                />
                                <Tab
                                    label={<FormattedMessage id="SETTINGS.SECTION.LABEL.LETTERHEADS" />}
                                    {...a11yProps(ENGAGEMENT_STEPS.LETTERHEADS)}
                                />
                                <Tab
                                    label={<FormattedMessage id="SETTINGS.SECTION.LABEL.AUDITORS" />}
                                    {...a11yProps(ENGAGEMENT_STEPS.MEMBERS)}
                                />
                                <Tab
                                    label={<FormattedMessage id="SETTINGS.SECTION.LABEL.THIRD_PARTIES" />}
                                    {...a11yProps(ENGAGEMENT_STEPS.THIRD_PARTIES)}
                                />
                            </Tabs>
                        </Grid>
                        <Grid container item direction="column" justifyContent="center" alignItems="stretch">
                            {engagementStepRender({
                                currentStep: tab,
                                intl: intl,
                                loading: loading,
                                classes: classes,
                                disabled: disabled,
                                limitedAccess: isLimitedAccess,
                                restrictedEdition: !hasEditionRights(engagementSettings, user),
                                engagement: engagementSettings,
                                propsInfo: {
                                    engagementCodeRegEx,
                                    engagementCodeMessage,
                                    handleInfoSubmit: handleEngagementInfoSubmit,
                                    sbmtEngagementRef: sbmtEngagementRef
                                },
                                propsClient: {
                                    handleClientAdd: handleClientAdd,
                                    handleClientUpdate: handleClientUpdate,
                                    handleClientDelete: handleClientDelete
                                },
                                propsContact: {
                                    contactType: CONTACT_TYPE.CLIENT,
                                    handleContactAdd: handleContactAdd,
                                    handleContactUpdate: handleContactUpdate,
                                    handleContactDelete: handleContactDelete,
                                    handleContactInvite: handleContactInvite,
                                    handleContactRevokeAccess: handleContactRevokeAccess,
                                    handleContactDeleteAccess: handleContactDeleteEngagementAccess,
                                    handleContactResetPassword: handleContactResetPassword
                                },
                                propsMember: {
                                    handleAddMember: handleAddMember,
                                    handleDeleteMember: handleDeleteMember,
                                    handleChangeRole: handleChangeRole
                                }
                            })}
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>

            <DeleteEngagementComponent engagementId={engagementSettings?.id} />
            <ArchiveEngagementComponent engagement={engagementSettings} />
        </>
    );
}

Settings.propTypes = {
    /**
     * Information received from the API of connected user.
     */
    user: PropTypes.object.isRequired,
    /**
     * Allow edition for engagement information fields.
     */
    isEditable: PropTypes.bool,
    /**
     * New engagement.
     */
    engagement: PropTypes.object,
    /**
     * Update engagement with API call.
     */
    updateEngagement: PropTypes.func.isRequired,
    /**
     * Delete engagement with API call.
     */
    deleteEngagement: PropTypes.func.isRequired,
    /**
     * Archive an engagement with API call.
     */
    archiveEngagement: PropTypes.func.isRequired,
    /**
     * Active step.
     */
    activeStepPosition: PropTypes.number.isRequired,
    /**
     * Save active step to local store.
     */
    saveActiveStepPosition: PropTypes.func.isRequired,

    /**
     * Send an invitation for the given contact (API call).
     */
    inviteContact: PropTypes.func,

    /**
     * reset the password for the given contact (API call).
     */
    resetPasswordContact: PropTypes.func
};

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    margin: {
        margin: 1
    },
    textField: {
        width: '98%',
        paddingLeft: 25,
        paddingRight: 25
    },
    selectField: {
        width: '90%',
        paddingLeft: 25,
        paddingRight: 25
    },
    inputField: {
        width: '80%',
        paddingLeft: 25,
        paddingRight: 25
    },
    divider: {
        height: 1,
        margin: 1
    },
    memberContainer: { width: '80%', padding: '0 25px' },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: 0.5
    }
};
