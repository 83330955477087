import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';

function valuetext(value) {
    return `P${value}`;
}

function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

const marks = [
    {
        value: 0,
        label: 'High'
    },
    {
        value: 20,
        label: 'P1'
    },
    {
        value: 40,
        label: 'P2'
    },
    {
        value: 60,
        label: 'P3'
    },
    {
        value: 100,
        label: 'Low'
    }
];

export function StorageBaseConfigComponent(props) {
    const { storageConfiguration, handleUpdateConfig } = props;

    const [isActive, setActive] = useState(0);
    const [priorityLevel, setPriority] = useState(0);

    useEffect(() => {
        const adjustedPriority = 20 * storageConfiguration?.priorityLevel;
        setPriority(adjustedPriority);
        setActive(storageConfiguration?.isActive);
    }, [storageConfiguration]);

    const handlePriorityChange = (event, newValue) => {
        const adjustedPriority = newValue / 20;
        setPriority(newValue);
        handleUpdateConfig({ priorityLevel: adjustedPriority });
    };

    const handleIsActiveChange = () => {
        setActive(!isActive);
        handleUpdateConfig({ isActive: !isActive });
    };

    return (
        <>
            <Grid item>
                <Grid container>
                    <label>
                        <FormattedMessage id="STORAGE.BASE.CONFIG.ISACTIVE.LABEL" />
                    </label>
                </Grid>
                <Grid container>
                    <FormControlLabel
                        control={<Switch checked={isActive} onChange={handleIsActiveChange} />}
                        label={<FormattedMessage id="STORAGE.BASE.CONFIG.ISACTIVE.SUBLABEL" />}
                    />
                </Grid>
                <Grid container>
                    <label>
                        <FormattedMessage id="STORAGE.BASE.CONFIG.PRIORITY_LEVEL.LABEL" />
                    </label>
                </Grid>
                <Grid container>
                    <Slider
                        value={priorityLevel}
                        valueLabelFormat={valueLabelFormat}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-restrict"
                        step={null}
                        valueLabelDisplay="auto"
                        marks={marks}
                        onChange={handlePriorityChange}
                    />
                </Grid>
            </Grid>
        </>
    );
}

injectIntl(StorageBaseConfigComponent);
