import { DOCUMENT_ITEM_TYPE, PROFILE } from '../types/types';
import { ITEM_SOURCE } from '../components/document/InitItem';
import { getHash, checkMimeType, formatItemDescription, UPLOAD_TYPES } from '../helpers/ItemHelper';
import { itemRepository } from '../kit/repository';
import { performAPIAction } from './BaseService';

const ItemMethods = ({ user, definedUserProfile, authorisedUploadItemType, setErrorMessage, ...rest }) => {
    const { intl } = rest;
    const enhanceItem = async (initItem, file) => {
        const fileHash = await getHash(file);

        const updatedFile = {
            ...initItem,
            file: file,
            name: file.name,
            userProfile: definedUserProfile,
            description: formatItemDescription(intl, definedUserProfile, false),
            source: ITEM_SOURCE.SUPPORT,
            type: DOCUMENT_ITEM_TYPE.FILE,
            fileHash: fileHash
        };

        return updatedFile;
    };

    const formatItems = async (items, isGrouped, initItem) => {
        const isValidMimeType = checkMimeType(items, authorisedUploadItemType);

        if (!isValidMimeType) {
            setErrorMessage('ITEM.UPLOAD.TYPE.ERROR.MESSAGE');
            return [];
        } else {
            const listedItems = Array.from(items);

            const childItems = await listedItems?.reduce(async (acc, file) => {
                return [...(await acc), await enhanceItem(initItem, file)];
            }, Promise.resolve([]));

            return !isGrouped
                ? childItems
                : [
                      {
                          ...initItem,
                          userProfile: definedUserProfile,
                          name: intl.formatMessage({ id: 'ITEM.UPLOAD.DIRECTORY.NAME' }),
                          description: formatItemDescription(intl, definedUserProfile, true),
                          source: ITEM_SOURCE.SUPPORT,
                          type: DOCUMENT_ITEM_TYPE.FOLDER,
                          fileHash: null,
                          childItems: childItems
                      }
                  ];
        }
    };

    return {
        fetch: async filters => {
            let result = await performAPIAction({
                ...rest,
                apiCall: itemRepository.fetchAll(filters),
                errorMessageId: 'DOCUMENT.ACCESS.DOCUMENTS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            });

            /** Extract childItems */
            const resultItems = result.parsedValue?.items
                ? result.parsedValue?.items.reduce(
                      (parentAcc, item) => [...parentAcc, item, ...(item?.childItems || [])],
                      []
                  )
                : [];

            const updatedResult = Object.assign(result, {
                parsedValue: { ...result?.parsedValue, items: resultItems }
            });

            return updatedResult;
        },

        fetchFile: async (itemId, filters) =>
            performAPIAction({
                ...rest,
                apiCall: itemRepository.fetchFile(itemId, filters),
                errorMessageId: 'DOCUMENT.FILE.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),
        fetchInfo: async itemId =>
            performAPIAction({
                ...rest,
                apiCall: itemRepository.fetch(itemId),
                errorMessageId: 'DOCUMENT.INFO.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        upload: async (items, isGrouped, initItem = {}) => {
            const formattedItems = await formatItems(items, isGrouped, initItem);
            formattedItems.length > 0 &&
                (await performAPIAction({
                    ...rest,
                    apiCall: itemRepository.upload(formattedItems),
                    successMessageId: 'DOCUMENT.ITEM.UPLOAD.SUCCESS',
                    errorMessageId: 'DOCUMENT.ITEM.UPLOAD.ERROR'
                }));
        },
        update: (item, displaySuccessMessage = true) =>
            performAPIAction({
                ...rest,
                apiCall: itemRepository.update(item.id, {
                    ...item,
                    userProfile: item?.userProfile ?? definedUserProfile
                }),
                successMessageId: displaySuccessMessage ? 'DOCUMENT.ITEM.UPDATE.SUCCESS' : null,
                errorMessageId: 'DOCUMENT.ITEM.UPDATE.ERROR'
            }),
        delete: item =>
            performAPIAction({
                ...rest,
                apiCall: itemRepository.delete(item.id),
                successMessageId: 'DOCUMENT.ITEM.DELETE.SUCCESS',
                errorMessageId: 'DOCUMENT.ITEM.DELETE.ERROR'
            }),
        download: (item, callback) =>
            performAPIAction({
                ...rest,
                apiCall: itemRepository.download(item.id),
                errorMessageId: 'DOCUMENT.FILE.ACCESS.ERROR',
                callback: callback
            }),
        getDocumentTypes: filters =>
            performAPIAction({
                ...rest,
                apiCall: itemRepository.fetchDocumentTypes(filters),
                errorMessageId: 'DOCUMENT.STATUSES.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),
        getItemErrors: () =>
            performAPIAction({
                ...rest,
                apiCall: itemRepository.fetchErrors(),
                errorMessageId: 'DOCUMENT.ERRORS.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            })
    };
};

export const ItemService = ({
    intl,
    user,
    definedUserProfile = PROFILE.POSTIE,
    authorisedUploadItemType = UPLOAD_TYPES.SUPPORT,
    checkSession,
    addToastMessage,
    callback,
    setLoading = isLoading => {},
    setErrorMessage = () => {}
}) => {
    const itemsMethods = ItemMethods({
        intl,
        user,
        definedUserProfile,
        authorisedUploadItemType,
        checkSession,
        addToastMessage,
        callback,
        setLoading,
        setErrorMessage
    });
    return { ...itemsMethods };
};
