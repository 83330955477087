// import { FORM_FIELD } from "../../../../helpers";

export const GENERAL_FIELDS = [
    {
        itemKey: '0',
        labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.NAME.LABEL', isRequired: true },
        TextFieldItem: { id: 'name' }
    },
    {
        itemKey: '1',
        labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.DESCRIPTION.LABEL' },
        TextFieldItem: { id: 'description' }
    }
];


// to use with useForm Hook
// export const GENERAL_FIELDS = [
//     {
//         id: 'name',
//         fieldType: FORM_FIELD.TEXT_FIELD,
//         color: 'primary'
//     },
//     {
//         id: 'description',
//         fieldType: FORM_FIELD.TEXT_FIELD,
//         labelItem: { id: 'SELECT.PARAMETERS.CONFIRMATION.DESCRIPTION.LABEL' },
//         color: 'primary'
//     }
// ];