import React from 'react';
import { saveContact } from '../helpers/ContactHelper';
import { ContactsList } from '../components/contact';
import { ContactSearchBar } from '../components/contact/ContactSearchBar';

export const withContact = WrappedComponent =>
    function withContactContainer(props) {
        const isContactAlreadySelected = (newContact, oldContacts) => {
            return oldContacts.find(x => x?.email === newContact.email);
        };

        const handleContactChange = (newContact, oldContacts) => {
            const updatedContacts = [...oldContacts];

            if (!isContactAlreadySelected(newContact, oldContacts)) {
                updatedContacts.push({
                    ...newContact,
                    ...{ isSignatory: false, isRecipient: true }
                });
            }

            //else the contact has been already selected
            return updatedContacts;
        };

        const renderContactsSelect = ({
            allContacts,
            selectedContacts,
            handleContactsUpdate,
            disabled,
            optionLabelDisplay,
            selectedContact = null
        }) => {
            return (
                <ContactSearchBar
                    optionLabelDisplay={optionLabelDisplay}
                    disabled={disabled}
                    selectedContact={selectedContact}
                    options={allContacts}
                    onSelectionChange={contact => {
                        handleContactsUpdate(handleContactChange(contact, selectedContacts));
                    }}
                />
            );
        };

        const handleContactSubmit = ({ contact, contacts, callback, createContact, updateContact }) => {
            (async () => {
                await saveContact({
                    contact: contact,
                    allContacts: contacts,
                    create: createContact,
                    update: updateContact,
                    callback: callback
                });
            })();
        };

        const handleUpdateRole = (updatedContact, contacts) => {
            const updatedContacts = contacts.map(oldContact => {
                let newContact;
                if (oldContact.id === updatedContact.id) {
                    newContact = { ...updatedContact, isSignatory: updatedContact.isSignatory };
                } else {
                    newContact = {
                        ...oldContact,
                        isSignatory: updatedContact.isSignatory ? false : oldContact.isSignatory
                    };
                }

                return newContact;
            });

            return updatedContacts;
        };

        const renderContacts = ({
            isSignatoryOptionDisabled = false,
            contacts,
            handleEditContact,
            handleDeleteContact,
            roleChangeCallback,
            isRoleEditable = true,
            disabled = false,
            contactType,
            handleInvite = null,
            handleManageAccessRights = null,
            showInvitationStatus = false
        }) => {
            return (
                <>
                    {contacts && (
                        <ContactsList
                            showInvitationStatus={showInvitationStatus}
                            handleManageAccessRights={handleManageAccessRights}
                            handleInvite={handleInvite}
                            isSignatoryOptionDisabled={isSignatoryOptionDisabled}
                            contactType={contactType}
                            disabled={disabled}
                            isRoleEditable={isRoleEditable}
                            contacts={contacts}
                            handleClickOpen={handleEditContact}
                            handleRemoveContact={handleDeleteContact}
                            handleRoleChange={updatedContact =>
                                roleChangeCallback && roleChangeCallback(handleUpdateRole(updatedContact, contacts))
                            }
                        />
                    )}
                </>
            );
        };

        return (
            <WrappedComponent
                {...props}
                handleContactSubmit={handleContactSubmit}
                renderContacts={renderContacts}
                renderContactsSelect={renderContactsSelect}
            />
        );
    };
