import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers';
import { CountryAdminMenuComponent } from '../../components/admin';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PROFILE } from '../../types/types';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import { Loader } from '../../components/loader/Loader';

/**
 * Country administration page component for user with countryAdmin role.
 *
 * This administration page give users the tools to setup the application for his country's needs.
 *
 * Country configuration for mailing, robot, DMS, workflows, templates, client companies (Please refer to the config section).
 */
class CountryAdminMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkSessionResults: []
        };
    }

    render() {
        const { user } = this.props;

        if (!user.profil) {
            return <Loader />;
        }

        if (isAuthorisedProfile(PROFILE.ADMIN, user.profil)) {
            return <CountryAdminMenuComponent />;
        }

        return <PageNotFound {...this.props} />;
    }
}

CountryAdminMenu.propTypes = {
    /**
     * Information received from the API of connected user.
     */
    user: PropTypes.object.isRequired,
    /**
     * Memorise messages received from API requests.
     */
    checkSessionResults: PropTypes.array
};

const mapStateToProps = state => {
    return {
        user: state.account.user
    };
};

export const countryAdminMenu = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps)
)(injectIntl(CountryAdminMenu));
