import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers';
import { ConfigComponent } from '../../components/setup';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PROFILE } from '../../types/types';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import { Loader } from '../../components/loader/Loader';

/**
 * Country administration page component for user with countryAdmin role.
 *
 * This administration page give users the tools to setup the application for his country's needs.
 *
 * Country configuration for mailing, robot, DMS, workflows, templates, client companies (Please refer to the config section).
 */
class CountryAdminSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };
    }

    render() {
        const { user } = this.props;

        if (!user.profil) {
            return <Loader />;
        }

        if (isAuthorisedProfile(PROFILE.ADMIN, user.profil)) {
            return <ConfigComponent {...this.state} />;
        }

        return <PageNotFound {...this.props} />;
    }
}

CountryAdminSettings.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array
};

CountryAdminSettings.defaultProps = {
    loading: false
};

const mapStateToProps = state => {
    return {
        user: state.account.user,
    };
};

export const countryAdminSettings = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps)
)(injectIntl(CountryAdminSettings));
