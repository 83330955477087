import { deepFreeze } from '../../../../helpers/FreezeHelper';

const InitStorageDefault = {
    clusterId: null,
    isActive: false,
    priorityLevel: 5,
    targetPath: ''
};

export const initStorageDefault = deepFreeze(InitStorageDefault);
