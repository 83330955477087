export const confirmation = {
    'CONFIRMATION.TITLE': 'Circularisation',
    'CONFIRMATION.ACCESS.CONFIRMATION.ERROR': 'Échec lors de l\'accès aux informations de la circularisation',
    'CONFIRMATION.ACCESS.DOCUMENTS.ERROR': 'Échec lors de l\'accès aux documents de la circularisation',
    'CONFIRMATION.ACCESS.COMPANY.THIRDPARTY.ERROR': 'Échec lors de l\'accès aux tiers de la circularisation',
    'CONFIRMATION.UPDATE.SUCCESS': 'Circularisation mise à jour',
    'CONFIRMATION.UPDATE.ERROR': 'Échec lors de la mise à jour de la circularisation',
    'CONFIRMATION.ERASE.SUCCESS': 'Circularisation supprimée définitivement',
    'CONFIRMATION.ERASE.ERROR': 'Échec lors de la suppression de la circularisation',
    'CONFIRMATION.DELETE.SUCCESS': 'Circularisation déplacée dans la corbeille',
    'CONFIRMATION.DELETE.ERROR': 'Échec lors du déplacement de la circularisation dans la corbeille',
    'CONFIRMATION.DELETE.INFO': 'Mettre la circularisation à la corbeille',
    'CONFIRMATION.RESTORE.INFO': 'Restaurer la circularisation',
    'CONFIRMATION.ERASE.INFO': 'Supprimer définitivement la circularisation',
    'CONFIRMATION.HEADER.TITLE': 'Circularisation de {title}',
    'CONFIRMATION.BUTTON.BACK.LABEL': 'Retour',
    'CONFIRMATION.BUTTON.RESET.LABEL': 'Reinitialiser',
    'CONFIRMATION.BUTTON.DELETE.LABEL': 'Supprimer',
    'CONFIRMATION.BUTTON.SAVE.LABEL': 'Sauvegarder',
    'CONFIRMATION.BUTTON.EDIT.LABEL': 'Modifier',
    'CONFIRMATION.BUTTON.REPLACE.LABEL': 'Remplacer',
    'CONFIRMATION.BUTTON.CREATE.LABEL': 'Créer',
    'CONFIRMATION.BUTTON.NEXTSTEP.LABEL': 'Étape suivante',
    'CONFIRMATION.BUTTON.VALIDATE.LABEL': 'Valider',
    'CONFIRMATION.LABEL.CONFIRMATION_NAME': 'Circularisation',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.CONFIRMATION_NAME': 'Le nom de la circularisation est requis',
    'CONFIRMATION.LABEL.CONFIRMATION_DESCRIPTION': 'Description',
    'CONFIRMATION.LABEL.PERIODE_STARTDATE': 'Date de début de la période',
    'CONFIRMATION.LABEL.PERIODE_ENDDATE': 'Date de fin de la période',
    'CONFIRMATION.LABEL.CONFIRMATION_CLIENT': 'Entité',
    'CONFIRMATION.LABEL.CONFIRMATION_CODE': 'Code',
    'CONFIRMATION.LABEL.CLIENT_DUEDATE': 'Date d\'échéance de signature du client',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.CLIENT_DUEDATE': 'La date d\'échéance de signature du client est requise',
    'CONFIRMATION.PLACEHOLDER.CLIENT_DUEDATE': 'Date de signature',
    'CONFIRMATION.LABEL.THIRDPARTY_DUEDATE': 'Date d\'échéance de la réponse du tiers',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.THIRDPARTY_DUEDATE': 'La date d\'échéance de réponse du tiers est requise',
    'CONFIRMATION.LABEL.LANGUAGE': 'Langue',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'La langue est requise',
    'CONFIRMATION.PLACEHOLDER.THIRDPARTY_DUEDATE': 'Date de réponse du tiers',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.AUTOMATIC_CLIENT_SENDDATE':
        'La date d\'envoi automatique du client est requise',
    'CONFIRMATION.FIELD.VALIDATION.REQUIRED_FIELD.AUTOMATIC_THIRDPARTY_SENDDATE':
        'La date d\'envoi automatique du tiers est requise',
    'CONFIRMATION.CHECKBOX.UPLOAD.GROUPED.LABEL': 'Grouper plusieurs fichiers dans un dossier',
    'CONFIRMATION.CHECKBOX.UPLOAD.GROUPED.TOOLTIP': 'Uploader des documents liés à la même circularisation dans un dossier.',
    'CONFIRMATION.ALL.DOCUMENTS.FILE.NAME': 'confirmation_all_documents.zip',
    'CONFIRMATION.ALL.DOCUMENTS.DOWNLOAD.ALL.FILES': 'Tout télécharger',
    'IMPORT.CONFIRMATION.EXCEL.FILE.LABEL': 'Sélectionner un fichier',
    'IMPORT.CONFIRMATION.EXCEL.FILE.NAME.LABEL': 'confirmations_upload.xlsx',
    'IMPORT.CONFIRMATION.EXCEL.FILE.WORKSHEETNAME.LABEL': 'Circularisations',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.HEADER.LABEL': 'Information entités',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.COMPANY.HEADER.LABEL': 'Entité',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.COMPANY.NAME.LABEL': 'Nom',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.COMPANY.NAME.COMMENT':
        'Choisissez l\'entreprise cliente dans le menu déroulant. Si vous devez créer une nouvelle société cliente, ajoutez-la dans vos paramètres d\'engagement sur l\'application web, puis régénérez ce fichier.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.HEADER.LABEL': 'Contact principal',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.EMAIL.LABEL': 'E-mail',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.EMAIL.COMMENT':
        'Choisissez le contact client dans le menu déroulant. Si vous devez créer un nouveau contact client, ajoutez-le dans vos paramètres d\'engagement sur l\'application web, puis régénérez ce fichier.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.RECIPIENT.LABEL': 'est destinataire',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.RECIPIENT.COMMENT':
        "'True' si vous souhaitez que le contact reçoive la demande de circularisation. Si vous choisissez 'False', le contact pourra remplir le modèle de lettre mais ne recevra pas d'e-mail.",
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT2.HEADER.LABEL': '(Optional) contact secondaire',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT2.EMAIL.LABEL': 'E-mail',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT2.EMAIL.COMMENT':
        'Choisissez le contact client dans le menu déroulant. Si vous devez créer un nouveau contact client, ajoutez-le dans vos paramètres d\'engagement sur l\'application web, puis régénérez ce fichier.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.HEADER.LABEL': 'Information tiers',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.HEADER.LABEL': 'Entreprise',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.NAME.LABEL': 'Nom',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.NAME.COMMENT':
        'Nom de l\'entreprise tierce qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ADDRESS.LABEL': 'Adresse',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ADDRESS.COMMENT':
        'Adresse de l\'entreprise tierce qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.CITY.LABEL': 'Ville',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.CITY.COMMENT':
        'Ville de l\'entreprise tierce qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ZIPCODE.LABEL': 'Code postal',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ZIPCODE.COMMENT':
        'Code postal de l\'entreprise tierce qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.COUNTRY.LABEL': 'Pays',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.COUNTRY.COMMENT':
        'Pays de l\'entreprise tierce qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.HEADER.LABEL': 'Contact',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.JOBTITLE.LABEL': 'Poste',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.JOBTITLE.COMMENT':
        'Titre du poste du contact tiers qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.EMAIL.LABEL': 'E-Mail',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.EMAIL.COMMENT':
        'Adresse électronique du contact tiers qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.TITLE.LABEL': 'Title',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.TITLE.COMMENT':
        'Titre du contact tiers qui recevra la demande de circularisation. (par exemple, M., Mme...).',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.FIRSTNAME.LABEL': 'Prénom',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.FIRSTNAME.COMMENT':
        'First name of the third-party contact that will receive the circularisation request.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.LASTNAME.LABEL': 'Nom de famille',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.LASTNAME.COMMENT':
        'Nom de famille du contact tiers qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.DIALING_CODE.LABEL': 'Indicatif',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.DIALING_CODE.COMMENT':
        'Indicatif du numéro de téléphone du contact tiers qui recevra la demande de circularisation. (par exemple +33, +65...).',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.PHONE.LABEL': 'Téléphone',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.PHONE.COMMENT':
        'Numéro de téléphone du contact tiers qui recevra la demande de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.HEADER.LABEL': 'Information de la circularisation',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.SEND_DATE.LABEL': 'Date d\'envoi au client',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.SEND_DATE.COMMENT':
        'Date à laquelle vous souhaitez envoyer la circularisation au client. La date sera affichée sur la lettre de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.DUE_DATE.LABEL': 'Date d\'échéance de la réponse du client',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.DUE_DATE.COMMENT':
        'Date à laquelle vous souhaitez la réponse du client à votre circularisation. La date sera affichée sur la lettre de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.SEND_DATE.LABEL': 'Date d\'envoi au tiers',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.SEND_DATE.COMMENT':
        'Date à laquelle vous souhaitez envoyer la circularisation au tiers. La date sera affichée sur la lettre de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.DUE_DATE.LABEL': 'Date d\'échéance de la réponse du tiers',
    'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.DUE_DATE.COMMENT':
        'Date à laquelle vous souhaitez que le tiers réponde à la circularisation. La date sera affichée sur la lettre de circularisation.',
    'IMPORT.CONFIRMATION.EXCEL.TRUE_VALUE': 'Oui',
    'IMPORT.CONFIRMATION.EXCEL.FALSE_VALUE': 'Non',
    'IMPORT.CONFIRMATION.EXCEL.CLIENT.COMPANY.SELECTION.ERROR.TITLE': 'La société cliente sélectionnée n\'est pas valide',
    'IMPORT.CONFIRMATION.EXCEL.CLIENT.COMPANY.SELECTION.ERROR.MESSAGE':
        'Veuillez sélectionner une société cliente dans le menu déroulant. Si vous devez ajouter une nouvelle société cliente, ajoutez-la dans vos paramètres d\'engagement sur l\'application web, puis régénérez ce fichier.',
    'IMPORT.CONFIRMATION.EXCEL.CLIENT.CONTACT.SELECTION.ERROR.TITLE': 'L\'e-mail de contact du client sélectionné n\'est pas valide',
    'IMPORT.CONFIRMATION.EXCEL.CLIENT.CONTACT.SELECTION.ERROR.MESSAGE':
        'Veuillez sélectionner l\'e-mail d\'un contact client dans le menu déroulant. Si vous devez ajouter un nouveau contact client, ajoutez-le dans vos paramètres d\'engagement sur l\'application web, puis régénérez ce fichier.',
    'IMPORT.CONFIRMATION.EXCEL.BOOLEAN.SELECTION.ERROR.TITLE': 'Sélection non valide',
    'IMPORT.CONFIRMATION.EXCEL.BOOLEAN.SELECTION.ERROR.MESSAGE': "Veuillez sélectionner 'True' ou 'False'.",
    'IMPORT.CONFIRMATION.EXCEL.DATE_TIME.ERROR.TITLE': 'Date invalide',
    'IMPORT.CONFIRMATION.EXCEL.DATE_TIME.ERROR.MESSAGE':
        "Veuillez saisir une date/heure valide comprise entre 'aujourd'hui - 15 ans' et 'aujourd'hui + 15 ans'.",
    'IMPORT.CONFIRMATION.LETTER_TEMPLATE.NOT.EXISTS':
        'Les informations contenues dans votre fichier n\'existent pas ou ont été modifiées, veuillez régénérer un nouveau modèle Excel.',
    'METHOD.CREATE.CONFIRMATION.BUTTON.BACK.LABEL': 'Retour',
    'METHOD.CREATE.CONFIRMATION.MANUAL.TITLE': 'Manuel',
    'METHOD.CREATE.CONFIRMATION.MANUAL.DESCRIPTION': 'Créer une circularisation unitairement',
    'METHOD.CREATE.CONFIRMATION.MANUAL.CONTENT': 'Suivez toutes les étapes du processus de création de la circularisation.',
    'METHOD.CREATE.CONFIRMATION.IMPORT.TITLE': 'Import',
    'METHOD.CREATE.CONFIRMATION.IMPORT.DESCRIPTION': 'Créer plusieurs circularisation',
    'METHOD.CREATE.CONFIRMATION.IMPORT.CONTENT':
        'Télécharger le modèle de fichier Excel à remplir. Sélectionner le type de circularisation à créer. Importer le fichier Excel contenant toutes les circularisations à créer.',
    'METHOD.CREATE.CONFIRMATION.TITLE': 'Nouvelle circularisation',
    'MANUAL.CREATE.CONFIRMATION.TITLE': 'Creation unitaire',
    'MANUAL.CREATE.CONFIRMATION.CREATE.ERROR': 'Échec lors de la création de la circularisation',
    'MANUAL.CREATE.CONFIRMATION.CREATE.SUCCESS': 'Circularisation créée',
    'MANUAL.CREATE.CONFIRMATION.ACCESS.ENGAGEMENT.ERROR': 'Échec lors de l\'accès à l\'engagement',
    'MANUAL.CREATE.CONFIRMATION.TEMPLATES.ACCESS.ERROR': 'Échec lors de l\'accès au modèle de circularisation',
    'MANUAL.CREATE.CONFIRMATION.ACCESS.COMPANY.CLIENT.ERROR': 'Échec lors de l\'accès aux entreprises de l\'engagement',
    'MANUAL.CREATE.CONFIRMATION.COMPANY.SEARCH_BAR.PLACEHOLDER': 'Recherche par nom, adresse, ville, code postal, ...',
    'MANUAL.CREATE.CONFIRMATION.COMPANY.NOT.EXISTS': 'Mon entreprise n\'existe pas',
    'MANUAL.CREATE.CONFIRMATION.COMPANY.NO.CONTACTS': 'Pas de contacts',
    'MANUAL.CREATE.CONFIRMATION.COMPANY.CONTACTS': 'Contacts',
    'CREATE_CONFIRMATION.STEP_TEMPLATE.LABEL': 'Modèle de circularisation',
    'CREATE_CONFIRMATION.STEP_CLIENT.LABEL': 'Entité',
    'CREATE_CONFIRMATION.STEP_THIRD_PARTY.LABEL': 'Tiers',
    'CREATE_CONFIRMATION.STEP_PARAMETERS.LABEL': 'Information',
    'CREATE_CONFIRMATION.STEP_OVERVIEW.LABEL': 'Overview',
    'CREATE_CONFIRMATION.STEP_WORKFLOW.LABEL': 'Workflow',
    'CREATE_CONFIRMATION.BUTTON.LABEL.BACK': 'Retour',
    'CREATE_CONFIRMATION.BUTTON.LABEL.RESET': 'Reinitialiser',
    'CREATE_CONFIRMATION.BUTTON.LABEL.CREATE': 'Créer',
    'CREATE_CONFIRMATION.STEPS.NEXT.LABEL': 'Suivant',
    'CREATE_CONFIRMATION.STEPS.PREVIOUS.LABEL': 'Précédent',
    'CREATE_CONFIRMATION.STEPS.FINISH.LABEL': 'Finir',
    'CREATE_CONFIRMATION.STEPS.FINISH.MSG': 'Toutes les étapes sont achevées',
    'CREATE_CONFIRMATION.STEPS.RESET.LABEL': 'Reinitialiser',
    'CREATE_CONFIRMATION.VALIDATION.REQUIRED_STEPS.CLIENT': ' - Une entreprise cliente est requise',
    'CREATE_CONFIRMATION.VALIDATION.REQUIRED_STEPS.STACKED_STEPS': 'La sélection d\'une option est requise pour chaque étape',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SECTION.FILTER.LABEL': 'Filtres',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.BUTTON.CLEAR.FILTER.LABEL': 'Retirer les filtres',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.TYPE.LABEL': 'Sélectionner une catégorie de circularisation',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.TYPE.PLACEHOLDER': 'Catégorie de circularisation',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.LANGUAGE.LABEL': 'Sélectionner la langue',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.LANGUAGE.PLACEHOLDER': 'langue',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.TYPE.LABEL': 'Type: ',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.UPDATE.LABEL': 'Dernière mise à jour:',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.LANGUAGE.LABEL': 'Langue: ',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.LOADING.LABEL': 'Chargement..',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.TEMPLATE.BUTTON.LABEL': 'Sélectionner',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SEE_MORE.BUTTON.LABEL': 'Voir plus d\'informations',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.STACKED_STEPS':
        'Pour chaque étape optionnelle du workflow, veuillez sélectionner celle à utiliser.',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.MORE_INFO_STACKED_STEPS': 'Modèle de workflow',
    'CREATE_CONFIRMATION.SELECT_TEMPLATE.SEE_MORE.TITLE': "Détails du modèle de lettre",
    'CREATE_CONFIRMATION.SELECT.CLIENT.COMPANY.LABEL': "Sélectionnez l'une des entités de votre mission",
    'CREATE_CONFIRMATION.CLIENT.CREATE.COMPANY.LABEL': 'Créer une entité',
    'CREATE_CONFIRMATION.SELECT.CLIENT.CONTACT_COMPANY.LABEL': "Sélectionnez l'un des contacts de votre mission",
    'CREATE_CONFIRMATION.CLIENT.CONTACT.LABEL': 'Contacts',
    'CREATE_CONFIRMATION.CLIENT.CREATE.CONTACT.LABEL': 'Créer un contact',
    'CREATE_CONFIRMATION.CLIENT.CONTACT.SIGNATORY': 'Signataire',
    'CREATE_CONFIRMATION.CLIENT.CONTACT.RECIPIENT': 'Destinataire',
    'CREATE_CONFIRMATION.CLIENT.CONTACT.SIGNATORY.INFO':
        'Cochez cette case pour désigner la personne dont le nom apparaîtra comme signataire de la lettre.',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.MAIN.INFO':
        'Contact à qui la lettre sera adressée. Le nom du contact sera utilisé dans la lettre générée.',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.MAIN': 'Principal',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.RECIPIENT.INFO': 'Le contact recevra la demande de circularisation par courriel',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.RECIPIENT': 'Destinataire',
    'CREATE_CONFIRMATION.CLIENT.CONTACT.RECIPIENT.INFO':
        'Cochez cette case pour désigner la personne qui recevra la lettre de demande de confirmation.',
    'CREATE_CONFIRMATION.SELECT.THIRDPARTY.COMPANY.LABEL': 'Sélectionner une entreprise tierce',
    'CREATE_CONFIRMATION.THIRDPARTY.COMPANY.LABEL': 'Entreprise',
    'CREATE_CONFIRMATION.THIRDPARTY.SEARCH.COMPANY.LABEL': 'Sélectionner une entreprise tierce',
    'CREATE_CONFIRMATION.THIRDPARTY.CREATE.COMPANY.LABEL': 'Créer une entreprise tierce',
    'CREATE_CONFIRMATION.THIRDPARTY.EDIT.COMPANY.LABEL': 'Modifier une entreprise tierce',
    'CREATE_CONFIRMATION.THIRDPARTY.SELECT.COMPANY.LABEL': 'Sélectionner une entreprise tierce',
    'CREATE_CONFIRMATION.SELECT.THIRDPARTY.CONTACT.LABEL': 'Sélectionner des contacts tiers',
    'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.LABEL': 'Contacts',
    'CREATE_CONFIRMATION.THIRDPARTY.CREATE.CONTACT.LABEL': 'Créer contact',
    'IMPORT.CREATE.CONFIRMATION.TITLE': 'Créer des circularisations via import',
    'IMPORT.CONFIRMATION.CARD.HEADER': 'Importer des circularisations',
    'IMPORT.CONFIRMATION.BUTTON.BACK.LABEL': 'Retour',
    'IMPORT.CONFIRMATION.BUTTON.RESET.LABEL': 'Reinitialiser',
    'IMPORT.CONFIRMATION.BUTTON.SAVE.LABEL': 'Sauvegarder',
    'IMPORT.CONFIRMATION.BUTTON.DOWNLOAD.XLS_TEMPLATE.LABEL': 'Télécharger modèle Excel',
    'IMPORT.CONFIRMATION.BUTTON.IMPORT.LABEL': 'Upload circularisations',
    'IMPORT_DIALOG.SECTION.TITLE': 'Importer fichier de circularisations',
    'IMPORT_DIALOG.BUTTON.CANCEL': 'Annuler',
    'IMPORT_DIALOG.BUTTON.VALIDATE': 'Valider',
    'IMPORT.CONFIRMATION.LABEL.CLIENT': 'Entité',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.CLIENT': 'Entité est requise',
    'IMPORT.CONFIRMATION.LABEL.TYPE': 'Catégorie de circularisation',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.TYPE': 'La catégorie de la circularisation est requise',
    'IMPORT.CONFIRMATION.LABEL.TEMPLATE': 'Modèle de circularisation',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.TEMPLATE': 'Le modèle est requis',
    'IMPORT.CONFIRMATION.LABEL.LANGUAGE': 'Langue',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'La langue est requise',
    'IMPORT.CONFIRMATION.LABEL.SHEETNAME': 'Nom de l\'onglet',
    'IMPORT.CONFIRMATION.VALIDATION.REQUIRED_FIELD.SHEETNAME': 'Le nom de l\'onglet est requis',
    'IMPORT.CONFIRMATION.LABEL.UPLOAD': 'Upload des circularisations',
    'IMPORT.CONFIRMATION.UPLOAD.ERROR.FORMAT': 'Erreur de format du fichier',
    'IMPORT.CONFIRMATION.UPLOAD.SUBMIT.LABEL': 'Soumettre',
    'IMPORT.CONFIRMATION.UPLOAD.CHECK.MAPPING.MESSAGE': 'Veuillez vérifier la correspondance des colonnes avant de soumettre le formulaire.',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.INFORMATION': 'Général',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.OTHERS': 'Autres',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.PERIOD_DATES': 'Dates de la période',
    'SELECT.PARAMETERS.CONFIRMATION.NAME.LABEL': 'Nom',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.NAME': 'Le nom de la circularisation est requis',
    'SELECT.PARAMETERS.CONFIRMATION.DESCRIPTION.LABEL': 'Description',
    'SELECT.PARAMETERS.CONFIRMATION.PERIOD.STARTDATE.LABEL': 'Date de début de la période',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.PERIOD.STARTDATE': 'La date de début de la période est requise',
    'SELECT.PARAMETERS.CONFIRMATION.PERIOD.ENDDATE.LABEL': 'Date de fin de la période',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.PERIOD.ENDDATE': 'La date de fin de la période est requise',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.DATES': 'Dates',
    'SELECT.PARAMETERS.CONFIRMATION.CLIENT.DUEDATE.LABEL': 'Date d\'échéance de signature du client',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.CLIENT.DUEDATE': 'La date d\'échéance client est requise',
    'SELECT.PARAMETERS.CONFIRMATION.CLIENT.SENDDATE.LABEL': 'Date d\'envoi au client',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.CLIENT.SENDDATE': 'la date d\'envoi au client est requise',
    'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.DUEDATE.LABEL': 'Date d\'échéance de réponse du tiers',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.THIRDPARTY.DUEDATE': 'La date d\'échéance tiers est requise',
    'SELECT.PARAMETERS.CONFIRMATION.SECTION.AUTO': 'Automatisation',
    'SELECT.PARAMETERS.CONFIRMATION.CLIENT.AUTOMATIC.SENDING.LABEL': 'Envoi automatique au client',
    'SELECT.PARAMETERS.CONFIRMATION.THIRDPARTY.AUTOMATIC.SENDING.LABEL': 'Envoi automatique au tiers',
    'SELECT.PARAMETERS.VALIDATION.REQUIRED_FIELD.EXTRA_FIELDS_ITEM': '{fieldName} est requis',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.SUMMARY.LABEL': 'Résumé',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CATEGORY.LABEL': 'Catégorie de circularisation',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.LANGUAGE.LABEL': 'langue',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.TEMPLATE.LABEL': 'Modèle',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.NAME.LABEL': 'Nom',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.DESCRIPTION.LABEL': 'Description',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CLIENT.NAME.LABEL': "Entités de la mission",
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CLIENT.CONTACTS.LABEL': "Contacts de l'entité",
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CLIENT.DUEDATE.LABEL': 'Date d\'échéance de signature du client',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.CLIENT.SENDDATE.LABEL': 'Date d\'envoi au client',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.THIRDPARTY.NAME.LABEL': 'Entreprise tierce',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.THIRDPARTY.CONTACTS.LABEL': 'Contacts tiers',
    'CREATE_CONFIRMATION.OVERVIEW.CONFIRMATION.THIRDPARTY.DUEDATE.LABEL': 'Date d\'échéance de réponse du tiers',
    'CREATE_CONFIRMATION.OVERVIEW.ENGAGEMENT.CODE.LABEL': 'Code mission (engagement)',
    'CREATE_CONFIRMATION.OVERVIEW.ENGAGEMENT.NAME.LABEL': 'Nom de la mission',
    'CONFIRMATION.CREATE.ERROR': 'Échec lors de la création dela circularisation',
    'CONFIRMATION.SUBSECTION.CONTACTS.THIRDPARTY': 'Contacts tiers',
    'CONFIRMATION.SUBSECTION.COMPANY.THIRDPARTY': 'Entreprises tierces',
    'CONFIRMATION.SUBSECTION.INFORMATION': 'Information',
    'CONFIRMATION.SUBSECTION.DOCUMENTS': 'Documents',
    'CONFIRMATION.SUBSECTION.RECYCLE_BIN': 'Corbeille',
    'CONFIRMATION.DOCUMENTS.RECYCLE_BIN': 'Ouvrir la corbeille',
    'CONFIRMATION.DOCUMENTS.RECYCLE_BIN.RETURN': 'Fermer la corbeille',
    'CONFIRMATION.SUBSECTION.COMMENTS': 'Commentaires',
    'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_SIGNATORY': ' - Un contact signataire est requis',
    'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_MAIN': ' - Un contact principal est requis',
    'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_RECIPIENT': ' - Au moins un contact destinataire est requis',
    'CONFIRMATION.DOCUMENT.TYPE.0': 'type de document inconnu',
    'CONFIRMATION.DOCUMENT.TYPE.1': 'Modèle de lettre de circularisation',
    'CONFIRMATION.DOCUMENT.TYPE.2': 'Demande au client',
    'CONFIRMATION.DOCUMENT.TYPE.3': 'Réponse du client',
    'CONFIRMATION.DOCUMENT.TYPE.4': 'Demande au tiers',
    'CONFIRMATION.DOCUMENT.TYPE.5': 'Réponse du tiers',
    'CONFIRMATION.DOCUMENT.TYPE.6': 'E-mail',
    'CONFIRMATION.DOCUMENT.TYPE.7': 'Autre',
    'CONFIRMATION.DOCUMENT.TYPE.PLACEHOLDER': 'Sélectionner un type de document',
    'CONFIRMATION.CONTACT.EMAIL.INVALID.MESSAGE':
        'Courriel envoyé au contact {email}, retourné comme non délivré le {date}',
    'CONFIRMATION.CLIENT.SENDING.REQUESTS.NUMBER.MESSAGE':
        'Circularisation envoyée au client {compteur} fois - dernière demande : {date}',
    'CONFIRMATION.THIRDPARTY.SENDING.REQUESTS.NUMBER.MESSAGE':
        'Circularisation envoyée au tiers {compteur} fois - dernière demande : {date}',
    'CONFIRMATION.THIRDPARTY.SENDING.REQUESTS.CHECK.LETTER.MESSAGE':
        'Veuillez vérifier le contenu de votre lettre de circularisation avant de l\'envoyer (signature, qualité, nombre de pages,...).',
    'CONFIRMATION.STACKABLE.ACTIONS.CHOICE': 'Options d\'envoi de la circularisation',
    'COMMENT.PLACEHOLDER.COMMENT': 'Ecrivez votre commentaire ici...',
    'COMMENT.POST.VALIDATION.REQUIRED_FIELD.TEXT': 'Un message est requis',
    'COMMENT.BUTTON.ADD': 'Envoyer',
    'COMMENT.CHECKBOX.LABEL': 'Privé',
    'COMMENT.CHECKBOX.TOOLTIP': 'Les messages privés ne peuvent être visualisés que par un utilisateur autorisé.',
    'CONFIRMATION.ACCESS.COUNTRIES.ERROR': 'Échec lors de l\'accès aux pays',
    'CONFIRMATION.ACCESS.LANGUAGES.ERROR': 'Échec lors de l\'accès aux langues',
    'IMPORT.CREATE.CONFIRMATION.ACCESS.XLS_TEMPLATE.ACCESS.ERROR': 'Échec lors de l\'accès au modèle Excel de création de circularisations',
    'IMPORT.CREATE.CONFIRMATION.ACCESS.CONTACT.ERROR': 'Échec lors de l\'accès aux contacts',
    'IMPORT.CREATE.CONFIRMATION.CHECK.SUCCESS': 'Circularisations importées validées',
    'IMPORT.CREATE.CONFIRMATION.CHECK.ERROR': 'Échec lors de la validation des circularisations importées.\nVeuillez modifier les cases en rouges dans le fichier telechargé',
    'IMPORT.CREATE.CONFIRMATION.CREATE.SUCCESS': 'Circularisations créées',
    'IMPORT.CREATE.CONFIRMATION.CREATE.ERROR': 'Échec lors de la création des circularisations',
    'FETCH.CONFIRMATION.WORKFLOWS.ACCESS.ERROR': 'Échec lors de l\'accès au workflow',
    'FETCH.CONFIRMATION.WORKFLOW.ACTIONS.ACCESS.ERROR': 'Échec lors de l\'accès aux étapes du workflow',
    'LETTER.TEMPLATE.ACCESS.ERROR': 'Échec lors de l\'accès au modèle de lettre',
    'LETTER.TEMPLATE.TAG.ACCESS.ERROR': 'Échec lors de l\'accès aux balises du modèle de lettre',
    'FETCH.EMAIL.TEMPLATES.ACCESS.ERROR': 'Échec lors de l\'accès au modèle de courrier électronique',
    'CONFIRMATION.NAVIGATION.PREVIOUS.TOOLTIP': 'Circularisation précédente',
    'CONFIRMATION.NAVIGATION.NEXT.TOOLTIP': 'Prochaine circularisation',
    'FETCH.ENGAGEMENT.MENU.ACCESS.ERROR': 'Échec lors de l\'accès au menu circularisation',
};
