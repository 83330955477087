import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from '../../../helpers/AssetsHelpers';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import { ScatterPlot as ScatterPlotIcon } from '@mui/icons-material';
import { StorageDefaultSetup, StorageSharePointOnlineSetup } from '../../../screens/setup';
const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 40,
        left: 'calc(-50% + 40px)',
        right: 'calc(50% + 40px)'
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(10,31,143) 0%,rgb(0,113,206) 50%,rgb(120,120,120) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(10,31,143) 0%,rgb(0,113,206) 50%,rgb(120,120,120) 100%)'
        },
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
    },
    line: {
        height: 0,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        borderColor: '#eaeaf0',
        zIndex: 1,
        width: 80,
        height: 80,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        boxShadow: '0 4px 10px 0 rgba(0,113,206,.25)'
    },
    completed: {}
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <img alt="azure" src={toAbsoluteUrl('/media/logos/azure-storage-96.svg')} />,
        2: <img alt="sharepoint" src={toAbsoluteUrl('/media/logos/ms-sharepoint-96.svg')} />,
        3: <ScatterPlotIcon color="primary" size="large" />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}>
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    }
}));

function getSteps() {
    return [
        <FormattedMessage id="TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.DEFAULT.LABEL" />,
        <FormattedMessage id="TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.SHAREPOINT_ONLINE.LABEL" />,
        <FormattedMessage id="TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.NEW.LABEL" />
    ];
}

function getStepContent(step, hasValidConfig, setIsStorageConfigExpanded) {
    switch (step) {
        case 0:
            return (
                <StorageDefaultSetup
                    hasValidConfig={hasValidConfig}
                    setIsStorageConfigExpanded={setIsStorageConfigExpanded}
                />
            );
        case 1:
            return (
                <StorageSharePointOnlineSetup
                    hasValidConfig={hasValidConfig}
                    setIsStorageConfigExpanded={setIsStorageConfigExpanded}
                />
            );
        case 2:
            return (
                <div className="fullCenter">
                    <Typography style={{ paddingLeft: '.8em' }} variant="h6">
                        <FormattedMessage id="TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.NEW.MESSAGE" />
                    </Typography>
                </div>
            );
        default:
            return;
    }
}

export function StorageConfigWrapper(props) {
    const { hasValidConfig, setIsStorageConfigExpanded } = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(null);
    const steps = getSteps();

    const handleStep = step => () => {
        setActiveStep(step);
    };

    return (
        <div className={classes.root}>
            <Stepper nonLinear alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {React.Children.toArray(
                    steps.map((label, index) => (
                        <Step>
                            <StepButton onClick={handleStep(index)}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </StepButton>
                        </Step>
                    ))
                )}
            </Stepper>

            <Typography className={classes.instructions}>
                {getStepContent(activeStep, hasValidConfig, setIsStorageConfigExpanded)}
            </Typography>
        </div>
    );
}

injectIntl(StorageConfigWrapper);
