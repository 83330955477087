import { appApiUrl, mazarsOrganizationId } from '../../config/appConfiguration';
import { parseOrganizationCluster, parseOrganizationClusters } from './userParsers';
import { Repository } from '../Repository';

/**
 * Class to communicate with API's AppCustomer resources.
 */
class OrganizationClustersRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        const result = await this.network.get(`organization/${mazarsOrganizationId}/clusters`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseOrganizationClusters);
        return result;
    };

    fetch = async clusterId => {
        let result = await this.network.get(`organization/cluster/${clusterId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseOrganizationCluster);
        return result;
    };

    fetchOrganization = async organizationId => {
        let result = await this.network.get(`organization/${organizationId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseOrganizationCluster);
        return result;
    };
}

export const organizationClustersRepository = new OrganizationClustersRepository();
