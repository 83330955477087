import { deepFreeze } from '../../../helpers/FreezeHelper';

export const FILE_HEADER_SECTIONS_INDEX = 0;
export const FILE_HEADER_SUBSECTIONS_INDEX = 1;
// the line position in the file where we have the columns names
export const FILE_HEADER_INDEX = 2;
export const FILE_DISPLAY_ROWS_NUMBER = 5;
export const LETTER_TEMPLATE_ID_CELL = { LINE_INDEX: 6, COLUMN_INDEX: 0 };
export const COLUMN_UNDEFINED_INDEX = -1;

export const InitUploadConfirmations = {
    file: null,
    worksheetName: null,
    // client part
    clientCompanyNameColumnIndex: 1,
    clientContact1EmailColumnIndex: 2,
    clientContact1IsRecipientColumnIndex: 3,
    clientContact2EmailColumnIndex: 4,

    // third-party part
    thirdPartyCompanyNameColumnIndex: 5,
    thirdPartyCompanyAddressColumnIndex: 6,
    thirdPartyCompanyZipCodeColumnIndex: 7,
    thirdPartyCompanyCityColumnIndex: 8,
    thirdPartyCompanyCountryColumnIndex: 9,
    thirdPartyContact1JobTitleColumnIndex: 10,
    thirdPartyContact1EmailColumnIndex: 11,
    thirdPartyContact1TitleColumnIndex: 12,
    thirdPartyContact1FirstNameColumnIndex: 13,
    thirdPartyContact1LastNameColumnIndex: 14,
    thirdPartyContact1DialingCodeColumnIndex: 15,
    thirdPartyContact1PhoneColumnIndex: 16,

    // confirmation main part
    confirmationClientDueDateColumnIndex: 17,
    confirmationThirdPartyDueDateColumnIndex: 18,

    // confirmation optional part
    // <!> these are the optional tags: if there name change in server Side they must be updated ex: confirmationPeriodStartDateColumnIndex ===> accountExtractStartDateColumnIndex
    accountExtractStartDateColumnIndex: COLUMN_UNDEFINED_INDEX,
    accountExtractEndDateColumnIndex: COLUMN_UNDEFINED_INDEX,
    accountColumnIndex: COLUMN_UNDEFINED_INDEX,
    amountColumnIndex: COLUMN_UNDEFINED_INDEX,
    currencyColumnIndex: COLUMN_UNDEFINED_INDEX
};

const InitConfirmationImport = {
    engagementId: null,
    letterTemplateId: null,
    activeStackedWorkflowActions: [],
    workflowActions: [],
    labels: { fileName: null, worksheetName: null } // and the list of labels, see bellow
};

export const initConfirmationImport = deepFreeze(InitConfirmationImport);

export const IMPORT_CONFIRMATION_CLIENT_COLUMN_NAMES = {
    clientHeaderColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.HEADER.LABEL' },
    clientCompanyHeaderColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.COMPANY.HEADER.LABEL' },
    clientCompanyNameColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.COMPANY.NAME.LABEL' },
    clientContact1HeaderColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.HEADER.LABEL' },
    clientContact1EmailColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.EMAIL.LABEL' },
    clientContact1IsRecipientColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT1.RECIPIENT.LABEL' },
    clientContact2HeaderColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT2.HEADER.LABEL' },
    clientContact2EmailColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CLIENT.CONTACT2.EMAIL.LABEL' }
};

export const IMPORT_CONFIRMATION_THIRDPARTY_COLUMN_NAMES = {
    thirdPartyHeaderColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.HEADER.LABEL' },
    thirdPartyCompanyHeaderColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.HEADER.LABEL' },
    thirdPartyCompanyNameColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.NAME.LABEL' },
    thirdPartyCompanyAddressColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ADDRESS.LABEL' },
    thirdPartyCompanyZipCodeColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.ZIPCODE.LABEL' },
    thirdPartyCompanyCityColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.CITY.LABEL' },
    thirdPartyCompanyCountryColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.COMPANY.COUNTRY.LABEL' },
    thirdPartyContact1HeaderColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.HEADER.LABEL' },
    thirdPartyContact1JobTitleColumnIndex: {
        id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.JOBTITLE.LABEL'
    },
    thirdPartyContact1EmailColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.EMAIL.LABEL' },
    thirdPartyContact1TitleColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.TITLE.LABEL' },
    thirdPartyContact1FirstNameColumnIndex: {
        id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.FIRSTNAME.LABEL'
    },
    thirdPartyContact1LastNameColumnIndex: {
        id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.LASTNAME.LABEL'
    },
    thirdPartyContact1DialingCodeColumnIndex: {
        id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.DIALING_CODE.LABEL'
    },
    thirdPartyContact1PhoneColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.THIRD_PARTY.CONTACT1.PHONE.LABEL' }
};

export const IMPORT_CONFIRMATION_CONFIRMATION_COLUMN_NAMES = {
    confirmationHeaderColumnIndex: { id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.HEADER.LABEL' },
    confirmationClientDueDateColumnIndex: {
        id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.CLIENT.DUE_DATE.LABEL'
    },
    confirmationThirdPartyDueDateColumnIndex: {
        id: 'IMPORT.CONFIRMATION.EXCEL.COLUMNS.CONFIRMATION.THIRD_PARTY.DUE_DATE.LABEL'
    }
};

export const IMPORT_CONFIRMATION_COLUMN_NAMES = {
    ...IMPORT_CONFIRMATION_CLIENT_COLUMN_NAMES,
    ...IMPORT_CONFIRMATION_THIRDPARTY_COLUMN_NAMES,
    ...IMPORT_CONFIRMATION_CONFIRMATION_COLUMN_NAMES
};

export const IMPORT_CONFIRMATION_MESSAGES = {
    clientCompanySelectionErrorTitle: { id: 'IMPORT.CONFIRMATION.EXCEL.CLIENT.COMPANY.SELECTION.ERROR.TITLE' },
    clientCompanySelectionErrorMessage: { id: 'IMPORT.CONFIRMATION.EXCEL.CLIENT.COMPANY.SELECTION.ERROR.MESSAGE' },
    clientContactSelectionErrorTitle: { id: 'IMPORT.CONFIRMATION.EXCEL.CLIENT.CONTACT.SELECTION.ERROR.TITLE' },
    clientContactSelectionErrorMessage: { id: 'IMPORT.CONFIRMATION.EXCEL.CLIENT.CONTACT.SELECTION.ERROR.MESSAGE' },
    booleanSelectionErrorTitle: { id: 'IMPORT.CONFIRMATION.EXCEL.BOOLEAN.SELECTION.ERROR.TITLE' },
    booleanSelectionErrorMessage: { id: 'IMPORT.CONFIRMATION.EXCEL.BOOLEAN.SELECTION.ERROR.MESSAGE' },
    trueValue: { id: 'IMPORT.CONFIRMATION.EXCEL.TRUE_VALUE' },
    falseValue: { id: 'IMPORT.CONFIRMATION.EXCEL.FALSE_VALUE' },
    dateTimeErrorTitle: { id: 'IMPORT.CONFIRMATION.EXCEL.DATE_TIME.ERROR.TITLE' },
    dateTimeErrorMessage: { id: 'IMPORT.CONFIRMATION.EXCEL.DATE_TIME.ERROR.MESSAGE' }
};
