import crypto from 'crypto';
import { IntlShape } from 'react-intl';
import { DOCUMENT_ITEM_TYPE, ItemNode, PROFILE } from '../types/types';
import { getLocalizedString, isTranslationStringExists } from '../i18n/i18Helper';
import { localizedReadableFullDate } from '../kit/utils/DateUtil.js';

export const UPLOAD_MAX_FILE_SIZE = 4000000; // 4MB

export const UPLOAD_TYPES = {
    EXCEL: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    TEMPLATES: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    SUPPORT: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/x-pkcs7-certreqresp', // for .p7m files
        'application/x-pkcs7-certificates', // for .p7m files
        'application/pkcs7-mime' // for .p7m files
    ],
    AUDITOR: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/x-pkcs7-certreqresp', // for .p7m files
        'application/x-pkcs7-certificates', // for .p7m files
        'application/pkcs7-mime', // for .p7m files
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    USER_RESOURCE: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ],
    PICTURES: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'],
    LETTERHEAD: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    PDF: ['application/pdf'],
    EXTERNAL_CLIENT: ['application/pdf']
};

export const FILE_SIZE = 160 * 1024;

export const checkMimeType = (files, fileTypes) => {
    let error = '';
    const types = fileTypes;

    let isValidMimeType = true;
    Array.prototype.forEach.call(files, file => {
        if (types.every(type => file.type !== type)) {
            error += file.type + ' File format error';
            isValidMimeType = false;
        }
    });

    error && console.log('checkMimeType', error);
    return isValidMimeType;
};

export const getHash = async file => {
    if (file) {
        const fileBinary = new Uint8Array(await file.arrayBuffer());
        const hash = crypto.createHash('sha512').update(fileBinary).digest('hex').toUpperCase();
        return hash;
    }
};

export const saveFileFromUrl = (url, fileName) => {
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
};

export const saveFileFromBlob = (blob, fileName) => {
    const url = URL.createObjectURL(blob);
    saveFileFromUrl(url, fileName);
};

export const saveDownloadedFile = (data, fileName) => {
    saveFileFromBlob(data.body, fileName);
};

export const printDownloadedFile = data => {
    const url = URL.createObjectURL(data.body);
    window.open(url)?.print();
};

export const formatGenerateFileRequest = (fileName, worksheetName, columnsNames, intl) => {
    let formatExcelTemplate = { fileName: fileName, worksheetName: worksheetName };
    const convertedColumnsNames = Object.keys(columnsNames)?.reduce((acc, column) => {
        const label = intl.formatMessage({ id: columnsNames[column].id });
        let current = { ...acc, [column]: label };
        const stringExists = isTranslationStringExists(intl, columnsNames[column].id.replace('.LABEL', '.COMMENT'));

        if (stringExists) {
            const comment = intl.formatMessage({
                id: columnsNames[column].id.replace('.LABEL', '.COMMENT')
            });

            current = { ...current, [column.replace('Index', 'Comment')]: comment };
        }

        return current;
    }, {});

    formatExcelTemplate = { ...formatExcelTemplate, ...convertedColumnsNames };
    return formatExcelTemplate;
};

// filter Header fields from base list of columns names
export const filterFileColumns = basedColumns => {
    return Object.keys(basedColumns)
        .filter(key => !key.includes('Header'))
        .reduce((obj, key) => {
            obj[key] = basedColumns[key];
            return obj;
        }, {});
};

const auditorItemDescription = (isFolder, intl) => {
    if (isFolder) {
        return intl.formatMessage({ id: 'ITEM.UPLOAD.DIRECTORY.AUDITOR.DESCRIPTION' });
    } else {
        return intl.formatMessage({ id: 'ITEM.UPLOAD.DOCUMENT.AUDITOR.DESCRIPTION' });
    }
};

const supportItemDescription = (isFolder, intl) => {
    if (isFolder) {
        return intl.formatMessage({ id: 'ITEM.UPLOAD.DIRECTORY.SUPPORT.DESCRIPTION' });
    } else {
        return intl.formatMessage({ id: 'ITEM.UPLOAD.DOCUMENT.SUPPORT.DESCRIPTION' });
    }
};

// TODO: may have an impact on multiple profile :: to verify
export const formatItemDescription = (intl, profile, isFolder = false) => {
    switch (profile) {
        case PROFILE.AUDITOR:
            return auditorItemDescription(isFolder, intl);

        case PROFILE.POSTIE:
            return supportItemDescription(isFolder, intl);

        default:
            return null;
    }
};

export const getDocumentLabelInfo = (node: ItemNode, intl: IntlShape) =>
    node.type === DOCUMENT_ITEM_TYPE.FOLDER && node.childItems
        ? getLocalizedString(intl, 'ITEM.FOLDER.NUMBER.OF.FILE.LABEL', { filesNumber: node.childItems.length || 0 })
        : '';

export const getDocumentDate = (node: ItemNode, intl: IntlShape) => {
    const getLabel = (prefix: string, date: string, fullName: string) => {
        return `${getLocalizedString(intl, prefix)} ${localizedReadableFullDate(intl, date)} ${
            fullName ? getLocalizedString(intl, 'ITEM.POPUP.INFORMATION.AUTHOR.BY', { author: fullName }) : ''
        }`;
    };

    return node?.created >= node?.updated
        ? getLabel('ITEM.POPUP.INFORMATION.DATE.CREATED', node.created, node.creator?.fullname)
        : getLabel('ITEM.POPUP.INFORMATION.DATE.UPDATED', node.updated, node.updator?.fullname);
};

export const getDocumentInvalidPostalAddress = (node: ItemNode, intl: IntlShape) => getLocalizedString(intl, 'ITEM.POPUP.INFORMATION.INVALID.ADDRESS', { date: localizedReadableFullDate(intl, node?.isInvalidAddressDate) });
