import { deepFreeze } from '../../helpers/FreezeHelper';
import { CLIENT_CONTACT_INVITATION_STATUS } from '../../types/types';

export const TITLE_LIST = [
    { id: 'EDIT_CONTACT.TITLE.MR' },
    { id: 'EDIT_CONTACT.TITLE.MRS' },
    { id: 'EDIT_CONTACT.TITLE.MISS' },
    { id: 'EDIT_CONTACT.TITLE.MS' }
];

const InitContact = {
    id: null,
    engagementCompanyId: null,
    jobTitle: '',
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    description: '',
    languageId: 'a10ee029-a60b-4929-bd8f-645a6501b37c', //TODO remove later, just for testing
    dialingCode: '',
    phone: '',
    clusterId: null,
    isSignatory: false,
    isRecipient: true,
    invitationStatus: { status: CLIENT_CONTACT_INVITATION_STATUS.NOT_INVITED, lastConnection: null }
};

export const initContact = deepFreeze(InitContact);
