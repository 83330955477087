import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { Palette } from '../../styles/colors';

export const UITooltip = styled(
    ({ className, children, wrap = false, ...props }: TooltipProps & { wrap?: boolean }) => (
        <Tooltip {...props} classes={{ popper: className }}>
            {wrap ? <div>{children}</div> : children}
        </Tooltip>
    )
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: Palette.whiteSmoke,
        boxShadow: theme.shadows[1],
        fontSize: '1em',
        color: Palette.primaryTextColor,
        zIndex: 1,
        padding: '0.6em'
    }
}));
