import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { COMPANY_TYPE } from '../../kit/utils/constants';
import { EditClientCompany, EditThirdPartyCompany } from '.';
import PropTypes from 'prop-types';

export function EditCompanyComponent(props) {
    const { company, handleSubmit, intl, companyType = COMPANY_TYPE.CLIENT } = props;
    const CompanyForms = {
        [COMPANY_TYPE.CLIENT]: EditClientCompany({ intl }),
        [COMPANY_TYPE.THIRDPARTY]: EditThirdPartyCompany({ intl })
    };

    const companyForm = CompanyForms[companyType];

    const buttonFormRef = useRef(null);
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    props.sbmtCompanyRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    return (
        <>
            <div className="form form-label-right">
                <Formik
                    enableReinitialize={true}
                    initialValues={company}
                    validationSchema={companyForm.yup}
                    onSubmit={values => handleSubmit(values)}>
                    {({ errors, touched }) => (
                        <>
                            <Form>
                                {React.Children.toArray(companyForm.fields.map(row => row.render()))}

                                <button
                                    type="submit"
                                    style={{ display: 'none' }}
                                    onClick={triggerButtonClick}
                                    ref={buttonFormRef}
                                />
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
}

export const EditCompany = injectIntl(EditCompanyComponent);

EditCompany.propTypes = {
    company: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    companyType: PropTypes.string.isRequired
};

EditCompany.defaultProps = {
    companyType: COMPANY_TYPE.CLIENT
};
