export const contact = {
    'CREATE_CLIENT.SECTION.CONTACT.SUBSECTION.FORM.TITLE': 'Create contact',
    'CREATE_CLIENT.SECTION.CONTACT.BUTTON.NEW_CONTACT': 'Create contact',
    'CREATE_CLIENT.SECTION.CONTACT.BUTTON.NEW_COMPANY': 'Create company',
    'CREATE_CLIENT.SECTION.CONTACT.BUTTON.EDIT_COMPANY': 'Edit company',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.INTERNALID': 'Forvis Mazars client code missing',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.NAME': 'Company Name missing',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.ADDRESS': 'Address missing',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.CITY': 'City missing',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.ZIPCODE': 'Postal code missing',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.COUNTRY': 'Country missing',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER': 'National identification number missing',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER2': 'National identification number 2 missing',
    'EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.VAT_ID_NUMBER': 'VAT number missing',
    'EDIT_CLIENT.LABEL.INTERNALID': 'Forvis Mazars client code',
    'EDIT_CLIENT.PLACEHOLDER.INTERNALID': 'Forvis Mazars client code',
    'EDIT_CLIENT.LABEL.NAME': 'Name',
    'EDIT_CLIENT.PLACEHOLDER.NAME': 'Company Name',
    'EDIT_CLIENT.LABEL.ADDRESS': 'Address',
    'EDIT_CLIENT.PLACEHOLDER.ADDRESS': 'Address',
    'EDIT_CLIENT.LABEL.CITY': 'City',
    'EDIT_CLIENT.PLACEHOLDER.CITY': 'City',
    'EDIT_CLIENT.LABEL.ZIPCODE': 'Postal code',
    'EDIT_CLIENT.PLACEHOLDER.ZIPCODE': 'Postal code',
    'EDIT_CLIENT.LABEL.COUNTRY': 'Country',
    'EDIT_CLIENT.PLACEHOLDER.COUNTRY': 'Country',
    'EDIT_CLIENT.LABEL.COMPANY_ID_NUMBER': 'Company identification number',
    'EDIT_CLIENT.PLACEHOLDER.COMPANY_ID_NUMBER': 'Company Identification Number',
    'EDIT_CLIENT.LABEL.COMPANY_ID_NUMBER2': 'Company identification number 2',
    'EDIT_CLIENT.PLACEHOLDER.COMPANY_ID_NUMBER2': 'Company identification number 2',
    'EDIT_CLIENT.LABEL.COMPANY_ID_NUMBER3': 'Company identification number 3',
    'EDIT_CLIENT.PLACEHOLDER.COMPANY_ID_NUMBER3': 'Company identification number 3',
    'EDIT_CLIENT.LABEL.VAT_ID_NUMBER': 'VAT number',
    'EDIT_CLIENT.PLACEHOLDER.VAT_ID_NUMBER': 'VAT number',
    'EDIT_CLIENT.LABEL.CONTACTS': 'Contacts',
    'CREATE_CONTACT.CONTACT.CREATE.SUCCESS': 'Contact created',
    'CREATE_CONTACT.CONTACT.CREATE.ERROR': 'Contact creation error',
    'CREATE_CONTACT.CONTACT.VALIDATION.REQUIRED_FIELD.CLIENT': 'Please filled out a company',
    'CREATE_CONTACT.CONTACT.VALIDATION.REQUIRED_FIELD.THIRDPARTY': 'Please filled out a third-party',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.COMPANY': 'Missing company',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.TITLE': 'Missing title',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.JOBTITLE': 'Missing job title',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.FIRSTNAME': 'Missing first name',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.LASTNAME': 'Missing last name',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.EMAIL': 'Missing email',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.DESCRIPTION': 'Missing description',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.COUNTRY': 'Missing country',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.CITY': 'Missing city',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'Missing LANGUAGE',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.DIALING_CODE': 'Missing dialing code',
    'EDIT_CONTACT.CREATE.VALIDATION.REQUIRED_FIELD.PHONE': 'Missing phone',
    'EDIT_CONTACT.LABEL.COMPANY': 'Company',
    'EDIT_CONTACT.PLACEHOLDER.COMPANY': 'Company name',
    'EDIT_CONTACT.LABEL.TITLE': 'Title',
    'EDIT_CONTACT.PLACEHOLDER.TITLE': 'Title',
    'EDIT_CONTACT.TITLE.MR': 'Mr',
    'EDIT_CONTACT.TITLE.MRS': 'Mrs',
    'EDIT_CONTACT.TITLE.MISS': 'Miss',
    'EDIT_CONTACT.TITLE.MS': 'Ms',
    'EDIT_CONTACT.TITLE.LAYER': 'Esquire',
    'EDIT_CONTACT.TITLE.DOCTOR': 'Dr',
    'EDIT_CONTACT.LABEL.JOBTITLE': 'Job title',
    'EDIT_CONTACT.PLACEHOLDER.JOBTITLE': 'Job title',
    'EDIT_CONTACT.LABEL.FULLNAME': 'Full name',
    'EDIT_CONTACT.LABEL.FIRSTNAME': 'First name',
    'EDIT_CONTACT.PLACEHOLDER.FIRSTNAME': 'First name',
    'EDIT_CONTACT.LABEL.LASTNAME': 'Last name',
    'EDIT_CONTACT.PLACEHOLDER.LASTNAME': 'Last name',
    'EDIT_CONTACT.LABEL.CITY': 'City',
    'EDIT_CONTACT.PLACEHOLDER.CITY': 'City',
    'EDIT_CONTACT.LABEL.COUNTRY': 'Country',
    'EDIT_CONTACT.PLACEHOLDER.COUNTRY': 'Country',
    'EDIT_CONTACT.LABEL.LANGUAGE': 'Language',
    'EDIT_CONTACT.PLACEHOLDER.LANGUAGE': 'Language',
    'EDIT_CONTACT.LABEL.EMAIL': 'Email',
    'EDIT_CONTACT.PLACEHOLDER.EMAIL': 'contact@company.com',
    'EDIT_CONTACT.LABEL.DESCRIPTION': 'Description',
    'EDIT_CONTACT.PLACEHOLDER.DESCRIPTION': 'Description',
    'EDIT_CONTACT.LABEL.DIALINGCODE': 'Dialing code',
    'EDIT_CONTACT.PLACEHOLDER.DIALINGCODE': 'Dialing code',
    'EDIT_CONTACT.LABEL.PHONE': 'Phone',
    'EDIT_CONTACT.PLACEHOLDER.PHONE': 'Phone',
    'EDIT_CONTACT.LABEL.DIALINGCODE2': 'Dialing code 2',
    'EDIT_CONTACT.PLACEHOLDER.DIALINGCODE2': 'Dialing code2',
    'EDIT_CONTACT.LABEL.PHONE2': 'Phone 2',
    'EDIT_CONTACT.PLACEHOLDER.PHONE2': 'Phone 2',
    'CONTACT_SECTION.SUBSECTION.CONTACTS.CLIENT': 'Entity contacts',
    'CONTACT_SECTION.SUBSECTION.CONTACTS.THIRDPARTY': 'Third-party contacts',
    'CONTACT_SECTION.SELECT.CONTACT_COMPANY.LABEL': 'Select contact company',
    'CONTACT_SECTION.SELECT.CONTACT.LABEL': 'Select contacts',
    'CONTACT_SECTION.BUTTON.NEW.LABEL': 'Create',
    'CONTACT_SECTION.BUTTON.SAVE.LABEL': 'Save',

    'CONTACT.ACCESS.CONTACT.ERROR': 'Contact access error',
    'CONTACT.CREATE.SUCCESS': 'Contact created',
    'CONTACT.CREATE.ERROR': 'Contact creation error',
    'CONTACT.UPDATE.SUCCESS': 'Contact updated',
    'CONTACT.UPDATE.ERROR': 'Contact update error',
    'CONTACT.SECTION.BUTTON.CREATE': 'Create contact',

    'CONTACT.INVITATION.NOTINVITED': 'Not invited',
    'CONTACT.INVITATION.NOTCONNECTED': 'Invited - never connected',
    'CONTACT.INVITATION.CONNECTED': 'Invited - last access {date}'
};
