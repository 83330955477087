import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import * as actions from '../../store/actions';
import './engagement.scss';
import { withCheckSession, withEngagement } from '../../enhancers';
import { DocumentPendingComponent } from '../../components/document';
import { PROFILE } from '../../types/types';
import { fetchEngagementConfirmations, ConfirmationService, ItemService } from '../../services';
import { saveDownloadedFile } from '../../helpers';
import { INIT_ENGAGEMENT_FILTER, INIT_ITEMS_FILTER, SORT_CHOICES } from '../../kit/utils/constants';
import { EngagementContainer } from '../../components/engagement';
import { ENGAGEMENT_COLUMNS_FILTERS } from '../../components/engagement/InitEngagement';
import { fetchData } from '../../services/FetchHelper';
import * as engagementService from '../../services/EngagementService';
import {
    CONFIRMATION_COLUMNS_FILTERS,
    INIT_MENU_CONFIRMATION_FILTERS
} from '../../components/confirmation/common';

/***
 * Clean store filters in case the engagement has been changed
 * @param storedFilters: stored filters in redux
 * @param currentEngagementId: the current displayed engagement
 * @param cleanFilters: method that flushed the stored filters
 * @return the updated filters
 */
const getCleanedFilters = (storedFilters, currentEngagementId, cleanFilters) => {
    let confirmationFilters = { ...storedFilters };

    if (!!storedFilters.engagementId && storedFilters.engagementId !== currentEngagementId) {
        cleanFilters();
        confirmationFilters = {};
    }

    return confirmationFilters;
};

/**
 * Engagement page component for user with auditor role.
 *
 * This engagement page give users an overview on their confirmations created.
 * @public
 */
class AuditorEngagementComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkSessionResults: [],
            menuEngagements: null, // list of engagements displayed in the engagement dropdown of pending documents
            menuConfirmationsFilters: {
                // for pending documents accordion
                ...INIT_MENU_CONFIRMATION_FILTERS,
                [CONFIRMATION_COLUMNS_FILTERS.CLUSTER_ID]: props.currentClusterId,
                [CONFIRMATION_COLUMNS_FILTERS.ENGAGEMENT_ID]: props.engagementId
            },
            paginatedItems: [], // pending items
            confirmationsFilters: {
                ...INIT_ENGAGEMENT_FILTER,
                engagementId: this.props?.engagementId,
                isTrashed: false,
                ...getCleanedFilters(
                    props.reduxConfirmationsFilters,
                    props.engagementId,
                    props.flushConfirmationsFilters
                )
            },
            itemsFilters: {
                userprofile: PROFILE.AUDITOR,
                pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
                pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
                sort: SORT_CHOICES.UPDATED_DESC,
                displayMode: INIT_ITEMS_FILTER.DISPLAY_MODE_ROOT_ITEM_ONLY,
                engagementId: this.props?.engagementId,
                confirmationId: INIT_ITEMS_FILTER.CONFIRMATION_ID,
                isLost: INIT_ITEMS_FILTER.IS_LOST
            },
            docTypesFilters: {
                userprofile: PROFILE.AUDITOR
            },
            loading: false
        };

        this.confirmationService = ConfirmationService({
            intl: this.props.intl,
            user: this.props.user,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading,
            callback: async isSuccess => {
                isSuccess && (await this.performFetchConfirmations());
            }
        });

        this.isFilteringMode =
            !!this.state?.confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.CLIENT] ||
            !!this.state?.confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY] ||
            !!this.state?.confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.CATEGORY] ||
            !!this.state?.confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION];
    }

    async componentDidMount() {
        const { itemsFilters, docTypesFilters } = this.state;

        await fetchData(this, [
            () => this.performFetchConfirmations(),
            () => this.fetchItems(itemsFilters),
            () => this.fetchDocumentTypes(docTypesFilters)
        ]);
    }

    async componentDidUpdate(prevProps, prevState, snapShot) {
        if (this.state.confirmationsFilters !== prevState.confirmationsFilters && !this.state.loading) {
            await this.performFetchConfirmations();
            //save the filters to redux
            this.props.persistConfirmationsFilters(this.state.confirmationsFilters);
        }

        if (this.state.itemsFilters !== prevState.itemsFilters) {
            if (!this.state.loading) await this.fetchItems(this.state.itemsFilters);
        }
    }

    setLoading = isLoading => {
        this.setState({ loading: isLoading });
    };

    setErrorMessage = errorMessage => {
        this.setState({ errorMessage: errorMessage });
    };

    itemManager = ItemService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        callback: async (isSuccess, _data, _) => isSuccess && (await this.fetchItems(this.state.itemsFilters)),
        setLoading: this.setLoading,
        setErrorMessage: this.setErrorMessage
    });

    fetchItems = async filters => {
        const result = await this.itemManager.fetch(filters);
        if (result?.isSuccess) {
            this.setState({ paginatedItems: result.parsedValue });
        }
    };

    downloadItem = async item => {
        await this.itemManager.download(item, (isSuccess, data, _) => isSuccess && saveDownloadedFile(data, item.name));
    };

    updateItem = async item => {
        return await this.itemManager.update({ ...item, userProfile: PROFILE.AUDITOR });
    };

    fetchDocumentTypes = async filters => {
        const result = await this.itemManager.getDocumentTypes(filters);
        if (result?.isSuccess) {
            this.setState({ documentTypes: result?.body });
        }
    };

    handleConfirmationFilterChange = values => {
        this.setState({ confirmationsFilters: { ...this.state.confirmationsFilters, ...values } });
    };

    handleItemsFilterChange = values => {
        this.setState({ itemsFilters: { ...this.state.itemsFilters, ...values } });
    };

    performFetchConfirmations = async () => {
        const { intl, checkSession, addToastMessage, engagementId } = this.props;
        const { confirmationsFilters } = this.state;

        await fetchEngagementConfirmations({
            filters: confirmationsFilters,
            engagementId: engagementId,
            checkSession,
            addToastMessage,
            errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR' }),
            setLoading: this.setLoading,
            callback: (isSuccess, data) => {
                if (isSuccess) {
                    this.setState({ confirmationPaginated: data.parsedValue });
                } else {
                    const checkSessionResults = [
                        ...this.state.checkSessionResults,
                        {
                            sessionStatus: data.status,
                            technicalMessage: data?.error,
                            errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR' })
                        }
                    ];

                    this.setState({ checkSessionResults: checkSessionResults });
                }
            }
        });
    };

    performDeletePermanentConfirmation = async confirmationInfo => {
        await this.confirmationService.deletePermanently(confirmationInfo);
    };

    // Put the confirmation in the recycle bin or restore it
    performTrashConfirmation = async (confirmationInfo, isTrashed) => {
        await this.confirmationService.trash(confirmationInfo, isTrashed);
    };

    handleDeleteEngagement = async engagementInfo => {
        const { performDeleteEngagement } = this.props;
        return performDeleteEngagement({
            engagementInfo: engagementInfo,
            setLoading: this.setLoading
        });
    };

    handleArchiveEngagement = async ({ engagement: { id, archived } }) => {
        const { performToggleArchiveEngagement } = this.props;
        const result = await performToggleArchiveEngagement({
            engagementId: id,
            setLoading: this.setLoading,
            strings: {
                successMessageId: `FETCH.ENGAGEMENT.${!archived ? 'ARCHIVE' : 'UNARCHIVE'}.SUCCESS`,
                errorMessageId: `FETCH.ENGAGEMENT.${!archived ? 'ARCHIVE' : 'UNARCHIVE'}.ERROR`
            }
        });

        return result;
    };

    handleDownloadEngagement = async (engagement, downloadType) => {
        const { performDownloadEngagement } = this.props;
        return performDownloadEngagement({
            downloadType: downloadType,
            engagementId: engagement.id,
            setLoading: this.setLoading,
            engagementName: engagement.name
        });
    };

    handleFetchMenuEngagements = async () => {
        await engagementService.fetchMenuEngagements(this);
    };

    fetchMenuConfirmations = async values => {
        const { currentClusterId } = this.props;
        const filters = { ...this.state.menuConfirmationsFilters, clusterId: currentClusterId, ...values };

        let menuConfirmations = [];
        await this.confirmationService.fetchMenu(filters, (isSuccess, result) => {
            if (isSuccess) {
                menuConfirmations = result.parsedValue;
            }
        });

        this.setState({ menuConfirmationsFilters: filters });
        return menuConfirmations;
    };

    render() {
        const {
            user,
            getDownloadEngagementOptions,
            resetActiveStepPosition,
            engagement,
            confirmationCategories,
            workflowActions,
            saveSelectedConfirmationIndex
        } = this.props;
        const { loading, confirmationPaginated, confirmationsFilters } = this.state;

        return (
            <>
                <DocumentPendingComponent
                    engagementId={this.props.engagementId}
                    shouldPopulateMenuConfirmations={!!this.props.engagementId}
                    fetchMenuEngagement={this.handleFetchMenuEngagements}
                    handleFetchMenuConfirmations={this.fetchMenuConfirmations}
                    user={this.props.user}
                    actionProfile={PROFILE.AUDITOR}
                    loading={this.state.loading}
                    paginatedItems={this.state.paginatedItems}
                    menuEngagements={this.state.menuEngagements}
                    documentTypes={this.state.documentTypes}
                    fetchItemFile={this.itemManager.fetchFile}
                    fetchItemInfo={this.itemManager.fetchInfo}
                    updateItem={this.updateItem}
                    downloadItem={this.downloadItem}
                    handleFilterItems={this.handleItemsFilterChange}
                />

                <EngagementContainer
                    saveSelectedConfirmationIndex={saveSelectedConfirmationIndex}
                    isFilteringMode={this.isFilteringMode}
                    user={user}
                    deleteEngagement={this.handleDeleteEngagement}
                    archiveEngagement={this.handleArchiveEngagement}
                    deleteConfirmation={info => this.performTrashConfirmation(info, true)}
                    restoreConfirmation={info => this.performTrashConfirmation(info, false)}
                    eraseConfirmation={this.performDeletePermanentConfirmation}
                    downloadEngagement={this.handleDownloadEngagement}
                    getDownloadEngagementOptions={getDownloadEngagementOptions}
                    resetActiveStepPosition={resetActiveStepPosition}
                    loading={loading}
                    engagement={engagement}
                    confirmationPaginated={confirmationPaginated}
                    filters={confirmationsFilters}
                    handleFilterChange={this.handleConfirmationFilterChange}
                    confirmationCategories={confirmationCategories}
                    workflowActions={workflowActions}
                />
            </>
        );
    }
}

AuditorEngagementComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    paginatedItems: PropTypes.array,
    checkSessionResults: PropTypes.array
};

AuditorEngagementComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    },
    resetActiveStepPosition: () => {
        dispatch(actions.resetActiveStepPosition());
    },
    persistConfirmationsFilters: filters => {
        dispatch(actions.setConfirmationsFilters(filters));
    },
    flushConfirmationsFilters: () => {
        dispatch(actions.resetConfirmationsFilters());
    },
    saveSelectedConfirmationIndex: index => {
        dispatch(actions.setSelectedConfirmationIndex(index));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        reduxConfirmationsFilters: state.engagement.confirmationsFilters,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const AuditorEngagement = compose(
    injectIntl,
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(withEngagement(AuditorEngagementComponent));
