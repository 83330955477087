import { deepFreeze } from '../../../helpers/FreezeHelper';

const InitUserResourceConfig = {
    clusterId: null,
    name: null,
    description: null,
    eUserResourceType: 0,
    languageId: null,
    url: null,
    file: null
};

export const initUserResourceConfig = deepFreeze(InitUserResourceConfig);

export const E_USER_RESOURCE_TYPES = {
    FILE: 1,
    LINK: 2,
    VIDEO_LINK: 3,
    TEXT: 4
};
