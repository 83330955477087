export const support = {
    'SUPPORT.FILTER.PROCESS.PAPER.LABEL': 'Confirmation requests',
    'SUPPORT.FILTER.PROCESS.PAPER.TOOLTIP': 'Documents to send',
    'SUPPORT.FILTER.DOCUMENT.RECEIVED.LABEL': 'Confirmation answers',
    'SUPPORT.FILTER.DOCUMENT.RECEIVED.TOOLTIP': 'Documents to dispatch',
    'SUPPORT.BUTTON.LABEL.UPLOAD': 'Upload scanned letters',
    'SUPPORT.CHECKBOX.UPLOAD.GROUPED.LABEL': 'Group multiple files',
    'SUPPORT.CHECKBOX.UPLOAD.GROUPED.TOOLTIP': 'Upload files from the same confirmation within a folder',
    'COUNTRY_SUPPORT.TITLE': 'Country Support',
    'SUPPORT.BUTTON.CLOSE.TRASH.LABEL': 'Close recycle bin',
    'SUPPORT.SEARCH.PLACEHOLDER': 'Search by name or comment'
};
