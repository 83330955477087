import { IntlShape } from 'react-intl';
import { getLocalizedString } from '../i18n/i18Helper';
import { CLIENT_REQUEST_STATUS, ClientRequest, ClientRequestStep } from '../types/types';

export const LetterheadOptions: { label: string; id: boolean }[] = [
    {
        label: 'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.ON',
        id: true
    },
    {
        label: 'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.OFF',
        id: false
    }
];

export const getDownloadOptions = (
    intl: IntlShape,
    request: ClientRequest,
    handleDownload: (request: ClientRequest) => void,
    handleUpload: (request: ClientRequest) => void
) => [
    {
        id: 'Download',
        title: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.TABLE.ACTION.OPTION.DOWNLOAD'),
        handler: () => handleDownload(request)
    },
    {
        id: 'upload',
        title: getLocalizedString(intl, 'CLIENT.ENGAGEMENT.TABLE.ACTION.OPTION.UPLOAD'),
        handler: () => handleUpload(request)
    }
];

export const getStepsStatus = (steps: ClientRequestStep[]) => {
    return steps.reduce((acc, step) => ({ ...acc, [step.type]: step.isCompleted }), {});
};

export function getSteps(steps: ClientRequestStep[]) {
    return steps.map(step => ({
        ...step,
        key: step.type
    }));
}

export const allStepsCompleted = (steps: ClientRequestStep[]) => {
    return !steps.find(step => !step.isCompleted);
};

export const requestActionsReducer = (request: ClientRequest, user: any): string[] => {
    if ([CLIENT_REQUEST_STATUS.REFUSED, CLIENT_REQUEST_STATUS.NOT_STARTED].includes(request.clientRequestStatus)) {
        return ['complete', 'refuse'];
    } else if (request.clientRequestStatus === CLIENT_REQUEST_STATUS.WAITING_AUDITOR_VALIDATION) {
        return [];
    } else if (request.clientRequestStatus === CLIENT_REQUEST_STATUS.WAITING_SIGNATURE) {
        let actions = ['open', 'download', 'refuse'];
        if (isSignableRequest(request, user)) {
            actions = [...actions, 'sign'];
        }

        return actions;
    } else return [];
};

export const isSignableRequest = (request: ClientRequest, user: any): boolean => {
    const currentUser = request.clientContacts.find(contact => contact.email === user.email) as {
        email: string;
        isSignatory: boolean;
    };

    return (
        request.clientRequestStatus === CLIENT_REQUEST_STATUS.WAITING_SIGNATURE &&
        !!currentUser?.isSignatory &&
        request.isLetterGenerated
    );
};

export const ClientRequestStatusOptions: { label: string; id: CLIENT_REQUEST_STATUS }[] = [
    {
        label: 'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.NOT_STARTED',
        id: CLIENT_REQUEST_STATUS.NOT_STARTED
    },
    {
        label: 'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.REFUSED',
        id: CLIENT_REQUEST_STATUS.REFUSED
    },
    {
        label: 'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.WAITING_AUDITOR_VALIDATION',
        id: CLIENT_REQUEST_STATUS.WAITING_AUDITOR_VALIDATION
    },
    {
        label: 'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.WAITING_SIGNATURE',
        id: CLIENT_REQUEST_STATUS.WAITING_SIGNATURE
    }
];
