import { useIntl } from 'react-intl';
import React from 'react';
import { MZRSelectEngagementOption, MZRValueSelectedContainer } from '../../../helpers';
import { MZRSearchBar } from '../../common/MZRSearchBar';

export const EngagementSelect = (props: any) => {
    const intl = useIntl();
    const { clientCompaniesVisible = true, handleChange, handleDelete } = props;

    const getOptionValue = (option: any) => {
        let value = `${option.name ?? ''} ${option.engagementCode ?? ''}`;

        return clientCompaniesVisible
            ? `${value} ${Object.values(option?.clientCompanies).map(
                  (company: any) =>
                      `${company.name} ${company.address} ${company.zipCode} ${company.city} ${company.countryText}`
              )}`.toLocaleLowerCase()
            : value;
    };

    const handleChangeValue = (selectedValue: any) => {
        props.onChange ? props.onChange(selectedValue) : handleChange(selectedValue);
    };

    return (
        <>
            {!props.hidden && (
                <MZRSearchBar
                    {...props}
                    fullWidth
                    id={props.id ?? props.name}
                    name={props.name}
                    isAreaFocusVisible={false}
                    clientCompaniesVisible={false}
                    components={{
                        Option: MZRSelectEngagementOption,
                        ValueContainer: MZRValueSelectedContainer
                    }}
                    disabled={props.disabled}
                    options={props.options}
                    onSelectionChange={handleChangeValue}
                    isClearable={true}
                    onClear={handleDelete}
                    getOptionValue={getOptionValue}
                    placeholder={intl.formatMessage({
                        id: 'DOCUMENT.SELECT.ENGAGEMENT.SEARCH_BAR.PLACEHOLDER'
                    })}
                />
            )}
        </>
    );
};
