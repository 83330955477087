import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../partials/controls/Card';
import { FormattedMessage } from 'react-intl';
import { HighlightOffRounded as HighlightOffRoundedIcon } from '@mui/icons-material';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UIActionButton } from '../../../common/UIActionButton';
import { getLocalizedString } from '../../../../i18n/i18Helper';
import { toolbarStyle } from '../../../../styles/toolbar/ToolbarStyle';
import { MZRSearchBar } from '../../../common/MZRSearchBar';
import { Box } from '@mui/material';

const FILTER = {
    CATEGORY: 'confirmationCategoryId',
    LANGUAGE: 'languageId'
};

const getOptionLabel = category => `${category.name ?? ''}`;
const getOptionValue = option => option.id;

export function LetterTemplateFilters(props) {
    const { handleChange, handleResetFilters, confirmationCategories, languages, templates, intl } = props;

    const [categoryFilter, setCategoryFilter] = useState(null);
    const [languageFilter, setLanguageFilter] = useState(null);

    const applyFilters = (categoryId, languageId) => {
        const templateFiltered = templates
            ?.filter(x => (!!categoryId ? x.confirmationCategoryId === categoryId : true))
            ?.filter(x => (!!languageId ? x.languageId === languageId : true));
        return templateFiltered;
    };

    /**
     * Updates the filters and submit the result to the parent handleChange method
     * @param filterName: name of the filter @seealso FILTER.LANGUAGE ans FILTER.CATEGORY
     * @param filterValueObject the id of the filter ex category.id or language.id
     */
    const handleChangeFilter = filterName => filterValueObject => {
        let selectionTemplatesUpdate = [];

        switch (filterName) {
            case FILTER.CATEGORY:
                selectionTemplatesUpdate = [...applyFilters(filterValueObject?.id, languageFilter?.id)];
                setCategoryFilter(filterValueObject);
                break;

            case FILTER.LANGUAGE:
                selectionTemplatesUpdate = [...applyFilters(categoryFilter?.id, filterValueObject?.id)];
                setLanguageFilter(filterValueObject);
                break;

            default:
                break;
        }

        handleChange({ [filterName]: filterValueObject?.id }, selectionTemplatesUpdate);
    };

    const handleClearFilters = () => {
        setCategoryFilter(null);
        setLanguageFilter(null);
        handleResetFilters();
    };

    return (
        <>
            <Card>
                <CardHeader title={<FormattedMessage id="CREATE_CONFIRMATION.SELECT_TEMPLATE.SECTION.FILTER.LABEL" />}>
                    <CardHeaderToolbar>
                        <UIActionButton
                            sx={toolbarStyle.button}
                            startIcon={<HighlightOffRoundedIcon />}
                            display="secondary"
                            title={getLocalizedString(
                                intl,
                                'CREATE_CONFIRMATION.SELECT_TEMPLATE.BUTTON.CLEAR.FILTER.LABEL'
                            )}
                            onClick={handleClearFilters}
                        />
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Box
                        sx={{
                            mx: 15,
                            display: 'grid',
                            flexWrap: 'wrap',
                            gap: 1,
                            gridTemplateColumns: 'repeat(2, 1fr)'
                        }}>
                        <MZRSearchBar
                            isAreaFocusVisible={false}
                            value={categoryFilter}
                            getOptionValue={getOptionValue}
                            getOptionLabel={getOptionLabel}
                            id={FILTER.CATEGORY}
                            name={FILTER.CATEGORY}
                            options={confirmationCategories}
                            onSelectionChange={handleChangeFilter(FILTER.CATEGORY)}
                            placeholder={getLocalizedString(
                                intl,
                                'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.TYPE.LABEL'
                            )}
                        />

                        <MZRSearchBar
                            isAreaFocusVisible={false}
                            value={languageFilter}
                            getOptionValue={getOptionValue}
                            getOptionLabel={getOptionLabel}
                            id={FILTER.LANGUAGE}
                            name={FILTER.LANGUAGE}
                            options={languages}
                            onSelectionChange={handleChangeFilter(FILTER.LANGUAGE)}
                            placeholder={getLocalizedString(
                                intl,
                                'CREATE_CONFIRMATION.SELECT_TEMPLATE.SELECT.LANGUAGE.LABEL'
                            )}
                        />
                    </Box>
                </CardBody>
            </Card>
        </>
    );
}

LetterTemplateFilters.propTypes = {
    /**
     * Function to handle filter changes
     */
    handleChange: PropTypes.func.isRequired,

    /**
     * Function to reset filters
     */
    handleResetFilters: PropTypes.func.isRequired,

    /**
     * The list of confirmation categories to filter on
     */
    confirmationCategories: PropTypes.array,

    /**
     * The list of languages to filter on
     */
    languages: PropTypes.array.isRequired,

    /**
     * The list of templates to filter
     */
    templates: PropTypes.array
};
