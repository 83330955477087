export const cardFooterStyle = {
    container: {
        m: 1,
        gap: 1,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    button: { minWidth: '100px' }
};
