import { deepFreeze } from '../../helpers/FreezeHelper';

const InitCompany = {
    engagementId: null,
    internalId: '',
    name: '',
    address: '',
    city: '',
    zipCode: '',
    companyId: '',
    companyId2: '',
    vatId: '',
    clusterId: null,
    countryText: '',
    isClient: false,
    isThirdParty: false,
    contacts: []
};

export const initCompany = deepFreeze(InitCompany);
