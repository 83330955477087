export const engagement = {
    'ENGAGEMENT.TITLE': 'Engagement',
    'ENGAGEMENT.ERROR': 'Error loading engagement',
    'ENGAGEMENT.DOWNLOAD.OPTION.CLIENT_REQUEST_LETTERS': "Clients' letters",
    'ENGAGEMENT.DOWNLOAD.OPTION.FILES': 'All documents',
    'ENGAGEMENT.DOWNLOAD.OPTION.REPORTING': 'Reporting',
    'ENGAGEMENT.CARDHEADER.TITLE': 'Engagement {name}',
    'ENGAGEMENT.CARDHEADER.RECYCLE.BIN.TITLE': 'Recycle Bin of Engagement {engagementName}',
    'ENGAGEMENT.BUTTON.BACK.LABEL': 'Back',
    'ENGAGEMENT.BUTTON.RECYCLE.BIN.LABEL': 'Recycle Bin',
    'ENGAGEMENT.BUTTON.DELETE.LABEL': 'Delete',
    'ENGAGEMENT.BUTTON.ARCHIVE.LABEL': 'Archive',
    'ENGAGEMENT.BUTTON.DOWNLOAD.LABEL': 'Download',
    'ENGAGEMENT.BUTTON.SETTINGS.LABEL': 'Settings',
    'ENGAGEMENT.BUTTON.NEW.LABEL': 'New confirmation',
    'ENGAGEMENT.BUTTON.SIGN.LABEL': 'Sign selected',
    'ENGAGEMENT.CONFIRMATION.LOADING.ERROR': 'Error loading confirmations',
    'ENGAGEMENT.TABLE.ACTION.TOOLTIP.NEW_CONFIRMATION': 'Create new confirmation',
    'ENGAGEMENT.TABLE.ACTION.TOOLTIP.SETTINGS': 'Settings',
    'ENGAGEMENT.TABLE.FILTER.CLIENT.PLACEHOLDER': 'Entity',
    'ENGAGEMENT.TABLE.FILTER.THIRD_PARTY.PLACEHOLDER': 'Third-party',
    'ENGAGEMENT.TABLE.FILTER.CATEGORY.PLACEHOLDER': 'Category',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.PLACEHOLDER': 'Workflow action',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.PLACEHOLDER': 'Status',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.NOT_STARTED': 'Not started',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.IN_PROGRESS': 'In Progress',
    'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.VALIDATED': 'Validated',
    'ENGAGEMENT.TABLE.FILTER.CLEAR': 'Clear filters',
    'ENGAGEMENT.TABLE.FILTER': 'Filter',
    'ENGAGEMENT.TABLE.TITLE.CONFIRMATION': 'Confirmation',
    'ENGAGEMENT.TABLE.TITLE.CODE': 'Code',
    'ENGAGEMENT.TABLE.TITLE.THIRDPARTY': 'Third-Party',
    'ENGAGEMENT.TABLE.TITLE.CATEGORY': 'Category',
    'ENGAGEMENT.TABLE.TITLE.LETTERHEAD': 'Letterhead',
    'ENGAGEMENT.TABLE.TITLE.UNKNOWN_CATEGORY': 'Unknown',
    'ENGAGEMENT.TABLE.TITLE.CLIENT': 'Entity',
    'ENGAGEMENT.TABLE.PLACEHOLDER.THIRD_PARTY': 'Third-party not provided',
    'ENGAGEMENT.TABLE.PLACEHOLDER.CLIENT': 'Company not provided',
    'ENGAGEMENT.TABLE.TITLE.LAST_UPDATE': 'Last update',
    'ENGAGEMENT.TABLE.TITLE.PROGRESS': 'Progress',
    'ENGAGEMENT.ADD.CLIENT.ERROR.MESSAGE': 'Company already exists within the engagement',
    'ENGAGEMENT.ADD.CLIENT.CONTACT.ERROR.MESSAGE': 'Contact already exists in this company',
    'CREATE_ENGAGEMENT.TITLE': 'Create engagement',
    'CREATE_ENGAGEMENT.STEP.INFORMATION.LABEL': 'Engagement information',
    'CREATE_ENGAGEMENT.STEP.CLIENTS.LABEL': "Select engagement's entities",
    'CREATE_ENGAGEMENT.STEP.CONTACTS.LABEL': "Select entities' contacts",
    'CREATE_ENGAGEMENT.STEP.AUDITORS.LABEL': "Add engagement's audit team",
    'CREATE_ENGAGEMENT.STEP.OPTIONAL.INFO.LABEL': 'Options',
    'CREATE_ENGAGEMENT.BUTTON.BACK.LABEL': 'Back',
    'CREATE_ENGAGEMENT.BUTTON.PREVIOUS.LABEL': 'Previous',
    'CREATE_ENGAGEMENT.BUTTON.RESET.LABEL': 'Reset',
    'CREATE_ENGAGEMENT.BUTTON.SAVE.LABEL': 'Save',
    'CREATE_ENGAGEMENT.BUTTON.NEXT.LABEL': 'Next',
    'CREATE_ENGAGEMENT.NEW_ENGAGEMENT.ERROR': 'Engagement creation error',
    'CREATE_ENGAGEMENT.NEW_ENGAGEMENT.SUCCESS': 'Engagement created',
    'EDIT.ENGAGEMENT.FIELD.NAME.LABEL': 'Engagement Name',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.NAME': 'Engagement name is required',
    'EDIT.ENGAGEMENT.FIELD.ENGAGEMENT_CODE.LABEL': 'Forvis Mazars Engagement Code',
    'EDIT.ENGAGEMENT.FIELD.ENGAGEMENT_CODE.TOOLTIP': 'Forvis Mazars Project Code used in planning',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE': 'Project code is required',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE.FORMAT': 'Project code format is not valid',
    'EDIT.ENGAGEMENT.FIELD.ENGAGEMENT_CODE.DESCRIPTION.LABEL': 'Engagement code: {customMessage}',
    'EDIT.ENGAGEMENT.FIELD.OPENING_DATE.LABEL': 'Opening Date',
    'EDIT.ENGAGEMENT.FIELD.OPENING_DATE.DESCRIPTION.LABEL':
        '<!> Changing opening date, automatically updates the closing date by adding 1 year',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.OPENING_DATE': 'Opening date is required',
    'EDIT.ENGAGEMENT.FIELD.CLOSING_DATE.LABEL': 'Closing Date',
    'EDIT.ENGAGEMENT.VALIDATION.REQUIRED_FIELD.CLOSING_DATE': 'Closing date is required',
    'EDIT.ENGAGEMENT.VALIDATION.CLOSING.DATE.AFTER.OPENING.DATE': 'Closing date must be after opening date',
    'EDIT.ENGAGEMENT.FIELD.DESCRIPTION.LABEL': 'Description',
    'CONFIRMATION.ACCESS.COMMENTS.ERROR': 'Confirmation comments access error',
    'CONFIRMATION.MOVE.SUCCESS': 'Confirmation moved to next step with success',
    'CONFIRMATION.MOVE.ERROR': 'Confirmation move to next step error',
    'CONFIRMATION.STACKED_STEP.SUCCESS': 'Step selection with success',
    'CONFIRMATION.STACKED_STEP.ERROR': 'Step selection error',
    'FETCH.ENGAGEMENT.USER.ACCESS.ERROR': 'Engagement access error',
    'FETCH.ENGAGEMENT.LIST.ACCESS.ERROR': 'Engagements list access error',
    'FETCH.ENGAGEMENT.MENU.ACCESS.ERROR': 'Engagements list for menu, access error',
    'FETCH.ENGAGEMENT.ITEMS.ACCESS.ERROR': 'Engagement items access error',
    'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR': 'Engagement confirmations access error',
    'FETCH.ENGAGEMENT.ARCHIVE.SUCCESS': 'Engagement archived',
    'FETCH.ENGAGEMENT.UNARCHIVE.SUCCESS': 'Engagement unarchived',
    'FETCH.ENGAGEMENT.ARCHIVE.ERROR': 'Engagement archiving failed',
    'FETCH.ENGAGEMENT.DELETE.SUCCESS': 'Engagement deleted',
    'FETCH.ENGAGEMENT.DOWNLOAD.ERROR': 'Engagement download failed',
    'FETCH.ENGAGEMENT.DELETE.ERROR': 'Engagement deleting failed',
    'FETCH.ENGAGEMENT.ALL.DOCUMENTS.FILE.NAME': 'Engagement_{engagement}_documents.zip',
    'FETCH.ENGAGEMENT.REPORTING.FILE.NAME': 'Engagement_{engagement}_reporting.xlsx',
    'FETCH.ENGAGEMENT.DOWNLOAD.CLIENT.LETTERS.NAME': 'Engagement_{engagement}_client_letters.zip',
    'FETCH.CONFIRMATION.TYPE.ACCESS.ERROR': 'Confirmation types access error',
    'SEARCH.TABLE.TITLE.NAME': 'Name',
    'SEARCH.TABLE.TITLE.CODE': 'Engagement code',
    'SEARCH.TABLE.DIALOG.TITLE': 'Search engagement',
    'CREATE.ENGAGEMENT.COPY.EXISTING': 'Copy existing engagement',
    'CREATE.ENGAGEMENT.CHECKBOX.COPY.LETTERHEADS.LABEL': 'Copy entities letterheads',
    'CREATE.ENGAGEMENT.CHECKBOX.COPY.LETTERHEADS.TOOLTIP': "Copy entities' letterheads from the selected engagement",
    'CREATE.ENGAGEMENT.CHECKBOX.COPY.THIRD_PARTY.LABEL': 'Copy third-party companies and contacts',
    'CREATE.ENGAGEMENT.CHECKBOX.COPY.THIRD_PARTY.TOOLTIP':
        'Copy third-party companies and contacts from the selected engagement',
    'CREATE.ENGAGEMENT.PERIOD.TOO.LARGE':
        "Engagements don't usually exceed {months} months. Please double-check the dates to ensure correctness.",
    'ENGAGEMENT.TABLE.PENDING.DOCUMENTS.INDICATOR.TOOLTIP':
        'There are documents pending in this confirmation, please have a look ...',
    'ENGAGEMENT.TABLE.TITLE.PENDING.DOCUMENTS': 'Pending documents',
    'SETTINGS.ARCHIVE.ENGAGEMENT.ERROR': 'Archiving engagement failed',
    'SETTINGS.UNARCHIVE.ENGAGEMENT.ERROR': 'Unarchiving engagement failed'
};
