import { confirmationsRepository, serializeConfirmation } from '../kit/repository';
import { performAPIAction } from './BaseService';
import { cleanFilters } from '../helpers/EngagementHelper';

const confirmationMethods = ({ callback: globalCallback, ...rest }) => {
    return {
        fetch: (confirmationId, requestCallBack) =>
            performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.fetch(confirmationId),
                errorMessageId: 'CONFIRMATION.ACCESS.CONFIRMATION.ERROR',
                callback: requestCallBack || globalCallback
            }),

        create: (confirmation, requestCallBack) =>
            performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.create([serializeConfirmation(confirmation)]),
                errorMessageId: 'MANUAL.CREATE.CONFIRMATION.CREATE.ERROR',
                successMessageId: 'MANUAL.CREATE.CONFIRMATION.CREATE.SUCCESS',
                callback: requestCallBack || globalCallback
            }),

        update: (confirmation, isSuccessMessageDisplayed, requestCallBack) =>
            performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.update([serializeConfirmation(confirmation)]),
                errorMessageId: 'CONFIRMATION.UPDATE.ERROR',
                successMessageId: isSuccessMessageDisplayed ? 'CONFIRMATION.UPDATE.SUCCESS' : null,
                callback: requestCallBack || globalCallback
            }),
        deletePermanently: (confirmationInfo, requestCallBack) =>
            performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.deletePermanently(
                    confirmationInfo.id,
                    confirmationInfo.comment
                ),
                errorMessageId: 'CONFIRMATION.ERASE.ERROR',
                successMessageId: 'CONFIRMATION.ERASE.SUCCESS',
                callback: requestCallBack || globalCallback
            }),

        trash: (confirmationInfo, isTrashed, requestCallBack) =>
            performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.trash(
                    confirmationInfo.id,
                    confirmationInfo.comment,
                    isTrashed
                ),
                errorMessageId: 'CONFIRMATION.DELETE.ERROR',
                successMessageId: 'CONFIRMATION.DELETE.SUCCESS',
                callback: requestCallBack || globalCallback
            }),
        downloadFiles: (confirmationId, requestCallBack) =>
            performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.downloadFiles(confirmationId),
                errorMessageId: 'DOCUMENT.STATUSES.ACCESS.ERROR',
                callback: requestCallBack || globalCallback
            }),
        fetchExcelTemplate: (configurationInfo, requestCallBack) =>
            performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.fetchExcelTemplate(configurationInfo),
                errorMessageId: 'IMPORT.CREATE.CONFIRMATION.ACCESS.XLS_TEMPLATE.ACCESS.ERROR',
                callback: requestCallBack || globalCallback
            }),
        bulkUpload: (confirmationsConfig, requestCallBack) =>
            performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.upload({
                    file: confirmationsConfig.file,
                    configurationInfo: confirmationsConfig
                }),
                errorMessageId: 'IMPORT.CREATE.CONFIRMATION.CHECK.ERROR',
                successMessageId: 'IMPORT.CREATE.CONFIRMATION.CHECK.SUCCESS',
                callback: requestCallBack || globalCallback
            }),
        fetchMenu: (confirmationsFilters, requestCallBack) => {
            // remove the filters (engagementId, confirmationNameOrCode) which are null
            const filters = cleanFilters(confirmationsFilters);
            return performAPIAction({
                ...rest,
                apiCall: confirmationsRepository.fetchMenu(filters),
                errorMessageId: 'FETCH.ENGAGEMENT.MENU.ACCESS.ERROR',
                callback: requestCallBack || globalCallback
            });
        }
    };
};

export const ConfirmationService = ({
    intl,
    user,
    checkSession,
    addToastMessage,
    callback,
    setLoading = () => {}
}) => {
    const methods = confirmationMethods({
        intl,
        user,
        checkSession,
        addToastMessage,
        callback,
        setLoading
    });
    return { ...methods };
};
