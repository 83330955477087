import { deepFreeze } from '../../../helpers/FreezeHelper';
import { EMPTY_GUID } from '../../../kit/utils/constants';

const InitEmailTemplate = {
    name: null,
    description: null,
    emailTypeId: null,
    clusterId: null,
    archived: false,
    languageId: null,
    subject: null,
    body: null
};

export const initEmailTemplate = deepFreeze(InitEmailTemplate);

export const DEFAULT_CONFIRMATION_TEMPLATE_CATEGORY = {
    id: EMPTY_GUID,
    name: 'Default',
    description: 'Default correspond to no confirmation category selected',
    eConfirmationCategory: 99
};
