import React from 'react';
import { CLIENT_CONFIRMATION_STEP_TYPE, ClientRequest } from '../../../../types/types';
import { UIScaffoldChildContext } from '../../../../scaffolder/UIScaffold';
import { ClientRequestThirdPartyStep } from './ClientRequestThirdPartyStep';
import { ClientRequestLetterHeadStep } from './ClientRequestLetterHeadStep';
import { ClientRequestValidationStep } from './ClientRequestValidationStep';

interface ClientRequestStepProps {
    step: CLIENT_CONFIRMATION_STEP_TYPE;
    handleRequestUpdate: (values: any) => void;
    fetchRequests: () => Promise<any>;
    request: ClientRequest;
    stepsStatus: any;
}

export const ClientRequestStepComponent = ({
    step,
    request,
    setToast,
    setLoading,
    setSessionExpired,
    handleRequestUpdate,
    stepsStatus,
    fetchRequests
}: ClientRequestStepProps & UIScaffoldChildContext): JSX.Element | null => {
    switch (step) {
        case CLIENT_CONFIRMATION_STEP_TYPE.THIRD_PARTY:
            return (
                <ClientRequestThirdPartyStep
                    handleRequestUpdate={handleRequestUpdate}
                    request={request}
                    setLoading={setLoading}
                    setSessionExpired={setSessionExpired}
                    setToast={setToast}
                />
            );

        case CLIENT_CONFIRMATION_STEP_TYPE.COMPLEMENTARY_INFORMATION:
            return <ClientRequestComplementaryInfoStep />;

        case CLIENT_CONFIRMATION_STEP_TYPE.LETTERHEAD:
            return (
                <ClientRequestLetterHeadStep
                    fetchRequests={fetchRequests}
                    handleRequestUpdate={handleRequestUpdate}
                    request={request}
                    setLoading={setLoading}
                    setSessionExpired={setSessionExpired}
                    setToast={setToast}
                />
            );

        case CLIENT_CONFIRMATION_STEP_TYPE.CONFIRM:
            return <ClientRequestValidationStep request={request} stepsStatus={stepsStatus} />;

        default:
            return null;
    }
};

export const ClientRequestComplementaryInfoStep = (): JSX.Element => {
    return <></>;
};
