import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CLIENT_CONFIRMATION_STEP_TYPE, ClientRequest, ClientRequestStep } from '../../../../types/types';
import { useStepper } from '../../../../hooks/useStepper';
import { CreateConfirmationProgress as Stepper } from '../../../confirmation/common';
import { UIScaffoldChildContext } from '../../../../scaffolder/UIScaffold';
import {
    hasThirdParty,
    hasThirdPartyContacts,
    allStepsCompleted,
    getSteps,
    getStepsStatus
} from '../../../../helpers';
import { Alert } from '@mui/material';
import { FormattedErrorMessage } from '../../../errorMessage/ErrorMessage';
import { getLocalizedString } from '../../../../i18n/i18Helper';
import { ClientRequestStepComponent } from './ClientRequestStepComponent';
import { StepperFooterActions } from '../../../stepper/StepperFooterActions';

interface ClientConfirmationStepsProps {
    steps: ClientRequestStep[];
    request: ClientRequest;
    handleRequestUpdate: (values: any) => void;
    handleSaveChanges: (request: any) => Promise<boolean>;
    handleConfirmRequest: (request: any) => void;
    fetchRequests: () => Promise<any>;
}

export const ClientRequestSteps = ({
    steps,
    request: selectedRequest,
    setSessionExpired,
    setToast,
    setLoading,
    handleSaveChanges,
    handleRequestUpdate: updateSelectedRequest,
    handleConfirmRequest,
    fetchRequests
}: ClientConfirmationStepsProps & UIScaffoldChildContext) => {
    const [request, setRequest] = useState<ClientRequest>(selectedRequest);

    //need to  have a refresh locale state, to refresh the step dialog after an action like deleting letterhead
    useEffect(() => {
        setRequest(selectedRequest);
    }, [selectedRequest]);

    const [updatedSections, setUpdatedSections] = useState<CLIENT_CONFIRMATION_STEP_TYPE[]>([]);
    const [stepErrorMessage, setStepErrorMessage] = useState<string>(request.auditorRefuseMessage);
    const intl = useIntl();

    const { activeSteps, activeStep, firstStep, lastStep, activeStepPosition, nextStep, previousStep } = useStepper({
        initialSteps: getSteps(steps),
        initialPosition: 0,
        changeStepCallback: () => {}
    });

    const handleRequestUpdate = (values: any) => {
        setUpdatedSections([...updatedSections, activeStep]);
        setRequest({ ...request, ...values });
        updateSelectedRequest(values);
    };

    async function handleNext() {
        setStepErrorMessage('');

        switch (activeStep) {
            case CLIENT_CONFIRMATION_STEP_TYPE.THIRD_PARTY:
                if (hasThirdParty(request) && hasThirdPartyContacts(request)) {
                    if (updatedSections.includes(CLIENT_CONFIRMATION_STEP_TYPE.THIRD_PARTY)) {
                        const isSuccess: boolean = await handleSaveChanges(request);
                        isSuccess && nextStep();
                    } else {
                        nextStep();
                    }
                } else {
                    setStepErrorMessage(getLocalizedString(intl, 'CLIENT.REQUEST.THIRD_PARTY.REQUIRED'));
                }

                break;

            case CLIENT_CONFIRMATION_STEP_TYPE.COMPLEMENTARY_INFORMATION:
                nextStep();
                break;

            case CLIENT_CONFIRMATION_STEP_TYPE.LETTERHEAD:
                nextStep();
                break;

            default:
                break;
        }
    }

    function handlePrevious() {
        previousStep();
    }

    const handleConfirm = () => {
        if (!allStepsCompleted(activeSteps)) {
            setStepErrorMessage(getLocalizedString(intl, 'CLIENT.REQUEST.VALIDATION.STEP.ALL.STEPS.REQUIRED'));
        } else {
            handleConfirmRequest(request);
        }
    };

    return (
        <>
            {stepErrorMessage && (
                <ClientRequestErrorArea errorMessage={stepErrorMessage} onClose={() => setStepErrorMessage('')} />
            )}

            <Stepper activeStepPosition={activeStepPosition} activeSteps={activeSteps} />

            <ClientRequestStepComponent
                fetchRequests={fetchRequests}
                stepsStatus={getStepsStatus(activeSteps)}
                handleRequestUpdate={handleRequestUpdate}
                step={activeStep}
                request={request}
                setLoading={setLoading}
                setSessionExpired={setSessionExpired}
                setToast={setToast}
            />

            <StepperFooterActions
                loading={false}
                nextLabelId="CLIENT.CONFIRMATION.STEPS.NEXT.LABEL"
                finishLabelId="CLIENT.CONFIRMATION.STEPS.CONFIRM.LABEL"
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                handleSave={handleConfirm}
                isNextStepDisabled={false}
                isFirstStep={activeStep === firstStep}
                isLastStep={activeStep === lastStep}
            />
        </>
    );
};

const ClientRequestErrorArea = ({ errorMessage, onClose }: { errorMessage: string; onClose: () => void }) => {
    return (
        <>
            <Alert severity="error" onClose={onClose}>
                {FormattedErrorMessage(errorMessage)}
            </Alert>
        </>
    );
};
