import React from 'react';
import { UITooltip } from '../common/UITooltip';
import { Typography } from '@mui/material';
import { CONFIRMATION_STEP_STATUS } from '../../kit/utils/constants';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import { FormattedDate } from 'react-intl';
import { Stepper, Step, StepLabel } from '@mui/material';
import { ProcessConnector, ProcessStepIcon } from '../../helpers/StepperHelper';

export const ConfirmationWorkflowProgress = ({ progressData }) => {
    const activeSteps = progressData.steps?.filter(step => step.isActive);

    return (
        <div className="d-flex flex-column w-100 mr-2" key={`key_div_engagement_stepper_${progressData.id}`}>
            <Stepper
                activeStep={progressData?.currentStep?.position}
                alternativeLabel
                connector={<ProcessConnector />}
                key={`key_engagement_stepper_${progressData?.id}`}>
                {React.Children.toArray(
                    activeSteps?.map(x => (
                        <UITooltip
                            title={
                                <>
                                    <Typography variant="subtitle1">
                                        {x.status === CONFIRMATION_STEP_STATUS.VALIDATED ? (
                                            <CheckCircleOutlineRounded />
                                        ) : null}
                                        {x.name.toUpperCase()}
                                    </Typography>
                                    <Typography variant="body2">{x?.description}</Typography>
                                    {x.status === CONFIRMATION_STEP_STATUS.VALIDATED && (
                                        <FormattedDate value={x.updated} year="numeric" month="short" day="numeric" />
                                    )}
                                </>
                            }
                            children={
                                <Step key={x.id}>
                                    <StepLabel StepIconComponent={ProcessStepIcon} />
                                </Step>
                            }
                        />
                    ))
                )}
            </Stepper>
        </div>
    );
};
