import React from 'react';
import { LayoutInit } from '../components/LayoutInit';
import { Header } from '../components/header/Header';
import { MZRNavHeader } from '../components/header/MZRNavHeader';
import { DevelopmentWarningBanner } from '../components/header/DevelopmentWarningBanner';
import './layout.css';
import { Palette } from '../styles/colors';
import { Divider, Paper, Stack } from '@mui/material';
import { UIText } from '../components/common/UIText';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../i18n/i18Helper';
import { toAbsoluteUrl } from '../helpers';

/**
 * Default Layout for the application.
 *
 * @param {Node} param0
 */
const DefaultLayout = ({ children, ...rest }) => (
    <>
        <div className="d-flex flex-column flex-root">
            <div className="d-flex flex-row flex-column-fluid page">
                <div className="d-flex flex-column flex-row-fluid wrapper" id="mzr_wrapper">
                    {process.env.REACT_APP_ENV !== 'production' && <DevelopmentWarningBanner />}
                    <Header />
                    <MZRNavHeader {...rest} />

                    <div id="mzr_content" className="content flex-column flex-column-fluid">
                        <div className="flex-column-fluid">
                            <div className="container">
                                <>{children}</>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StickyFooter />
        </div>
        <LayoutInit />
    </>
);

export default DefaultLayout;

export function StickyFooter() {
    const intl = useIntl();

    return (
        <>
            <Divider sx={{ height: '0.5', width: '100%', marginTop: '10px' }} />
            <Paper elevation={3} style={style.container}>
                <Stack direction="row">
                    <img
                        src={toAbsoluteUrl('/media/svg/icons/innovation_rocket.svg')}
                        style={{
                            height: '32px',
                            marginTop: '-8px',
                            marginLeft: '16px'
                        }}
                        alt="letterhead"
                    />
                    <UIText text={getLocalizedString(intl, 'COMMON.POWERED_BY.LABEL')} sx={style.text} />
                </Stack>
            </Paper>
        </>
    );
}

const style = {
    container: {
        backgroundColor: Palette.whiteSmoke,
        paddingTop: '8px',
        position: 'fixed',
        left: '0',
        bottom: '0',
        height: '38px',
        width: '100%'
    },
    text: {
        color: Palette.primaryTextColor,
        fontSize: 12,
        paddingX: 1
    }
};
