export interface InternAuthInfoSpec {
    email: string;
    password: string;
    countryCode: string;
}

export const initInternLoginInfo: InternAuthInfoSpec = {
    email: '',
    password: '',
    countryCode: ''
};

export interface ExternAuthInfoSpec {
    email: string;
    password: string;
}

export const initExternLoginInfo: ExternAuthInfoSpec = {
    email: '',
    password: '',
};