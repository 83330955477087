import { Children } from 'react';
import { FormattedDate } from 'react-intl';
import { ProcessConnector, HighlightedProcessStepIcon } from '../../../../helpers/StepperHelper';
import { CONFIRMATION_STEP_STATUS } from '../../../../kit/utils/constants';
import { UITooltip } from '../../../common/UITooltip';
import { ConfirmationStepActions } from '../../workflow/ConfirmationStepActions';
import { Stepper, Step, StepLabel, StepContent, Typography } from '@mui/material';

export const EditConfirmationStepper = ({ currentStepPosition, steps, ...rest }) => {
    const activeSteps = steps.filter(step => step.isActive);

    return (
        <Stepper activeStep={currentStepPosition} alternativeLabel connector={<ProcessConnector />}>
            {Children.toArray(
                activeSteps.map(stepData => (
                    <UITooltip
                        title={stepData.description}
                        children={ConfirmationStep({
                            stepData,
                            ...rest
                        })}
                    />
                ))
            )}
        </Stepper>
    );
};

const ConfirmationStep = ({ stepData, handleStepAction }) => {
    return (
        <Step key={String(stepData.id)}>
            <StepLabel StepIconComponent={HighlightedProcessStepIcon}>
                <Typography variant="subtitle2">{stepData.name.toUpperCase()}</Typography>
                {stepData.status === CONFIRMATION_STEP_STATUS.VALIDATED && (
                    <FormattedDate
                        value={stepData.updated}
                        year="numeric"
                        month="short"
                        day="numeric"
                        style={{ colour: 'primary' }}
                    />
                )}
            </StepLabel>
            {
                <StepContent>
                    <ConfirmationStepActions actions={stepData.stepActions} handleStepAction={handleStepAction} />
                </StepContent>
            }
        </Step>
    );
};
