import React from 'react';
import { SimpleFormItem } from '../../helpers/FormHelper';
import { Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Palette } from '../../styles/colors';
import { UIText } from '../common/UIText';

const useStyles = makeStyles(_ => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}));

export const EntityDetails = ({ fieldsList, entity }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            {React.Children.toArray(
                fieldsList.map((field, key) => (
                    <Grid item container key={key}>                        
                        <SimpleFormItem title={field.title} value={entity[field.attribute]} />
                        
                        {entity[field?.subAttribute?.attribute] && (
                            <UIText variant="body2" sx={{ color: Palette.red }} text={field.subAttribute.title} />)}
                    </Grid>
                ))
            )}
        </Grid>
    );
};
