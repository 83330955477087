import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExpandMore, ErrorOutlineRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { DocumentTableComponent } from '../document';
import { DocumentsToolbar } from './DocumentsToolbar';
import { Box } from '@mui/material';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { getLocalizedString } from '../../i18n/i18Helper';
import { UITooltip } from '../common/UITooltip';

export function DocumentPendingComponent(props) {
    const {
        setLoading,
        isSearchEnabled,
        user,
        actionProfile,
        loading,
        paginatedItems: originalPaginatedItems,
        menuEngagements,
        fetchMenuEngagement,
        handleFetchMenuConfirmations,
        documentTypes,
        fetchItemFile,
        fetchItemInfo,
        updateItem,
        downloadItem,
        handleFilterItems,
        titleId = 'DOCUMENTS.PENDING.SUBSECTION.TITLE',
        isRefuseEnabled = true,
        shouldPopulateMenuConfirmations = true,
        engagementId = null,
        confirmationId = null,
        isDocumentPendingDisplay = true
    } = props;

    const intl = useIntl();
    const [paginatedItems, setPaginatedItems] = useState(originalPaginatedItems);

    useEffect(() => {
        shouldPopulateMenuConfirmations
            ? (async () =>
                  originalPaginatedItems &&
                  setPaginatedItems(await enrichItemsWithConfirmations(originalPaginatedItems)))()
            : setPaginatedItems(originalPaginatedItems);
    }, [originalPaginatedItems]);

    const enrichItemsWithConfirmations = async paginatedItems => {
        // fetch menuConfirmation for every item
        const menuConfirmations = await handleFetchMenuConfirmations();
        const { items } = paginatedItems;
        // and enhance everyone with the fetched menu confirmations and the corresponding engagementId and confirmation id
        const updatedItems = items?.map(item => ({
            ...item,
            engagementId: engagementId,
            confirmationId: confirmationId,
            menuConfirmations: menuConfirmations
        }));

        return { ...paginatedItems, items: updatedItems };
    };

    /***
     * If pending documents are available, fetch menu engagements and menu confirmations once we open the accordion to be give the possibility to dispatch the documents
     */
    const handlePopulateMenu = async () => {
        if (paginatedItems?.totalItems > 0) {
            await fetchMenuEngagement();
            shouldPopulateMenuConfirmations && setPaginatedItems(await enrichItemsWithConfirmations(paginatedItems));
        }
    };

    const getAccordionColor = () => {
        const getBackGroundColor =
            paginatedItems?.totalItems > 0 ? (isDocumentPendingDisplay ? '#F08080' : '#D4EBE6') : '#0071CE1A';
        return { backgroundColor: getBackGroundColor };
    };

    const handleSearch = (filterName, value) => {
        handleFilterItems({ [filterName]: value ?? '' });
    };

    return (
        <>
            <Accordion
                onChange={async (e, expanded) => {
                    expanded && (await handlePopulateMenu());
                }}
                key={`documents_pending-actions-section`}
                TransitionProps={{ unmountOnExit: true }}
                style={getAccordionColor()}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-label="Expand"
                    aria-controls="documents_pending-actions1-content"
                    id="documents_pending-actions1-header">
                    <UITooltip
                        title={getLocalizedString(
                            intl,
                            isDocumentPendingDisplay
                                ? 'DOCUMENTS.PENDING.SUBSECTION.TOOLTIP'
                                : 'DOCUMENTS.LOST.DOCUMENTS.SUBSECTION.TOOLTIP'
                        )}
                        children={
                            <div className="fullCenter">
                                {paginatedItems?.totalItems > 0 &&
                                    (isDocumentPendingDisplay ? (
                                        <ErrorOutlineRounded
                                            color="secondary"
                                            fontSize="large"
                                            style={{ marginRight: 2 }}
                                        />
                                    ) : (
                                        <SearchIcon color="primary" fontSize="large" style={{ marginRight: 2 }} />
                                    ))}

                                <FormattedMessage
                                    id={titleId}
                                    values={{ numberDocuments: paginatedItems?.totalItems }}
                                />
                            </div>
                        }
                    />
                </AccordionSummary>

                <AccordionDetails
                    children={
                        <Box
                            sx={{
                                flexDirection: 'column',
                                display: 'flex',
                                width: '100%'
                            }}>
                            {isSearchEnabled && (
                                <DocumentsToolbar
                                    handleSearch={handleSearch}
                                    style={{
                                        backgroundColor: 'white',
                                        padding: 10
                                    }}
                                />
                            )}

                            <DocumentTableComponent
                                setLoading={setLoading}
                                handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                                user={user}
                                actionProfile={actionProfile}
                                loading={loading}
                                paginatedItems={paginatedItems}
                                menuEngagements={menuEngagements}
                                setDisplayInvalidAddressEnabled={false}
                                documentTypes={documentTypes}
                                fetchItemFile={fetchItemFile}
                                fetchItemInfo={fetchItemInfo}
                                updateItem={updateItem}
                                downloadItem={downloadItem}
                                handleFilterItems={handleFilterItems}
                                isRefuseEnabled={isRefuseEnabled}
                            />
                        </Box>
                    }
                />
            </Accordion>
        </>
    );
}
