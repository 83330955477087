import { useIntl } from 'react-intl';
import React from 'react';
import { UIActionButton } from './UIActionButton';
import { getLocalizedString } from '../../i18n/i18Helper';
import { toolbarStyle } from '../../styles/toolbar/ToolbarStyle';
import { Box } from '@mui/material';
import { Palette } from '../../styles/colors';

export const BaseToolbarItems = ({
    handleReset,
    isResetHidden = true,
    handleDelete,
    isDeleteHidden = true,
    handleSave,
    loading,
    style,
    disabled = false
}) => {
    const intl = useIntl();

    return (
        <Box sx={{ ...toolbarStyle.container, m: 0 }}>
            {!isResetHidden && (
                <UIActionButton
                    sx={toolbarStyle.button}
                    display="secondary"
                    onClick={handleReset}
                    title={getLocalizedString(intl, 'CONFIRMATION.BUTTON.RESET.LABEL')}
                />
            )}

            {!isDeleteHidden && (
                <UIActionButton
                    title={getLocalizedString(intl, 'CONFIRMATION.BUTTON.DELETE.LABEL')}
                    disabled={disabled}
                    sx={{ ...toolbarStyle.button, backgroundColor: Palette.red }}
                    onClick={handleDelete}
                />
            )}

            <UIActionButton
                sx={toolbarStyle.button}
                title={getLocalizedString(intl, 'CONFIRMATION.BUTTON.SAVE.LABEL')}
                startIcon={loading && <span className="mr-6 spinner spinner-white" />}
                disabled={disabled}
                display={style?.save.display ?? 'primary'}
                onClick={handleSave}
            />
        </Box>
    );
};
