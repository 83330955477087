import { appApiUrl } from '../../config/appConfiguration';
import { parseStep, parseSteps } from './confirmationParsers';
import { Repository } from '../Repository';

class StepRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`step`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseSteps);
        return result;
    };

    fetch = async (stepId) => {
        let result = await this.network.get(`step/${stepId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseStep);
        return result;
    };
}

export const stepRepository = new StepRepository();
