import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers';
import { Loader } from '../../components/loader/Loader';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PROFILE } from '../../types/types';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import { 
    fetchData,
    fetchConfirmationCategories,
    fetchEngagement,
    ConfirmationService,
    LetterTemplateService
} from '../../services';
import { CreateConfirmationImportComponent } from '../../components/confirmation';
import { saveDownloadedFile } from '../../helpers/ItemHelper';
import { LetterTemplateContext } from '../../components/setup';

/**
 * Create confirmation import page component for user with auditor role.
 *
 * User can create through this page multiple confirmations from a file imported.
 * @public
 */
class CreateConfirmationImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };

        this.letterTemplateManager = LetterTemplateService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading
        });

        this.confirmationService = ConfirmationService({
            intl: this.props.intl,
            user: this.props.user,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading
        });
    }

    // fetch the letter templates
    async componentDidMount() {
        await fetchData(this, [() => fetchConfirmationCategories(this), () => fetchEngagement(this)]);

        await this.fetchAllLetterTemplates();
    }

    fetchAllLetterTemplates = async () => {
        const { engagement } = this.state;
        const { intl } = this.props;
        const result = await this.letterTemplateManager.fetchAll({ clusterId: engagement?.clusterId });
        if (result.isSuccess) {
            this.setState({ letterTemplates: Object.values(result.parsedValue) });
        } else {
            this.setState({ errorMessage: intl.formatMessage({ id: 'LETTER.TEMPLATE.ACCESS.ERROR' }) });
        }
    };

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    handleFetchImportExcelTemplate = async configurationInfo => {
        let result;
        await this.confirmationService.fetchExcelTemplate(configurationInfo, (isSuccess, data) => {
            isSuccess && saveDownloadedFile(data, configurationInfo?.labels.fileName);
            result = data;
        });

        return result;
    };

    handleUploadConfirmations = async confirmationsConfig => {
        const { intl } = this.props;
        let result;

        await this.confirmationService.bulkUpload(confirmationsConfig, (isSuccess, data) => {
            !isSuccess &&
                data.isFile &&
                saveDownloadedFile(data, intl.formatMessage({ id: 'IMPORT.CONFIRMATION.EXCEL.FILE.NAME.LABEL' }));
            result = data;
        });

        return result;
    };

    render() {
        const { user, languages } = this.props;
        const { letterTemplates, confirmationCategories, engagement, loading } = this.state;

        if (!user.profil) {
            return <Loader />;
        } else {
            if (isAuthorisedProfile(PROFILE.AUDITOR, user.profil)) {
                return (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <LetterTemplateContext.Provider
                                    value={{
                                        fetchTemplate: this.letterTemplateManager.fetch,
                                        downloadTemplate: this.letterTemplateManager.download
                                    }}>
                                    <CreateConfirmationImportComponent
                                        loading={loading}
                                        languages={languages}
                                        templates={letterTemplates}
                                        confirmationCategories={confirmationCategories}
                                        fetchImportExcelTemplate={this.handleFetchImportExcelTemplate}
                                        engagement={engagement}
                                        uploadConfirmations={this.handleUploadConfirmations}
                                    />
                                </LetterTemplateContext.Provider>
                            </div>
                        </div>
                    </>
                );
            } else {
                return <PageNotFound {...this.props} />;
            }
        }
    }
}

CreateConfirmationImport.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array,
    languages: PropTypes.array.isRequired
};

CreateConfirmationImport.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        languages: state.cluster.languages
    };
};

export const createConfirmationImport = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(CreateConfirmationImport));
