export const Types = {
    SET_USER_CONNECTION: 'setConnection',
    SET_PROFILE: 'setProfile',
    SET_SESSION_PROFILE: 'setSessionProfile',
    RESET_USER: 'resetUser',
    ADD_TOAST_MESSAGE: 'addToastMessage',
    SET_TOAST_MESSAGE: 'setToastMessage',
    SET_DEFAULT_PATH: 'setDefaultPath',
    SET_SELECTED_PROFILE_ROUTE: 'setSelectedProfileRoute',
    RESET_APP: 'resetApp'
};

export const setConnection = (isConnected, sessionType) => {
    return {
        type: Types.SET_USER_CONNECTION,
        isConnected,
        sessionType
    };
};

export const setProfile = profile => {
    return {
        type: Types.SET_PROFILE,
        profile
    };
};

// use only pure profile (not composed profile)
export const setSessionProfile = sessionProfile => {
    return {
        type: Types.SET_SESSION_PROFILE,
        sessionProfile
    };
};

export const resetUser = () => {
    return {
        type: Types.RESET_USER
    };
};

export const setDefaultPath = defaultPath => {
    return {
        type: Types.SET_DEFAULT_PATH,
        defaultPath
    };
};

export const setToastMessage = toastList => {
    return {
        type: Types.SET_TOAST_MESSAGE,
        toastList
    };
};

export const addToastMessage = toastMessage => {
    return {
        type: Types.ADD_TOAST_MESSAGE,
        toastMessage
    };
};

export const resetApp = () => {
    return {
        type: Types.RESET_APP
    };
};

export const setSelectedProfileRoute = route => {
    return {
        type: Types.SET_SELECTED_PROFILE_ROUTE,
        route
    };
};
