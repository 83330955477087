import { ServiceResult } from '../kit/network/ServiceResult';
import { UIScaffoldChildContext } from '../scaffolder/UIScaffold';
import { TOAST_TYPE } from '../components/toast/Toast';

/**
 * Transform array to key:value format.
 * @param {*} json
 * @returns
 */
export const listToArrayOfObjects = (list: any, startIndex: number = 0) => {
    return list.map((key: string, index: number) => ({ id: index + startIndex, name: key }));
};

export const listToArrayOfObjectsKey = (list: any, startIndex: number = 0) => {
    return list.map((key: string, index: number) => ({ key: index + startIndex, name: key }));
};

/**
 * Transform object to key:value format.
 * @param {*} json
 * @returns
 */
export const swap = (json: any) => {
    return Object.keys(json).map(key => ({ id: json[key], name: key }));
};

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export const uncapitalize = (message: string) => {
    return message?.length > 0 ? message?.charAt(0).toLowerCase() + message?.slice(1) : message;
};

export const handleApiFailure = (apiResult: ServiceResult, title: string, scaffold?: UIScaffoldChildContext) => {
    if (!!apiResult?.isUnauthorizedUserError) {
        scaffold?.setSessionExpired(true);
    } else {
        scaffold?.setToast(title, apiResult.error, TOAST_TYPE.DANGER);
    }
};

export const transposeMatrix = matrix => {
    let [firstRow] = matrix;
    return firstRow.map((_value, column) => matrix.map(row => row[column]));
};

export function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

/**
 * Returns the given `obj` without the `properties`.
 *
 * @param {Object} obj
 * @param {String[]} properties
 *
 * @returns {Object}
 */
export function removeProperties(obj, properties) {
    let cleanedObject = obj;
    properties.forEach(property => {
        const { [property]: unused, ...rest } = cleanedObject;
        cleanedObject = rest;
    });

    return cleanedObject;
}
