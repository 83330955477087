import React from 'react';
import { EntityDetails } from '../common/EntityDetails';

export const CompanyDetails = ({ company }) => {
    const INFORMATION_FIELDS = [
        {
            title: 'EDIT_CLIENT.LABEL.NAME',
            attribute: 'name'
        },
        {
            title: 'EDIT_CLIENT.LABEL.INTERNALID',
            attribute: 'internalId'
        },
        {
            title: 'EDIT_CLIENT.LABEL.ADDRESS',
            attribute: 'address'
        },
        {
            title: 'EDIT_CLIENT.LABEL.CITY',
            attribute: 'city'
        },
        {
            title: 'EDIT_CLIENT.LABEL.ZIPCODE',
            attribute: 'zipCode'
        },
        {
            title: 'EDIT_CLIENT.LABEL.COUNTRY',
            attribute: 'countryText'
        },
        {
            title: 'EDIT_CLIENT.LABEL.COMPANY_ID_NUMBER',
            attribute: 'companyId'
        },
        {
            title: 'EDIT_CLIENT.LABEL.COMPANY_ID_NUMBER2',
            attribute: 'companyId2'
        },
        {
            title: 'EDIT_CLIENT.LABEL.VAT_ID_NUMBER',
            attribute: 'vatId'
        },
        {
            title: 'EDIT_CLIENT.LABEL.CONTACTS',
            attribute: 'displayedContacts'
        }
    ];
    
    const formattedCompany = {
        ...company,
        displayedContacts: company.contacts?.reduce((prev, curr) => `${prev} ${curr.email} \n`, '') || ''
    };
    return <EntityDetails fieldsList={INFORMATION_FIELDS} entity={formattedCompany} />;
};
