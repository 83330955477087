import { useMemo, useState } from 'react';
import { resetColumnsFilters } from '../helpers/EngagementHelper';

export interface FilterOption {
    id: string;
    value: any;
}

export interface FilterTableSpec {
    isFilteringMode: boolean;
    onFilterTableChanged: (filterName: string, value: any, extraFilters?: any) => void;
    filterableColumnsValues: any;
    handleClearFilters: () => void;
    onFilterClicked: () => void;
    updateFiltersValues?: (values: Record<string, FilterOption>) => void;
}

export const useFilterTable = (
    filterableColumns: any,
    callback?: any,
    initialFilteringMode?: boolean,
    initialValues?: Record<string, FilterOption>
): FilterTableSpec => {
    const emptyFilters = useMemo(() => resetColumnsFilters(filterableColumns), []);

    // ex {filterId: {selectedValue}} => {
    // engagementClientCompanyId: {id: 'a08ddaf2-2b3e-4c5a-b4a3-9acc12046faf', name: 'KOMBON SAS'}}
    const [columnsFiltersValues, setColumnsFiltersValues] = useState<any>(initialValues ?? {});
    const [isFilteringMode, setFilteringMode] = useState<boolean>(initialFilteringMode ?? false);

    const onFilterTableChanged = (filterName: string, value: any, extraFilters?: any) => {
        const extra = extraFilters || { columns: {}, values: {} };
        // ex values: {id: 'a08ddaf2-2b3e-4c5a-b4a3-9acc12046faf', name: 'KOMBON SAS'}
        setColumnsFiltersValues({ ...columnsFiltersValues, [filterName]: value, ...extra.columns });
        // build the filter ex: {NameOrEngagementCode: 'Engagement name test'}
        callback({ [filterName]: value?.id ?? null, ...extra.values });
    };

    const handleClearFilters = () => {
        setColumnsFiltersValues({});
        callback(emptyFilters);
    };

    const onFilterClicked = () => {
        if (isFilteringMode) {
            handleClearFilters();
        }

        setFilteringMode(!isFilteringMode);
    };

    const updateFiltersValues = (values: Record<string, FilterOption>) => {
        setColumnsFiltersValues({ ...columnsFiltersValues, ...values });
    };

    return {
        isFilteringMode,
        onFilterTableChanged,
        filterableColumnsValues: columnsFiltersValues,
        handleClearFilters,
        onFilterClicked,
        updateFiltersValues
    };
};
