import { ServiceResult } from './ServiceResult';
import { mzrRequest } from './mzrRequest';

const noPayloadStatus = [204];

/**
 * Network component to perform CRUD action as such as GET, POST, PUT, DELETE.
 */
export class Network {
    constructor(options) {
        this.token = options.token;
        this.baseURL = options.baseURL;

        this.options = {
            headers: [],
            ...options
        };
    }

    /*static connectivity = {
        isConnected: () => {
            throw new Error('isConnected method should be implemented');
        },
        isNetworkAvailable: true
    };*/

    get(path, options = {}) {
        return this.execute(path, 'get', undefined, options);
    }

    post(path, body = {}, options = {}) {
        return this.execute(path, 'post', body, options);
    }

    patch(path, body = {}, options = {}) {
        return this.execute(path, 'PATCH', body, options);
    }

    put(path, body = {}, options = {}) {
        return this.execute(path, 'put', body, options);
    }

    delete(path, body = {}, options = {}) {
        return this.execute(path, 'delete', body, options);
    }

    hasPayload = statusCode => !noPayloadStatus.includes(statusCode);

    getBlob = async result => {
        let response = result;

        try {
            const resFile = await result.clone();
            response = await resFile.blob();
        } catch (e) {
            console.log('[Network] parsing to blob error: ', e);
        }
        return response;
    };

    format = async result => {
        const isJson = result?.headers?.get('content-type')?.indexOf('application/json') !== -1;

        let errorsResult = {};
        try {
            errorsResult = !result.ok && result.status !== 401 ? await result.clone()?.json() : {};
        } catch (error) {
            console.log('[Network] parsing to json error: ', error);
        }

        const buildFormattedResponse = body =>
            new ServiceResult({
                body,
                status: result?.status,
                headers: result?.headers,
                error:
                    typeof errorsResult?.error === 'string'
                        ? errorsResult?.error?.toString()
                        : JSON.stringify(errorsResult?.error, null, 2)
            });

        let formattedResponse;
        if (!this.hasPayload(result?.status)) {
            formattedResponse = buildFormattedResponse({});
        } else if (result?.ok) {
            formattedResponse = isJson
                ? result['json']().then(body => buildFormattedResponse(body))
                : buildFormattedResponse(await this.getBlob(result));
        } else {
            formattedResponse = isJson
                ? buildFormattedResponse(result)
                : buildFormattedResponse(await this.getBlob(result));
        }

        return formattedResponse;
    };

    execute = async (path, method, body, opts = {}) => {
        const options = {
            ...this.options,
            ...opts
        };

        try {
            const response = await mzrRequest(this.baseURL, path, method, body, options);
            const formattedResponse = await this.format(response);

            return formattedResponse;
        } catch (error) {
            console.log('[Network] execute request error: ', error);
            return new ServiceResult({ error: error.toString() });
        }
    };
}
