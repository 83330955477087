import { useIntl } from 'react-intl';
import React from 'react';
import { UIActionButton } from '../common/UIActionButton';
import { Box } from '@mui/material';
import { cardFooterStyle } from '../../styles/toolbar/CardFooter';
import { getLocalizedString } from '../../i18n/i18Helper';

interface StepperFooterActionsProps {
    isNextStepDisabled: boolean;
    isFirstStep: boolean;
    isLastStep: boolean;

    handleBack?: () => void;
    handleReset?: () => void;

    handlePrevious: () => void;
    handleSave: () => void;
    handleNext: () => void;
    nextLabelId?: string;
    finishLabelId?: string;
    loading: boolean;
}

export const StepperFooterActions = ({
    isNextStepDisabled = false,
    isFirstStep,
    isLastStep,
    handleSave,
    handleNext,
    handleReset,
    handlePrevious,
    handleBack,
    loading,
    nextLabelId = 'CREATE_ENGAGEMENT.BUTTON.NEXT.LABEL',
    finishLabelId = 'CREATE_ENGAGEMENT.BUTTON.SAVE.LABEL'
}: StepperFooterActionsProps) => {
    const intl = useIntl();

    return (
        <Box sx={{ ...cardFooterStyle.container, m: 0 }}>
            {handleBack && (
                <UIActionButton
                    title={getLocalizedString(intl, 'CREATE_ENGAGEMENT.BUTTON.BACK.LABEL')}
                    sx={cardFooterStyle.button}
                    display="secondary"
                    onClick={handleBack}
                />
            )}

            {handleReset && (
                <UIActionButton
                    title={getLocalizedString(intl, 'CREATE_ENGAGEMENT.BUTTON.RESET.LABEL')}
                    sx={cardFooterStyle.button}
                    display="secondary"
                    onClick={handleReset}
                />
            )}

            <UIActionButton
                title={getLocalizedString(intl, 'CREATE_ENGAGEMENT.BUTTON.PREVIOUS.LABEL')}
                sx={cardFooterStyle.button}
                display="secondary"
                disabled={isFirstStep}
                onClick={handlePrevious}
            />

            <UIActionButton
                sx={cardFooterStyle.button}
                startIcon={loading && <span className="mr-6 spinner spinner-white" />}
                title={getLocalizedString(intl, isLastStep ? finishLabelId : nextLabelId)}
                disabled={isNextStepDisabled || loading}
                onClick={isLastStep ? handleSave : handleNext}
            />
        </Box>
    );
};
