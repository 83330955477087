import { appName, appApiUrl, authApiBaseURL } from '../../config/appConfiguration';
import { parseAppUser, parseLoginResponse } from './userParsers';
import { Repository } from '../Repository';
import { Network } from '../../network/Network';

/**
 * Class to communicate with API's authentication resources.
 */
class AccountRepository extends Repository {
    constructor() {
        super(appApiUrl);
        this.networkAuth = new Network({ baseURL: authApiBaseURL });
    }

    /**
     * Get token from The Gate for an auhorised user based on Mazars credentials
     * Credentials: email / password / country code
     */
    login = async (email, password, countryCode) => {
        let result = await this.networkAuth.post(
            `user/login`,
            {
                email: email,
                password: password,
                countryCode: countryCode
            },
            {
                headers: {
                    ...this.buildHeaders(),
                    appname: appName
                }
            }
        );

        result.tryParsingValue(parseLoginResponse);

        return result;
    };

    logout = async () => {
        let result = await this.network.post(`user/logout`, {}, {
            headers: this.buildHeaders()
        });

        return result;
    };

    /**
     * Get information about a specific appUser (from token)
     */
    fetchUserInfo = async () => {
        let result = await this.network.get(`user/info`, {
            headers: {
                ...this.buildHeaders(),
                appname: appName
            }
        });

        result.tryParsingValue(parseAppUser);
        return result;
    };

    /**
     * Create a user.
     * TODO: check if it is concerning a special user???
     * @param {*} user
     * @returns
     */
    create = async (user) => {
        let result = await this.network.post(`user`, user, {
            headers: this.buildHeaders()
        });

        return result;
    };

    /**
     * Update a user.
     * TODO: check if it concern a special user role or not??
     * @param {*} userId
     * @param {*} user
     * @returns
     */
    update = async (userId, user) => {
        let result = await this.network.post(`user/${userId}`, user, {
            headers: this.buildHeaders()
        });

        return result;
    };

    /**
     * Delete user.
     * TODO: check is you delete a special user role or a user??
     * @param {*} userId
     * @returns
     */
    delete = async (userId) => {
        let result = await this.network.post(`user/${userId}`, null, {
            headers: this.buildHeaders()
        });

        return result;
    };

    /// revoke access of the contact from the platform (all his engagements)
    revokeAccess = async (email, inviter) => {
        const body = { email, inviter };

        return await this.network.delete('user/access/revoke', body, {
            headers: {
                ...this.buildHeaders(true),
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                mode: 'no-cors'
            }
        });
    };
}

export const accountRepository = new AccountRepository();
