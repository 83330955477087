import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import { ChevronRight, HomeOutlined } from '@mui/icons-material';
import { isAuthorisedProfile } from '../../auth/UserHelper';
import { PROFILE } from '../../../types/types';
import { ROUTES } from '../../../navigation/Routes';

function BreadCrumbsComponent({ crumbs, setSelectedProfileRoute, defaultPath }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.account.user);

    if (!crumbs?.length) {
        return null;
    }

    const goHome = () => {
        // TODO: get main profile of the user @seealso Auth.js
        isAuthorisedProfile(PROFILE.AUDITOR, user.profil)
            ? dispatch(actions.setSessionProfile(PROFILE.AUDITOR))
            : setSelectedProfileRoute(defaultPath);
    };

    const getLinkClassName = isLast => {
        const opacity = isLast ? 'opacity-100' : 'opacity-75';
        return 'text-white text-hover-white hover-opacity-100 ' + opacity;
    };

    return (
        <div className="d-flex align-items-center font-weight-bold my-2">
            <Link to={defaultPath ?? ROUTES.AUTH} onClick={goHome}>
                <HomeOutlined className="text-white text-hover-white opacity-75 hover-opacity-100" />
            </Link>

            {crumbs.map(({ path, title }, index) => (
                <div key={index} className="mt-1">
                    <ChevronRight />
                    <Link className={getLinkClassName(index === crumbs.length - 1)} to={path}>
                        {title}
                    </Link>
                </div>
            ))}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        defaultPath: state.app.defaultPath
    };
};

const mapDispatchToProps = dispatch => ({
    setSelectedProfileRoute: selectedProfile => {
        dispatch(actions.setSelectedProfileRoute(selectedProfile));
    }
});

export const Breadcrumbs = connect(mapStateToProps, mapDispatchToProps)(BreadCrumbsComponent);
