import { Card, CardHeader, CardHeaderToolbar } from '../../../../partials/controls/Card';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { EngagementToolbarItems } from '../../EngagementToolbarItems';
import React from 'react';
import { getLocalizedString } from '../../../../i18n/i18Helper';
import { useTableBaseStyles } from '../../../common/MZRPaginatedTable';
import { ROUTES } from '../../../../navigation/Routes';
import { useHistory } from 'react-router-dom';
import { FilterTableSpec } from '../../../../hooks/useFilterTable';

interface ClientEngagementHeaderProps {
    engagementName?: string;
    engagementId: string;
    filterManager: FilterTableSpec;
    handleSign: (requests: any[]) => void;
    isSignHidden: boolean;
}

export const ClientEngagementHeader = ({
    engagementName,
    engagementId,
    filterManager,
    isSignHidden,
    handleSign
}: ClientEngagementHeaderProps) => {
    const intl = useIntl();
    const classes = useTableBaseStyles();
    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    const handleSettings = () => {
        history.push(ROUTES.ENGAGEMENT_SETTINGS.replace(':engagementId', engagementId));
    };

    return (
        <>
            {
                // @ts-ignore
                <Card className={clsx(classes.root, classes.margin)}>
                    {
                        // @ts-ignore
                        <CardHeader
                            title={getLocalizedString(intl, 'ENGAGEMENT.CARDHEADER.TITLE', {
                                name: engagementName || getLocalizedString(intl, 'COMMON.LOADING.IN.PROGRESS')
                            })}>
                            {
                                // @ts-ignore
                                <CardHeaderToolbar>
                                    <EngagementToolbarItems
                                        isCreateConfirmationHidden
                                        handleCreateConfirmation={undefined}
                                        moreOptions={undefined}
                                        handleBack={handleGoBack}
                                        handleSettings={handleSettings}
                                        isFilteringMode={filterManager.isFilteringMode}
                                        handleFilter={filterManager.onFilterClicked}
                                        handleSign={handleSign}
                                        isSignHidden={isSignHidden}
                                    />
                                </CardHeaderToolbar>
                            }
                        </CardHeader>
                    }
                </Card>
            }
        </>
    );
};
