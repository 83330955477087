const inMemoryTokenManager = () => {
    let logoutEventName = 'mconf-logout';
    let inMemoryToken = null;

    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryToken = null;
        }
    });

    const getToken = () => inMemoryToken;

    const setToken = (token) => {
        inMemoryToken = token;
        return true;
    };

    const isTokenAvailable = () => !!inMemoryToken;

    const clearToken = () => {
        inMemoryToken = null;
        let logoutTime = Date.now()
        window.localStorage.setItem(logoutEventName, logoutTime.toString());
        return true;
    };

    const setLogoutEventName = name => logoutEventName = name;

    return {
        clearToken,
        getToken,
        setLogoutEventName,
        setToken,
        isTokenAvailable,
    }
};

export default inMemoryTokenManager();
