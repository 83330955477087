import { deepFreeze } from '../../helpers/FreezeHelper';
import { DEFAULT_PAGE_SIZE } from '../../helpers/TableHelper';

const InitPaginatedObject = {
    totalItems: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    totalPages: 0,
    pageIndex: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    itemType: '',
    filters: {},
    items: []
};

export const initPaginatedObject = deepFreeze(InitPaginatedObject);
