import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardHeaderToolbar } from '../../../partials/controls/Card';
import { FormattedMessage } from 'react-intl';
import { Alert } from '@mui/material';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { ROUTES } from '../../../navigation/Routes';
import { initPaginatedObject } from '../../common/InitPaginatedObject';
import { useArchiveEngagement, useDeleteConfirmation, useDeleteEngagement } from '../../../hooks/useEntityActionPopup';
import { EngagementToolbarItems } from '../EngagementToolbarItems';
import { hasEditionRights } from '../../../services';
import { InputOutlined } from '@mui/icons-material';
import { ENGAGEMENT_COLUMNS_FILTERS } from '../InitEngagement';
import { getMoreSettings, getWorkflowActionsStatus, prepareFilters } from '../../../helpers/EngagementHelper';
import { useTableBaseStyles } from '../../common/MZRPaginatedTable';
import { useFilterTable } from '../../../hooks/useFilterTable';
import { EngagementContent } from './EngagementContent';

export function AuditorEngagementContent(props) {
    const {
        openRecycleBin,
        engagement,
        confirmationPaginated,
        handleFilterChange,
        resetActiveStepPosition,
        deleteConfirmation,
        deleteEngagement,
        archiveEngagement,
        downloadEngagement,
        intl,
        getDownloadEngagementOptions,
        confirmationCategories,
        workflowActions,
        isFilteringMode,
        saveSelectedConfirmationIndex
    } = props;

    const workflowActionsStatus = useMemo(() => getWorkflowActionsStatus(intl), []);
    const history = useHistory();
    const classes = useTableBaseStyles();
    const filterManager = useFilterTable(ENGAGEMENT_COLUMNS_FILTERS, handleFilterChange, isFilteringMode);

    useEffect(() => {
        if (confirmationCategories && engagement?.clientCompanies && workflowActions && workflowActionsStatus)
            filterManager.updateFiltersValues(
                prepareFilters(
                    props.filters,
                    engagement.clientCompanies,
                    confirmationCategories,
                    workflowActions,
                    workflowActionsStatus
                )
            );
    }, [confirmationCategories, engagement?.clientCompanies, workflowActions, workflowActionsStatus]);

    const [confirmationsLoaded, setConfirmationLoaded] = useState(initPaginatedObject);
    const [confirmationToDelete, setConfirmationToDelete] = useState(null);
    const [isLetterheadMissing, setIsLetterheadMissing] = useState(false);

    useEffect(() => {
        setConfirmationLoaded(confirmationPaginated);
    }, [confirmationPaginated]);

    useEffect(() => {
        setIsLetterheadMissing(
            engagement?.clientCompanies
                ? !!engagement?.clientCompanies.find(company => company?.isLetterHeadActivated & !company?.letterHead)
                : false
        );
    }, [engagement]);

    const {
        delete: DeleteConfirmationComponent,
        handleDeleteClick: handleDeleteConfirmationClick
    } = useDeleteConfirmation({
        user: props.user,
        deleteAPI: deleteConfirmation,
        warningMessageId: 'MESSAGE.TRASH.CONFIRMATION.CONTENT'
    });

    const { delete: DeleteEngagementComponent, handleDeleteClick: handleDeleteEngagementClick } = useDeleteEngagement({
        user: props.user,
        deleteAPI: deleteEngagement,
        deleteEngagementCallback: isSuccess => isSuccess && history.replace(ROUTES.HOME)
    });

    const { archive: ArchiveEngagementComponent, handleArchiveClick } = useArchiveEngagement({
        user: props.user,
        archiveAPI: archiveEngagement,
        archiveEngagementCallback: isSuccess => isSuccess && history.replace(ROUTES.HOME)
    });

    const backToPreviousPage = () => {
        history.goBack();
    };

    const onRowClick = (state, rowData) => {
        const confirmationIndex = confirmationPaginated.items.findIndex(i => i.id === rowData.id);
        saveSelectedConfirmationIndex(confirmationIndex);

        history.push(
            ROUTES.CONFIRMATION.replace(':engagementId', engagement?.id).replace(':confirmationId', rowData.id)
        );
    };

    const handleCreateConfirmation = () => {
        !!engagement
            ? history.push({
                  pathname: ROUTES.ENGAGEMENT_CONFIRMATION_CREATE.replace(':engagementId', engagement.id),
                  state: { engagement: engagement }
              })
            : console.error('No engagement has been found');
    };

    const handleDeleteEngagement = () => {
        !!engagement && handleDeleteEngagementClick();
    };

    const onArchiveClick = async () => {
        !!engagement && handleArchiveClick();
    };

    const handleDownloadEngagement = async (engagement, downloadType) => {
        !!engagement && downloadEngagement(engagement, downloadType);
    };

    //This puts the confirmation in the recycle bin
    const onDeleteConfirmationClick = confirmationId => {
        if (engagement) {
            setConfirmationToDelete(confirmationId);
            handleDeleteConfirmationClick();
        }
    };

    const handleSettings = () => {
        resetActiveStepPosition();
        !!engagement
            ? history.push(ROUTES.ENGAGEMENT_SETTINGS.replace(':engagementId', engagement.id))
            : console.error('No engagement has been found');
    };

    const renderLetterheadMissingMessage = () => {
        return (
            <Alert severity="error" onClose={() => setIsLetterheadMissing(false)}>
                {FormattedErrorMessage(intl.formatMessage({ id: 'SETTINGS.LETTERHEAD.ALERT.MISSING.MESSAGE' }))}
                <InputOutlined fontSize="small" onClick={() => handleSettings()} />
            </Alert>
        );
    };

    const actions = [
        {
            id: 'delete',
            iconProps: { color: 'secondary' },
            icon: 'delete',
            tooltip: <FormattedMessage id="CONFIRMATION.DELETE.INFO" />,
            onClick: (_, rowData) => onDeleteConfirmationClick(rowData.id)
        }
    ];

    const downloadRef = useRef();
    return (
        <>
            {
                // @ts-ignore
                <Card className={clsx(classes.root, classes.margin)}>
                    {
                        // @ts-ignore
                        <CardHeader
                            title={
                                <FormattedMessage
                                    id="ENGAGEMENT.CARDHEADER.TITLE"
                                    values={{
                                        name:
                                            engagement?.name || intl.formatMessage({ id: 'COMMON.LOADING.IN.PROGRESS' })
                                    }}
                                />
                            }>
                            {
                                // @ts-ignore
                                <CardHeaderToolbar>
                                    <EngagementToolbarItems
                                        handleBack={backToPreviousPage}
                                        isCreateConfirmationHidden={!engagement?.isEditable || engagement?.archived}
                                        handleCreateConfirmation={handleCreateConfirmation}
                                        handleSettings={handleSettings}
                                        isFilteringMode={filterManager.isFilteringMode}
                                        handleFilter={filterManager.onFilterClicked}
                                        moreOptions={getMoreSettings({
                                            intl: intl,
                                            isArchiveHidden: engagement?.archived,
                                            isArchiveDisabled: !hasEditionRights(engagement, props.user),
                                            handleArchive: onArchiveClick,
                                            isDownloadHidden: false,
                                            downloadOptions: getDownloadEngagementOptions(
                                                engagement,
                                                handleDownloadEngagement
                                            ),
                                            handleOpenRecycleBin: openRecycleBin,
                                            handleDelete: handleDeleteEngagement,
                                            isDeleteHidden: !engagement?.isDeletable,
                                            downloadRef: downloadRef
                                        })}
                                    />
                                </CardHeaderToolbar>
                            }
                        </CardHeader>
                    }
                </Card>
            }
            {isLetterheadMissing && renderLetterheadMissingMessage()}
            <EngagementContent
                confirmationsFilters={props.filters}
                filterManager={filterManager}
                actions={actions}
                handleFilterChange={handleFilterChange}
                engagement={engagement}
                confirmationCategories={confirmationCategories}
                workflowActions={workflowActions}
                loading={props.loading}
                data={confirmationsLoaded}
                handleRowSelection={onRowClick}
                workflowActionsStatus={workflowActionsStatus}
            />
            <DeleteConfirmationComponent confirmationId={confirmationToDelete} />
            <DeleteEngagementComponent engagementId={engagement?.id} />
            <ArchiveEngagementComponent engagement={engagement} />
        </>
    );
}
