import {
    CheckOutlined,
    DeleteForever,
    DeleteOutlined,
    FindInPageOutlined,
    Restore,
    UndoOutlined
} from '@mui/icons-material';
import { Palette } from '../../styles/colors';
import { MZRTableActionIcon } from '../common/MZRTableActionIcon';
import { getVisibilityIcon } from '../../helpers/DocumentHelper';

export const DocumentTableActions = ({ action, data, authorizedVisualisation, authorizedDownload }) => {
    const actionReducer = () => {
        let actionInfo = action;
        if (typeof action.action === 'function') {
            actionInfo = action.action(data);
        }

        switch (actionInfo.icon) {
            case 'open':
                return (
                    <MZRTableActionIcon
                        data={data}
                        {...actionInfo}
                        icon={getVisibilityIcon(data, authorizedVisualisation, authorizedDownload)}
                    />
                );

            case 'validate':
                return <MZRTableActionIcon data={data} {...actionInfo} icon={<CheckOutlined color="primary" />} />;

            case 'lost':
                return <MZRTableActionIcon data={data} {...actionInfo} icon={<FindInPageOutlined color="primary" />} />;

            case 'refuse':
                return <MZRTableActionIcon data={data} {...actionInfo} icon={<UndoOutlined color="primary" />} />;
            //TODO actions: handle them automatically
            case 'delete':
                return (
                    <MZRTableActionIcon
                        data={data}
                        {...actionInfo}
                        icon={<DeleteOutlined sx={{ color: Palette.red }} />}
                    />
                );

            case 'check':
                return <MZRTableActionIcon data={data} {...actionInfo} icon={<Restore color="primary" />} />;

            case 'clear':
                return (
                    <MZRTableActionIcon
                        data={data}
                        {...actionInfo}
                        icon={<DeleteForever sx={{ color: Palette.red }} />}
                    />
                );

            default:
                return null;
        }
    };

    return <> {actionReducer()}</>;
};
