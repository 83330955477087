import { appApiUrl } from '../../config/appConfiguration';
import { Repository } from '../Repository';

class LetterHeadRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetch = async (letterHeadId) => {
        return await this.network.get(`letterhead/${letterHeadId}`, {
            headers: this.buildHeaders()
        });
    };

    fetchFile = async (letterHeadId) => {
        return await this.network.get(`letterhead/${letterHeadId}/file`, {
            headers: this.buildHeaders()
        });
    };

    fetchByCompany = async (engagementCompanyId) => {
        return await this.network.get(`letterhead/engagementClientCompany/${engagementCompanyId}`, {
            headers: this.buildHeaders()
        });
    };

    delete = async (letterHeadId) => {
        return await this.network.delete(`letterhead/${letterHeadId}`, null, {
            headers: this.buildHeaders()
        });
    };

    create = async (letterHeadInfo) => {
        const formData = new FormData();
        formData.append('File', letterHeadInfo.file);
        formData.append('JSON', JSON.stringify(letterHeadInfo.info));

        return await this.network.post('letterhead', formData, {
            headers: this.buildHeaders(true)
        });
    };
}

export const letterHeadRepository = new LetterHeadRepository();
