import { ServiceResult } from '../../network/ServiceResult';
import { ConfirmationRequestSpec, UploadDocumentSpec } from '../../../types/types';
import { appApiUrl } from '../../config/appConfiguration';
import { parsePaginationResponse } from '../parsers';
import { buildFilters } from '../../network/mzrRequest';
import { parseConfirmationRequest, parseConfirmationRequests } from './confirmationParsers';
import { Repository } from '../Repository';

export interface ConfirmationRequestRepositorySpec {
    fetchAll(filters: Record<string, any>): Promise<ServiceResult>;

    fetch(requestId: string): Promise<ServiceResult>;

    create(request: ConfirmationRequestSpec): Promise<ServiceResult>;

    update(request: ConfirmationRequestSpec): Promise<ServiceResult>;

    delete(requestId: string): Promise<ServiceResult>;

    upload(letterInfo: any): Promise<ServiceResult>;

    confirm(request: ConfirmationRequestSpec): Promise<ServiceResult>;
}

export class ConfirmationRequestRepository extends Repository implements ConfirmationRequestRepositorySpec {
    constructor() {
        super(appApiUrl);
    }

    async fetchAll(filters: Record<string, any>): Promise<ServiceResult> {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`confirmationRequest/${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseConfirmationRequests));
        return result;
    }

    async fetch(requestId: string): Promise<ServiceResult> {
        let result = await this.network.get(`confirmationRequest/${requestId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseConfirmationRequest));
        return result;
    }

    async create(request: ConfirmationRequestSpec): Promise<ServiceResult> {
        return await this.network.post('confirmationRequest', request, {
            headers: this.buildHeaders()
        });
    }

    async update(request: ConfirmationRequestSpec): Promise<ServiceResult> {
        let result = await this.network.put(`confirmationRequest/${request.id}`, request, {
            headers: this.buildHeaders()
        });

        return result;
    }

    async delete(requestId: string): Promise<ServiceResult> {
        return await this.network.delete(
            `confirmationRequest/${requestId}`,
            {},
            {
                headers: this.buildHeaders()
            }
        );
    }

    upload(letterInfo: UploadDocumentSpec): Promise<ServiceResult> {
        const result = new ServiceResult({ body: {}, status: 200, error: '', headers: {} });
        return Promise.resolve(result);
    }

    confirm(request: ConfirmationRequestSpec): Promise<ServiceResult> {
        const result = new ServiceResult({ body: {}, status: 200, error: '', headers: {} });

        return Promise.resolve(result);
    }
}

export const confirmationRequestRepository = new ConfirmationRequestRepository();
