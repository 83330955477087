import { Network } from '../network/Network';
import inMemoryTokenManager from '../../kit/network/inMemoryToken';

export abstract class Repository {
    public network: Network;

    protected constructor(baseUrl: string | undefined) {
        this.network = new Network({ baseURL: baseUrl });
    }

    public buildHeaders(tokenOnly: boolean = false): Record<string, any> {
        let headers = {
            'Access-Control-Allow-Origin': '*',
            mode: 'no-cors',
            Accept: 'application/json'
        };

        var userToken = inMemoryTokenManager.getToken();
        if (!!userToken) {
            if(tokenOnly){
                return {'Authorization': `Bearer ${userToken}`};
            } else {
                headers['Authorization'] = `Bearer ${userToken}`;
            }
        }

        return headers;
    }
}
