import { EngagementCompanyLetterHeads } from '../../../engagement/sections/EngagementCompanyLetterHeads';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { LetterHeadService } from '../../../../services';
import { ServiceResult } from '../../../../kit/network/ServiceResult';
import { getSessionStatus } from '../../../../enhancers/wichCheckSession';
import { SessionStatus } from '../../../../kit/utils/constants';
import { UIScaffoldChildContext } from '../../../../scaffolder/UIScaffold';
import { LetterHeadContext } from '../../../../context';
import { ClientRequest } from '../../../../types/types';

interface ClientRequestLetterHeadStepProps {
    request: ClientRequest;
    fetchRequests: () => Promise<any>;
    handleRequestUpdate: (values: any) => void;
}

export const ClientRequestLetterHeadStep = ({
    request,
    setLoading,
    setToast,
    setSessionExpired,
    fetchRequests
}: ClientRequestLetterHeadStepProps & UIScaffoldChildContext): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles();

    const letterHeadManager = LetterHeadService({
        intl: intl,
        checkSession: (result: ServiceResult) => {
            const status = getSessionStatus(result);
            setSessionExpired(status === SessionStatus.invalid);
            return status;
        },
        addToastMessage: (toast: any) => {
            setToast(toast.title, toast.description, toast.type);
        },
        setLoading: setLoading,
        callback: async (isSuccess: boolean) => {
            if (isSuccess) {
                await fetchRequests();
            }
        }
    });

    return (
        <>
            <LetterHeadContext.Provider
                value={{
                    createLetterHead: letterHeadManager.createLetterHead,
                    deleteLetterHead: letterHeadManager.deleteLetterHead,
                    fetchLetterHeadFile: letterHeadManager.fetchLetterHeadFile
                }}>
                <EngagementCompanyLetterHeads
                    intl={intl}
                    disabled={false}
                    loading={false}
                    classes={classes}
                    companies={[request.clientCompany]}
                />
            </LetterHeadContext.Provider>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    margin: {
        margin: theme.spacing(1)
    },
    textField: {
        width: '98%',
        paddingLeft: 25,
        paddingRight: 25
    },
    selectField: {
        width: '90%',
        paddingLeft: 25,
        paddingRight: 25
    },
    inputField: {
        width: '80%',
        paddingLeft: 25,
        paddingRight: 25
    },
    divider: {
        height: 1,
        margin: 1
    },
    memberContainer: { width: '80%', padding: '0 25px' },
    memberItems: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        margin: '0 25%'
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    }
}));
