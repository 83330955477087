import { deepFreeze } from '../../../helpers/FreezeHelper';

const InitRobotConfig = {
    id: null,
    clusterId: null,
    administrators: '',
    mailingConfiguration: {},
    engagementCodePattern: '',
    description: '',
    confirmationCodePattern: ''
};

export const initRobotConfig = deepFreeze(InitRobotConfig);
