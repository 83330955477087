import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CompanyTable, EditCompany, initCompany } from '../../company';
import { Button, Box } from '@material-ui/core';
import { ClientImportForm } from './ClientImportForm';
import { IMPORT_COLUMN_NAME } from './InitClientImport';
import { COMPANY_TYPE } from '../../../kit/utils/constants';
import { formatGenerateFileRequest } from '../../../helpers/ItemHelper';
import { UIDialog } from '../../common/UIDialog';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    },
    maxWidth: 'lg'
}));

function ClientConfigComponent(props) {
    const {
        intl,
        clientCompaniesPaginated,
        loading,
        updateCompany,
        downloadFile,
        uploadFile,
        handleFilterChange
    } = props;

    const classes = useStyles();
    const sbmtCompanyRef = useRef();
    const sbmtImportFormRef = useRef();

    const [companySelected, setCompanySelected] = useState(initCompany);
    const [openDialogCompany, setOpenDialogCompany] = useState(false);
    const [openDialogImportForm, setOpenDialogImportForm] = useState(false);

    const handleCompanyPopUpOpening = () => setOpenDialogCompany(!openDialogCompany);
    const handleImportFormPopUpOpening = () => setOpenDialogImportForm(!openDialogImportForm);

    const handleCompanySelect = company => {
        setCompanySelected({ ...initCompany, ...company });
        setOpenDialogCompany(!openDialogCompany);
    };

    const handleClose = () => {
        if (sbmtCompanyRef && sbmtCompanyRef.current) {
            sbmtCompanyRef.current.saveForm();
        }
    };

    const handleCompanySubmit = async values => {
        const { isSuccess } = await updateCompany(values);
        isSuccess && setOpenDialogCompany(!openDialogCompany);
    };
    const handleExcelDownload = async () => {
        const fileRequest = formatGenerateFileRequest(
            intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.EXCEL.FILE.NAME.LABEL' }),
            intl.formatMessage({
                id: 'CLIENT.CONFIG.IMPORT.EXCEL.FILE.WORKSHEETNAME.LABEL'
            }),
            IMPORT_COLUMN_NAME,
            intl
        );
        await downloadFile(fileRequest);
    };

    const handleExcelUpload = async values => {
        const result = await uploadFile(values);
        result?.isSuccess && setOpenDialogImportForm(!openDialogImportForm);
    };

    const handleExcelImport = () => {
        setOpenDialogImportForm(!openDialogImportForm);
    };

    const handleImportFormClose = () => {
        if (sbmtImportFormRef && sbmtImportFormRef.current) {
            sbmtImportFormRef.current.saveForm();
        }
    };

    return (
        <>
            <Box component="span" m={1} className={clsx(classes.box, classes.boxButton)}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleExcelDownload}>
                    <FormattedMessage id="CLIENT.CONFIG.EXCEL.TEMPLATE.BUTTON.DOWNLOAD.LABEL" />
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleExcelImport}>
                    <FormattedMessage id="CLIENT.CONFIG.EXCEL.TEMPLATE.BUTTON.IMPORT.LABEL" />
                </Button>
            </Box>

            <CompanyTable
                intl={intl}
                shouldDisplayAdminColumns={true}
                loading={loading}
                companiesPaginated={clientCompaniesPaginated}
                handleCompanyClick={handleCompanySelect}
                handleFilterChange={handleFilterChange}
            />

            {openDialogCompany && (
                <UIDialog
                    open={openDialogCompany}
                    titleId="DIALOG.COMPANY.EDIT.TITLE"
                    onSave={handleClose}
                    onClose={handleCompanyPopUpOpening}
                    saveTitleId="DIALOG.BUTTON.VALIDATE">
                    <EditCompany
                        variant="outlined"
                        company={companySelected}
                        companyType={COMPANY_TYPE.CLIENT}
                        handleSubmit={handleCompanySubmit}
                        sbmtCompanyRef={sbmtCompanyRef}
                    />
                </UIDialog>
            )}

            {openDialogImportForm && (
                <UIDialog
                    classes={classes}
                    open={openDialogImportForm}
                    titleId="CLIENT.CONFIG.EXCEL.TEMPLATE.BUTTON.IMPORT.LABEL"
                    onSave={handleImportFormClose}
                    onClose={handleImportFormPopUpOpening}
                    saveTitleId="DIALOG.BUTTON.VALIDATE">
                    <ClientImportForm handleSubmit={handleExcelUpload} sbmtImportFormRef={sbmtImportFormRef} />
                </UIDialog>
            )}
        </>
    );
}

ClientConfigComponent.defaultProps = {
    loading: false
};

ClientConfigComponent.propTypes = {
    /**
     * List of companies.
     */
    clientCompaniesPaginated: PropTypes.object,
    /**
     * Display admin columns.
     */
    shouldDisplayAdminColumns: PropTypes.bool,
    /**
     * Indicator of loading during API requests.
     */
    loading: PropTypes.bool,
    /**
     * Function tu update a company.
     */
    updateCompany: PropTypes.func
};

export const ClientConfig = injectIntl(ClientConfigComponent);
