import * as React from 'react';
import { MouseEventHandler, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Palette } from '../../styles/colors';
import { UIText } from './UIText';
import { Box, Divider } from '@mui/material';
import { MenuProps, styled } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        // minWidth: 50,
        alignItems: 'flex-start',
        color: Palette.primaryTextColor,
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 20,
                color: Palette.primaryTextColor,
                marginRight: theme.spacing(1)
            },
            '&:active': {
                backgroundColor: Palette.whiteSmoke
            }
        }
    }
}));

export interface UIDropDownMenuEntryProps {
    id: string;
    title?: string;
    handler?: MouseEventHandler<any>;
    titleStyle?: any;
    titleComponent?: any;
    nestedMenu?: any;
    disabled?: boolean;
    tooltip?: string;
    divider?: boolean;
    nested?: boolean;
}

export interface UIDropDownMenuProps extends UIDropDownMenuEntryProps {
    entries: UIDropDownMenuEntryProps[];
}

export const UIDropDownMenu = ({ entries, title, titleStyle, titleComponent: TitleComponent }: UIDropDownMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (handler?: MouseEventHandler<any>) => (event: React.MouseEvent<HTMLElement>) => {
        handleCloseMenu();
        handler && handler(event);
    };

    return (
        <>
            {TitleComponent ? (
                <Box onClick={handleOpenMenu} title="header">
                    <TitleComponent />
                </Box>
            ) : (
                <UIText
                    title="header"
                    text={title ?? ''}
                    style={titleStyle ?? {}}
                    sx={{
                        color: Palette.primaryTextColor,
                        textAlign: 'center',
                        padding: 1
                    }}
                    onClick={handleOpenMenu}
                />
            )}

            <StyledMenu
                sx={{ mt: '30px', maxWidth: '30%' }}
                id="menu-appbar"
                open={isMenuOpen}
                keepMounted
                anchorEl={anchorEl}
                onClose={handleCloseMenu}>
                {entries.map(
                    (
                        {
                            id,
                            title,
                            handler,
                            divider,
                            titleComponent: TitleComponent,
                            nestedMenu: NestedMenu,
                            disabled = false,
                            titleStyle = {},
                            tooltip,
                            nested
                        },
                        index
                    ) => {
                        return (
                            <MenuItem
                                style={{ width: '100%' }}
                                key={id}
                                onClick={nested ? undefined : onMenuItemClick(handler)}
                                disabled={disabled}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        padding: 1,
                                        display: 'flex'
                                    }}>
                                    {NestedMenu ? (
                                        <NestedMenu />
                                    ) : TitleComponent ? (
                                        <TitleComponent />
                                    ) : (
                                        <UIText textAlign="center" text={title ?? ''} style={titleStyle} />
                                    )}

                                    {divider && index < entries.length - 1 && <Divider />}
                                </Box>
                            </MenuItem>
                        );
                    }
                )}
            </StyledMenu>
        </>
    );
};
