import React, { useEffect, useState } from 'react';
import { EngagementRecycleBin } from '.';
import { AuditorEngagementContent } from '.';
import { initPaginatedObject } from '../common/InitPaginatedObject';
import { useIntl } from 'react-intl';

export function EngagementContainer(props) {
    const {
        engagement,
        confirmationPaginated,
        handleFilterChange,
        eraseConfirmation,
        restoreConfirmation,
        loading,
        user,
        ...rest
    } = props;

    const intl = useIntl();
    const [isRecycleBinDisplay, setIsRecycleBinDisplay] = useState(false);
    const [rawConfirmations, setRawConfirmations] = useState(confirmationPaginated);

    useEffect(() => {
        setRawConfirmations(initPaginatedObject);
        handleFilterChange({ isTrashed: isRecycleBinDisplay });
    }, [isRecycleBinDisplay]);

    useEffect(() => {
        setRawConfirmations(confirmationPaginated);
    }, [confirmationPaginated]);

    const handleCloseRecycleBin = () => {
        setIsRecycleBinDisplay(false);
    };

    const handleOpenRecycleBin = () => {
        setIsRecycleBinDisplay(true);
    };

    return isRecycleBinDisplay ? (
        <EngagementRecycleBin
            closeRecycleBin={handleCloseRecycleBin}
            engagement={engagement}
            handleFilterChange={handleFilterChange}
            confirmationPaginated={rawConfirmations}
            eraseConfirmation={eraseConfirmation}
            restoreConfirmation={restoreConfirmation}
            intl={intl}
            loading={loading}
            user={user}
        />
    ) : (
        <AuditorEngagementContent
            openRecycleBin={handleOpenRecycleBin}
            engagement={engagement}
            handleFilterChange={handleFilterChange}
            confirmationPaginated={rawConfirmations}
            intl={intl}
            loading={loading}
            user={user}
            {...rest}
        />
    );
}
