import { getLocalizedString } from '../../i18n/i18Helper';
import { DocumentTableSelect } from './DocumentTableSelect';
import { ConfirmationSelectField, DocumentTypeSelectField, EngagementSelectField } from '../engagement';
import { FormattedDate } from 'react-intl';
import { isAuthorisedProfile } from '../auth/UserHelper';
import { PROFILE } from '../../types/types';
import { DocumentTableStatusIcon } from './DocumentTableStatusIcon';
import { IntlShape } from 'react-intl/src/types';
import { UICheckbox } from '../common/UICheckbox';

export const configureDocumentTable = ({
    intl,
    columnsProps: {
        documentTypes,
        menuEngagements,
        setDisplayInvalidAddressEnabled = false,
        handleEngagementChanged,
        handleLoadedItemsUpdate,
        handleConfirmationInputChange
    },
    actionsProps: {
        isRefuseEnabled,
        actionProfile,
        actions,
        optionalActionsEnabled,
        handleItemOpen,
        handleItemValidate,
        handleItemLost,
        handleItemRefuse
    }
}: {
    intl: IntlShape;
    columnsProps: {
        documentTypes: any[];
        menuEngagements: any[];
        setDisplayInvalidAddressEnabled?: boolean;
        handleEngagementChanged: (item: any) => void;
        handleConfirmationInputChange: (input: string, item) => void;
        handleLoadedItemsUpdate: (item: any) => void;
    };
    actionsProps: {
        isRefuseEnabled?: boolean;
        actionProfile: PROFILE;
        optionalActionsEnabled: boolean;
        actions: any[];
        handleItemOpen: (item: any) => void;
        handleItemValidate: (item: any) => void;
        handleItemLost: (item: any) => void;
        handleItemRefuse: (item: any) => void;
    };
}) => {
    const mainColumns = [
        {
            title: getLocalizedString(intl, 'DOCUMENT.TABLE.TITLE.ROBOT'),
            field: 'processedByRobot',
            render: rowData => <DocumentTableStatusIcon item={rowData} />
        },
        {
            title: getLocalizedString(intl, 'DOCUMENT.TABLE.TITLE.DOCUMENT_TYPE'),
            field: 'documentType',
            render: rowData => (
                <DocumentTableSelect
                    field="documentType"
                    item={rowData}
                    options={documentTypes}
                    component={DocumentTypeSelectField}
                    handleUpdate={handleLoadedItemsUpdate}
                />
            )
        },
        {
            title: getLocalizedString(intl, 'DOCUMENT.TABLE.TITLE.NAME'),
            field: 'name'
        },
        {
            title: getLocalizedString(intl, 'DOCUMENT.TABLE.TITLE.ENGAGEMENT'),
            field: 'engagementId',
            render: rowData => (
                <DocumentTableSelect
                    field="engagementId"
                    item={rowData}
                    handleUpdate={handleEngagementChanged}
                    component={EngagementSelectField}
                    options={menuEngagements}
                />
            )
        },
        {
            title: getLocalizedString(intl, 'DOCUMENT.TABLE.TITLE.CONFIRMATION'),
            field: 'confirmationId',
            render: rowData => {
                return (
                    <DocumentTableSelect
                        field="confirmationId"
                        item={rowData}
                        onInputChange={inputValue => handleConfirmationInputChange(inputValue, rowData)}
                        handleUpdate={handleLoadedItemsUpdate}
                        component={ConfirmationSelectField}
                        options={rowData.menuConfirmations || []}
                    />
                );
            }
        },
        {
            title: getLocalizedString(intl, 'DOCUMENT.TABLE.TITLE.CREATED'),
            field: 'created',
            type: 'date',
            render: rowData => <FormattedDate value={rowData.created} year="numeric" month="short" day="numeric" />,
            cellStyle: { textAlign: 'center' }
        }
    ];

    const optionalColumns = [        
        ...(setDisplayInvalidAddressEnabled ? [{
            title: getLocalizedString(intl, 'DOCUMENT.TABLE.TITLE.INVALID.ADDRESS'),
            field: 'isInvalidAddress',
            render: rowData => (
                <UICheckbox
                    label={''}
                    checked={rowData?.isInvalidAddress}
                    onChange={() => handleLoadedItemsUpdate({ ...rowData, isInvalidAddress: !rowData?.isInvalidAddress })}
                />),
            cellStyle: { textAlign: 'center' }
        }] : [])
    ];

    const columns = [...mainColumns, ...optionalColumns];

    const optionalActions = optionalActionsEnabled
        ? [
              {
                  icon: 'validate',
                  onClick: (_, rowData) => handleItemValidate(rowData),
                  tooltip: getLocalizedString(intl, 'DOCUMENT.TABLE.ACTION.TOOLTIP.VALIDATE')
              },
              {
                  icon: 'lost',
                  onClick: (_, rowData) => handleItemLost(rowData),
                  tooltip: getLocalizedString(intl, 'DOCUMENT.TABLE.ACTION.TOOLTIP.LOST'),
                  hidden: isAuthorisedProfile(PROFILE.AUDITOR, actionProfile)
              },
              {
                  icon: 'refuse',
                  onClick: (_, rowData) => handleItemRefuse(rowData),
                  tooltip: getLocalizedString(intl, 'DOCUMENT.TABLE.ACTION.TOOLTIP.REFUSE'),
                  hidden: isAuthorisedProfile(PROFILE.POSTIE, actionProfile) || !isRefuseEnabled
              }
          ]
        : [];

    const updatedActions = [
        {
            icon: 'open',
            onClick: (_, rowData) => handleItemOpen(rowData),
            tooltip: intl.formatMessage({ id: 'DOCUMENT.TABLE.ACTION.TOOLTIP.OPEN' })
        },
        ...optionalActions,
        ...actions
    ];
    return { actions: updatedActions, columns: columns };
};
