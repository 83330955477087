import React, { Component } from 'react';
import '../style.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { DOCUMENT_ITEM_TYPE, PROFILE } from '../../types/types';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import { Loader } from '../../components/loader/Loader';
import { ConfirmationService, fetchMenuEngagements, ItemService } from '../../services';
import { printDownloadedFile, saveDownloadedFile, UPLOAD_TYPES } from '../../helpers';
import { SupportWrapper } from '../../components/support/SupportWrapper';
import { PAPER_PROCESS_STATUS } from '../../components/document';
import { Alert } from '@mui/material';
import { FormattedErrorMessage } from '../../components/errorMessage/ErrorMessage';
import { NewlineText } from '../../components/common/NewlineText';
import { INIT_ITEMS_FILTER, SORT_CHOICES } from '../../kit/utils/constants';
import { fetchData } from '../../services/FetchHelper';
import { getLocalizedString } from '../../i18n/i18Helper';
import { ItemContext } from '../../context';
import {
    CONFIRMATION_COLUMNS_FILTERS,
    INIT_MENU_CONFIRMATION_FILTERS
} from '../../components/confirmation/common';

/**
 * Support screen dedicated to user with support role.
 *
 * User can upload scanned confirmation letter and affect them to an engagement/confirmation.
 *
 * Robot have access to uploaded document to process them affect them automaticaly to an engagement/confirmation.
 */
class Support extends Component {
    constructor(props) {
        super(props);
        this.setItemFilters = this.setItemFilters.bind(this);
        const paperSendingFilters = this.setItemFilters(this.state?.isPaperProcess);

        this.state = {
            loading: false,
            menuEngagements: null, // list of engagements displayed in the engagement dropdown
            menuConfirmationsFilters: {
                ...INIT_MENU_CONFIRMATION_FILTERS,
                [CONFIRMATION_COLUMNS_FILTERS.CLUSTER_ID]: props.currentClusterId,
                [CONFIRMATION_COLUMNS_FILTERS.USER_PROFILE]: PROFILE.POSTIE
            },
            checkSessionResults: [],
            isPaperProcess: true,
            errorMessage: '',
            itemsFilters: {
                userProfile: PROFILE.POSTIE,
                pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
                pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
                clusterId: props.currentClusterId,
                ...paperSendingFilters
            },
            docTypesFilters: {
                userProfile: PROFILE.POSTIE
            },

            engagementsFilters: {
                clusterId: props.currentClusterId,
                userProfile: PROFILE.POSTIE
            }
        };
    }

    isSupportAccessible = isAuthorisedProfile(PROFILE.POSTIE, this.props.user.profil);

    setLoading = isLoading => {
        this.setState({ loading: isLoading });
    };

    setErrorMessage = errorMessage => {
        this.setState({ errorMessage: errorMessage });
    };

    setItemFilters = isPaperProcess =>
        isPaperProcess ?? true
            ? {
                  pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
                  type: DOCUMENT_ITEM_TYPE.FILE,
                  paperSendingStatus: [PAPER_PROCESS_STATUS.PENDING, PAPER_PROCESS_STATUS.IN_PROGRESS],
                  sort: SORT_CHOICES.CREATED_DESC
              }
            : {
                  pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
                  engagementId: INIT_ITEMS_FILTER.ENGAGEMENT_ID,
                  isLost: INIT_ITEMS_FILTER.IS_LOST,
                  isTrashed: INIT_ITEMS_FILTER.IS_TRASHED,
                  displayMode: INIT_ITEMS_FILTER.DISPLAY_MODE_ROOT_ITEM_ONLY,
                  sort: SORT_CHOICES.CREATED_DESC
              };

    itemManager = ItemService({
        intl: this.props.intl,
        user: this.props.user,
        definedUserProfile: PROFILE.POSTIE,
        authorisedUploadItemType: UPLOAD_TYPES.SUPPORT,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        callback: async (isSuccess, _data, _) => isSuccess && (await this.fetchItems(this.state.itemsFilters)),
        setLoading: this.setLoading,
        setErrorMessage: this.setErrorMessage
    });

    confirmationService = ConfirmationService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading
    });

    fetchItems = async filters => {
        const result = await this.itemManager.fetch(filters);
        if (result?.isSuccess) {
            this.setState({ paginatedItems: result.parsedValue });
        }
    };

    downloadItem = async item => {
        await this.itemManager.download(item, (isSuccess, data, _) => isSuccess && saveDownloadedFile(data, item.name));
    };

    printItem = async item => {
        await this.itemManager.download(item, async (isSuccess, data, _) => isSuccess && printDownloadedFile(data));
    };

    fetchDocumentTypes = async filters => {
        const result = await this.itemManager.getDocumentTypes(filters);
        if (result?.isSuccess) {
            this.setState({ documentTypes: result?.body });
        }
    };

    fetchMenuEngagements = async () => {
        const { engagementsFilters } = this.props;

        await fetchMenuEngagements(this, engagementsFilters);
    };

    fetchMenuConfirmations = async values => {
        const { currentClusterId } = this.props;
        const filters = { ...this.state.menuConfirmationsFilters, clusterId: currentClusterId, ...values };
        let menuConfirmations = [];
        await this.confirmationService.fetchMenu(filters, (isSuccess, result) => {
            if (isSuccess) {
                menuConfirmations = result.parsedValue;
            }
        });

        this.setState({ menuConfirmationsFilters: filters });
        return menuConfirmations;
    };

    async componentDidMount() {
        const { itemsFilters, docTypesFilters } = this.state;
        await fetchData(this, [
            () => this.fetchItems(itemsFilters),
            () => this.fetchMenuEngagements(),
            () => this.fetchDocumentTypes(docTypesFilters)
        ]);
    }

    handleItemsFilterChange = values => {
        this.setState({ itemsFilters: { ...this.state.itemsFilters, ...values } });
    };

    handlePaperProcessChange = async isPaperProcess => {
        //TODO use removeProperties instead of deleting one by one
        let previousFilters = this.state.itemsFilters;
        delete previousFilters.engagementId;
        delete previousFilters.confirmationId;
        delete previousFilters.displaymode;
        delete previousFilters.isLost;
        delete previousFilters.isTrashed;
        delete previousFilters.type;
        delete previousFilters.paperSendingStatus;

        const paperSendingFilters = this.setItemFilters(isPaperProcess);
        let newFilters = {
            ...previousFilters,
            ...paperSendingFilters
        };

        this.setState({
            isPaperProcess: isPaperProcess,
            itemsFilters: newFilters
        });
    };

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.state.itemsFilters !== prevState.itemsFilters) {
            if (!this.state.loading) await this.fetchItems(this.state.itemsFilters);
        }

        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            this.handleItemsFilterChange({ clusterId: this.props.currentClusterId });
        }
    }

    render() {
        const { intl, user } = this.props;
        const { loading, paginatedItems, documentTypes, isPaperProcess, menuEngagements } = this.state;

        return !user.profil ? (
            <Loader />
        ) : !this.isSupportAccessible ? (
            <PageNotFound {...this.props} />
        ) : (
            <>
                {this.state.errorMessage && (
                    <Alert severity="error" onClose={() => this.setErrorMessage('')}>
                        {FormattedErrorMessage(
                            <NewlineText text={getLocalizedString(intl, this.state.errorMessage)} />
                        )}
                    </Alert>
                )}
                <ItemContext.Provider
                    value={{
                        deleteItem: this.itemManager.delete,
                        fetchItems: this.fetchItems,
                        fetchItemFile: this.itemManager.fetchFile,
                        fetchItemInfo: this.itemManager.fetchInfo,
                        uploadItems: this.itemManager.upload,
                        updateItem: this.itemManager.update,
                        downloadItem: this.downloadItem,
                        printItem: this.printItem,
                        filterItems: this.handleItemsFilterChange
                    }}>
                    <SupportWrapper
                        user={user}
                        loading={loading}
                        paginatedItems={paginatedItems}
                        documentTypes={documentTypes}
                        menuEngagements={menuEngagements} // TODO: will be replaced by a search
                        isPaperProcess={isPaperProcess}
                        handleFetchMenuConfirmations={this.fetchMenuConfirmations}
                        handlePaperProcessChange={this.handlePaperProcessChange}
                        handleConfirmationsFilterChange={this.handleConfirmationsFilterChange}
                    />
                </ItemContext.Provider>
            </>
        );
    }
}

Support.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array
};

Support.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const support = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(Support));
