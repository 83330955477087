export { LetterTemplateSetup } from './LetterTemplateSetup';
export { LostItemSetup } from './LostItemSetup';
export { EmailTemplateSetup } from './EmailTemplateSetup';
export { MailingSetup } from './MailingSetup';
export { RobotSetup } from './RobotSetup';
export { WorkflowSetup } from './WorkflowSetup';
export { GlobalClientSetup } from './GlobalClientSetup';
export { UserResourceSetup } from './UserResourceSetup';

export { StorageSetup } from './StorageSetup';
export { StorageDefaultSetup } from './StorageDefaultSetup';
export { StorageSharePointOnlineSetup } from './StorageSharePointOnlineSetup';
