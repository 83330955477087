import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';
import TextInput from '../../textField/TextInput';
import { UPLOAD_TYPES, FORM_FIELD } from '../../../helpers';
import { WorkflowWrapper } from '..';
import { LetterTemplateTagsSelector } from './LetterTemplateTagsSelector';
import { useForm } from '../../../hooks/useForm';

/**
 * Component to create a confirmation template.
 * @param {*} props
 * @returns
 */
function LetterTemplateFormComponent(props) {
    const {
        intl,
        letterTemplate,
        letterTemplateTags,
        confirmationCategories,
        workflows,
        languages,
        handleSubmit,
        isPreview,
        isEdition
    } = props;

    const variant = isPreview ? 'standard' : 'outlined';

    const buttonTemplFormRef = useRef(null);
    const triggerButtonClick = () => buttonTemplFormRef?.current?.click();
    props.sbmtTemplRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const LetterTemplateSchema = Yup.object().shape({
        id: Yup.string().nullable(true),
        confirmationCategoryId: Yup.string()
            .nullable(true)
            .required(
                intl.formatMessage({ id: 'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.CONFIRMATION.CATEGORY' })
            ),
        workflowId: Yup.string()
            .nullable(true)
            .required(intl.formatMessage({ id: 'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.WORKFLOW' })),
        name: Yup.string()
            .nullable(true)
            .min(3, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 3 }))
            .max(50, intl.formatMessage({ id: 'INVALID.FIELD.MAX.SYMBOLS' }, { min: 50 }))
            .required(intl.formatMessage({ id: 'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.NAME' })),
        description: Yup.string()
            .nullable(true)
            .min(3, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 3 }))
            .max(255, intl.formatMessage({ id: 'INVALID.FIELD.MAX.SYMBOLS' }, { min: 255 })),
        languageId: Yup.string()
            .nullable(true)
            .required(intl.formatMessage({ id: 'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.LANGUAGE' }))
        // tags: Yup.array().when('id', {
        //     is: null,
        //     then: Yup.array()
        //         .of(
        //             Yup.object().shape({
        //                 tagId: Yup.string(),
        //                 isMandatory: Yup.boolean()
        //             })
        //         )
        //         .required(intl.formatMessage({ id: 'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.TAGS' }))
        // }),
        // file: Yup.mixed()
        //     .nullable(true)
        //     .when('id', {
        //         is: null,
        //         then: Yup.mixed().required(
        //             intl.formatMessage({ id: 'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.UPLOAD' })
        //         )
        //     })
    });

    const L_TEMPLATE_FIELDS = [
        {
            id: 'confirmationCategoryId',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'LETTER_TEMPLATE.INFO.FIELD.CONFIRMATION.CATEGORY.LABEL', isRequired: true },
            options: confirmationCategories ? confirmationCategories : [{ id: 0, name: 'None' }],
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'workflowId',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'LETTER_TEMPLATE.INFO.FIELD.WORKFLOW.LABEL', isRequired: true },
            options: workflows ? workflows : [{ id: 0, name: 'None' }],
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'workflowId',
            fieldType: FORM_FIELD.COMPONENT_FIELD,
            labelItem: { id: 'LETTER_TEMPLATE.INFO.FIELD.WORKFLOW.LABEL', isRequired: true },
            component: WorkflowWrapper,
            options: workflows,
            variant: variant,
            disabled: isPreview,
            isCategoryDeletable: false
        },
        {
            id: 'name',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'LETTER_TEMPLATE.INFO.FIELD.NAME.LABEL', isRequired: true },
            component: TextInput,
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'description',
            fieldType: FORM_FIELD.TEXT_FIELD,
            labelItem: { id: 'LETTER_TEMPLATE.INFO.FIELD.DESCRIPTION.LABEL', isRequired: true },
            component: 'textarea',
            rows: 4,
            variant: 'outlined',
            disabled: isPreview
        },
        {
            id: 'languageId',
            fieldType: FORM_FIELD.SELECT_FIELD,
            labelItem: { id: 'LETTER_TEMPLATE.INFO.FIELD.LANGUAGE.LABEL', isRequired: true },
            options: languages ? languages : [{ id: 0, name: 'None' }],
            variant: variant,
            disabled: isPreview
        },
        {
            id: 'file',
            fieldType: FORM_FIELD.INPUT_FIELD,
            labelItem: { id: 'LETTER_TEMPLATE.INFO.FIELD.UPLOAD.LABEL', isRequired: true },
            acceptedFiles: UPLOAD_TYPES.TEMPLATES,
            showPreviews: true,
            dropzoneText: intl.formatMessage({ id: 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.MSG' }),
            previewText: intl.formatMessage({
                id: 'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.UPLOADED_FILES.LABEL'
            }),
            hidden: isPreview || isEdition
        },
        {
            id: 'tags',
            fieldType: FORM_FIELD.COMPONENT_FIELD,
            labelItem: { id: 'LETTER_TEMPLATE.INFO.FIELD.WORKFLOW.LABEL', isRequired: true },
            component: LetterTemplateTagsSelector,
            options: letterTemplateTags,
            variant: variant,
            disabled: isPreview,
            hidden: isPreview || isEdition
        }
    ];

    const { RenderForm } = useForm({
        enableReinitialize: true,
        initialValues: letterTemplate,
        validationSchema: LetterTemplateSchema,
        handleSubmit: handleSubmit
    });

    return (
        <>
            <RenderForm
                formFields={L_TEMPLATE_FIELDS}
                submitRef={buttonTemplFormRef}
                submitTrigger={triggerButtonClick}
            />
        </>
    );
}

LetterTemplateFormComponent.propTypes = {
    letterTemplate: PropTypes.object.isRequired,
    letterTemplateTags: PropTypes.array,
    confirmationCategories: PropTypes.array,
    workflows: PropTypes.array,
    languages: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    isPreview: PropTypes.bool,
    isEdition: PropTypes.bool
};

export const LetterTemplateForm = injectIntl(LetterTemplateFormComponent);
