import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../partials/controls/Card';
import { TilesWidget } from '../tileswidget/TilesWidget';
import { Box, Button, Grid, CircularProgress } from '@mui/material';
import {
    PeopleAltRounded,
    DescriptionRounded,
    BusinessRounded,
    AccountBalanceRounded,
    AssignmentRounded,
    FindInPageRounded
} from '@mui/icons-material';

const classes = {
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: 0.5
    }
};

export function MetricsComponent(props) {
    const { loading, metrics } = props;
    const history = useHistory();

    const [metricsLoaded, setMetricsLoaded] = useState({});

    useEffect(() => {
        metrics && setMetricsLoaded(metrics);
    }, [metrics]);

    const backToPreviousPage = () => {
        history.goBack();
    };

    const METRICS_DISPLAY = {
        numberOfAppUsers: {
            icon: <PeopleAltRounded />,
            label: 'METRICS.APPUSERS.LABEL'
        },
        numberOfConfirmations: {
            icon: <DescriptionRounded />,
            label: 'METRICS.CONFIRMATIONS.LABEL'
        },
        numberOfEngagementClientCompanies: {
            icon: <BusinessRounded />,
            label: 'METRICS.CLIENT.COMPANIES.LABEL'
        },
        numberOfEngagementThirdPartyCompanies: {
            icon: <AccountBalanceRounded />,
            label: 'METRICS.THIRDPARTY.COMPANIES.LABEL'
        },
        numberOfEngagements: {
            icon: <AssignmentRounded />,
            label: 'METRICS.ENGAGEMENTS.LABEL'
        },
        numberOfItems: {
            icon: <FindInPageRounded />,
            label: 'METRICS.ITEMS.LABEL'
        }
    };

    return (
        <Card>
            <CardHeader title={<FormattedMessage id="METRICS.CARD.TITLE.LABEL" />}>
                <CardHeaderToolbar>
                    <Box component="span" m={1} sx={{ ...classes.box, ...classes.boxButton }}>
                        <Button type="button" variant="outlined" sx={classes.button} onClick={backToPreviousPage}>
                            <i className="fa fa-arrow-left"></i>
                            <FormattedMessage id="METHOD.CREATE.CONFIRMATION.BUTTON.BACK.LABEL" />
                        </Button>
                    </Box>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Grid container item justifyContent="space-around" alignItems="center" xs={12} spacing={3}>
                    {loading && (
                        <div className="fullCenter">
                            <CircularProgress />
                        </div>
                    )}

                    {metricsLoaded &&
                        !loading &&
                        React.Children.toArray(
                            Object.keys(metricsLoaded).map(metric => (
                                <Grid item xs={4}>
                                    <TilesWidget
                                        className="gutter-b bg-light"
                                        iconColor="white"
                                        widgetHeight="150px"
                                        number={metricsLoaded[metric]}
                                        type={<FormattedMessage id={METRICS_DISPLAY[metric].label} />}
                                        icon={METRICS_DISPLAY[metric].icon}
                                    />
                                </Grid>
                            ))
                        )}
                </Grid>
            </CardBody>
        </Card>
    );
}
