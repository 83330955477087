import { COMPANY_TYPE } from '../kit/utils/constants';
import {
    engagementThirdPartyCompanyRepository,
    engagementClientCompanyRepository,
    globalClientCompanyRepository,
    serializeEngagementCompany,
    serializeEngagementCompaniesToDelete
} from '../kit/repository';
import { performAPIAction } from './BaseService';

const ClientCompanyMethods = ({ engagementId, ...rest }) => {
    return {
        createClient: company =>
            performAPIAction({
                ...rest,
                apiCall: engagementClientCompanyRepository.create([
                    serializeEngagementCompany({ ...company, engagementId: engagementId })
                ]),
                successMessageId: 'COMPANY.CREATE.CLIENT.SUCCESS',
                errorMessageId: 'COMPANY.CREATE.CLIENT.ERROR',
                entityType: COMPANY_TYPE.CLIENT
            }),
        updateClient: company =>
            performAPIAction({
                ...rest,
                apiCall: engagementClientCompanyRepository.update([
                    serializeEngagementCompany({ ...company, engagementId: engagementId })
                ]),
                successMessageId: 'COMPANY.UPDATE.CLIENT.SUCCESS',
                errorMessageId: 'COMPANY.UPDATE.CLIENT.ERROR',
                entityType: COMPANY_TYPE.CLIENT
            }),
        searchGlobalClients: async filters => {
            const result = await performAPIAction({
                ...rest,
                apiCall: globalClientCompanyRepository.fetchAll(filters),
                successMessageId: null,
                errorMessageId: 'COMPANY.SEARCH.CLIENT.ERROR',
                entityType: COMPANY_TYPE.CLIENT,
                callback: null
            });

            return result;
        }
    };
};

const ThirdPartyCompanyMethods = ({ engagementId, ...rest }) => {
    return {
        createThirdParty: company =>
            performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyCompanyRepository.create([serializeEngagementCompany(company)]),
                successMessageId: 'COMPANY.CREATE.CLIENT.SUCCESS',
                errorMessageId: 'COMPANY.CREATE.CLIENT.ERROR',
                entityType: COMPANY_TYPE.THIRDPARTY
            }),
        updateThirdParty: company =>
            performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyCompanyRepository.update([serializeEngagementCompany(company)]),
                successMessageId: 'COMPANY.UPDATE.CLIENT.SUCCESS',
                errorMessageId: 'COMPANY.UPDATE.CLIENT.ERROR',
                entityType: COMPANY_TYPE.THIRDPARTY
            }),
        deleteThirdParty: company =>
            performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyCompanyRepository.delete(
                    serializeEngagementCompaniesToDelete([company])
                ),
                successMessageId: 'COMPANY.DELETE.CLIENT.SUCCESS',
                errorMessageId: 'COMPANY.DELETE.CLIENT.ERROR',
                entityType: COMPANY_TYPE.THIRDPARTY
            }),
        searchThirdParties: async filters => {
            const result = await performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyCompanyRepository.fetchByEngagement(engagementId, filters),
                successMessageId: null,
                errorMessageId: 'COMPANY.SEARCH.CLIENT.ERROR',
                entityType: COMPANY_TYPE.THIRDPARTY,
                callback: null
            });

            return result;
        },
        fetchThirdPartyCompany: async companyId =>
            performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyCompanyRepository.fetch(companyId),
                successMessageId: null,
                errorMessageId: 'COMPANY.FETCH.CLIENT.ERROR',
                entityType: COMPANY_TYPE.THIRDPARTY
            }),
        downloadThirdParties: async (engagementId, callback) =>
            performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyCompanyRepository.downloadAll(engagementId),
                successMessageId: null,
                errorMessageId: 'COMPANY.DOWNLOAD.THIRDPARTY.ERROR',
                entityType: COMPANY_TYPE.THIRDPARTY,
                callback: callback
            }),
    };
};

/***
 * A hook that implement the functions (create, update and search) of a company
 * @param engagementId
 * @param intl: to manage wordings
 * @param checkSession: to check the validity of the user session
 * @param addToastMessage: to show toast messages (error or success)
 * @param callback: a callback to execute after the asked function is executed
 * @param setLoading: show/hide loader
 * @param target: <important!> types of companies to ask functions for: possible value @seealso COMPANY_TYPE
 * @returns {{createClient: function(*): Promise<null>, createThirdParty: function(*): Promise<null>, updateClient: function(*): Promise<null>, updateThirdParty: function(*): Promise<null>, deleteThirdParty: function(*): Promise<null>, fetchThirdPartyCompany: function(*): Promise<{}>, downloadThirdParties: function(*): Promise<{}>, searchGlobalClients: function(*): Promise<{companiesPaginated: (*|{}), isSuccess: *}>, searchThirdParties: function(*): Promise<{companiesPaginated: (*|{}), isSuccess: *}>}}
 */
export const CompanyService = ({
    engagementId,
    intl,
    checkSession,
    addToastMessage,
    callback,
    setLoading = _ => {},
    target = [COMPANY_TYPE.CLIENT, COMPANY_TYPE.THIRDPARTY]
}) => {
    const clientCompanyMethods = target.includes(COMPANY_TYPE.CLIENT)
        ? {
              ...ClientCompanyMethods({
                  engagementId,
                  intl,
                  checkSession,
                  addToastMessage,
                  callback,
                  setLoading
              })
          }
        : {};

    const thirdPartyCompanyMethods = target.includes(COMPANY_TYPE.THIRDPARTY)
        ? {
              ...ThirdPartyCompanyMethods({
                  engagementId,
                  intl,
                  checkSession,
                  addToastMessage,
                  callback,
                  setLoading
              })
          }
        : {};

    return {
        ...clientCompanyMethods,
        ...thirdPartyCompanyMethods
    };
};
