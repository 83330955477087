import { ListItemSecondaryAction, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { initContact } from '.';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { UITooltip } from '../common/UITooltip';
import { CONTACT_TYPE } from '../../types/types';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../i18n/i18Helper';
import { UIActionButton } from '../common/UIActionButton';
import { canInviteContact, canManageAccessRights } from '../../helpers';
import { Palette } from '../../styles/colors';

const ContactActionsDetails = ({
    contact,
    disabled,
    handleClickOpen,
    handleRemoveContact,
    handleInvite,
    handleManageAccessRights
}) => {
    const intl = useIntl();

    const onDelete = () => {
        handleRemoveContact(contact);
    };

    const onInvite = () => {
        handleInvite(contact);
    };

    const onOpen = () => {
        handleClickOpen({ ...initContact, ...cleanContact(contact) });
    };

    const onManageAccess = () => {
        handleManageAccessRights(contact);
    };

    return (
        <>
            {handleInvite && canInviteContact(contact) && (
                <UIActionButton
                    disabled={disabled}
                    uppercase={false}
                    display="secondary"
                    sx={styles.actionButton}
                    title={getLocalizedString(intl, 'CLIENT.CONTACT.INVITE')}
                    onClick={onInvite}
                />
            )}

            {handleManageAccessRights && canManageAccessRights(contact) && (
                <UIActionButton
                    disabled={disabled}
                    uppercase={false}
                    display="secondary"
                    style={styles.actionButton}
                    title={getLocalizedString(intl, 'CLIENT.CONTACT.MANAGE.ACCESS.RIGHTS')}
                    onClick={onManageAccess}
                />
            )}

            {handleClickOpen && (
                <UIActionButton
                    disabled={disabled}
                    uppercase={false}
                    display="secondary"
                    style={{ ...styles.actionButton, marginLeft: 2 }}
                    title={getLocalizedString(intl, 'CLIENT.CONTACT.EDIT')}
                    onClick={onOpen}
                />
            )}

            {handleRemoveContact && (
                <IconButton disabled={disabled} edge="end" aria-label="delete" onClick={onDelete}>
                    <Delete sx={{ color: Palette.red }} />
                </IconButton>
            )}
        </>
    );
};

const ContactItemWrapper = ({
    contact,
    isRoleEditable,
    handleRoleChange,
    intl,
    disabled,
    contactType,
    isSignatoryOptionDisabled,
    handleClickOpen,
    handleRemoveContact,
    handleInvite,
    handleManageAccessRights
}) => {
    const handleChange = (updatedContact, key) => {
        handleRoleChange({
            ...updatedContact,
            [key]: !updatedContact[key]
        });
    };

    const roleForms = {
        [CONTACT_TYPE.CLIENT]: () =>
            ClientContactItem({
                contact,
                handleChange,
                handleRoleChange,
                intl,
                disabled,
                handleClickOpen,
                handleRemoveContact,
                isRoleEditable,
                handleInvite,
                handleManageAccessRights
            }),
        [CONTACT_TYPE.THIRD_PARTY]: () =>
            ThirdPartyContactItem({
                contact,
                handleChange,
                handleRoleChange,
                intl,
                disabled,
                isSignatoryOptionDisabled,
                handleClickOpen,
                handleRemoveContact,
                isRoleEditable
            })
    };

    const ContactItemRole = roleForms[contactType];

    return ContactItemRole ? <ContactItemRole /> : <></>;
};

const ClientContactItem = ({
    contact,
    intl,
    disabled,
    handleChange,
    handleClickOpen,
    handleRemoveContact,
    isRoleEditable,
    handleInvite,
    handleManageAccessRights
}) => {
    return (
        <>
            {isRoleEditable && (
                <>
                    <UITooltip
                        title={getLocalizedString(intl, 'CREATE_CONFIRMATION.CLIENT.CONTACT.SIGNATORY.INFO')}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    key={`key_contact_list_checkbox_contact_signatory_${contact.id}`}
                                    checked={contact?.isSignatory}
                                    onChange={() => handleChange(contact, 'isSignatory')}
                                    name="isSignatory"
                                    color="primary"
                                />
                            }
                            label={getLocalizedString(intl, 'CREATE_CONFIRMATION.CLIENT.CONTACT.SIGNATORY')}
                        />
                    </UITooltip>

                    <UITooltip
                        title={getLocalizedString(intl, 'CREATE_CONFIRMATION.CLIENT.CONTACT.RECIPIENT.INFO')}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    key={`key_contact_list_checkbox_contact_recipient_${contact.id}`}
                                    checked={contact?.isRecipient}
                                    onChange={() => handleChange(contact, 'isRecipient')}
                                    name="isRecipient"
                                    color="primary"
                                />
                            }
                            label={getLocalizedString(intl, 'CREATE_CONFIRMATION.CLIENT.CONTACT.RECIPIENT')}
                        />
                    </UITooltip>
                </>
            )}

            <ContactActionsDetails
                handleInvite={handleInvite}
                handleManageAccessRights={handleManageAccessRights}
                contact={contact}
                disabled={disabled}
                handleClickOpen={handleClickOpen}
                handleRemoveContact={handleRemoveContact}
            />
        </>
    );
};

const ThirdPartyContactItem = ({
    contact,
    handleChange,
    intl,
    isSignatoryOptionDisabled,
    disabled,
    handleClickOpen,
    handleRemoveContact,
    isRoleEditable
}) => {
    return (
        <>
            {isRoleEditable && (
                <UITooltip
                    title={getLocalizedString(intl, 'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.MAIN.INFO')}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={isSignatoryOptionDisabled}
                                key={`key_contact_list_checkbox_contact_main_${contact.id}`}
                                checked={contact?.isSignatory}
                                onChange={() => handleChange(contact, 'isSignatory')}
                                name="isSignatory"
                                color="primary"
                            />
                        }
                        label={getLocalizedString(intl, 'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.MAIN')}
                    />
                </UITooltip>
            )}

            <UITooltip
                title={getLocalizedString(intl, 'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.RECIPIENT.INFO')}>
                <FormControlLabel
                    control={
                        <Checkbox
                            // disabled={isSignatoryOptionDisabled}
                            key={`key_contact_list_checkbox_contact_recipient_${contact.id}`}
                            checked={contact?.isRecipient}
                            onChange={() => handleChange(contact, 'isRecipient')}
                            name="isRecipient"
                            color="primary"
                        />
                    }
                    label={getLocalizedString(intl, 'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.RECIPIENT')}
                />
            </UITooltip>

            <ContactActionsDetails
                contact={contact}
                disabled={contact.isSignatory ? isSignatoryOptionDisabled : disabled}
                handleClickOpen={handleClickOpen}
                handleRemoveContact={handleRemoveContact}
            />
        </>
    );
};

const cleanContact = contact => {
    Object.keys(contact).forEach(k => !contact[k] && delete contact[k]);
    return contact;
};

export const ContactItemActions = ({
    contact,
    isSignatoryOptionDisabled,
    isRoleEditable,
    handleRoleChange,
    intl,
    handleClickOpen,
    handleRemoveContact,
    disabled,
    contactType,
    handleInvite,
    handleManageAccessRights
}) => {
    return (
        <ListItemSecondaryAction>
            <ContactItemWrapper
                handleInvite={handleInvite}
                handleManageAccessRights={handleManageAccessRights}
                isSignatoryOptionDisabled={isSignatoryOptionDisabled}
                contactType={contactType}
                contact={contact}
                isRoleEditable={isRoleEditable}
                handleRoleChange={handleRoleChange}
                intl={intl}
                disabled={disabled}
                handleClickOpen={handleClickOpen}
                handleRemoveContact={handleRemoveContact}
            />
        </ListItemSecondaryAction>
    );
};

const styles = {
    actionButton: {
        minWidth: '100px',
        paddingX: 1,
        minHeight: '1.5em',
        fontSize: 12
    }
};
