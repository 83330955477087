import React from 'react';
import { Alert } from '@mui/material';
import { FormattedErrorMessage } from '../../../errorMessage/ErrorMessage';
import { NewlineText } from '../../../common/NewlineText';
import { localizedReadableFullDate } from '../../../../kit/utils/DateUtil';
import { E_WORKFLOW_ACTIONS } from '../../../../kit/utils/constants';

export const ConfirmationAlertsSection = ({ confirmation, invalidContacts, invalidPostalAddresses, validationError, onValidationErrorClose, intl }) => {
    const workflowActionWithCheckBeforeSendingAlert = [
        E_WORKFLOW_ACTIONS.CONFIRMATION_THIRDPARTY_REQUEST_SENT_VIA_EMAIL,
        E_WORKFLOW_ACTIONS.CONFIRMATION_THIRDPARTY_REQUEST_SENT_VIA_PAPER
    ];

    return (
        <>
            {confirmation?.numberOfClientSending > 1 && (
                <Alert severity="error">
                    {FormattedErrorMessage(
                        intl.formatMessage(
                            {
                                id: 'CONFIRMATION.CLIENT.SENDING.REQUESTS.NUMBER.MESSAGE'
                            },
                            {
                                counter: confirmation?.numberOfClientSending,
                                date: localizedReadableFullDate(intl, confirmation?.lastClientSendingDate)
                            }
                        )
                    )}
                </Alert>
            )}

            {confirmation?.numberOfThirdPartySending > 1 && (
                <Alert severity="error">
                    {FormattedErrorMessage(
                        intl.formatMessage(
                            {
                                id: 'CONFIRMATION.THIRDPARTY.SENDING.REQUESTS.NUMBER.MESSAGE'
                            },
                            {
                                counter: confirmation?.numberOfThirdPartySending,
                                date: localizedReadableFullDate(intl, confirmation?.lastThirdPartySendingDate)
                            }
                        )
                    )}
                </Alert>
            )}

            {validationError && (
                <Alert severity="error" onClose={onValidationErrorClose}>
                    {FormattedErrorMessage(<NewlineText text={validationError}></NewlineText>)}
                </Alert>
            )}

            {workflowActionWithCheckBeforeSendingAlert.includes(
                confirmation?.currentStep?.workflowAction?.eWorkflowAction
            ) && (
                <Alert severity="error">
                    {FormattedErrorMessage(
                        intl.formatMessage({
                            id: 'CONFIRMATION.THIRDPARTY.SENDING.REQUESTS.CHECK.LETTER.MESSAGE'
                        })
                    )}
                </Alert>
            )}

            {invalidContacts.length > 0 && React.Children.toArray(invalidContacts.map(x =>
                <Alert severity="warning">
                    {FormattedErrorMessage(
                        intl.formatMessage(
                            {
                                id: 'CONFIRMATION.CONTACT.EMAIL.INVALID.MESSAGE'
                            },
                            {
                                email: x?.email,
                                date: localizedReadableFullDate(intl, x?.isInvalidAddressDate)
                            }
                        )
                    )}
                </Alert>
            ))}

            {invalidPostalAddresses.length > 0 && React.Children.toArray(invalidPostalAddresses.map(x =>
                <Alert severity="warning">
                    {FormattedErrorMessage(
                        intl.formatMessage(
                            {
                                id: 'DOCUMENT.INFORMATION.INVALID.ADDRESS'
                            },
                            {
                                name: `${x.name}${x.fileExtension}`,
                                date: localizedReadableFullDate(intl, x?.isInvalidAddressDate)
                            }
                        )
                    )}
                </Alert>
            ))}
        </>
    );
};
