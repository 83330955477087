import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseArray } from '../parsers';
import { parseCompanies, parseCompany } from './companyParsers';
import { parseContacts } from '../contact/contactParsers';
import { Repository } from '../Repository';

/**
 * Class to communicate with API to access to third-party company resources.
 */
class EngagementClientCompanyRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    /**
     * Create a list of client companies.
     * @param {*} companies
     * @returns
     */
    create = async (companies) => {
        let result = await this.network.post(`EngagementClientCompany`, companies, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseArray);
        return result;
    };

    /**
     * Update a list of client companies.
     * @param {*} companies
     * @returns
     */
    update = async (companies) => {
        return await this.network.put(`EngagementClientCompany`, companies, {
            headers: this.buildHeaders()
        });
    };

    /**
     * Delete a list of client companies.
     * @param {*} companies
     * @returns
     */
    delete = async (companies) => {
        return await this.network.delete(`EngagementClientCompany`, companies, {
            headers: this.buildHeaders()
        });
    };

    /**
     * Get client companies for the engagement.
     * @param {*} engagementId
     * @returns
     */
    fetchByEngagement = async (engagementId) => {
        let result = await this.network.get(`EngagementClientCompany/engagement/${engagementId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseCompanies);
        return result;
    };

    /**
     * Get client companies for the engagement.
     * @param {*} engagementId
     * @returns
     */
    deleteEngagement = async (engagementId) => {
        return await this.network.delete(`EngagementClientCompany/engagement/${engagementId}`, {
            headers: this.buildHeaders()
        });
    };

    /**
     * Get details for a client company selected.
     * @param {*} companyId
     * @returns
     */
    fetch = async (companyId) => {
        let result = await this.network.get(`EngagementClientCompany/${companyId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseCompany);
        return result;
    };

    /**
     * Get contacts for a client company selected.
     * @param {*} companyId
     * @returns
     */
    fetchContacts = async (companyId) => {
        let result = await this.network.get(`EngagementClientCompany/${companyId}/contacts`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseContacts);
        return result;
    };
}

export const engagementClientCompanyRepository = new EngagementClientCompanyRepository();
