import { createContext } from 'react';

interface CompanyContextSpec {
    createCompany?: (company: any) => void;
    updateCompany?: (company: any) => void;
    deleteCompany?: (company: any) => void;
    searchCompany?: (filters: Record<string, any>) => void;
    fetchCompany?: (id: string) => void;
    downloadAll?: (engagementId: string) => void;
}

const companyContextDefaultValue: CompanyContextSpec = {
    createCompany: undefined,
    updateCompany: undefined,
    deleteCompany: undefined,
    searchCompany: undefined,
    fetchCompany: undefined,
    downloadAll: undefined
};

export const ThirdPartyCompanyContext = createContext(companyContextDefaultValue);
export const ClientCompanyContext = createContext(companyContextDefaultValue);
