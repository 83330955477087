import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { SessionStatus } from '../../kit/utils/constants';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { serializeStorageConfigurationSharePointOnline, storageConfigurationRepository } from '../../kit/repository';
import { StorageSharePointOnlineWrapper } from '../../components/setup';
import { fetchData, fetchStorageSharePointOnline, fetchStorage } from '../../services';
import { createToast, getToastType } from '../../components/toast/Toast';
import { COMPONENT_NAME } from '../../components/setup/constants';

class StorageSharePointOnlineSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };
    }

    createStorage = async configuration => {
        const {
            intl,
            checkSession,
            user,
            addToastMessage,
            hasValidConfig,
            setIsStorageConfigExpanded
        } = this.props;
        this.setState({ loading: true });

        const configurationSerialized = serializeStorageConfigurationSharePointOnline({
            ...configuration,
            clusterId: user?.clusterId
        });

        const result = await storageConfigurationRepository.createSharePointOnline(configurationSerialized);
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid && result.isSuccess) {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({
                        id: 'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.CREATE.SUCCESS'
                    })
                )
            );
            hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, true);
            setIsStorageConfigExpanded(false);
            await fetchStorage(this);
        } else {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({
                        id: 'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.CREATE.ERROR'
                    }),
                    result.error
                )
            );
            hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, false);
        }
        this.setState({ loading: false });
    };

    updateStorage = async configuration => {
        const {
            intl,
            checkSession,
            user,
            addToastMessage,
            hasValidConfig,
            setIsStorageConfigExpanded
        } = this.props;
        this.setState({ loading: true });

        const configurationSerialized = serializeStorageConfigurationSharePointOnline({
            ...configuration,
            clusterId: user?.clusterId
        });

        const result = await storageConfigurationRepository.updateSharePointOnline(
            configuration.id,
            configurationSerialized
        );
        const sessionStatus = await checkSession(result);

        if (sessionStatus !== SessionStatus.invalid && result.isSuccess) {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({
                        id: 'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.UPDATE.SUCCESS'
                    })
                )
            );
            hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, true);
            setIsStorageConfigExpanded(false);
            await fetchStorage(this);
        } else {
            addToastMessage(
                createToast(
                    getToastType(sessionStatus),
                    intl.formatMessage({
                        id: 'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.UPDATE.ERROR'
                    }),
                    result.error
                )
            );
            hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, false);
        }
        this.setState({ loading: false });
    };

    handleStorageConfig = async configuration =>
        !!configuration?.id ? this.updateStorage(configuration) : this.createStorage(configuration);

    async componentDidMount() {
        await fetchData(this, [() => fetchStorageSharePointOnline(this, false)]);
    }

    render() {
        const { storageSharePointOnlineConfiguration } = this.state;
        const { dmsRef, hasValidConfig } = this.props;
        return (
            <>
                <StorageSharePointOnlineWrapper
                    dmsRef={dmsRef}
                    storageConfiguration={storageSharePointOnlineConfiguration}
                    handleStorageConfig={this.handleStorageConfig}
                    hasValidConfig={hasValidConfig}
                />
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user
    };
};

export const StorageSharePointOnlineSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(StorageSharePointOnlineSetupComponent));
