import * as React from 'react';
import { InputAdornment, IconButton, Input } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export function UIPasswordInput({ form: { dirty, touched, errors }, field, ...props }) {
    const hasError = dirty && touched[field.name] && errors[field.name] !== undefined;

    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Input
            {...props}
            {...field}
            error={hasError}
            disableUnderline
            type={showPassword ? 'text' : 'password'}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
        />
    );
}
