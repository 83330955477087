import React from 'react';
import { UITooltip } from '../common/UITooltip';
import { AvatarGroup, Avatar, Grid } from '@mui/material';

const getClassNameByEngagementRole = engagementRole =>
    engagementRole === 2 ? 'avatar bg-success' : 'avatar bg-primary';

const getTitle = user => (user.fullname ? user.fullname : user.Email);

export function UserAvatarComponent(props) {
    const { data, max } = props;

    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
            <AvatarGroup max={max}>
                {Object.values(data).map(x =>
                    x.pictureUrl ? (
                        <Avatar key={x.id} alt={getTitle(x)} src={x.pictureUrl} />
                    ) : (
                        <UITooltip key={x.id} title={`${x.fullname ?? ''} | ${x.jobTitle ?? ''} ${x.email}`}>
                            <Avatar className={getClassNameByEngagementRole(x.engagementRole)} key={x.id} alt={x.email}>
                                {String(getTitle(x))
                                    .split(' ')
                                    .map(y => y.substring(0, 1))}
                            </Avatar>
                        </UITooltip>
                    )
                )}
            </AvatarGroup>
        </Grid>
    );
}
