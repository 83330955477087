export const administration = {
    'EMAIL_TEMPLATE.SUBSECTION.CREATE.TITLE': 'New template',
    'EMAIL_TEMPLATE.SUBSECTION.TEMPLATES.TITLE': 'Templates',
    'EMAIL_TEMPLATE.INFO.BUTTON.CREATE.LABEL': 'Create template',
    'EMAIL_TEMPLATE.INFO.BUTTON.EDIT.LABEL': 'Edit template',
    'EMAIL_TEMPLATE.INFO.BUTTON.CLEAR.LABEL': 'Clear',
    'EMAIL_TEMPLATE.INFO.BUTTON.SAVE.LABEL': 'Save',
    'EMAIL_TEMPLATE.INFO.TITLE.CREATE.LABEL': 'New email template',
    'EMAIL_TEMPLATE.INFO.FIELD.TYPE.LABEL': 'Type',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.TYPE': 'Email type is required',
    'EMAIL_TEMPLATE.INFO.FIELD.NAME.LABEL': 'Name',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.NAME': 'Name is required',
    'EMAIL_TEMPLATE.INFO.FIELD.DESCRIPTION.LABEL': 'Description',
    'EMAIL_TEMPLATE.INFO.FIELD.CONFIRMATION.CATEGORY.LABEL': 'Confirmation category',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.CONFIRMATION.CATEGORY': 'Confirmation category is required',
    'EMAIL_TEMPLATE.INFO.FIELD.LANGUAGE.LABEL': 'Language',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'Language is required',
    'EMAIL_TEMPLATE.INFO.FIELD.ARCHIVED.LABEL': 'Archived',
    'EMAIL_TEMPLATE.INFO.FIELD.SUBJECT.LABEL': 'Subject',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.SUBJECT': 'Subject is required',
    'EMAIL_TEMPLATE.INFO.FIELD.BODY.LABEL': 'Email content',
    'EMAIL_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.BODY': 'Email content is required',
    'EMAIL_TEMPLATE.ACTION.TOOLTIP.DELETE': 'Delete email template',
    'EMAIL_TEMPLATE.ALERT.TYPE.MISSING.LABEL': 'Missing type of email template(s): {missingEmailTemplates}',
    'EMAIL_TEMPLATE.ALERT.DEFAULT.LANGUAGE.MISSING.LABEL':
        'Missing default email template for type {emailTypeName} in language(s): {missingEmailLanguages}',
    'TEMPLATE_TAGS.SUBSECTION.TAGS.MANDATORY': 'Mandatory tags',
    'TEMPLATE_TAGS.SUBSECTION.TAGS.OPTIONAL': 'Optional tags',
    'COUNTRY_ADMIN.TITLE': 'Administration',
    'COUNTRY_ADMIN_SETTINGS.TITLE': 'Settings',
    'COUNTRY_ADMIN_METRICS.TITLE': 'Metrics',
    'COUNTRY_ADMIN.MENU.SETTINGS.TITLE': 'Settings',
    'COUNTRY_ADMIN.MENU.METRICS.TITLE': 'Metrics',
    'SETUP.SUBSECTION.TECHNICAL.CONFIGURATION.TITLE': 'Technical configurations',
    'SETUP.SUBSECTION.GENERAL.CONFIGURATION.TITLE': 'General configurations',
    'SETUP.SUBSECTION.WORKFLOW.TITLE': 'Workflows configuration',
    'SETUP.SUBSECTION.LETTER.TEMPLATE.TITLE': 'Letter Templates configuration',
    'SETUP.SUBSECTION.EMAIL.TEMPLATE.TITLE': 'Email Templates configuration',
    'SETUP.SUBSECTION.CLIENT.TITLE': 'Clients Upload',
    'SETUP.BUTTON.BACK.LABEL': 'Back',
    'SETUP.BUTTON.PREVIOUS.LABEL': 'Previous',
    'SETUP.BUTTON.NEXT.LABEL': 'Next',
    'SETUP.BUTTON.RESET.LABEL': 'Reset',
    'SETUP.BUTTON.FINISH.LABEL': 'Finish',
    'SETUP.BUTTON.CREATE.LABEL': 'Create',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.TITLE': 'Storage Configuration',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.DEFAULT.LABEL': 'Default storage',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.SHAREPOINT_ONLINE.LABEL': 'SharePoint Online',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.NEW.LABEL': 'Request new connector',
    'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.NEW.MESSAGE': 'Contact application administrators..',
    'TECHNICAL.CONFIGURATION.MAILING.SUBSECTION.TITLE': 'Mailing configuration',
    'TECHNICAL.CONFIGURATION.ROBOT.SUBSECTION.TITLE': 'Robot configuration',
    'TECHNICAL.CONFIGURATION.BUTTON.SAVE.LABEL': 'Save',
    'TECHNICAL.CONFIGURATION.ALERT.ERROR.STORAGE.MESSAGE':
        'Storage config not available or has been modified. Please save your modifications.',
    'TECHNICAL.CONFIGURATION.ALERT.ERROR.MAILING.MESSAGE':
        'Mailing config not available or has been modified. Please save your modifications.',
    'TECHNICAL.CONFIGURATION.ALERT.ERROR.ROBOT.MESSAGE':
        'Robot config not available or has been modified. Please save your modifications.',
    'TECHNICAL.CONFIGURATION.BUTTON.TOKEN.LABEL': 'Connect',
    'STORAGE.BASE.CONFIG.ISACTIVE.LABEL': 'Activate this storage config?',
    'STORAGE.BASE.CONFIG.ISACTIVE.SUBLABEL': 'Active',
    'STORAGE.BASE.CONFIG.PRIORITY_LEVEL.LABEL': 'Priority level',
    'STORAGE.BASE.CONFIG.PRIORITY_LEVEL.DESCRIPTION': 'Priority level',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.CREATE.TITLE': 'Default storage configuration',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.RESET.LABEL': 'Reset',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.SAVE.LABEL': 'Save',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.CREATE.LABEL': 'Create configuration',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.EDIT.LABEL': 'Edit configuration',
    'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.TITLE.CREATE.LABEL': 'Create default store configuration',
    'STORAGE.DEFAULT.CONFIG.FIELD.TARGET_PATH.LABEL': 'Root path',
    'STORAGE.DEFAULT.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TARGET_PATH': 'Root path is required',
    'STORAGE.DEFAULT.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.ISACTIVE': 'Active or Inactive is required',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.CREATE.TITLE': 'SharePoint Online storage configuration',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.RESET.LABEL': 'Reset',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.SAVE.LABEL': 'Save',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.CREATE.LABEL': 'Create configuration',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.BUTTON.EDIT.LABEL': 'Edit configuration',
    'STORAGE.SHAREPOINTONLINE.CONFIG.WRAPPER.SUBSECTION.TITLE.CREATE.LABEL':
        'Create SharePoint Online store configuration',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TENANT_NAME.LABEL': 'Tenant name',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TENANT_NAME': 'Tenant name is required',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TENANT_ID.LABEL': 'Tenant ID',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TENANT_ID': 'Tenant ID is required',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TARGET_PATH.LABEL': 'Root path',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TARGET_PATH': 'Root path is required',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.SITE_NAME.LABEL': 'Site name',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.SITE_NAME': 'Site name is required',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.SITE_URL.LABEL': 'Site url',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.SITE_URL': 'Site url is required',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.DOCUMENT_LIBRARY.LABEL': 'Document library',
    'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.DOCUMENT_LIBRARY': 'Document library is required',
    'ROBOT.CONFIG.CARDHEADER.MAILING.TITLE': 'Robot configuration',
    'ROBOT.CONFIG.LABEL.ADMINISTRATORS': 'Administrators emails - use coma (,) as separator without spaces',
    'ROBOT.CONFIG.VALIDATION.REQUIRED_FIELD.ADMINISTRATORS': 'An administrator is required',
    'ROBOT.CONFIG.LABEL.ENGAGEMENT_CODE_PATTERN': 'Engagement code pattern',
    'ROBOT.CONFIG.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE_PATTERN': 'Code pattern is required',
    'ROBOT.CONFIG.LABEL.DESCRIPTION': 'Description (engagement code example for auditor)',
    'GENERAL.CONFIGURATION.LOST.ITEM.SUBSECTION.TITLE': 'Lost items Configuration',
    'GENERAL.CONFIGURATION.USER.RESOURCE.SUBSECTION.TITLE': 'User resources Configuration',
    'GENERAL.CONFIGURATION.LOST.ITEM.BUTTON.SAVE.LABEL': 'Save',
    'GENERAL.CONFIGURATION.USER.RESOURCE.BUTTON.CREATE.LABEL': 'Create user resource',
    'LOST.ITEM.CONFIGURATION.IS_MANDATORY_SUPPORT_TO_ASSIGN.LABEL':
        'Support user action is mandatory to declare a document as lost',
    'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_MANDATORY_SUPPORT_TO_ASSIGN':
        'Support user action is mandatory to declare a document as lost',
    'LOST.ITEM.CONFIGURATION.IS_CLAIM_MANDATORY.LABEL':
        'Activate the claim mechanism for the auditor to request access to the document',
    'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_CLAIM_MANDATORY':
        'Activate the claim mechanism for the auditor to request access to the document',
    'LOST.ITEM.CONFIGURATION.IS_READ_ALLOWED.LABEL':
        'Allow auditor to read a lost document, otherwise they will have only access to the comment of the support',
    'LOST.ITEM.CONFIGURATION.VALIDATION.REQUIRED_FIELD.IS_READ_ALLOWED':
        'Allow auditor to read a lost document, otherwise they will have only access to the comment of the support',
    'LOST.ITEM.CONFIGURATION.SWITCH.ACTIVE.LABEL': 'Active',
    'LOST.ITEM.CONFIGURATION.SWITCH.OFF.LABEL': 'Off',
    'USER.RESOURCE.CONFIGURATION.DIALOG.CREATE.TITLE': 'Create user resource',
    'USER.RESOURCE.CONFIGURATION.DIALOG.EDIT.TITLE': 'Edit user resource',
    'USER.RESOURCE.CONFIGURATION.INFO.BUTTON.EDIT.LABEL': 'Edit',
    'USER.RESOURCE.CONFIGURATION.INFO.BUTTON.DELETE.LABEL': 'Delete',
    'USER.RESOURCE.CONFIGURATION.FIELD.TYPE.LABEL': 'Type',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.TYPE': 'Type is required',
    'USER.RESOURCE.CONFIGURATION.FIELD.NAME.LABEL': 'Name',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.NAME': 'Name is required',
    'USER.RESOURCE.CONFIGURATION.FIELD.DESCRIPTION.LABEL': 'Description',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.DESCRIPTION': 'Description is required',
    'USER.RESOURCE.CONFIGURATION.FIELD.LANGUAGE.LABEL': 'Language',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'Language is required',
    'USER.RESOURCE.CONFIGURATION.FIELD.FILE.LABEL': 'Upload file',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.FILE': 'File upload is required',
    'USER.RESOURCE.CONFIGURATION.FIELD.URL.LABEL': 'URL',
    'USER.RESOURCE.CONFIGURATION.VALIDATION.REQUIRED_FIELD.URL': 'URL is required',
    'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.MSG': 'Drag and drop a file here or click',
    'USER.RESOURCE.CONFIGURATION.FIELD.FILE.DROPZONE.UPLOADED_FILES.LABEL': 'Selected files',
    'USER.RESOURCE.CONFIGURATION.TYPE.FILE.NAME': 'File',
    'USER.RESOURCE.CONFIGURATION.TYPE.LINK.NAME': 'link',
    'USER.RESOURCE.CONFIGURATION.TYPE.VIDEO_LINK.NAME': 'Video link',
    'USER.RESOURCE.CONFIGURATION.TYPE.TEXT.NAME': 'Text',
    'USER.RESOURCE.MENU.CONTACT.US': 'Contact us',
    'CLIENT.CONFIG.EXCEL.TEMPLATE.BUTTON.DOWNLOAD.LABEL': 'Excel template',
    'CLIENT.CONFIG.EXCEL.TEMPLATE.BUTTON.IMPORT.LABEL': 'Import',
    'CLIENT.CONFIG.IMPORT.FORM.FILE.LABEL': 'Upload client companies file',
    'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.FILE': 'File is required',
    'CLIENT.CONFIG.IMPORT.FORM.WORKSHEETNAME.LABEL': 'Select worksheet to import',
    'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.WORKSHEETNAME': 'Worksheet is required',
    'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.LABEL':
        'Do you want to reset the database and delete all previous companies?',
    'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.RESETDATABASE': 'Answer is required',
    'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.YES.LABEL': 'Delete all the companies of the database',
    'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.YES.WARNING.MESSAGE':
        '<Caution!> Delete all the companies of the database erases all the existing and replace them by the uploaded ones',
    'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.NO.LABEL': 'Do not reset the database',
    'CLIENT.CONFIG.IMPORT.FORM.SECTION.COLUMNS.LABEL': 'Check columns mapping',
    'CLIENT.CONFIG.IMPORT.EXCEL.FILE.NAME.LABEL': 'mazars_confirmations_upload_global_client.xlsx',
    'CLIENT.CONFIG.IMPORT.EXCEL.VALIDATION.REQUIRED_FIELD.FILE': 'Template Excel file is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.FILE.WORKSHEETNAME.LABEL': 'Companies',
    'CLIENT.CONFIG.IMPORT.EXCEL.VALIDATION.REQUIRED_FIELD.WORKSHEETNAME': 'Worksheet name is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.INTERNAL_ID.LABEL': 'Forvis Mazars Internal ID',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.INTERNAL_ID': 'Coloumn internal ID is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.INTERNAL_ID.COMMENT':
        'The Engagement code is available in AGIL or RETAIN. Please fill it in to be able to interface with ATLAS or SIGNAL in the future',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER.LABEL': 'Company Identification Number',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER.COMMENT':
        'The Company Identification Number code is available on SOCIETE.COM. Please enter it in order to interface with ATLAS or SIGNAL in the future.',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER':
        'Column Company Identification Number is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER2.LABEL': 'Column Company Identification Number 2',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER2.COMMENT': 'Column Company Identification Number 2 comment',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER2':
        'Company Identification Number is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER3.LABEL': 'Company Identification Number 3',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COMPANY_ID_NUMBER3.COMMENT': 'Optional Company Identification Number 3',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.VAT_NUMBER.LABEL': 'Number TVA intracommunautaire',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.VAT_NUMBER.COMMENT': 'Number TVA intracommunautaire',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.VAT_NUMBER': 'VAT Number is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.NAME.LABEL': 'Name',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.NAME': 'Column name is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.NAME.COMMENT': 'Name of the client entity or company',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.DESCRIPTION.LABEL': 'Description',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.DESCRIPTION': 'Column description is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.DESCRIPTION.COMMENT': 'Description comment',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ADDRESS.LABEL': 'Address',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.ADDRESS': 'Column address is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ADDRESS.COMMENT': 'Address comment',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ZIPCODE.LABEL': 'Zip Code',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.ZIPCODE': 'Column zipcode is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.ZIPCODE.COMMENT': 'Zip Code comment',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.CITY.LABEL': 'City',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.CITY': 'Column city is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.CITY.COMMENT': 'City comment',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COUNTRY.LABEL': 'Country',
    'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.COUNTRY': 'Column country is required',
    'CLIENT.CONFIG.IMPORT.EXCEL.COLUMNS.COUNTRY.COMMENT': 'Country and/or State',
    'WORKFLOW.DIALOG.TITLE.EDIT.LABEL': 'Edit workflow',
    'WORKFLOW.INFO.BUTTON.EDIT.LABEL': 'Edit workflow',
    'WORKFLOW.INFO.FIELD.NAME.LABEL': 'Workflow name',
    'WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.NAME': 'Workflow name is required',
    'WORKFLOW.INFO.FIELD.DESCRIPTION.LABEL': 'Workflow description',
    'WORKFLOW.INFO.FIELD.CATEGORY.LABEL': 'Workflow categories',
    'WORKFLOW.ACTIVATE.WORKFLOW.STEP.LABEL': 'Click on a step to activate or deactivate',
    'WORKFLOW.INFO.FIELD.WORKFLOW_ACTIONS.LABEL': 'Add actions to the workflow',
    'WORKFLOW.INFO.FIELD.ACTIONS.LABEL': 'WORKFLOW ACTIONS',
    'WORKFLOW.INFO.FIELD.ACTIONS.POSITION.LABEL': 'Step',
    'WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.POSITION': 'Action position is required',
    'WORKFLOW.INFO.VALIDATION.POSITIVE.ACTION.POSITION': 'Action position should be positive',
    'WORKFLOW.INFO.FIELD.ACTIONS.NAME.LABEL': 'Action name',
    'WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.NAME': 'Action name is required',
    'WORKFLOW.INFO.FIELD.ACTIONS.DESCRIPTION.LABEL': 'Action description',
    'WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.DESCRIPTION': 'Action description is required',
    'WORKFLOW.SETUP.SUBSECTION.BANK.TITLE': 'Bank',
    'WORKFLOW.SETUP.SUBSECTION.DEBTOR.TITLE': 'Debtor',
    'WORKFLOW.SETUP.SUBSECTION.OTHER.TITLE': 'Other',
    'WORKFLOW.SETUP.SUBSECTION.LAWYER.TITLE': 'Lawyer',
    'TEMPLATE.ACTION.TOOLTIP.DELETE': 'Delete template',
    'TEMPLATE.TEXT.LANGUAGE': 'Language: ',
    'TEMPLATE.TEXT.CONFIRMATION.CATEGORY': 'Confirmation category: ',
    'TEMPLATE.TEXT.CREATED': 'Created: ',
    'TEMPLATE.TEXT.UPDATED': 'Updated: ',
    'LETTER_TEMPLATE.INFO.BUTTON.CREATE.LABEL': 'Create template',
    'LETTER_TEMPLATE.INFO.BUTTON.EDIT.LABEL': 'Edit template',
    'LETTER_TEMPLATE.INFO.BUTTON.DELETE.LABEL': 'Delete template',
    'LETTER_TEMPLATE.INFO.FIELD.CONFIRMATION.CATEGORY.LABEL': 'Confirmation category',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.CONFIRMATION.CATEGORY': 'Confirmation category is required',
    'LETTER_TEMPLATE.INFO.FIELD.WORKFLOW.LABEL': 'Workflow',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.WORKFLOW': 'Workflow is required',
    'LETTER_TEMPLATE.INFO.FIELD.NAME.LABEL': 'Template name',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.NAME': 'Template name is required',
    'LETTER_TEMPLATE.INFO.FIELD.DESCRIPTION.LABEL': 'Template description',
    'LETTER_TEMPLATE.INFO.FIELD.LANGUAGE.LABEL': 'Language',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.LANGUAGE': 'Language is required',
    'LETTER_TEMPLATE.INFO.FIELD.UPLOAD.LABEL': 'Upload word document',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.UPLOAD': 'Word document is required',
    'LETTER_TEMPLATE.TAG.SELECTOR.EDIT.LABEL': 'Select tags to include in the letter template (word)',
    'LETTER_TEMPLATE.TAG.SELECTOR.READ.LABEL': 'Tags included in the letter template',
    'LETTER_TEMPLATE.INFO.VALIDATION.REQUIRED_FIELD.TAGS': 'Tags included in the letter template are required',
    'FETCH.CONFIG.CHECKS.ERROR': 'Configurations checks access error',
    'FETCH.STORAGE.CONFIGURATION.ACCESS.ERROR': 'Storage configuration access error',
    'FETCH.STORAGE.DEFAULT.CONFIG.CREATE.SUCCESS': 'Default storage configuration created',
    'FETCH.STORAGE.DEFAULT.CONFIG.CREATE.ERROR': 'Default storage configuration creation failed',
    'FETCH.STORAGE.DEFAULT.CONFIG.UPDATE.SUCCESS': 'Default storage configuration updated',
    'FETCH.STORAGE.DEFAULT.CONFIG.UPDATE.ERROR': 'Default storage configuration update failed',
    'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.CREATE.SUCCESS': 'SharePoint Online storage configuration created',
    'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.CREATE.ERROR': 'SharePoint Online storage configuration creation failed',
    'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.UPDATE.SUCCESS': 'SharePoint Online storage configuration updated',
    'FETCH.STORAGE.SHAREPOINTONLINE.CONFIG.UPDATE.ERROR': 'SharePoint Online storage configuration update failed',
    'FETCH.WORKFLOW.UPDATE.SUCCESS': 'Workflow updated',
    'FETCH.WORKFLOW.UPDATE.ERROR': 'Workflow update failed',
    'LETTER.TEMPLATE.CREATE.SUCCESS': 'Letter template created',
    'LETTER.TEMPLATE.CREATE.ERROR': 'Letter template create failed',
    'LETTER.TEMPLATE.UPDATE.SUCCESS': 'Letter template updated',
    'LETTER.TEMPLATE.UPDATE.ERROR': 'Letter template update failed',
    'LETTER.TEMPLATE.DELETE.SUCCESS': 'Letter template deleted',
    'LETTER.TEMPLATE.DELETE.ERROR': 'Letter template delete failed',
    'LETTER.TEMPLATE.FILE.ACCESS.ERROR': 'Letter template file access failed',
    'ROBOT.CONFIG.ACCESS.ERROR': 'Access to robot configuration failed',
    'ROBOT.CONFIG.USER.PROFILE.ERROR': 'Access to user profile failed',
    'ROBOT.CONFIG.CREATE.ERROR': 'Create robot configuration failed',
    'ROBOT.CONFIG.CREATE.SUCCESS': 'Robot configuration created',
    'ROBOT.CONFIG.UPDATE.ERROR': 'Update robot configuration failed',
    'ROBOT.CONFIG.UPDATE.SUCCESS': 'Robot configuration updated',
    'ROBOT.CONFIG.DELETE.ERROR': 'Delete robot configuration failed',
    'ROBOT.CONFIG.DELETE.SUCCESS': 'Robot configuration deleted',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.CREATE.SUCCESS': 'Confirmation template created',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.CREATE.ERROR': 'Confirmation template creation failed',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.DELETE.SUCCESS': 'Confirmation template deleted',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.DELETE.ERROR': 'Confirmation template delete failed',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.UPDATE.ERROR': 'Confirmation template update error',
    'CREATE_TEMPLATE.CONFIRMATION.TEMPLATES.UPDATE.SUCCESS': 'Confirmation template updated',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.CREATE.SUCCESS': 'Email template created',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.CREATE.ERROR': 'Email template create failed',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.UPDATE.SUCCESS': 'Email template updated',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.UPDATE.ERROR': 'Email template update failed',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.DELETE.SUCCESS': 'Email template deleted',
    'CREATE_TEMPLATE.EMAIL.TEMPLATES.DELETE.ERROR': 'Email template delete failed',
    'EMAIL.TEMPLATES.SETUP.USER.PROFILE.ERROR': 'Email template setup - user profile error',
    'EMAIL.TEMPLATES.SETUP.TYPES.ERROR': 'Email templates types access error',
    'LOST_ITEM_CONFIG.INFO.ACCESS.ERROR': 'Lost item configuration loading error',
    'LOST_ITEM_CONFIG.CREATE.SUCCESS': 'Lost item configuration created',
    'LOST_ITEM_CONFIG.CREATE.ERROR': 'Lost item configuration create failed',
    'LOST_ITEM_CONFIG.UPDATE.SUCCESS': 'Lost item configuration updated',
    'LOST_ITEM_CONFIG.UPDATE.ERROR': 'Lost item configuration update failed',
    'LOST_ITEM_CONFIG.DELETE.SUCCESS': 'Lost item configuration deleted',
    'LOST_ITEM_CONFIG.DELETE.ERROR': 'Lost item configuration delete failed',

    'METRICS.CARD.TITLE.LABEL': 'Application usage metrics',
    'METRICS.APPUSERS.LABEL': 'Number of users',
    'METRICS.CONFIRMATIONS.LABEL': 'Number of confirmations',
    'METRICS.CLIENT.COMPANIES.LABEL': 'Number of client companies',
    'METRICS.THIRDPARTY.COMPANIES.LABEL': 'Number of third-party companies',
    'METRICS.ENGAGEMENTS.LABEL': 'Number of engagements',
    'METRICS.ITEMS.LABEL': 'Number of documents'
};
