import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { handleApiFailure, UPLOAD_TYPES } from '../../helpers';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../i18n/i18Helper';
import { initPaginatedObject } from '../../components/common/InitPaginatedObject';
import {
    PROFILE,
    CLIENT_ENGAGEMENT_TABS,
    ClientRequest,
    RefuseConfirmationRequestSpec,
    UploadDocumentSpec
} from '../../types/types';
import { AllInclusiveOutlined, AutorenewOutlined } from '@mui/icons-material';
import { ClientRequestsContent } from '../../components/clientRequest';
import {
    clientEngagementRequestRepository,
    confirmationsRepository,
    serializeConfirmation,
    engagementRepository
} from '../../kit/repository';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { INIT_ITEMS_FILTER, SessionStatus, SORT_CHOICES } from '../../kit/utils/constants';
import { ENGAGEMENT_COLUMNS_FILTERS } from '../../components/engagement/InitEngagement';
import { FilterTableSpec, useFilterTable } from '../../hooks/useFilterTable';
import { ClientEngagementHeader } from '../../components/engagement';
import { EngagementContent as ClientAllRequestsContent } from '../../components/engagement';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { ItemService } from '../../services';
import { ServiceResult } from '../../kit/network/ServiceResult';
import { getSessionStatus } from '../../enhancers/wichCheckSession';
import * as actions from '../../store/actions';
import { cleanFilters, getWorkflowActionsStatus } from '../../helpers/EngagementHelper';
import { UITooltip } from '../../components/common/UITooltip';

interface ClientEngagementComponentProps {
    engagement?: any;
    engagementId: string;
    user: any;
    confirmationCategories: any;
    workflowActions: any;
    scaffold: UIScaffoldChildContext;
    addToastMessage: (message: any) => void;
}

const ClientEngagementComponent = ({
    engagement,
    engagementId,
    user,
    confirmationCategories,
    workflowActions,
    scaffold,
    addToastMessage
}: ClientEngagementComponentProps) => {
    const intl = useIntl();

    const workflowActionsStatus = useMemo(() => getWorkflowActionsStatus(intl), []);

    const commonFilters = useMemo(
        () => ({
            pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
            pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
            userProfile: PROFILE.CLIENT,
            sort: SORT_CHOICES.UPDATED_DESC,
            engagementId: engagementId,
            isTrashed: false
        }),
        [engagementId]
    );

    const [additionalActionsActive, setAdditionalActions] = useState<boolean>(false);
    const [openSign, setOpenSign] = useState<boolean>(false);

    const [currentPaginatedEntries, setCurrentPaginatedEntries] = useState(initPaginatedObject);
    const [currentTab, setCurrentTab] = useState<CLIENT_ENGAGEMENT_TABS>(CLIENT_ENGAGEMENT_TABS.REQUESTS);
    const [filters, setFilters] = useState<{ [key in CLIENT_ENGAGEMENT_TABS]: any }>({
        [CLIENT_ENGAGEMENT_TABS.REQUESTS]: {
            ...commonFilters,
            [ENGAGEMENT_COLUMNS_FILTERS.CLIENT]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.CATEGORY]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.CONFIRMATION_INFO]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.LETTERHEAD]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.CLIENT_REQUEST_STATUS]: null
        },
        [CLIENT_ENGAGEMENT_TABS.ALL]: {
            ...commonFilters,
            [ENGAGEMENT_COLUMNS_FILTERS.CLIENT]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.CATEGORY]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION]: null,
            [ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION_STATUS]: null
        }
    });

    const handleFilterChange = (values: Record<string, any>) => {
        setFilters({ ...filters, [currentTab]: { ...filters[currentTab], ...values } });
    };

    const filterManager: FilterTableSpec = useFilterTable(ENGAGEMENT_COLUMNS_FILTERS, handleFilterChange);

    const checkSession = (result: ServiceResult) => {
        scaffold.setSessionExpired(getSessionStatus(result) === SessionStatus.invalid);
    };

    const setLoading = (loading: boolean) => scaffold.setLoading(loading);

    //TODO it should be download a letter of a confirmation not a given item id ?
    const itemManager = ItemService({
        intl: intl,
        user: user,
        definedUserProfile: PROFILE.AUDITOR, //PROFILE.EXTERNAL_CLIENT,
        authorisedUploadItemType: UPLOAD_TYPES.AUDITOR,
        checkSession: checkSession,
        addToastMessage: addToastMessage,
        setLoading: setLoading,
        setErrorMessage: () => {},
        callback: () => {}
    });

    useEffect(() => {
        fetchData();
    }, [currentTab, filters]);

    const fetchData = async () => {
        const currentFilters = filters[currentTab];
        let result: ServiceResult;

        scaffold.setLoading(true);
        if (currentTab === CLIENT_ENGAGEMENT_TABS.ALL) {
            result = await engagementRepository.fetchConfirmations(
                engagementId,
                cleanFilters(currentFilters)
            );
        } else {
            result = await engagementRepository.fetchConfirmationRequests(
                engagementId,
                cleanFilters(currentFilters),
            );
        }

        scaffold.setLoading(false);

        if (result?.isSuccess) {
            setCurrentPaginatedEntries(result.parsedValue);
        } else {
            handleApiFailure(result, getLocalizedString(intl, 'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR'), scaffold);
        }
    };

    const fetchConfirmation = async (confirmationId: string): Promise<any | undefined> => {
        const result: ServiceResult = await confirmationsRepository.fetch(confirmationId);

        if (!result.isSuccess) {
            handleApiFailure(result, getLocalizedString(intl, 'CONFIRMATION.ACCESS.CONFIRMATION.ERROR'), scaffold);
        }

        return result.parsedValue;
    };

    const handleRefuseRequest = async (values: RefuseConfirmationRequestSpec) => {
        scaffold.setLoading(true);
        const result = await clientEngagementRequestRepository.refuse([values]);
        scaffold.setLoading(false);
        if (result.isSuccess) {
            scaffold.setToast(getLocalizedString(intl, 'CLIENT.ENGAGEMENT.REQUESTS.REFUSE.SUCCESS'), '', 'success');
        } else {
            handleApiFailure(result, getLocalizedString(intl, 'ENGAGEMENT.REQUESTS.REFUSE.ERROR'), scaffold);
        }
        return result;
    };

    const handleUploadLetter = async (letterInfo: UploadDocumentSpec) => {
        const result = await clientEngagementRequestRepository.upload(letterInfo);
        if (result.isSuccess) {
            scaffold.setToast(getLocalizedString(intl, 'CLIENT.ENGAGEMENT.REQUESTS.UPLOAD.SUCCESS'), '', 'success');
        } else {
            handleApiFailure(result, getLocalizedString(intl, 'ENGAGEMENT.REQUESTS.UPLOAD.ERROR'), scaffold);
        }

        return result.isSuccess;
    };

    const handleUpdateRequest = async (request: ClientRequest) => {
        const result = await confirmationsRepository.update([serializeConfirmation(request)]);
        if (result.isSuccess) {
            scaffold.setToast(getLocalizedString(intl, 'CONFIRMATION.UPDATE.SUCCESS'), '', 'success');
            await fetchData();
        } else {
            handleApiFailure(result, getLocalizedString(intl, 'CONFIRMATION.UPDATE.ERROR'), scaffold);
        }

        return result;
    };

    const handleConfirmRequest = async (request: ClientRequest) => {
        const result = await clientEngagementRequestRepository.confirm(request);
        if (result.isSuccess) {
            scaffold.setToast(getLocalizedString(intl, 'CLIENT.ENGAGEMENT.REQUESTS.VALIDATE.SUCCESS'), '', 'success');
        } else {
            handleApiFailure(result, getLocalizedString(intl, 'ENGAGEMENT.REQUESTS.VALIDATE.ERROR'), scaffold);
        }

        return result;
    };

    const handleSignRequests = () => {
        setOpenSign(true);
    };

    const handleTabChange = (event: any, newValue: CLIENT_ENGAGEMENT_TABS) => {
        event.stopPropagation();
        setCurrentTab(newValue);
    };

    return (
        <>
            <ClientEngagementHeader
                handleSign={handleSignRequests}
                isSignHidden={!additionalActionsActive}
                filterManager={filterManager}
                engagementName={engagement?.name}
                engagementId={engagementId}
            />
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary">
                <UITooltip
                    title={getLocalizedString(intl, 'CLIENT.ENGAGEMENT.PENDING.REQUESTS.TOOLTIP')}
                    children={
                        <Tab
                            value={CLIENT_ENGAGEMENT_TABS.REQUESTS}
                            icon={<AutorenewOutlined fontSize="large" />}
                            label={getLocalizedString(intl, 'CLIENT.ENGAGEMENT.REQUESTS.LABEL')}
                        />
                    }
                />

                <UITooltip
                    title={getLocalizedString(intl, 'CLIENT.ENGAGEMENT.ALL.REQUESTS.TOOLTIP')}
                    children={
                        <Tab
                            value={CLIENT_ENGAGEMENT_TABS.ALL}
                            icon={<AllInclusiveOutlined fontSize="large" />}
                            label={getLocalizedString(intl, 'CLIENT.ENGAGEMENT.ALL.REQUESTS.LABEL')}
                        />
                    }
                />
            </Tabs>

            {currentTab === CLIENT_ENGAGEMENT_TABS.REQUESTS ? (
                <ClientRequestsContent
                    fetchRequests={fetchData}
                    isSignatureAsked={openSign}
                    saveRequestChanges={handleUpdateRequest}
                    fetchConfirmationDocument={itemManager.fetchFile}
                    fetchConfirmationDocumentInfo={itemManager.fetchInfo}
                    fetchRequestDetails={fetchConfirmation}
                    refuseRequest={handleRefuseRequest}
                    setAdditionalActions={setAdditionalActions}
                    uploadLetter={handleUploadLetter}
                    user={user}
                    engagement={engagement}
                    filterManager={filterManager}
                    handleFilterChange={handleFilterChange}
                    paginatedRequests={currentPaginatedEntries}
                    confirmationCategories={confirmationCategories}
                    setLoading={scaffold.setLoading}
                    setToast={scaffold.setToast}
                    setSessionExpired={scaffold.setSessionExpired}
                    confirmRequest={handleConfirmRequest}
                />
            ) : currentTab === CLIENT_ENGAGEMENT_TABS.ALL ? (
                <ClientAllRequestsContent
                    workflowActionsStatus={workflowActionsStatus}
                    engagement={engagement}
                    handleFilterChange={handleFilterChange}
                    loading={false}
                    confirmationCategories={confirmationCategories}
                    workflowActions={workflowActions}
                    data={currentPaginatedEntries}
                    filterManager={filterManager}
                />
            ) : null}
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        user: state.account.user,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    addToastMessage: (message: any) => {
        dispatch(actions.addToastMessage(message));
    }
});

export const ClientEngagement = compose(connect(mapStateToProps, mapDispatchToProps))(ClientEngagementComponent);
