import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { MZRPaginatedTable, useTableBaseStyles } from '../../common/MZRPaginatedTable';
import { initPaginatedObject } from '../../common/InitPaginatedObject';
import { useDeleteConfirmation } from '../../../hooks/useEntityActionPopup';
import { Card, CardHeader, CardHeaderToolbar } from '../../../partials/controls/Card';
import { ConfirmationWorkflowProgress } from '../ConfirmationWorkflowProgress';
import { Box } from '@mui/material';
import { RestoreFromTrash, DeleteForever } from '@mui/icons-material';
import { MZRTableActionIcon } from '../../common/MZRTableActionIcon';
import { UIBackButton } from '../../common/UIBackButton';
import { toolbarStyle } from '../../../styles/toolbar/ToolbarStyle';
import { Palette } from '../../../styles/colors';

export function EngagementRecycleBin({
    engagement,
    confirmationPaginated,
    handleFilterChange,
    eraseConfirmation,
    restoreConfirmation,
    intl,
    closeRecycleBin,
    user,
    loading
}) {
    const classes = useTableBaseStyles();

    const [confirmationsLoaded, setConfirmationLoaded] = useState(initPaginatedObject);
    const [confirmationToDelete, setConfirmationToDelete] = useState(null);

    useEffect(() => {
        setConfirmationLoaded(confirmationPaginated);
    }, [confirmationPaginated]);

    const {
        delete: DeleteConfirmationComponent,
        handleDeleteClick: handleDeleteConfirmationClick
    } = useDeleteConfirmation({
        user: user,
        deleteAPI: eraseConfirmation,
        warningMessageId: 'MESSAGE.ERASE.CONFIRMATION.CONTENT'
    });

    const onEraseConfirmationClick = confirmationId => {
        if (engagement) {
            setConfirmationToDelete(confirmationId);
            handleDeleteConfirmationClick();
        }
    };

    const onRestoreConfirmationClick = async confirmationId => {
        await restoreConfirmation({ id: confirmationId });
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.CLIENT' }),
            field: 'clientCompany',
            width: 260,
            cellStyle: { textAlign: 'center' },
            render: rowData =>
                rowData?.clientCompany?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.PLACEHOLDER.CLIENT" />
        },
        {
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.THIRDPARTY' }),
            field: 'thirdPartyCompany',
            width: 260,
            cellStyle: { textAlign: 'center' },
            render: rowData =>
                rowData?.thirdPartyCompany?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.PLACEHOLDER.THIRD_PARTY" />
        },
        {
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.CATEGORY' }),
            field: 'confirmationCategory',
            width: 100,
            cellStyle: { textAlign: 'center' },
            render: rowData =>
                rowData?.confirmationCategory?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.TITLE.UNKNOWN_CATEGORY" />
        },
        {
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.PROGRESS' }),
            field: 'progress',
            grouping: false,
            render: rowData => <ConfirmationWorkflowProgress progressData={rowData} />
        },
        {
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.LAST_UPDATE' }),
            field: 'currentStep',
            type: 'date',
            grouping: false,
            width: 170,
            filtering: false,
            render: rowData => (
                <FormattedTime value={rowData.currentStep.updated} year="numeric" month="short" day="numeric" />
            ),

            cellStyle: { textAlign: 'center' }
        }
    ];

    const actions = [
        rowData => ({
            iconProps: { color: 'primary' },
            icon: 'check',
            tooltip: <FormattedMessage id="CONFIRMATION.RESTORE.INFO" />,
            onClick: () => onRestoreConfirmationClick(rowData.id)
        }),
        {
            iconProps: { color: 'secondary' },
            icon: 'clear',
            tooltip: <FormattedMessage id="CONFIRMATION.ERASE.INFO" />,
            onClick: (_, rowData) => onEraseConfirmationClick(rowData.id)
        }
    ];

    const components = {
        Action: props => {
            const { action, data } = props;
            let actionInfo = action;
            if (typeof action.action === 'function') {
                actionInfo = action.action(data);
            }

            switch (actionInfo.icon) {
                case 'check':
                    return (
                        <MZRTableActionIcon data={data} {...actionInfo} icon={<RestoreFromTrash color="primary" />} />
                    );

                case 'clear':
                    return (
                        <MZRTableActionIcon
                            data={data}
                            {...actionInfo}
                            icon={<DeleteForever sx={{ color: Palette.red }} />}
                        />
                    );

                default:
                    return null;
            }
        }
    };

    return (
        <>
            <Card className={clsx(classes.root, classes.margin)}>
                <CardHeader
                    title={
                        <FormattedMessage
                            id="ENGAGEMENT.CARDHEADER.RECYCLE.BIN.TITLE"
                            values={{
                                engagementName:
                                    engagement?.name || intl.formatMessage({ id: 'COMMON.LOADING.IN.PROGRESS' })
                            }}
                        />
                    }>
                    <CardHeaderToolbar>
                        <Box component="span" m={1} sx={toolbarStyle.container}>
                            <UIBackButton onClick={closeRecycleBin} />

                            {/*TODO add delete selected confirmations*/}
                        </Box>
                    </CardHeaderToolbar>
                </CardHeader>
            </Card>

            <MZRPaginatedTable
                columns={columns}
                onRowClick={() => {}}
                loading={loading}
                data={confirmationsLoaded}
                handleFilterChange={handleFilterChange}
                actions={actions}
                components={components}
            />
            <DeleteConfirmationComponent confirmationId={confirmationToDelete} />
        </>
    );
}
