export const mailing = {
    // General
    'MAILING.CONFIG.TITLE': 'Mailing Setup',
    'MAILING.CONFIG.CARDHEADER.MAILING.TITLE': 'Mailing configuration',
    'MAILING.CONFIG.SECTION.SMTP.TITLE': 'SMTP',
    'MAILING.CONFIG.SECTION.IMAP.TITLE': 'IMAP',
    'MAILING.CONFIG.SECTION.MAILBOX.FOLDERS.TITLE': 'MAILBOX FOLDERS',

    'MAILING.CONFIGURATION.BASICAUTH.TYPE.LABEL': 'BasicAuth',
    'MAILING.CONFIGURATION.MODERNAUTH.TYPE.LABEL': 'ModernAuth',

    'MAILING.CONFIG.BASIC.AUTH.PROVIDER.NAME.LABEL': 'Mailbox provider',
    'MAILING.CONFIG.BASIC.AUTH.PROVIDER.NAME.PLACEHOLDER': 'Microsoft 365',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROVIDER.NAME': 'Mailbox provider name is required',

    'MAILING.CONFIG.BASIC.AUTH.DISPLAY.NAME.LABEL': 'Name of the sender that will be displayed',
    'MAILING.CONFIG.BASIC.AUTH.DISPLAY.NAME.PLACEHOLDER': 'Forvis Mazars Confirmations',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCOUNT.LABEL': 'SMTP - Account',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCOUNT.PLACEHOLDER': 'confirmations@mazars.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.ACCOUNT': 'SMTP account is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.PASSWORD.LABEL': 'SMTP - Account password',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.PASSWORD.PLACEHOLDER': 'azerty1234',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.PASSWORD': 'SMTP account password is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.HOST.LABEL': 'SMTP - Host',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.HOST.PLACEHOLDER': 'smtp.office365.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.HOST': 'SMTP host is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.PORT.LABEL': 'SMTP - Port',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.PORT.PLACEHOLDER': '587',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.PORT': 'SMTP port is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.SECURE_SOCKET_OPTIONS.LABEL': 'SMTP - Secure socket option',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.SECURE_SOCKET_OPTIONS.PLACEHOLDER': 'None',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.SECURE_SOCKET_OPTIONS': 'SMTP secure socket option is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.LABEL': 'SMTP - Accept all SSL certificates',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.PLACEHOLDER': 'False',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SMTP.ACCEPT_ALL_SSL_CERTIFICATES': 'SMTP accept all SSL certificates is required',

    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.LABEL': 'No',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.NO.TOOLTIP': 'Unactive accept all SSL certificate',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.YES.LABEL': 'Yes',
    'MAILING.CONFIG.BASIC.AUTH.SMTP.ACCEPT_ALL_SSL_CERTIFICATES.YES.TOOLTIP': 'Active accept all SSL certificate',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCOUNT.LABEL': 'IMAP - Account',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCOUNT.PLACEHOLDER': 'confirmations@mazars.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.ACCOUNT': 'IMAP account is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.PASSWORD.LABEL': 'IMAP - Account password',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.PASSWORD.PLACEHOLDER': 'azerty1234',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.PASSWORD': 'IMAP account password is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.HOST.LABEL': 'IMAP - Host',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.HOST.PLACEHOLDER': 'outlook.office365.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.HOST': 'IMAP host is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.PORT.LABEL': 'IMAP - Port',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.PORT.PLACEHOLDER': '993',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.PORT': 'IMAP port is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.SECURE_SOCKET_OPTIONS.LABEL': 'IMAP - Secure socket option',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.SECURE_SOCKET_OPTIONS.PLACEHOLDER': 'None',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.SECURE_SOCKET_OPTIONS': 'IMAP secure socket option is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.LABEL': 'IMAP - Accept all SSL certificates',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.PLACEHOLDER': 'False',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.IMAP.ACCEPT_ALL_SSL_CERTIFICATES': 'IMAP accept all SSL certificates is required',

    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.NO.LABEL': 'No',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.NO.TOOLTIP': 'Unactive accept all SSL certificate',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.YES.LABEL': 'Yes',
    'MAILING.CONFIG.BASIC.AUTH.IMAP.ACCEPT_ALL_SSL_CERTIFICATES.YES.TOOLTIP': 'Active accept all SSL certificate',

    'MAILING.CONFIG.ADMINISTRATORS.LABEL': 'Administrators emails - use coma (,) as separator without spaces',
    'MAILING.CONFIG.ADMINISTRATORS.PLACEHOLDER': 'administrator1@mazars.com, administrator2@mazars.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.ADMINISTRATOR': 'At least one administrator email is required',

    'MAILING.CONFIG.INBOX.FOLDER.LABEL': 'Inbox folder',
    'MAILING.CONFIG.INBOX.FOLDER.PLACEHOLDER': 'Inbox',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.INBOX.FOLDER': 'Mailbox Inbox folder path is required',

    'MAILING.CONFIG.SENT_ITEMS.FOLDER.LABEL': 'Sent Items folder',
    'MAILING.CONFIG.SENT_ITEMS.FOLDER.PLACEHOLDER': 'Sent Items',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.SENT_ITEMS.FOLDER': 'Mailbox Sent Items folder path is required',

    'MAILING.CONFIG.WIP.FOLDER.LABEL': 'Processing in progress folder',
    'MAILING.CONFIG.WIP.FOLDER.PLACEHOLDER': 'WIP',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.WIP.FOLDER': 'Mailbox WIP folder path is required',

    'MAILING.CONFIG.PROCESSED.FOLDER.LABEL': 'Processed folder',
    'MAILING.CONFIG.PROCESSED.FOLDER.PLACEHOLDER': 'PROCESSED',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROCESSED.FOLDER': 'Mailbox Processed folder path is required',

    'MAILING.CONFIG.REQUESTS.FOLDER.LABEL': 'Requests folder',
    'MAILING.CONFIG.REQUESTS.FOLDER.PLACEHOLDER': 'REQUESTS',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.REQUESTS.FOLDER': 'Mailbox Requests folder path is required',

    'MAILING.CONFIG.ISSUES.FOLDER.LABEL': 'Issues folder',
    'MAILING.CONFIG.ISSUES.FOLDER.PLACEHOLDER': 'ISSUES',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.ISSUES.FOLDER': 'Mailbox Issues folder path is required',

    'MAILING.CONFIG.OTHERS.FOLDER.LABEL': 'Others folder',
    'MAILING.CONFIG.OTHERS.FOLDER.PLACEHOLDER': 'OTHERS',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.OTHERS.FOLDER': 'Mailbox Others folder path is required',

    'MAILING.CONFIG.FOLLOWUP.NB.DAYS.LABEL': 'Number of days of restriction for the next follow-up',
    'MAILING.CONFIG.FOLLOWUP.NB.DAYS.PLACEHOLDER': '7',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.FOLLOWUP.NB.DAYS': 'Number of days restriction is required',

    'MAILING.CONFIG.TYPE.AUTH.LABEL': 'Authentification type',
    'MAILING.CONFIG.TYPE.AUTH.PLACEHOLDER': 'Basic authentication (login/password)',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.TYPE.AUTH': 'Type of authentication for mailbox access is required',

    'MAILING.CONFIG.MODERNAUTH.PROVIDER.LABEL': 'Mailbox authentication provider',
    'MAILING.CONFIG.MODERNAUTH.PROVIDER.PLACEHOLDER': 'Microsoft Online',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.PROVIDER': 'Mailbox authentication provider is required',

    'MAILING.CONFIG.MODERNAUTH.USERNAME.LABEL': 'Account e-mail address',
    'MAILING.CONFIG.MODERNAUTH.USERNAME.PLACEHOLDER': 'confirmations@mazars.com',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.USERNAME': 'Mailbox e-mail account is required',

    'MAILING.CONFIG.MODERNAUTH.USER_ID.LABEL': 'Account unique identifier of the provider',
    'MAILING.CONFIG.MODERNAUTH.USER_ID.PLACEHOLDER': 'e.g. 25a6ad59-cb79-4e72-abed-9c034b080a08',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.USER_ID': 'Account unique identifier of the provider is required',

    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.NONE.LABEL': 'None',
    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.SSL_ON_CONNECT.LABEL': 'SSL on connect',
    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.START_TLS.LABEL': 'Start TLS',
    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.START_TLS_WHEN_AVAILABLE.LABEL': 'Start TLS when available',
    'MAILING.CONFIGURATION.SELECT_SECURE_SOCKET_OPTIONS.AUTO.LABEL': 'Auto',

    'MAILING.CONFIGURATION.ACCEPT_ALL_SSL_CERTIFICATES.NO.LABEL': 'No',
    'MAILING.CONFIGURATION.ACCEPT_ALL_SSL_CERTIFICATES.YES.LABEL': 'yes',

    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.LABEL': 'Automatic delete emails',
    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.DEACTIVATED.LABEL': 'Deactivated',
    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.DEACTIVATED.TOOLTIP': 'Automatic delete emails configuration will not delete each email from the mailbox after the end of the automatic processing',
    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.ACTIVATED.LABEL': 'Activated',
    'MAILING.CONFIG.AUTOMATIC_DELETE_EMAILS.ACTIVATED.TOOLTIP': 'Automatic delete emails configuration will delete each email from the mailbox after the end of the automatic processing',
    'MAILING.CONFIG.VALIDATION.REQUIRED_FIELD.AUTOMATIC_DELETE_EMAILS': 'Automatic delete email config is required',

    // Requests messages
    'MAILING.CONFIG.ACCESS.ERROR': 'Access to mailing configuration failed',
    'MAILING.CONFIG.USER.PROFILE.ERROR': 'Access to user profile failed',
    'MAILING.CONFIG.CREATE.ERROR': 'Create mailing configuration error',
    'MAILING.CONFIG.CREATE.SUCCESS': 'Mailing configuration created',
    'MAILING.CONFIG.UPDATE.ERROR': 'Update mailing configuration error',
    'MAILING.CONFIG.UPDATE.SUCCESS': 'Mailing configuration updated',
    'MAILING.AUTH.PROVIDERS.ACCESS.ERROR': 'Access to providers failed',
    'MAILING.AUTH.PROVIDERS.CREATE.SUCCESS': 'Provider created',
    'MAILING.AUTH.PROVIDERS.CREATE.ERROR': 'Provider create failed',
    'MAILING.AUTH.PROVIDERS.UPDATE.SUCCESS': 'Provider updated',
    'MAILING.AUTH.PROVIDERS.UPDATE.ERROR': 'Provider update failed',
    'MAILING.AUTH.PROVIDERS.DELETE.SUCCESS': 'Provider deleted',
    'MAILING.AUTH.PROVIDERS.DELETE.ERROR': 'Provider delete failed',
};