import './home.scss';
import { useSelector } from 'react-redux';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PROFILE } from '../../types/types';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import { ClientHome } from './ClientHome';
import { AuditorHome } from './AuditorHome';
import { connect } from 'react-redux';
import { compose } from 'redux';

const homeReducer = (sessionProfile: number, userProfile: number) => {
    if (isAuthorisedProfile(PROFILE.AUDITOR, userProfile) && sessionProfile === PROFILE.AUDITOR) return <AuditorHome />;
    else if (isAuthorisedProfile(PROFILE.CLIENT, userProfile) && sessionProfile === PROFILE.CLIENT)
        return <ClientHome />;
    else return <PageNotFound />;
};

export const HomeComponent = () => {
    const user: any = useSelector((state: any) => state.account.user);
    const sessionProfile: any = useSelector((state: any) => state.app.sessionProfile);
    return <>{homeReducer(sessionProfile, user.profil)}</>;
};

const mapStateToProps = (state: any) => {
    return {
        user: state.account.user,
        sessionProfile: state.app.sessionProfile
    };
};

export const Home = compose(connect(mapStateToProps, null))(HomeComponent);
