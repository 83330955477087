import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { LetterTemplateConfigComponent } from '../../components/setup';
import {
    fetchData,
    fetchConfirmationCategories,
    fetchConfigurationChecks,
    LetterTemplateService,
    WorkflowService
} from '../../services';
import { CircularProgress } from '@mui/material';

/**
 * Confirmations setup component for users with country administration role
 */
class LetterTemplateSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };

        this.letterTemplateManager = LetterTemplateService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading,
            callback: async (isSuccess, _data, _) => isSuccess && await this.fetchLetterTemplates({clusterId: this.props.currentClusterId})
        });

        this.workflowManager = WorkflowService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading,
            callback: async (isSuccess, _data, _) => isSuccess && await this.fetchWorkflows({clusterId: this.props.currentClusterId})
        });
    };

    setLoading = isLoading => {
        this.setState({ loading: isLoading });
    };

    fetchLetterTemplates = async filters => {
        const result = await this.letterTemplateManager.fetchAll(filters);
        if (result?.isSuccess) {
            this.setState({ letterTemplates: Object.values(result.parsedValue) });
        } 
    };

    letterTemplateSubmit = async template => {
        if (template?.id && template?.id !== null) {
            return await this.letterTemplateManager.update(template);
        } else {
            return await this.letterTemplateManager.create({ ...template, clusterId: this.props.currentClusterId });
        }
    };

    fetchLetterTemplateTags = async () => {
        const result = await this.letterTemplateManager.fetchTags();
        if (result?.isSuccess) {
            this.setState({ letterTemplateTags: Object.values(result.parsedValue) });
        } 
    };

    fetchWorkflows = async filters => {
        const result = await this.workflowManager.fetchAll(filters);
        if (result?.isSuccess) {
            this.setState({ workflows: Object.values(result.parsedValue) });
        } 
    };

    async componentDidMount() {
        await fetchData(this, [
            () => fetchConfirmationCategories(this),
            () => fetchConfigurationChecks(this, {clusterId: this.props.currentClusterId}),
            () => this.fetchLetterTemplateTags(),
            () => this.fetchWorkflows({clusterId: this.props.currentClusterId}),
            () => this.fetchLetterTemplates({clusterId: this.props.currentClusterId})
        ]);
    };

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            if (!this.state.loading)
                await fetchData(this, [
                    () => fetchConfigurationChecks(this, {clusterId: this.props.currentClusterId}),
                    () => this.fetchWorkflows({clusterId: this.props.currentClusterId}),
                    () => this.fetchLetterTemplates({clusterId: this.props.currentClusterId})
                ]);
        }
    };

    render() {
        return (
            <>
                {this.state.letterTemplates ? (
                    <LetterTemplateConfigComponent
                        loading={this.state.loading}
                        workflows={this.state.workflows}
                        languages={this.props.languages}
                        letterTemplates={this.state.letterTemplates}
                        letterTemplateTags={this.state.letterTemplateTags}
                        confirmationCategories={this.state.confirmationCategories}
                        letterTemplateSubmit={this.letterTemplateSubmit}
                        deleteLetterTemplate={this.letterTemplateManager.delete}
                        configurationsChecks={this.state.configurationsChecks}
                    />
                ) : (
                    <div className="fullCenter">
                        <CircularProgress />
                    </div>
                )}
            </>
        );
    }
}

LetterTemplateSetupComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    checkSessionResults: PropTypes.array,
    languages: PropTypes.array.isRequired
};

LetterTemplateSetupComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        languages: state.cluster.languages,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const LetterTemplateSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(LetterTemplateSetupComponent));
