import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { MouseEventHandler } from 'react';
import { UIActionButton } from '../../common/UIActionButton';
import { toolbarStyle } from '../../../styles/toolbar/ToolbarStyle';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { UIBackButton } from '../../common/UIBackButton';

interface CreateConfirmationToolbarItemsProps {
    handleBack: MouseEventHandler | undefined;
    handleReset: () => void;
}

export const CreateConfirmationToolbarItems = ({ handleBack, handleReset }: CreateConfirmationToolbarItemsProps) => {
    const intl = useIntl();

    return (
        <Box sx={toolbarStyle.container}>
            <UIBackButton onClick={handleBack} />

            <UIActionButton
                sx={toolbarStyle.button}
                display="secondary"
                onClick={handleReset}
                title={getLocalizedString(intl, 'CREATE_CONFIRMATION.BUTTON.LABEL.RESET')}
            />
        </Box>
    );
};
