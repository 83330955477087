import React, { Component } from 'react';
import { UserResourceService } from '../../../services';
import { Help } from '@mui/icons-material';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withCheckSession } from '../../../enhancers';
import { connect } from 'react-redux';
import { saveDownloadedFile } from '../../../helpers';
import * as actions from '../../../store/actions';
import { hasOnlyProfile } from '../../auth/UserHelper';
import { userResourceReducer } from '../UserResourceHelper';
import { UIDropDownMenu } from '../../common/UIDropDownMenu';
import { UIActionButton } from '../../common/UIActionButton';
import { PROFILE } from '../../../types/types';

const UserResourcesTitleComponent = ({ onClick }) => {
    return (
        <UIActionButton
            onClick={onClick}
            endIcon={<Help />}
            color="inherit"
            sx={{ borderColor: 'white', minHeight: 'auto' }}>
            <FormattedMessage id="COMMON.HELP" />
        </UIActionButton>
    );
};

class UserResourcesMenuComponent extends Component {
    state = {
        userResources: []
    };

    async componentDidMount() {
        this.userResourceManager = UserResourceService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage
        });

        await this.fetchUserResources();
    }

    handleDownloadFile = resource => async event => {
        event.preventDefault();
        event.stopPropagation();
        await this.userResourceManager.fetchFile(
            resource.id,
            (isSuccess, data, _) => isSuccess && saveDownloadedFile(data, resource.name)
        );
    };

    fetchUserResources = async () => {
        const { isSuccess, parsedValue: resources } = await this.userResourceManager.fetch();

        if (isSuccess) {
            const menuResources = resources.map(resource =>
                userResourceReducer({
                    resource,
                    handleDownloadFile: this.handleDownloadFile
                })
            );

            this.setState({ userResources: menuResources });
        }
    };

    render() {
        const { user } = this.props;
        const { userResources } = this.state;

        return (
            <>
                {!hasOnlyProfile(PROFILE.CLIENT, user.profil) ? (
                    <UIDropDownMenu
                        entries={userResources}
                        titleComponent={UserResourcesTitleComponent}
                        id="ResourceMenu"
                    />
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user
    };
};

export const UserResourcesTopBarItem = compose(
    injectIntl,
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(UserResourcesMenuComponent);
