export const common = {
    'COMMON.HELP': 'Aide',
    'COMMON.LOADING.IN.PROGRESS': 'chargement ...',
    'APPLICATION.NAME': 'Confirmations',
    'PAGE_NOT_FOUND.TITLE': 'Nous ne pouvons pas vous laisser accéder cette page.',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_1': 'Pour accéder à cette page, vous devrez peut-être ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_2_LINK': 'vous connecter avec un autre compte. ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_3': 'Vous pouvez retourner à la page ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_4_LINK': 'précédente ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_5': 'ou se rendre sur ',
    'PAGE_NOT_FOUND.PARAGRAPH_PART_6_LINK': 'la page d\'accueil.',
    'DEFAULT_ERROR_MESSAGE.TITLE': 'Erreur',
    'DEFAULT_ERROR_MESSAGE.MESSAGE': 'Un problème est survenu',
    'INVALID.FIELD.MIN.SYMBOLS': 'Minimum {min} caractères',
    'INVALID.FIELD.MAX.SYMBOLS': 'Maximum {max} caractères',
    'INVALID.FIELD.MIN.NUMBER': '{min} est le minimum',
    'INVALID.FIELD.MAX.NUMBER': '{max} est le maximum',
    'INVALID.FIELD.GUID.REGEX': 'Id format invalid',
    'INVALID.FIELD.EMAIL.FORMAT': 'Format email invalide',
    'PLACEHOLDER.END.OF.FILE': 'Placeholder end of file',
    'COMMON.SEARCH': 'Recherche',
    'COMMON.BUTTON.BACK.LABEL': 'Retour',
    'COMMON.POWERED_BY.LABEL': 'Powered by Global Technology & Innovation'
};
