import { useIntl } from 'react-intl';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Palette } from '../../../styles/colors';
import React from 'react';
import { getLocalizedString } from '../../../i18n/i18Helper';

interface MZRSearchFieldProps {
    filter: {
        id: string;
        value: string;
    };
    filterKey: string;
    handleSearch: () => void;
    handleChange: (event) => void;
    titleId: string;
    onClear?: (event) => void;
    children?: React.ReactElement;
    searchIcon?: React.ReactElement;
}

export const MZRSearchField = ({
    filter,
    filterKey,
    handleSearch,
    handleChange,
    titleId,
    onClear,
    searchIcon
}: MZRSearchFieldProps) => {
    const intl = useIntl();
    const uiOptions: TextFieldProps = { variant: 'standard', size: 'medium', sx: sx.textField };

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const onChange = event => {
        handleChange(event);

        if (!event.target.value) {
            onClear && onClear(event);
        }
    };

    return (
        <TextField
            {...uiOptions}
            InputProps={{
                startAdornment: <InputAdornment position="start">{searchIcon}</InputAdornment>
            }}
            key={filterKey}
            type="search"
            value={filter.value}
            placeholder={getLocalizedString(intl, titleId)}
            name={filter.id}
            onChange={onChange}
            onKeyPress={handleKeyPress}
        />
    );
};

const sx = {
    textField: {
        marginX: 1,
        size: 'small',
        minWidth: '200px',
        input: { color: Palette.primaryTextColor, fontSize: 14 }
    }
};
