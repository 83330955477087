import { getLocalizedString } from '../../i18n/i18Helper';
import { UIActionButton } from './UIActionButton';
import React from 'react';
import { useIntl } from 'react-intl';
import { toolbarStyle } from '../../styles/toolbar/ToolbarStyle';
import { ButtonProps } from '@mui/material';

export const UIBackButton = ({ sx, onClick }: ButtonProps) => {
    const intl = useIntl();
    const style = sx ?? {};

    return (
        <UIActionButton
            sx={{ ...style, ...toolbarStyle.button }}
            display="secondary"
            onClick={onClick}
            title={getLocalizedString(intl, 'COMMON.BUTTON.BACK.LABEL')}
        />
    );
};
