import { auth } from './auth';
import { home } from './home';
import { engagement } from './engagement';
import { settings } from './settings';
import { confirmation } from './confirmation';
import { contact } from './contact';
import { company } from './company';
import { externalClient } from './externalClient';
import { administration } from './administration';
import { documents } from './documents';
import { support } from './support';
import { common } from './common';
import { popup } from './popup';
import { header } from './header';
import { mailing } from './mailing';

export const fr = {
    ...header,
    ...common,
    ...auth,
    ...home,
    ...engagement,
    ...settings,
    ...confirmation,
    ...contact,
    ...company,
    ...externalClient,
    ...administration,
    ...documents,
    ...support,
    ...popup,
    ...mailing
};
