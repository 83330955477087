import React, { useEffect } from 'react';
import { getCrumbs } from '../../navigation/RouterHelpers';
import { Breadcrumbs } from './components/Breadcrumbs';
import { injectIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { UserResourcesTopBarItem } from './components/UserResourcesTopBarItem';
import './warningBanner.scss';
import '../../components/engagement/common/engagement.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}));

/**
 * A second bar for the tool is placed underneath in a solid blue bar (‘true blue’ colour) with the.
 * All navigation relating to the tool should be placed in this blue bar.
 *
 * flex-wrap flex-sm-nowrap
 *
 */
const MZRNavHeaderComponent = props => {
    const { intl } = props;
    const classes = useStyles();
    const crumbs = getCrumbs(props);
    const pageTitle = crumbs?.length > 0 ? crumbs[crumbs.length - 1].title : '';

    useEffect(() => {
        document.title = pageTitle;
    }, []);

    return (
        <div className="container">
            <div className="mzr-nav-header">
                <Grid container justifyContent="space-between" className={classes.root}>
                    <Grid container item xs={11}>
                        <Grid item>
                            <h2 className="my-2 mr-5">
                                {intl.formatMessage({
                                    id: 'APPLICATION.NAME'
                                })}
                            </h2>
                        </Grid>

                        <Grid item>
                            <Breadcrumbs crumbs={crumbs} />
                        </Grid>
                    </Grid>

                    <Grid item container xs={1} alignItems="center" justifyContent="center">
                        <UserResourcesTopBarItem />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export const MZRNavHeader = injectIntl(MZRNavHeaderComponent);
