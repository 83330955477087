/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toAbsoluteUrl } from '../../helpers/AssetsHelpers';

export function TilesWidget({ className, icon, iconColor = 'success', widgetHeight = '150px', type, number }) {
    return (
        <>
            <div className={`card card-custom ${className}`} style={{ height: widgetHeight }}>
                <div className="card-body">
                    <span className={`svg-icon svg-icon-3x svg-icon-${iconColor}`}>
                        {icon ? icon : <img alt="title" src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')} />}
                    </span>
                    <div className=".text-white font-weight-bolder font-size-h2 mt-3">{number}</div>

                    <a className=".text-white text-hover-white font-weight-bold font-size-lg mt-1">{type}</a>
                </div>
            </div>
        </>
    );
}
