import moment from 'moment';

const formatDate = (date, format) => {
    const momentDate = moment(date).format(format);
    return momentDate;
};

export const readableDate = (date, format = 'YYYY-MM-DD') => {
    return date && formatDate(date, format);
};

export const parseDate = (date, format = 'YYYY-MM-DD') => {
    return moment(date, format);
};

export const dateWithOffset = (date, offsetYears = 0, offSetMonths = 0, offSetDays = 0) => {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    const futureDate = new Date(year + offsetYears, month + offSetMonths, day + offSetDays);
    return futureDate;
};

export const isValidDate = date => {
    return date instanceof Date && !isNaN(date);
};

export const localizedReadableFullDate = (intl, dateString) => {
    return dateString
        ? intl.formatDate(moment(dateString).toDate(), {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
          })
        : '';
};

/// give the diff between the given dates
export const datesDiff = (date1, date2, unit = 'month') => {
    return moment(date2).diff(moment(date1), unit);
};
