import React from 'react';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { Palette } from '../../styles/colors';

interface UITextProps {
    text?: string;
}

export const UIText = ({ text, sx, variant, ...rest }: UITextProps & TypographyProps) => {
    const updatedStyle = { ...styles.text, ...sx };
    return (
        <Typography {...rest} variant={variant} sx={updatedStyle}>
            {text}
        </Typography>
    );
};

const styles = {
    text: {
        color: Palette.primaryTextColor,
        fontSize: 14
    }
};
