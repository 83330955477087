import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { UIActionButton } from '../../../common/UIActionButton';
import React from 'react';
import { getLocalizedString } from '../../../../i18n/i18Helper';

interface ClientRequestSignatureActionsProps {
    request: any;
    handleRefuse: (request: any) => void;
    handleDownload: (request: any) => void;
    handleSignLater: (request: any) => void;
    handleElectronicSignature: (request: any) => void;
}

export const ClientRequestSignatureActions = ({
    request,
    handleRefuse,
    handleSignLater,
    handleElectronicSignature
}: ClientRequestSignatureActionsProps) => {
    const intl = useIntl();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gridGap: 3, mt: 1, justifyContent: 'center' }}>
            <UIActionButton
                display="secondary"
                title={getLocalizedString(intl, 'CLIENT.REQUEST.ACTION.REFUSE')}
                onClick={handleRefuse}
            />
            <UIActionButton
                title={getLocalizedString(intl, 'CLIENT.REQUEST.ACTION.SIGN_LATER')}
                onClick={handleSignLater}
            />

            <UIActionButton
                disabled={true}
                title={getLocalizedString(intl, 'CLIENT.REQUEST.ACTION.SIGNATURE.E_SIGNATURE.OPTION')}
                onClick={handleElectronicSignature}
            />
        </Box>
    );
};
