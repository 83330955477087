import { metricsRepository } from '../kit/repository';
import { performAPIAction } from './BaseService';

const MetricsMethods = ({ user, setErrorMessage, ...rest }) => {
    return {
        fetch: async (filters = {}) =>
            performAPIAction({
                ...rest,
                apiCall: metricsRepository.fetchAll(filters),
                errorMessageId: 'METRICS.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            })
    };
};

export const MetricsService = ({
    intl,
    user,
    checkSession,
    addToastMessage,
    callback,
    setLoading = () => {},
    setErrorMessage = () => {}
}) => {
    const metricsMethods = MetricsMethods({
        intl,
        user,
        checkSession,
        addToastMessage,
        callback,
        setLoading,
        setErrorMessage
    });
    return { ...metricsMethods };
};
