import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInput from '../../../textField/TextInput';
import { initStorageDefault } from './InitStorageDefault';
import { getFieldElement } from '../../../../helpers/FormHelper';
import { COMPONENT_NAME } from '../../constants';

/**
 * Storage Default component fields.
 *  *
 * @param {*} props
 * @returns
 */
export function StorageDefaultComponent(props) {
    const { storageDefault, hasValidConfig, disabled } = props;
    const variant = disabled ? 'standard' : 'outlined';

    const [hasBeenTrigger, setHasBeenTrigger] = useState(false);
    const [storageConfig, setStorage] = useState(initStorageDefault);

    useEffect(() => {
        storageDefault && setStorage(storageDefault);
    }, [storageDefault]);

    const buttonFormRef = useRef(null);
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    props.sbmtConfRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    // Validation schema
    const StorageSchema = Yup.object().shape({
        clusterId: Yup.object().nullable(),
        isActive: Yup.boolean(),
        // .required(
        //     <FormattedMessage id="STORAGE.DEFAULT.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.ISACTIVE" />
        // ),
        priorityLevel: Yup.number(),
        // .required(<FormattedMessage id="DMS.CONFIG.VALIDATION.REQUIRED_FIELD.PASSWORD" />),
        targetPath: Yup.string().min(5, 'Minimum 5 symbols').max(20, 'Maximum 20 symbols')
        // .required(<FormattedMessage id="STORAGE.DEFAULT.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TARGET_PATH" />)
    });

    const STORAGE_DEFAULT_FIELDS = [
        {
            labelItem: { id: 'STORAGE.DEFAULT.CONFIG.FIELD.TARGET_PATH.LABEL' },
            TextFieldItem: {
                id: 'targetPath',
                component: disabled && TextInput,
                variant: variant,
                disabled: disabled ?? false
            }
        }
    ];

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Formik
                    enableReinitialize
                    validationSchema={StorageSchema}
                    initialValues={storageConfig}
                    onSubmit={async values => {
                        setHasBeenTrigger(false);
                        return props.handleSubmit(values);
                    }}>
                    {formikProps => {
                        if (Object.keys(formikProps?.touched).length > 0) {
                            !hasBeenTrigger && hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, false);
                            setHasBeenTrigger(true);
                        }
                        return (
                            <Form className="form">
                                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                    {STORAGE_DEFAULT_FIELDS?.map(field => (
                                        <Grid
                                            container
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '0 20%'
                                            }}>
                                            <Grid item>
                                                <label>
                                                    <FormattedMessage id={field?.labelItem?.id} />
                                                </label>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '22em'
                                                }}
                                                item>
                                                {getFieldElement(field)}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                                <button
                                    type="submit"
                                    style={{ display: 'none' }}
                                    onClick={triggerButtonClick}
                                    ref={buttonFormRef}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </>
    );
}

injectIntl(StorageDefaultComponent);
