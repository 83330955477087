import * as actions from '../actions';

const initialState = {
    sessionType: '', // @see SessionType
    user: { firstName: '', lastName: '', fullname: '', email: '', clusterId: null, profil: '' },
    roles: [],
    isUserConnected: false
};

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.Types.SET_USER_CONNECTION:
            return {
                ...state,
                isUserConnected: action.isConnected,
                sessionType: action.sessionType
            };

        case actions.Types.SET_PROFILE:
            return { ...state, user: action.profile, roles: action.profile.roles };

        case actions.Types.RESET_USER:
            return { ...state, ...initialState };

        default:
            return state;
    }
};
