import React, { useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import * as Yup from 'yup';
import TextInput from '../../textField/TextInput';
import { Grid } from '@mui/material';
import { FormLabel, getFieldElement } from '../../../helpers/FormHelper';
import { WorkflowWrapper } from './WorkFlowWrapper';

const WorkflowSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 3 }} />)
        .required(<FormattedMessage id="WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.NAME" />),
    description: Yup.string()
        .min(3, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 3 }} />)
        .max(255, 'Maximum 255 symbols'),
    confirmationCategories: Yup.array(),
    workflowActions: Yup.array().of(
        Yup.object().shape({
            position: Yup.number()
                .min(0, <FormattedMessage id="WORKFLOW.INFO.VALIDATION.POSITIVE.ACTION.POSITION" />)
                .required(<FormattedMessage id="WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.POSITION" />),
            name: Yup.string()
                .min(3, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 3 }} />)
                .required(<FormattedMessage id="WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.NAME" />),
            description: Yup.string()
                .min(3, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 3 }} />)
                .required(<FormattedMessage id="WORKFLOW.INFO.VALIDATION.REQUIRED_FIELD.ACTION.DESCRIPTION" />)
        })
    )
});

/**
 * Component to create a confirmation template.
 */
export function InfoWorkflowComponent(props) {
    const { categories, workflowActions, workflow, workflows, handleSubmit, edition } = props;

    const variant = edition ? 'outlined' : 'standard';

    const buttonWorkflowFormRef = useRef(null);
    const triggerButtonClick = () => buttonWorkflowFormRef?.current?.click();
    props.sbmtWorkflowRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const WORKFLOW_FIELDS = [
        {
            labelItem: { id: 'WORKFLOW.INFO.FIELD.NAME.LABEL', isRequired: true },
            TextFieldItem: {
                id: 'name',
                component: !edition && TextInput,
                variant: variant,
                disabled: edition ? false : true
            }
        },
        {
            labelItem: { id: 'WORKFLOW.INFO.FIELD.DESCRIPTION.LABEL' },
            TextFieldItem: {
                id: 'description',
                component: !edition && TextInput,
                variant: variant,
                disabled: edition ? false : true
            }
        }
    ];

    const CATEGORIES_FIELDS = [
        {
            labelItem: { id: 'WORKFLOW.INFO.FIELD.CATEGORY.LABEL', isRequired: true },
            SelectFieldItem: {
                id: 'confirmationCategories',
                options: categories ? categories : [{ id: 0, name: 'None' }],
                optionsObjectChoices: true,
                multiple: true,
                variant: variant,
                disabled: edition ? false : true
            }
        }
    ];

    const WORKFLOW_ACTIONS_FIELDS = [
        {
            labelItem: { id: 'WORKFLOW.INFO.FIELD.WORKFLOW_ACTIONS.LABEL' },
            SelectFieldItem: {
                id: 'workflowActions',
                options: workflowActions ? workflowActions : [{ id: 0, name: 'None' }],
                optionsObjectChoices: true,
                multiple: true,
                variant: variant,
                disabled: edition ? false : true,
                noValidation: true
            }
        }
    ];

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={workflow}
                validationSchema={WorkflowSchema}
                onSubmit={values => handleSubmit(values)}>
                {formikProps => {
                    return (
                        <Form className="form form-label-right">
                            <Grid container justifyContent="space-around" alignItems="stretch" spacing={2}>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    justifyContent="space-around"
                                    alignItems="stretch"
                                    spacing={1}>
                                    {WORKFLOW_FIELDS?.map(field => {
                                        if (field?.HiddenItem?.field) {
                                            return null;
                                        }
                                        return (
                                            <Grid
                                                container
                                                item
                                                key={`formik-grid-item-${field?.labelItem?.id}`}
                                                direction="column"
                                                justifyContent="space-around"
                                                alignItems="stretch">
                                                <Grid container item>
                                                    {field?.HiddenItem?.label ?? (
                                                        <label>
                                                            <FormLabel
                                                                id={field?.labelItem?.id}
                                                                isRequired={field?.labelItem?.isRequired}
                                                            />
                                                        </label>
                                                    )}
                                                </Grid>
                                                <Grid container item>
                                                    {getFieldElement(field)}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>

                                <Grid
                                    container
                                    item
                                    direction="column"
                                    justifyContent="space-around"
                                    alignItems="stretch"
                                    spacing={1}>
                                    {edition &&
                                        CATEGORIES_FIELDS?.map(field => {
                                            if (field?.HiddenItem?.field) {
                                                return null;
                                            }
                                            return (
                                                <Grid
                                                    container
                                                    item
                                                    key={`formik-grid-item-${field?.labelItem?.id}`}
                                                    direction="column"
                                                    justifyContent="space-around"
                                                    alignItems="stretch">
                                                    <Grid container item>
                                                        {field?.HiddenItem?.label ?? (
                                                            <label>
                                                                <FormLabel
                                                                    id={field?.labelItem?.id}
                                                                    isRequired={field?.labelItem?.isRequired}
                                                                />
                                                            </label>
                                                        )}
                                                    </Grid>
                                                    <Grid container item>
                                                        {getFieldElement(field)}
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                </Grid>

                                <Grid
                                    container
                                    item
                                    direction="column"
                                    justifyContent="space-around"
                                    alignItems="stretch"
                                    spacing={2}>
                                    <Grid item>
                                        <label>
                                            <FormLabel id="WORKFLOW.ACTIVATE.WORKFLOW.STEP.LABEL" />
                                        </label>
                                    </Grid>
                                    <Grid item>
                                        <Field
                                            component={WorkflowWrapper}
                                            name="confirmationCategories"
                                            options={workflows}
                                            disabled={!edition}
                                            isCategoryDeletable={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    direction="column"
                                    justifyContent="space-around"
                                    alignItems="stretch"
                                    spacing={1}>
                                    {edition &&
                                        WORKFLOW_ACTIONS_FIELDS?.map(field => {
                                            if (field?.HiddenItem?.field) {
                                                return null;
                                            }
                                            return (
                                                <Grid
                                                    container
                                                    item
                                                    key={`formik-grid-item-${field?.labelItem?.id}`}
                                                    direction="column"
                                                    justifyContent="space-around"
                                                    alignItems="stretch">
                                                    <Grid container item>
                                                        {field?.HiddenItem?.label ?? (
                                                            <label>
                                                                <FormLabel
                                                                    id={field?.labelItem?.id}
                                                                    isRequired={field?.labelItem?.isRequired}
                                                                />
                                                            </label>
                                                        )}
                                                    </Grid>
                                                    <Grid container item>
                                                        {getFieldElement(field)}
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                </Grid>

                                {edition && (
                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="stretch"
                                        spacing={2}>
                                        <Grid container item>
                                            <label>
                                                <FormLabel id={'WORKFLOW.INFO.FIELD.ACTIONS.LABEL'} isRequired={true} />
                                            </label>
                                        </Grid>
                                        <Grid container justifyContent="center" item spacing={1}>
                                            <Grid
                                                container
                                                item
                                                key={`formik-grid-item-${'labels'}`}
                                                justifyContent="space-around"
                                                alignItems="stretch"
                                                spacing={2}
                                                xs={12}>
                                                <Grid container justifyContent="center" alignItems="center" item xs={1}>
                                                    <label>
                                                        <FormLabel
                                                            id={'WORKFLOW.INFO.FIELD.ACTIONS.POSITION.LABEL'}
                                                            isRequired={true}
                                                        />
                                                    </label>
                                                </Grid>
                                                <Grid container item xs>
                                                    <label>
                                                        <FormLabel
                                                            id={'WORKFLOW.INFO.FIELD.ACTIONS.NAME.LABEL'}
                                                            isRequired={true}
                                                        />
                                                    </label>
                                                </Grid>
                                                <Grid container item xs>
                                                    <label>
                                                        <FormLabel
                                                            id={'WORKFLOW.INFO.FIELD.ACTIONS.DESCRIPTION.LABEL'}
                                                            isRequired={true}
                                                        />
                                                    </label>
                                                </Grid>
                                            </Grid>
                                            {formikProps?.values?.workflowActions
                                                ?.sort((a, b) =>
                                                    a?.position !== b?.position
                                                        ? a?.position < b?.position
                                                            ? -1
                                                            : 1
                                                        : 0
                                                )
                                                ?.map((action, index) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            item
                                                            key={`formik-grid-item-${action?.id}`}
                                                            justifyContent="space-around"
                                                            alignItems="stretch"
                                                            spacing={2}
                                                            xs={12}>
                                                            <Grid container item xs={1}>
                                                                <Field
                                                                    id={`step-${index}-position`}
                                                                    name={`workflowActions[${index}].position`}
                                                                    component={TextInput}
                                                                    type="number"
                                                                    variant={variant}
                                                                    disabled={!action?.isSelected ?? false}
                                                                    fullWidth
                                                                />
                                                                <ErrorMessage
                                                                    name={`workflowActions[${index}].position`}>
                                                                    {errorMessage =>
                                                                        FormattedErrorMessage(errorMessage)
                                                                    }
                                                                </ErrorMessage>
                                                            </Grid>
                                                            <Grid container item xs>
                                                                <Field
                                                                    id={`step-${index}-name`}
                                                                    name={`workflowActions[${index}].name`}
                                                                    component={TextInput}
                                                                    variant={variant}
                                                                    disabled={!action?.isSelected ?? false}
                                                                    fullWidth
                                                                />
                                                                <ErrorMessage name={`workflowActions[${index}].name`}>
                                                                    {errorMessage =>
                                                                        FormattedErrorMessage(errorMessage)
                                                                    }
                                                                </ErrorMessage>
                                                            </Grid>
                                                            <Grid container item xs>
                                                                <Field
                                                                    id={`step-${index}-description`}
                                                                    name={`workflowActions[${index}].description`}
                                                                    component={TextInput}
                                                                    variant={variant}
                                                                    disabled={!action?.isSelected ?? false}
                                                                    fullWidth
                                                                />
                                                                <ErrorMessage
                                                                    name={`workflowActions[${index}].description`}>
                                                                    {errorMessage =>
                                                                        FormattedErrorMessage(errorMessage)
                                                                    }
                                                                </ErrorMessage>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            <button
                                type="submit"
                                style={{ display: 'none' }}
                                onClick={triggerButtonClick}
                                ref={buttonWorkflowFormRef}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default injectIntl(InfoWorkflowComponent);
