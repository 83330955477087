import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import { useSelector } from 'react-redux';

export const ScreenSelector = ({ authorizedProfile, children }) => {
    const user: any = useSelector((state: any) => state.account.user);
    const sessionProfile: any = useSelector((state: any) => state.app.sessionProfile);

    if (isAuthorisedProfile(authorizedProfile, user?.profil) && sessionProfile === authorizedProfile) {
        return children;
    } else return <PageNotFound />;
};
