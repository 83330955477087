import React from 'react';
import { deepFreeze } from '../../../helpers/FreezeHelper';

const InitLetterTemplate = {
    id: null,
    name: null,
    description: null,
    confirmationCategoryId: null,
    workflowId: null,
    clusterId: null,
    languageId: null,
    tags: [],
    file: null
};

export const initLetterTemplate = deepFreeze(InitLetterTemplate);

export const LetterTemplateContext = React.createContext({
    fetchAllTemplates: null,
    fetchTemplate: null,
    createTemplate: null,
    updateTemplate: null,
    downloadTemplate: null,
    deleteTemplate: null
});
