import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { MailingConfigComponent } from '../../components/setup';
import { fetchData, MailingAuthProviderService, MailingConfigService } from '../../services';
import { CircularProgress } from '@mui/material';

class MailingSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    authProviderManager = MailingAuthProviderService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading,
        callback: async (isSuccess, data, _) => isSuccess && this.setState({ authProviders: data?.parsedValue })
    });

    mailingConfigManager = MailingConfigService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading,
        callback: async (isSuccess, data, _) => isSuccess && this.setState({ mailingConfig: data?.parsedValue })
    });

    handleSaveConfig = configuration => {
        const clusterConfiguration = { ...configuration, clusterId: this.props.currentClusterId };
        !!configuration?.id
            ? this.mailingConfigManager.update(clusterConfiguration)
            : this.mailingConfigManager.create(clusterConfiguration);
    };

    async componentDidMount() {
        await fetchData(this, [
            () => this.mailingConfigManager.fetchCluster(this.props.currentClusterId),
            () => this.authProviderManager.fetchCluster(this.props.currentClusterId)
        ]);
    }

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            if (!this.state.loading)
                await fetchData(this, [
                    () => this.mailingConfigManager.fetchCluster(this.props.currentClusterId),
                    () => this.authProviderManager.fetchCluster(this.props.currentClusterId)
                ]);
        }
    };

    render() {
        return (
            <>
                {this.state.loading ? ( <div className="fullCenter"><CircularProgress /></div>
                ) : (
                    <MailingConfigComponent
                        authProviders={this.state.authProviders}
                        mailingConfiguration={this.state.mailingConfig}
                        handleSave={this.handleSaveConfig}
                        mailingRef={this.props.mailingRef}
                        hasValidConfig={this.props.hasValidConfig}
                    />
                )}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const MailingSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(MailingSetupComponent));
