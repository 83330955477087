import { performAPIAction } from './BaseService';
import { IntlShape } from 'react-intl';
import { ServiceResult } from '../kit/network/ServiceResult';
import { serializeWorkflow, workflowActionRepository, workflowRepository } from '../kit/repository';

interface ServiceProps {
    filters: Record<string, any>;
    intl: IntlShape;
    setLoading: (loading: boolean) => void;
    checkSession: (result: ServiceResult) => void;
    addToastMessage: (toast: any) => void;
    callback: null | ((isSuccess: boolean, data: ServiceResult) => void);
}

export const WorkflowService = (props: ServiceProps) => {
    return {
        fetchAll: (filters: Record<string, any>) =>
            performAPIAction({
                ...props,
                apiCall: workflowRepository.fetchAll(filters),
                errorMessageId: 'FETCH.CONFIRMATION.WORKFLOWS.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        fetchActions: () =>
            performAPIAction({
                ...props,
                apiCall: workflowActionRepository.fetchAll(),
                errorMessageId: 'FETCH.CONFIRMATION.WORKFLOW.ACTIONS.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        create: (workflow: any) =>
            performAPIAction({
                ...props,
                apiCall: workflowRepository.create(serializeWorkflow(workflow)),
                successMessageId: 'FETCH.WORKFLOW.CREATE.SUCCESS',
                errorMessageId: 'FETCH.WORKFLOW.CREATE.ERROR'
            }),

        update: (workflow: any) =>
            performAPIAction({
                ...props,
                apiCall: workflowRepository.update(workflow.id, serializeWorkflow(workflow)),
                successMessageId: 'FETCH.WORKFLOW.UPDATE.SUCCESS',
                errorMessageId: 'FETCH.WORKFLOW.UPDATE.ERROR'
            }),

        delete: (workflowId: string) =>
            performAPIAction({
                ...props,
                apiCall: workflowRepository.delete(workflowId),
                successMessageId: 'FETCH.WORKFLOW.DELETE.SUCCESS',
                errorMessageId: 'FETCH.WORKFLOW.DELETE.ERROR'
            }),



        
    };
};
