import React from 'react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { EditEntityRow } from './EditEntityRow';

export const EditThirdPartyCompany = ({ intl }) => {
    return {
        title: 'ThirdParty',
        yup: Yup.object().shape({
            name: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.NAME" />),
            address: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.ADDRESS" />),
            zipCode: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.ZIPCODE" />),
            city: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.CITY" />),
            countryText: Yup.string()
                .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
                .required(<FormattedMessage id="EDIT_CLIENT.CREATE.VALIDATION.REQUIRED_FIELD.COUNTRY" />)
        }),
        fields: [
            {
                render: () =>
                    EditEntityRow({
                        columns: [
                            { id: 'name', label: 'NAME', isRequired: true },
                            {
                                id: 'address',
                                label: 'ADDRESS',
                                isRequired: true
                            }
                        ],
                        intl: intl
                    })
            },

            {
                render: () =>
                    EditEntityRow({
                        columns: [
                            { id: 'zipCode', label: 'ZIPCODE', isRequired: true },
                            {
                                id: 'city',
                                label: 'CITY',
                                isRequired: true
                            }
                        ],
                        intl: intl
                    })
            },
            {
                render: () =>
                    EditEntityRow({
                        columns: [
                            {
                                id: 'countryText',
                                label: 'COUNTRY',
                                isRequired: true
                            }
                        ],
                        intl: intl
                    })
            }
        ]
    };
};
