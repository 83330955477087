import React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { BaseToolbarItems } from '../../common/BaseToolbarItems';
import { UIActionButton } from '../../common/UIActionButton';
import { toolbarStyle } from '../../../styles/toolbar/ToolbarStyle';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { UIBackButton } from '../../common/UIBackButton';

interface SettingsToolbarItemsProps {
    handleBack: () => void;
    handleReset: () => void;
    isResetHidden?: boolean;
    handleDelete: () => void;
    isDeleteHidden?: boolean;
    isSaveHidden?: boolean;
    handleSave: () => void;
    loading: boolean;
    disabled: boolean;
    archiveProps: {
        hidden?: boolean;
        disabled?: boolean;
        handleToggleArchive: () => void;
        title: string;
    };
}

export const SettingsToolbarItems = ({
    handleBack,
    handleReset,
    isResetHidden = false,
    handleDelete,
    isDeleteHidden = true,
    isSaveHidden = false,
    handleSave,
    loading,
    disabled,
    archiveProps: {
        hidden: isToggleArchiveHidden = true,
        disabled: isArchiveDisabled = true,
        handleToggleArchive,
        title: archiveTitle
    }
}: SettingsToolbarItemsProps) => {
    const intl = useIntl();

    return (
        <Box component="span" sx={toolbarStyle.container}>
            <UIBackButton onClick={handleBack} />

            {!isToggleArchiveHidden && (
                <UIActionButton
                    sx={toolbarStyle.button}
                    title={getLocalizedString(intl, archiveTitle)}
                    disabled={isArchiveDisabled}
                    display="secondary"
                    onClick={handleToggleArchive}
                />
            )}

            {!isSaveHidden && (
                <BaseToolbarItems
                    disabled={disabled}
                    style={{
                        save: {
                            display: 'primary'
                        }
                    }}
                    handleSave={handleSave}
                    handleReset={handleReset}
                    isResetHidden={isResetHidden}
                    handleDelete={handleDelete}
                    isDeleteHidden={isDeleteHidden}
                    loading={loading}
                />
            )}
        </Box>
    );
};
