export const company = {
    'COMPANY.TABLE.TITLE.NAME': 'Entreprise',
    'COMPANY.TABLE.TITLE.ADDRESS': 'Adresse',
    'COMPANY.TABLE.TITLE.ZIPCODE': 'Code postal',
    'COMPANY.TABLE.TITLE.CITY': 'Ville',
    'COMPANY.TABLE.TITLE.COUNTRY': 'Pays',
    'COMPANY.TABLE.TITLE.INTERNAL_ID': 'Code Forvis Mazars (interne)',
    'COMPANY.TABLE.TITLE.COMPANY_ID_NUMBER': 'Code SIREN',
    'COMPANY.TABLE.TITLE.COMPANY_ID_NUMBER2': 'Code SIRET',
    'COMPANY.TABLE.TITLE.VAT_NUMBER': 'Numéro TVA intracommunautaire',
    'COMPANY.TABLE.TITLE.CREATED': 'Créé',
    'COMPANY.TABLE.TITLE.CONTACTS': 'Contacts',
    'COMPANY.TABLE.DELETE.COMPANY': 'Supprimer',
    'COMPANY.TABLE.EDIT.COMPANY': 'Modifier',
    'COMPANY.CREATE.CLIENT.SUCCESS': 'entreprise créée',
    'COMPANY.CREATE.CLIENT.ERROR': 'Échec de la création de l\'entreprise',
    'COMPANY.UPDATE.CLIENT.SUCCESS': 'Entreprise modifiée',
    'COMPANY.UPDATE.CLIENT.ERROR': 'Échec de la modification de l\'entreprise',
    'COMPANY.DELETE.CLIENT.SUCCESS': 'Entreprise supprimée',
    'COMPANY.DELETE.CLIENT.ERROR': 'Échec de la suppression de l\'entreprise',
    'COMPANY.SEARCH.CLIENT.SUCCESS': 'Companies search sucess',
    'COMPANY.SEARCH.CLIENT.ERROR': 'Échec de la recherche de l\'entreprise',
    'COMPANY.FETCH.CLIENT.SUCCESS': 'Company fech success',
    'COMPANY.FETCH.CLIENT.ERROR': 'Échec de l\'accès à l\'entreprise',
    'COMPANY.UPLOAD.CLIENT.SUCCESS': 'Entreprises uploadées',
    'COMPANY.UPLOAD.CLIENT.ERROR': 'Échec de l\'upload des entreprises',
    'COMPANY.DOWNLOAD.CLIENT.ERROR': 'Échec du téléchargement du modèle Excel des entreprises',
    'COMPANY.BUTTON.DOWNLOAD.LABEL': 'Tout télécharger',
    'COMPANY.BUTTON.DOWNLOAD.TOOLTIP': 'Télécharger fichier Excel contenant les entreprises et contacts tiers',
    'COMPANY.DOWNLOAD.THIRDPARTY.FILENAME': '{engagementName}_thirparty_companies_and_contacts.xlsx',
    'COMPANY.DOWNLOAD.THIRDPARTY.ERROR': 'Échec du téléchargement des entreprises et contacts tiers',
    'SEARCH.COMPANY.LABEL.SEARCH.FILTER_1': 'Nom',
    'SEARCH.COMPANY.LABEL.SEARCH.FILTER_2': 'Pays',
    'SEARCH.COMPANY.LABEL.SEARCH.FILTER_3': 'Adresse, Code postal, Ville,...',
    'SEARCH.COMPANY.BUTTON.SEARCH.LABEL': 'Recherche',
    'SEARCH.COMPANY.BUTTON.CLEAR.LABEL': 'Effacer',
    'SEARCH.COMPANY.BUTTON.CLEAR.DESCRIPTION': 'Effacer les filtres',
    'SEARCH.COMPANY.BUTTON.CREATE.COMPANY.LABEL': 'Mon entreprise n\'existe pas',
    'FETCH.COMPANY.CLIENTS.ACCESS.ERROR': 'Échec de l\'accès aux entreprises clientes',
    'FETCH.COMPANY.THIRDPARTIES.ACCESS.ERROR': 'Échec de l\'accès aux entreprises tierces',
};
