export enum E_AUTH_TYPE {
    BASIC_AUTH = 'BasicAuth',
    MODERN_AUTH = 'ModernAuth'
}

export interface MailingConfigurationSpec {
    id: string;
    clusterId: string;
    administrators: string;
    inboxFolder: string;
    sentItemsFolder: string;
    processingInProgressFolder: string;
    processedFolder: string;
    requestsFolder: string;
    issuesFolder: string;
    othersFolder: string;
    authType: E_AUTH_TYPE;
    automaticDeleteEmails: boolean;
    basicAuthConfiguration: BasicAuthConfigurationSpec;
    modernAuthConfiguration: ModernAuthConfigurationSpec;
}

export interface BasicAuthConfigurationSpec {
    id: string;
    providerName: string;
    smtpDisplayName: string;
    smtpUsername: string;
    smtpPassword: string;
    smtpHost: string;
    smtpPort: number;
    imapUsername: string;
    imapPassword: string;
    imapHost: string;
    imapPort: number;
}

export interface ModernAuthConfigurationSpec {
    id: string;
    mailingAuthProviderId: string;
    userName: string;
    userObjectId: string;
}

export const initMailingConfiguration: MailingConfigurationSpec = {
    id: '',
    clusterId: '',
    administrators: '',
    inboxFolder: '',
    sentItemsFolder: '',
    processingInProgressFolder: '',
    processedFolder: '',
    requestsFolder: '',
    issuesFolder: '',
    othersFolder: '',
    authType: E_AUTH_TYPE.BASIC_AUTH,
    automaticDeleteEmails: false,
    basicAuthConfiguration: {} as (BasicAuthConfigurationSpec),
    modernAuthConfiguration: {} as (ModernAuthConfigurationSpec)
};
