import {
    globalClientCompanyRepository,
    serializeCompany,
    engagementClientCompanyRepository,
    serializeEngagementCompany
} from '../kit/repository';
import { callAPI } from './BaseService';

export const createClientCompany = async ({
    engagementId,
    company,
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
}) => {
    const apiMethodCall = () =>
        engagementClientCompanyRepository.create(
            serializeEngagementCompany({ ...company, engagement: engagementId })
        );

    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};

export const updateGlobalClientCompany = async ({
    company,
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
}) => {
    const apiMethodCall = () => globalClientCompanyRepository.update([serializeCompany(company)]);

    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};

export const downloadClientCompanyTemplateFile = async ({
    templateParams,
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
}) => {
    const apiMethodCall = () => globalClientCompanyRepository.fetchExcelTemplate(templateParams);

    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};

export const uploadClientCompanyTemplateFile = async ({
    companiesInfo,
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
}) => {
    const apiMethodCall = () =>
        globalClientCompanyRepository.upload({
            clusterId: companiesInfo?.clusterId,
            file: companiesInfo?.file,
            info: companiesInfo,
            resetDatabase: companiesInfo?.resetDatabase
        });

    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};
