import React from 'react';
import { Topbar } from './Topbar';
import { toAbsoluteUrl } from '../../helpers/AssetsHelpers';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../../navigation/Routes';
import { PROFILE } from '../../types/types';
import * as actions from '../../store/actions';
import { isAuthorisedProfile } from '../auth/UserHelper';

export function HeaderComponent(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.account.user);

    const goHome = () => {
        // TODO: get main profile of the user @seealso Auth.js
        isAuthorisedProfile(PROFILE.AUDITOR, user.profil) && dispatch(actions.setSessionProfile(PROFILE.AUDITOR));
    };

    return (
        <>
            <div className="header header-fixed" id="mzr_header">
                <div className="container d-flex align-items-stretch justify-content-between">
                    <div className="d-flex align-items-stretch mr-3">
                        <div className="header-logo m-3">
                            <Link to={props?.defaultPath ?? ROUTES.AUTH} onClick={goHome}>
                                <>
                                    <img
                                        className="logo-default max-h-80px"
                                        alt="Logo"
                                        src={toAbsoluteUrl('/media/logos/ForvisMazars-Logo-Color-CMYK.png')}
                                    />
                                    <img
                                        className="logo-sticky max-h-80px"
                                        alt="Logo"
                                        src={toAbsoluteUrl('/media/logos/ForvisMazars-Logo-Color-CMYK.png')}
                                    />
                                </>
                            </Link>
                        </div>
                    </div>
                    <Topbar />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        defaultPath: state.app.defaultPath
    };
};

export const Header = compose(connect(mapStateToProps, null))(HeaderComponent);
