import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@mui/icons-material';
import { Palette } from '../../styles/colors';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../i18n/i18Helper';
import { UIActionButton } from './UIActionButton';
import { UIText } from './UIText';

interface UIDialogProps {
    titleId: string | { id: string; values: any };
    actions?: React.ReactNode;
    toolbarActions?: React.ReactNode;
    onClose?: () => void;
    onSave?: (event?: React.MouseEvent) => void;
    saveTitleId?: string;
    loading?: boolean;
    classes?: any;
}

const DefaultDialogActions = ({ handleSave, title, loading }: any) => {
    return (
        <DialogActions>
            {handleSave && (
                <UIActionButton
                    style={{ minWidth: '100px', minHeight: '30px' }}
                    title={title}
                    onClick={handleSave}
                    startIcon={loading && <span className="mr-6 spinner spinner-white" />}></UIActionButton>
            )}
        </DialogActions>
    );
};

export const UIDialog = ({
    titleId,
    actions,
    children,
    open,
    onClose,
    onSave,
    saveTitleId,
    toolbarActions,
    loading,
    classes
}: UIDialogProps & DialogProps) => {
    const innerClasses = useStyles();
    const intl = useIntl();

    const dialogTitle =
        typeof titleId === 'string'
            ? getLocalizedString(intl, titleId)
            : getLocalizedString(intl, titleId.id, titleId.values);

    const Actions = actions ?? (
        <DefaultDialogActions
            handleSave={onSave}
            title={getLocalizedString(intl, saveTitleId ?? 'DIALOG.BUTTON.SAVE')}
        />
    );

    const handleClose = (event: any, reason: string) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            onSave && onSave(event);
        }

        onClose && onClose();
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={classes?.maxWidth ?? 'md'}
            scroll="paper"
            onClose={handleClose}
            classes={{ paper: innerClasses.dialogPaper, ...classes?.root }}
            aria-labelledby="dialog">
            <DialogTitle id="simple-dialog-title" style={sx.dialogTitle}>
                <Box sx={sx.titleContainer}>
                    <UIText style={sx.title} text={dialogTitle} />
                    <Box sx={sx.toolbarActions}>
                        {toolbarActions && <Paper style={sx.toolbarActionsPaper}>{toolbarActions}</Paper>}
                        {onClose && (
                            <IconButton aria-label="close" disabled={loading} onClick={onClose} style={sx.close}>
                                <Close />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent className="mt-10">{children}</DialogContent>

            <DialogActions>{Actions}</DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles({
    dialogPaper: {
        minHeight: '35vh'
    }
});

const sx = {
    title: { flexGrow: 1, fontWeight: '800', fontSize: 18 },
    dialogTitle: { margin: 0, padding: 2 },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginTop: 2,
        marginLeft: 2,
        marginRight: 2
    },
    toolbarActionsPaper: { marginRight: 1, marginLeft: 1 },
    toolbarActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    close: { color: Palette.primaryTextColor }
};
