import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import React from 'react';
import {
    Done,
    FilterList,
    BorderColorTwoTone,
    SettingsTwoTone,
    HighlightOffRounded
} from '@mui/icons-material';
import { getLocalizedString } from '../../i18n/i18Helper';
import { UIActionButton } from '../common/UIActionButton';
import { toolbarStyle } from '../../styles/toolbar/ToolbarStyle';
import { UIBackButton } from '../common/UIBackButton';
import { UIDropDownMenu } from '../common/UIDropDownMenu';
import { UIExpandableIconButton } from '../common/UIExpandableIconButton';

export const EngagementToolbarItems = ({
    handleBack,
    isCreateConfirmationHidden = true,
    handleCreateConfirmation,
    handleSettings,
    handleFilter,
    isFilteringMode,
    moreOptions,
    isSignHidden = true,
    handleSign
}) => {
    const intl = useIntl();

    return (
        <Box sx={toolbarStyle.container}>
            <UIBackButton onClick={handleBack} />

            <UIActionButton
                sx={toolbarStyle.button}
                display="secondary"
                startIcon={isFilteringMode ? <HighlightOffRounded /> : <FilterList />}
                onClick={handleFilter}
                title={getLocalizedString(
                    intl,
                    isFilteringMode ? 'ENGAGEMENT.TABLE.FILTER.CLEAR' : 'ENGAGEMENT.TABLE.FILTER'
                )}
            />

            {!isCreateConfirmationHidden && (
                <UIActionButton
                    sx={toolbarStyle.button}
                    startIcon={<BorderColorTwoTone color="inherit" fontSize="small" />}
                    title={getLocalizedString(intl, 'ENGAGEMENT.BUTTON.NEW.LABEL')}
                    onClick={handleCreateConfirmation}
                />
            )}

            {!isSignHidden && (
                <UIActionButton
                    title={getLocalizedString(intl, 'ENGAGEMENT.BUTTON.SIGN.LABEL')}
                    startIcon={<Done color="inherit" fontSize="small" />}
                    onClick={handleSign}
                />
            )}

            <UIActionButton
                sx={toolbarStyle.button}
                startIcon={<SettingsTwoTone color="inherit" fontSize="small" />}
                title={getLocalizedString(intl, 'ENGAGEMENT.BUTTON.SETTINGS.LABEL')}
                onClick={handleSettings}
            />

            {moreOptions && (
                <UIDropDownMenu
                    entries={moreOptions}
                    titleComponent={() => <UIExpandableIconButton aria-label="settings" />}
                />
            )}
        </Box>
    );
};
