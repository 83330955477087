// leave only one action for one position
export const getMainWorkflowActions = allAction => {
    return allAction
        ? Object.values(allAction)
              .slice()
              .reverse()
              .filter((value, index, array) => array.findIndex(action => action.position === value.position) === index)
              .reverse()
        : [];
};

export const sortByStepsByName = steps => {
    const pattern = (a, b) => (a.name < b.name ? -1 : 1);
    return steps.sort((a, b) => (a.name !== b.name ? pattern(a, b) : 0));
};

export const getGroupedStackableActions = steps => {
    const groupedSteps = steps?.reduce((acc, step) => {
        if (!acc[step.position]) acc[step.position] = [];
        acc[step.position].push(step);
        return acc;
    }, {});

    const stackableSteps = groupedSteps
        ? Object.keys(groupedSteps)
              .filter(position => groupedSteps[position].length > 1)
              ?.reduce((result, key) => {
                  result[key] = sortByStepsByName(groupedSteps[key]);
                  return result;
              }, {})
        : {};

    return {
        isStackable: Object.keys(stackableSteps).length > 0,
        steps: stackableSteps
    };
};

export const expectedOptionAnswersNumber = workflowActions => {
    // Get number of stackable actions per position
    let groupedStackableActions = workflowActions.reduce((acc, action) => {
        if (action?.isStackable) {
            acc = { ...acc, [action.position]: acc[action.position] ? acc[action.position] + 1 : 1 };
        }

        return acc;
    }, {});

    // get the number of stackable actions to provide an answer for
    return Object.values(groupedStackableActions).filter(v => v > 1).length;
};
