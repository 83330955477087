import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseShort } from '../parsers';
import { parseWorkflowAction, parseWorkflowActions } from './workflowParsers';
import { Repository } from '../Repository';

class WorkflowActionRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`workflowaction`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseWorkflowActions);
        return result;
    };

    create = async (action) => {
        let result = await this.network.post(`workflowaction`, action, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    fetch = async (actionId) => {
        let result = await this.network.get(`workflowaction/${actionId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseWorkflowAction);
        return result;
    };

    update = async (actionId, action) => {
        let result = await this.network.put(`workflowaction/${actionId}`, action, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    delete = async (actionId) => {
        let result = await this.network.delete(`workflowaction/${actionId}`, null, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };
}

export const workflowActionRepository = new WorkflowActionRepository();
