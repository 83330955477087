import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import { HighlightedProcessStepIcon, ProcessConnector } from '../../../helpers/StepperHelper';
import { UITooltip } from '../../common/UITooltip';

import { FormattedMessage } from 'react-intl';
import { StackableSteps } from '../workflow/StackableSteps';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useProcessStepIconStyles = makeStyles(theme => ({
    root: {
        width: '80px', //TODO find better way to do it
        marginTop: theme.spacing(2)
    }
}));

export const CreateConfirmationStepper = ({ workflowActions, mainWorkflowActions, isEditable, titleId, ...props }) => {
    const classes = useProcessStepIconStyles();

    const renderWorkflowAction = action => {
        const stackedStepsAtPosition = workflowActions
            ?.filter(step => step.isStackable && step.position === action.position)
            .sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0));
        const isStackableAction = action.isStackable && stackedStepsAtPosition.length > 1;

        return (
            <Step completed={!isStackableAction}>
                <StepLabel StepIconComponent={HighlightedProcessStepIcon}>
                    {!isStackableAction ? (
                        <Typography variant="subtitle2">{action.name?.toUpperCase()}</Typography>
                    ) : (
                        ''
                    )}
                </StepLabel>
                {isStackableAction ? (
                    <div className={clsx(classes.root)}>
                        <StackableSteps stackableSteps={stackedStepsAtPosition} isEditable={isEditable} {...props} />
                    </div>
                ) : null}
            </Step>
        );
    };

    return (
        <div className="stepper">
            {titleId && (
                <label>
                    <FormattedMessage id={titleId} />
                </label>
            )}

            <Stepper activeStep={mainWorkflowActions?.length - 1} alternativeLabel connector={<ProcessConnector />}>
                {mainWorkflowActions.map(action => (
                    <UITooltip key={action.id} title={action?.description} children={renderWorkflowAction(action)} />
                ))}
            </Stepper>
        </div>
    );
};
