import React from 'react';
import { TOAST, Toast } from './Toast';
import { connect } from 'react-redux';

export const ToastWrapperComponent = ({ children, ...props }) => (
    <div>
        <Toast
            toastList={props.toastMessageList}
            autoDelete={TOAST.SHOULD_AUTO_DELETE}
            dismissTime={TOAST.DISMISS_TIME}
            position={'top-right'}
        />
        {children}
    </div>
);

const mapStateToProps = state => {
    return {
        toastMessageList: state.app.toastMessageList
    };
};

export const ToastWrapper = connect(mapStateToProps, null)(ToastWrapperComponent);
