import { performAPIAction } from './BaseService';
import { IntlShape } from 'react-intl';
import { ServiceResult } from '../kit/network/ServiceResult';
import { letterTemplateRepository, letterTemplateTagRepository } from '../kit/repository';

interface ServiceProps {
    filters: Record<string, any>;
    intl: IntlShape;
    setLoading: (loading: boolean) => void;
    checkSession: (result: ServiceResult) => void;
    addToastMessage: (toast: any) => void;
    callback: null | ((isSuccess: boolean, data: ServiceResult) => void);
}

export const LetterTemplateService = (props: ServiceProps) => {
    return {
        fetchAll: (filters: Record<string, any>) =>
            performAPIAction({
                ...props,
                apiCall: letterTemplateRepository.fetchAll(filters),
                errorMessageId: 'LETTER.TEMPLATE.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        fetch: (templateId: string) =>
            performAPIAction({
                ...props,
                apiCall: letterTemplateRepository.fetch(templateId),
                errorMessageId: 'LETTER.TEMPLATE.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        fetchTags: () =>
            performAPIAction({
                ...props,
                apiCall: letterTemplateTagRepository.fetchAll(),
                errorMessageId: 'LETTER.TEMPLATE.TAG.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        download: async (templateId, filters) =>
            performAPIAction({
                ...props,
                apiCall: letterTemplateRepository.download(templateId, filters),
                errorMessageId: 'LETTER.TEMPLATE.FILE.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        create: async template =>
            performAPIAction({
                ...props,
                apiCall: letterTemplateRepository.create(template),
                successMessageId: 'LETTER.TEMPLATE.CREATE.SUCCESS',
                errorMessageId: 'LETTER.TEMPLATE.CREATE.ERROR'
            }),

        update: (template, displaySuccessMessage = true) =>
            performAPIAction({
                ...props,
                apiCall: letterTemplateRepository.update(template.id, template),
                successMessageId: displaySuccessMessage ? 'LETTER.TEMPLATE.UPDATE.SUCCESS' : undefined,
                errorMessageId: 'LETTER.TEMPLATE.UPDATE.ERROR'
            }),

        delete: async templateId =>
            performAPIAction({
                ...props,
                apiCall: letterTemplateRepository.delete(templateId),
                successMessageId: 'LETTER.TEMPLATE.DELETE.SUCCESS',
                errorMessageId: 'LETTER.TEMPLATE.DELETE.ERROR'
            })
    };
};
