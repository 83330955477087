import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { initStorageSharePointOnline } from './InitStorageSharePointOnline';
import { getFieldElement } from '../../../../helpers/FormHelper';
import { COMPONENT_NAME } from '../../constants';

export function StorageSharePointOnlineComponent(props) {
    const { storageConfiguration, hasValidConfig } = props;

    const [storageSharePointOnline, setStorage] = useState(initStorageSharePointOnline);
    const [hasBeenTrigger, setHasBeenTrigger] = useState(false);

    const buttonFormRef = useRef(null);
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    props.sbmtConfRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    useEffect(() => {
        storageConfiguration && setStorage(storageConfiguration);
    }, [storageConfiguration]);

    // Validation schema
    const StorageSchema = Yup.object().shape({
        isActive: Yup.boolean().required(
            <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TARGET_PATH" />
        ),
        clusterId: Yup.object().nullable(),
        priorityLevel: Yup.number(),
        targetPath: Yup.string(),
        // .required(
        //     <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TARGET_PATH" />
        // ),
        tenantName: Yup.string().required(
            <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TENANT_NAME" />
        ),
        tenantId: Yup.string().required(
            <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.TENANT_ID" />
        ),
        siteName: Yup.string().required(
            <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.SITE_NAME" />
        ),
        siteUrl: Yup.string().required(
            <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.SITE_URL" />
        ),
        documentLibrary: Yup.string().required(
            <FormattedMessage id="STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.VALIDATION.REQUIRED_FIELD.DOCUMENT_LIBRARY" />
        )
    });

    const STORAGE_SHAREPOINTONLINE_FIELDS_1 = [
        {
            labelItem: { id: 'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TENANT_NAME.LABEL' },
            TextFieldItem: { id: 'tenantName' }
        },
        {
            labelItem: { id: 'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TENANT_ID.LABEL' },
            TextFieldItem: { id: 'tenantId' }
        },
        {
            labelItem: { id: 'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.TARGET_PATH.LABEL' },
            TextFieldItem: { id: 'targetPath' }
        }
    ];

    const STORAGE_SHAREPOINTONLINE_FIELDS_2 = [
        {
            labelItem: { id: 'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.SITE_NAME.LABEL' },
            TextFieldItem: { id: 'siteName' }
        },
        {
            labelItem: { id: 'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.SITE_URL.LABEL' },
            TextFieldItem: { id: 'siteUrl' }
        },
        {
            labelItem: { id: 'STORAGE.SHAREPOINTONLINE.CONFIG.FIELD.DOCUMENT_LIBRARY.LABEL' },
            TextFieldItem: { id: 'documentLibrary' }
        }
    ];

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Formik
                    enableReinitialize
                    validationSchema={StorageSchema}
                    initialValues={storageSharePointOnline}
                    onSubmit={async values => {
                        setHasBeenTrigger(false);
                        return props.handleSubmit(values);
                    }}>
                    {formikProps => {
                        if (Object.keys(formikProps?.touched).length > 0) {
                            !hasBeenTrigger && hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, false);
                            setHasBeenTrigger(true);
                        }
                        return (
                            <Form className="form">
                                <Grid container>
                                    <Grid style={{ display: 'flex' }}>
                                        <Grid container spacing={2} style={{ padding: '0 1.2em' }}>
                                            {STORAGE_SHAREPOINTONLINE_FIELDS_1?.map(field => (
                                                <Grid
                                                    container
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        padding: '0 20%'
                                                    }}>
                                                    <Grid item>
                                                        <label>
                                                            <FormattedMessage id={field?.labelItem?.id} />
                                                        </label>
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '22em'
                                                        }}
                                                        item>
                                                        {getFieldElement(field)}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid container spacing={2} style={{ padding: '0 1.2em' }}>
                                            {STORAGE_SHAREPOINTONLINE_FIELDS_2?.map(field => (
                                                <Grid
                                                    container
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        padding: '0 20%'
                                                    }}>
                                                    <Grid item>
                                                        <label>
                                                            <FormattedMessage id={field?.labelItem?.id} />
                                                        </label>
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '22em'
                                                        }}
                                                        item>
                                                        {getFieldElement(field)}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <button
                                        type="submit"
                                        style={{ display: 'none' }}
                                        onClick={triggerButtonClick}
                                        ref={buttonFormRef}
                                    />
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </>
    );
}

injectIntl(StorageSharePointOnlineComponent);
