import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import { UITooltip } from '../../common/UITooltip';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { MoreHorizTwoTone as MoreHorizTwoToneIcon } from '@mui/icons-material';
import { MZRChip } from '../../common/MZRChip';

/**
 * Tags Selector component used with Formik.
 */
export function LetterTemplateTagsSelector(props) {
    const { options, disabled } = props;
    const [tagSelected, setTagsSelected] = useState([]);

    useEffect(() => {
        setTagsSelected(props?.field?.value);
    }, [props?.field?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = values => {
        if (!disabled) {
            const valueUpdated = [];
            if (props.field.value?.some(x => x?.id === values?.id)) {
                props.field.value.map(x => {
                    if (x?.id !== values?.id) valueUpdated.push(x);
                    return null;
                });
            } else {
                props.field.value.map(x => valueUpdated.push(x));
                valueUpdated.push(values);
            }

            props.form.setFieldValue(props.field.name, valueUpdated);
        }
    };

    return (
        <React.Fragment key={`letter_template-tags_${props?.field?.id}`}>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={4}>
                <Grid item>
                    <label>
                        <strong>
                            {disabled ? (
                                <FormattedMessage id={'LETTER_TEMPLATE.TAG.SELECTOR.READ.LABEL'} />
                            ) : (
                                <FormattedMessage id={'LETTER_TEMPLATE.TAG.SELECTOR.EDIT.LABEL'} />
                            )}
                        </strong>
                    </label>
                </Grid>
                <Grid item container spacing={2}>
                    <Box key="mandatory_box_key" width={1}>
                        <Grid container item justifyContent="space-around" alignItems="center" spacing={1}>
                            {options && tagSelected ? (
                                Object.values(options)?.map(tag => {
                                    const tagVariant = tagSelected?.some(x => x?.id === tag?.tag?.id)
                                        ? 'default'
                                        : 'outlined';
                                    return (
                                        <Grid item key={`grid_mandatory_tag_${tag?.id}`}>
                                            <UITooltip
                                                title={`${tag?.isMandatory ? 'MANDATORY - ' : ''}${
                                                    tag?.description ?? tag?.tag?.description
                                                }`}
                                                key={`styled-tooltip-tag-mandatory-${tag?.id}`}
                                                children={
                                                    <MZRChip
                                                        id={`mandatory_${tag?.id}`}
                                                        key={`mandatory_${tag?.id}`}
                                                        variant={tagVariant}
                                                        size="small"
                                                        color={tag?.isMandatory ? 'secondary' : 'primary'}
                                                        label={tag?.text ?? tag?.tag?.text}
                                                        onClick={() => handleClick(tag?.tag)}
                                                        avatar={
                                                            <Avatar>
                                                                {String(tag?.text ?? tag?.tag?.text)
                                                                    .split(' ')
                                                                    .map(x => x.substring(0, 1))}
                                                            </Avatar>
                                                        }
                                                    />
                                                }
                                            />
                                        </Grid>
                                    );
                                })
                            ) : (
                                <div className="text-center">
                                    <MoreHorizTwoToneIcon />
                                </div>
                            )}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

LetterTemplateTagsSelector.propTypes = {
    // /**
    //  * Options mandatory and optional tags available.
    //  */
    options: PropTypes.object,
    // /**
    //  * Disabled the modifications.
    //  */
    disabled: PropTypes.bool.isRequired
};

export default injectIntl(LetterTemplateTagsSelector);
