import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { MZRPaginatedTable } from '../../common/MZRPaginatedTable';
import { HOME_COLUMNS_FILTERS } from '../../home/Constants';
import { UserAvatarComponent } from '../../user/UserAvatar';
import { FilterRowContainer } from '../../common/filtering/FilterRowContainer';
import React from 'react';
import { Typography } from '@mui/material';
import { ROUTES } from '../../../navigation/Routes';
import { useHistory } from 'react-router-dom';
import { FILTER_COMPONENT } from '../../../types/types';

interface ClientEngagementTableProps {
    paginatedEngagements: Record<string, any>;
    handleFilterChange: (filters: Record<string, any>) => void;
    isFilteringMode: boolean;
    columnsFiltersValues: any;
    handleFilterEngagementsChange: (filterName: string, value: string) => void;
}

export const ClientEngagementTable = ({
    paginatedEngagements,
    handleFilterChange,
    isFilteringMode,
    columnsFiltersValues,
    handleFilterEngagementsChange
}: ClientEngagementTableProps) => {
    const intl = useIntl();
    const history = useHistory();

    const handleRowClick = (_: any, rowData: any) => {
        if (!rowData.archived) {
            history.push(ROUTES.ENGAGEMENT.replace(':engagementId', rowData?.id));
        }
    };

    return (
        <div className="engagement-table">
            <MZRPaginatedTable
                loading={false}
                isFiltering={isFilteringMode}
                handleFilterChange={handleFilterChange}
                data={paginatedEngagements}
                onRowClick={handleRowClick}
                columns={[
                    {
                        filtering: isFilteringMode,
                        filteringConfig: [
                            {
                                component: FILTER_COMPONENT.INPUT,
                                placeholder: intl.formatMessage({
                                    id: 'HOME.TABLE.FILTER.ENGAGEMENT.NAME.CODE.PLACEHOLDER'
                                }),
                                filterName: HOME_COLUMNS_FILTERS.ENGAGEMENT_NAME_OR_CODE
                            }
                        ],
                        title: intl.formatMessage({ id: 'CLIENT.HOME.TABLE.TITLE.ENGAGEMENT' }),
                        field: 'name',
                        cellStyle: { textAlign: 'center' }
                    },
                    {
                        title: intl.formatMessage({ id: 'CLIENT.HOME.TABLE.TITLE.CLOSING_DATE' }),
                        field: 'closingDate',
                        type: 'date',
                        cellStyle: { textAlign: 'center' },
                        render: (rowData: any) => (
                            <FormattedDate value={rowData.closingDate} year="numeric" month="short" day="numeric" />
                        )
                    },
                    {
                        title: <FormattedMessage id="CLIENT.HOME.TABLE.TITLE.AUDITORS" />,
                        field: 'appUserEngagements',
                        render: (rowData: any) => <UserAvatarComponent data={rowData.appUserEngagements} max={4} />
                    },
                    {
                        title: <FormattedMessage id="CLIENT.HOME.TABLE.TITLE.CONTACTS" />,
                        field: 'clientContacts',
                        render: (rowData: any) => <UserAvatarComponent data={rowData.clientContacts} max={4} />
                    },

                    {
                        title: <FormattedMessage id="CLIENT.HOME.TABLE.TITLE.NB_COMPLETED_REQUESTS" />,
                        field: 'numberOfPendingRequests',
                        cellStyle: { textAlign: 'center' },
                        render: (rowData: any) => (
                            <Typography>
                                {`${rowData.numberOfPendingRequests} /
                            ${rowData.numberOfRequests}`}{' '}
                            </Typography>
                        )
                    }
                ]}
                components={{
                    FilterRow: (props: any) => (
                        <FilterRowContainer
                            rowProps={props}
                            handleFilterChange={handleFilterEngagementsChange}
                            selectedFilters={columnsFiltersValues}
                        />
                    )
                }}
            />
        </div>
    );
};
