import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from '../../../partials/controls/Card';
import { FormattedMessage } from 'react-intl';
import { FormLabel, getFieldElement } from '../../../helpers/FormHelper';
import './confirmationInfo.css';

export const ConfirmationSection = ({ title, fields }) => {
    return (
        <Card>
            {title && <CardHeader title={<FormattedMessage id={title} />} />}
            <CardBody>
                {React.Children.toArray(
                    fields?.map(field => (
                        <>
                            <label>
                                <FormLabel
                                    id={field?.labelItem?.id}
                                    isRequired={field?.labelItem?.isRequired}
                                />
                            </label>

                            {getFieldElement(field)}
                        </>
                    ))
                )}
            </CardBody>
        </Card>
    );
};

ConfirmationSection.propTypes = {
    title: PropTypes.string,
    fields: PropTypes.array.isRequired,
};
