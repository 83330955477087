export const COMPONENT_NAME = {
    STORAGE_SETUP: 'STORAGE_SETUP',
    MAILING_SETUP: 'MAILING_SETUP',
    ROBOT_SETUP: 'ROBOT_SETUP',
    LOST_ITEM_SETUP: 'LOST_ITEM_SETUP',
    USER_RESOURCE_SETUP: 'USER_RESOURCE_SETUP'
};

Object.freeze(COMPONENT_NAME);

export const COMPONENT_MSG = {
    STORAGE: 'TECHNICAL.CONFIGURATION.ALERT.ERROR.STORAGE.MESSAGE',
    MAILING: 'TECHNICAL.CONFIGURATION.ALERT.ERROR.MAILING.MESSAGE',
    ROBOT: 'TECHNICAL.CONFIGURATION.ALERT.ERROR.ROBOT.MESSAGE'
};

export const SETUP_SECTIONS = {
    TECHNICAL_CONFIGURATION: 0,
    GENERAL_CONFIGURATION: 1,
    WORKFLOW: 2,
    LETTER_TEMPLATE: 3,
    EMAIL_TEMPLATE: 4,
    CLIENT: 5
};
