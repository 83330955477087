import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { UIActionButton } from '../../../common/UIActionButton';
import { getLocalizedString } from '../../../../i18n/i18Helper';
import { toolbarStyle } from '../../../../styles/toolbar/ToolbarStyle';

const optionLabelDisplay = contact =>
    contact.firstName || contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.email;

export const ConfirmationContactToolbar = ({
    allContacts,
    selectedContacts,
    handleContactsUpdate,
    disabled,
    renderContactsSelect,
    isCreateDisabled,
    handleContactClickCreate,
    strings
}) => {
    const intl = useIntl();

    return (
        <Box component="span" sx={toolbarStyle.container}>
            <Box sx={{ minWidth: '200px' }}>
                {renderContactsSelect({
                    optionLabelDisplay: optionLabelDisplay,
                    disabled: disabled,
                    allContacts: allContacts,
                    selectedContacts: selectedContacts,
                    handleContactsUpdate: handleContactsUpdate
                })}
            </Box>

            <UIActionButton
                sx={toolbarStyle.button}
                title={getLocalizedString(intl, strings.createButton)}
                disabled={disabled || isCreateDisabled}
                onClick={handleContactClickCreate}></UIActionButton>
        </Box>
    );
};
