import { buildFilters } from '../../network/mzrRequest';
import { parseResponse, parsePaginationResponse } from '../parsers';
import { parseAppUsers } from '../user/userParsers';
import { parseEngagement, parseEngagements, parseEngagementsMenu } from './engagementParsers';
import { parseConfirmations } from '../confirmation/confirmationParsers';
import { parseItems } from '../item/itemParsers';
import { parseCompanies } from '../company/companyParsers';
import { parseContacts } from '../contact/contactParsers';
import { Repository } from '../Repository';
import { appApiUrl } from '../../config/appConfiguration';

class EngagementRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async (filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`engagement${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseEngagements));
        return result;
    };

    clientFetchAll = async (filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`engagement/client${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseEngagements));
        return result;
    };

    fetch = async (engagementId) => {
        let result = await this.network.get(`engagement/${engagementId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseEngagement);
        return result;
    };

    create = async (engagement) => {
        let result = await this.network.post(`engagement`, engagement, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    fetchMenu = async (filters) => {
        const _filters = buildFilters(filters);
        const result = await this.network.get(`engagement/menu${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseEngagementsMenu);
        return result;
    };

    update = async (engagementId, engagement) => {
        return await this.network.put(`engagement/${engagementId}`, engagement, {
            headers: this.buildHeaders()
        });
    };

    delete = async (engagementId, comment) => {
        return await this.network.delete(
            `engagement/${engagementId}`,
            { comment: comment },
            {
                headers: this.buildHeaders()
            }
        );
    };

    fetchClientsCompanies = async (engagementId) => {
        let result = await this.network.get(`engagement/${engagementId}/clientcompanies`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseCompanies);
        return result;
    };

    fetchClientContacts = async (engagementId) => {
        let result = await this.network.get(`engagement/${engagementId}/clientcontacts`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseContacts);
        return result;
    };

    fetchThirdPartyCompanies = async (engagementId) => {
        let result = await this.network.get(`engagement/${engagementId}/thirdpartycompanies`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseCompanies));
        return result;
    };

    fetchConfirmations = async (engagementId, filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`engagement/${engagementId}/confirmations${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseConfirmations));
        return result;
    };

    fetchMembers = async (engagementId) => {
        let result = await this.network.get(`${'engagement'}/${engagementId}/members`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseAppUsers);
        return result;
    };

    fetchItems = async (engagementId, filters) => {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`engagement/${engagementId}/items${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseItems));
        return result;
    };

    /***
     * Download all then engagement's files
     * @param engagementId: the id of the engagement
     */
    downloadFiles = async (engagementId, downloadOptions) => {
        const options = buildFilters(downloadOptions);
        return await this.network.get(`engagement/${engagementId}/reporting${options}`, {
            headers: {
                ...this.buildHeaders(true),
                mode: 'no-cors',
                'Access-Control-Allow-Origin': '*'
            }
        });
    };

    async fetchConfirmationRequests(engagementId, filters) {
        const _filters = buildFilters(filters);
        let result = await this.network.get(`engagement/${engagementId}/confirmationRequests${_filters}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parsePaginationResponse.bind(this, parseConfirmations));
        return result;
    }

    toggleArchiveStatus = async (engagementId) => {
        return await this.network.patch(`engagement/${engagementId}/archive`, undefined, {
            headers: { ...this.buildHeaders() }
        });
    };
}

export const engagementRepository = new EngagementRepository();
