export enum SESSION_TYPE {
    MAZARS = 'MAZARS',
    EXTERNAL_CLIENT = 'EXTERNAL_CLIENT'
}

export enum PROFILE {
    AUDITOR = 1, // country auditor
    ADMIN = 2, // country admin (cluster)
    POSTIE = 4, // country mail team, ex-Support
    CLIENT = 8, // external client
    ROBOT = 16, // robot (item classification-algorithm)
    SUPPORT = 32, // country helpDesk
    COCAC = 64, // co-cac profile (future role),
    SUPER_ADMIN = 127 // Super Admin
}

export enum CLIENT_ENGAGEMENT_TABS {
    REQUESTS,
    ALL
}

export enum FILTER_COMPONENT {
    SELECT,
    INPUT
}

export enum CLIENT_REFUSE_REQUEST_REASON {
    DUPLICATE = 0,
    INACCURATE = 1,
    REFUSE = 2
}

export interface RefuseConfirmationRequestSpec {
    id: string;
    reason?: CLIENT_REFUSE_REQUEST_REASON;
    comment?: string;
}

export enum CLIENT_CONFIRMATION_STEP_TYPE {
    THIRD_PARTY,
    LETTERHEAD,
    COMPLEMENTARY_INFORMATION,
    CONFIRM
}

export enum CONTACT_TYPE {
    CLIENT = 1,
    THIRD_PARTY = 2
}

export interface UploadDocumentSpec {
    id?: string;
    files: Blob[];
    comment?: string;
    isGrouped?: boolean;
}

export interface LetterHeadSpec {
    id?: string;
    engagementClientCompanyId?: string;
    file?: Blob;
    isLetterHeadActivated: boolean;
}

export interface ClientRequestStep {
    title: string;
    isActive: boolean;
    isCompleted: boolean;
    type: CLIENT_CONFIRMATION_STEP_TYPE;
    subSteps?: ClientRequestStep[];
}

export interface ConfirmationRequestSpec {
    id: string;
    engagementId: string;
    confirmationId: string;
    status: string;
    refusalReason: string;
    comments: CommentSpec[];
}

export interface CommentSpec {
    id: string;
    confirmationId: string;
    parentCommentId: string;
    text: string;
    isPrivate: boolean;
}

export interface ClientRequest {
    id: string;
    confirmationCode: string;
    engagementId: string;
    name: string;
    description: string;
    clientSendDate: string;
    clientDueDate: string;
    thirdPartySendDate: string;
    thirdPartyDueDate: string;
    confirmationCategoryId: string;
    confirmationCategory: any;
    engagementThirdPartyCompanyId: string;
    thirdPartyCompany: any;
    thirdPartyContacts: any[];
    engagementClientCompanyId: string;
    clientCompany: any;
    clientContacts: any[];
    isDeletable: boolean;
    isEditable: boolean;
    clientRequestStatus: CLIENT_REQUEST_STATUS;
    auditorRefuseMessage: string;
    comment: string;
    isLetterGenerated: boolean;
    letterItemId?: string;
}

export enum CLIENT_REQUEST_STATUS {
    NONE = 0,
    NOT_STARTED = 1,
    REFUSED = 2,
    WAITING_AUDITOR_VALIDATION = 3,
    WAITING_SIGNATURE = 4
}

export enum CLIENT_CONTACT_INVITATION_STATUS {
    NOT_INVITED = 'NotInvited',
    INVITED_NOT_CONNECTED = 'NotConnected',
    INVITED_CONNECTED = 'Connected',
    REVOKED = 'Revoked'
}

export interface ContactAccess {
    company: any;
    rights: ContactRight[];
}

export interface ContactRight {
    type: string; //TODO enum
    name: string;
    active: boolean;
}

export enum CLIENT_CONTACT_RIGHT_TYPE {
    ALL = 'All',
    DOCUMENT_UPLOAD = 'DocumentUpload',
    COMMENT = 'Comment'
}

export enum AUTH_PROVIDER {
    THE_GATE = 0,
    MAZARS_EXTERNAL_AUTH = 1
}

export enum DOCUMENT_ITEM_TYPE {
    FILE = 0,
    FOLDER = 1
}

export interface ItemNode {
    id: string;
    name: string;
    description: string;
    comment: string;
    type: DOCUMENT_ITEM_TYPE;
    errorType: any;
    documentType: number;
    fileExtension: string;
    childItems?: any[];
    created: string;
    updated: string;
    creator: any;
    updator: any;
    isTrashed: boolean;
    isDeletable: boolean;
    languageId: string;
    paperSendingStatus: any;
    engagementId: string | null;
    confirmationId: string | null;
    parentItemId: string | null;
    isLost: boolean;
    isInvalidAddress: boolean;
    isInvalidAddressDate: Date;
}

export enum STEP_ACTION_TYPE {
    Next = 'Next',
    Validate = 'Validate',
    Refuse = 'Refuse',
    Reinitiate = 'Reinitiate',
    Reset = 'Reset'
}

export enum SECURE_SOCKET_OPTIONS {
    NONE = 0,
    SSL_ON_CONNECT = 1,
    START_TLS = 2,
    START_TLS_WHEN_AVAILABLE = 3,
    AUTO = 4
}
