import { Component } from 'react';
import { connect } from 'react-redux';
import { CreateMethodComponent } from '../../components/confirmation';
import { Loader } from '../../components/loader/Loader';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PROFILE } from '../../types/types';
import { PageNotFound } from '../pageNotFound/PageNotFound';

/**
 * Create confirmation method selection component.
 * Select creation "Manual" or "Import"
 */
class CreateMethod extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, automation: false };
    }

    render() {
        const { user } = this.props;

        if (!user.profil) {
            return <Loader />;
        } else {
            if (isAuthorisedProfile(PROFILE.AUDITOR, user.profil)) {
                return (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <CreateMethodComponent {...this.props} />
                            </div>
                        </div>
                    </>
                );
            } else {
                return <PageNotFound {...this.props} />;
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.account.user
    };
};
export const createMethod = connect(mapStateToProps)(CreateMethod);
