import { lostItemConfigurationRepository } from '../kit/repository';
import { performAPIAction } from './BaseService';

const LostItemConfigMethods = ({ user, setErrorMessage, ...rest }) => {
    return {
        fetch: async () =>
            performAPIAction({
                ...rest,
                apiCall: lostItemConfigurationRepository.fetch(),
                errorMessageId: 'LOST_ITEM_CONFIG.INFO.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        create: configuration =>
            performAPIAction({
                ...rest,
                apiCall: lostItemConfigurationRepository.create(configuration),
                successMessageId: 'LOST_ITEM_CONFIG.CREATE.SUCCESS',
                errorMessageId: 'LOST_ITEM_CONFIG.CREATE.ERROR'
            }),

        update: configuration =>
            performAPIAction({
                ...rest,
                apiCall: lostItemConfigurationRepository.update(configuration.id, configuration),
                successMessageId: 'LOST_ITEM_CONFIG.UPDATE.SUCCESS',
                errorMessageId: 'LOST_ITEM_CONFIG.UPDATE.ERROR'
            }),

        delete: configurationId =>
            performAPIAction({
                ...rest,
                apiCall: lostItemConfigurationRepository.delete(configurationId),
                successMessageId: 'LOST_ITEM_CONFIG.DELETE.SUCCESS',
                errorMessageId: 'LOST_ITEM_CONFIG.DELETE.ERROR'
            })
    };
};

export const LostItemConfigService = ({
    intl,
    user,
    checkSession,
    addToastMessage,
    callback,
    setLoading = () => {},
    setErrorMessage = () => {}
}) => {
    const lostItemConfigMethods = LostItemConfigMethods({
        intl,
        user,
        checkSession,
        addToastMessage,
        callback,
        setLoading,
        setErrorMessage
    });
    return { ...lostItemConfigMethods };
};
