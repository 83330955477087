export const auth = {
    'AUTH.WELCOME.LEGAL': '2024 Forvis Mazars',
    'AUTH.WELCOME.MESSAGE': 'Welcome to the Forvis Mazars audit confirmation platform',
    'AUTH.WELCOME.MESSAGE.TEXT':
        'Present in over 100 countries and territories, we work together as one team across borders and functions to deliver exceptional and tailored services.\n\nWe combine our skills and expertise with both a global perspective and local knowledge to provide clients with a broad array of professional services in audit and accounting, as well as tax, financial advisory, consulting and legal services*\n\n\n\n*where permitted under applicable country laws.',

    'AUTH.LOGIN.TITLE': 'Forvis Mazars Confirmations login',
    'AUTH.LOGIN.MESSAGE': 'Enter your username and password',
    'AUTH.LOGIN.WARNING.MESSAGE': 'Use your Forvis Mazars account and password to continue.',
    'AUTH.LOGIN.BUTTON': 'Login',
    'AUTH.INPUT.EMAIL': 'Email',

    'AUTH.INPUT.PASSWORD': 'Password',
    'AUTH.INVALID.FIELD.COUNTRY': 'Invalid country',
    'AUTH.CREDENTIALS.ERROR': 'Invalid credentials',
    'AUTH.VALIDATION.INVALID_LOGIN': 'Invalid credentials',
    'AUTH.VALIDATION.REQUIRED_FIELD': 'Missing information',
    'AUTH.OPTIONS.CONTINUE.AS.MAZARS.BUTTON': 'Continue as Forvis Mazars employee',
    'AUTH.OPTIONS.CONTINUE.AS.EXTERNAL.BUTTON': 'Continue as external user',
    'AUTH.LOGIN.AS.MAZARS.TITLE': 'Login as a <b>Forvis Mazars employee </b>',
    'AUTH.LOGIN.AS.EXTERNAL.TITLE': 'Login as an <b>external user </b>',
    'AUTH.CHANGE.LOGIN.METHOD': '- Change login method -'
};
