import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext, Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedErrorMessage } from '../components/errorMessage/ErrorMessage';
import { FormLabel, makeSelectOptions, makeSelectObjectOptions, FORM_FIELD } from '../helpers/FormHelper';
import { UITooltip } from '../components/common/UITooltip';
import { UIDatePickerField } from '../components/common/UIDatePickerField';
import { OutlinedInput, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { UPLOAD_MAX_FILE_SIZE } from '../helpers';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../i18n/i18Helper';

const useStyles = makeStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 350
    },
    dropzone: {
        width: 'inherit',
        minHeight: '150px'
    }
});

const RenderTextFieldItem = ({ id, component, rows, placeholder, variant, disabled, type, className }) => {
    return (
        <>
            <Field
                as={OutlinedInput}
                key={id}
                id={id}
                name={id}
                component={component}
                rows={rows}
                placeholder={placeholder}
                variant={variant ?? 'outlined'}
                disabled={disabled}
                type={type ?? ''}
                autoComplete={type ? 'current-password' : ''}
                style={{ padding: 0, width: '100%' }}
                className={className}
            />
            <ErrorMessage name={id}>
                {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
            </ErrorMessage>
        </>
    );
};

const RenderSelectFieldItem = ({
    id,
    multiple,
    placeholder,
    variant,
    disabled,
    className,
    optionsObjectChoices,
    noValidation,
    options,
    disableUnderline = false,
    callback
}) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = event => {
        setFieldValue(event.target.name, event.target.value);
        callback && callback(event, setFieldValue);
    };

    return (
        <>
            <Field
                as={Select}
                onChange={handleChange.bind(this)}
                disableUnderline={disableUnderline}
                key={id}
                labelId={id}
                label={id}
                id={id}
                name={id}
                style={{ height: '100% !important' }}
                placeholder={placeholder}
                multiple={multiple}
                variant={variant ?? 'outlined'}
                disabled={disabled}
                className={className}
                fullWidth>
                {optionsObjectChoices ? makeSelectObjectOptions(options) : makeSelectOptions(options)}
            </Field>
            {!noValidation ? (
                <ErrorMessage name={id}>
                    {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
                </ErrorMessage>
            ) : null}
        </>
    );
};

const RenderDropzoneFieldItem = ({
    id,
    hidden,
    dropzoneText,
    acceptedFiles,
    filesLimit = 1,
    showPreviews,
    previewText,
    isSubmitOnChange = false,
    callback
}) => {
    const classes = useStyles();
    const { submitForm, setFieldValue, setErrors, setTouched } = useFormikContext();
    const intl = useIntl();

    const handleChange = files => {
        if (files.length > 0) {
            setFieldValue(id, files);
            isSubmitOnChange && submitForm();
            callback && callback(files, setFieldValue);
        }
    };

    return (
        <>
            <Field
                getDropRejectMessage={(rejectedFile, _, maxFileSize) => {
                    if (rejectedFile.size > maxFileSize) {
                        return getLocalizedString(intl, 'DOCUMENT.ERRORS.FILE_SIZE_EXCEEDED', {
                            fileName: rejectedFile.name,
                            maxFileSize: UPLOAD_MAX_FILE_SIZE / 1000000
                        });
                    }
                }}
                onAlert={(message, variant) => {
                    if (variant === 'error') {
                        setTouched({ [id]: true }, false);
                        setErrors({ [id]: message });
                    }
                }}
                component={DropzoneArea}
                maxFileSize={UPLOAD_MAX_FILE_SIZE}
                id={id}
                name={id}
                hidden={hidden}
                onChange={handleChange}
                acceptedFiles={acceptedFiles}
                filesLimit={filesLimit}
                dropzoneText={dropzoneText}
                showPreviews={showPreviews}
                showPreviewsInDropzone={false}
                dropzoneClass={classes.dropzone}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row', justify: 'center' } }}
                previewChipProps={{ classes: { root: classes.previewChip } }}
                previewText={previewText}
                showAlerts={false}
            />

            <ErrorMessage name={id}>
                {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
            </ErrorMessage>
        </>
    );
};

const RenderDateFieldItem = ({ id, variant, disabled, disablePast, className, locale }) => {
    return (
        <>
            <Field
                as={UIDatePickerField}
                name={id}
                locale={locale}
                variant={variant}
                className={className}
                disabled={disabled}
                disablePast={disablePast}
            />

            <ErrorMessage name={id} style={{ margin: 5 }}>
                {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
            </ErrorMessage>
        </>
    );
};

const RenderSwitchFieldItem = ({
    id,
    color,
    notCheckedLabelId,
    notCheckedTooltipLabel,
    checkedLabelId,
    checkedTooltipLabel
}) => {
    const { values, setFieldValue } = useFormikContext();

    const handleChange = () => {
        // Nested object not handled by setFieldValue
        if (id.includes('.')) {
            let mainId = id.split('.')[0];
            let secondaryId = id.split('.')[1];
            setFieldValue(mainId, { ...values[mainId], [secondaryId]: !values[mainId][secondaryId] });
        } else {
            setFieldValue(id, !values[id]);
        }
    };

    // handle value if nested
    const getValue = (id, values) => {
        if (id.includes('.')) {
            const splitedId = id.split('.');
            let subOject = { ...values[splitedId[0]] };
            return subOject[splitedId[1]];
        }   else {
            return values[id];
        }
    }

    return (
        <>
            <Grid container item alignItems="center" justifyContent="center" spacing={1}>
                <Grid item>
                    {notCheckedLabelId && notCheckedTooltipLabel && (
                        <UITooltip
                            title={notCheckedTooltipLabel}
                            children={<FormLabel id={notCheckedLabelId} />}
                        />
                    )}
                </Grid>
                <Grid item>
                    <Field
                        component={Switch}
                        id={id}
                        name={id}
                        checked={getValue(id, values)}
                        onChange={handleChange.bind(this)}
                        color={color}
                    />
                </Grid>
                <Grid item>
                    {checkedLabelId && checkedTooltipLabel && (
                        <UITooltip
                            title={checkedTooltipLabel}
                            children={<FormLabel id={checkedLabelId} />}
                        />
                    )}
                </Grid>
            </Grid>
            <ErrorMessage name={id}>
                {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
            </ErrorMessage>
        </>
    );
};

const RenderCheckboxFieldItem = ({ id, toolTipLabelId, labelId, customStyle, color, isSubmitOnChange = false }) => {
    const { values, setFieldValue, submitForm } = useFormikContext();

    const handleChange = () => {
        setFieldValue(id, !values[id]);
        isSubmitOnChange && submitForm();
    };

    return (
        <>
            <UITooltip
                title={toolTipLabelId}
                children={
                    <FormControlLabel
                        control={
                            <Field
                                component={Checkbox}
                                id={id}
                                name={id}
                                checked={values[id]}
                                onChange={handleChange}
                                color={color}
                            />
                        }
                        style={customStyle}
                        label={labelId}
                    />
                }
            />
        </>
    );
};

const RenderComponentFieldItem = ({ id, component, options, isPreview, ...rest }) => {
    return (
        <>
            <Field component={component} name={id} options={options} disabled={isPreview} {...rest} />
            <ErrorMessage name={id}>
                {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
            </ErrorMessage>
        </>
    );
};

const RenderProvidedFieldItem = ({ render, customProps }) => {
    return React.cloneElement(render, { ...customProps });
};

export function useForm({ enableReinitialize, initialValues, validationSchema, handleSubmit, locale='fr-fr' }) {
    const getFieldElement = field => {
        switch (field.fieldType) {
            case FORM_FIELD.TEXT_FIELD:
                return <RenderTextFieldItem {...field} />;

            case FORM_FIELD.SELECT_FIELD:
                return <RenderSelectFieldItem {...field} />;

            case FORM_FIELD.INPUT_FIELD:
                return <RenderDropzoneFieldItem {...field} />;

            case FORM_FIELD.DATE_FIELD:
                return <RenderDateFieldItem {...field} locale={locale} />;

            case FORM_FIELD.SWITCH_FIELD:
                return <RenderSwitchFieldItem {...field} />;

            case FORM_FIELD.CHECKBOX_FIELD:
                return <RenderCheckboxFieldItem {...field} />;

            case FORM_FIELD.PROVIDED_FIELD:
                return <RenderProvidedFieldItem {...field} />;

            case FORM_FIELD.COMPONENT_FIELD:
                return <RenderComponentFieldItem {...field} />;

            // case 'CustomListFieldItem':
            //     return RenderCustomListFieldItem(field);

            default:
                return null;
        }
    };

    const PrettyField = ({ field, numberOfcolumns }) => {
        const renderFieldElement = getFieldElement(field);
        const gridXs = numberOfcolumns && numberOfcolumns > 0 ? 12 / numberOfcolumns : 12  // 12: total number of columns of the container

        if (!renderFieldElement?.props?.hidden)
            return (
                <Grid container item alignItems="stretch" xs={gridXs}>
                    {field?.labelItem && (
                        <Grid container item style={{ width: 'inherit' }}>
                            <label>
                                <FormLabel id={field?.labelItem?.id} isRequired={field?.labelItem?.isRequired} />
                            </label>
                        </Grid>
                    )}
                    <Grid container item style={{ width: 'inherit' }}>
                        {renderFieldElement}
                    </Grid>
                </Grid>
            );
        else return null;
    };

    const BuildForm = ({ formFields, formNumberOfColumns, conditionalFormKey, conditionalFields, submitRef, submitTrigger }) => {
        return (
            <Form>
                <Grid container alignContent="center" alignItems="center" spacing={3}>
                    <Grid container item alignItems="stretch" xs={12} spacing={3}>
                        {formFields && React.Children.toArray(formFields?.map(
                            field => <PrettyField field={field} numberOfcolumns={formNumberOfColumns} />))}
                    </Grid>
                    <Grid container item alignItems="stretch" xs={12} spacing={3}>
                        {conditionalFields && conditionalFormKey && (
                            <BuildConditionalForm
                                conditionalFormKey={conditionalFormKey}
                                conditionalFields={conditionalFields}
                            />
                        )}
                    </Grid>
                </Grid>
                <button type="submit" style={{ display: 'none' }} onClick={submitTrigger} ref={submitRef} />
            </Form>
        );
    };

    const BuildConditionalForm = ({ conditionalFormKey, conditionalFields }) => {
        const { values } = useFormikContext();

        const form = conditionalFields?.find(form => form?.id === values[conditionalFormKey])?.form;
        const numberOfcolumns = conditionalFields?.find(form => form?.id === values[conditionalFormKey])?.numberOfcolumns ?? 0;

        return form && React.Children.toArray(
            form?.map(field => <PrettyField field={field} fieldLabel={field?.labelItem} numberOfcolumns={numberOfcolumns} />)
        );
    };

    const RenderForm = ({
        formFields,
        formNumberOfColumns = 0,    // set at 0 instead of null which is not recognised as a number
        conditionalFormKey,
        conditionalFields,
        submitRef,
        submitTrigger
    }) => {
        return (
            <Formik
                enableReinitialize={enableReinitialize}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                <BuildForm
                    formFields={formFields}
                    formNumberOfColumns={formNumberOfColumns}
                    conditionalFormKey={conditionalFormKey}
                    conditionalFields={conditionalFields}
                    submitRef={submitRef}
                    submitTrigger={submitTrigger}
                />
            </Formik>
        );
    };

    return {
        RenderForm
    };
}
