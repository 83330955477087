import React, {Component} from 'react';

export function withMenu(WrappedComponent) {
    return class extends Component {

        render() {
            return (
                <div>
                    <WrappedComponent  {...this.props}/>
                </div>
            )
        }
    }
}
