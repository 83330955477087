import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { getSelectDocumentTypeOptions } from '../../../helpers';
import { useIntl } from 'react-intl';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import Select from '@material-ui/core/Select';

export const DocumentTypeSelectField = (props: any) => {
    const intl = useIntl();
    return (
        <>
            {!props.hidden && (
                <>
                    <Field as={Select} fullWidth id={props.id ?? props.name} name={props.name} {...props}>
                        {getSelectDocumentTypeOptions(props.options, intl)}
                    </Field>
                    <ErrorMessage name={props.name}>
                        {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
                    </ErrorMessage>
                </>
            )}
        </>
    );
};
