import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody } from '../../../../partials/controls/Card';
import { Grid } from '@mui/material';
import { CompanySearchableSelect } from '../../../company/search/CompanySearchableSelect';
import { SearchClient } from './SearchClient';
import { ContactContainer } from '../../../contact/ContactContainer';
import { getExistingCompanyWithinEngagement } from '../../../../helpers/CompanyHelper';
import { CONTACT_TYPE } from '../../../../types/types';
import { ClientCompanyContext } from '../../../../context';
import { UIDialog } from '../../../common/UIDialog';

const contactsLabels = {
    cardTitle: 'CREATE_CONFIRMATION.SELECT.CLIENT.CONTACT_COMPANY.LABEL',
    createButton: 'CREATE_CONFIRMATION.CLIENT.CREATE.CONTACT.LABEL',
    createContactPopupTitle: 'DIALOG.CONTACT.CREATE.TITLE',
    editContactPopupTitle: 'DIALOG.CONTACT.EDIT.TITLE',
    contactsSelect: 'CREATE_CONFIRMATION.CLIENT.CONTACT.LABEL'
};

export function SelectClientComponent(props) {
    const {
        intl,
        loading,
        confirmation,
        clientCompanies,
        clientContacts,
        handleConfirmationUpdate,
        engagement,
        updateEngagement
    } = props;

    const [selectedContacts, setSelectedContacts] = useState(confirmation?.clientContacts);
    const [selectClientCompanies, setSelectClientCompanies] = useState(clientCompanies);
    const [openCreateCompany, setOpenCreateCompany] = useState(false);
    const [clientCompany, setClientCompany] = useState(null);
    const { createCompany, searchCompany } = useContext(ClientCompanyContext);

    useEffect(() => {
        setSelectedContacts(confirmation?.clientContacts);

        setClientCompany(confirmation.clientCompanyId ? confirmation.clientCompany : null);
    }, [confirmation]);

    useEffect(() => {
        setSelectClientCompanies(clientCompanies);
    }, [clientCompanies]);

    const handleCompanyChange = clientCompany => {
        const values = {
            clientCompany: clientCompany,
            clientCompanyId: clientCompany?.id,
            clientContacts: []
        };

        handleConfirmationUpdate(values);
    };

    const handleDeleteCompany = () => {
        const values = {
            clientCompany: null,
            clientCompanyId: null,
            clientContacts: []
        };

        handleConfirmationUpdate(values);
    };

    const handleCompanyPopUpOpening = () => {
        setOpenCreateCompany(!openCreateCompany);
    };

    const handleCompanyPopUpCancel = () => {
        setOpenCreateCompany(false);
    };

    const updateClientCallback = updatedClient => {
        const confValues = {
            clientCompany: updatedClient,
            clientCompanyId: updatedClient.id,
            clientContacts: [] //TODO do we remove all the contacts if we change the company ?
        };

        handleConfirmationUpdate(confValues);
        setOpenCreateCompany(!openCreateCompany);
    };
    /** select a client company from search module or create a new one and add it to the related engagement */
    const handleUpdateClient = (isSuccess, updatedClient) => {
        (async () => {
            if (isSuccess) {
                let updatedEngagement = engagement;
                const foundCompany = getExistingCompanyWithinEngagement(updatedEngagement, updatedClient);
                if (!foundCompany) {
                    updatedEngagement.clientCompanies.push(updatedClient);
                    const { isSuccess: isUpdateEngagementSuccess } = await updateEngagement(updatedEngagement);
                    isUpdateEngagementSuccess && updateClientCallback(updatedClient);
                } else {
                    updateClientCallback(updatedClient);
                }
            }
        })();
    };

    const handleContactsUpdate = updatedContacts => {
        handleConfirmationUpdate({
            clientContacts: updatedContacts
        });
    };

    const renderSelectCompany = () => {
        return (
            <>
                <Card>
                    <CardBody>
                        <label>
                            <FormattedMessage id="CREATE_CONFIRMATION.SELECT.CLIENT.COMPANY.LABEL" />
                        </label>

                        <Grid
                            container
                            sx={{ display: 'flex', flexWrap: 'wrap' }}
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={2}>
                            <Grid item xs={12}>
                                <CompanySearchableSelect
                                    handleDelete={handleDeleteCompany}
                                    handleCreate={handleCompanyPopUpOpening}
                                    selectedCompany={clientCompany}
                                    options={selectClientCompanies}
                                    onSelectionChange={handleCompanyChange}
                                />

                                {openCreateCompany && (
                                    <UIDialog
                                        {...props}
                                        open={openCreateCompany}
                                        titleId="DIALOG.COMPANY.CREATE.TITLE"
                                        onClose={handleCompanyPopUpCancel}
                                        children={
                                            <SearchClient
                                                loading={loading}
                                                searchCompany={searchCompany}
                                                createCompanyAPI={createCompany}
                                                createCompanyCallback={handleUpdateClient}
                                                selectCompanyCallback={handleUpdateClient}
                                            />
                                        }
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CardBody>
                </Card>
            </>
        );
    };

    return (
        <>
            {renderSelectCompany()}
            <ContactContainer
                intl={intl}
                selectedContacts={selectedContacts}
                contactType={CONTACT_TYPE.CLIENT}
                allContacts={clientContacts}
                companies={selectClientCompanies}
                handleContactsUpdate={handleContactsUpdate}
                strings={contactsLabels}
                {...props}
            />
        </>
    );
}
