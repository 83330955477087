import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody } from '../../../../partials/controls/Card';
import { Grid, Avatar } from '@mui/material';
import { initContact } from '../../../contact';
import { SearchOutlined } from '@mui/icons-material';
import { useThirdPartyCompany } from '../../../../hooks/useThirdPartyCompany';
import { MZRChip } from '../../../common/MZRChip';
import { ContactContainer } from '../../../contact/ContactContainer';
import { CompanyDetails } from '../../../company/CompanyDetails';
import { UITooltip } from '../../../common/UITooltip';
import { initCompany } from '../../../company/InitCompany';
import { CONTACT_TYPE } from '../../../../types/types';
import { ThirdPartyCompanyContext } from '../../../../context';
import { UIActionButton } from '../../../common/UIActionButton';
import { getLocalizedString } from '../../../../i18n/i18Helper';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    margin: {
        margin: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    }
}));

const contactsLabels = {
    cardTitle: 'CREATE_CONFIRMATION.SELECT.THIRDPARTY.CONTACT.LABEL',
    createButton: 'CREATE_CONFIRMATION.THIRDPARTY.CREATE.CONTACT.LABEL',
    createContactPopupTitle: 'DIALOG.CONTACT.CREATE.TITLE',
    editContactPopupTitle: 'DIALOG.CONTACT.EDIT.TITLE',
    contactsSelect: 'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.LABEL'
};

const ThirdPartySection = ({
    edit: EditComponent,
    search: SearchComponent,
    onEditCompanyClick,
    onSelectCompanyClick,
    thirdParty,
    handleDeleteThirdParty
}) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <>
            <Card>
                <CardBody>
                    <label>
                        <FormattedMessage id="CREATE_CONFIRMATION.SELECT.THIRDPARTY.COMPANY.LABEL" />
                    </label>

                    <Grid
                        container
                        className={classes.root}
                        justifyContent="center"
                        direction="column"
                        alignItems="center"
                        spacing={2}>
                        <Grid item>
                            <UIActionButton
                                startIcon={<SearchOutlined />}
                                display="secondary"
                                title={getLocalizedString(intl, 'CREATE_CONFIRMATION.THIRDPARTY.SEARCH.COMPANY.LABEL')}
                                onClick={onSelectCompanyClick}></UIActionButton>

                            <SearchComponent />
                        </Grid>

                        <Grid item>
                            {thirdParty?.id && (
                                <UITooltip
                                    title={<CompanyDetails company={thirdParty} />}
                                    children={
                                        <MZRChip
                                            editable={true}
                                            onEdit={onEditCompanyClick}
                                            onDelete={handleDeleteThirdParty}
                                            className={classes.margin}
                                            color="primary"
                                            label={`${thirdParty.name} / ${thirdParty.city}`}
                                            avatar={
                                                <Avatar>
                                                    {String(thirdParty.name)
                                                        .split(' ')
                                                        .map(x => x.substring(0, 1))
                                                        .slice(0, 2)}
                                                </Avatar>
                                            }
                                        />
                                    }
                                />
                            )}

                            <EditComponent />
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>
        </>
    );
};

export function SelectThirdPartyComponent(props) {
    const { intl, loading, confirmation, handleConfirmationUpdate } = props;
    const { fetchCompany } = useContext(ThirdPartyCompanyContext);
    const [selectedContacts, setSelectedContacts] = useState(confirmation?.thirdPartyContacts);
    const [thirdParty, setSelectedThirdParty] = useState(null);
    const [allContacts, setAllContacts] = useState([]);
    const [newContact, setNewContact] = useState(initContact);
    const classes = useStyles();

    useEffect(() => {
        setSelectedContacts(confirmation?.thirdPartyContacts);
        setNewContact({
            ...newContact,
            ...{ engagementCompanyId: confirmation?.thirdPartyCompanyId }
        });

        setSelectedThirdParty(confirmation.thirdPartyCompanyId ? confirmation.thirdPartyCompany : null);
        setAllContacts(confirmation?.thirdPartyCompany?.contacts ?? []);
    }, [confirmation]); // eslint-disable-line react-hooks/exhaustive-deps

    const editCompanyCallback = (isSuccess, editedCompany) => {
        if (isSuccess) {
            const confValues = {
                thirdPartyCompany: editedCompany,
                thirdPartyCompanyId: editedCompany.id
            };

            handleConfirmationUpdate(confValues);
        }
    };

    // Used in the case of selection from the search module or creation (click on my company does not exist button)
    const handleSelectCompany = (isSuccess, selectedCompany) => {
        const { cancelSelectCompany } = thirdPartyCompanyEnhancer;
        cancelSelectCompany(isSuccess);

        if (isSuccess) {
            const confValues = {
                thirdPartyCompany: selectedCompany,
                thirdPartyCompanyId: selectedCompany.id,
                thirdPartyContacts: []
            };

            handleConfirmationUpdate(confValues);
            setNewContact({ ...newContact, engagementCompanyId: selectedCompany.id });
        }
    };

    const thirdPartyCompanyEnhancer = useThirdPartyCompany({
        thirdPartyCompany: thirdParty,
        initialCompany: { ...initCompany, isThirdParty: true, engagementId: confirmation?.engagementId },
        handleSelectCompany: handleSelectCompany,
        editCompanyCallback: editCompanyCallback
    });

    const handleContactsUpdate = (updatedContacts, isContactUpdated = false) => {
        if (!isContactUpdated) {
            // case of selecting a contact from select
            handleConfirmationUpdate({
                thirdPartyContacts: updatedContacts
            });
        } else {
            // case of creating or updating a contact => fetch the updated company to have the list of all contact updated
            (async () => {
                const { isSuccess, parsedValue: company } = await fetchCompany(thirdParty.id);
                if (isSuccess) {
                    handleConfirmationUpdate({
                        thirdPartyContacts: updatedContacts,
                        thirdPartyCompany: company,
                        thirdPartyCompanyId: company.id
                    });
                }
            })();
        }
    };

    const handleDeleteThirdParty = () => {
        const values = {
            thirdPartyCompany: null,
            thirdPartyCompanyId: null,
            thirdPartyContacts: []
        };

        handleConfirmationUpdate(values);
    };

    return (
        <>
            <ThirdPartySection
                loading={loading}
                thirdParty={thirdParty}
                handleDeleteThirdParty={handleDeleteThirdParty}
                classes={classes}
                {...thirdPartyCompanyEnhancer}
            />
            <ContactContainer
                isCreateDisabled={!(thirdParty?.id || selectedContacts.length > 0)}
                intl={intl}
                selectedContacts={selectedContacts}
                contactType={CONTACT_TYPE.THIRD_PARTY}
                allContacts={allContacts}
                companies={[thirdParty]}
                handleContactsUpdate={handleContactsUpdate}
                strings={contactsLabels}
                initialContact={{ ...initContact, engagementCompanyId: thirdParty?.id }}
                {...props}
            />
        </>
    );
}
