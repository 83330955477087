import React from 'react';
import { EditRounded, CancelSharp } from '@mui/icons-material';
import { Grid, IconButton, Chip } from '@mui/material';
import { Palette } from '../../styles/colors';

const ChipIcon = ({ onDelete, onEdit, editable, color, disabled }) => {
    return (
        <Grid style={{ marginLeft: '5px' }}>
            {editable && !disabled && onEdit && (
                <IconButton onClick={onEdit}>
                    <EditRounded color={color} />
                </IconButton>
            )}
            {onDelete && !disabled && (
                <IconButton onClick={onDelete}>
                    <CancelSharp color={color} />
                </IconButton>
            )}
        </Grid>
    );
};

const CHIP_ALLOWED_COLORS = ['default', 'primary', 'secondary'];

const PRIMARY_COLOR = '#1565c0';

export const MZRChip = ({ onDelete, onEdit, editable = false, disabled = false, color, ...props }) => {
    // Warning allowed colors for Chip and SVGIcon are different
    const chipColor = CHIP_ALLOWED_COLORS.includes(color) ? color : CHIP_ALLOWED_COLORS[0];

    return (
        <Chip
            variant="outlined"
            sx={style.chip(chipColor)}
            {...props}
            color={chipColor}
            deleteIcon={
                <ChipIcon onDelete={onDelete} onEdit={onEdit} editable={editable} color={color} disabled={disabled} />
            }
            onMouseDown={event => {
                event.stopPropagation();
            }}
            onDelete={event => {
                event.stopPropagation();
            }}
        />
    );
};

/// need to add this style in case we customize deleteIcon (add onDelete) the chip is wrapped in MuiButtonBase-root => the border is hidden
const style = {
    chip: chipColor => ({
        '&.MuiButtonBase-root': {
            border: 1,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: chipColor === 'primary' ? PRIMARY_COLOR : Palette.secondaryTextColor
        }
    })
};
