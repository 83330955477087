import { useIntl } from 'react-intl';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { usePrevious } from '../../../hooks/usePrevious';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { EngagementSelect } from './EngagementSelect';
import { Box } from '@mui/material';

export const EngagementSelectField = (props: any) => {
    const intl = useIntl();
    const { clientCompaniesVisible = true, name } = props;

    const {
        values: { engagementId },
        touched,
        setFieldValue
    } = useFormikContext<{ engagementId: string }>();

    const previousEngagementId = usePrevious(engagementId);

    useEffect(() => {
        if (touched.engagementId && engagementId !== previousEngagementId) {
            setFieldValue('confirmationId', null);
        }
    }, [engagementId, touched.engagementId, setFieldValue, props.name]);

    const handleDelete = () => setFieldValue('confirmationId', null);

    const handleChange = (selectedValue: any) => {
        setFieldValue(name, selectedValue.id);
        props.onChange && props.onChange(selectedValue);
    };

    return (
        <Box sx={{ ...(props.sx || {}) }}>
            {!props.hidden && (
                <Field
                    as={EngagementSelect}
                    {...props}
                    clientCompaniesVisible={clientCompaniesVisible}
                    disabled={props.disabled}
                    options={props.options}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    placeholder={intl.formatMessage({
                        id: 'DOCUMENT.SELECT.ENGAGEMENT.SEARCH_BAR.PLACEHOLDER'
                    })}
                />
            )}
            <ErrorMessage name={props.name}>
                {errorMessage => FormattedErrorMessage(errorMessage, { margin: '0 .8em' })}
            </ErrorMessage>
        </Box>
    );
};
