import React, { useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Alert, Box } from '@mui/material';
import { FORM_FIELD } from '../../../helpers/FormHelper';
import { UPLOAD_TYPES } from '../../../helpers/ItemHelper';
import {
    IMPORT_CLIENT_COLUMN_RESET_DB,
    IMPORT_COLUMN_NAME,
    initClientImport,
    FILE_DISPLAY_ROWS_NUMBER,
    FILE_HEADER_INDEX
} from './InitClientImport';
import { useXLSXFile } from '../../../hooks/useXLSXFile';
import { UploadFilePreview } from '../../common/UploadFilePreview';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { useForm } from '../../../hooks/useForm';

const UploadFilePreviewComponent = (groupColumnsBySection, columnsNames) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <UploadFilePreview
            allColumns={values}
            setFieldValue={setFieldValue}
            groupedColumns={groupColumnsBySection([], [], columnsNames)}
            columnsHeaders={[IMPORT_COLUMN_NAME]}
        />
    );
};

function ClientImportFormComponent(props) {
    const { intl, handleSubmit } = props;
    const { sheetNamesList, columnsNames, onFileUploaded, onSheetSelected, groupColumnsBySection } = useXLSXFile();
    const [isResetDatabaseWarningVisible, showResetDatabaseWarning] = useState(false);
    const importFormBtnRef = useRef(null);

    const triggerButtonClick = () => importFormBtnRef?.current?.click();
    props.sbmtImportFormRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    const handleSheetSelect = event => {
        onSheetSelected(event.target.value.name, FILE_HEADER_INDEX, FILE_DISPLAY_ROWS_NUMBER);
    };

    const handleResetToggle = event => {
        showResetDatabaseWarning(event.target.value === IMPORT_CLIENT_COLUMN_RESET_DB.YES);
    };

    // Validation schema
    const importSchema = Yup.object().shape({
        worksheetName: Yup.string()
            .nullable()
            .min(1, 'Minimum 1 character')
            .max(50, 'Maximum 50 characters')
            .required(intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.WORKSHEETNAME' })),
        file: Yup.mixed().required(
            intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.FILE' })
        ),
        resetDatabase: Yup.string()
            .nullable()
            .required(intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.VALIDATION.REQUIRED_FIELD.RESETDATABASE' })),
        internalIdColumnIndex: Yup.number()
            .min(1, 'Minimum 1')
            .max(8, 'Maximum 8')
            .required(
                intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.INTERNAL_ID' })
            ),
        nameColumnIndex: Yup.number()
            .min(1, 'Minimum 1')
            .max(8, 'Maximum 8')
            .required(intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.NAME' })),
        addressColumnIndex: Yup.number()
            .min(1, 'Minimum 1')
            .max(8, 'Maximum 8')
            .required(
                intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.ADDRESS' })
            ),
        zipCodeColumnIndex: Yup.number()
            .min(1, 'Minimum 1')
            .max(8, 'Maximum 8')
            .required(
                intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.ZIPCODE' })
            ),
        cityColumnIndex: Yup.number()
            .min(1, 'Minimum 1')
            .max(8, 'Maximum 8')
            .required(intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.CITY' })),
        countryTextColumnIndex: Yup.number()
            .min(1, 'Minimum 1')
            .max(8, 'Maximum 8')
            .required(
                intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.COUNTRY' })
            ),
        companyIdColumnIndex: Yup.number()
            .min(1, 'Minimum 1')
            .max(8, 'Maximum 8')
            .required(
                intl.formatMessage({
                    id: 'CLIENT.CONFIG.IMPORT.FORM.COLUMNS.VALIDATION.REQUIRED_FIELD.COMPANY_ID_NUMBER'
                })
            )
    });

    const fields = [
        {
            id: 'file',
            name: 'file',
            fieldType: FORM_FIELD.INPUT_FIELD,
            acceptedFiles: UPLOAD_TYPES.EXCEL,
            showPreviews: true,
            filesLimit: 1,
            callback: onFileUploaded,
            dropzoneText: getLocalizedString(intl, 'CLIENT.CONFIG.IMPORT.FORM.FILE.LABEL')
        },
        {
            labelItem: { id: 'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.LABEL' },
            id: 'resetDatabase',
            name: 'resetDatabase',
            fieldType: FORM_FIELD.SELECT_FIELD,
            callback: handleResetToggle,
            options: [
                {
                    id: IMPORT_CLIENT_COLUMN_RESET_DB.NO,
                    name: intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.NO.LABEL' }),
                    value: false
                },
                {
                    id: IMPORT_CLIENT_COLUMN_RESET_DB.YES,
                    name: intl.formatMessage({ id: 'CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.YES.LABEL' }),
                    value: true
                }
            ]
        },
        {
            labelItem: { id: 'CLIENT.CONFIG.IMPORT.FORM.WORKSHEETNAME.LABEL' },
            id: 'worksheetName',
            name: 'worksheetName',
            optionsObjectChoices: true,
            fieldType: FORM_FIELD.SELECT_FIELD,
            callback: handleSheetSelect,
            options: sheetNamesList.length > 0 ? sheetNamesList : [{ id: 0, name: 'None' }]
        },
        {
            labelItem: columnsNames.length > 0 ? { id: 'CLIENT.CONFIG.IMPORT.FORM.SECTION.COLUMNS.LABEL' } : null,
            id: 'preview',
            name: 'preview',
            fieldType: FORM_FIELD.COMPONENT_FIELD,
            component: () =>
                columnsNames.length > 0 ? UploadFilePreviewComponent(groupColumnsBySection, columnsNames) : null
        }
    ];

    const handleUpload = values => {
        // reformat the data
        handleSubmit({
            ...values,
            worksheetName: values.worksheetName?.name,
            resetDatabase: values.resetDatabase === IMPORT_CLIENT_COLUMN_RESET_DB.YES,
            file: values.file[0]
        });
    };

    const form = useForm({
        initialValues: initClientImport,
        handleSubmit: handleUpload,
        enableReinitialize: true,
        validationSchema: importSchema
    });

    // need to use memo here otherwise, the formik values will be reinit after executing handleSheetSelect
    const { RenderForm: ImportForm } = useMemo(() => form, []);

    return (
        <Box>
            <Box sx={{ paddingY: 5, paddingX: 10 }}>
                {isResetDatabaseWarningVisible && (
                    <Alert severity="warning">
                        <FormattedMessage id="CLIENT.CONFIG.IMPORT.FORM.RESETDATABASE.YES.WARNING.MESSAGE" />
                    </Alert>
                )}

                <ImportForm
                    key="client_import_fields"
                    formFields={fields}
                    conditionalFields={undefined}
                    conditionalFormKey={undefined}
                    submitRef={importFormBtnRef}
                    submitTrigger={triggerButtonClick}
                />
            </Box>
        </Box>
    );
}

export const ClientImportForm = injectIntl(ClientImportFormComponent);
