import { appApiUrl } from '../../config/appConfiguration';
import { parseResponseArray } from '../parsers';
import { parseContact, parseContacts } from './contactParsers';
import { Repository } from '../Repository';

class EngagementThirdPartyContactRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetch = async (contactId) => {
        let result = await this.network.get(`EngagementThirdPartyContact/${contactId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseContact);
        return result;
    };

    fetchByCompany = async (engagementCompanyId) => {
        let result = await this.network.get(`EngagementThirdPartyContact/company/${engagementCompanyId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseContacts);
        return result;
    };

    deleteCompany = async (engagementClientCompanyId) => {
        return await this.network.delete(`EngagementThirdPartyContact/company/${engagementClientCompanyId}`, null, {
            headers: this.buildHeaders()
        });
    };

    create = async (contacts) => {
        let result = await this.network.post(`EngagementThirdPartyContact`, contacts, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseArray);
        return result;
    };

    update = async (contacts) => {
        return await this.network.put(`EngagementThirdPartyContact`, contacts, {
            headers: this.buildHeaders()
        });
    };

    delete = async (contacts) => {
        return await this.network.delete(`EngagementThirdPartyContact`, contacts, {
            headers: this.buildHeaders()
        });
    };
}

export const engagementThirdPartyContactRepository = new EngagementThirdPartyContactRepository();
