import { parseTimeZone } from '../parsers';
import { parseEngagementRole } from '../engagement/engagementParsers';
import { parseLanguage } from '../setup/setupParsers';

export const parseLoginResponse = json => {
    return {
        token: json.token,
        refreshToken: json.refreshToken
    };
};

export const parseAppUsers = json => {
    return json ? Object.values(json).map(x => parseAppUser(x)) : [];
};

export const parseAppUser = json => {
    return {
        id: json.id,
        appUserId: json.appUserId,
        firstName: json.firstName,
        lastName: json.lastName,
        fullname: json.firstName && json.lastName ? json.firstName + ' ' + json.lastName : null,
        email: json.email,
        pictureUrl: json.pictureUrl,
        office: json.office,
        city: json.city,
        businessLine: json.businessLine,
        jobTitle: json.jobTitle,
        hierarchicalGrade: json.hierarchicalGrade,
        profil: json.profile,
        clusterId: json.clusterId,
        engagementId: json.engagementId,
        engagementRole: json.engagementRole,
        engagementRoles: json.engagementRoles
            ? Object.values(json.engagementRoles).map(x => parseEngagementRole(x))
            : [],
        roles: json.roles ? json.roles.map(x => parseRole(x)) : []
    };
};

export const parseOrganization = json => {
    return {
        id: json.id,
        name: json.name,
        clusters: json.clusters ? parseOrganizationClusters(json.clusters) : []
    };
};

export const parseOrganizationClusters = json => {
    return json ? Object.values(json).map(x => parseOrganizationCluster(x)) : [];
};

export const parseOrganizationCluster = json => {
    return {
        id: json.id,
        name: json.name,
        authenticationCode: json.authenticationCode,
        organization: json.organization ? parseOrganization(json.organization) : {},
        languageId: json.languageId,
        language: json.language ? parseLanguage(json.language) : {},
        timeZone: json.timeZone,
        timeZoneInfo: json.timeZoneInfo ? parseTimeZone(json.timeZoneInfo) : {}
    };
};

export const parseUserResources = json => {
    return json ? Object.values(json).map(x => parseUserResource(x)) : [];
};

export const parseUserResource = json => {
    return {
        id: json.id,
        name: json.name,
        description: json.description,
        eUserResourceType: json.eUserResourceType,
        url: json.url,
        clusterId: json.clusterId,
        languageId: json.languageId,
        language: json.language ? parseLanguage(json.language) : {}
    };
};

export const parseRole = json => {
    return {
        id: json.id,
        name: json.name,
        description: json.description,
        isEnabled: json.isEnabled,
        isEditable: json.isEditable,
        isDefault: json.isDefault,
        isRestricted: json.isRestricted,
        blueprintRoleId: json.blueprintRoleId,
        created: json.created,
        creator: json.creator,
        updated: json.updated,
        updator: json.updator,
        roleScopes: json.roleScopes ? json.roleScopes.map(x => parseRoleScope(x)) : []
    };
};

export const parseRoleScope = json => {
    return {
        appUserRoleId: json.appUserRoleId,
        scopeId: json.scopeId,
        eRoleScope: json.eRoleScope,
        rights: json.rights ? json.rights.map(x => parseRights(x)) : []
    };
};

export const parseRights = json => {
    return {
        roleId: json.roleId,
        right: json.right,
        rightPermission: json.rightPermission,
        eRightPermission: json.eRightPermission
    };
};
