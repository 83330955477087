import { performAPIAction } from './BaseService';
import { IntlShape } from 'react-intl';
import { ServiceResult } from '../kit/network/ServiceResult';
import { clientAccountRepository, externalAuthRepository } from '../kit/repository';

interface ServiceProps {
    entityType: any;
    intl: IntlShape;
    setLoading: (loading: boolean) => void;
    checkSession: (result: ServiceResult) => void;
    addToastMessage: (toast: any) => void;
    callback: (isSuccess: boolean, data: ServiceResult, entityType: any) => void;
}

export const ExternalClientService = (props: ServiceProps) => {
    return {
        invite: (email, inviter, redirectUrl, rights) =>
            performAPIAction({
                ...props,
                apiCall: clientAccountRepository.invite(email, inviter, redirectUrl, rights),
                successMessageId: 'SETTINGS.CLIENT.CONTACT.INVITE.SUCCESS',
                errorMessageId: 'SETTINGS.CLIENT.CONTACT.INVITE.ERROR'
            }),
        resetPassword: (email, inviter) =>
            performAPIAction({
                ...props,
                apiCall: externalAuthRepository.resetPassword(email, inviter),
                successMessageId: 'SETTINGS.CLIENT.CONTACT.RESET.PASSWORD.SUCCESS',
                errorMessageId: 'SETTINGS.CLIENT.CONTACT.RESET.PASSWORD.ERROR'
            })
    };
};
