export const toolbarStyle = {
    container: {
        m: 2,
        gap: 1,
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'center'
    },
    button: { minWidth: 'auto', minHeight: 'auto' }
};
