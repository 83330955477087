export const company = {
    'COMPANY.TABLE.TITLE.NAME': 'Company',
    'COMPANY.TABLE.TITLE.ADDRESS': 'Address',
    'COMPANY.TABLE.TITLE.ZIPCODE': 'ZipCode',
    'COMPANY.TABLE.TITLE.CITY': 'City',
    'COMPANY.TABLE.TITLE.COUNTRY': 'Country',
    'COMPANY.TABLE.TITLE.INTERNAL_ID': 'Forvis Mazars Code',
    'COMPANY.TABLE.TITLE.COMPANY_ID_NUMBER': 'Company ID Number',
    'COMPANY.TABLE.TITLE.COMPANY_ID_NUMBER2': 'Company ID Number 2',
    'COMPANY.TABLE.TITLE.VAT_NUMBER': 'VAT Number',
    'COMPANY.TABLE.TITLE.CREATED': 'Created',
    'COMPANY.TABLE.TITLE.CONTACTS': 'Contacts',
    'COMPANY.TABLE.DELETE.COMPANY': 'Delete',
    'COMPANY.TABLE.EDIT.COMPANY': 'Edit',
    'COMPANY.CREATE.CLIENT.SUCCESS': 'Company created',
    'COMPANY.CREATE.CLIENT.ERROR': 'Company creation error',
    'COMPANY.UPDATE.CLIENT.SUCCESS': 'Company updated',
    'COMPANY.UPDATE.CLIENT.ERROR': 'Company update error',
    'COMPANY.DELETE.CLIENT.SUCCESS': 'Company deleted',
    'COMPANY.DELETE.CLIENT.ERROR': 'Company delete error',
    'COMPANY.SEARCH.CLIENT.SUCCESS': 'Companies search sucess',
    'COMPANY.SEARCH.CLIENT.ERROR': 'Companies search error',
    'COMPANY.FETCH.CLIENT.SUCCESS': 'Company fech success',
    'COMPANY.FETCH.CLIENT.ERROR': 'Company fetch error',
    'COMPANY.UPLOAD.CLIENT.SUCCESS': 'Companies uploaded',
    'COMPANY.UPLOAD.CLIENT.ERROR': 'Companies upload failed',
    'COMPANY.DOWNLOAD.CLIENT.ERROR': 'Companies Excel template download failed',
    'COMPANY.BUTTON.DOWNLOAD.LABEL': 'Download all',
    'COMPANY.BUTTON.DOWNLOAD.TOOLTIP': 'Download Excel file of third-party companies and contacts',
    'COMPANY.DOWNLOAD.THIRDPARTY.FILENAME': '{engagementName}_thirparty_companies_and_contacts.xlsx',
    'COMPANY.DOWNLOAD.THIRDPARTY.ERROR': 'Third-party companies & contacts download failed',
    'SEARCH.COMPANY.LABEL.SEARCH.FILTER_1': 'Name',
    'SEARCH.COMPANY.LABEL.SEARCH.FILTER_2': 'Country',
    'SEARCH.COMPANY.LABEL.SEARCH.FILTER_3': 'Address, ZipCode, City,...',
    'SEARCH.COMPANY.BUTTON.SEARCH.LABEL': 'Search',
    'SEARCH.COMPANY.BUTTON.CLEAR.LABEL': 'Clear',
    'SEARCH.COMPANY.BUTTON.CLEAR.DESCRIPTION': 'Clear filters',
    'SEARCH.COMPANY.BUTTON.CREATE.COMPANY.LABEL': 'My company does not exist',
    'FETCH.COMPANY.CLIENTS.ACCESS.ERROR': 'Client companies access error',
    'FETCH.COMPANY.THIRDPARTIES.ACCESS.ERROR': 'Third-party companies access error',

};
