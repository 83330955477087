import { parseAppUser, parseOrganizationCluster } from '../user/userParsers';
import { parseWorkflow, parseWorkflowAction } from '../workflow/workflowParsers';
import { parseItem, parseItems } from '../item/itemParsers';
import { parseCompany } from '../company/companyParsers';
import { parseContact, parseContacts } from '../contact/contactParsers';
import { CLIENT_REQUEST_STATUS } from '../../../types/types';

export const parseConfirmationsMenu = json => {
    return json ? Object.values(json).map(x => parseConfirmationMenuItem(x)) : [];
};

export const parseConfirmationMenuItem = json => {
    return {
        id: json.id,
        name: json.name,
        isTrashed: json.isTrashed,
        confirmationCode: json.confirmationCode,
        engagementId: json.engagementId
    };
};

export const parseConfirmations = json => {
    return json ? Object.values(json).map(x => parseConfirmation(x)) : [];
};

export const parseConfirmation = json => {
    return {
        id: json.id,
        confirmationCode: json.confirmationCode,
        languageId: json.languageId,
        name: json.name,
        description: json.description,
        periodStartDate: json.periodStartDate,
        periodEndDate: json.periodEndDate,
        clientSendDate: json.clientSendDate,
        clientDueDate: json.clientDueDate,
        automaticClientSending: json.automaticClientSending,
        thirdPartySendDate: json.thirdPartySendDate,
        thirdPartyDueDate: json.thirdPartyDueDate,
        automaticThirdPartySending: json.automaticThirdPartySending,
        confirmationCategoryId: json.confirmationCategoryId,
        confirmationCategory: json.confirmationCategory ? parseConfirmationCategory(json.confirmationCategory) : {},
        confirmationLetterTemplate: json.confirmationLetterTemplate ? parseLetterTemplate(json) : {},
        thirdPartyCompany: json.thirdPartyCompany ? parseCompany(json.thirdPartyCompany) : null,
        engagementThirdPartyCompanyId: json.EngagementThirdPartyCompanyId,
        clientCompany: json.clientCompany ? parseCompany(json.clientCompany) : {},
        engagementClientId: json.engagementClientId, //TODO check its usage
        engagementClientCompanyId: json.EngagementClientCompanyId,
        engagementId: json.engagementId,
        clusterId: json.clusterId,
        clientContacts: json.clientContacts ? json.clientContacts.map(x => parseContact(x)) : [],
        thirdPartyContacts: json.thirdPartyContacts ? json.thirdPartyContacts.map(x => parseContact(x)) : [],
        steps: json.steps ? json.steps.map(x => parseConfirmationStep(x)) : [],
        previousStep: json.previousStep ? parseConfirmationStep(json.previousStep) : {},
        currentStep: json.currentStep ? parseConfirmationStep(json.currentStep) : {},
        nextStep: json.nextStep ? parseConfirmationStep(json.nextStep) : {},
        items: json.items ? parseItems(json.items) : [],
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updatorId: json.updatorId,
        updator: json.updator ? parseAppUser(json.updator) : {},
        optionalFields: json?.tags ? parseConfirmationOptionalFields(json?.tags) : [],
        isDeletable: json.isDeletable,
        isEditable: json.isEditable,
        numberOfClientSending: json.numberOfClientSending, //  reminder action to client counter
        lastClientSendingDate: json.lastClientSendingDate,
        numberOfThirdPartySending: json.numberOfThirdPartySending, // reminder action to third party counter
        lastThirdPartySendingDate: json.lastThirdPartySendingDate,
        clientRequestStatus: json.clientRequestStatus ?? CLIENT_REQUEST_STATUS.NOT_STARTED,
        auditorRefuseMessage: json.auditorRefuseMessage,
        confirmationInfo: json.name, //TODO not only name but account/amount/comment //TODO it should be an info that the auditor provide in confirmation creation process
        isLetterGenerated: json.isLetterGenerated ?? true, //TODO remove true when it s ready in the api side
        letterItemId: json.letterItemId ?? '302467ab-2d05-4464-1cd8-08d9e0220980', //TODO remove hard coded item id when it s ready
        hasPendingDocuments: json.hasPendingDocuments ?? false,
        isTrashed: json.isTrashed
    };
};

export const parseConfirmationCategories = json => {
    return json ? Object.values(json).map(x => parseConfirmationCategory(x)) : [];
};

export const parseConfirmationCategory = json => {
    return {
        id: json.id,
        name: json.name,
        description: json.description,
        eConfirmationCategory: json.eConfirmationCategory,

        //TODO clean up code: the following attributes don't exist anymore
        steps: json.steps ? Object.values(json.steps).map(x => parseStep(x)) : [],
        numberOfSteps: json.numberOfSteps,
        mandatoryTags: json.mandatoryTags ? Object.values(json.mandatoryTags).map(x => parseTag(x)) : [],
        optionalTags: json.optionalTags ? Object.values(json.optionalTags).map(x => parseTag(x)) : [],
        confirmationLetterTemplate: json.confirmationLetterTemplate,
        letterTemplate: json.letterTemplate
    };
};

export const parseConfirmationOptionalFields = json => {
    return json ? Object.values(json).map(x => parseOptionalField(x)) : [];
};

export const parseOptionalField = json => {
    return {
        id: json.id,
        eTag: json.eTag,
        dataType: json.dataType,
        value: json.value,
        name: json.name,
        description: json.description,
        isInput: json.isInput // if true, the tag is displayed to the auditor and he must filled it, else it's not displayed
    };
};

export const parseLetterTemplates = json => {
    return json ? Object.values(json).map(x => parseLetterTemplate(x)) : [];
};

export const parseLetterTemplate = json => {
    return {
        id: json.id,
        name: json.name,
        description: json.description,
        itemId: json.itemId,
        item: json.item ? parseItem(json.item) : {},
        confirmationCategoryId: json.confirmationCategoryId,
        confirmationCategory: json.confirmationCategory ? parseConfirmationCategory(json.confirmationCategory) : {},
        workflowId: json.workflowId,
        workflow: json.workflow ? parseWorkflow(json.workflow) : {},
        clusterId: json.clusterId,
        cluster: json.cluster ? parseOrganizationCluster(json.cluster) : {},
        languageId: json.languageId,
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updatorId: json.updatorId,
        updator: json.updator ? parseAppUser(json.updator) : {},
        tags: json.tags ? parseTags(json.tags) : []
    };
};

export const parseLetterTemplateTags = json => {
    return json ? Object.values(json).map(x => parseLetterTemplateTag(x)) : [];
};

export const parseLetterTemplateTag = json => {
    return {
        id: json.id,
        tagId: json.tagId,
        tag: json.tag ? parseTag(json.tag) : {},
        isMandatory: json.isMandatory
    };
};

export const parseSteps = json => {
    return json ? Object.values(json).map(x => parseStep(x)) : [];
};

export const parseStep = json => {
    return {
        id: json.id,
        position: json.position,
        name: json.name,
        description: json.description,
        mandatory: json.mandatory
    };
};

export const parseTags = json => {
    return json ? Object.values(json).map(x => parseTag(x)) : [];
};

export const parseTag = json => {
    return {
        id: json.id,
        name: json.name,
        description: json.description,
        text: json.text,
        dataType: json.dataType,
        eTag: json.eTag,
        isMandatory: json.isMandatory,
        isInput: json.isInput
    };
};

const parseConfirmationStep = json => {
    return {
        id: json.id,
        name: json.name,
        confirmationId: json.confirmationId,
        workflowActionId: json.workflowActionId,
        workflowAction: json.workflowAction ? parseWorkflowAction(json.workflowAction) : {},
        description: json.description,
        position: json.position,
        status: json.status,
        Created: json.created,
        creatorId: json.creatorId,
        updated: json.updated,
        updatorId: json.updatorId,
        isActive: json.isActive,
        stepActions: json.stepActions ? json.stepActions.map(action => parseConfirmationStepAction(action)) : []
    };
};

const parseConfirmationStepAction = json => {
    return {
        confirmationStepId: json.confirmationStepId,
        status: json.status,
        text: json.text,
        type: json.type,
        isMenu: json.isMenu,
        isCritical: json.isCritical,
        isHighlight: json.isHighlight
    };
};

export const parseComments = json => {
    return json ? Object.values(json).map(x => parseComment(x)) : [];
};

export const parseComment = json => {
    return {
        id: json.id,
        confirmationId: json.confirmationId,
        text: json.text,
        isPrivate: json.isPrivate,
        created: json.created,
        updated: json.updated,
        author: json.author ? parseAppUser(json.author) : {},
        authorEngagementRole: json.authorEngagementRole
    };
};

const parseKeyValue = json => {
    switch (json.constructor.name) {
        case 'clientContacts':
            return json.value ? parseContacts(json.value) : [];

        case 'thirdPartyContacts':
            return json.value ? parseContacts(json.value) : [];

        default:
            return json.value;
    }
};

const parseUploadedValue = json => {
    return {
        value: typeof json.value === 'object' && json.value !== null ? parseKeyValue(json) : json.value,
        isValid: json.isValid,
        message: json.message
    };
};

export const parseUploadedConfirmation = json => {
    return {
        name: json.name ? parseUploadedValue(json.name) : {},
        description: json.description ? parseUploadedValue(json.description) : {},
        languageId: json.languageId,
        clientSendDate: json.clientSendDate ? parseUploadedValue(json.clientSendDate) : {},
        clientDueDate: json.clientDueDate ? parseUploadedValue(json.clientDueDate) : {},
        automaticClientSending: json.automaticClientSending ? parseUploadedValue(json.automaticClientSending) : {},
        thirdPartySendDate: json.thirdPartySendDate ? parseUploadedValue(json.thirdPartySendDate) : {},
        thirdPartyDueDate: json.thirdPartyDueDate ? parseUploadedValue(json.thirdPartyDueDate) : {},
        automaticThirdPartySending: json.automaticThirdPartySending
            ? parseUploadedValue(json.automaticThirdPartySending)
            : {},
        confirmationTypeId: json.confirmationTypeId,
        confirmationTemplateId: json.confirmationTemplateId,
        engagementId: json.engagementId,
        clientCompanyId: json.clientCompanyId,
        thirdPartyCompanyId: json.thirdPartyCompanyId,
        clientContacts: json.clientContacts ? json.clientContacts.map(x => parseUploadedValue(x)) : [],
        thirdPartyContacts: json.thirdPartyContacts ? json.thirdPartyContacts.map(x => parseUploadedValue(x)) : [],
        accounts: json.accounts ? json.accounts : []
    };
};

export const parseConfirmationRequests = json => {
    return json ? Object.values(json).map(x => parseConfirmationRequest(x)) : [];
};

export const parseConfirmationRequest = json => {
    return {
        id: json.id,
        engagementId: json.engagementId,
        confirmationId: json.confirmationId,
        confirmationCategory: json.confirmationCategory ? parseConfirmationCategory(json.confirmationCategory) : {},
        status: json.status,
        refusalReason: json.refusalReason,
        clientCompany: json.clientCompany ? parseCompany(json.clientCompany) : {},
        clientDueDate: json.clientDueDate,
        letterHead: json.letterHead ? parseLetterHead(json.letterHead) : {},
        isLetterGenerated: json.isLetterGenerated,
        thirdPartyCompany: json.clientCompany ? parseCompany(json.thirdPartyCompany) : {},
        clientContacts: json.clientContacts ? parseContacts(json.clientContacts) : [],
        thirdPartyContacts: json.thirdPartyContacts ? parseContacts(json.thirdPartyContacts) : [],
        comments: json.comments ? parseComments(json.comments) : [],
        created: json.created,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updator: json.updator ? parseAppUser(json.updator) : {}
    };
};

export const parseLetterHead = json => {
    return {
        id: json.id,
        engagementClientCompanyId: json.engagementClientCompanyId,
        engagementClientCompany: json.engagementClientCompany,
        fileExtension: json.fileExtension,
        originalName: json.originalName,
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {}
    };
};
