import React from 'react';
import { injectIntl } from 'react-intl';
import { MZRSearchTab } from '../../common/MZRSearchTab';

export const SEARCH_FILTERS = {
    FILTER_1: { id: 'FILTER_1', fields: ['name'] },
    FILTER_2: { id: 'FILTER_2', fields: ['countryText'] },
    FILTER_3: { id: 'FILTER_3', fields: ['address', 'zipCode', 'city'] }
};

const CompanySearchTabComponent = props => {
    return <MZRSearchTab {...props} filtersList={SEARCH_FILTERS} />;
};

export const SearchCompanyTab = injectIntl(CompanySearchTabComponent);
