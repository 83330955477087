export const popup = {
    'MESSAGE.REFUSE.WORKFLOW.STEP.TITLE': 'ARE YOU SURE?',
    'MESSAGE.REFUSE.WORKFLOW.STEP.CONTENT':
        'Hello {userName}, Are you sure that you want to refuse and reset the confirmation? This operation is definitive.',
    'MESSAGE.REFUSE.WORKFLOW.STEP.COMMENT': 'Please explain your refusal',
    'MESSAGE.REFUSE.LOST.DOCUMENT.CONTENT':
        'Hello {userName}, Are you sure that you want to send this document to the section lost documents? This operation is definitive.',
    'MESSAGE.REFUSE.LOST.DOCUMENT.COMMENT':
        'Please provide information about the document so that it can be identified by the auditors',
    'MESSAGE.RESET.WORKFLOW.STEP.TITLE': 'ARE YOU SURE?',
    'MESSAGE.RESET.WORKFLOW.STEP.CONTENT':
        'Hello {userName}, please be aware that cancelling a confirmation will reset it and all data will be lost. This operation is definitive.',
    'MESSAGE.RESET.WORKFLOW.STEP.ERROR': 'Select error type',
    'MESSAGE.RESET.FIELD.VALIDATION.REQUIRED_FIELD.ERROR_TYPE': 'Document error type is required',
    'MESSAGE.RESET.WORKFLOW.STEP.COMMENT': 'Please explain',
    'MESSAGE.DELETE.CONFIRMATION.TITLE': 'ARE YOU SURE?',
    'MESSAGE.ERASE.CONFIRMATION.CONTENT':
        'Hello {userName}, please be aware that deleting a confirmation will remove all its data. This operation is definitive.',
    'MESSAGE.TRASH.CONFIRMATION.CONTENT':
        "Hello {userName}, are you sure you want to put this confirmation in the engagement's recycle bin?",
    'MESSAGE.DELETE.CONFIRMATION.COMMENT': 'Please provide a reason for the deletion',
    'MESSAGE.DELETE.ITEM.TITLE': 'ARE YOU SURE?',
    'MESSAGE.DELETE.ITEM.CONTENT':
        'Hello {userName}, please be aware that deleting a document will remove it permanently and will not be recovered. This operation is definitive.',
    'MESSAGE.DELETE.ITEM.COMMENT': 'Please confirm the deletion.',
    'MESSAGE.ARCHIVE.ENGAGEMENT.TITLE': 'ARE YOU SURE TO ARCHIVE THIS ENGAGEMENT?',
    'MESSAGE.UNARCHIVE.ENGAGEMENT.TITLE': 'ARE YOU SURE TO UNARCHIVE THIS ENGAGEMENT?',
    'MESSAGE.ARCHIVE.ENGAGEMENT.CONTENT':
        'Hello {userName}, please be aware that archiving an engagement is not reversible and disables its edition.{br}{br}It will be impossible to create another engagement with the "{ engagementCode }" code afterward.{br}{br}This operation is definitive. Please confirm your choice?',
    'MESSAGE.UNARCHIVE.ENGAGEMENT.CONTENT':
        'Hello {userName}, please be aware that will unarchive the engagement "{ engagementCode }". Please confirm your choice?',
    'MESSAGE.DELETE.ENGAGEMENT.TITLE': 'ARE YOU SURE?',
    'MESSAGE.DELETE.ENGAGEMENT.CONTENT':
        'Hello {userName}, please be aware that deleting an engagement will remove all its data. This operation is definitive.',
    'MESSAGE.DELETE.ENGAGEMENT.COMMENT': 'Please provide a reason for the deletion',
    'MESSAGE.VALIDATION.DISPATCH.ITEM.TYPE': 'Type of document',
    'MESSAGE.VALIDATION.DISPATCH.ITEM.COMMENT': 'Comment',
    'MESSAGE.VALIDATION.DISPATCH.ITEM.CONTENT':
        'Hello {userName}, please confirm your modifications for the document: {documentName}.',
    'MESSAGE.VALIDATION.DISPATCH.APPLIED.TO.FOLDER.LABEL':
        "Do you want to apply those modifications to the document or to the document's folder?",
    'MESSAGE.VALIDATION.DISPATCH.VALIDATION.REQUIRED_FIELD.APPLIED.TO.FOLDER':
        'The modifications can not be applied to the folder if the document is removed from the folder',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.ITEM.LABEL': 'Apply to Document',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.ITEM.TOOLTIP':
        'Apply modifications (eg. engagement code, confirmation code and/or document type) to the document',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.FOLDER.LABEL': 'Apply to Folder',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.APPLY.TO.FOLDER.TOOLTIP':
        'Apply modifications (eg. engagement code, confirmation code and/or document type) to the folder',
    'MESSAGE.VALIDATION.DISPATCH.REMOVE.FROM.FOLDER.LABEL':
        'Do you want to REMOVE the item from his folder or leave it in his folder?',
    'MESSAGE.VALIDATION.DISPATCH.VALIDATION.REQUIRED_FIELD.REMOVE.FROM.FOLDER':
        'Document can not be removed from folder',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.KEEP.IN.FOLDER.LABEL': 'Leave in folder',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.KEEP.IN.FOLDER.TOOLTIP': 'Leave the current document to his folder',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.REMOVE.FROM.FOLDER.LABEL': 'Remove from folder',
    'MESSAGE.VALIDATION.DISPATCH.BUTTON.REMOVE.FROM.FOLDER.TOOLTIP': 'Remove the current document from his folder',
    'INVALID.SESSION.POPUP.TITLE': 'Error',
    'INVALID.SESSION.FORBIDDEN.ACCESS.MESSAGE': 'You are not authorized to access this content',
    'INVALID.SESSION.NOT.FOUND.MESSAGE': 'The resource you are trying to access is not found',
    'INVALID.SESSION.UNAUTHORISED.USER.MESSAGE':
        'Your session has expired, please refresh your page and log in again to have access to the platform',
    'UNKNOWN.ERROR.MESSAGE': 'An unknown error has occurred, please try again later',

    'DIALOG.SECTION.TITLE': 'Contact information',
    'DIALOG.BUTTON.CANCEL': 'Cancel',
    'DIALOG.BUTTON.SAVE': 'Save',
    'DIALOG.BUTTON.VALIDATE': 'Validate',
    'DIALOG.BUTTON.OK': 'OK',
    'DIALOG.BUTTON.DELETE': 'Delete',
    'DIALOG.COMPANY.CREATE.TITLE': 'Create company',
    'DIALOG.COMPANY.EDIT.TITLE': 'Edit company',
    'DIALOG.CONTACT.CREATE.TITLE': 'Create contact',
    'DIALOG.CONTACT.EDIT.TITLE': 'Edit contact',
    'DIALOG.CONTACT.INVITE.TITLE': 'Invite and manage access rights for {contact} {email}',
    'DIALOG.CONTACT.MANAGE.ACCESS.TITLE': 'Manage access rights for  {contact} {email}'
};
