import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { getSessionStatus, withCheckSession } from '../../enhancers/wichCheckSession';
import { Settings } from '../../components/engagement/settings/Settings';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import {
    fetchData,
    fetchEngagement,
    CompanyService,
    LetterHeadService,
    ExternalClientService,
    ContactService,
    RobotConfigurationService
} from '../../services';
import { withEngagement } from '../../enhancers/withEngagement';
import { COMPANY_TYPE, SessionStatus } from '../../kit/utils/constants';
import { ClientCompanyContext, ThirdPartyCompanyContext, LetterHeadContext, ContactContext } from '../../context';
import { UIScaffold } from '../../scaffolder/UIScaffold';
import { CONTACT_TYPE, PROFILE } from '../../types/types';
import { ROUTES } from '../../navigation/Routes';
import { UILoader } from '../../components/common/UILoader';
import { saveDownloadedFile } from '../../helpers';
import { getLocalizedString } from '../../i18n/i18Helper';

/**
 * Settings screen for engagement.
 *
 * User can update engagement content as such as
 * - Information (Name, description, Mazars project code, closing date)
 * - Clients or subsidiaries
 * - Members (audit team) and members' rights
 */
class SettingsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isEditable: true,
            checkSessionResults: []
        };
    }

    scaffold = null;

    settingsAccessible =
        isAuthorisedProfile(PROFILE.AUDITOR, this.props.user.profil) ||
        isAuthorisedProfile(PROFILE.CLIENT, this.props.user.profil);

    setLoading = loading => {
        this.scaffold.setLoading(loading);
    };

    externalClientService = ExternalClientService({
        intl: this.props.intl,
        checkSession: result => {
            const status = getSessionStatus(result);
            this.scaffold.setSessionExpired(status === SessionStatus.invalid);
            return status;
        },
        addToastMessage: toast => this.scaffold.setToast(toast.title, toast.description, toast.type),
        target: [CONTACT_TYPE.CLIENT],
        setLoading: this.setLoading,
        callback: (isSuccess, data, entityType) => {
            //TODO refresh engagement ?
        }
    });

    clientCompanyManager = CompanyService({
        engagementId: this.props.match.params.engagementId,
        intl: this.props.intl,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        target: [COMPANY_TYPE.CLIENT],
        setLoading: this.setLoading
    });

    thirdPartyCompanyManager = CompanyService({
        engagementId: this.props.match.params.engagementId,
        intl: this.props.intl,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        target: [COMPANY_TYPE.THIRDPARTY],
        setLoading: this.setLoading
    });

    letterHeadManager = LetterHeadService({
        intl: this.props.intl,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading,
        callback: async isSuccess => {
            isSuccess && (await fetchEngagement(this));
        }
    });

    contactManager = ContactService({
        intl: this.props.intl,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        target: [CONTACT_TYPE.CLIENT],
        callback: async isSuccess => {
            isSuccess && (await fetchEngagement(this));
        }
    });

    robotConfigManager = RobotConfigurationService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading,
        callback: async (isSuccess, data, _) => isSuccess && this.setState({ robotConfig: data?.parsedValue })
    });

    async componentDidMount() {
        const { currentClusterId } = this.props;
        await fetchData(this, [
            () => fetchEngagement(this),
            () => this.robotConfigManager.fetchCluster(currentClusterId)
        ]);
    };

    /** Update engagement information. */
    handleUpdateEngagement = async engagement => {
        const { performUpdateEngagement } = this.props;
        await performUpdateEngagement({
            engagement: engagement,
            setLoading: this.setLoading,
            strings: {
                successMessageId: 'SETTINGS.UPDATE.ENGAGEMENT.SUCCESS',
                errorMessageId: 'SETTINGS.UPDATE.ENGAGEMENT.ERROR'
            },
            successCallback: async () => {
                await fetchEngagement(this);
            }
        });
    };

    handleArchiveEngagement = async ({ engagement: { id, archived } }) => {
        const { performToggleArchiveEngagement } = this.props;
        const result = await performToggleArchiveEngagement({
            engagementId: id,
            setLoading: this.setLoading,
            strings: {
                successMessageId: `FETCH.ENGAGEMENT.${!archived ? 'ARCHIVE' : 'UNARCHIVE'}.SUCCESS`,
                errorMessageId: `FETCH.ENGAGEMENT.${!archived ? 'ARCHIVE' : 'UNARCHIVE'}.ERROR`
            }
        });

        return result;
    };

    handleDeleteEngagement = async engagementInfo => {
        const { performDeleteEngagement } = this.props;
        return performDeleteEngagement({
            engagementInfo: engagementInfo,
            setLoading: this.setLoading
        });
    };

    handleInviteContact = contact => {
        const {
            user,
            match: { params }
        } = this.props;

        const redirectUrl = ROUTES.ENGAGEMENT.replace(':engagementId', params.engagementId);
        this.externalClientService.invite(contact.email, user.email, redirectUrl, contact.accessRights);
    };

    handleResetPassword = contact => {
        const { user } = this.props;
        this.externalClientService.resetPassword(contact.email, user.email);
    };

    handleDownloadAll = async () => {
        const { intl } = this.props;
        await this.thirdPartyCompanyManager.downloadThirdParties(
            this.state.engagement.id,
            (isSuccess, data, _) =>
                isSuccess &&
                saveDownloadedFile(
                    data,
                    getLocalizedString(intl, 'COMPANY.DOWNLOAD.THIRDPARTY.FILENAME', {
                        engagementName: this.state.engagement.name
                    })
                )
        );
    };

    render() {
        const { user, saveActiveStepPosition, activeStepPosition } = this.props;
        return !user.profil ? (
            <UILoader />
        ) : !this.settingsAccessible ? (
            <PageNotFound {...this.props} />
        ) : (
            <div className="row">
                <div className="col-12">
                    <ThirdPartyCompanyContext.Provider
                        value={{
                            createCompany: this.thirdPartyCompanyManager.createThirdParty,
                            updateCompany: this.thirdPartyCompanyManager.updateThirdParty,
                            searchCompany: this.thirdPartyCompanyManager.searchThirdParties,
                            deleteCompany: this.thirdPartyCompanyManager.deleteThirdParty,
                            downloadAll: this.handleDownloadAll
                        }}>
                        <ClientCompanyContext.Provider
                            value={{
                                searchCompany: this.clientCompanyManager.searchGlobalClients
                            }}>
                            <LetterHeadContext.Provider
                                value={{
                                    createLetterHead: this.letterHeadManager.createLetterHead,
                                    deleteLetterHead: this.letterHeadManager.deleteLetterHead,
                                    fetchLetterHeadFile: this.letterHeadManager.fetchLetterHeadFile
                                }}>
                                <ContactContext.Provider
                                    value={{
                                        [CONTACT_TYPE.CLIENT]: {
                                            revokeAccess: this.contactManager.revokeAccess,
                                            deleteAccess: this.contactManager.deleteAccess
                                        }
                                    }}>
                                    <UIScaffold>
                                        {props => {
                                            this.scaffold = props;
                                            return (
                                                <Settings
                                                    user={user}
                                                    loading={this.state.loading}
                                                    engagement={this.state.engagement}
                                                    engagementCodeRegEx={this.state.robotConfig?.engagementCodePattern}
                                                    engagementCodeMessage={this.state.robotConfig?.description}
                                                    activeStepPosition={activeStepPosition}
                                                    saveActiveStepPosition={saveActiveStepPosition}
                                                    updateEngagement={this.handleUpdateEngagement}
                                                    archiveEngagement={this.handleArchiveEngagement}
                                                    deleteEngagement={this.handleDeleteEngagement}
                                                    inviteContact={this.handleInviteContact}
                                                    resetPasswordContact={this.handleResetPassword}
                                                />
                                            );
                                        }}
                                    </UIScaffold>
                                </ContactContext.Provider>
                            </LetterHeadContext.Provider>
                        </ClientCompanyContext.Provider>
                    </ThirdPartyCompanyContext.Provider>
                </div>
            </div>
        );
    }
}

SettingsComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool,
    checkSessionResults: PropTypes.array
};

SettingsComponent.defaultProps = {
    loading: false,
    isEditable: true
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    },
    saveActiveStepPosition: position => {
        dispatch(actions.setActiveStepPosition(position));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        activeStepPosition: state.engagement.activeStepPosition,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const settingsComponent = compose(
    injectIntl,
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
    // the order if composition is important, need to inject first injectIntl and redux to have the props available in the HOC
)(withEngagement(SettingsComponent));
