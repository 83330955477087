import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { DEFAULT_CONFIRMATION_TEMPLATE_CATEGORY, EmailTemplateAlertSection, EmailTemplateConfigComponent } from '.';
import { EXTERNAL_EMAIL_TYPES } from '../../../kit/utils/constants';

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
});

export function EmailTemplateConfigWrapper(props) {
    const {
        currentClusterId,
        emailTypes,
        emailTemplates,
        languages,
        confirmationCategories,
        configurationsChecks,
        letterTemplates,
        createEmailTemplate,
        updateEmailTemplate,
        deleteEmailTemplate
    } = props;
    const classes = useStyles();

    const [isConfigValid, setIsConfigValid] = useState(false);

    const [isMissingEmailTemplateTypes, setIsMissingEmailTemplateTypes] = useState(false);
    const [missingEmailTemplateTypes, setMissingEmailTemplateTypes] = useState([]);

    // Collection of flags - object with type of email as a key
    const [isEmailTemplateLanguagesMissing, setIsEmailTemplateLanguagesMissing] = useState({});
    const [emailTemplateLanguagesMissing, setEmailTemplateLanguagesMissing] = useState({});

    const checkMissingEmailType = (emailTypes, emailTemplates) => {
        const missingEmailTemplate = emailTypes.filter(t => !emailTemplates.some(e => t.id === e?.emailType?.id));

        setIsMissingEmailTemplateTypes(missingEmailTemplate?.length > 0 ? true : false);
        setMissingEmailTemplateTypes(missingEmailTemplate);
    };

    // Function to check if every letter templates have an external email template corresponding to their language
    const checkMissingEmailTemplateLanguage = (emailTypes, emailTemplates, letterTemplates, emailTypesToCheck) => {
        let missingEmailTemplateLanguagesFlags = {};
        let missingEmailTemplateLanguages = {};

        emailTypesToCheck.forEach(emailTypeChecked => {
            const [selectedEmailType] = emailTypes.filter(x => x.name === emailTypeChecked);

            const letterTemplatesWithMissingEmailTemplate = letterTemplates.filter(
                t =>
                    !emailTemplates.some(
                        e =>
                            t.languageId === e?.languageId &&
                            e?.confirmationCategoryId === DEFAULT_CONFIRMATION_TEMPLATE_CATEGORY.id &&
                            e?.emailType?.id === selectedEmailType?.id
                    )
            );

            missingEmailTemplateLanguagesFlags = {
                ...missingEmailTemplateLanguagesFlags,
                [emailTypeChecked]: letterTemplatesWithMissingEmailTemplate?.length > 0 ? true : false
            };

            missingEmailTemplateLanguages = {
                ...missingEmailTemplateLanguages,
                [emailTypeChecked]: letterTemplatesWithMissingEmailTemplate
            };
        });

        setIsEmailTemplateLanguagesMissing(missingEmailTemplateLanguagesFlags);
        setEmailTemplateLanguagesMissing(missingEmailTemplateLanguages);
    };

    useEffect(() => {
        emailTypes && emailTemplates && checkMissingEmailType(emailTypes, emailTemplates);

        emailTypes &&
            emailTemplates &&
            letterTemplates &&
            checkMissingEmailTemplateLanguage(emailTypes, emailTemplates, letterTemplates, EXTERNAL_EMAIL_TYPES);
    }, [emailTypes, emailTemplates, letterTemplates]);

    useEffect(() => {
        setIsConfigValid(configurationsChecks?.isExistingEmailTemplate ?? false);
    }, [configurationsChecks]);

    const checkEmailType = (emailTypeToCheck, missingEmailTemplateTypes) =>
        missingEmailTemplateTypes?.find(e => e.name === emailTypeToCheck.name)?.id ? false : true;

    return (
        <>
            <EmailTemplateAlertSection
                languages={languages}
                isMissingEmailTemplateTypes={isMissingEmailTemplateTypes}
                missingEmailTemplateTypes={missingEmailTemplateTypes}
                isMissingEmailTemplateLanguages={isEmailTemplateLanguagesMissing}
                missingEmailTemplateLanguages={emailTemplateLanguagesMissing}
            />

            <div className={classes.root}>
                {React.Children.toArray(
                    emailTypes.map(x => {
                        let isValidEmailType = checkEmailType(x, missingEmailTemplateTypes);

                        return (
                            <Accordion TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls={`email-type-template-actions${x.id}-content`}>
                                    <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                checked={(isConfigValid && isValidEmailType) ?? false}
                                                color="primary"
                                            />
                                        }
                                        label={x.name}
                                    />
                                </AccordionSummary>

                                <AccordionDetails
                                    children={
                                        <EmailTemplateConfigComponent
                                            currentClusterId={currentClusterId}
                                            currentEmailType={x}
                                            emailTypes={emailTypes}
                                            emailTemplates={emailTemplates}
                                            languages={languages}
                                            confirmationCategories={confirmationCategories}
                                            createTemplate={createEmailTemplate}
                                            updateTemplate={updateEmailTemplate}
                                            deleteTemplate={deleteEmailTemplate}
                                        />
                                    }
                                />
                            </Accordion>
                        );
                    })
                )}
            </div>
        </>
    );
}
