import { ENGAGEMENT_COLUMNS_FILTERS } from '../InitEngagement';
import { buildFilters } from '../../../helpers/EngagementHelper';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { ConfirmationWorkflowProgress } from '../ConfirmationWorkflowProgress';
import React from 'react';
import { FilterTableSpec } from '../../../hooks/useFilterTable';
import { MZRPaginatedTable } from '../../common/MZRPaginatedTable';
import { FilterRowContainer } from '../../common/filtering/FilterRowContainer';
import { FILTER_COMPONENT } from '../../../types/types';
import { DEFAULT_ENGAGEMENT_PAGE_SIZE } from '../../../helpers/TableHelper.js';
import { NotificationIndicator } from '../../common/NotificationIndicator';
import { getLocalizedString } from '../../../i18n/i18Helper';

interface EngagementContentProps {
    handleFilterChange: (filters: Record<string, any>) => void;
    engagement: any;
    confirmationCategories: any;
    workflowActions: any;
    isMultipleSelect?: boolean;
    actions?: any;
    loading: boolean;
    data: any;
    handleRowSelection?: (state: any, rowData: any) => void;
    filterManager: FilterTableSpec;
    confirmationsFilters?: Record<string, any>;
    workflowActionsStatus: any;
}

export const EngagementContent = ({
    handleFilterChange,
    engagement,
    workflowActions,
    confirmationCategories,
    actions,
    loading,
    data,
    handleRowSelection,
    filterManager,
    confirmationsFilters: filters,
    workflowActionsStatus
}: EngagementContentProps) => {
    const intl = useIntl();
    const confirmationsFilters = filters || {};

    const columns = [
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.SELECT,
                    placeholder: intl.formatMessage({
                        id: 'ENGAGEMENT.TABLE.FILTER.CLIENT.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.CLIENT,
                    filters: buildFilters(
                        engagement?.clientCompanies,
                        (company: any) => `${company.name} | ${company.zipCode} ${company.city}`
                    )
                }
            ],
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.CLIENT' }),
            field: 'clientCompany',
            width: 260,
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) =>
                rowData?.clientCompany?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.PLACEHOLDER.CLIENT" />
        },
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.INPUT,
                    placeholder: intl.formatMessage({
                        id: 'ENGAGEMENT.TABLE.FILTER.THIRD_PARTY.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY
                }
            ],
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.THIRDPARTY' }),
            field: 'thirdPartyCompany',
            width: 260,
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) =>
                rowData?.thirdPartyCompany?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.PLACEHOLDER.THIRD_PARTY" />
        },
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.SELECT,
                    placeholder: intl.formatMessage({
                        id: 'ENGAGEMENT.TABLE.FILTER.CATEGORY.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.CATEGORY,
                    filters: buildFilters(confirmationCategories, (category: any) => `${category.name} `)
                }
            ],
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.CATEGORY' }),
            field: 'confirmationCategory',
            width: 100,
            cellStyle: { textAlign: 'center' },
            render: (rowData: any) =>
                rowData?.confirmationCategory?.name ?? <FormattedMessage id="ENGAGEMENT.TABLE.TITLE.UNKNOWN_CATEGORY" />
        },
        {
            filtering: filterManager.isFilteringMode,
            filteringConfig: [
                {
                    component: FILTER_COMPONENT.SELECT,
                    placeholder: intl.formatMessage({
                        id: 'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION,

                    filters: buildFilters(workflowActions, (action: any) => `${action.name} `)
                },
                {
                    component: FILTER_COMPONENT.SELECT,
                    disabled: !confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION],
                    placeholder: intl.formatMessage({
                        id: 'ENGAGEMENT.TABLE.FILTER.WORKFLOW_ACTION.STATUS.PLACEHOLDER'
                    }),
                    filterName: ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION_STATUS,
                    filters: buildFilters(workflowActionsStatus, (status: any) => `${status.name} `)
                }
            ],
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.PROGRESS' }),
            field: 'progress',
            grouping: false,
            render: (rowData: any) => <ConfirmationWorkflowProgress progressData={rowData} />
        },
        {
            filtering: false,
            cellStyle: { textAlign: 'center' },
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.PENDING.DOCUMENTS' }),
            field: 'hasPendingDocuments',
            render: (rowData: any) =>
                !!rowData?.hasPendingDocuments ? (
                    <NotificationIndicator
                        tooltip={getLocalizedString(intl, 'ENGAGEMENT.TABLE.PENDING.DOCUMENTS.INDICATOR.TOOLTIP')}
                    />
                ) : null
        },
        {
            title: intl.formatMessage({ id: 'ENGAGEMENT.TABLE.TITLE.LAST_UPDATE' }),
            field: 'currentStep',
            type: 'date',
            grouping: false,
            width: 170,
            filtering: false,
            render: (rowData: any) => (
                <FormattedTime value={rowData.currentStep.updated} year="numeric" month="short" day="numeric" />
            ),

            cellStyle: { textAlign: 'center' }
        }
    ];

    const updateWorkflowFilter = (filterName: string, value: any) => {
        const defaultStepStatusEntry =
            workflowActionsStatus?.length > 0
                ? {
                      id: workflowActionsStatus[0]?.id,
                      value: workflowActionsStatus[0]?.name
                  }
                : {};

        const defaultWorkflowFilter = [
            { eStepStatus: defaultStepStatusEntry },
            { eStepStatus: defaultStepStatusEntry?.id ?? null }
        ];

        const resetWorkflowFilter = [{ eStepStatus: null }, { eStepStatus: null }];

        switch (filterName) {
            case 'workflowActionId':
                if (!filterManager.filterableColumnsValues?.eStepStatus) {
                    return defaultWorkflowFilter;
                } else if (!value) {
                    return resetWorkflowFilter;
                } else {
                    return [
                        { eStepStatus: filterManager.filterableColumnsValues?.eStepStatus },
                        { eStepStatus: filterManager.filterableColumnsValues?.eStepStatus?.id }
                    ];
                }

            case 'eStepStatus':
                if (!value) {
                    return defaultWorkflowFilter;
                } else {
                    return [{}, {}];
                }

            default:
                return filterManager.filterableColumnsValues?.eStepStatus
                    ? [
                          { eStepStatus: filterManager.filterableColumnsValues?.eStepStatus },
                          { eStepStatus: filterManager.filterableColumnsValues?.eStepStatus?.id }
                      ]
                    : resetWorkflowFilter;
        }
    };

    const handleConfirmationsFilterChange = (filterName: string, value: any) => {
        const [updateWorkflowColumns, updateWorkflowValues] = updateWorkflowFilter(filterName, value) as Array<any>;
        filterManager.onFilterTableChanged(filterName, value, {
            columns: updateWorkflowColumns,
            values: updateWorkflowValues
        });
    };

    return (
        <>
            <MZRPaginatedTable
                pageSize={DEFAULT_ENGAGEMENT_PAGE_SIZE}
                actions={actions}
                isFiltering={filterManager.isFilteringMode}
                columns={columns}
                onRowClick={handleRowSelection}
                loading={loading}
                data={data}
                handleFilterChange={handleFilterChange}
                components={{
                    FilterRow: (rowProps: any) => (
                        <FilterRowContainer
                            rowProps={rowProps}
                            handleFilterChange={handleConfirmationsFilterChange}
                            selectedFilters={filterManager.filterableColumnsValues}
                        />
                    )
                }}
            />
        </>
    );
};
