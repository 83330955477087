export const hasThirdParty = (confirmation: any) => {
    return !!confirmation?.thirdPartyCompany?.id;
};

export const hasThirdPartyContacts = (confirmation: any) => {
    return confirmation?.thirdPartyContacts?.length > 0;
};

export const hasLetterhead = (confirmation: any) => {
    return !!confirmation?.clientCompany?.letterHead;
};
