import { Box } from '@mui/material';

import { useIntl } from 'react-intl';
import React from 'react';
import { BaseToolbarItems } from '../../../common/BaseToolbarItems';
import { UIActionButton } from '../../../common/UIActionButton';
import { toolbarStyle } from '../../../../styles/toolbar/ToolbarStyle';
import { getLocalizedString } from '../../../../i18n/i18Helper';
import { UIBackButton } from '../../../common/UIBackButton';

export const ConfirmationToolbarItems = ({
    handleBack,
    handleSave,
    handleDelete,
    loading,
    currentMenuActions,
    handleStepAction
}) => {
    const intl = useIntl();

    return (
        <Box component="span" sx={toolbarStyle.container}>
            <UIBackButton onClick={handleBack} />

            <BaseToolbarItems
                style={{
                    save: {
                        display: 'secondary'
                    }
                }}
                handleSave={handleSave}
                isResetHidden={true}
                handleDelete={handleDelete}
                isDeleteHidden={false}
            />

            {currentMenuActions &&
                React.Children.toArray(
                    currentMenuActions.map(action => {
                        const keyMenuName = action.text.replace(' ', '').toUpperCase();
                        return (
                            action?.isMenu && (
                                <UIActionButton
                                    title={getLocalizedString(
                                        intl,
                                        'CONFIRMATION.BUTTON.#KEY_MENU_NAME.LABEL'.replace(
                                            '#KEY_MENU_NAME',
                                            keyMenuName
                                        )
                                    )}
                                    startIcon={
                                        loading && action.isHighlight && <span className="mr-6 spinner spinner-white" />
                                    }
                                    display={action.isHighlight ? 'primary' : 'secondary'}
                                    sx={toolbarStyle.button}
                                    disabled={loading}
                                    onClick={() => handleStepAction(action)}
                                />
                            )
                        );
                    })
                )}
        </Box>
    );
};
