import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { isAuthorisedProfile } from '../../auth/UserHelper';
import { ROUTES } from '../../../navigation/Routes';
import './userProfileDropdown.css';
import { shortUserLabel } from '../../../helpers/ContactHelper';
// import { accountRepository } from '../../../kit/repository';

import { Menu, Box, Avatar, Divider } from '@mui/material';
import { UIText } from '../../common/UIText';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { UIActionButton } from '../../common/UIActionButton';
import { PROFILE } from '../../../types/types';
import inMemoryTokenManager from '../../../kit/network/inMemoryToken';

class UserProfileDropdownComponent extends Component {
    state = {
        profileMenuOptions: [],
        selectedProfile: null,
        anchorEl: null
    };

    componentDidMount() {
        const { user, intl } = this.props;
        let profileMenuOptions = [];
        if (isAuthorisedProfile(PROFILE.AUDITOR, user.profil)) {
            profileMenuOptions.push({
                title: intl.formatMessage({ id: 'NAVBAR.SELECT.AUDITOR' }),
                link: ROUTES.HOME,
                profile: PROFILE.AUDITOR
            });
        }

        if (isAuthorisedProfile(PROFILE.ADMIN, user.profil)) {
            profileMenuOptions.push({
                title: intl.formatMessage({ id: 'NAVBAR.SELECT.ADMIN' }),
                link: ROUTES.COUNTRY_ADMIN,
                profile: PROFILE.ADMIN
            });
        }

        if (isAuthorisedProfile(PROFILE.POSTIE, user.profil)) {
            profileMenuOptions.push({
                title: intl.formatMessage({ id: 'NAVBAR.SELECT.SUPPORT' }),
                link: ROUTES.COUNTRY_POSTIE,
                profile: PROFILE.POSTIE
            });
        }

        if (isAuthorisedProfile(PROFILE.CLIENT, user.profil)) {
            profileMenuOptions.push({
                title: intl.formatMessage({ id: 'NAVBAR.SELECT.CLIENT' }),
                link: ROUTES.HOME,
                profile: PROFILE.CLIENT
            });
        }

        const { defaultPath, selectedProfileRoute } = this.props;
        this.setState({ profileMenuOptions: profileMenuOptions, selectedProfile: selectedProfileRoute || defaultPath });
    }

    handleChangeProfile = option => {
        this.setState({ selectedProfile: option.link });
        const { setSessionProfile, setSelectedProfileRoute } = this.props;
        setSessionProfile(option.profile);
        setSelectedProfileRoute(option.link);
    };

    handleOpenMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    handleLogout = async () => {
        const { logoutUser, resetApp } = this.props;
        logoutUser();
        resetApp();

        if (inMemoryTokenManager.isTokenAvailable()) inMemoryTokenManager.clearToken();
        
        // TODO: uncomment when API endpoint is ready
        // const result = await accountRepository.logout();
        
        // if (result.isSuccess) {
        //     logoutUser();
        //     resetApp();
        // }
    };

    render() {
        const { user, intl, sessionProfile } = this.props;
        const { profileMenuOptions, anchorEl } = this.state;

        return (
            <>
                <Box onClick={this.handleOpenMenu} sx={{ marginX: 0.5, marginTop: '1.8rem', marginRight: '.25rem' }}>
                    <UIText
                        className=".text-sonic-silver opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1"
                        text={getLocalizedString(intl, 'NAVBAR.WELCOME')}></UIText>

                    <UIText
                        className=".text-sonic-silver opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4"
                        text={!!user.fullname ? user.fullname : user.email}></UIText>
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseMenu}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <Box>
                        <div className="d-flex align-items-center p-6 rounded-top">
                            <div className="symbol symbol-md mr-3 flex-shrink-0">
                                <Avatar
                                    className="avatar bg-primary"
                                    alt={!!user.fullname ? user.fullname : user.email}>
                                    {shortUserLabel(user)}
                                </Avatar>
                            </div>

                            <div className="text-dark m-0 flex-grow-1 flex-wrap mr-3 font-size-h6">{user.email}</div>
                        </div>
                    </Box>

                    <Box>
                        {profileMenuOptions.length > 1 && (
                            <>
                                <Divider />
                                <Box className="d-flex align-items-center p-6 rounded-top">
                                    <Box className="text-dark m-0 flex-grow-1 mr-3 font-size-h6">
                                        <FormattedMessage id={'NAVBAR.SWITCH.PROFILE'} />
                                        <ul className="DropDownList">
                                            {profileMenuOptions.map(option => (
                                                <li
                                                    className={`ListItem${
                                                        sessionProfile === option.profile ? ' selected' : ''
                                                    }`}
                                                    key={option.link}
                                                    onClick={() => this.handleChangeProfile(option)}>
                                                    <Link to={option.link}>{option.title}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                        <UIActionButton display="secondary" onClick={this.handleLogout} style={{ minHeight: '20px' }}>
                            <FormattedMessage id="NAVBAR.SIGNOUT" />
                        </UIActionButton>
                    </Box>
                </Menu>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    logoutUser: () => {
        dispatch(actions.resetUser());
        dispatch(actions.flushCurrentCluster());
    },
    resetApp: () => {
        dispatch(actions.resetApp());
    },
    setSelectedProfileRoute: selectedProfile => {
        dispatch(actions.setSelectedProfileRoute(selectedProfile));
    },
    setSessionProfile: profile => {
        dispatch(actions.setSessionProfile(profile));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        selectedProfileRoute: state.app.selectedProfileRoute,
        defaultPath: state.app.defaultPath,
        sessionProfile: state.app.sessionProfile
    };
};
export const ProfileTopBarItem = injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserProfileDropdownComponent));
