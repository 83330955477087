import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as actions from '../../store/actions';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { withEngagement } from '../../enhancers/withEngagement';
import CreateEngagement from '../../components/engagement/CreateEngagement';
import { Loader } from '../../components/loader/Loader';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PROFILE } from '../../types/types';
import { CompanyService, RobotConfigurationService } from '../../services';
import * as engagementService from '../../services/EngagementService';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import { COMPANY_TYPE } from '../../kit/utils/constants';
import { ENGAGEMENT_MEMBER_ROLE } from '../../components/engagement/InitEngagement';
import { ClientCompanyContext } from '../../context';


/**
 * Create engagement component for user auditor.
 * Setup with 4 steps: engagement information, client companies, client contacts and members.
 */
class CreateEngagementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isEditable: true,
            checkSessionResults: [],
            clientCompanies: []
        };
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    companyManager = CompanyService({
        intl: this.props.intl,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        target: [COMPANY_TYPE.CLIENT]
    });

    robotConfigManager = RobotConfigurationService({
        intl: this.props.intl,
        user: this.props.user,
        checkSession: this.props.checkSession,
        addToastMessage: this.props.addToastMessage,
        setLoading: this.setLoading,
        callback: async (isSuccess, data, _) => isSuccess && this.setState({ robotConfig: data?.parsedValue })
    });

    createEngagement = async engagement => {
        const { performCreateEngagement, history, resetEngagement } = this.props;
        performCreateEngagement({
            engagement: engagement,
            setLoading: this.setLoading,
            successCallback: async () => {
                resetEngagement();
                history.goBack();
            }
        });
    };

    async componentDidMount() {
        const { currentClusterId } = this.props;
        await this.robotConfigManager.fetchCluster(currentClusterId);
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    populateEngagementMembers = members => {
        const { user } = this.props;
        let updatedMembers = [...members];
        // add current user as a manager to the engagement
        if (!members.find(member => member.email === user.email)) {
            updatedMembers = [
                ...updatedMembers,
                {
                    email: user.email,
                    engagementRole: ENGAGEMENT_MEMBER_ROLE.ADMIN
                }
            ];
        }

        return updatedMembers;
    };

    handleSearchEngagements = async engagementsFilters => {
        const { checkSession, addToastMessage, intl } = this.props;

        const result = await engagementService.fetchAll({
            intl,
            setLoading: this.setLoading,
            checkSession,
            addToastMessage,
            filters: {
                ...engagementsFilters,
                userProfile: PROFILE.AUDITOR
            },
            callback: null
        });

        return result;
    };

    render() {
        const {
            user,
            activeStepPosition,
            newEngagement,
            resetEngagement,
            saveEngagement,
            saveCreationActiveStepPosition
        } = this.props;

        if (!user.profil) {
            return <Loader />;
        } else {
            if (isAuthorisedProfile(PROFILE.AUDITOR, user.profil)) {
                return (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <ClientCompanyContext.Provider
                                    value={{
                                        searchCompany: this.companyManager.searchGlobalClients
                                    }}>
                                    <CreateEngagement
                                        fetchEngagements={this.handleSearchEngagements}
                                        user={user}
                                        loading={this.state.loading}
                                        engagementCodeRegEx={this.state.robotConfig?.engagementCodePattern}
                                        engagementCodeMessage={this.state.robotConfig?.description}
                                        newEngagement={{
                                            ...newEngagement,
                                            clusterId: this.props.currentClusterId,
                                            appUserEngagements: this.populateEngagementMembers(
                                                newEngagement.appUserEngagements
                                            )
                                        }}
                                        saveEngagement={saveEngagement}
                                        resetEngagement={resetEngagement}
                                        initialActiveStep={activeStepPosition}
                                        saveActiveStepPosition={saveCreationActiveStepPosition}
                                        createEngagement={this.createEngagement}
                                    />
                                </ClientCompanyContext.Provider>
                            </div>
                        </div>
                    </>
                );
            } else {
                return <PageNotFound {...this.props} />;
            }
        }
    }
}

CreateEngagementComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool,
    checkSessionResults: PropTypes.array,
    currentClusterId: PropTypes.string.isRequired
};

CreateEngagementComponent.defaultProps = {
    loading: false,
    isEditable: true
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    },
    saveEngagement: engagement => {
        dispatch(actions.setEngagement(engagement));
    },
    saveCreationActiveStepPosition: position => {
        dispatch(actions.setActiveStepPosition(position));
    },
    resetEngagement: () => {
        dispatch(actions.resetEngagement());
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        newEngagement: state.engagement.newEngagement,
        activeStepPosition: state.engagement.activeStepPosition,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const createEngagementComponent = compose(
    injectIntl,
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(withEngagement(CreateEngagementComponent));
