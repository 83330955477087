const hasSameIds = (idName, company1, company2) => {
    return company1[idName] && company2[idName] && company1[idName] === company2[idName];
};

const isSameCompany = (company1, company2) => {
    return company1 && company2
        ? hasSameIds('id', company1, company2) ||
              hasSameIds('companyId', company1, company2) ||
              hasSameIds('internalId', company1, company2)
        : false;
};

export const getExistingCompanyWithinEngagement = (engagement, company) => {
    return engagement.clientCompanies?.find(currentCompany => isSameCompany(currentCompany, company));
};
