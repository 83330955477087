import { useState } from 'react';
import XLSX from 'xlsx';
import { listToArrayOfObjects } from '../helpers/BaseHelper';
import { checkMimeType, UPLOAD_TYPES } from '../helpers/ItemHelper';
import '../styles/excel-2007.css';

const HIDDEN_SHEET_NAME = '%hidden';

export const useXLSXFile = () => {
    const [workbook, setWorkbook] = useState([]);
    const [sheetNamesList, setSheetNamesList] = useState([]);
    const [configurationSheet, setConfigSheet] = useState(null);
    const [columnsNames, setColumnsNames] = useState([]);
    const [uploadedRows, setUploadedRows] = useState([]);
    const [headers, setHeaders] = useState([]);

    const handleUploadExcelFile = file => {
        try {
            const reader = new FileReader();
            reader.onload = event => {
                const binaryString = event.target.result;
                const workbook = XLSX.read(binaryString, { type: 'binary' });
                setWorkbook(workbook);
                const visibleSheets = workbook?.SheetNames?.filter(sheet => !sheet.includes(HIDDEN_SHEET_NAME));
                setSheetNamesList(listToArrayOfObjects(visibleSheets));
                const [hiddenSheetName] = workbook?.SheetNames?.filter(sheet => sheet.includes(HIDDEN_SHEET_NAME));
                if (workbook?.Sheets && hiddenSheetName) {
                    setConfigSheet(workbook.Sheets[hiddenSheetName]);
                }
            };

            reader.readAsBinaryString(file);
        } catch (e) {
            console.log(e);
        }
    };

    const reset = () => {
        setColumnsNames([]);
        setUploadedRows([]);
        setHeaders([]);
    };

    const onFileUploaded = files => {
        reset();
        let uploadedFile;
        if (files.length > 0 && checkMimeType(files, UPLOAD_TYPES.EXCEL)) {
            handleUploadExcelFile(files[0]);
            uploadedFile = files[0];
        }

        return uploadedFile;
    };

    const onSheetSelected = (sheetName, headerIndex, size) => {
        reset();
        if (workbook?.Sheets) {
            const worksheet = workbook.Sheets[sheetName];

            // Control if the range of imported table exist
            if (worksheet['!ref']) {
                const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false, blankrows: false });
                const columns = jsonSheet[headerIndex] ?? [];

                columns.unshift('');
                setColumnsNames(listToArrayOfObjects(columns, 0));
                setHeaders(jsonSheet.slice(0, Math.min(headerIndex + 1, jsonSheet.length)));
                const rows = jsonSheet.slice(headerIndex + 1, Math.min(headerIndex + 1 + size, jsonSheet.length));
                setUploadedRows(rows);
            }
        }
    };

    // ex sections: Client Information, Third-party Information, Confirmation Information
    const groupColumnsBySection = (sections, subsections, columns) => {
        let groupedColumns = {};

        if (sections?.length > 0) {
            // first define limits of every section ex {Client Information: {min: 1, max: 5}}
            const sectionsIndexes = sections.reduce((acc, sectionName, index) => {
                if (!acc[sectionName]) {
                    acc[sectionName] = { min: index + 1 }; //TODO explain why +1
                }
                acc[sectionName] = { ...acc[sectionName], max: index + 1 };
                return acc;
            }, {});

            // then for every section, get the list of columns
            sectionsIndexes &&
                Object.keys(sectionsIndexes).forEach(sectionName => {
                    const max = sectionsIndexes[sectionName].max;
                    const min = sectionsIndexes[sectionName].min;
                    groupedColumns[sectionName] = {
                        min: min,
                        max: max,
                        subsections: subsections.slice(min - 1, max), // TODO explain why -1
                        columns: columns.slice(min, max + 1)
                    };
                });
        } else {
            groupedColumns = { '': { columns: columns, subsections: [], min: 0, max: columns.length - 1 } };
        }

        // ex: {Client Information: {min: 1, max: 5, columns: [Name, E-mail, ...]}}
        return groupedColumns;
    };

    const retrieveCellContent = (worksheet, lineIndex, columnIndex) => {
        let content = null;
        if (worksheet) {
            // Control if the range of imported table exist
            if (worksheet['!ref']) {
                const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false, blankrows: false });
                const line = jsonSheet[lineIndex];
                content = line ? line[columnIndex] : null;
            }
        }

        return content;
    };

    return {
        sheetNamesList: sheetNamesList,
        columnsNames: columnsNames,
        onSheetSelected: onSheetSelected,
        onFileUploaded: onFileUploaded,
        onUploadExcelFile: handleUploadExcelFile,
        headers: headers,
        uploadedRows: uploadedRows,
        groupColumnsBySection: groupColumnsBySection,
        configurationSheet: configurationSheet,
        retrieveCellContent: retrieveCellContent
    };
};
