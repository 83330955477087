import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { DocumentInfoComponent } from './DocumentInfo';
import { makeStyles } from '@material-ui/core/styles';
import { DocumentTableSelect, ITEM_DOCUMENT_TYPE } from './index';
import { UIDialog } from '../common/UIDialog';
import { DocumentViewerContent } from './DocumentViewerContent';
import { DocumentTypeSelectField, ConfirmationSelectField, EngagementSelectField } from '../engagement';
import { CONFIRMATION_COLUMNS_FILTERS } from '../confirmation/common';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(0.5)
    },
    textField: {
        width: '100%',
        height: '5%'
    }
}));

interface DocumentPreviewActionsProps {
    isEditable;
    info;
    documentTypes;
    menuEngagements?;
    handleDocumentSubmit;
    sbmtDocRef;
    handleFetchMenuConfirmations;
}

const DocumentPreviewActions = ({
    isEditable = true,
    handleFetchMenuConfirmations,
    info,
    documentTypes,
    menuEngagements,
    handleDocumentSubmit,
    sbmtDocRef
}: DocumentPreviewActionsProps) => {
    const intl = useIntl();
    const classes = useStyles();

    const [menuConfirmations, setMenuConfirmations] = useState(info?.menuConfirmations);
    const [document, setDocument] = useState<any>(info);

    const handleEngagementChanged = async (data: any) => {
        const { engagementId } = data;
        setDocument({ ...document, engagementId: engagementId });

        // the engagement has been changed in the engagement dropdown => fetch its confirmations and populate the confirmations dropdown
        handleFetchMenuConfirmations &&
            setMenuConfirmations(
                await handleFetchMenuConfirmations({
                    [CONFIRMATION_COLUMNS_FILTERS.ENGAGEMENT_ID]: engagementId,
                    [CONFIRMATION_COLUMNS_FILTERS.CONFIRMATION_NAME_OR_CODE]: null
                })
            );
    };

    const handleConfirmationChanged = async (data: any) => {
        const { confirmationId, engagementId } = data;
        setDocument({ ...document, confirmationId: confirmationId, engagementId: engagementId });
    };

    const handleConfirmationInputChange = input => {
        if (input && input?.length >= 4) {
            handleFetchMenuConfirmations({
                [CONFIRMATION_COLUMNS_FILTERS.ENGAGEMENT_ID]: info?.engagementId,
                [CONFIRMATION_COLUMNS_FILTERS.CONFIRMATION_NAME_OR_CODE]: input
            }).then(menuConfirmations => {
                setMenuConfirmations(menuConfirmations);
            });
        }
    };

    return (
        <>
            {isEditable && document && (
                <DocumentInfoComponent
                    classes={classes}
                    intl={intl}
                    document={document}
                    renderSelectDocumentType={
                        <DocumentTypeSelectField
                            name="documentType"
                            options={documentTypes}
                            disabled={!documentTypes}
                            hidden={!documentTypes}
                        />
                    }
                    renderSelectEngagement={
                        <DocumentTableSelect
                            field="engagementId"
                            item={document}
                            handleUpdate={handleEngagementChanged}
                            component={EngagementSelectField}
                            options={menuEngagements || []}
                            sx={{ marginBottom: '5px' }}
                            disabled={!menuEngagements}
                            hidden={!menuEngagements}
                        />
                    }
                    renderSelectConfirmation={
                        <DocumentTableSelect
                            field="confirmationId"
                            item={document}
                            handleUpdate={handleConfirmationChanged}
                            component={ConfirmationSelectField}
                            onInputChange={handleConfirmationInputChange}
                            options={menuConfirmations || []}
                            disabled={!menuEngagements}
                            hidden={!menuEngagements}
                        />
                    }
                    handleSubmit={handleDocumentSubmit}
                    sbmtDocRef={sbmtDocRef}
                />
            )}
        </>
    );
};

const CLOSE_PREVIEW_DIALOG_VALUE = 0;
const forbiddenSaveItem = [ITEM_DOCUMENT_TYPE.LETTER_TEMPLATE];

interface DocumentPreviewProps {
    documentTypes;
    menuEngagements?;
    isDownloadable?;
    isEditable?;
    handleFetchMenuConfirmations?;
    children?;
    updateDocumentAPI?;
    shouldOpenPreview;
    updateOpenPreview;
    fetchDocumentFileAPI;
    item: any | null;
    setLoading?;
}

const itemFilters = { pdf: true };

export function DocumentPreview({
    updateOpenPreview,
    shouldOpenPreview,
    documentTypes,
    menuEngagements,
    isDownloadable = true,
    isEditable = true,
    handleFetchMenuConfirmations,
    children,
    updateDocumentAPI,
    fetchDocumentFileAPI,
    item,
    setLoading
}: DocumentPreviewProps) {
    const sbmtDocRef = useRef();
    const [documentInfo, setDocumentInfo] = useState<any>(item);
    const [documentBlob, setDocumentBlob] = useState<Blob | null>(null);
    const documentFileName = item?.fileName ?? item?.name;

    const [isValidDocument, setIsValidDocument] = useState<boolean>(false);

    useEffect(() => {
        if (item?.id) {
            setLoading && setLoading(true);
            fetchDocumentFile(item?.id).then(isFetched => {
                setLoading && setLoading(false);
                setIsValidDocument(isFetched);
            });
        }

        setDocumentInfo(item);
    }, [item?.id]);

    const fetchDocumentFile = async (itemId: string) => {
        const result = await fetchDocumentFileAPI(itemId, itemFilters);
        result.isSuccess && setDocumentBlob(result.body);
        return result.isSuccess;
    };

    const handleClosePreview = () => {
        setDocumentBlob(null);
        setIsValidDocument(false);
        updateOpenPreview(CLOSE_PREVIEW_DIALOG_VALUE);
    };

    const documentPreviewClose = () => {
        if (sbmtDocRef && sbmtDocRef.current) {
            // @ts-ignore
            sbmtDocRef.current.saveForm();
        }
    };

    const handleDocumentSubmit = async (values: any) => {
        if (updateDocumentAPI) {
            const result = await updateDocumentAPI(values);
            result?.isSuccess && handleClosePreview();
        } else {
            handleClosePreview();
        }
    };

    // Not allow save for letter template item.
    const saveAllowed = !forbiddenSaveItem.includes(documentInfo?.documentType);

    return !isValidDocument || !shouldOpenPreview ? null : (
        <UIDialog
            open={isValidDocument && shouldOpenPreview}
            titleId={{
                id: 'DOCUMENT.VIEWER.CARDHEADER.FILE.NAME.TITLE',
                values: { documentName: documentInfo?.fileName ?? documentInfo?.name }
            }}
            onClose={handleClosePreview}
            onSave={isEditable && saveAllowed ? documentPreviewClose : undefined}>
            <DocumentViewerContent
                id="pdfViewer"
                enableDownload={isDownloadable}
                fileName={documentFileName}
                children={children}
                actions={
                    <DocumentPreviewActions
                        info={documentInfo}
                        sbmtDocRef={sbmtDocRef}
                        documentTypes={documentTypes}
                        menuEngagements={menuEngagements}
                        handleDocumentSubmit={handleDocumentSubmit}
                        isEditable={isEditable}
                        handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                    />
                }
                document={documentBlob}
            />
        </UIDialog>
    );
}
