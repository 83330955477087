import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { getDefaultRouteByProfile } from '../../navigation/RouterHelpers';

export const PageNotFoundComponent = props => {
    const { user, logoutUser, isUserConnected } = props;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h1>
                <FormattedMessage id="PAGE_NOT_FOUND.TITLE" />
            </h1>
            {isUserConnected ? (
                <p>
                    <FormattedMessage id="PAGE_NOT_FOUND.PARAGRAPH_PART_1" />
                    <button style={linkStyle} onClick={() => logoutUser()}>
                        <FormattedMessage id="PAGE_NOT_FOUND.PARAGRAPH_PART_2_LINK" />
                    </button>
                    <FormattedMessage id="PAGE_NOT_FOUND.PARAGRAPH_PART_3" />
                    <button style={linkStyle} onClick={() => window.history.back()}>
                        <FormattedMessage id="PAGE_NOT_FOUND.PARAGRAPH_PART_4_LINK" />
                    </button>
                    <FormattedMessage id="PAGE_NOT_FOUND.PARAGRAPH_PART_5" />
                    <button style={linkStyle} onClick={() => window.location.replace(getDefaultRouteByProfile(user))}>
                        <FormattedMessage id="PAGE_NOT_FOUND.PARAGRAPH_PART_6_LINK" />
                    </button>
                </p>
            ) : (
                <p>
                    <FormattedMessage id="PAGE_NOT_FOUND.PARAGRAPH_PART_3" />
                    <button style={linkStyle} onClick={() => window.history.back()}>
                        <FormattedMessage id="PAGE_NOT_FOUND.PARAGRAPH_PART_4_LINK" />
                    </button>
                </p>
            )}
        </div>
    );
};

const linkStyle = { color: '#6993FF', textDecoration: 'none', backgroundColor: 'transparent', border: 'none' };

const mapStateToProps = state => {
    return {
        user: state.account.user,
        isUserConnected: state.account.isUserConnected
    };
};

const mapDispatchToProps = dispatch => ({
    logoutUser: () => {
        dispatch(actions.resetUser());
        dispatch(actions.flushCurrentCluster());
    }
});

export const PageNotFound = compose(connect(mapStateToProps, mapDispatchToProps))(injectIntl(PageNotFoundComponent));
