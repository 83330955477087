export const settings = {
    'SETTINGS.TITLE': 'Configurations',
    'SETTINGS.CARD.TITLE': 'Mission {engagementName} - Configurations',
    'SETTINGS.BUTTON.LABEL.BACK': 'Retour',
    'SETTINGS.BUTTON.LABEL.RESET': 'Reinitialiser',
    'SETTINGS.BUTTON.LABEL.SAVE': 'Sauvegarder',
    'SETTINGS.BUTTON.LABEL.NEW': 'NOUVELLE ENTREPRISE',
    'SETTINGS.BUTTON.LABEL.ADD': 'AJOUTER UN MEMBRE',
    'SETTINGS.SECTION.LABEL.INFORMATION': 'INFORMATIONS',
    'SETTINGS.SECTION.LABEL.CLIENTS': 'ENTITES',
    'SETTINGS.SECTION.LABEL.CONTACTS': 'CONTACTS',
    'SETTINGS.SECTION.LABEL.LETTERHEADS': 'PAPIER A EN-TETE',
    'SETTINGS.SECTION.LABEL.AUDITORS': 'AUDITEURS',
    'SETTINGS.SECTION.LABEL.THIRD_PARTIES': 'TIERS',
    'SETTINGS.ENGAGEMENT.EDIT.VALIDATION.REQUIRED_FIELD': 'Information manquantes',
    'SETTINGS.ENGAGEMENT.EDIT.VALIDATION.REQUIRED_FIELD.EMAIL': 'Une adresse électronique est requise',
    'SETTINGS.MEMBER.RIGHT.LABEL': 'Admin',
    'SETTINGS.MEMBER.RIGHT.HINT':
        'Le membre administrateur peut supprimer d\'autres membres de son équipe (standard ou administrateur) et doit valider la demande du client avant de l\'envoyer.',
    'SETTINGS.MEMBER.EMAIL.PLACEHOLDER': "Ajouter l'adresse électronique d'un memebre",
    'SETTINGS.ACTION.ARCHIVE': 'Archiver',
    'SETTINGS.ACTION.UNARCHIVE': 'Desarchiver',
    'SETTINGS.SECTION.CONTACT.ACTION.INVITE': 'Sauvegarder et Inviter',
    'SETTINGS.SECTION.CONTACT.RIGHTS.SELECT.ALL': 'Sélectionner tout',
    'SETTINGS.SECTION.CONTACT.MANAGE.ACCESS.MESSAGE':
        '{contact} a été invité à Forvis Mazars Confirmations. Utilisez la section ci-dessous pour gérer son accès à cet engagement ou révoquer l\'accès à la plateforme.',
    'SETTINGS.SECTION.CONTACT.INVITE.MESSAGE':
        "{contact} sera invité à Forvis Mazars Confirmations. Utilisez la section ci-dessous pour gérer leurs droits d'accès à cette mission. \nLe client invité devra créer un mot de passe conformément aux règles de sécurité requises pour accéder à Forvis Mazars Confirmations.'",
    'SETTINGS.SECTION.CONTACT.RESET.PASSWORD': 'Reinitialiser le mot de passe',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS': 'Révoquer l\'accès',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.CURRENT.ENGAGEMENT': 'Révocation de la mission actuelle',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.ALL.ENGAGEMENTS': 'Révoquer toutes les missions',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.DIALOG.TITLE': 'Gérer l\'accès aux contacts',
    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.DIALOG.MESSAGE':
        'Le contact actuel a déjà été invité sur la plateforme, souhaitez-vous révoquer son accès à la mission actuel ou à tous les autres missions où il est concerné ?',
    'SETTINGS.LETTERHEAD.ALERT.MISSING.MESSAGE': 'L\'une des entités n\'a pas de papier à en-tête, veuillez en télécharger un.',
    'SETTINGS.LETTERHEAD.SECTION.CREATE.DIALOG.TITLE': 'Uploader un papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.ALERT.SAVE.REQUIRED.MESSAGE':
        'Veuillez sauvegarder votre mission avant de télécharger le nouveau papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.BUTTON.NEW.LABEL': 'Créer',
    'SETTINGS.LETTERHEAD.SECTION.BUTTON.CREATE': 'Uploader un papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.CREATE.SUCCESS': 'Papier à en-tête créé',
    'SETTINGS.LETTERHEAD.SECTION.CREATE.ERROR': 'Échec lors de la création du papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.DELETE.SUCCESS': 'Papier à en-tête supprimé',
    'SETTINGS.LETTERHEAD.SECTION.DELETE.ERROR': 'Échec lors de la suppresion du papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.CREATE.TITLE': 'Créer papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.DETAILS.TITLE': 'Details du papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.FETCH.ERROR': 'Échec lors de l\'accès du papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.COMPANY': 'Entreprise',
    'SETTINGS.LETTERHEAD.SECTION.COMPANY_PLACEHOLDER': 'Nom de l\'entreprise',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.MESSAGE':
        "Veuillez télécharger le papier à en-tête de l'entité en suivant les étapes ci-dessous :",
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.MESSAGE.DETAIL':
        "1- Obtenez de votre client un document vierge (format A4) contenant l'en-tête de son entreprise \n2- S'il s'agit d'un document Word, le document doit être converti en format .jpg, .jpeg, .png ou .pdf (tout en conservant un format A4) \n3- Une fois le nouveau format généré, téléchargez le document obtenu en cliquant sur le bouton upload \n4- Seuls les documents avec une extension . jpg, .jpeg, .png ou .pdf sont acceptés \n\n- Si votre client ne souhaite pas communiquer l'en-tête de son entité, veuillez cocher la case `Ne pas utiliser de papier à en-tête` \n\n- Pour plus de détails, se référer au contenu de la formation.",
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.FORMAT.SAMPLE.TITLE': 'Exemple de papier à en-tête Mazars',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.ACTION.LABEL': 'Ne pas utiliser de papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.ENTITIES': 'Entités',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.ACTIONS': 'Actions',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.HEADER.STATE': 'Status',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.SUBTITLE':
        'Veuillez télécharger le papier à en-tête du client ou cocher l\'option "ne pas utiliser de papier à en-tête" pour chaque entité.',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.TITLE': 'Papier à en-tête',
    'SETTINGS.LETTERHEAD.SECTION.LETTERHEAD.ACTIVATE.ACTION.TOOLTIP':
        'Activer ou désactiver le papier à en-tête pour la génération de la lettre de circularisation de l\'entité',
    'SETTINGS.UPDATE.ENGAGEMENT.ERROR': 'Échec lors de la mise à jour de la mission',
    'SETTINGS.UPDATE.ENGAGEMENT.SUCCESS': 'Configurations de la mission mises à jour',
    'SETTINGS.ACCESS.ENGAGEMENT.ERROR': 'Échec lors de l\'accès à la mission',
    'SETTINGS.ACCESS.COMPANIES.ERROR': 'Échec lors de l\'accès aux entreprises',
    'SETTINGS.CLIENT.CONTACT.INVITE.SUCCESS': 'Invitation envoyé à l\'utilisateur',
    'SETTINGS.CLIENT.CONTACT.INVITE.ERROR': 'Échec lors de l\'envoi de l\'invitation à l\'utilisateur',
    'SETTINGS.CLIENT.CONTACT.REVOKE.ACCESS.SUCCESS': 'Accès utilisateur révoqué',
    'SETTINGS.CLIENT.CONTACT.REVOKE.ACCESS.ERROR': 'Échec lors de la révocation de l\'accès utilisateur',
    'SETTINGS.CLIENT.CONTACT.RESET.PASSWORD.SUCCESS': 'Mot de passe utilisateur réinitialisé',
    'SETTINGS.CLIENT.CONTACT.RESET.PASSWORD.ERROR': 'Échec lors de la reinitialisation du mot de passe utilisateur',
    'SETTINGS.CLIENT.CONTACT.INVITE.LAST.CONNECTION': 'Dernière connection {date}'
};
