import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { StorageConfigWrapper } from '../../components/setup';
import { fetchData, fetchStorage } from '../../services';

class StorageSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };
    }

    async componentDidMount() {
        await fetchData(this, [() => fetchStorage(this)]);
    }

    render() {
        const { storageConfigurations, hasValidConfig, setIsStorageConfigExpanded } = this.props;
        return (
            <>
                <StorageConfigWrapper
                    storageConfigurations={storageConfigurations}
                    handleDmsConfig={this.handleStorageConfig}
                    hasValidConfig={hasValidConfig}
                    setIsStorageConfigExpanded={setIsStorageConfigExpanded}
                />
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
    };
};

export const StorageSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(StorageSetupComponent));
