import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DocumentTableComponent } from '../document';
import { initItem } from '../document';
import { PROFILE } from '../../types/types';
import { UploadDocumentsComponent } from '../common/UploadDocumentsComponent';
import { UPLOAD_TYPES } from '../../helpers';
import { useDeleteItem } from '../../hooks/useEntityActionPopup';
import { initPaginatedObject } from '../common/InitPaginatedObject';
import { Grid } from '@mui/material';
import { UIActionButton } from '../common/UIActionButton';
import { getLocalizedString } from '../../i18n/i18Helper';
import { toolbarStyle } from '../../styles/toolbar/ToolbarStyle';
import { DocumentsToolbar } from '../document/DocumentsToolbar';
import { ItemContext } from '../../context';

function Support(props) {
    const { intl, user, loading, paginatedItems, menuEngagements, handleFetchMenuConfirmations, documentTypes } = props;
    const { fetchItemFile, fetchItemInfo, uploadItems, updateItem, deleteItem, downloadItem, filterItems } = useContext(
        ItemContext
    );
    const [currentPaginatedDocuments, setCurrentPaginatedDocuments] = useState(paginatedItems);
    const [isRecycleBinDisplay, setIsRecycleBinDisplay] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(initItem);

    const { delete: DeleteDocumentComponent, handleDeleteClick } = useDeleteItem({
        user: user,
        deleteAPI: deleteItem,
        deleteItemCallback: () => {}
    });

    useEffect(() => {
        setCurrentPaginatedDocuments(initPaginatedObject);
        filterItems({ isTrashed: isRecycleBinDisplay });
    }, [isRecycleBinDisplay]);

    useEffect(() => {
        setCurrentPaginatedDocuments(paginatedItems);
    }, [paginatedItems]);

    const handleOpenRecycleBin = () => {
        setIsRecycleBinDisplay(!isRecycleBinDisplay);
    };

    const onClickDeleteDocument = (_, document) => {
        setSelectedDocument(document);
        handleDeleteClick();
    };

    const handleRestore = (event, nodes) => {
        event.preventDefault();
        event.stopPropagation();

        updateItem({ ...nodes, isTrashed: false });
    };

    //TODO: add delete button for folders (API side: isDeletable should be true for folders)
    const handleTrash = (event, nodes) => {
        event.preventDefault();
        event.stopPropagation();
        updateItem({ ...nodes, isTrashed: true });
    };

    const handleUpload = async values => {
        await uploadItems(values.files, values.isGrouped, initItem);
    };

    const handleSearch = (filterName, value) => {
        filterItems({ [filterName]: value ?? '' });
    };

    const mainAction = nodes => {
        return (
            nodes.isDeletable &&
            !nodes.isTrashed && {
                icon: 'delete',
                onClick: handleTrash,
                tooltip: intl.formatMessage({ id: 'ITEM.TRASH.INFO' })
            }
        );
    };

    const binActions = [
        {
            icon: 'check',
            tooltip: <FormattedMessage id="ITEM.RESTORE.INFO" />,
            onClick: handleRestore
        },
        {
            icon: 'clear',
            tooltip: <FormattedMessage id="ITEM.DELETE.INFO" />,
            onClick: onClickDeleteDocument
        }
    ];

    return (
        <Grid container style={{ flex: 1, justifyContent: 'stretch', alignItems: 'stretch', flexDirection: 'column' }}>
            {!isRecycleBinDisplay ? (
                <Grid item>
                    <UploadDocumentsComponent
                        toolbar={
                            <DocumentsToolbar handleOpenRecycleBin={handleOpenRecycleBin} handleSearch={handleSearch} />
                        }
                        filesInputProps={{ acceptedFiles: UPLOAD_TYPES.SUPPORT, showPreviews: false, filesLimit: 10 }}
                        title="SUPPORT.BUTTON.LABEL.UPLOAD"
                        handleUpload={handleUpload}
                        isSubmitOnChange
                    />
                </Grid>
            ) : (
                <Grid item style={{ alignSelf: 'flex-end' }}>
                    <UIActionButton
                        display="secondary"
                        title={getLocalizedString(intl, 'SUPPORT.BUTTON.CLOSE.TRASH.LABEL')}
                        sx={{ margin: '16px', marginRight: '32px', ...toolbarStyle.button }}
                        onClick={handleOpenRecycleBin}
                    />
                </Grid>
            )}

            <Grid item>
                <DocumentTableComponent
                    user={user}
                    actionProfile={PROFILE.POSTIE}
                    loading={loading}
                    paginatedItems={currentPaginatedDocuments}
                    menuEngagements={menuEngagements}
                    setDisplayInvalidAddressEnabled
                    handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                    documentTypes={documentTypes}
                    fetchItemFile={fetchItemFile}
                    fetchItemInfo={fetchItemInfo}
                    updateItem={updateItem}
                    downloadItem={downloadItem}
                    handleFilterItems={filterItems}
                    optionalActionsEnabled={!isRecycleBinDisplay}
                    actions={isRecycleBinDisplay ? binActions : [mainAction]}
                />
            </Grid>

            <DeleteDocumentComponent itemId={selectedDocument?.id} />
        </Grid>
    );
}

Support.propTypes = {
    loading: PropTypes.bool,
    paginatedItems: PropTypes.object
};

export const SupportDocumentsAnswers = injectIntl(Support);
