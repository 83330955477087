import React, { Children } from 'react';
import { List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Typography, Grid, Button } from '@mui/material';
import { injectIntl, useIntl } from 'react-intl';
import { UITooltip } from '../common/UITooltip';
import { ContactDetails } from './ContactDetails';
import { ContactItemActions } from './ContactItemActions';
import { colorByInvitationStatus, shortUserLabel } from '../../helpers/ContactHelper';
import { getLocalizedString } from '../../i18n/i18Helper';
import { CONTACT_TYPE } from '../../types/types';
import { Palette } from '../../styles/colors';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const classes = {
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    inline: {
        display: 'inline'
    },
    fonts: {
        fontWeight: 'bold'
    }
};

function ContactInvitationIndicator({ contact, showInvitationStatus = false }) {
    const intl = useIntl();

    return (
        <>
            {showInvitationStatus && contact.id && contact.invitationStatus?.status ? (
                <ListItemText>
                    <>
                        <Button
                            sx={{
                                minWidth: '2px',
                                padding: '4px 4px',
                                backgroundColor: colorByInvitationStatus(contact.invitationStatus?.status),
                                borderRadius: 2,
                                mr: 1
                            }}
                        />
                        {getLocalizedString(
                            intl,
                            'CONTACT.INVITATION.' + contact.invitationStatus.status.toUpperCase(),
                            {
                                date: contact.invitationStatus?.lastConnection ?? ''
                            }
                        )}
                    </>
                </ListItemText>
            ) : null}
        </>
    );
}

function ContactsListComponent(props) {
    const { contacts, isRoleEditable = true, contactType, showInvitationStatus = false, ...rest } = props;
    let shouldShowInvitationStatus = contactType === CONTACT_TYPE.CLIENT && showInvitationStatus;

    return (
        <List>
            {Children.toArray(
                contacts.map(contact => (
                    <>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar className="avatar bg-primary" alt={contact.email}>
                                    {shortUserLabel(contact)}
                                </Avatar>
                            </ListItemAvatar>
                            <UITooltip
                                title={<ContactDetails contact={contact} />}
                                children={
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        <Typography sx={classes.fonts}>
                                                            {`${contact.firstName ?? ''} ${contact.lastName ?? ''}`}
                                                        </Typography>
                                                        <Typography>{`${
                                                            contact.engagementCompany?.name ?? ''
                                                        }`}</Typography>
                                                    </>
                                                }
                                                secondary={
                                                    <>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={classes.inline}
                                                            color="textPrimary">
                                                            {contact.jobTitle ? `${contact.jobTitle} -` : ''}
                                                        </Typography>
                                                        {contact.email}
                                                        {contact?.isInvalidAddress ? <WarningAmberIcon fontSize="small" sx={{ color: Palette.red }} /> : ''}
                                                    </>
                                                }
                                            />
                                        </Grid>
                                        {shouldShowInvitationStatus && (
                                            <Grid item>
                                                <ContactInvitationIndicator
                                                    showInvitationStatus={shouldShowInvitationStatus}
                                                    contact={contact}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                }
                            />

                            <ContactItemActions
                                contact={contact}
                                isRoleEditable={isRoleEditable}
                                contactType={contactType}
                                {...rest}
                            />
                        </ListItem>

                        <Divider />
                    </>
                ))
            )}
        </List>
    );
}

export const ContactsList = injectIntl(ContactsListComponent);
