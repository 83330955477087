import React, { useContext, useRef, useState } from 'react';
import { SearchThirdParty } from '../components/confirmation/manual';
import { EditCompany } from '../components/company';
import { COMPANY_TYPE } from '../kit/utils/constants';
import { usePrevious } from './usePrevious';
import { ThirdPartyCompanyContext } from '../context';
import { UIDialog } from '../components/common/UIDialog';

export function useThirdPartyCompany({ thirdPartyCompany, initialCompany, handleSelectCompany, editCompanyCallback }) {
    const [isEditCompanyOpen, setIsEditCompanyOpen] = useState(false);
    const prevIsEditCompanyOpen = usePrevious(isEditCompanyOpen);
    const [isSelectCompanyOpen, setIsSelectCompanyOpen] = useState(false);
    const prevIsSelectCompanyOpen = usePrevious(isSelectCompanyOpen);
    const sbmtEditCompanyRef = useRef();

    const cancelSelectCompany = cancel => setIsSelectCompanyOpen(!cancel);
    const cancelEditCompany = cancel => setIsEditCompanyOpen(!cancel);

    const { createCompany, searchCompany, updateCompany } = useContext(ThirdPartyCompanyContext);

    const handleEditCompanyClickSave = () => {
        if (sbmtEditCompanyRef && sbmtEditCompanyRef.current) {
            sbmtEditCompanyRef.current.saveForm();
        }
    };

    const handleEditCompany = editedCompany => {
        (async () => {
            const { isSuccess } = await updateCompany(editedCompany);
            cancelEditCompany(isSuccess);
            editCompanyCallback(isSuccess, editedCompany);
        })();
    };

    const onSelectClick = () => {
        setIsSelectCompanyOpen(!isSelectCompanyOpen);
    };

    const onEditClick = () => {
        setIsEditCompanyOpen(!isEditCompanyOpen);
    };

    const searchComponent = () => {
        const shouldRerender = isSelectCompanyOpen && prevIsSelectCompanyOpen !== isSelectCompanyOpen;

        return !shouldRerender ? null : (
            <UIDialog
                open={isSelectCompanyOpen}
                titleId="CREATE_CONFIRMATION.THIRDPARTY.SELECT.COMPANY.LABEL"
                onClose={() => setIsSelectCompanyOpen(false)}>
                <SearchThirdParty
                    initialCompany={initialCompany}
                    searchCompany={searchCompany}
                    createCompanyAPI={createCompany}
                    createCompanyCallback={handleSelectCompany}
                    selectCompanyCallback={handleSelectCompany}
                />
            </UIDialog>
        );
    };

    const editComponent = () => {
        //Trick to avoid unneeded rerender in this hook, ex when the loader changes state in the parent
        const shouldRerender = isEditCompanyOpen && prevIsEditCompanyOpen !== isEditCompanyOpen;

        return !shouldRerender ? null : (
            <UIDialog
                open={isEditCompanyOpen}
                titleId="CREATE_CONFIRMATION.THIRDPARTY.EDIT.COMPANY.LABEL"
                onSave={handleEditCompanyClickSave}
                onClose={() => setIsEditCompanyOpen(false)}>
                <EditCompany
                    variant="outlined"
                    company={thirdPartyCompany}
                    companyType={COMPANY_TYPE.THIRDPARTY}
                    handleSubmit={handleEditCompany}
                    sbmtCompanyRef={sbmtEditCompanyRef}
                />
            </UIDialog>
        );
    };

    return {
        onEditCompanyClick: onEditClick,
        onSelectCompanyClick: onSelectClick,
        cancelSelectCompany: cancel => cancelSelectCompany(cancel),
        search: searchComponent,
        edit: editComponent
    };
}
