import React from 'react';
import { CircularProgress, Paper } from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface MZRLoaderProps {
    text?: string;
    color?: any;
}

export const UILoader = ({ text, color = 'primary' }: MZRLoaderProps) => {
    const classes = useStyles();
    return (
        <Paper className={classes.container}>
            <>
                <CircularProgress color={color} />
                {text && <label>{text}</label>}
            </>
        </Paper>
    );
};

const useStyles: any = makeStyles(() =>
    createStyles({
        container: {
            backgroundColor: 'white',
            opacity: '0.05',
            flex: '1',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 'none'
        }
    })
);
