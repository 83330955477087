import { Box, Paper } from '@mui/material';
import { UIActionButton } from '../../components/common/UIActionButton';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../navigation/Routes';
import { AuthWelcomeMessage } from '../../components/auth/AuthWelcomeMessage';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../i18n/i18Helper';
import { UIText } from '../../components/common/UIText';
import { isExternalAccessEnabled } from '../../kit/config/appConfiguration';

export const LoginOptions = () => {
    const history = useHistory();
    const intl = useIntl();

    const navigate = (route: string) => () => {
        history.replace(route);
    };

    return (
        <>
            <Box className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white">
                <AuthWelcomeMessage />

                <Box className="flex-row-fluid d-flex flex-column position-relative p-5 overflow-hidden">
                    <Box className="d-flex flex-column-fluid flex-center mt-lg-0">
                        <Paper elevation={3}>
                            <Box sx={styles.container}>
                                <UIText
                                    variant="h4"
                                    sx={styles.title}
                                    text={getLocalizedString(intl, 'AUTH.LOGIN.TITLE')}
                                />

                                <UIActionButton
                                    uppercase={false}
                                    title={getLocalizedString(intl, 'AUTH.OPTIONS.CONTINUE.AS.MAZARS.BUTTON')}
                                    display="secondary"
                                    onClick={navigate(ROUTES.AUTH_MAZARS)}
                                />

                                {isExternalAccessEnabled && (
                                    <UIActionButton
                                        uppercase={false}
                                        title={getLocalizedString(intl, 'AUTH.OPTIONS.CONTINUE.AS.EXTERNAL.BUTTON')}
                                        onClick={navigate(ROUTES.AUTH_CLIENT)}
                                    />
                                )}
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: 15,
        paddingX: 15,
        paddingY: 15
    },
    title: {
        marginBottom: 20,
        textAlign: 'center'
    }
} as const;
