import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';

export const ConfirmationHeaderTitle = ({ isDefaultTitle, isConfirmationReady, engagementName, subtitle = '' }) => {
    return isConfirmationReady ? (
        !isDefaultTitle ? (
            <FormattedMessage id="CONFIRMATION.HEADER.TITLE" values={{ title: `${engagementName} > ${subtitle}` }} />
        ) : (
            <FormattedMessage id="ENGAGEMENT.TABLE.PLACEHOLDER.THIRD_PARTY" />
        )
    ) : (
        <CircularProgress />
    );
};
