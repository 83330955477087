import { useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from './Routes';
import React from 'react';

interface ProtectedRouteProps {
    children: JSX.Element | null;
    isProtected: boolean;
}

export function ProtectedRoute({ children, isProtected: requireAuth }: ProtectedRouteProps) {
    const location = useLocation();
    const isUserConnected: boolean | undefined = useSelector((state: any) => state.account.isUserConnected);
    const defaultPath: string | undefined = useSelector((state: any) => state.app.defaultPath);
    if (requireAuth) {
        return isUserConnected ? children : <Redirect to={ROUTES.AUTH} from={location.pathname} />;
    } else {
        return isUserConnected && defaultPath ? <Redirect to={defaultPath} from={location.pathname} /> : children;
    }
}
