import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { ClientRequest } from '../../../../types/types';
import { ServiceResult } from '../../../../kit/network/ServiceResult';
import { ClientRequestSignatureActions } from './ClientRequestSignatureActions';
import { DocumentViewerContent } from '../../../document/DocumentViewerContent';

interface ClientRequestSignatureCarouselItemProps {
    request: ClientRequest;
    handleRefuse: (request: ClientRequest) => void;
    handleDownload: (request: ClientRequest) => void;
    fetchDocumentFileAPI: (id: string, filters: Record<string, any>) => Promise<ServiceResult>;
    handleSignLater: () => void;
    id: string;
}

export const ClientRequestSignatureCarouselItem = ({
    request,
    handleRefuse,
    handleDownload,
    fetchDocumentFileAPI,
    handleSignLater,
    id
}: ClientRequestSignatureCarouselItemProps) => {
    const [documentBlob, setDocumentBlob] = useState<Blob | null>(null);

    const fetchDocument = async (itemId: string, fileName?: string) => {
        const result = await fetchDocumentFileAPI(itemId, { pdf: true });
        if (result.isSuccess) {
            setDocumentBlob(result.body);
        }
    };

    useEffect(() => {
        request.letterItemId && fetchDocument(request.letterItemId);
    }, [request?.letterItemId]);

    return (
        <Box sx={{ width: '100%' }}>
            {!documentBlob ? null : (
                <DocumentViewerContent
                    id={id}
                    enableDownload
                    enableSignature
                    fileName={request.name}
                    document={documentBlob}
                    actions={
                        <ClientRequestSignatureActions
                            request={request}
                            handleRefuse={handleRefuse}
                            handleDownload={handleDownload}
                            handleSignLater={handleSignLater}
                            handleElectronicSignature={() => {}}
                        />
                    }
                />
            )}
        </Box>
    );
};
