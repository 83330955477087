import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PrintOutlined as PrintOutlinedIcon, DraftsRounded as DraftsRoundedIcon } from '@mui/icons-material';
import { PaperDocumentTableComponent as DocumentsRequestTable } from '../document';
import { Tabs, Tab } from '@mui/material';
import { SUPPORT_PROCESS } from '../../kit/utils/constants';
import { UITooltip } from '../common/UITooltip';
import { initPaginatedObject } from '../common/InitPaginatedObject';
import { SupportDocumentsAnswers } from './Support';

function SupportWrapperComponent(props) {
    const {
        intl,
        user,
        loading,
        paginatedItems,
        menuEngagements,
        handleFetchMenuConfirmations,
        documentTypes,
        isPaperProcess,
        handlePaperProcessChange,
        updateDocument,
        deleteDocument
    } = props;
    const [currentPaginatedItems, setCurrentPaginatedItems] = useState(initPaginatedObject);
    const [paperProcessDisplay, setPaperProcessDisplay] = useState(
        isPaperProcess ? SUPPORT_PROCESS.REQUESTS : SUPPORT_PROCESS.ANSWERS
    );

    useEffect(() => {
        setCurrentPaginatedItems(paginatedItems);
    }, [paginatedItems]);

    useEffect(() => {
        isPaperProcess
            ? setPaperProcessDisplay(SUPPORT_PROCESS.REQUESTS)
            : setPaperProcessDisplay(SUPPORT_PROCESS.ANSWERS);
    }, [isPaperProcess]);

    const handleProcessChange = (event, newValue) => {
        switch (newValue) {
            case SUPPORT_PROCESS.ANSWERS:
                handlePaperProcessChange(false);
                break;
            case SUPPORT_PROCESS.REQUESTS:
                handlePaperProcessChange(true);
                break;
            default:
                break;
        }
        setCurrentPaginatedItems(initPaginatedObject);
        setPaperProcessDisplay(newValue);
        event.stopPropagation();
    };

    return (
        <>
            <Tabs
                value={paperProcessDisplay}
                onChange={handleProcessChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary">
                <UITooltip
                    title={intl.formatMessage({ id: 'SUPPORT.FILTER.PROCESS.PAPER.TOOLTIP' })}
                    children={
                        <Tab
                            value={SUPPORT_PROCESS.REQUESTS}
                            icon={<PrintOutlinedIcon fontSize="large" />}
                            label={<FormattedMessage id={'SUPPORT.FILTER.PROCESS.PAPER.LABEL'} />}
                        />
                    }
                />
                <UITooltip
                    title={intl.formatMessage({ id: 'SUPPORT.FILTER.DOCUMENT.RECEIVED.TOOLTIP' })}
                    children={
                        <Tab
                            value={SUPPORT_PROCESS.ANSWERS}
                            icon={<DraftsRoundedIcon fontSize="large" />}
                            label={<FormattedMessage id={'SUPPORT.FILTER.DOCUMENT.RECEIVED.LABEL'} />}
                        />
                    }
                />
            </Tabs>

            {isPaperProcess ? (
                <DocumentsRequestTable paginatedItems={currentPaginatedItems} />
            ) : (
                <SupportDocumentsAnswers
                    intl={intl}
                    updateDocument={updateDocument}
                    deleteDocument={deleteDocument}
                    user={user}
                    loading={loading}
                    paginatedItems={currentPaginatedItems}
                    menuEngagements={menuEngagements}
                    handleFetchMenuConfirmations={handleFetchMenuConfirmations}
                    documentTypes={documentTypes}
                />
            )}
        </>
    );
}

export const SupportWrapper = injectIntl(SupportWrapperComponent);
