import { DocxIcon, ExcelIcon, MazarsPdfIcon, UnknownFileIcon, PictureFileIcon } from '../../helpers/IconHelper';
import { MailRounded, KeyRounded } from '@mui/icons-material';
import { FileExtension } from './constants';
import { Icon } from '@mui/material';

const DocumentIcons = {
    [FileExtension.PDF]: MazarsPdfIcon,
    [FileExtension.MSG]: MailRounded,
    [FileExtension.EML]: MailRounded,
    [FileExtension.DIR]: () => <Icon />, // empty icon for folder to reserve the space, the treeitem has already folder icon
    [FileExtension.DOCX]: DocxIcon,
    [FileExtension.P7M]: KeyRounded,
    [FileExtension.XLS]: ExcelIcon,
    [FileExtension.XLSX]: ExcelIcon,
    [FileExtension.JPG]: PictureFileIcon,
    [FileExtension.JPEG]: PictureFileIcon,
    [FileExtension.PNG]: PictureFileIcon,
};

export const iconForDocument = fileExtension => {
    return fileExtension && DocumentIcons[fileExtension] ? DocumentIcons[fileExtension] : UnknownFileIcon;
};
