import React, { useState, useEffect } from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Avatar, FormControlLabel } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import TextInput from '../../textField/TextInput';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { initUser } from '../../common/InitUser';
import { MZRChip } from '../../common/MZRChip';
import { ENGAGEMENT_MEMBER_ROLE } from '../InitEngagement';
import { shortUserLabel, FormLabel } from '../../../helpers';
import { hasDeletionRights } from '../../../services';
import { UIActionButton } from '../../common/UIActionButton';
import { getLocalizedString } from '../../../i18n/i18Helper';
import { useIntl } from 'react-intl';

//TODO replace with the new one but need to investigate because the style is broken
import Switch from '@material-ui/core/Switch';

const memberColorsReducer = member => {
    return member.engagementRole === ENGAGEMENT_MEMBER_ROLE.ADMIN
        ? {
              chipColor: 'primary',
              memberColor: 'secondary'
          }
        : { chipColor: 'inherit', memberColor: 'primary' };
};

const MemberDetails = ({ member, handleDelete, disabled, handleChangeRole, deletable }) => {
    const { chipColor, memberColor } = memberColorsReducer(member);

    const handleDeleteMember = () => {
        handleDelete(member);
    };
    return (
        <Box sx={styles.memberItems}>
            <MZRChip
                disabled={!deletable}
                color={chipColor}
                value={member.id}
                label={member.fullname ? member.fullname : member.email}
                onDelete={handleDeleteMember}
                avatar={<Avatar>{shortUserLabel(member)}</Avatar>}
            />

            <FormControlLabel
                control={
                    <Switch
                        key={`key_switch_member_${member.id}`}
                        disabled={disabled}
                        checked={member.engagementRole === ENGAGEMENT_MEMBER_ROLE.ADMIN}
                        onChange={handleChangeRole}
                        name="engagementRole"
                        color={memberColor}
                        value={member.email}
                    />
                }
                label={
                    <FormLabel
                        style={{ marginTop: 8 }}
                        id="SETTINGS.MEMBER.RIGHT.LABEL"
                        helperTitleId="SETTINGS.MEMBER.RIGHT.HINT"
                    />
                }
                color={memberColor}
            />
        </Box>
    );
};
const AddMemberContent = ({ disabled }) => {
    const intl = useIntl();
    return (
        <>
            <Box sx={styles.addContainer}>
                <Field
                    style={styles.input}
                    disabled={disabled}
                    key="email"
                    type="email"
                    name="email"
                    component={TextInput}
                    variant="standard"
                    placeholder={getLocalizedString(intl, 'SETTINGS.MEMBER.EMAIL.PLACEHOLDER')}
                    fullWidth
                />

                <UIActionButton
                    type="submit"
                    sx={styles.addButton}
                    disabled={disabled}
                    startIcon={<AddCircleOutline color="inherit" />}
                    title={getLocalizedString(intl, 'SETTINGS.BUTTON.LABEL.ADD')}
                />
            </Box>

            <Box sx={styles.error}>
                <ErrorMessage name="email">{errorMessage => FormattedErrorMessage(errorMessage)}</ErrorMessage>
            </Box>
        </>
    );
};

/**
 * Select user component to add auditors to an engagement.
 * With display of users selected.
 *
 * @param {*} props
 * @returns
 */
export function EngagementMemberComponent(props) {
    const {
        intl,
        restrictedEdition,
        engagement,
        handleAddMember,
        handleDeleteMember,
        handleChangeRole,
        disabled,
        user
    } = props;

    const [membersSelected, setMembers] = useState(engagement?.appUserEngagements);

    useEffect(() => {
        setMembers(engagement?.appUserEngagements);
    }, [engagement]);

    const userSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email(intl.formatMessage({ id: 'INVALID.FIELD.EMAIL.FORMAT' }))
            .min(8, intl.formatMessage({ id: 'INVALID.FIELD.MIN.SYMBOLS' }, { min: 8 }))
            .required(intl.formatMessage({ id: 'SETTINGS.ENGAGEMENT.EDIT.VALIDATION.REQUIRED_FIELD.EMAIL' })),
        engagementRole: Yup.number()
            .min(1, intl.formatMessage({ id: 'INVALID.FIELD.MIN.NUMBER' }, { min: 1 }))
            .max(2, intl.formatMessage({ id: 'INVALID.FIELD.MAX.NUMBER' }, { max: 2 }))
            .required(intl.formatMessage({ id: 'SETTINGS.ENGAGEMENT.EDIT.VALIDATION.REQUIRED_FIELD' }))
    });

    const handleSubmit = (values, formikProps) => {
        handleAddMember(values);
        // trick to empty the email field after we add the member
        formikProps.setFieldValue('email', '', false);
    };

    return (
        <>
            {!disabled && (
                <Formik
                    enableReinitialize
                    initialValues={initUser}
                    validationSchema={userSchema}
                    onSubmit={handleSubmit}>
                    {() => (
                        <Form>
                            <AddMemberContent disabled={disabled} />
                        </Form>
                    )}
                </Formik>
            )}

            <Box sx={styles.memberContainer}>
                {membersSelected &&
                    React.Children.toArray(
                        membersSelected.map(member => (
                            <MemberDetails
                                deletable={!disabled && hasDeletionRights(engagement, user, member)}
                                member={member}
                                disabled={restrictedEdition || disabled}
                                handleDelete={handleDeleteMember}
                                handleChangeRole={handleChangeRole}
                            />
                        ))
                    )}
            </Box>
        </>
    );
}

const styles = {
    error: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    addButton: {
        minWidth: '180px',
        ml: 3
    },
    addContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        maxWidth: '45%'
    },
    memberContainer: {
        width: '100%',
        padding: '0 25px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 2
    },
    memberItems: {
        display: 'grid',
        gap: 5,
        gridTemplateColumns: 'repeat(2, 1fr)'
    }
};
