import React, { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, CardBody, CardHeader } from '../../partials/controls/Card';
import { Grid } from '@mui/material';
import { FormLabel, getFieldElement } from '../../helpers';
import * as Yup from 'yup';
import { initItem } from '../document/InitItem';

export function RefuseMessageComponent(props) {
    const { user, document, handleSubmit, messageTitle, messageComment } = props;

    const [documentRefused, setDocument] = useState(initItem);

    const ItemSchema = Yup.object().shape({
        errorType: Yup.number(),
        comment: Yup.string().min(2, 'Minimum 2 symbols')
    });

    const btnRefuseMsgRef = useRef(null);
    const triggerButtonClick = () => btnRefuseMsgRef?.current?.click();
    props.btnRefuseRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    useEffect(() => {
        setDocument(document);
    }, [document]);

    const MSG_FIELDS = [
        {
            labelItem: { id: messageComment },
            TextFieldItem: {
                id: 'comment',
                component: 'textarea',
                rows: 5,
                variant: 'outlined'
            }
        }
    ];

    return (
        <Formik
            enableReinitialize={true}
            initialValues={documentRefused}
            validationSchema={ItemSchema}
            onSubmit={values => handleSubmit({ ...values, errorType: 6 })}>
            {({ errors, touched }) => (
                <Form>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center" spacing={1}>
                        <Card>
                            <CardHeader
                                title={
                                    <FormattedMessage id={messageTitle} values={{ userName: user?.fullname }} />
                                }></CardHeader>
                            <CardBody>
                                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                    {MSG_FIELDS?.map(field => (
                                        <Grid
                                            container
                                            direction="column"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '0 20%'
                                            }}>
                                            <Grid item>
                                                <label>
                                                    <FormLabel
                                                        id={field?.labelItem?.id}
                                                        isRequired={field?.labelItem?.isRequired}
                                                    />
                                                </label>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: 'stretch',
                                                    flexDirection: 'column',
                                                    width: '22em'
                                                }}
                                                item>
                                                {getFieldElement(field)}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        onClick={triggerButtonClick}
                        ref={btnRefuseMsgRef}
                    />
                </Form>
            )}
        </Formik>
    );
}

injectIntl(RefuseMessageComponent);
