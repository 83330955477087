import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Box } from '@mui/material';
import { HighlightOffRounded, SearchOutlined } from '@mui/icons-material';
import { UITooltip } from './UITooltip';
import { UIActionButton } from './UIActionButton';
import { getLocalizedString } from '../../i18n/i18Helper';
import { toolbarStyle } from '../../styles/toolbar/ToolbarStyle';
import { MZRSearchField } from './search/MZRSearchField';

const SearchTabComponent = props => {
    const { intl, handleSearch, handleClear, globalFilters, doInitialSearch = false, filtersList } = props;
    const [filters, setFilters] = useState({ ...globalFilters });
    const [shouldSearch, setShouldSearch] = useState(doInitialSearch);

    const handleChangeFilter = event => {
        setFilters({ ...filters, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        // perform initial search according to doInitialSearch flag
        shouldSearch && (async () => await handleSearch({ ...filters, ...globalFilters }))();
        // turn shouldSearch to yes to not be depended of doInitialSearch anymore
        setShouldSearch(true);
    }, [globalFilters]);

    const handleSearchClick = () => {
        handleSearch(filters);
    };

    const handleSearchClear = () => {
        setFilters({ ...globalFilters });
        // come back to the initial state: if doInitialSearch is on than search on clear otherwise wipe the result table
        doInitialSearch ? handleSearch(globalFilters) : handleClear();
    };

    return (
        <Box component="span" m={1} sx={sx.container}>
            {Object.keys(filtersList).map(filterKey => (
                <MZRSearchField
                    key={filterKey}
                    titleId={`SEARCH.COMPANY.LABEL.SEARCH.${filterKey}`}
                    filter={{ id: filtersList[filterKey].id, value: filters[filtersList[filterKey].id] ?? '' }}
                    filterKey={filterKey}
                    handleChange={handleChangeFilter}
                    handleSearch={handleSearchClick}
                />
            ))}

            <UIActionButton
                startIcon={<SearchOutlined />}
                sx={toolbarStyle.button}
                title={getLocalizedString(intl, 'SEARCH.COMPANY.BUTTON.SEARCH.LABEL')}
                onClick={handleSearchClick}
            />

            <UITooltip title={intl.formatMessage({ id: 'SEARCH.COMPANY.BUTTON.CLEAR.DESCRIPTION' })}>
                <UIActionButton
                    sx={toolbarStyle.button}
                    display="secondary"
                    title={getLocalizedString(intl, 'SEARCH.COMPANY.BUTTON.CLEAR.LABEL')}
                    startIcon={<HighlightOffRounded />}
                    onClick={handleSearchClear}
                />
            </UITooltip>
        </Box>
    );
};

export const MZRSearchTab = injectIntl(SearchTabComponent);
const sx = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1
    }
};
