import {
    accountRepository,
    engagementClientContactRepository,
    engagementThirdPartyContactRepository,
    serializeContact
} from '../kit/repository';
import { performAPIAction } from './BaseService';
import { CONTACT_TYPE } from '../types/types';

const ClientContactMethods = ({ ...rest }) => {
    return {
        createClientContact: contact =>
            performAPIAction({
                ...rest,
                apiCall: engagementClientContactRepository.create([serializeContact(contact)]),
                successMessageId: 'CREATE_CONTACT.CONTACT.CREATE.SUCCESS',
                errorMessageId: 'CREATE_CONTACT.CONTACT.CREATE.ERROR',
                entityType: CONTACT_TYPE.CLIENT
            }),
        updateClientContact: contact =>
            performAPIAction({
                ...rest,
                apiCall: engagementClientContactRepository.update([serializeContact(contact)]),
                successMessageId: 'CONTACT.UPDATE.SUCCESS',
                errorMessageId: 'CONTACT.UPDATE.ERROR',
                entityType: CONTACT_TYPE.CLIENT
            }),
        fetchClientContacts: companyId =>
            performAPIAction({
                ...rest,
                apiCall: engagementClientContactRepository.fetchByCompany(companyId),
                successMessageId: null,
                errorMessageId: 'CONTACT.ACCESS.CONTACT.ERROR',
                entityType: CONTACT_TYPE.CLIENT,
                callback: null // don't execute successCallback in case of fetch
            }),
        deleteAccess: (email, inviter, engagementId) =>
            performAPIAction({
                ...rest,
                apiCall: engagementClientContactRepository.deleteAccess(email, inviter, engagementId),
                successMessageId: 'SETTINGS.CLIENT.CONTACT.REVOKE.ACCESS.SUCCESS',
                errorMessageId: 'SETTINGS.CLIENT.CONTACT.REVOKE.ACCESS.ERROR'
            }),
        revokeAccess: (email, inviter) =>
            performAPIAction({
                ...rest,
                apiCall: accountRepository.revokeAccess(email, inviter),
                successMessageId: 'SETTINGS.CLIENT.CONTACT.REVOKE.ACCESS.SUCCESS',
                errorMessageId: 'SETTINGS.CLIENT.CONTACT.REVOKE.ACCESS.ERROR'
            })
    };
};

const ThirdPartyContactMethods = ({ ...rest }) => {
    return {
        createThirdPartyContact: contact =>
            performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyContactRepository.create([serializeContact(contact)]),
                successMessageId: 'CREATE_CONTACT.CONTACT.CREATE.SUCCESS',
                errorMessageId: 'CREATE_CONTACT.CONTACT.CREATE.ERROR',
                entityType: CONTACT_TYPE.THIRD_PARTY
            }),
        updateThirdPartyContact: contact =>
            performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyContactRepository.update([serializeContact(contact)]),
                successMessageId: 'CONTACT.UPDATE.SUCCESS',
                errorMessageId: 'CONTACT.UPDATE.ERROR',
                entityType: CONTACT_TYPE.THIRD_PARTY
            }),

        fetchThirdPartyContacts: companyId =>
            performAPIAction({
                ...rest,
                apiCall: engagementThirdPartyContactRepository.fetchByCompany(companyId),
                successMessageId: null,
                errorMessageId: 'CONTACT.ACCESS.CONTACT.ERROR',
                entityType: CONTACT_TYPE.THIRD_PARTY,
                callback: null // don't execute successCallback in case of fetch
            })
    };
};

export const ContactService = ({
    target = [CONTACT_TYPE.CLIENT, CONTACT_TYPE.THIRD_PARTY],
    callback,
    intl,
    checkSession,
    addToastMessage,
    setLoading = _ => {}
}) => {
    const clientMethods = target.includes(CONTACT_TYPE.CLIENT)
        ? {
              ...ClientContactMethods({
                  intl,
                  callback,
                  checkSession,
                  addToastMessage,
                  setLoading
              })
          }
        : {};

    const thirdPartyMethods = target.includes(CONTACT_TYPE.THIRD_PARTY)
        ? {
              ...ThirdPartyContactMethods({
                  intl,
                  callback,
                  checkSession,
                  addToastMessage,
                  setLoading
              })
          }
        : { createThirdPartyContact: undefined, updateThirdPartyContact: undefined };

    return {
        ...clientMethods,
        ...thirdPartyMethods
    };
};
