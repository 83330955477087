import { IntlShape } from 'react-intl';

export function getLocalizedString(
    intl: IntlShape,
    id: string,
    values?: Record<string, any>,
    defaultMessageId?: string
) {
    const defaultMessage = defaultMessageId ? intl.formatMessage({ id: defaultMessageId }) ?? '' : '';

    return intl.formatMessage({ id: id, defaultMessage: defaultMessage }, values);
}

export const isTranslationStringExists = (intl: IntlShape, key: string) => {
    return !!intl.messages[key];
};
