import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage, injectIntl, FormattedTime } from 'react-intl';
import { GetApp, Print } from '@mui/icons-material';
import { initPaginatedObject } from '../common/InitPaginatedObject';
import { PAPER_PROCESS_STATUS } from './InitItem';
import { FileExtension } from '../../kit/utils/constants';
import { MZRTableActionIcon } from '../common/MZRTableActionIcon';
import { getVisibilityIcon, getValidationIcon } from '../../helpers/DocumentHelper';
import { PaperDocumentStatusIcon } from './PaperDocumentStatusIcon';
import { MZRPaginatedTable } from '../common/MZRPaginatedTable';
import { ItemContext } from '../../context';
import { DocumentPreview } from './DocumentPreview';

const authorizedVisualisation = [FileExtension.PDF];

/**
 * Display a table with the list of document to send via postal mail by the support.
 */
function PaperDocumentTable(props) {
    const { intl, paginatedItems } = props;

    const { updateItem, downloadItem, printItem, filterItems, fetchItemFile } = useContext(ItemContext);
    const [itemsLoaded, setDocument] = useState(initPaginatedObject);
    const [currentDocument, setCurrentDocument] = useState(null);
    const [triggerPreviewDialog, setTriggerPreviewDialog] = useState(0);

    useEffect(() => {
        setDocument(initPaginatedObject);
    }, []);

    useEffect(() => {
        setDocument(paginatedItems);
    }, [paginatedItems]);

    const handleItemOpen = item => {
        authorizedVisualisation.includes(item?.fileExtension.toLowerCase()) && documentPreviewClick(item?.id);
    };

    const handleItemDownload = async item => {
        const { isSuccess } = await updateItem(
            { ...item, paperSendingStatus: PAPER_PROCESS_STATUS.IN_PROGRESS },
            false
        );
        isSuccess && (await downloadItem(item));
    };

    const handleItemPrint = async item => {
        const { isSuccess } = await updateItem(
            { ...item, paperSendingStatus: PAPER_PROCESS_STATUS.IN_PROGRESS },
            false
        );
        isSuccess && (await printItem(item));
    };

    const handleItemComplete = async item => {
        if (parseInt(item.paperSendingStatus) === PAPER_PROCESS_STATUS.IN_PROGRESS)
            await updateItem({ ...item, paperSendingStatus: PAPER_PROCESS_STATUS.DONE });
    };

    const columns = [
        {
            title: '',
            field: 'paperSendingStatus',
            width: 30,
            render: rowData => <PaperDocumentStatusIcon intl={intl} item={rowData} />
        },
        { title: <FormattedMessage id="DOCUMENT.TABLE.TITLE.NAME" />, field: 'name', width: 350 },
        {
            title: <FormattedMessage id="DOCUMENT.TABLE.TITLE.UPDATED" />,
            field: 'updated',
            type: 'date',
            width: 100,
            render: rowData => <FormattedTime value={rowData.updated} year="numeric" month="short" day="numeric" />,
            cellStyle: { textAlign: 'center' }
        }
    ];

    const actions = [
        {
            icon: 'open',
            onClick: (_, rowData) => handleItemOpen(rowData),
            tooltip: intl.formatMessage({ id: 'DOCUMENT.TABLE.ACTION.TOOLTIP.OPEN' })
        },
        {
            icon: 'update',
            onClick: (_, rowData) => handleItemComplete(rowData),
            tooltip: intl.formatMessage({ id: 'DOCUMENT.TABLE.ACTION.TOOLTIP.VALIDATE' })
        },
        {
            icon: 'download',
            onClick: (_, rowData) => handleItemDownload(rowData),
            tooltip: intl.formatMessage({ id: 'DOCUMENT.TABLE.ACTION.TOOLTIP.DOWNLOAD' })
        },
        {
            icon: 'print',
            onClick: (_, rowData) => handleItemPrint(rowData),
            tooltip: intl.formatMessage({ id: 'DOCUMENT.TABLE.ACTION.TOOLTIP.PRINT' })
        }
    ];

    const components = {
        Action: props => {
            const { action, data } = props;
            let actionInfo = action;
            if (typeof action.action === 'function') {
                actionInfo = action.action(data);
            }

            switch (actionInfo.icon) {
                case 'open':
                    return (
                        <MZRTableActionIcon
                            data={data}
                            {...actionInfo}
                            icon={getVisibilityIcon(data, authorizedVisualisation)}
                        />
                    );

                case 'update':
                    return (
                        <MZRTableActionIcon
                            data={data}
                            {...actionInfo}
                            icon={getValidationIcon(data, PAPER_PROCESS_STATUS.IN_PROGRESS)}
                        />
                    );

                case 'download':
                    return <MZRTableActionIcon data={data} {...actionInfo} icon={<GetApp color="primary" />} />;

                case 'print':
                    return <MZRTableActionIcon data={data} {...actionInfo} icon={<Print color="primary" />} />;

                default:
                    return null;
            }
        }
    };

    const documentPreviewClick = item => {
        setCurrentDocument(item);
        setTriggerPreviewDialog(trigger => trigger + 1);
    };

    const updateOpenPreviewDocument = value => {
        setTriggerPreviewDialog(value);
        value === 0 && setCurrentDocument(null);
    };

    return (
        <>
            <MZRPaginatedTable
                handleFilterChange={filterItems}
                columns={columns}
                data={itemsLoaded}
                loading={props.loading}
                actions={actions}
                components={components}
            />
            {currentDocument && triggerPreviewDialog && (
                <DocumentPreview
                    isDownloadable
                    fetchDocumentFileAPI={fetchItemFile}
                    item={currentDocument}
                    updateDocumentAPI={updateItem}
                    isEditable={false}
                    updateOpenPreview={updateOpenPreviewDocument}
                    shouldOpenPreview={triggerPreviewDialog}
                />
            )}
        </>
    );
}

export const PaperDocumentTableComponent = injectIntl(PaperDocumentTable);
