import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, CardBody, CardHeader, CardFooter } from '../../partials/controls/Card';
import { useStepper } from '../../hooks/useStepper';
import { ENGAGEMENT_STEPS, ENGAGEMENT_ACTIONS } from '../engagement/InitEngagement';
import { useEngagement } from '../../hooks/useEngagement';
import { CONTACT_TYPE } from '../../types/types';
import { StepperFooterActions } from '../stepper/StepperFooterActions';
import { Stepper, Step, StepLabel, StepButton, Box } from '@mui/material';

import { ColorlibConnector, ColorlibStepIcon } from '../../helpers';

const creationSteps = [
    {
        key: ENGAGEMENT_STEPS.INFORMATION,
        title: <FormattedMessage id="CREATE_ENGAGEMENT.STEP.INFORMATION.LABEL" />,
        isActive: true
    },
    {
        key: ENGAGEMENT_STEPS.CLIENTS,
        title: <FormattedMessage id="CREATE_ENGAGEMENT.STEP.CLIENTS.LABEL" />,
        isActive: true
    },
    {
        key: ENGAGEMENT_STEPS.CONTACTS,
        title: <FormattedMessage id="CREATE_ENGAGEMENT.STEP.CONTACTS.LABEL" />,
        isActive: true
    },
    {
        key: ENGAGEMENT_STEPS.MEMBERS,
        title: <FormattedMessage id="CREATE_ENGAGEMENT.STEP.AUDITORS.LABEL" />,
        isActive: true
    },
    {
        key: ENGAGEMENT_STEPS.OPTIONAL_INFO_SELECTION,
        title: <FormattedMessage id="CREATE_ENGAGEMENT.STEP.OPTIONAL.INFO.LABEL" />,
        isActive: false //optional step to activate in case of duplicating an engagement
    }
];

/**
 * Create engagement component used to manage the creation steps of an engagement.
 *
 * @param {*} props
 * @returns
 */
export function CreateEngagement(props) {
    const {
        intl,
        user,
        engagementCodeRegEx,
        engagementCodeMessage,
        newEngagement,
        saveEngagement,
        resetEngagement,
        createEngagement,
        initialActiveStep,
        saveActiveStepPosition,
        loading
    } = props;

    const history = useHistory();
    const classes = styles;
    const sbmtEngagementRef = useRef();

    const {
        activeSteps,
        activeStep,
        activeStepPosition,
        firstStep,
        lastStep,
        nextStep,
        previousStep,
        resetStep,
        getStep,
        moveToTargetPosition,
        setTargetStepPosition,
        isStepCompleted,
        completeStep,
        update: updateStep
    } = useStepper({
        initialSteps: creationSteps,
        initialPosition: initialActiveStep,
        changeStepCallback: saveActiveStepPosition
    });

    const [engagementManagement, engagementStepRender, renderErrorMessage] = useEngagement({ intl: intl, user: user });

    useEffect(() => {
        completeStep(ENGAGEMENT_STEPS.INFORMATION, newEngagement?.engagementCode);
        completeStep(ENGAGEMENT_STEPS.CLIENTS, newEngagement?.clientCompanies?.length > 0);
        completeStep(ENGAGEMENT_STEPS.CONTACTS, newEngagement?.clientContacts?.length > 0);
        completeStep(ENGAGEMENT_STEPS.MEMBERS, newEngagement?.appUserEngagements?.length > 1);
        completeStep(ENGAGEMENT_STEPS.OPTIONAL_INFO_SELECTION, true);
    }, [newEngagement]);

    useEffect(() => {
        //Duplicate engagement mode
        if (newEngagement?.parentId) {
            activateOptionalInfoStep();
        }
    }, [newEngagement?.parentId]);

    const handleBack = () => {
        previousStep();
    };

    const handleNext = () => {
        switch (activeStep) {
            case ENGAGEMENT_STEPS.INFORMATION:
                handleEngagementClickSave();
                setTargetStepPosition(activeStepPosition + 1);
                break;

            default:
                nextStep();
                break;
        }
    };

    const handleChangeStep = targetPosition => () => {
        const previousStep = activeStep;
        const nextActiveStep = getStep(targetPosition);

        // everytime we leave information step or select optional step, trigger update engagement object with the data from information form
        if (previousStep === ENGAGEMENT_STEPS.INFORMATION && nextActiveStep !== ENGAGEMENT_STEPS.INFORMATION) {
            setTargetStepPosition(targetPosition);
            handleEngagementClickSave();
        } else {
            moveToTargetPosition(targetPosition);
        }
    };

    const handleReset = () => {
        resetEngagement();
        updateStep(creationSteps, activeStep);
        resetStep();
    };

    const handleEngagementClickSave = () => {
        if (sbmtEngagementRef && sbmtEngagementRef.current) {
            sbmtEngagementRef.current.saveForm();
        }
    };

    /** Add information to the current engagement. */
    const handleEngagementInfoSubmit = values => {
        saveEngagement({ ...newEngagement, ...values });
        // the targetPosition to reach is either the next position in case we click on next button or the target position in case we use the stepper to navigate
        //@seeAlso handleNext and handleChangeStep where targetPosition is set
        moveToTargetPosition();
    };

    const handleEngagementOptionalInfoSubmit = values => {
        saveEngagement({ ...newEngagement, ...values });
    };

    /** Save and create the current engagement. */
    const handleClickSave = () => {
        createEngagement(newEngagement);
    };

    const handleClientAdd = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.ADD_CLIENT,
            values: values
        });
        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleClientUpdate = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.UPDATE_CLIENT,
            values: values
        });
        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleClientDelete = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.DELETE_CLIENT,
            values: values
        });
        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleContactAdd = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.ADD_CLIENT_CONTACT,
            values: values
        });
        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleContactUpdate = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.UPDATE_CLIENT_CONTACT,
            values: values
        });
        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleContactDelete = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.DELETE_CLIENT_CONTACT,
            values: values
        });
        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleAddMember = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.ADD_MEMBER,
            values: values
        });
        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleDeleteMember = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.REMOVE_MEMBER,
            values: values
        });
        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleChangeRole = event => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.CHANGE_MEMBER_ROLE,
            values: { email: event.target.value, isRoleManager: event.target.checked, hasEditionRights: true } // The engagement creator is by default a manager of the engagement, he has edition rights
        });

        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const handleDuplicateEngagement = values => {
        const updatedEngagement = engagementManagement(newEngagement, {
            type: ENGAGEMENT_ACTIONS.DUPLICATE_ENGAGEMENT,
            values: values
        });

        updatedEngagement && saveEngagement(updatedEngagement);
    };

    const activateOptionalInfoStep = () => {
        const updatedSteps = creationSteps.map(step =>
            step.key === ENGAGEMENT_STEPS.OPTIONAL_INFO_SELECTION
                ? {
                      ...step,
                      isActive: true
                  }
                : step
        );

        updateStep(updatedSteps, activeStep);
    };

    const backToPreviousPage = () => history.goBack();

    return (
        <>
            <Card>
                <CardHeader>
                    <Box sx={classes.root}>
                        <Stepper
                            activeStep={activeStepPosition}
                            alternativeLabel
                            nonLinear
                            connector={<ColorlibConnector />}>
                            {activeSteps.map((step, index) => (
                                <Step key={`create_engagement_step_${index}`}>
                                    <StepButton onClick={handleChangeStep(index)}>
                                        <StepLabel
                                            StepIconComponent={props => (
                                                <ColorlibStepIcon {...props} completed={isStepCompleted(index)} />
                                            )}
                                            completed={isStepCompleted(index)}>
                                            {step.title}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </CardHeader>

                <CardBody>{renderErrorMessage()}</CardBody>

                <CardBody>
                    <Box sx={classes.instructions}>
                        {engagementStepRender({
                            currentStep: activeStep,
                            intl: intl,
                            classes: styles,
                            disabled: false,
                            restrictedEdition: false,
                            engagement: newEngagement,
                            propsInfo: {
                                handleSelectEngagement: handleDuplicateEngagement,
                                handleFetchEngagements: props.fetchEngagements,
                                engagementCodeRegEx,
                                engagementCodeMessage,
                                handleInfoSubmit: handleEngagementInfoSubmit,
                                sbmtEngagementRef: sbmtEngagementRef
                            },
                            propsClient: {
                                handleClientAdd: handleClientAdd,
                                handleClientUpdate: handleClientUpdate,
                                handleClientDelete: handleClientDelete
                            },
                            propsContact: {
                                contactType: CONTACT_TYPE.CLIENT,
                                handleContactAdd: handleContactAdd,
                                handleContactUpdate: handleContactUpdate,
                                handleContactDelete: handleContactDelete
                            },
                            propsMember: {
                                handleAddMember: handleAddMember,
                                handleDeleteMember: handleDeleteMember,
                                handleChangeRole: handleChangeRole
                            },
                            propsOptionalInfo: {
                                handleSubmit: handleEngagementOptionalInfoSubmit,
                                sbmtEngagementRef: sbmtEngagementRef
                            }
                        })}
                    </Box>
                </CardBody>
                <CardFooter>
                    <StepperFooterActions
                        handleBack={backToPreviousPage}
                        handleReset={handleReset}
                        handlePrevious={handleBack}
                        handleSave={handleClickSave}
                        handleNext={handleNext}
                        loading={loading}
                        isFirstStep={activeStep === firstStep}
                        isLastStep={activeStep === lastStep}
                    />
                </CardFooter>
            </Card>
        </>
    );
}

CreateEngagement.propTypes = {
    /**
     * Information received from the API of connected user.
     */
    user: PropTypes.object.isRequired,
    /**
     * Allow edition for engagement information fields.
     */
    isEditable: PropTypes.bool,
    /**
     * New engagement.
     */
    newEngagement: PropTypes.object.isRequired,
    /**
     * Save engagement to local store.
     */
    saveEngagement: PropTypes.func.isRequired,
    /**
     * Reset engagement saved in local store.
     */
    resetEngagement: PropTypes.func.isRequired,
    /**
     * Create engagement with API call.
     */
    createEngagement: PropTypes.func.isRequired,
    /**
     * Initial active step.
     */
    initialActiveStep: PropTypes.number.isRequired,
    /**
     * Save active step to local store.
     */
    saveActiveStepPosition: PropTypes.func.isRequired,
    /**
     * Search client companies in global referential.
     */
    searchClientCompanies: PropTypes.func.isRequired
};

export default injectIntl(CreateEngagement);

const styles = {
    root: {
        width: '100%',
        margin: 5
    },
    instructions: {
        marginTop: 1,
        marginBottom: 1
    },
    margin: {
        margin: 1
    },
    textField: {
        width: '98%',
        paddingLeft: 25,
        paddingRight: 25
    },
    selectField: {
        width: '90%',
        paddingLeft: 25,
        paddingRight: 25
    },
    inputField: {
        width: '80%',
        paddingLeft: 25,
        paddingRight: 25
    },
    divider: {
        height: 1,
        margin: 1
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: 0.5
    }
};
