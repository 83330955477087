import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { DEFAULT_PAGE_SIZE, handleRowsPerPage, ROWS_PER_PAGE_OPTIONS } from '../../helpers';
import { TablePagination } from '@mui/material';
import React from 'react';
import '../engagement/common/engagement.css';
import { Palette } from '../../styles/colors';
import { MZRTablePaginationActions } from './MZRTablePaginationActions';

const tableStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch !important',
    flexDirection: 'column',
    boxShadow: '0px 0px 30px 0px rgba(82, 63, 105, 0.05)',
    border: '0',
    marginBottom: '0px',
    position: 'relative',
    minWidth: 0,
    backgroundColor: '#ffffff',
    backgroundClip: 'border-box',
    borderRadius: '0.42rem',
    fontFamily: 'Poppins, Helvetica, sans-serif'
};

export const useTableBaseStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    margin: {
        margin: theme.spacing(1)
    },
    textField: {
        width: '100%',
        height: '5%'
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    }
}));

export const MZRPaginatedTable = ({
    onRowClick,
    columns,
    data,
    loading,
    isFiltering = false,
    actions = [],
    components = {},
    handleFilterChange,
    options = {},
    selectionOptions = {
        selection: false,
        selectionProps: {},
        headerSelectionProps: {},
        showSelectAllCheckbox: false
    },
    onSelectionChange = _ => {},
    pageSize = DEFAULT_PAGE_SIZE,
    ...rest
}) => {
    const customClasses = useStyles();
    const handleChangePage = (_, pageIndex) => {
        handleFilterChange({ pageIndex: pageIndex });
    };

    const handleChangeRowsPerPage = (event, innerChangeRowsPerPage) => {
        innerChangeRowsPerPage(event);
        handleFilterChange({ pageSize: event.target.value });
    };

    return (
        <MaterialTable
            title=""
            style={tableStyle}
            onRowClick={onRowClick}
            columns={columns}
            data={data?.items}
            isLoading={loading}
            onSelectionChange={onSelectionChange}
            options={{
                ...selectionOptions,
                filtering: isFiltering,
                headerStyle: {
                    fontWeight: 600,
                    color: Palette.primaryTextColor,
                    fontSize: '1em',
                    textTransform: 'uppercase',
                    letterSpacing: '0.1rem',
                    verticalAlign: 'middle',
                    textAlign: 'center'
                },
                search: false,
                toolbar: false,
                sorting: false,
                actionsColumnIndex: -1,
                pageSize: pageSize,
                ...options
            }}
            actions={actions}
            components={{
                ...components,
                Pagination: ({ onChangeRowsPerPage, style, classes }) => {
                    return (
                        <TablePagination
                            style={style}
                            classes={{
                                ...classes,
                                ...customClasses
                            }}
                            labelDisplayedRows={() => ''}
                            labelRowsPerPage=""
                            rowsPerPage={handleRowsPerPage(data?.pageSize)}
                            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            count={data?.totalItems ?? 0}
                            page={data?.pageIndex ?? 0}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={event => handleChangeRowsPerPage(event, onChangeRowsPerPage)}
                            ActionsComponent={MZRTablePaginationActions}
                        />
                    );
                }
            }}
            {...rest}
        />
    );
};

const useStyles = makeStyles({
    menuItem: {
        display: 'grid',
        gap: 1
    }
});
