import React from 'react';
import { StyledTooltip } from '../../helpers/TooltipHelper';
import { ErrorOutlineRounded } from '@mui/icons-material';

export const NotificationIndicator = ({ tooltip }) => {
    return (
        <StyledTooltip
            title={tooltip}
            children={<ErrorOutlineRounded style={{ color: 'orange' }} fontSize="large" />}
        />
    );
};
