

export function formatAutoName (engagement, confirmation) {
    const LIMIT_SIZE = 255;
    const const_size = 7;

    const var_date = String(engagement.closingDate).slice(0, 10);
    const var_client = confirmation?.clientCompany?.name ?? '';
    const var_thirdparty = confirmation?.thirdPartyCompany?.name ?? '';

    const var_size = var_client.length + var_thirdparty.length + var_date.length + const_size;

    if (var_size <= LIMIT_SIZE) 
        return var_client + ' - ' + var_thirdparty + ' - ' + var_date;
    else {
        return var_client.slice(0, 115) + ' - ' + var_thirdparty.slice(0, 115) + ' - ' + var_date;
    }
};

export function sortSteps (steps: { position: number; }[]) {
    const updatedSteps = steps.sort((a: { position: number; }, b: { position: number; }) =>
        a?.position !== b?.position ? (a?.position < b?.position ? -1 : 1) : 0
    );

    return updatedSteps;
};
