import React, { useEffect, useState } from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../partials/controls/Card';
import { initCompany, ThirdPartyDetails } from '../../../company';
import { SearchOutlined } from '@mui/icons-material';
import { useThirdPartyCompany } from '../../../../hooks/useThirdPartyCompany';
import { ContactContainer } from '../../../contact/ContactContainer';
import { initContact } from '../../../contact';
import { ConfirmationContactToolbar } from '../utils/ConfirmationContactToolbar';
import PropTypes from 'prop-types';
import { CONTACT_TYPE } from '../../../../types/types';
import { UIActionButton } from '../../../common/UIActionButton';
import { toolbarStyle } from '../../../../styles/toolbar/ToolbarStyle';
import { getLocalizedString } from '../../../../i18n/i18Helper';

const contactsLabels = {
    cardTitle: null,
    createButton: 'CONTACT_SECTION.BUTTON.NEW.LABEL',
    createContactPopupTitle: 'DIALOG.CONTACT.CREATE.TITLE',
    editContactPopupTitle: 'DIALOG.CONTACT.EDIT.TITLE',
    contactsSelect: 'CREATE_CONFIRMATION.THIRDPARTY.CONTACT.LABEL',
    headerTitle: 'CONTACT_SECTION.SUBSECTION.CONTACTS.THIRDPARTY'
};

export const ConfirmationThirdPartySection = ({
    intl,
    classes,
    isThirdPartyLoaded = false,
    isConfirmationLoaded = false,
    confirmationEngagementId,
    thirdPartyCompany,
    thirdPartyContacts,
    handleConfirmationUpdate,
    disabled
}) => {
    const [confirmationContacts, setConfirmationContacts] = useState(thirdPartyContacts); //important to handle a locale state, otherwise the component is not rerendered when confirmation.thirdparties are updated
    const [allCompanyContacts, setAllCompanyContacts] = useState(thirdPartyCompany?.contacts);
    useEffect(() => {
        setConfirmationContacts(thirdPartyContacts);
    }, [thirdPartyContacts]);

    useEffect(() => {
        setAllCompanyContacts(thirdPartyCompany?.contacts);
    }, [thirdPartyCompany]);

    const editCompanyCallback = (isSuccess, editedCompany) => {
        if (isSuccess) {
            const confValues = {
                thirdPartyCompany: editedCompany,
                thirdPartyCompanyId: editedCompany.id
            };

            handleConfirmationUpdate(confValues);
        }
    };

    const handleSelectThirdPartyCompany = (isSuccess, selectedCompany) => {
        const { cancelSelectCompany } = thirdPartyCompanyEnhancer;
        cancelSelectCompany(isSuccess);

        if (isSuccess) {
            if (thirdPartyCompany?.id !== selectedCompany.id) {
                const thirdParty = {
                    thirdPartyCompanyId: selectedCompany.id,
                    thirdPartyCompany: selectedCompany,
                    thirdPartyContacts: []
                };

                setConfirmationContacts([]);
                handleConfirmationUpdate(thirdParty);
            }
        }
    };

    const thirdPartyCompanyEnhancer = useThirdPartyCompany({
        thirdPartyCompany: thirdPartyCompany,
        initialCompany: { ...initCompany, isThirdParty: true, engagementId: confirmationEngagementId },
        handleSelectCompany: handleSelectThirdPartyCompany,
        editCompanyCallback: editCompanyCallback
    });

    const {
        onSelectCompanyClick,
        onEditCompanyClick,
        edit: EditThirdParty,
        search: SearchThirdParty
    } = thirdPartyCompanyEnhancer;

    const handleContactsUpdate = updatedContacts => {
        setConfirmationContacts(updatedContacts);
        handleConfirmationUpdate({
            thirdPartyContacts: updatedContacts
        });
    };
    const SectionBody = () => {
        return !isThirdPartyLoaded ? (
            <div className="text-center">
                <UIActionButton
                    disabled={disabled}
                    startIcon={<SearchOutlined />}
                    title={getLocalizedString(intl, 'CREATE_CONFIRMATION.THIRDPARTY.SEARCH.COMPANY.LABEL')}
                    onClick={onSelectCompanyClick}
                />
            </div>
        ) : (
            <>
                <ThirdPartyDetails
                    intl={intl}
                    classes={classes}
                    variant="standard"
                    company={{
                        ...thirdPartyCompany,
                        isClient: false,
                        isThirdParty: true
                    }}
                    disabled
                />

                <EditThirdParty />
            </>
        );
    };

    return (
        <>
            <Card>
                <CardHeader title={intl.formatMessage({ id: 'CONFIRMATION.SUBSECTION.COMPANY.THIRDPARTY' })}>
                    {isThirdPartyLoaded && (
                        <CardHeaderToolbar>
                            <Box component="span" sx={toolbarStyle.container}>
                                <UIActionButton
                                    sx={toolbarStyle.button}
                                    title={getLocalizedString(intl, 'CONFIRMATION.BUTTON.REPLACE.LABEL')}
                                    disabled={disabled}
                                    onClick={onSelectCompanyClick}
                                />
                                <UIActionButton
                                    disabled={disabled}
                                    sx={toolbarStyle.button}
                                    onClick={onEditCompanyClick}
                                    title={getLocalizedString(intl, 'CONFIRMATION.BUTTON.EDIT.LABEL')}
                                />
                            </Box>
                        </CardHeaderToolbar>
                    )}
                </CardHeader>
                <CardBody>
                    {!isConfirmationLoaded ? (
                        <div className="text-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <SectionBody />
                    )}

                    <SearchThirdParty />
                </CardBody>
            </Card>

            {/*Third party contacts section*/}

            {isThirdPartyLoaded && (
                <Grid item>
                    <ContactContainer
                        disabled={false}
                        isSignatoryOptionDisabled={disabled}
                        isCreateDisabled={!(thirdPartyCompany?.id || confirmationContacts?.length > 0)}
                        intl={intl}
                        selectedContacts={confirmationContacts}
                        contactType={CONTACT_TYPE.THIRD_PARTY}
                        allContacts={allCompanyContacts}
                        companies={[thirdPartyCompany]}
                        handleContactsUpdate={handleContactsUpdate}
                        strings={contactsLabels}
                        initialContact={{ ...initContact, engagementCompanyId: thirdPartyCompany?.id }}
                        view={null}
                        toolbarView={ConfirmationContactToolbar}
                        style={classes}
                    />
                </Grid>
            )}
        </>
    );
};

ConfirmationThirdPartySection.propTypes = {
    thirdPartyCompany: PropTypes.object,
    isThirdPartyLoaded: PropTypes.bool,
    thirdPartyContacts: PropTypes.array,
    handleConfirmationUpdate: PropTypes.func,
    disabled: PropTypes.bool,
    isConfirmationLoaded: PropTypes.bool,
    updateThirdPartyCompany: PropTypes.func,
    searchThirdPartyCompanies: PropTypes.func,
    createThirdPartyCompany: PropTypes.func
};

ConfirmationThirdPartySection.defaultProps = {
    isThirdPartyLoaded: false,
    isConfirmationLoaded: false,
    disabled: false
};
