import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { COMPANY_TYPE } from '../../kit/utils/constants';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { CreateConfirmationComponent } from '../../components/confirmation';
import { Loader } from '../../components/loader/Loader';
import { isAuthorisedProfile } from '../../components/auth/UserHelper';
import { PageNotFound } from '../pageNotFound/PageNotFound';
import {
    fetchData,
    fetchConfirmationCategories,
    fetchEngagement,
    fetchEngagementClients,
    CompanyService,
    ConfirmationService,
    ContactService,
    LetterTemplateService
} from '../../services';
import { ROUTES } from '../../navigation/Routes';
import { LetterTemplateContext } from '../../components/setup';
import { CONTACT_TYPE, PROFILE } from '../../types/types';
import { ThirdPartyCompanyContext, ClientCompanyContext, ContactContext } from '../../context';

/**
 * Create confirmation manual page component for user with auditor role.
 *
 * User can create through this page a unique confirmation manually.
 * @public
 */
class CreateConfirmationManual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };

        this.companyManager = CompanyService({
            engagementId: this.props.match.params.engagementId,
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            callback: async (iSuccess, _, companyType) =>
                iSuccess && companyType === COMPANY_TYPE.CLIENT && (await fetchEngagementClients(this))
        });

        this.contactManager = ContactService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            callback: async (isSuccess, data, contactType) => {
                isSuccess && contactType === CONTACT_TYPE.CLIENT && (await fetchEngagement(this));
            }
        });

        this.letterTemplateManager = LetterTemplateService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading
        });

        this.confirmationService = ConfirmationService({
            intl: this.props.intl,
            user: this.props.user,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading
        });
    }

    setLoading = loading => {
        this.setState({ loading: loading });
    };

    createConfirmation = async confirmation => {
        const { engagement } = this.state;
        const newConfirmation = {
            ...confirmation,
            ...{ clusterId: engagement.clusterId, engagementId: engagement.id }
        };

        await this.confirmationService.create(newConfirmation, isSuccess => {
            isSuccess && this.props.history.push(ROUTES.ENGAGEMENT.replace(':engagementId', engagement.id));
        });
    };

    async componentDidMount() {
        await fetchData(this, [
            () => fetchConfirmationCategories(this),
            () => fetchEngagement(this),
        ]);
        await this.fetchAllLetterTemplates();
    }

    fetchAllLetterTemplates = async () => {
        const { engagement } = this.state;
        const { intl } = this.props;
        const result = await this.letterTemplateManager.fetchAll({ clusterId: engagement?.clusterId });
        if (result.isSuccess) {
            this.setState({ letterTemplates: Object.values(result.parsedValue) });
        } else {
            this.setState({ errorMessage: intl.formatMessage({ id: 'LETTER.TEMPLATE.ACCESS.ERROR' }) });
        }
    };

    render() {
        const { user } = this.props;

        if (!user.profil) {
            return <Loader />;
        } else {
            if (isAuthorisedProfile(PROFILE.AUDITOR, user.profil)) {
                return (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <LetterTemplateContext.Provider
                                    value={{
                                        fetchTemplate: this.letterTemplateManager.fetch,
                                        downloadTemplate: this.letterTemplateManager.download
                                    }}>
                                    <ThirdPartyCompanyContext.Provider
                                        value={{
                                            createCompany: this.companyManager.createThirdParty,
                                            updateCompany: this.companyManager.updateThirdParty,
                                            searchCompany: this.companyManager.searchThirdParties,
                                            fetchCompany: this.companyManager.fetchThirdPartyCompany
                                        }}>
                                        <ClientCompanyContext.Provider
                                            value={{
                                                createCompany: this.companyManager.createClient,
                                                searchCompany: this.companyManager.searchGlobalClients
                                            }}>
                                            <ContactContext.Provider
                                                value={{
                                                    [CONTACT_TYPE.CLIENT]: {
                                                        createContact: this.contactManager.createClientContact,
                                                        updateContact: this.contactManager.updateClientContact
                                                    },
                                                    [CONTACT_TYPE.THIRD_PARTY]: {
                                                        createContact: this.contactManager.createThirdPartyContact,
                                                        updateContact: this.contactManager.updateThirdPartyContact
                                                    }
                                                }}>
                                                <CreateConfirmationComponent
                                                    loading={this.props.loading}
                                                    languages={this.props.languages}
                                                    engagement={this.state.engagement}
                                                    templates={this.state.letterTemplates}
                                                    confirmationCategories={this.state.confirmationCategories}
                                                    createConfirmation={this.createConfirmation}
                                                />
                                            </ContactContext.Provider>
                                        </ClientCompanyContext.Provider>
                                    </ThirdPartyCompanyContext.Provider>
                                </LetterTemplateContext.Provider>
                            </div>
                        </div>
                    </>
                );
            } else {
                return <PageNotFound {...this.props} />;
            }
        }
    }
}

CreateConfirmationManual.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    languages: PropTypes.array.isRequired
};

CreateConfirmationManual.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        languages: state.cluster.languages
    };
};

export const createConfirmationManual = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(CreateConfirmationManual));
