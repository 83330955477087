import { appApiUrl } from '../../config/appConfiguration';
import { parseEmailType, parseEmailTypes } from './setupParsers';
import { Repository } from '../Repository';

class EmailTypeRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`emailtype`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseEmailTypes);
        return result;
    };

    fetch = async (typeId) => {
        let result = await this.network.get(`emailtype/${typeId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseEmailType);
        return result;
    };
}

export const emailTypeRepository = new EmailTypeRepository();
