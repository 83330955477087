import { useState, useEffect, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import './../createMethod.css';
import { UIActionButton } from '../../common/UIActionButton';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../partials/controls/Card';
import { Alert, Typography } from '@mui/material';
import {
    SelectLetterTemplate,
    SelectClientComponent,
    SelectThirdPartyComponent,
    SelectParametersComponent,
} from '.';
import { expectedOptionAnswersNumber, contactRoleValidator } from '../../../helpers';
import {
    ConfirmationHeaderTitle,
    CreateConfirmationProgress,
    CreationProcessStep,
    initConfirmation
} from '../common';
import { withCreateConfirmation } from '../../../enhancers/withCreateConfirmation';
import { CreateConfirmationToolbarItems } from './CreateConfirmationToolbarItems';
import { useStepper } from '../../../hooks/useStepper';
import { StepperFooterActions } from '../../stepper/StepperFooterActions';
import { getLocalizedString } from '../../../i18n/i18Helper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

function getCreationSteps() {
    return [
        {
            key: CreationProcessStep.TEMPLATE,
            title: <FormattedMessage id="CREATE_CONFIRMATION.STEP_TEMPLATE.LABEL" />,
            isActive: true
        },
        {
            key: CreationProcessStep.CLIENT,
            title: <FormattedMessage id="CREATE_CONFIRMATION.STEP_CLIENT.LABEL" />,
            isActive: true
        },
        {
            key: CreationProcessStep.THIRDPARTY,
            title: <FormattedMessage id="CREATE_CONFIRMATION.STEP_THIRD_PARTY.LABEL" />,
            isActive: true
        },
        {
            key: CreationProcessStep.PARAMETERS,
            title: <FormattedMessage id="CREATE_CONFIRMATION.STEP_PARAMETERS.LABEL" />,
            isActive: true
        }
    ];
}

function CreateConfirmation(props) {
    const { loading, engagement, templates, languages, confirmationCategories, createConfirmation } = props;

    const intl = useIntl();
    let history = useHistory();
    const sbmtParamRef = useRef();
    const classes = useStyles();

    const [stepErrorMessage, setStepErrorMessage] = useState('');
    const [confirmation, setConfirmation] = useState(initConfirmation);
    const [isSubmitAuthorised, setIsAuthorisedSubmit] = useState(false);
    const [isParamsSubmited, setIsParamsSubmited] = useState(false);

    const {
        steps,
        activeSteps,
        activeStep,
        firstStep,
        lastStep,
        activeStepPosition,
        nextStep,
        previousStep,
        resetStep,
    } = useStepper({
        initialSteps: getCreationSteps(),
        initialPosition: 0
    });

    async function handleCreate () {
        await createConfirmation(confirmation);
    };

    const handleCreateCallback = useCallback(handleCreate, [handleCreate]);

    useEffect(() => {
        setIsParamsSubmited(true);
    }, [confirmation])

    useEffect(() => {
        if (activeStep === lastStep && isSubmitAuthorised && isParamsSubmited) {
            handleCreateCallback();
            setIsParamsSubmited(false);
            setIsAuthorisedSubmit(false);
        };
    }, [confirmation, activeStep, lastStep, isSubmitAuthorised, isParamsSubmited, handleCreateCallback]);

    function handleStepAction(activeStep, action) {
        switch (activeStep) {
            case CreationProcessStep.TEMPLATE:
                action();
                break;

            case CreationProcessStep.CLIENT:
                const isValidData =
                    confirmation.clientCompanyId &&
                    confirmation.clientCompanyId !== 0 &&
                    confirmation.clientCompanyId !== '';

                const { errorMessage: clientErrors, hasValidContacts: hasValidClientContacts } = contactRoleValidator({
                    contacts: confirmation.clientContacts,
                    intl: intl,
                    message: !isValidData
                        ? intl.formatMessage({ id: 'CREATE_CONFIRMATION.VALIDATION.REQUIRED_STEPS.CLIENT' })
                        : ''
                });

                if (isValidData && hasValidClientContacts) {
                    action();
                    setStepErrorMessage('');
                } else {
                    setStepErrorMessage(clientErrors);
                }

                break;

            case CreationProcessStep.THIRDPARTY:
                const {
                    errorMessage: thirdPartyErrors,
                    hasValidSignatoryContacts: hasUniqueMainContact
                } = contactRoleValidator({
                    contacts: confirmation.thirdPartyContacts,
                    intl: intl,
                    signatoryMessageId: 'CONFIRMATION.VALIDATION.REQUIRED.CONTACT_MAIN'
                });

                if (hasUniqueMainContact) {
                    action();
                    setStepErrorMessage('');
                } else {
                    setStepErrorMessage(thirdPartyErrors);
                }

                break;
            
            case CreationProcessStep.PARAMETERS:
                const expectedStackedWorkflowActionsNumber = expectedOptionAnswersNumber(confirmation?.workflowActions);
                if (
                    Object.keys(confirmation.activeStackedWorkflowActions).length === expectedStackedWorkflowActionsNumber) {
                    setStepErrorMessage('');
                    handleParamsSave();
                    action();
                } else {
                    setStepErrorMessage(
                        intl.formatMessage({ id: 'CREATE_CONFIRMATION.VALIDATION.REQUIRED_STEPS.STACKED_STEPS' })
                    );
                }

                break;

            default:
                break;
        }
    }

    function handleNext () {
        handleStepAction(activeStep, nextStep);
    }

    function handleSave () {
        handleStepAction(activeStep, () => setIsAuthorisedSubmit(true));
    }

    function handlePrevious () {
        previousStep();
    }

    function handleReset () {
        setConfirmation(initConfirmation);
        resetStep();
        setStepErrorMessage('');
    }

    function backToPreviousPage () {
        history.goBack();
    };

    function handleTemplateSelect (values) {
        const { onLetterTemplateChanged } = props;

        const callback = (newSteps, hasStackableWorkflowActions, updatedValues) => {
            nextStep();
            setConfirmation({
                ...confirmation,
                ...updatedValues,
                engagementId: engagement?.id
            });
        };

        onLetterTemplateChanged(values, steps, callback);
    };

    function handleParamsSave () {
        if (sbmtParamRef && sbmtParamRef.current) {
            setIsParamsSubmited(false);
            sbmtParamRef.current.saveForm();
        }
    };

    function handleUpdate (values) {
        setConfirmation((previousConfirmation) => { return { ...previousConfirmation, ...values }; });
    };

    function handleStepOptionSelect (selectedStackedAction, stackedSteps) {
        const { onStackableStepChanged } = props;

        const callback = updatedActions => {
            setConfirmation((previousConfirmation) => { return {
                ...previousConfirmation,
                workflowActions: updatedActions,
                activeStackedWorkflowActions: {
                    ...previousConfirmation.activeStackedWorkflowActions,
                    ...{ [selectedStackedAction.position]: selectedStackedAction.workflowActionId }
                }
            }});
        };

        onStackableStepChanged(selectedStackedAction, stackedSteps, confirmation.workflowActions, callback);
    };

    return (
        <Card>
            <CardHeader
                title={
                    <ConfirmationHeaderTitle
                        isConfirmationReady={!!engagement?.id}
                        isDefaultTitle={false}
                        engagementName={engagement?.name}
                    />
                }>
                <CardHeaderToolbar>
                    <CreateConfirmationToolbarItems handleReset={handleReset} handleBack={backToPreviousPage} />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="add-confirmation mt-5">
                    <div className={classes.root}>
                        <CreateConfirmationProgress activeStepPosition={activeStepPosition} activeSteps={activeSteps} />
                        <Alert
                            severity="error"
                            style={stepErrorMessage !== '' ? {} : { display: 'none' }}
                            onClose={() => setStepErrorMessage('')}>
                            {FormattedErrorMessage(stepErrorMessage)}
                        </Alert>

                        <div>
                            {activeStep === activeSteps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        <FormattedMessage id="CREATE_CONFIRMATION.STEPS.FINISH.MSG" />
                                    </Typography>
                                    <UIActionButton
                                        disabled={loading}
                                        title={getLocalizedString(intl, 'CREATE_CONFIRMATION.STEPS.RESET.LABEL')}
                                        onClick={handleReset}
                                    />
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="mb-10">
                                        {activeStep === CreationProcessStep.TEMPLATE && (
                                            <SelectLetterTemplate
                                                key={`key_select_template_${activeStep}`}
                                                templates={templates}
                                                languages={languages}
                                                confirmationCategories={confirmationCategories}
                                                handleSelect={handleTemplateSelect}
                                                handleConfirmationUpdate={handleUpdate}
                                            />
                                        )}

                                        {activeStep === CreationProcessStep.CLIENT && (
                                            <SelectClientComponent
                                                key={`key_select_client_${activeStep}`}
                                                {...props}
                                                loading={loading}
                                                clientContacts={engagement?.clientContacts}
                                                clientCompanies={engagement?.clientCompanies}
                                                confirmation={confirmation}
                                                handleConfirmationUpdate={handleUpdate}
                                            />
                                        )}

                                        {activeStep === CreationProcessStep.THIRDPARTY && (
                                            <SelectThirdPartyComponent
                                                key={`key_select_thirdparty_${activeStep}`}
                                                {...props}
                                                loading={loading}
                                                confirmation={confirmation}
                                                handleConfirmationUpdate={handleUpdate}
                                            />
                                        )}

                                        {activeStep === CreationProcessStep.PARAMETERS && (
                                            <SelectParametersComponent
                                                key={`key_select_parameters_${activeStep}`}
                                                engagement={engagement}
                                                confirmation={confirmation}
                                                handleConfirmationUpdate={handleUpdate}
                                                handleStepOptionSelect={handleStepOptionSelect}
                                                sbmtParamRef={sbmtParamRef}
                                            />
                                        )}

                                    </div>

                                    <StepperFooterActions
                                        isNextStepDisabled={activeStep === firstStep}
                                        handleNext={handleNext}
                                        handlePrevious={handlePrevious}
                                        handleSave={handleSave}
                                        isFirstStep={activeStep === firstStep}
                                        isLastStep={activeStep === lastStep}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

CreateConfirmation.propTypes = {
    createConfirmation: PropTypes.func.isRequired
};

export const CreateConfirmationComponent = withCreateConfirmation(CreateConfirmation);
