import { Box, Grid } from '@mui/material';
import React from 'react';
import { FormLabel, getFieldElement } from '../../helpers/FormHelper';

const defaultProps = {
    component: 'span',
    bgcolor: 'background.paper',
    m: 1,
    border: 2,
    padding: '25px 25px 25px 25px'
};

export const EditDocumentSection = ({ fields }) => {
    return (
        <Grid item style={{ display: 'flex', flexDirection: 'column' }} xs={12}>
            <Box borderColor="primary.main" {...defaultProps}>
                {React.Children.toArray(
                    fields?.map(field => (
                        <Grid container direction="row" justifyContent="space-around" alignItems="center">
                            <Grid container item direction="column" justifyContent="center" xs={4}>
                                <FormLabel
                                    id={field?.labelItem?.id}
                                    isRequired={field?.labelItem?.isRequired}
                                    isHidden={field?.labelItem?.isHidden}
                                />
                            </Grid>

                            <Grid container item direction="column" justifyContent="center" xs={5}>
                                {getFieldElement(field)}
                            </Grid>
                        </Grid>
                    ))
                )}
            </Box>
        </Grid>
    );
};
