import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import './createMethod.css';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../partials/controls/Card';
import { ListItemText, Box } from '@mui/material';
import { ROUTES } from '../../navigation/Routes';
import { toolbarStyle } from '../../styles/toolbar/ToolbarStyle';
import { getLocalizedString } from '../../i18n/i18Helper';
import { UIBackButton } from '../common/UIBackButton';
import { UIText } from '../common/UIText';

const CreateMethodTile = ({ headerId, titleId, subtitleId, onClick }) => {
    const intl = useIntl();
    return (
        <Card className="pl-10 pr-10 pb-70 cursor-pointer" onClick={onClick}>
            <CardHeader title={getLocalizedString(intl, headerId)} />
            <CardBody>
                <ListItemText
                    sx={{
                        width: '200px',
                        padding: '0 0.1em'
                    }}
                    primary={<UIText variant="overline" text={getLocalizedString(intl, titleId)} />}
                    secondary={
                        <UIText variant="body2" color="textSecondary" text={getLocalizedString(intl, subtitleId)} />
                    }
                />
            </CardBody>
        </Card>
    );
};

export function CreateMethodComponent(props) {
    const history = useHistory();

    const backToPreviousPage = () => {
        history.goBack();
    };

    const handleCreate = route => () => {
        const {
            match: { params }
        } = props;

        history.push({
            pathname: route.replace(':engagementId', params?.engagementId)
        });
    };

    return (
        <Card>
            <CardHeader title={<FormattedMessage id={'METHOD.CREATE.CONFIRMATION.TITLE'} />}>
                <CardHeaderToolbar>
                    <Box component="span" sx={toolbarStyle.container}>
                        <UIBackButton onClick={backToPreviousPage} />
                    </Box>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Box className="add-confirmation mt-5">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CreateMethodTile
                            headerId="METHOD.CREATE.CONFIRMATION.MANUAL.TITLE"
                            titleId="METHOD.CREATE.CONFIRMATION.MANUAL.DESCRIPTION"
                            subtitleId="METHOD.CREATE.CONFIRMATION.MANUAL.CONTENT"
                            onClick={handleCreate(ROUTES.CONFIRMATION_CREATE_MANUAL)}
                        />

                        <CreateMethodTile
                            headerId="METHOD.CREATE.CONFIRMATION.IMPORT.TITLE"
                            titleId="METHOD.CREATE.CONFIRMATION.IMPORT.DESCRIPTION"
                            subtitleId="METHOD.CREATE.CONFIRMATION.IMPORT.CONTENT"
                            onClick={handleCreate(ROUTES.CONFIRMATION_CREATE_IMPORT)}
                        />
                    </Box>
                </Box>
            </CardBody>
        </Card>
    );
}
