export const ClusterTypes = {
    SET_CLUSTERS: 'setClusters',
    SET_CURRENT_CLUSTER_ID: 'setCurrentClusterId',
    FLUSH_CURRENT_CLUSTER: 'flushCurrentCluster',
    SET_LANGUAGES: 'setLanguages'
};

export const setClusters = clusters => {
    return {
        type: ClusterTypes.SET_CLUSTERS,
        clusters
    };
};

export const setCurrentClusterId = clusterId => {
    return {
        type: ClusterTypes.SET_CURRENT_CLUSTER_ID,
        clusterId
    };
};

export const flushCurrentCluster = () => {
    return {
        type: ClusterTypes.FLUSH_CURRENT_CLUSTER
    };
};

export const setLanguages = languages => {
    return {
        type: ClusterTypes.SET_LANGUAGES,
        languages
    };
};
