export const externalClient = {
    'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.DUE_DATE': 'Due date',
    'CLIENT.ENGAGEMENT.TABLE.ACTION.TOOLTIP.REFUSE': 'Refuse the confirmation request',
    'CLIENT.ENGAGEMENT.TABLE.ACTION.TOOLTIP.COMPLETE.TASKS': 'Complete the confirmations tasks',
    'CLIENT.ENGAGEMENT.TABLE.ACTION.OPTION.DOWNLOAD': 'Download letter',
    'CLIENT.ENGAGEMENT.TABLE.ACTION.OPTION.UPLOAD': 'Upload signed letter',

    'CLIENT.HOME.TABLE.TITLE.ENGAGEMENT': 'ENGAGEMENT',
    'CLIENT.HOME.TABLE.TITLE.CLOSING_DATE': 'CLOSING DATE',
    'CLIENT.HOME.TABLE.TITLE.NB_PENDING_REQUESTS': 'PENDING REQUESTS',
    'CLIENT.HOME.TABLE.TITLE.NB_COMPLETED_REQUESTS': 'COMPLETED REQUESTS',
    'CLIENT.HOME.TABLE.TITLE.AUDITORS': 'AUDITORS',
    'CLIENT.HOME.TABLE.TITLE.CONTACTS': 'CONTACTS',
    'CLIENT.ENGAGEMENT.PENDING.REQUESTS.TOOLTIP': 'Pending requests',
    'CLIENT.ENGAGEMENT.ALL.REQUESTS.TOOLTIP': 'All requests',
    'CLIENT.ENGAGEMENT.REQUESTS.LABEL': 'REQUESTS',
    'CLIENT.ENGAGEMENT.ALL.REQUESTS.LABEL': 'ALL CONFIRMATIONS',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.CONFIRMATION.PLACEHOLDER': 'Account/Amount/Comment',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.PLACEHOLDER': 'Letterhead',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.ON': 'Already uploaded',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.LETTERHEAD.OFF': 'Not uploaded',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.THIRD_PARTY': 'Third-Party Company',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.THIRD_PARTY.CONTACTS': 'Third-Party Contacts',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.CONTACTS': 'Contacts',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.CLIENT.SIGNATORY': 'Signatory',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.DUPLICATE': 'This confirmation has already been requested',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.INACCURATE':
        'The information of this confirmation request are not accurate (justify in comment section)',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.REFUSE': 'I do not wish this request to be made (justify in comment section)',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.REASON': 'Reason',
    'CLIENT.ENGAGEMENT.REFUSE.REQUEST.COMMENT': 'Comment',
    'CLIENT.ENGAGEMENT.REQUESTS.REFUSE.SUCCESS': 'The request is refused with success',
    'CLIENT.ENGAGEMENT.REQUESTS.REFUSE.ERROR': 'Refuse request error',
    'CLIENT.ENGAGEMENT.REQUESTS.UPLOAD.SUCCESS': 'The confirmation is uploaded with success',
    'CLIENT.ENGAGEMENT.REQUESTS.UPLOAD.ERROR': 'Upload confirmation error',
    'CLIENT.REFUSE.REQUEST.REASON.REQUIRED': 'Reason is required',
    'CLIENT.REFUSE.REQUEST.COMMENT.REQUIRED': 'Comment is required',
    'CLIENT.REFUSE.REQUEST.POPUP.TITLE': 'Select the reason of your refusal',
    'CLIENT.ENGAGEMENT.UPLOAD.DIALOG.TITLE': 'Upload signed confirmation letter',
    'CLIENT.ENGAGEMENT.COMPLETE.DIALOG.TITLE': 'Complete the request steps',
    'CLIENT.ENGAGEMENT.COMPLETE.THIRD_PARTY.SECTION': 'Third Party',
    'CLIENT.ENGAGEMENT.COMPLETE.LETTERHEAD.SECTION': 'Letterhead',
    'CLIENT.ENGAGEMENT.COMPLETE.COMPLEMENTARY_INFO.SECTION': 'Complementary information',
    'CLIENT.ENGAGEMENT.COMPLETE.VALIDATE.SECTION': 'Validation',
    'CLIENT.CONFIRMATION.STEPS.NEXT.LABEL': 'Save and Next',
    'CLIENT.CONFIRMATION.STEPS.CONFIRM.LABEL': 'Confirm',
    'CLIENT.REQUEST.LETTERHEAD.REQUIRED': 'Letterhead is required',
    'CLIENT.REQUEST.THIRD_PARTY.REQUIRED': 'Third party company and contact are required',
    'CLIENT.REQUEST.VALIDATION.STEP.CLIENT.COMPANY': 'Client information',
    'CLIENT.REQUEST.VALIDATION.STEP.CLIENT.CONTACTS': 'Client contacts',
    'CLIENT.REQUEST.VALIDATION.STEP.THIRD_PARTY.COMPANY': 'Third party company information',
    'CLIENT.REQUEST.VALIDATION.STEP.THIRD_PARTY.CONTACTS': 'Third party contacts',
    'CLIENT.REQUEST.VALIDATION.STEP.LETTERHEAD': 'Letterhead',
    'CLIENT.ENGAGEMENT.REQUESTS.VALIDATE.SUCCESS': 'The request is validated with success',
    'CLIENT.ENGAGEMENT.REQUESTS.VALIDATE.ERROR': 'Confirm request error',
    'CLIENT.REQUEST.VALIDATION.STEP.ALL.STEPS.REQUIRED': 'Please complete all the steps to validate your request',
    'CLIENT.ENGAGEMENT.SIGN.DIALOG.TITLE': 'Signature',
    'CLIENT.ENGAGEMENT.TABLE.TITLE.STATUS': 'Status',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.NOT_STARTED': 'To Do',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.REFUSED': 'Refused by auditors team',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.WAITING_AUDITOR_VALIDATION': 'Waiting for validation',
    'CLIENT.ENGAGEMENT.TABLE.FILTER.STATUS.WAITING_SIGNATURE': 'waiting for signature',
    'CLIENT.REQUEST.ACTION.SIGNATURE.E_SIGNATURE.OPTION': 'E-Signature',
    'CLIENT.REQUEST.ACTION.SIGNATURE.DRAW.OPTION': 'Draw signature',
    'CLIENT.REQUEST.ACTION.SIGNATURE.UPLOAD.OPTION': 'Upload signature',
    'CLIENT.REQUEST.ACTION.SIGNATURE': 'SIGNATURE',
    'CLIENT.REQUEST.ACTION.REFUSE': 'REFUSE',
    'CLIENT.REQUEST.ACTION.DOWNLOAD': 'DOWNLOAD',
    'CLIENT.REQUEST.ACTION.SIGN_LATER': 'SIGN LATER',
    'CLIENT.CONTACT.INVITE': 'Invite to app',
    'CLIENT.CONTACT.MANAGE.ACCESS.RIGHTS': 'Manage access & rights',
    'CLIENT.CONTACT.EDIT': 'Edit info'
};
