export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_ENGAGEMENT_PAGE_SIZE = 10;
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 20, 30, 40, 50];

export const handleRowsPerPage = numberOfItems => {
    const rowsPerPage = ROWS_PER_PAGE_OPTIONS.reduce((a, b) => {
        return a < numberOfItems && numberOfItems <= b ? b : a;
    });
    return rowsPerPage;
};

export const TREE_COLOR = {
    1: '#0071CE4D',
    2: '#0071CE1A',
    3: '#79AFDA',
    4: '#438375',
    5: '#704B62',
    6: '#3D4975'
};
