import { userResourceRepository } from '../kit/repository';
import { performAPIAction } from './BaseService';

const UserResourceMethods = ({ user, setErrorMessage, ...rest }) => {
    return {
        fetch: async () =>
            performAPIAction({
                ...rest,
                apiCall: userResourceRepository.fetch(),
                errorMessageId: 'DOCUMENT.INFO.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        create: resource =>
            performAPIAction({
                ...rest,
                apiCall: userResourceRepository.create(resource),
                successMessageId: 'DOCUMENT.ITEM.UPDATE.SUCCESS',
                errorMessageId: 'DOCUMENT.ITEM.UPDATE.ERROR'
            }),
        update: resource =>
            performAPIAction({
                ...rest,
                apiCall: userResourceRepository.update(resource.id, resource),
                successMessageId: 'DOCUMENT.ITEM.UPDATE.SUCCESS',
                errorMessageId: 'DOCUMENT.ITEM.UPDATE.ERROR'
            }),

        delete: resourceId =>
            performAPIAction({
                ...rest,
                apiCall: userResourceRepository.delete(resourceId),
                successMessageId: 'DOCUMENT.ITEM.UPDATE.SUCCESS',
                errorMessageId: 'DOCUMENT.ITEM.UPDATE.ERROR'
            }),
        fetchFile: (fileId, requestCallback) =>
            performAPIAction({
                ...rest,
                apiCall: userResourceRepository.fetchFile(fileId),
                errorMessageId: 'DOCUMENT.FILE.ACCESS.ERROR',
                callback: requestCallback
            })
    };
};

export const UserResourceService = ({
    intl,
    user,
    checkSession,
    addToastMessage,
    callback,
    setLoading = () => {},
    setErrorMessage = () => {}
}) => {
    const userResourceMethods = UserResourceMethods({
        intl,
        user,
        checkSession,
        addToastMessage,
        callback,
        setLoading,
        setErrorMessage
    });
    return { ...userResourceMethods };
};
