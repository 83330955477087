import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Card, CardBody, CardHeader } from '../../../partials/controls/Card';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import { UploadFilePreview } from '../../common/UploadFilePreview';
import {
    FILE_HEADER_SECTIONS_INDEX,
    FILE_HEADER_SUBSECTIONS_INDEX,
    IMPORT_CONFIRMATION_CLIENT_COLUMN_NAMES,
    IMPORT_CONFIRMATION_CONFIRMATION_COLUMN_NAMES,
    IMPORT_CONFIRMATION_THIRDPARTY_COLUMN_NAMES
} from './InitImportedConfirmations';
import { filterFileColumns } from '../../../helpers';

export const ImportConfirmationsPreview = (
    groupColumnsBySection,
    columnsNames,
    headers,
    optionalTagsNames,
    uploadedRows
) => {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
        // need to init formik values of the optional tags here because the ImportForm is memorise (cf   const { RenderForm: ImportForm } = useMemo(() => form, []);)
        // and because the optional tags are set later after sheet selection, then we need to do it here
        const setOptionalTagsIndexes = () => {
            optionalTagsNames &&
                Object.keys(optionalTagsNames)?.forEach(key => {
                    const tagValue = optionalTagsNames[key];
                    const [tagColumn] = columnsNames.filter(column => column.name === tagValue.id);
                    if (tagColumn) {
                        setFieldValue(key, tagColumn.id);
                    }
                });
        };

        setOptionalTagsIndexes();
    }, [optionalTagsNames]);

    return (
        <Card>
            {<CardHeader title={<FormattedMessage id={'IMPORT.CONFIRMATION.UPLOAD.CHECK.MAPPING.MESSAGE'} />} />}
            <CardBody>
                <Grid
                    container
                    justifyContent="space-around"
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        padding: '0 5%'
                    }}>
                    <Grid container item xs={12}>
                        <UploadFilePreview
                            allColumns={values}
                            setFieldValue={setFieldValue}
                            groupedColumns={groupColumnsBySection(
                                headers[FILE_HEADER_SECTIONS_INDEX],
                                headers[FILE_HEADER_SUBSECTIONS_INDEX],
                                columnsNames
                            )}
                            columnsHeaders={[
                                filterFileColumns(IMPORT_CONFIRMATION_CLIENT_COLUMN_NAMES),
                                filterFileColumns(IMPORT_CONFIRMATION_THIRDPARTY_COLUMN_NAMES),
                                {
                                    ...filterFileColumns(IMPORT_CONFIRMATION_CONFIRMATION_COLUMN_NAMES),
                                    ...optionalTagsNames
                                }
                            ]}
                            uploadedRows={uploadedRows}
                        />
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    );
};
