import { UIText } from './UIText';
import React from 'react';
import { Variant } from '@mui/material/styles/createTypography';
import { SxProps } from '@mui/material';

export const UISectionTitle = ({
    title,
    variant,
    my,
    sx = {}
}: {
    title: string;
    variant?: Variant;
    my?: number;
    sx?: SxProps;
}) => {
    return (
        <UIText
            text={title}
            sx={{ color: 'black', my: my ?? 1, fontWeight: 'bolder', ...sx }}
            variant={variant || 'h5'}
        />
    );
};
