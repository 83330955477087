import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Card, CardBody } from '../../../partials/controls/Card';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { COMPONENT_NAME } from '../constants';
import { initRobotConfig } from './InitRobotConfig';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    margin: {
        margin: theme.spacing(1)
    },
    textField: {
        width: '98%',
        paddingLeft: 25,
        paddingRight: 25
    },
    selectField: {
        width: '90%',
        paddingLeft: 25,
        paddingRight: 25
    },
    inputField: {
        width: '80%',
        paddingLeft: 25,
        paddingRight: 25
    },
    divider: {
        height: 1,
        margin: 1
    }
}));

export const RobotConfigComponent = props => {
    const { robotConfig, hasValidConfig } = props;
    const classes = useStyles();

    const [currentRobotConfig, setCurrentRobotConfig] = useState(initRobotConfig);
    const [hasBeenTrigger, setHasBeenTrigger] = useState(false);

    useEffect(() => {
        setCurrentRobotConfig(robotConfig);
    }, [robotConfig]);

    const buttonFormRef = useRef(null);
    const triggerButtonClick = () => buttonFormRef?.current?.click();
    props.robotRef.current = {
        saveForm() {
            triggerButtonClick();
        }
    };

    // Validation schema
    const RobotConfigSchema = Yup.object().shape({
        administrators: Yup.string()
            .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
            .max(250, <FormattedMessage id="INVALID.FIELD.MAX.SYMBOLS" values={{ min: 250 }} />)
            .required(<FormattedMessage id="ROBOT.CONFIG.VALIDATION.REQUIRED_FIELD.ADMINISTRATORS" />),
        engagementCodePattern: Yup.string()
            .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
            .max(250, <FormattedMessage id="INVALID.FIELD.MAX.SYMBOLS" values={{ min: 250 }} />)
            .required(<FormattedMessage id="ROBOT.CONFIG.VALIDATION.REQUIRED_FIELD.ENGAGEMENT_CODE_PATTERN" />),
        description: Yup.string()
            .min(2, <FormattedMessage id="INVALID.FIELD.MIN.SYMBOLS" values={{ min: 2 }} />)
            .max(250, <FormattedMessage id="INVALID.FIELD.MAX.SYMBOLS" values={{ min: 250 }} />)
    });

    const ROBOT_FIELDS = [
        {
            labelItem: { id: 'ROBOT.CONFIG.LABEL.ADMINISTRATORS', isRequired: true },
            TextFieldItem: { id: 'administrators', component: 'textarea', rows: 5, multiline: 5 }
        },
        {
            labelItem: { id: 'ROBOT.CONFIG.LABEL.ENGAGEMENT_CODE_PATTERN', isRequired: true },
            TextFieldItem: { id: 'engagementCodePattern', component: 'textarea', rows: 5, multiline: 5 }
        },
        {
            labelItem: { id: 'ROBOT.CONFIG.LABEL.DESCRIPTION', isRequired: true },
            TextFieldItem: { id: 'description', component: 'textarea', rows: 5, multiline: 5 }
        }
    ];

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Card style={{ width: '80%' }}>
                    <CardBody>
                        <Formik
                            enableReinitialize
                            validationSchema={RobotConfigSchema}
                            initialValues={currentRobotConfig}
                            onSubmit={async values => {
                                setHasBeenTrigger(false);
                                return props.handleRobotConfig(values);
                            }}>
                            {formikProps => {
                                if (Object.keys(formikProps?.touched).length > 0) {
                                    !hasBeenTrigger && hasValidConfig(COMPONENT_NAME.ROBOT_SETUP, false);
                                    setHasBeenTrigger(true);
                                }
                                return (
                                    <Form className="form">
                                        <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                            {React.Children.toArray(
                                                ROBOT_FIELDS?.map(field => (
                                                    <Grid
                                                        container
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            padding: '0 20%'
                                                        }}>
                                                        <Grid item xs={4}>
                                                            <label>
                                                                <FormattedMessage id={field?.labelItem?.id} />
                                                            </label>
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                width: '22em'
                                                            }}
                                                            item
                                                            xs={8}>
                                                            <Field
                                                                id={field?.TextFieldItem?.id}
                                                                name={field?.TextFieldItem?.id}
                                                                as={OutlinedInput}
                                                                type={field?.TextFieldItem?.type ?? ''}
                                                                autoComplete={
                                                                    field?.TextFieldItem?.type ? 'current-password' : ''
                                                                }
                                                                multiline={!!field?.TextFieldItem?.multiline ?? false}
                                                                rowsMax={field?.TextFieldItem?.multiline ?? 1}
                                                                className={clsx(classes.margin, classes.inputField)}
                                                                style={{ padding: 0, width: '100%' }}
                                                            />
                                                            <ErrorMessage name={field?.TextFieldItem?.id}>
                                                                {errorMessage =>
                                                                    FormattedErrorMessage(errorMessage, {
                                                                        margin: '0 .8em'
                                                                    })
                                                                }
                                                            </ErrorMessage>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            )}
                                        </Grid>
                                        <button
                                            type="submit"
                                            style={{ display: 'none' }}
                                            onClick={triggerButtonClick}
                                            ref={buttonFormRef}
                                        />
                                    </Form>
                                );
                            }}
                        </Formik>
                    </CardBody>
                </Card>
            </Grid>
        </>
    );
};

injectIntl(RobotConfigComponent);
