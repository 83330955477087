import { useEffect, useState } from 'react';
import { getGroupedStackableActions } from '../../../helpers';
import { sortSteps } from '../common';
import { ConfirmationStackableActionsSection } from '../workflow'

export function SelectImportParametersComponent(props) {
    const { parameters, handleSelectStackableStep } = props;
    const [sortedSteps, setSortedSteps] = useState([]);

    const { steps: stackableSteps } = getGroupedStackableActions(sortedSteps);

    useEffect(() => {
        parameters && setSortedSteps(sortSteps(parameters?.workflowActions));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ConfirmationStackableActionsSection
            workflowCurrentPosition={0}
            stackableSteps={stackableSteps}
            handleSelection={handleSelectStackableStep}
        />
    )
}