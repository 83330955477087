import { ContactAccessRightsMatrix } from './ContactAccessRightsMatrix';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { UIActionButton } from '../common/UIActionButton';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../i18n/i18Helper';
import { UIText } from '../common/UIText';
import { UIDialog } from '../common/UIDialog';

type REVOKE_ACCESS_TYPE = 'All' | 'Engagement';

interface ManageContactAccessRightsProps {
    contact: any;
    handleRevokeAccess: (contact: any) => void;
    handleDeleteEngagementAccess: (contact: any) => void;
    handleResetPassword: (contact: any) => void;
}

export const ManageContactAccessRights = ({
    contact,
    handleResetPassword,
    handleRevokeAccess,
    handleDeleteEngagementAccess
}: ManageContactAccessRightsProps) => {
    const intl = useIntl();

    const [revokeDialogOpen, setRevokeDialogOpen] = useState(false);

    const handleUpdateRevokeAccess = () => {
        setRevokeDialogOpen(!revokeDialogOpen);
    };

    const handleValidate = (revokeOption: REVOKE_ACCESS_TYPE) => () => {
        revokeOption === 'All' ? handleRevokeAccess(contact) : handleDeleteEngagementAccess(contact);
        handleUpdateRevokeAccess();
    };

    const onRestPassword = () => {
        handleResetPassword(contact);
    };

    return (
        <>
            <UIText
                text={getLocalizedString(intl, 'SETTINGS.SECTION.CONTACT.MANAGE.ACCESS.MESSAGE', {
                    contact: `${contact.firstName ?? ''} ${contact.lastName ?? ''}`
                })}
            />

            <Box sx={{ display: 'flex', flexDirection: 'row', gridGap: 5, justifyContent: 'center', my: 3 }}>
                <UIActionButton
                    title={getLocalizedString(intl, 'SETTINGS.SECTION.CONTACT.RESET.PASSWORD')}
                    onClick={onRestPassword}
                />
                <UIActionButton
                    display="secondary"
                    title={getLocalizedString(intl, 'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS')}
                    onClick={handleUpdateRevokeAccess}
                />
            </Box>

            <ContactAccessRightsMatrix accessRights={contact.accessRights} />

            {revokeDialogOpen && (
                <UIDialog
                    actions={
                        <>
                            <UIActionButton
                                style={{ width: '125px' }}
                                title={getLocalizedString(
                                    intl,
                                    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.CURRENT.ENGAGEMENT'
                                )}
                                onClick={handleValidate('Engagement')}
                            />

                            <UIActionButton
                                style={{ width: '125px' }}
                                title={getLocalizedString(
                                    intl,
                                    'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.ALL.ENGAGEMENTS'
                                )}
                                onClick={handleValidate('All')}
                            />
                        </>
                    }
                    titleId={'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.DIALOG.TITLE'}
                    open={revokeDialogOpen}
                    onClose={handleUpdateRevokeAccess}>
                    <UIText text={getLocalizedString(intl, 'SETTINGS.SECTION.CONTACT.REVOKE.ACCESS.DIALOG.MESSAGE')} />
                </UIDialog>
            )}
        </>
    );
};
