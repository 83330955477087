import { createContext } from 'react';
import { CONTACT_TYPE } from '../types/types';

interface ContactContextSpec {
    createContact?: (contact: any) => void;
    updateContact?: (contact: any) => void;
    revokeAccess?: (contact: any) => void;
    deleteAccess?: (contact: any) => void;
}

const contactContextDefaultValue: { [key in CONTACT_TYPE]: ContactContextSpec | null } = {
    [CONTACT_TYPE.CLIENT]: {
        createContact: undefined,
        updateContact: undefined,
        revokeAccess: undefined,
        deleteAccess: undefined
    },
    [CONTACT_TYPE.THIRD_PARTY]: {
        createContact: undefined,
        updateContact: undefined,
        revokeAccess: undefined,
        deleteAccess: undefined
    }
};
export const ContactContext = createContext(contactContextDefaultValue);
