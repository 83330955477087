import React from 'react';
import { Form, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import TextInput from '../textField/TextInput';
import clsx from 'clsx';
import { EditConfirmationSection } from '../confirmation';

function ThirdPartyDetailsComponent({ company, handleSubmit = undefined, classes, variant, disabled }) {
    const uiOptions = {
        component: TextInput,
        className: clsx(classes.margin, classes.textField),
        variant: variant,
        disabled: disabled
    };

    const fields = [
        {
            labelItem: { id: 'EDIT_CLIENT.PLACEHOLDER.NAME' },
            TextFieldItem: { id: 'name', ...uiOptions }
        },
        {
            labelItem: { id: 'EDIT_CLIENT.PLACEHOLDER.ADDRESS' },
            TextFieldItem: { id: 'address', ...uiOptions }
        },
        {
            labelItem: { id: 'EDIT_CLIENT.PLACEHOLDER.CITY' },
            TextFieldItem: { id: 'city', ...uiOptions }
        },
        {
            labelItem: { id: 'EDIT_CLIENT.PLACEHOLDER.ZIPCODE' },
            TextFieldItem: { id: 'zipCode', ...uiOptions }
        },
        {
            labelItem: { id: 'EDIT_CLIENT.PLACEHOLDER.COUNTRY' },
            TextFieldItem: { id: 'countryText', ...uiOptions }
        }
    ];

    return (
        <>
            <Formik enableReinitialize={true} initialValues={company} onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                    <>
                        <Form className="form form-label-right">
                            <EditConfirmationSection fields={fields} />
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}

export const ThirdPartyDetails = injectIntl(ThirdPartyDetailsComponent);
