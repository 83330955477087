import { appApiUrl } from '../../config/appConfiguration';
import { parseResponse, parseResponseShort } from '../parsers';
import { parseRobotConfiguration, parseRobotConfigurations } from './setupParsers';
import { Repository } from '../Repository';

class RobotConfigRepository extends Repository {
    constructor() {
        super(appApiUrl);
    }

    fetchAll = async () => {
        let result = await this.network.get(`robotconfiguration`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseRobotConfigurations);
        return result;
    };

    create = async (configuration) => {
        let result = await this.network.post(`robotconfiguration`, configuration, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponseShort);
        return result;
    };

    fetch = async (configurationId) => {
        let result = await this.network.get(`robotconfiguration/${configurationId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseRobotConfiguration);
        return result;
    };

    update = async (configurationId, configuration) => {
        let result = await this.network.put(`robotconfiguration/${configurationId}`, configuration, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    delete = async (configurationId) => {
        let result = await this.network.delete(`robotconfiguration/${configurationId}`, null, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseResponse);
        return result;
    };

    fetchByCluster = async (clusterId) => {
        let result = await this.network.get(`robotconfiguration/cluster/${clusterId}`, {
            headers: this.buildHeaders()
        });

        result.tryParsingValue(parseRobotConfiguration);
        return result;
    };
}

export const robotConfigRepository = new RobotConfigRepository();
