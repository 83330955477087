import * as React from 'react';
import { MouseEventHandler } from 'react';
import { Box, IconButton } from '@mui/material';
import { UIDropDownMenu, UIDropDownMenuEntryProps } from '../../common/UIDropDownMenu';
import { useLang, setLanguage } from '../../../i18n';
import { toAbsoluteUrl } from '../../../helpers/AssetsHelpers';
import { UIText } from '../../common/UIText';

const languages = [
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('/media/svg/flags/260-united-kingdom.svg')
    },
    {
        lang: 'fr',
        name: 'Français',
        flag: toAbsoluteUrl('/media/svg/flags/195-france.svg')
    }
];

export const LanguageTopBarItem = () => {
    const lang = useLang();
    const currentLanguage = languages.find(x => x.lang === lang);

    const menuEntries: UIDropDownMenuEntryProps[] = languages.map(language => ({
        titleComponent: () => (
            <>
                <img src={language.flag} alt={language.name} style={classes.flag} />
                <UIText text={language.name} sx={{ marginX: 2 }} />
            </>
        ),
        id: language.lang,
        handler: () => onLanguageSelect(language)
    }));

    const onLanguageSelect = (language: any) => {
        setLanguage(language.lang);
    };

    const LanguageTitleComponent = ({ onClick }: { onClick: MouseEventHandler<any> }) => {
        return (
            <Box sx={classes.titleContainer}>
                <IconButton onClick={onClick}>
                    <img alt="language-flag" src={currentLanguage?.flag} style={classes.flag} />
                </IconButton>
            </Box>
        );
    };

    return <UIDropDownMenu id="topBarLanguage" entries={menuEntries} titleComponent={LanguageTitleComponent} />;
};

const classes = {
    flag: {
        width: '2Opx',
        height: '20px',
        borderRadius: 5
    },
    titleContainer: {
        marginTop: 1.3,
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    }
};
