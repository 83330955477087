import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedErrorMessage } from '../../errorMessage/ErrorMessage';
import { Alert } from '@mui/material';
import { StorageSetup, MailingSetup, RobotSetup } from '../../../screens/setup';
import { COMPONENT_NAME, COMPONENT_MSG } from '../constants';
import { configurationRepository, oAuth2Repository } from '../../../kit/repository';
import { AccordionSections } from '../../../helpers/AccordionHelper';
import { getLocalizedString } from '../../../i18n/i18Helper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}));

export const TechnicalConfigComponent = props => {
    const classes = useStyles();
    const intl = useIntl();

    const mailingConfigFormRef = useRef();
    const robotConfigFormRef = useRef();

    const [configErrorMessages, setConfigErrorMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isStorageConfigValid, setIsStorageConfigValid] = useState(false);
    const [isStorageConfigExpanded, setIsStorageConfigExpanded] = useState(false);

    const [isMailingConfigValid, setIsMailingConfigValid] = useState(false);
    const [isMailingExpanded, setIsMailingExpanded] = useState(false);

    const [isRobotConfigValid, setIsRobotConfigValid] = useState(false);
    const [isRobotExpanded, setIsRobotExpanded] = useState(false);

    const handleCloseErrorMessage = msgId => {
        setConfigErrorMessages(configErrorMessages.filter(x => x !== msgId));
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const { parsedValue } = await configurationRepository.getAll();

            if (parsedValue) {
                setIsStorageConfigValid(parsedValue?.isValidStorageConfiguration ?? false);
                setIsMailingConfigValid(parsedValue?.isValidMailingConfiguration ?? false);
                setIsRobotConfigValid(parsedValue?.isValidRobotConfiguration ?? false);
            }
            setIsLoading(false);
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const isAllSectionsValid = isStorageConfigValid && isMailingConfigValid && isRobotConfigValid;
        props.toggleCanHandleNext(isAllSectionsValid);
    }, [isMailingConfigValid, isStorageConfigValid, isRobotConfigValid]); // eslint-disable-line react-hooks/exhaustive-deps

    const performValidationActions = (componentName, value, setIsConfigValid, errorMessages, setErrorMessages) => {
        setIsConfigValid(value);
        if (!value) {
            if (errorMessages.filter(x => x === componentName).length === 0)
                setErrorMessages([...errorMessages, componentName]);
        } else {
            // Close error message
            setErrorMessages(errorMessages.filter(x => x !== componentName));
        }
    };

    const hasValidConfig = (componentName, value) => {
        switch (componentName) {
            case COMPONENT_NAME.STORAGE_SETUP:
                performValidationActions(
                    COMPONENT_MSG.STORAGE,
                    value,
                    setIsStorageConfigValid,
                    configErrorMessages,
                    setConfigErrorMessages
                );
                break;
            case COMPONENT_NAME.MAILING_SETUP:
                performValidationActions(
                    COMPONENT_MSG.MAILING,
                    value,
                    setIsMailingConfigValid,
                    configErrorMessages,
                    setConfigErrorMessages
                );
                break;
            case COMPONENT_NAME.ROBOT_SETUP:
                performValidationActions(
                    COMPONENT_MSG.ROBOT,
                    value,
                    setIsRobotConfigValid,
                    configErrorMessages,
                    setConfigErrorMessages
                );
                break;

            default:
                break;
        }
    };

    const handleSaveMailingConfig = () => {
        if (mailingConfigFormRef && mailingConfigFormRef.current) {
            mailingConfigFormRef.current.saveForm();
        }
    };

    const handleSaveRobotConfig = () => {
        if (robotConfigFormRef && robotConfigFormRef.current) {
            robotConfigFormRef.current.saveForm();
        }
    };

    const handleContactProvider = async () => {
        const result = await oAuth2Repository.getAuthorisation();
        window.open(result.body);
    };

    const TECHNICAL_CONFIG = [
        {
            hidden: true,
            isExpanded: isStorageConfigExpanded,
            isConfigValid: isStorageConfigValid,
            setExpanded: setIsStorageConfigExpanded,
            checkboxLabel: getLocalizedString(intl, 'TECHNICAL.CONFIGURATION.STORAGE.SUBSECTION.TITLE'),
            isSaveEnabled: false,
            isCreateEnabled: false,
            component: (
                <StorageSetup hasValidConfig={hasValidConfig} setIsStorageConfigExpanded={setIsStorageConfigExpanded} />
            )
        },
        {
            isExpanded: isMailingExpanded,
            // TODO: isConfigValid is disabled to keep save button displayed, trigger mechanism required in MailingConfig component
            isConfigValid: false,   // isMailingConfigValid,
            setExpanded: setIsMailingExpanded,
            checkboxLabel: getLocalizedString(intl, 'TECHNICAL.CONFIGURATION.MAILING.SUBSECTION.TITLE'),
            isSaveEnabled: true,
            saveLabel: getLocalizedString(intl, 'TECHNICAL.CONFIGURATION.BUTTON.SAVE.LABEL'),
            handleSave: handleSaveMailingConfig,
            isCreateEnabled: false,
            isCustomActionEnabled: true,
            customActionLabel: getLocalizedString(intl, 'TECHNICAL.CONFIGURATION.BUTTON.TOKEN.LABEL'),
            handleCustomAction: handleContactProvider,
            component: (
                <MailingSetup
                    mailingRef={mailingConfigFormRef}
                    hasValidConfig={hasValidConfig}
                    setIsMailingExpanded={setIsMailingExpanded}
                />
            )
        },
        {
            isExpanded: isRobotExpanded,
            isConfigValid: isRobotConfigValid,
            setExpanded: setIsRobotExpanded,
            checkboxLabel: getLocalizedString(intl, 'TECHNICAL.CONFIGURATION.ROBOT.SUBSECTION.TITLE'),
            isSaveEnabled: true,
            saveLabel: getLocalizedString(intl, 'TECHNICAL.CONFIGURATION.BUTTON.SAVE.LABEL'),
            handleSave: handleSaveRobotConfig,
            isCreateEnabled: false,
            component: (
                <RobotSetup
                    robotRef={robotConfigFormRef}
                    hasValidConfig={hasValidConfig}
                    setIsRobotExpanded={setIsRobotExpanded}
                />
            )
        }
    ];

    return (
        <>
            <div className={classes.root}>
                {React.Children.toArray(
                    configErrorMessages.map(
                        msgId =>
                            msgId && (
                                <Alert severity="error" onClose={() => handleCloseErrorMessage(msgId)}>
                                    {FormattedErrorMessage(getLocalizedString(intl, msgId))}
                                </Alert>
                            )
                    )
                )}

                <AccordionSections sections={TECHNICAL_CONFIG} isLoading={isLoading} />
            </div>
        </>
    );
};
