import { UIScaffoldChildContext } from '../../../../scaffolder/UIScaffold';
import { useIntl } from 'react-intl';
import { CompanyService, ContactService } from '../../../../services';
import { COMPANY_TYPE, SessionStatus } from '../../../../kit/utils/constants';
import { ClientRequest, CONTACT_TYPE } from '../../../../types/types';
import { ContactContext, ThirdPartyCompanyContext } from '../../../../context';
import { hasThirdParty } from '../../../../helpers';
import { ConfirmationThirdPartySection } from '../../../confirmation';
import { makeStyles } from '@material-ui/core/styles';
import { SelectThirdPartyComponent } from '../../../confirmation/manual';
import { ServiceResult } from '../../../../kit/network/ServiceResult';
import { getSessionStatus } from '../../../../enhancers/wichCheckSession';

interface ClientConfirmationThirdPartyStepProps {
    request: ClientRequest;
    handleRequestUpdate: (values: any) => void;
}

export const ClientRequestThirdPartyStep = ({
    request,
    setLoading,
    setToast,
    setSessionExpired,
    handleRequestUpdate
}: ClientConfirmationThirdPartyStepProps & UIScaffoldChildContext): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles();

    const managerConfig = {
        intl: intl,
        checkSession: (result: ServiceResult) => {
            const status = getSessionStatus(result);
            setSessionExpired(status === SessionStatus.invalid);
            return status;
        },
        addToastMessage: (toast: any) => {
            setToast(toast.title, toast.description, toast.type);
        },
        callback: () => {}
    };
    const companyManager = CompanyService({
        engagementId: request.engagementId,
        target: [COMPANY_TYPE.THIRDPARTY],
        ...managerConfig
    });

    const contactManager = ContactService({
        target: [CONTACT_TYPE.THIRD_PARTY],
        setLoading: setLoading,
        ...managerConfig
    });

    return (
        <ThirdPartyCompanyContext.Provider
            value={{
                createCompany: companyManager.createThirdParty,
                updateCompany: companyManager.updateThirdParty,
                searchCompany: companyManager.searchThirdParties
            }}>
            <ContactContext.Provider
                value={{
                    [CONTACT_TYPE.THIRD_PARTY]: {
                        createContact: contactManager.createThirdPartyContact,
                        updateContact: contactManager.updateThirdPartyContact
                    },
                    [CONTACT_TYPE.CLIENT]: null
                }}>
                {hasThirdParty(request) ? (
                    <ConfirmationThirdPartySection
                        confirmationEngagementId={request.engagementId}
                        isThirdPartyLoaded
                        isConfirmationLoaded={!!request.id}
                        thirdPartyCompany={request.thirdPartyCompany}
                        thirdPartyContacts={request.thirdPartyContacts}
                        disabled={!request.isEditable}
                        classes={classes}
                        intl={intl}
                        handleConfirmationUpdate={handleRequestUpdate}
                    />
                ) : (
                    <SelectThirdPartyComponent
                        intl={intl}
                        loading={false}
                        confirmation={request}
                        handleConfirmationUpdate={handleRequestUpdate}
                    />
                )}
            </ContactContext.Provider>
        </ThirdPartyCompanyContext.Provider>
    );
};

const useStyles = makeStyles(theme => ({
    backButton: {
        marginRight: theme.spacing(1)
    },
    root: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    margin: {
        margin: theme.spacing(0.5)
    },
    textField: {
        width: '100%',
        height: '5%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    },
    inline: {
        display: 'inline'
    },
    actionsContainer: {
        marginTop: theme.spacing(2)
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    }
}));
