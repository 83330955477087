import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';

interface DocumentTableSelectProps {
    item?: any;
    field: string;
    component: any;
    onInputChange?: (inputValue: string) => void;
    handleUpdate: (values: Record<string, any>) => void;
    options: any[];
    sx?: any;
    disabled?: boolean;
    hidden?: boolean;
}

export const DocumentTableSelect = ({
    item,
    field,
    component: SelectComponent,
    handleUpdate,
    onInputChange,
    options,
    sx,
    hidden,
    disabled
}: DocumentTableSelectProps) => {
    const formik = useFormik({
        initialValues: item,
        onSubmit: handleUpdate,
        enableReinitialize: true // need to enable Reinitialize otherwise we have formik issue that keeps another item data
    });

    const handleChanged = (selectedValue: any) => {
        selectedValue.id
            ? formik.setFieldValue(field, selectedValue.id) // for confirmation and engagement we receive the selected option (it has an id)
            : formik.handleChange(selectedValue); //for DocumentTypes we receive an event
        // force formik to submit (execute onSubmit method
        formik.submitForm();
    };

    return (
        <FormikProvider value={formik}>
            <SelectComponent
                hidden={hidden}
                disabled={disabled}
                sx={sx}
                id={`${field}-${item.id}`}
                name={field}
                onChange={handleChanged}
                onInputChange={onInputChange}
                options={options}
            />
        </FormikProvider>
    );
};
