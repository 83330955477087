import React from 'react';
import { useIntl } from 'react-intl';
import { EntityDetails } from '../common/EntityDetails';
import { getLocalizedString } from '../../i18n/i18Helper';
import { localizedReadableFullDate } from '../../kit/utils/DateUtil.js';

export const ContactDetails = ({ contact }) => {
    const intl = useIntl();

    const INFORMATION_FIELDS = [
        {
            title: 'EDIT_CONTACT.LABEL.JOBTITLE',
            attribute: 'jobTitle'
        },
        {
            title: 'EDIT_CONTACT.LABEL.FIRSTNAME',
            attribute: 'firstName'
        },
        {
            title: 'EDIT_CONTACT.LABEL.LASTNAME',
            attribute: 'lastName'
        },
        {
            title: 'EDIT_CONTACT.LABEL.EMAIL',
            attribute: 'email',
            subAttribute: {
                title: getLocalizedString(intl, 'CONFIRMATION.CONTACT.EMAIL.INVALID.MESSAGE', {
                    email: contact?.email,
                    date: localizedReadableFullDate(intl, contact?.isInvalidAddressDate)
                }),
                attribute: 'isInvalidAddress'
            }
        },
    ];

    return <EntityDetails fieldsList={INFORMATION_FIELDS} entity={contact} />;
};
