import { parseAppUser } from '../user/userParsers';
import { parseConfirmationCategories } from '../confirmation/confirmationParsers';

export const parseWorkflow = json => {
    return {
        id: json.id,
        name: json.name,
        isStacked: json.isStacked,
        description: json.description,
        created: json.created,
        creatorId: json.creatorId,
        creator: json.creator ? parseAppUser(json.creator) : {},
        updated: json.updated,
        updatorId: json.updatorId,
        updator: json.updator ? parseAppUser(json.updator) : {},
        workflowActions: json.workflowActions ? parseWorkflowActions(json.workflowActions) : [],
        confirmationCategories: json.confirmationCategories
            ? parseConfirmationCategories(json.confirmationCategories)
            : []
    };
};

export const parseWorkflows = json => {
    return json ? Object.values(json).map(x => parseWorkflow(x)) : [];
};

export const parseWorkflowAction = json => {
    return {
        id: json.id,
        workflowActionId: json.workflowActionId,
        workflowId: json.workflowId,
        name: json.name,
        description: json.description,
        eWorkflowAction: json.eWorkflowAction,
        isStackable: json.isStackable,
        position: json.position,
        mandatory: json.mandatory,
        isSelected: true,
        isActive: false
    };
};

export const parseWorkflowActions = json => {
    return json ? Object.values(json).map(x => parseWorkflowAction(x)) : [];
};
