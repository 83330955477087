import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { withCheckSession } from '../../enhancers/wichCheckSession';
import { CircularProgress } from '@mui/material';
import { WorkflowConfigComponent } from '../../components/setup';
import {
    fetchData,
    fetchConfirmationCategories,
    fetchConfigurationChecks,
    WorkflowService
} from '../../services';

class WorkflowSetupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkSessionResults: []
        };

        this.workflowManager = WorkflowService({
            intl: this.props.intl,
            checkSession: this.props.checkSession,
            addToastMessage: this.props.addToastMessage,
            setLoading: this.setLoading,
            callback: async (isSuccess, _data, _) => isSuccess && await this.fetchWorkflows({clusterId: this.props.currentClusterId})
        });
    };

    setLoading = isLoading => {
        this.setState({ loading: isLoading });
    };

    fetchWorkflows = async filters => {
        const result = await this.workflowManager.fetchAll(filters);
        if (result?.isSuccess) {
            this.setState({ workflows: Object.values(result.parsedValue) });
        } 
    };

    fetchWorkflowActions = async () => {
        const result = await this.workflowManager.fetchAll();
        if (result?.isSuccess) {
            this.setState({ workflowActions: Object.values(result.parsedValue) });
        } 
    };

    async componentDidMount() {
        await fetchData(this, [
            () => fetchConfirmationCategories(this),
            () => fetchConfigurationChecks(this, {clusterId: this.props.currentClusterId}),
            () => this.fetchWorkflows({clusterId: this.props.currentClusterId}),
            () => this.fetchWorkflowActions()
        ]);
    };

    async componentDidUpdate(_prevProps, prevState, _) {
        if (this.props.currentClusterId !== _prevProps.currentClusterId) {
            if (!this.state.loading)
                await fetchData(this, [
                    () => fetchConfigurationChecks(this, {clusterId: this.props.currentClusterId}),
                    () => this.fetchWorkflows({clusterId: this.props.currentClusterId}),
                ]);
        }
    };

    render() {
        return (
            <>
                {this.state.workflows ? (
                    <WorkflowConfigComponent
                        configurationsChecks={this.state.configurationsChecks}
                        workflows={this.state.workflows}
                        workflowActions={this.state.workflowActions}
                        confirmationCategories={this.state.confirmationCategories}
                        loading={this.state.loading}
                        updateWorkflow={this.workflowManager.update}
                    />
                ) : (
                    <div className="fullCenter">
                        <CircularProgress />
                    </div>
                )}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const WorkflowSetup = compose(
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(WorkflowSetupComponent));
