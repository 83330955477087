import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../partials/controls/Card';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Button, Box } from '@material-ui/core';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { initStorageDefault } from './InitStorageDefault';
import { StorageDefaultComponent } from './StorageDefault';
import { StorageBaseConfigComponent } from '../../../setup';
import { COMPONENT_NAME } from '../../constants';
import { UIDialog } from '../../../common/UIDialog';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    box: {
        display: 'flex'
    },
    boxButton: {
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0.5)
    }
}));

/**
 * Storage Default wrapper contain creation/edition/activate/priority of use.
 *
 * @param {*} props
 * @returns
 */
export function StorageDefaultWrapper(props) {
    const { storageConfiguration, handleStorageConfig, hasValidConfig } = props;

    const classes = useStyles();
    const sbmtConfRef = useRef();

    const [storageDefault, setStorageConfig] = useState(initStorageDefault);
    const [openConfig, setOpenConfig] = useState(false);

    useEffect(() => {
        setStorageConfig(storageConfiguration);
    }, [storageConfiguration]);

    const handlePopUpConfigOpening = () => setOpenConfig(!openConfig);

    const handleClickSave = () => {
        if (sbmtConfRef && sbmtConfRef.current) {
            sbmtConfRef.current.saveForm();
        }
    };

    const handleSubmit = values => {
        setStorageConfig({ ...storageDefault, ...values });
        handleStorageConfig({ ...storageDefault, ...values });
        setOpenConfig(!openConfig);
    };

    const handleResetConfig = () => {
        setStorageConfig(initStorageDefault);
    };

    const handleSaveConfig = () => {
        handleStorageConfig(storageDefault);
    };

    const handleUpdateConfig = values => {
        hasValidConfig(COMPONENT_NAME.STORAGE_SETUP, false);
        setStorageConfig({ ...storageDefault, ...values });
    };

    return (
        <>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid container alignItems="stretch" justifyContent="center">
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader
                                title={
                                    <FormattedMessage id="STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.CREATE.TITLE" />
                                }>
                                <CardHeaderToolbar>
                                    {storageDefault?.targetPath !== '' ? (
                                        <Box component="span" m={1} className={clsx(classes.box, classes.boxButton)}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                className={classes.button}
                                                onClick={handleResetConfig}>
                                                <FormattedMessage id="STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.RESET.LABEL" />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                className={classes.button}
                                                onClick={handleSaveConfig}>
                                                <FormattedMessage id="STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.SAVE.LABEL" />
                                            </Button>
                                        </Box>
                                    ) : null}
                                </CardHeaderToolbar>
                            </CardHeader>
                            <CardBody>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={6}>
                                    <Grid item>
                                        <Button
                                            startIcon={<SettingsIcon />}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handlePopUpConfigOpening}>
                                            {storageDefault?.id ? (
                                                <FormattedMessage
                                                    id={'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.EDIT.LABEL'}
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id={'STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.BUTTON.CREATE.LABEL'}
                                                />
                                            )}
                                        </Button>

                                        {openConfig && (
                                            <UIDialog
                                                {...props}
                                                open={openConfig}
                                                labelId="STORAGE.DEFAULT.CONFIG.WRAPPER.SUBSECTION.TITLE.CREATE.LABEL"
                                                onSave={handleClickSave}
                                                onClose={() => setOpenConfig(!openConfig)}>
                                                <StorageDefaultComponent
                                                    {...props}
                                                    storageDefault={storageDefault}
                                                    disabled={false}
                                                    handleSubmit={handleSubmit}
                                                    hasValidConfig={hasValidConfig}
                                                    sbmtConfRef={sbmtConfRef}
                                                />
                                            </UIDialog>
                                        )}
                                    </Grid>

                                    <Grid item>
                                        <StorageBaseConfigComponent
                                            storageConfiguration={storageDefault}
                                            handleUpdateConfig={handleUpdateConfig}
                                        />
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default injectIntl(StorageDefaultWrapper);
