import React from 'react';
import PropTypes from 'prop-types';
import { StepConnector, stepConnectorClasses, styled } from '@mui/material';

import {
    CheckCircleRounded as CheckCircleRoundedIcon,
    RadioButtonUncheckedRounded as RadioButtonUncheckedRoundedIcon,
    AdjustRounded as AdjustRoundedIcon,
    FeaturedPlayList as FeaturedPlayListIcon,
    PlaylistAdd as PlaylistAddIcon,
    ContactMail as ContactMailIcon,
    GroupAdd as GroupAddIcon,
    Settings as SettingsIcon
} from '@mui/icons-material';

import { Palette } from '../styles/colors';

export const ProcessConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 16,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,rgb(0, 113, 206) 0%,rgb(61, 131, 117) 100%)'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: Palette.defaultPrimaryColor
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1
    }
}));

export const ProcessStepIconRoot = styled('div')(
    ({ theme, ownerState, style = { active: {}, completed: {}, root: {} } }) => ({
        backgroundColor: Palette.white,
        marginTop: 8,
        zIndex: 1,
        height: 22,
        width: 22,
        color: '#eaeaf0',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#eaeaf0',
            boxShadow: ownerState.highlight && '0 4px 10px 0 rgba(0,0,0,.25)',
            ...style.active
        }),
        ...(ownerState.completed && {
            color: Palette.defaultPrimaryColor,
            ...style.completed
        }),
        ...style.root
    })
);

export function ProcessStepIcon(props) {
    const { active, completed, className, highlight } = props;
    const icon = (
        <div>
            {completed ? (
                <CheckCircleRoundedIcon />
            ) : active ? (
                <AdjustRoundedIcon />
            ) : (
                <RadioButtonUncheckedRoundedIcon />
            )}
        </div>
    );

    return (
        <ProcessStepIconRoot ownerState={{ completed, active, highlight }} className={className}>
            {icon}
        </ProcessStepIconRoot>
    );
}

export const HighlightedProcessStepIcon = props => {
    const newProps = { ...props, highlight: true };
    return <ProcessStepIcon {...newProps} />;
};

ProcessStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool
};

/**
 * Color style connector.
 */
export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 32
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,rgb(10,31,143) 0%,rgb(0,113,206) 50%,rgb(120,120,120) 100%)'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'linear-gradient( 95deg,rgb(10,31,143) 0%,rgb(0,113,206) 50%,rgb(120,120,120) 100%)'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1
    }
}));

export function ColorlibStepIcon(props) {
    const { active, completed, highlight = true, className } = props;
    const icons = {
        1: <FeaturedPlayListIcon />,
        2: <PlaylistAddIcon />,
        3: <ContactMailIcon />,
        4: <GroupAddIcon />,
        5: <SettingsIcon />
    };

    return (
        <ProcessStepIconRoot
            ownerState={{ completed, active, highlight }}
            className={className}
            style={ColorLibIconStyle}>
            <div>{icons[String(props.icon)]}</div>
        </ProcessStepIconRoot>
    );
}

const ColorLibIconStyle = {
    root: {
        backgroundColor: '#ccc',
        color: '#fff',
        width: 50,
        height: 50
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, rgb(10,31,143) 0%, rgb(0,113,206) 50%, rgb(120,120,120) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    completed: {
        backgroundImage: 'linear-gradient( 136deg, rgb(10,31,143) 0%, rgb(0,113,206) 50%, rgb(120,120,120) 100%)'
    }
};
