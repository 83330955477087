import React from 'react';
import { MZRSearchBar } from '../../common/MZRSearchBar';
import { injectIntl } from 'react-intl';

const onFocus = ({ focused: focusedCompany }, intl) => {
    const contacts =
        focusedCompany.contacts?.length > 0
            ? `${intl.formatMessage({
                  id: 'MANUAL.CREATE.CONFIRMATION.COMPANY.CONTACTS'
              })}: ${focusedCompany.contacts.reduce((prev, curr) => `${prev} ${curr.email} |`, '')}`
            : intl.formatMessage({ id: 'MANUAL.CREATE.CONFIRMATION.COMPANY.NO.CONTACTS' });

    const message = `${focusedCompany.name}: ${focusedCompany.address}\n ${focusedCompany.zipCode}, ${focusedCompany.city}, ${focusedCompany.countryText} \n ${contacts}`;

    return message;
};

const getOptionLabel = option => `${option.name}: ${option.address}, ${option.city} ${option.zipCode}`;

const getOptionValue = option => option.id;

function CompanySearchSelectComponent(props) {
    const {
        options,
        onSelectionChange,
        intl,
        selectedCompany,
        handleCreate,
        handleDelete,
        disabled,
        isClearable = true,
        isAreaFocusVisible = true,
        optionLabelDisplay = getOptionLabel
    } = props;

    return (
        <MZRSearchBar
            isAreaFocusVisible={isAreaFocusVisible}
            disabled={disabled}
            createLabel={intl.formatMessage({ id: 'MANUAL.CREATE.CONFIRMATION.COMPANY.NOT.EXISTS' })}
            handleCreate={handleCreate}
            value={selectedCompany}
            options={options}
            isClearable={isClearable}
            onSelectionChange={onSelectionChange}
            onClear={handleDelete}
            onCustomFocus={value => onFocus(value, intl)}
            getOptionValue={getOptionValue}
            getOptionLabel={optionLabelDisplay}
            placeholder={intl.formatMessage({ id: 'MANUAL.CREATE.CONFIRMATION.COMPANY.SEARCH_BAR.PLACEHOLDER' })}
        />
    );
}

export const CompanySearchableSelect = injectIntl(CompanySearchSelectComponent);
