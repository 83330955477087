import { parseCompany } from '../company/companyParsers';
import { CLIENT_CONTACT_INVITATION_STATUS } from '../../../types/types';

export const parseContacts = json => {
    return json ? Object.values(json).map(x => parseContact(x)) : [];
};

export const parseContact = json => {
    return {
        id: json.id,
        engagementCompanyId: json.engagementCompanyId,
        jobTitle: json.jobTitle,
        title: json.title,
        firstName: json.firstName,
        lastName: json.lastName,
        fullname: json.firstName && json.lastName ? json.firstName + ' ' + json.lastName : null,
        email: json.email,
        description: json.description,
        languageId: json.languageId,
        dialingCode: json.dialingCode,
        phone: json.phone,
        clusterId: json.clusterId,
        countryCode: json.countryCode,
        city: json.city,
        created: json.created,
        creatorId: json.creatorId,
        archived: json.archived,
        isSignatory: json.isSignatory,
        isRecipient: json.isRecipient,
        contactType: json.contactType,
        engagementCompany: json.engagementCompany ? parseCompany(json.engagementCompany) : null,
        invitationStatus: json.invitationStatus
            ? parseContactStatus(json.invitationStatus)
            : { status: CLIENT_CONTACT_INVITATION_STATUS.NOT_INVITED, lastConnection: null },
        accessRights: json.accessRights?.map(right => parseAccessRight(right)) ?? accessDataMock, // TODO discuss with Maxime
        isInvalidAddress: json.isInvalidAddress,
        isInvalidAddressDate: json.isInvalidAddressDate,
    };
};

const parseContactStatus = json => ({
    status: json.status ?? CLIENT_CONTACT_INVITATION_STATUS.NOT_INVITED,
    lastConnection: json.lastConnection
});

const parseAccessRight = json => ({
    company: parseCompany(json.company),
    rights: json.rights?.map(right => parseRight(right))
});

const parseRight = right => ({
    type: right.type, // uploadDocument, comment
    name: right.name,
    active: right.active
});

let accessDataMock = [
    {
        company: { name: 'entity1', id: 'entity1Id' },
        rights: [
            {
                type: 'UploadDocument',
                name: 'Upload document',
                active: true
            },
            { type: 'Comment', name: 'Comment', active: true }
        ]
    },
    {
        company: { name: 'entity2', id: 'entity2Id' },
        rights: [
            {
                type: 'UploadDocument',
                name: 'Upload document',
                active: false
            },
            { type: 'Comment', name: 'Comment', active: false }
        ]
    },
    {
        company: { name: 'entity3', id: 'entity3Id' },
        rights: [
            {
                type: 'UploadDocument',
                name: 'Upload document',
                active: false
            },
            { type: 'Comment', name: 'Comment', active: true }
        ]
    },
    {
        company: { name: 'entity4', id: 'entity4Id' },
        rights: [
            {
                type: 'UploadDocument',
                name: 'Upload document',
                active: false
            },
            { type: 'Comment', name: 'Comment', active: false }
        ]
    },
    {
        company: { name: 'entity5', id: 'entity5Id' },
        rights: [
            {
                type: 'UploadDocument',
                name: 'Upload document',
                active: false
            },
            { type: 'Comment', name: 'Comment', active: true }
        ]
    },
    {
        company: { name: 'entity6', id: 'entity6Id' },
        rights: [
            {
                type: 'UploadDocument',
                name: 'Upload document',
                active: false
            },
            { type: 'Comment', name: 'Comment', active: false }
        ]
    },

    {
        company: { name: 'entity7', id: 'entity7Id' },
        rights: [
            {
                type: 'UploadDocument',
                name: 'Upload document',
                active: false
            },
            { type: 'Comment', name: 'Comment', active: true }
        ]
    },
    {
        company: { name: 'entity8', id: 'entity8Id' },
        rights: [
            {
                type: 'UploadDocument',
                name: 'Upload document',
                active: false
            },
            { type: 'Comment', name: 'Comment', active: true }
        ]
    }
];
