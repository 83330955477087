import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, CardBody, CardFooter, CardHeader } from '../../partials/controls/Card';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { Typography } from '@mui/material';
import {
    Settings as SettingsIcon,
    SettingsOutlined as SettingsOutlinedIcon,
    GroupAdd as GroupAddIcon,
    VideoLabel as VideoLabelIcon,
    MailOutlineOutlined as MailOutlineOutlinedIcon,
    PlaylistAdd as PlaylistAddIcon
} from '@mui/icons-material';
import { LetterTemplateSetup, WorkflowSetup, EmailTemplateSetup, GlobalClientSetup } from '../../screens/setup';
import { TechnicalConfigComponent, GeneralConfig, SETUP_SECTIONS } from '.';
import { StepperFooterActions } from '../stepper/StepperFooterActions';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(10,31,143) 0%,rgb(0,113,206) 50%,rgb(120,120,120) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(10,31,143) 0%,rgb(0,113,206) 50%,rgb(120,120,120) 100%)'
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, rgb(10,31,143) 0%, rgb(0,113,206) 50%, rgb(120,120,120) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    completed: {
        backgroundImage: 'linear-gradient( 136deg, rgb(10,31,143) 0%, rgb(0,113,206) 50%, rgb(120,120,120) 100%)'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <SettingsOutlinedIcon />,
        3: <PlaylistAddIcon />,
        4: <VideoLabelIcon />,
        5: <MailOutlineOutlinedIcon />,
        6: <GroupAddIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}>
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

function getSteps() {
    return [
        <FormattedMessage id="SETUP.SUBSECTION.TECHNICAL.CONFIGURATION.TITLE" />,
        <FormattedMessage id="SETUP.SUBSECTION.GENERAL.CONFIGURATION.TITLE" />,
        <FormattedMessage id="SETUP.SUBSECTION.WORKFLOW.TITLE" />,
        <FormattedMessage id="SETUP.SUBSECTION.LETTER.TEMPLATE.TITLE" />,
        <FormattedMessage id="SETUP.SUBSECTION.EMAIL.TEMPLATE.TITLE" />,
        <FormattedMessage id="SETUP.SUBSECTION.CLIENT.TITLE" />
    ];
}

function getStepContent(step, toggleCanHandleNext) {
    switch (step) {
        case SETUP_SECTIONS.TECHNICAL_CONFIGURATION:
            return <TechnicalConfigComponent toggleCanHandleNext={toggleCanHandleNext} />;
        case SETUP_SECTIONS.GENERAL_CONFIGURATION:
            return <GeneralConfig toggleCanHandleNext={toggleCanHandleNext} />;
        case SETUP_SECTIONS.WORKFLOW:
            return <WorkflowSetup toggleCanHandleNext={toggleCanHandleNext} />;
        case SETUP_SECTIONS.LETTER_TEMPLATE:
            return <LetterTemplateSetup toggleCanHandleNext={toggleCanHandleNext} />;
        case SETUP_SECTIONS.EMAIL_TEMPLATE:
            return <EmailTemplateSetup toggleCanHandleNext={toggleCanHandleNext} />;
        case SETUP_SECTIONS.CLIENT:
            return <GlobalClientSetup toggleCanHandleNext={toggleCanHandleNext} />;

        default:
            return 'Unknown step';
    }
}

export function ConfigComponent(props) {
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [canHandleNext, setCanHandleNext] = useState(false);

    const steps = getSteps();

    const handleNext = () => {
        canHandleNext && setActiveStep(prevActiveStep => prevActiveStep + 1);
        handleComplete(activeStep);
    };

    const handlePrevious = () => setActiveStep(prevActiveStep => prevActiveStep - 1);
    const handleStep = step => () => {
        setActiveStep(step);
        handleComplete(activeStep);
    };

    const handleComplete = step => {
        // TODO: Check within the step component if the step is finalised
        const newCompleted = completed;
        newCompleted[step] = true;
        setCompleted(newCompleted);
    };

    const handleReset = () => {
        setActiveStep(SETUP_SECTIONS.TECHNICAL_CONFIGURATION);
        setCompleted({});
    };

    const handleFinish = () => {
        history.goBack();
    };

    const backToPreviousPage = () => {
        history.goBack();
    };

    const toggleCanHandleNext = isAllSectionsValid => setCanHandleNext(isAllSectionsValid);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Card>
                        <CardHeader>
                            <div className={classes.root}>
                                <Stepper
                                    nonLinear
                                    activeStep={activeStep}
                                    alternativeLabel
                                    connector={<ColorlibConnector />}>
                                    {steps.map((label, index) => (
                                        <Step key={`setup_step_${index}`} completed={completed[index]}>
                                            <StepButton onClick={handleStep(index)}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <>
                                <Typography className={classes.instructions} component={'span'}>
                                    {getStepContent(activeStep, toggleCanHandleNext)}
                                </Typography>
                            </>
                        </CardBody>
                        <CardFooter>
                            <StepperFooterActions
                                finishLabelId="SETUP.BUTTON.FINISH.LABEL"
                                isNextStepDisabled={activeStep === steps.length - 1}
                                isFirstStep={activeStep === 0}
                                isLastStep={activeStep === steps.length - 1}
                                handleBack={backToPreviousPage}
                                handlePrevious={handlePrevious}
                                handleReset={handleReset}
                                handleSave={handleFinish}
                                handleNext={handleNext}
                                loading={false}
                            />
                        </CardFooter>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default injectIntl(ConfigComponent);
