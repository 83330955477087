import { performAPIAction } from './BaseService';
import { IntlShape } from 'react-intl';
import { ServiceResult } from '../kit/network/ServiceResult';
import { mailingConfigRepository, serializeMailingConfiguration } from '../kit/repository';

interface ServiceProps {
    entityType: any;
    intl: IntlShape;
    setLoading: (loading: boolean) => void;
    checkSession: (result: ServiceResult) => void;
    addToastMessage: (toast: any) => void;
    callback: (isSuccess: boolean, data: ServiceResult, entityType: any) => void;
}

export const MailingConfigService = (props: ServiceProps) => {
    return {
        fetchAll: () =>
            performAPIAction({
                ...props,
                apiCall: mailingConfigRepository.fetchAll(),
                errorMessageId: 'MAILING.CONFIG.ACCESS.ERROR',
            }),
        fetch: configurationId =>
            performAPIAction({
                ...props,
                apiCall: mailingConfigRepository.fetch(configurationId),
                errorMessageId: 'MAILING.CONFIG.ACCESS.ERROR',
            }),
        fetchCluster: clusterId =>
            performAPIAction({
                ...props,
                apiCall: mailingConfigRepository.fetchCluster(clusterId),
                errorMessageId: 'MAILING.CONFIG.ACCESS.ERROR',
            }),
        create: configuration => 
            performAPIAction({
                ...props,
                apiCall: mailingConfigRepository.create(serializeMailingConfiguration(configuration)),
                successMessageId: 'MAILING.CONFIG.CREATE.SUCCESS',
                errorMessageId: 'MAILING.CONFIG.CREATE.ERROR'
            }),
        update: configuration =>
            performAPIAction({
                ...props,
                apiCall: mailingConfigRepository.update(configuration.id, serializeMailingConfiguration(configuration)),
                successMessageId: 'MAILING.CONFIG.UPDATE.SUCCESS',
                errorMessageId: 'MAILING.CONFIG.UPDATE.ERROR',
            }),
        delete: configurationId =>
            performAPIAction({
                ...props,
                apiCall: mailingConfigRepository.delete(configurationId),
                successMessageId: 'MAILING.CONFIG.DELETE.SUCCESS',
                errorMessageId: 'MAILING.CONFIG.DELETE.ERROR'
            })
    };
};
