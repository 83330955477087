import React, { useMemo } from 'react';
import { LanguageTopBarItem } from './components/LanguageTopBarItem';
import { ProfileTopBarItem } from '../extras/dropdowns/UserProfileDropdown';
import { ClusterTopBarItem } from './components/ClusterTopBarItem';
import { useSelector } from 'react-redux';
import { isAuthorisedProfile } from '../auth/UserHelper';
import { PROFILE } from '../../types/types';

export function Topbar() {
    const user = useSelector(state => state.account.user);

    const isSuperAdmin = useMemo(() => {
        return isAuthorisedProfile(PROFILE.SUPER_ADMIN, user.profil);
    }, [user?.profil]);

    return (
        <div className="topbar">
            {isSuperAdmin && <ClusterTopBarItem />}
            <LanguageTopBarItem />
            <ProfileTopBarItem />
        </div>
    );
}
